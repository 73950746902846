import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHooks from "./CustomApolloHooks";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
import { dateTimeTypePolicy, dateTypePolicy, timeTypePolicy } from "./ScalarTypePolicies";

export const scalarTypePolicies = {
	Categoria: { fields: { DataAlteracao: dateTimeTypePolicy } },
	CategoriaCliente: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Cep: { fields: { DataConsulta: dateTimeTypePolicy } },
	Cest: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Cliente: {
		fields: {
			DataAdmissao: dateTypePolicy,
			DataAlteracao: dateTimeTypePolicy,
			DataCadastro: dateTimeTypePolicy,
			DataNascimento: dateTypePolicy,
		},
	},
	Colaborador: {
		fields: { DataAdmissao: dateTypePolicy, DataDemissao: dateTypePolicy, DataNascimento: dateTypePolicy },
	},
	Colecao: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Condicional: {
		fields: {
			Data: dateTypePolicy,
			DataAlteracao: dateTimeTypePolicy,
			DataCadastro: dateTimeTypePolicy,
			DataPrevRetorno: dateTypePolicy,
			DataRetorno: dateTimeTypePolicy,
		},
	},
	ContaFinanceira: { fields: { DataCad: dateTimeTypePolicy } },
	Contador: {
		fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy, DataNascimento: dateTypePolicy },
	},
	Credito: { fields: { DataLancamento: dateTimeTypePolicy } },
	Devolucao: { fields: { Data: dateTypePolicy, Hora: timeTypePolicy } },
	Empresa: { fields: { DataCadastro: dateTimeTypePolicy, DataFundacao: dateTypePolicy } },
	EventoNfe: { fields: { DataAutorizacao: dateTimeTypePolicy, DataRegistro: dateTimeTypePolicy } },
	Financeiro: { fields: { DataAlteracao: dateTimeTypePolicy } },
	FormaPagamento: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Fornecedor: {
		fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy, DataNascimento: dateTypePolicy },
	},
	Grade: { fields: { DataAlteracao: dateTimeTypePolicy } },
	GradeCampo: { fields: { DataAlteracao: dateTimeTypePolicy } },
	GradeEixo: { fields: { DataAlteracao: dateTimeTypePolicy } },
	GradeTipo: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Importacao: { fields: { DataDesembaraco: dateTypePolicy, DataRegistro: dateTypePolicy } },
	ItemCondicional: { fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy } },
	ItemVenda: { fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy } },
	Lancamento: { fields: { DataHoraRegistro: dateTimeTypePolicy, DataLancamento: dateTimeTypePolicy } },
	Manifesto: { fields: { Emissao: dateTimeTypePolicy } },
	Marca: { fields: { DataAlteracao: dateTimeTypePolicy } },
	MovimentoContaFinanceira: { fields: { Data: dateTypePolicy, Hora: timeTypePolicy } },
	MovimentoEstoque: { fields: { Data: dateTimeTypePolicy } },
	Ncm: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Nfe: {
		fields: {
			DataAlteracao: dateTimeTypePolicy,
			DataCadastro: dateTimeTypePolicy,
			DataCancelamento: dateTimeTypePolicy,
			DataCompet: dateTypePolicy,
			DataEmissao: dateTypePolicy,
			DataHoraEmissao: dateTimeTypePolicy,
			DataHoraSaiEnt: dateTimeTypePolicy,
			DataInutilizada: dateTimeTypePolicy,
			DataSaiEnt: dateTypePolicy,
			HoraEmissao: timeTypePolicy,
			HoraSaiEnt: timeTypePolicy,
		},
	},
	NfeRetorno: {
		fields: {
			DataAlteracao: dateTimeTypePolicy,
			DataCadastro: dateTimeTypePolicy,
			DataCancelamento: dateTimeTypePolicy,
			DataCompet: dateTypePolicy,
			DataEmissao: dateTypePolicy,
			DataHoraEmissao: dateTimeTypePolicy,
			DataHoraSaiEnt: dateTimeTypePolicy,
			DataInutilizada: dateTimeTypePolicy,
			DataSaiEnt: dateTypePolicy,
			HoraEmissao: timeTypePolicy,
			HoraSaiEnt: timeTypePolicy,
		},
	},
	Notificacao: { fields: { DataCriacao: dateTimeTypePolicy } },
	Pagamento: { fields: { DataEmissao: dateTypePolicy, DataPagamento: dateTypePolicy, DataVencimento: dateTypePolicy } },
	Parcelamento: {
		fields: {
			DataLancamento: dateTypePolicy,
			DataPgto: dateTypePolicy,
			DataVencimento: dateTypePolicy,
			HoraLancamento: timeTypePolicy,
		},
	},
	PedidoVenda: {
		fields: {
			Data: dateTypePolicy,
			DataAlteracao: dateTimeTypePolicy,
			DataCadastro: dateTimeTypePolicy,
			DataEntrega: dateTypePolicy,
			DataHora: dateTimeTypePolicy,
			Hora: timeTypePolicy,
		},
	},
	PermissaoColaborador: { fields: { DataAlteracao: dateTimeTypePolicy } },
	PermissaoColaboradorTerceiro: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Pessoa: {
		fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy, DataNascimento: dateTypePolicy },
	},
	PrestacaoDevolucao: { fields: { DataAlteracao: dateTimeTypePolicy, DataVencimento: dateTypePolicy } },
	PrestacaoNfe: { fields: { DataAlteracao: dateTimeTypePolicy, DataVencimento: dateTypePolicy } },
	PrestacaoVenda: { fields: { DataAlteracao: dateTimeTypePolicy, DataVencimento: dateTimeTypePolicy } },
	Produto: { fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy } },
	Query: { fields: { ultimaRegraAtualizada: dateTimeTypePolicy } },
	Receita: { fields: { DataEmissao: dateTypePolicy, DataRecebimento: dateTypePolicy, DataVencimento: dateTypePolicy } },
	ReferenciaNfe: { fields: { DataEmissao: dateTypePolicy } },
	RelatorioComissoesDevolucoes: { fields: { DataVenda: dateTypePolicy } },
	RelatorioComissoesVenda: { fields: { DataVenda: dateTypePolicy } },
	Subcategoria: { fields: { DataAlteracao: dateTimeTypePolicy } },
	TipoDependente: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Token: { fields: { DataCriacao: dateTimeTypePolicy, DataExpiracao: dateTimeTypePolicy } },
	Transportadora: {
		fields: { DataAlteracao: dateTimeTypePolicy, DataCadastro: dateTimeTypePolicy, DataNascimento: dateTypePolicy },
	},
	Unidade: { fields: { DataAlteracao: dateTimeTypePolicy } },
	Venda: {
		fields: {
			DataAlteracao: dateTimeTypePolicy,
			DataCadastro: dateTimeTypePolicy,
			DataEntrega: dateTypePolicy,
			DataHora: dateTimeTypePolicy,
		},
	},
	VendasPorTributacao: { fields: { DataEmissao: dateTypePolicy } },
	VolumeVenda: { fields: { Data: dateTypePolicy } },
};

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	Date: Date;
	DateTime: Date;
	Resposta: RespostaEnum;
	RetornoBalanceteDemonstrativo: BalanceteDemonstrativo;
	RetornoEnvioCsv: any;
	RetornoInventarioEstoque: InventarioEstoque;
	RetornoMovimentoProduto: MovimentoProduto;
	RetornoPadrao: RetornoPadrao;
	RetornoPermissao: RetornoPermissao;
	RetornoTarefa: RetornoTarefa;
	Status: StatusEnum;
	Time: Date;
	Upload: File;
};

export enum Administradoras_Cartao_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type AdicaoImportacao = {
	__typename?: "AdicaoImportacao";
	CodFabricante: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdImportacao: Scalars["Int"];
	NumAdicao?: Maybe<Scalars["Int"]>;
	NumDrawback?: Maybe<Scalars["String"]>;
	NumSequencialAdicao: Scalars["Int"];
	ValorDescDI?: Maybe<Scalars["Float"]>;
};

export type AdminCartao = {
	__typename?: "AdminCartao";
	CodigoBandeira?: Maybe<Scalars["Int"]>;
	DiasRecebimentoCredito: Scalars["Int"];
	DiasRecebimentoDebito: Scalars["Int"];
	Id: Scalars["Int"];
	IdBandeira?: Maybe<Scalars["Int"]>;
	IdContaFinanceiro: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	OperadoraBandeira?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	TaxaCred1: Scalars["Float"];
	TaxaCred2: Scalars["Float"];
	TaxaCred3: Scalars["Float"];
	TaxaCred4: Scalars["Float"];
	TaxaCred5: Scalars["Float"];
	TaxaCred6: Scalars["Float"];
	TaxaCred7: Scalars["Float"];
	TaxaCred8: Scalars["Float"];
	TaxaCred9: Scalars["Float"];
	TaxaCred10: Scalars["Float"];
	TaxaCred11: Scalars["Float"];
	TaxaCred12: Scalars["Float"];
	TaxaDebito: Scalars["Float"];
};

export enum AgrupamentoRelatorioVendasPorProduto {
	Categoria = "CATEGORIA",
	Colecao = "COLECAO",
	Marca = "MARCA",
	Vendedor = "VENDEDOR",
}

export type AliquotaIcms = {
	__typename?: "AliquotaIcms";
	Aliquota: Scalars["Float"];
	Id: Scalars["Int"];
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdOriginal: Scalars["Int"];
	IdUfDestino: Scalars["Int"];
	IdUfOrigem: Scalars["Int"];
	NomeUfDestino: Scalars["String"];
	NomeUfOrigem: Scalars["String"];
	SiglaUfDestino: Scalars["String"];
	SiglaUfOrigem: Scalars["String"];
};

export type Arma = {
	__typename?: "Arma";
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdItemNfe: Scalars["Int"];
	NumCano: Scalars["String"];
	NumSerie: Scalars["String"];
	TipoArma: TipoArmaFogo;
};

export enum AtividadeSped {
	Industrial = "INDUSTRIAL",
	Outras = "OUTRAS",
}

export type AtualizaCache = {
	__typename?: "AtualizaCache";
	FieldNames: Array<Scalars["String"]>;
};

export type BandeiraCartao = {
	__typename?: "BandeiraCartao";
	Codigo: Scalars["Int"];
	Id: Scalars["Int"];
	Operadora: Scalars["String"];
};

export enum BandeirasCartao {
	Alelo = "ALELO",
	AmericanExpress = "AMERICAN_EXPRESS",
	Aura = "AURA",
	BanesCard = "BANES_CARD",
	Cabal = "CABAL",
	CalCard = "CAL_CARD",
	Credz = "CREDZ",
	DinersClub = "DINERS_CLUB",
	Discover = "DISCOVER",
	Elo = "ELO",
	GoodCard = "GOOD_CARD",
	GreenCard = "GREEN_CARD",
	Hiper = "HIPER",
	Hipercard = "HIPERCARD",
	JcB = "JC_B",
	Mais = "MAIS",
	Mastercard = "MASTERCARD",
	MaxVan = "MAX_VAN",
	Outros = "OUTROS",
	Policard = "POLICARD",
	RedeCompras = "REDE_COMPRAS",
	Sodexo = "SODEXO",
	Sorocred = "SOROCRED",
	Ticket = "TICKET",
	ValeCard = "VALE_CARD",
	Verocheque = "VEROCHEQUE",
	Visa = "VISA",
	Vr = "VR",
}

export enum Captacao_Emprestimos_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Categoria_Cliente_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Categoria_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Cliente_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Colaborador_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Colecao_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Condicional_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	EditarAposRetornado = "EDITAR_APOS_RETORNADO",
	Retorno = "RETORNO",
	Visualizar = "VISUALIZAR",
}

export enum Configuracoes_Gerais_Permissao {
	Editar = "EDITAR",
}

export enum Contador_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Conta_Financeira_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Cupom_Fiscal_Permissao {
	Cadastrar = "CADASTRAR",
	Cancelar = "CANCELAR",
	CancelarItem = "CANCELAR_ITEM",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type CalculaImpostoNfe = {
	__typename?: "CalculaImpostoNfe";
	IdCfop?: Maybe<Scalars["Int"]>;
	IdEnderecoEntrega?: Maybe<Scalars["Int"]>;
	IdPessoa?: Maybe<Scalars["Int"]>;
	Itens: Array<CalculaImpostoNfeItens>;
	ModCliente?: Maybe<ModalidadeCliente>;
	ObservacaoComplementar?: Maybe<Scalars["String"]>;
	OutrasOpcoes?: Maybe<Scalars["String"]>;
	TipoNF?: Maybe<TipoNFe>;
	ValorBCIss?: Maybe<Scalars["Float"]>;
	ValorBcIcms?: Maybe<Scalars["Float"]>;
	ValorBcIcmsSt?: Maybe<Scalars["Float"]>;
	ValorCofins?: Maybe<Scalars["Float"]>;
	ValorDesconto: Scalars["Float"];
	ValorFcpSt?: Maybe<Scalars["Float"]>;
	ValorFrete: Scalars["Float"];
	ValorIcms?: Maybe<Scalars["Float"]>;
	ValorIcmsDesonerado?: Maybe<Scalars["Float"]>;
	ValorIcmsSt?: Maybe<Scalars["Float"]>;
	ValorIi?: Maybe<Scalars["Float"]>;
	ValorIpi?: Maybe<Scalars["Float"]>;
	ValorIpiDevol?: Maybe<Scalars["Float"]>;
	ValorIss?: Maybe<Scalars["Float"]>;
	ValorOutro: Scalars["Float"];
	ValorPis?: Maybe<Scalars["Float"]>;
	ValorSeguro: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorTotalNfe: Scalars["Float"];
};

export type CalculaImpostoNfeItens = {
	__typename?: "CalculaImpostoNfeItens";
	CodigoCfop?: Maybe<Scalars["String"]>;
	DetalhamentoItem?: Maybe<Scalars["String"]>;
	IdCest?: Maybe<Scalars["Int"]>;
	IdCfop: Scalars["Int"];
	IdCstCofins?: Maybe<Scalars["Int"]>;
	IdCstIpi?: Maybe<Scalars["Int"]>;
	IdCstPis?: Maybe<Scalars["Int"]>;
	IdNcm?: Maybe<Scalars["Int"]>;
	IdOperacaoCfop?: Maybe<TipoCfopOperacao>;
	IdProduto: Scalars["Int"];
	IdSituaTribut?: Maybe<Scalars["Int"]>;
	Index: Scalars["Int"];
	OutrasOpcoes?: Maybe<Scalars["String"]>;
	PorcentFcpStRet?: Maybe<Scalars["Float"]>;
	PorcentIcms?: Maybe<Scalars["Float"]>;
	PorcentIcmsEfet?: Maybe<Scalars["Float"]>;
	PorcentIcmsSt?: Maybe<Scalars["Float"]>;
	PorcentIpi?: Maybe<Scalars["Float"]>;
	PorcentRedBCEfet?: Maybe<Scalars["Float"]>;
	PorcentSt?: Maybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	ValorAcrescimo: Scalars["Float"];
	ValorBCEfet?: Maybe<Scalars["Float"]>;
	ValorBCFcpStRet?: Maybe<Scalars["Float"]>;
	ValorBCIcms?: Maybe<Scalars["Float"]>;
	ValorBCIcmsSt?: Maybe<Scalars["Float"]>;
	ValorBCIpi?: Maybe<Scalars["Float"]>;
	ValorBCStRet?: Maybe<Scalars["Float"]>;
	ValorDesconto: Scalars["Float"];
	ValorFcpSt?: Maybe<Scalars["Float"]>;
	ValorFcpStRet?: Maybe<Scalars["Float"]>;
	ValorIcms?: Maybe<Scalars["Float"]>;
	ValorIcmsDeson?: Maybe<Scalars["Float"]>;
	ValorIcmsEfet?: Maybe<Scalars["Float"]>;
	ValorIcmsSt?: Maybe<Scalars["Float"]>;
	ValorIcmsStRet?: Maybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: Maybe<Scalars["Float"]>;
	ValorIi?: Maybe<Scalars["Float"]>;
	ValorIpi?: Maybe<Scalars["Float"]>;
	ValorIpiDevol?: Maybe<Scalars["Float"]>;
	ValorUnitario: Scalars["Float"];
};

export type Categoria = {
	__typename?: "Categoria";
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEcommerce?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Status: Scalars["Status"];
	Subcategorias?: Maybe<Array<Subcategoria>>;
};

export type CategoriaSubcategoriasArgs = {
	Filtro?: InputMaybe<InputFiltroCategorias>;
};

export type CategoriaCliente = {
	__typename?: "CategoriaCliente";
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Status: Scalars["Status"];
};

export enum CategoriaSituacao {
	IcmsNormal = "ICMS_NORMAL",
	IcmsSn = "ICMS_SN",
	Ipi = "IPI",
	PisCofins = "PIS_COFINS",
}

export type Cep = {
	__typename?: "Cep";
	Bairro?: Maybe<Scalars["String"]>;
	Cep: Scalars["String"];
	Cidade?: Maybe<Scalars["String"]>;
	CodIBGE?: Maybe<Scalars["String"]>;
	Complemento?: Maybe<Scalars["String"]>;
	DataConsulta?: Maybe<Scalars["DateTime"]>;
	Id: Scalars["Int"];
	IdCidade: Scalars["Int"];
	IdEstado: Scalars["Int"];
	Logradouro?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
};

export type Cest = {
	__typename?: "Cest";
	CodCest: Scalars["String"];
	CodNcm?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdOriginal: Scalars["Int"];
	Item?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
};

export type Cfop = {
	__typename?: "Cfop";
	Codigo: Scalars["String"];
	CreditoIcmsCompra: Scalars["Status"];
	Descricao: Scalars["String"];
	DescricaoOperacao: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdOperacao: TipoCfopOperacao;
	IdOriginal: Scalars["Int"];
	MovimentaEstoque: Scalars["Status"];
	MovimentaFinanceiro: Scalars["Status"];
	PrecoPadrao: PrecoPadraoCfop;
	Status: Scalars["Status"];
	StatusOperacao: Scalars["Status"];
	TipoNFe: TipoNFe;
};

export type CfopOperacao = {
	__typename?: "CfopOperacao";
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	Status: Scalars["Status"];
};

export type Cidade = {
	__typename?: "Cidade";
	CodIBGE: Scalars["String"];
	CodUfIBGE: Scalars["String"];
	Id: Scalars["Int"];
	IdEstado: Scalars["Int"];
	Nome: Scalars["String"];
	NomeEstado: Scalars["String"];
	Uf: Scalars["String"];
};

export type Cliente = {
	__typename?: "Cliente";
	Bairro?: Maybe<Scalars["String"]>;
	Cargo?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	Complemento?: Maybe<Scalars["String"]>;
	Contatos?: Maybe<Array<Maybe<ContatoPessoa>>>;
	CpfCnpj?: Maybe<Scalars["String"]>;
	DataAdmissao?: Maybe<Scalars["Date"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataNascimento?: Maybe<Scalars["Date"]>;
	DescricaoGenero?: Maybe<Scalars["String"]>;
	Email?: Maybe<Scalars["String"]>;
	EnderecoComercial?: Maybe<Scalars["String"]>;
	EstadoCivil?: Maybe<EstadoCivil>;
	FoneComercial?: Maybe<Scalars["String"]>;
	Foto?: Maybe<Scalars["String"]>;
	IdCategoria?: Maybe<Scalars["Int"]>;
	IdCidade?: Maybe<Scalars["Int"]>;
	IdEcommerce?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdGenero?: Maybe<Scalars["Int"]>;
	IdPaiDependente?: Maybe<Scalars["Int"]>;
	IdPais?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	IdTipoDependente?: Maybe<Scalars["Int"]>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	LimiteCredito?: Maybe<Scalars["Float"]>;
	LocalTrabalho?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeCategoria?: Maybe<Scalars["String"]>;
	NomeFantasia?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Observacao?: Maybe<Scalars["String"]>;
	PermitirVenda?: Maybe<Scalars["Resposta"]>;
	RG?: Maybe<Scalars["String"]>;
	ReferenciasComerciais?: Maybe<Array<Maybe<ReferenciaComercial>>>;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	RendaMensal?: Maybe<Scalars["Float"]>;
	SpcSerasa?: Maybe<Scalars["Resposta"]>;
	Status: Scalars["Status"];
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
};

export type Colaborador = {
	__typename?: "Colaborador";
	Bairro?: Maybe<Scalars["String"]>;
	Cargo?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	Complemento?: Maybe<Scalars["String"]>;
	CpfCnpj?: Maybe<Scalars["String"]>;
	DataAdmissao?: Maybe<Scalars["Date"]>;
	DataDemissao?: Maybe<Scalars["Date"]>;
	DataNascimento?: Maybe<Scalars["Date"]>;
	Email?: Maybe<Scalars["String"]>;
	Foto?: Maybe<Scalars["String"]>;
	IdCidade?: Maybe<Scalars["Int"]>;
	IdDepartamento?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdPais?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	Login?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	NivelFuncao: Scalars["Int"];
	Nome: Scalars["String"];
	NomeDepartamento?: Maybe<Scalars["String"]>;
	NomeFantasia?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Observacao?: Maybe<Scalars["String"]>;
	PerfilAcesso: PerfilAcessoColaborador;
	Permissoes?: Maybe<Array<Maybe<PermissaoColaborador>>>;
	Pis?: Maybe<Scalars["String"]>;
	PorcentComis?: Maybe<Scalars["Float"]>;
	PorcentDescMax?: Maybe<Scalars["Float"]>;
	ProducaoDepartamento?: Maybe<Scalars["Status"]>;
	RG?: Maybe<Scalars["String"]>;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	Senha?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	StatusDepartamento?: Maybe<Scalars["Status"]>;
	TecnicoDepartamento?: Maybe<Scalars["Status"]>;
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
	VendasDepartamento?: Maybe<Scalars["Status"]>;
};

export type ColaboradorEmpresa = {
	__typename?: "ColaboradorEmpresa";
	CpfCnpj: Scalars["String"];
	CpfCnpjColaborador?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdColaborador: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdEmpresaColaborador: Scalars["Int"];
	LoginColaborador: Scalars["String"];
	Nome: Scalars["String"];
	NomeColaborador: Scalars["String"];
	NomeFantasia?: Maybe<Scalars["String"]>;
	NomeFantasiaColaborador?: Maybe<Scalars["String"]>;
	NomeFilial?: Maybe<Scalars["String"]>;
	SistemaEmpresa: SistemaEmpresa;
};

export type Colecao = {
	__typename?: "Colecao";
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Status: Scalars["Status"];
};

export type ComissaoFormaPgto = {
	__typename?: "ComissaoFormaPgto";
	DescricaoFormaPgto?: Maybe<Scalars["String"]>;
	Id?: Maybe<Scalars["Int"]>;
	IdColaborador?: Maybe<Scalars["Int"]>;
	IdFormaPgto?: Maybe<Scalars["Int"]>;
	Status?: Maybe<Scalars["Status"]>;
	TipoFormaPgto?: Maybe<TipoFormaPagamento>;
	Valor?: Maybe<Scalars["Float"]>;
};

export type Comissoes = {
	__typename?: "Comissoes";
	ComissoesFormaPgto?: Maybe<Array<Maybe<RelatorioComissoesFormaPgto>>>;
	Devolucao?: Maybe<Array<Maybe<RelatorioComissoesDevolucoes>>>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	Metas?: Maybe<Array<Maybe<RelatorioComissoesMeta>>>;
	ValorTotalComissao?: Maybe<Scalars["Float"]>;
	ValorTotalDevolucao?: Maybe<Scalars["Float"]>;
	ValorTotalVendas?: Maybe<Scalars["Float"]>;
	Venda?: Maybe<Array<Maybe<RelatorioComissoesVenda>>>;
};

export enum ComportamentoNivel {
	Soma = "SOMA",
	Subtrai = "SUBTRAI",
}

export type Condicional = {
	__typename?: "Condicional";
	BairroCliente?: Maybe<Scalars["String"]>;
	CelularCliente?: Maybe<Scalars["String"]>;
	CepCliente?: Maybe<Scalars["String"]>;
	CidadeCliente?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	CodigoVenda?: Maybe<Scalars["String"]>;
	ComplementoCliente?: Maybe<Scalars["String"]>;
	CpfCnpjCliente?: Maybe<Scalars["String"]>;
	Data: Scalars["Date"];
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataPrevRetorno?: Maybe<Scalars["Date"]>;
	DataRetorno?: Maybe<Scalars["DateTime"]>;
	DocFiscal?: Maybe<Scalars["String"]>;
	EmailCliente?: Maybe<Scalars["String"]>;
	HashLink?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCliente: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdUsuarioRetorno?: Maybe<Scalars["Int"]>;
	IdUsuarioSaida: Scalars["Int"];
	IdVenda?: Maybe<Scalars["Int"]>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	ItemCondicional: Array<ItemCondicional>;
	LogradouroCliente?: Maybe<Scalars["String"]>;
	NomeCliente?: Maybe<Scalars["String"]>;
	NomeFantasiaCliente?: Maybe<Scalars["String"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	NumeroCliente?: Maybe<Scalars["String"]>;
	Observacoes?: Maybe<Scalars["String"]>;
	Status: StatusCondicional;
	TelefoneCliente?: Maybe<Scalars["String"]>;
	UFCliente?: Maybe<Scalars["String"]>;
	ValorDesconto: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorTotal: Scalars["Float"];
	ValorTotalPrazo: Scalars["Float"];
};

export type Configuracao = {
	__typename?: "Configuracao";
	Id: Scalars["Int"];
	Nome: Scalars["String"];
	NomeLegivel: Scalars["String"];
	ValorPadrao?: Maybe<Scalars["String"]>;
};

export type ConfiguracaoEmpresa = {
	__typename?: "ConfiguracaoEmpresa";
	Id?: Maybe<Scalars["Int"]>;
	IdConfig: Configuracoes;
	Nome: Scalars["String"];
	NomeLegivel: Scalars["String"];
	Valor?: Maybe<Scalars["String"]>;
};

export enum Configuracoes {
	AliquotaIcmsPadrao = "ALIQUOTA_ICMS_PADRAO",
	AliqIcmsSn = "ALIQ_ICMS_SN",
	AmbienteSefaz = "AMBIENTE_SEFAZ",
	AmbienteSefazNfce = "AMBIENTE_SEFAZ_NFCE",
	CamposEtiqueta = "CAMPOS_ETIQUETA",
	CancelaAutomaticamenteNfce = "CANCELA_AUTOMATICAMENTE_NFCE",
	CancelaAutomaticamenteNfe = "CANCELA_AUTOMATICAMENTE_NFE",
	CancelaAutomaticamenteVenda = "CANCELA_AUTOMATICAMENTE_VENDA",
	CancelaAutomaticamenteVendaNfce = "CANCELA_AUTOMATICAMENTE_VENDA_NFCE",
	CasasDecimaisQuantidade = "CASAS_DECIMAIS_QUANTIDADE",
	CasasDecimaisValor = "CASAS_DECIMAIS_VALOR",
	CfopPadraoNfce = "CFOP_PADRAO_NFCE",
	CfopPadraoNfe = "CFOP_PADRAO_NFE",
	ComissaoOpcoesValor = "COMISSAO_OPCOES_VALOR",
	ComissaoValorGeral = "COMISSAO_VALOR_GERAL",
	ConcatenarCodForn = "CONCATENAR_COD_FORN",
	CondicionalDuasVias = "CONDICIONAL_DUAS_VIAS",
	ContaFinanceiraPadrao = "CONTA_FINANCEIRA_PADRAO",
	ContaFinanceiraPdv = "CONTA_FINANCEIRA_PDV",
	CriandoDadosDemonstrativos = "CRIANDO_DADOS_DEMONSTRATIVOS",
	DadosDemonstrativos = "DADOS_DEMONSTRATIVOS",
	DataAlteracaoCert = "DATA_ALTERACAO_CERT",
	DataVencimentoCert = "DATA_VENCIMENTO_CERT",
	DevolucaoAutomatica = "DEVOLUCAO_AUTOMATICA",
	EnviarEmailNfeAutomatico = "ENVIAR_EMAIL_NFE_AUTOMATICO",
	EnviarEmailNfeAutomaticoNfce = "ENVIAR_EMAIL_NFE_AUTOMATICO_NFCE",
	Experimentacao = "EXPERIMENTACAO",
	FaturarVendaAoTransmitirNfe = "FATURAR_VENDA_AO_TRANSMITIR_NFE",
	GerarNfceAutomaticamente = "GERAR_NFCE_AUTOMATICAMENTE",
	GerarVendaRetornoCondicional = "GERAR_VENDA_RETORNO_CONDICIONAL",
	HorarioConsultaNfe = "HORARIO_CONSULTA_NFE",
	IncluirIcmsNaBasePisCofins = "INCLUIR_ICMS_NA_BASE_PIS_COFINS",
	InformarQtdPdv = "INFORMAR_QTD_PDV",
	JuroParcelaAtrasada = "JURO_PARCELA_ATRASADA",
	LegendaPreco_1 = "LEGENDA_PRECO_1",
	LegendaPreco_2 = "LEGENDA_PRECO_2",
	ManifestoAutomatico = "MANIFESTO_AUTOMATICO",
	MargemLucro = "MARGEM_LUCRO",
	MensagemPersonalizadaEnvioCondicional = "MENSAGEM_PERSONALIZADA_ENVIO_CONDICIONAL",
	MensagemPersonalizadaEnvioVenda = "MENSAGEM_PERSONALIZADA_ENVIO_VENDA",
	ModalidadeCliente = "MODALIDADE_CLIENTE",
	ModalidadeClienteNfce = "MODALIDADE_CLIENTE_NFCE",
	ModeloEtiquetaPadrao = "MODELO_ETIQUETA_PADRAO",
	ModuloFiscalAtualizacaoCert = "MODULO_FISCAL_ATUALIZACAO_CERT",
	ModuloFiscalAtualizacaoLogo = "MODULO_FISCAL_ATUALIZACAO_LOGO",
	MultasSobreParcelas = "MULTAS_SOBRE_PARCELAS",
	NaturezaOperacaoNfce = "NATUREZA_OPERACAO_NFCE",
	NfceCsc = "NFCE_CSC",
	NfceIdCsc = "NFCE_ID_CSC",
	NumeroProxNfce = "NUMERO_PROX_NFCE",
	NumeroProxNfe = "NUMERO_PROX_NFE",
	ObrigarNumDocCartao = "OBRIGAR_NUM_DOC_CARTAO",
	ObrigarVendedorCondicional = "OBRIGAR_VENDEDOR_CONDICIONAL",
	ObrigarVendedorPdv = "OBRIGAR_VENDEDOR_PDV",
	ObrigarVendedorPedidoVenda = "OBRIGAR_VENDEDOR_PEDIDO_VENDA",
	ObservacaoNfce = "OBSERVACAO_NFCE",
	ObservacaoNfe = "OBSERVACAO_NFE",
	ObsPadraoCondicional = "OBS_PADRAO_CONDICIONAL",
	OpcaoFaturaVenda = "OPCAO_FATURA_VENDA",
	OpcaoFinalizaVenda = "OPCAO_FINALIZA_VENDA",
	OpcaoGerarVenda = "OPCAO_GERAR_VENDA",
	OpcaoGerarVendaNfe = "OPCAO_GERAR_VENDA_NFE",
	ParcelamentoEtiqueta = "PARCELAMENTO_ETIQUETA",
	PedidoVendaDuasVias = "PEDIDO_VENDA_DUAS_VIAS",
	PedidoVendaEntregaPadrao = "PEDIDO_VENDA_ENTREGA_PADRAO",
	PedidoVendaObservacaoPadrao = "PEDIDO_VENDA_OBSERVACAO_PADRAO",
	PerguntarVendedorPdv = "PERGUNTAR_VENDEDOR_PDV",
	PermitirDevolucaoParcialCondicional = "PERMITIR_DEVOLUCAO_PARCIAL_CONDICIONAL",
	PrecoPrincipal = "PRECO_PRINCIPAL",
	PrevisaoRetornoDiasCondicional = "PREVISAO_RETORNO_DIAS_CONDICIONAL",
	SerieNfce = "SERIE_NFCE",
	SerieNfe = "SERIE_NFE",
	SituaTributIcmsPadrao = "SITUA_TRIBUT_ICMS_PADRAO",
	TextoLivreEtiqueta = "TEXTO_LIVRE_ETIQUETA",
	TipoImpressaoNfce = "TIPO_IMPRESSAO_NFCE",
	TipoImpressaoVendaPdv = "TIPO_IMPRESSAO_VENDA_PDV",
	UltimaConsultaManifesto = "ULTIMA_CONSULTA_MANIFESTO",
	UltimoNsu = "ULTIMO_NSU",
	UltimoNsuConsultado = "ULTIMO_NSU_CONSULTADO",
	UtilizarDoisPrecosCondicional = "UTILIZAR_DOIS_PRECOS_CONDICIONAL",
	VenderProdutoSemEstoque = "VENDER_PRODUTO_SEM_ESTOQUE",
	VersaoDaBase = "VERSAO_DA_BASE",
	VincularProdCompraEanGrade = "VINCULAR_PROD_COMPRA_EAN_GRADE",
	VincularProdCompraForn = "VINCULAR_PROD_COMPRA_FORN",
	VisualizarCondicionalAutomaticamente = "VISUALIZAR_CONDICIONAL_AUTOMATICAMENTE",
	VisualizarDanfceAoGerarNfce = "VISUALIZAR_DANFCE_AO_GERAR_NFCE",
	VisualizarDanfeAoGerarNfe = "VISUALIZAR_DANFE_AO_GERAR_NFE",
	VisualizarPedidoVenda = "VISUALIZAR_PEDIDO_VENDA",
	VisualizarVendaPdv = "VISUALIZAR_VENDA_PDV",
}

export type ContaFinanceira = {
	__typename?: "ContaFinanceira";
	DataCad: Scalars["DateTime"];
	DvAgencia?: Maybe<Scalars["String"]>;
	DvConta?: Maybe<Scalars["String"]>;
	Email?: Maybe<Scalars["String"]>;
	Gerente?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdNivel: Scalars["Int"];
	Nome: Scalars["String"];
	NomeTitular?: Maybe<Scalars["String"]>;
	NumAgencia?: Maybe<Scalars["String"]>;
	NumConta?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	Telefone?: Maybe<Scalars["String"]>;
	Tipo: TipoContaFinanceira;
};

export type Contador = {
	__typename?: "Contador";
	Bairro?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Complemento?: Maybe<Scalars["String"]>;
	Cpf?: Maybe<Scalars["String"]>;
	CpfCnpj?: Maybe<Scalars["String"]>;
	Crc?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataNascimento?: Maybe<Scalars["Date"]>;
	Email?: Maybe<Scalars["String"]>;
	IdCidade?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdPais?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Observacao?: Maybe<Scalars["String"]>;
	RG?: Maybe<Scalars["String"]>;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	Status: Scalars["Status"];
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
};

export type ContatoPessoa = {
	__typename?: "ContatoPessoa";
	Contato: Scalars["String"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdPessoa?: Maybe<Scalars["Int"]>;
	Status: Scalars["Status"];
	Tipo: TipoContato;
};

export type Credito = {
	__typename?: "Credito";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	DataLancamento: Scalars["DateTime"];
	Hash: Scalars["String"];
	Historico?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdContaFinanceiro?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdPessoa?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	OperacaoNome: Scalars["String"];
	PessoaNome?: Maybe<Scalars["String"]>;
	PessoaNomeFantasia?: Maybe<Scalars["String"]>;
	Status?: Maybe<StatusCredito>;
	UsuarioNome: Scalars["String"];
	ValorAberto: Scalars["Float"];
	ValorTotal: Scalars["Float"];
	ValorUtilizado: Scalars["Float"];
};

export enum CustoRelatorioVendasPorProduto {
	CustoMedio = "CUSTO_MEDIO",
	CustoUltimaCompra = "CUSTO_ULTIMA_COMPRA",
}

export enum Definicao_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Departamentos_Colaborador_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Devolucoes_Trocas_Permissao {
	Cadastrar = "CADASTRAR",
	Visualizar = "VISUALIZAR",
}

export enum DadosSped {
	DetItensC170 = "DET_ITENS_C170",
	Nfce = "NFCE",
	Nfe = "NFE",
}

export type DateRange = {
	DataFim?: InputMaybe<Scalars["Date"]>;
	DataInicio?: InputMaybe<Scalars["Date"]>;
};

export type DateTimeRange = {
	DataFim?: InputMaybe<Scalars["DateTime"]>;
	DataInicio?: InputMaybe<Scalars["DateTime"]>;
};

export type Definicao = {
	__typename?: "Definicao";
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	MateriaPrima: Scalars["Status"];
	Nome: Scalars["String"];
	Producao: Scalars["Status"];
	Servico: Scalars["Status"];
	Status: Scalars["Status"];
	UsoConsumo: Scalars["Status"];
	Venda: Scalars["Status"];
};

export type Departamento = {
	__typename?: "Departamento";
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Producao: Scalars["Status"];
	Status: Scalars["Status"];
	Tecnico: Scalars["Status"];
	Vendas: Scalars["Status"];
};

export type Devolucao = {
	__typename?: "Devolucao";
	BairroPessoa?: Maybe<Scalars["String"]>;
	CelularPessoa?: Maybe<Scalars["String"]>;
	CidadePessoa?: Maybe<Scalars["String"]>;
	Codigo?: Maybe<Scalars["String"]>;
	ComplementoPessoa?: Maybe<Scalars["String"]>;
	CpfCnpjPessoa?: Maybe<Scalars["String"]>;
	Data?: Maybe<Scalars["Date"]>;
	EmailPessoa?: Maybe<Scalars["String"]>;
	EstadoPessoa?: Maybe<Scalars["String"]>;
	Hora?: Maybe<Scalars["Time"]>;
	Id: Scalars["Int"];
	IdCidadePessoa?: Maybe<Scalars["Int"]>;
	IdEstadoPessoa?: Maybe<Scalars["Int"]>;
	IdNFe?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	IdUsuario: Scalars["Int"];
	ItemDevolucao?: Maybe<Array<Maybe<ItemDevolucao>>>;
	NomeFantasiaPessoa?: Maybe<Scalars["String"]>;
	NomePessoa: Scalars["String"];
	NumeroPessoa?: Maybe<Scalars["String"]>;
	PrestacaoDevolucao?: Maybe<Array<Maybe<PrestacaoDevolucao>>>;
	RuaPessoa?: Maybe<Scalars["String"]>;
	TelefonePessoa?: Maybe<Scalars["String"]>;
	Tipo?: Maybe<TipoTransacao>;
	ValorTotal?: Maybe<Scalars["Float"]>;
};

export enum DiaSemana {
	Domingo = "DOMINGO",
	Quarta = "QUARTA",
	Quinta = "QUINTA",
	Sabado = "SABADO",
	Segunda = "SEGUNDA",
	Sexta = "SEXTA",
	Terca = "TERCA",
}

export enum DirecaoOrdenacao {
	Asc = "ASC",
	Desc = "DESC",
}

export enum Empresa_Permissao {
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type Empresa = {
	__typename?: "Empresa";
	Bairro?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Complemento?: Maybe<Scalars["String"]>;
	Configuracoes: Array<ConfiguracaoEmpresa>;
	CpfCnpj: Scalars["String"];
	DataCadastro: Scalars["DateTime"];
	DataFundacao?: Maybe<Scalars["Date"]>;
	EmailAdministrativo?: Maybe<Scalars["String"]>;
	EmailFinanceiro?: Maybe<Scalars["String"]>;
	EmailPrincipal?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCidade?: Maybe<Scalars["Int"]>;
	IdEstado?: Maybe<Scalars["Int"]>;
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	LogoEmpresa?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: Maybe<Scalars["String"]>;
	NomeFilial?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	NumNFe: Scalars["Int"];
	NumUsuarios: Scalars["Int"];
	Observacao?: Maybe<Scalars["String"]>;
	PlanoContratado: PlanoContratado;
	PossuiFilial: Scalars["Boolean"];
	ProdutoEmpresa: ProdutoEmpresa;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	Responsavel?: Maybe<Scalars["String"]>;
	Sistema: SistemaEmpresa;
	Status: StatusEmpresa;
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
};

export type EnderecoAdicional = {
	__typename?: "EnderecoAdicional";
	Bairro?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Complemento?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCidade: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	Logradouro?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	Tipo: TipoEndereco;
	Uf?: Maybe<Scalars["String"]>;
};

export type Estado = {
	__typename?: "Estado";
	CodUfIBGE: Scalars["Int"];
	CodigoPais?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdPais?: Maybe<Scalars["Int"]>;
	Nome: Scalars["String"];
	NomePais?: Maybe<Scalars["String"]>;
	UF: Scalars["String"];
};

export enum EstadoCivil {
	Casado = "CASADO",
	Divorciado = "DIVORCIADO",
	Separado = "SEPARADO",
	Solteiro = "SOLTEIRO",
	UniaoEstavel = "UNIAO_ESTAVEL",
	Viuvo = "VIUVO",
}

export enum Estagio {
	Aberto = "ABERTO",
	Cancelado = "CANCELADO",
	Faturado = "FATURADO",
	Mesclado = "MESCLADO",
}

export enum EventoManifestoNFe {
	CienciaOperacao = "CIENCIA_OPERACAO",
	ConfirmacaoOperacao = "CONFIRMACAO_OPERACAO",
	DesconhecimentoOperacao = "DESCONHECIMENTO_OPERACAO",
	OperacaoNaoRealizada = "OPERACAO_NAO_REALIZADA",
}

export type EventoNfe = {
	__typename?: "EventoNfe";
	DataAutorizacao?: Maybe<Scalars["DateTime"]>;
	DataRegistro: Scalars["DateTime"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdNFe?: Maybe<Scalars["Int"]>;
	Modelo?: Maybe<Modelo>;
	MsgRetorno?: Maybe<Scalars["String"]>;
	NumNF?: Maybe<Scalars["Int"]>;
	NumProt?: Maybe<Scalars["String"]>;
	NumSeq: Scalars["Int"];
	Retorno?: Maybe<Scalars["String"]>;
	Serie?: Maybe<Scalars["Int"]>;
	Status: StatusEventoNFe;
	Texto?: Maybe<Scalars["String"]>;
	TipoEvento: TipoEventoNFe;
};

export type Exportacao = {
	__typename?: "Exportacao";
	ChaveNFe: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdItemNfe?: Maybe<Scalars["Int"]>;
	NumeroDrawback?: Maybe<Scalars["String"]>;
	NumeroRegistroExportacao: Scalars["String"];
	QuantidadeExportacao: Scalars["Float"];
};

export enum Forma_Pagamento_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Fornecedor_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum FiltroRapidoDespesas {
	PagamentosFuturos = "PAGAMENTOS_FUTUROS",
	PagamentosHoje = "PAGAMENTOS_HOJE",
	PagamentosVencidos = "PAGAMENTOS_VENCIDOS",
	Pagas = "PAGAS",
}

export enum FiltroRapidoReceita {
	ParcelasFuturas = "PARCELAS_FUTURAS",
	ParcelasHoje = "PARCELAS_HOJE",
	ParcelasVencidas = "PARCELAS_VENCIDAS",
	Recebidas = "RECEBIDAS",
}

export enum FinalidadeArquivoSped {
	ArquivoOriginal = "ARQUIVO_ORIGINAL",
	ArquivoSubstituto = "ARQUIVO_SUBSTITUTO",
}

export enum FinalidadeNFe {
	Ajuste = "AJUSTE",
	Complementar = "COMPLEMENTAR",
	Devolucao = "DEVOLUCAO",
	Normal = "NORMAL",
}

export enum FinalidadeSintegra {
	Desfazimento = "DESFAZIMENTO",
	Normal = "NORMAL",
	RetificacaoAditiva = "RETIFICACAO_ADITIVA",
	RetificacaoTotal = "RETIFICACAO_TOTAL",
}

export type Financeiro = {
	__typename?: "Financeiro";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	Desconto?: Maybe<Scalars["Float"]>;
	Historico?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdAdminCartao?: Maybe<Scalars["Int"]>;
	IdContaFinanceiro?: Maybe<Scalars["Int"]>;
	IdDevolucao?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdFormaPgto?: Maybe<Scalars["Int"]>;
	IdLancamento: Scalars["Int"];
	IdNfe?: Maybe<Scalars["Int"]>;
	IdParcelamento?: Maybe<Scalars["Int"]>;
	IdVenda?: Maybe<Scalars["Int"]>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	Juro?: Maybe<Scalars["Float"]>;
	LancaFuturo?: Maybe<Scalars["Int"]>;
	NumPrest?: Maybe<Scalars["Int"]>;
	NumTransacao?: Maybe<Scalars["String"]>;
};

export type FloatRange = {
	ValorFim?: InputMaybe<Scalars["Float"]>;
	ValorInicio?: InputMaybe<Scalars["Float"]>;
};

export type FormaPagamento = {
	__typename?: "FormaPagamento";
	DataAlteracao: Scalars["DateTime"];
	FormaVenda: FormasVenda;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Status: Scalars["Status"];
	Tipo: TipoFormaPagamento;
	TipoPagamentoNFe: TagPagamentoNFe;
};

export enum FormasVenda {
	Aprazo = "APRAZO",
	Avista = "AVISTA",
}

export type Fornecedor = {
	__typename?: "Fornecedor";
	Bairro?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	Complemento?: Maybe<Scalars["String"]>;
	CpfCnpj?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataNascimento?: Maybe<Scalars["Date"]>;
	Email?: Maybe<Scalars["String"]>;
	IdCidade?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdPais?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Observacao?: Maybe<Scalars["String"]>;
	RG?: Maybe<Scalars["String"]>;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	Site?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
};

export enum Genero_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Grades_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type Genero = {
	__typename?: "Genero";
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Status: Scalars["Status"];
};

export type Grade = {
	__typename?: "Grade";
	CodBarras?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	CodigoProduto: Scalars["String"];
	ColunaCodigo?: Maybe<Scalars["Int"]>;
	ColunaNome?: Maybe<Scalars["String"]>;
	CustoMedio: Scalars["Float"];
	DataAlteracao: Scalars["DateTime"];
	GradeFilial: Array<GradeFilial>;
	Id?: Maybe<Scalars["Int"]>;
	IdColuna?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdLinha: Scalars["Int"];
	IdProduto: Scalars["Int"];
	LinhaCodigo: Scalars["Int"];
	LinhaNome: Scalars["String"];
	Preco1: Scalars["Float"];
	Preco2: Scalars["Float"];
	Quantidade: Scalars["Float"];
	SkuEcommerce?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	ValorCusto: Scalars["Float"];
};

export type GradeGradeFilialArgs = {
	Filtro?: InputMaybe<InputFiltroGradeFilial>;
};

export type GradeCampo = {
	__typename?: "GradeCampo";
	Codigo: Scalars["Int"];
	DataAlteracao: Scalars["DateTime"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Status: Scalars["Status"];
	Tipo: TipoGradeCampo;
};

export type GradeEixo = {
	__typename?: "GradeEixo";
	ColunaCodigo?: Maybe<Scalars["Int"]>;
	ColunaNome?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	GradeTipoNome: Scalars["String"];
	GradeTipoNomeColuna?: Maybe<Scalars["String"]>;
	GradeTipoNomeLinha: Scalars["String"];
	Id: Scalars["Int"];
	IdColuna?: Maybe<Scalars["Int"]>;
	IdGradeTipo: Scalars["Int"];
	IdLinha: Scalars["Int"];
	LinhaCodigo: Scalars["Int"];
	LinhaNome: Scalars["String"];
};

export type GradeFilial = {
	__typename?: "GradeFilial";
	CustoMedio: Scalars["Float"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdGrade: Scalars["Int"];
	Preco1: Scalars["Float"];
	Preco2: Scalars["Float"];
	Quantidade: Scalars["Float"];
	SaldoFiliais: Scalars["Float"];
	ValorCusto: Scalars["Float"];
};

export enum GradePreco {
	PrecoGrade = "PRECO_GRADE",
	PrecoProduto = "PRECO_PRODUTO",
}

export type GradeTipo = {
	__typename?: "GradeTipo";
	DataAlteracao: Scalars["DateTime"];
	GradeEixo: Array<GradeEixo>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdProduto?: Maybe<Scalars["Int"]>;
	NomeColuna?: Maybe<Scalars["String"]>;
	NomeGrade: Scalars["String"];
	NomeLinha?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	Tipo: TipoGrade;
};

export enum Intermediadores_Venda_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type Importacao = {
	__typename?: "Importacao";
	AdicaoImportacao?: Maybe<Array<Maybe<AdicaoImportacao>>>;
	CNPJ?: Maybe<Scalars["String"]>;
	CodExportador: Scalars["String"];
	DataDesembaraco: Scalars["Date"];
	DataRegistro: Scalars["Date"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdItemNfe: Scalars["Int"];
	LocalDesembaraco: Scalars["String"];
	NumDocImportacao: Scalars["String"];
	TipoIntermedio: TipoIntermedio;
	TipoViaTransporte: TipoViaTransporte;
	UfDesembaraco: Scalars["String"];
	UfTerceiro?: Maybe<Scalars["String"]>;
	ValorAFRMM?: Maybe<Scalars["Float"]>;
};

export type ImpressaoCondicional = {
	__typename?: "ImpressaoCondicional";
	DataCondicional: Condicional;
	DataEmpresa: Empresa;
	DuasVias?: Maybe<Scalars["Boolean"]>;
};

export type ImpressaoVenda = {
	__typename?: "ImpressaoVenda";
	DataEmpresa: Empresa;
	DataVenda: Venda;
	DuasVias?: Maybe<Scalars["Boolean"]>;
};

export enum IndicadorEmitente {
	Propria = "PROPRIA",
	Terceiros = "TERCEIROS",
}

export enum IndicadorOperacao {
	EntradaAquisicao = "ENTRADA_AQUISICAO",
	SaidaPrestacao = "SAIDA_PRESTACAO",
}

export enum IndicadorProducao {
	EscalaNaoRelevante = "ESCALA_NAO_RELEVANTE",
	EscalaRelevante = "ESCALA_RELEVANTE",
	Nenhum = "NENHUM",
}

export type InputAlterarSenha = {
	CodigoAutenticacao: Scalars["String"];
	ConfirmarSenha: Scalars["String"];
	Login: Scalars["String"];
	Senha: Scalars["String"];
};

export type InputAtualizaGradeTipo = {
	AdicionaGradeCampo?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	RemoveGradeCampo?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	TipoAtualizacao: TipoAtualizaGrade;
};

export type InputBaixaCredito = {
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdContaFinanceiro: Scalars["Int"];
	IdValeCredito: Scalars["Int"];
	ValorRecebido: Scalars["Float"];
};

export type InputCadastrarAdicaoImportacao = {
	CodFabricante: Scalars["String"];
	NumAdicao?: InputMaybe<Scalars["Int"]>;
	NumDrawback?: InputMaybe<Scalars["String"]>;
	NumSequencialAdicao: Scalars["Int"];
	ValorDescDI?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarAdminCartao = {
	DiasRecebimentoCredito?: InputMaybe<Scalars["Int"]>;
	DiasRecebimentoDebito?: InputMaybe<Scalars["Int"]>;
	IdBandeira?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro: Scalars["Int"];
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
	TaxaCred1?: InputMaybe<Scalars["Float"]>;
	TaxaCred2?: InputMaybe<Scalars["Float"]>;
	TaxaCred3?: InputMaybe<Scalars["Float"]>;
	TaxaCred4?: InputMaybe<Scalars["Float"]>;
	TaxaCred5?: InputMaybe<Scalars["Float"]>;
	TaxaCred6?: InputMaybe<Scalars["Float"]>;
	TaxaCred7?: InputMaybe<Scalars["Float"]>;
	TaxaCred8?: InputMaybe<Scalars["Float"]>;
	TaxaCred9?: InputMaybe<Scalars["Float"]>;
	TaxaCred10?: InputMaybe<Scalars["Float"]>;
	TaxaCred11?: InputMaybe<Scalars["Float"]>;
	TaxaCred12?: InputMaybe<Scalars["Float"]>;
	TaxaDebito?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarArma = {
	Descricao: Scalars["String"];
	NumCano: Scalars["String"];
	NumSerie: Scalars["String"];
	TipoArma: TipoArmaFogo;
};

export type InputCadastrarCategoria = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarCategoriaCliente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarCest = {
	CodCest: Scalars["String"];
	CodNcm?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Item?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarCfop = {
	Codigo: Scalars["String"];
	CreditoIcmsCompra?: InputMaybe<Scalars["Status"]>;
	Descricao: Scalars["String"];
	IdOperacao: TipoCfopOperacao;
	MovimentaEstoque?: InputMaybe<Scalars["Status"]>;
	MovimentaFinanceiro?: InputMaybe<Scalars["Status"]>;
	PrecoPadrao?: InputMaybe<PrecoPadraoCfop>;
	Status?: InputMaybe<Scalars["Status"]>;
	TipoNFe: TipoNFe;
};

export type InputCadastrarCliente = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cargo?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	ContatosCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarContatoPessoa>>>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<Scalars["Date"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	EnderecoComercial?: InputMaybe<Scalars["String"]>;
	EstadoCivil?: InputMaybe<EstadoCivil>;
	FoneComercial?: InputMaybe<Scalars["String"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdEcommerce?: InputMaybe<Scalars["Int"]>;
	IdGenero?: InputMaybe<Scalars["Int"]>;
	IdPaiDependente?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdTipoDependente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	LimiteCredito?: InputMaybe<Scalars["Float"]>;
	LocalTrabalho?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	PermitirVenda?: InputMaybe<Scalars["Resposta"]>;
	RG?: InputMaybe<Scalars["String"]>;
	ReferenciasComerciaisCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarReferenciaComercial>>>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	RendaMensal?: InputMaybe<Scalars["Float"]>;
	SpcSerasa?: InputMaybe<Scalars["Resposta"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarClientePessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cargo?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	ContatosCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarContatoPessoa>>>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<Scalars["Date"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	EnderecoComercial?: InputMaybe<Scalars["String"]>;
	EstadoCivil?: InputMaybe<EstadoCivil>;
	FoneComercial?: InputMaybe<Scalars["String"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdEcommerce?: InputMaybe<Scalars["Int"]>;
	IdGenero?: InputMaybe<Scalars["Int"]>;
	IdPaiDependente?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	IdTipoDependente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	LimiteCredito?: InputMaybe<Scalars["Float"]>;
	LocalTrabalho?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	PermitirVenda?: InputMaybe<Scalars["Resposta"]>;
	RG?: InputMaybe<Scalars["String"]>;
	ReferenciasComerciaisCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarReferenciaComercial>>>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	RendaMensal?: InputMaybe<Scalars["Float"]>;
	SpcSerasa?: InputMaybe<Scalars["Resposta"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarColaborador = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cargo?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<Scalars["Date"]>;
	DataDemissao?: InputMaybe<Scalars["Date"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdDepartamento?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Login?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	NivelFuncao?: InputMaybe<Scalars["Int"]>;
	Nome: Scalars["String"];
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	Permissoes?: InputMaybe<Array<InputMaybe<InputPermissaoColaborador>>>;
	Pis?: InputMaybe<Scalars["String"]>;
	PorcentComis?: InputMaybe<Scalars["Float"]>;
	PorcentDescMax?: InputMaybe<Scalars["Float"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Senha?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarColaboradorPessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cargo?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<Scalars["Date"]>;
	DataDemissao?: InputMaybe<Scalars["Date"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdDepartamento?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Login?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	NivelFuncao?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	Permissoes?: InputMaybe<Array<InputMaybe<InputPermissaoColaborador>>>;
	Pis?: InputMaybe<Scalars["String"]>;
	PorcentComis?: InputMaybe<Scalars["Float"]>;
	PorcentDescMax?: InputMaybe<Scalars["Float"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Senha?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarColecao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarComissaoFormaPgto = {
	IdColaborador?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto: Scalars["Int"];
	Status: Scalars["Status"];
	Valor: Scalars["Float"];
};

export type InputCadastrarCondicional = {
	Data: Scalars["Date"];
	DataPrevRetorno?: InputMaybe<Scalars["Date"]>;
	IdCliente: Scalars["Int"];
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	ItensCadastrar: Array<InputCadastrarItemCondicional>;
	Observacoes?: InputMaybe<Scalars["String"]>;
	Status?: StatusCondicional;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarConfiguracaoEmpresa = {
	IdConfig: Configuracoes;
	Valor: Scalars["String"];
};

export type InputCadastrarContaFinanceira = {
	DvAgencia?: InputMaybe<Scalars["String"]>;
	DvConta?: InputMaybe<Scalars["String"]>;
	Email?: InputMaybe<Scalars["String"]>;
	Gerente?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeTitular?: InputMaybe<Scalars["String"]>;
	NumAgencia?: InputMaybe<Scalars["String"]>;
	NumConta?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
	Tipo: TipoContaFinanceira;
};

export type InputCadastrarContador = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	Cpf?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Crc?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarContadorPessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	Cpf?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Crc?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarContatoPessoa = {
	Contato: Scalars["String"];
	Descricao?: InputMaybe<Scalars["String"]>;
	Tipo: TipoContato;
};

export type InputCadastrarDefinicao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	MateriaPrima?: InputMaybe<Scalars["Status"]>;
	Nome: Scalars["String"];
	Producao?: InputMaybe<Scalars["Status"]>;
	Servico?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	UsoConsumo?: InputMaybe<Scalars["Status"]>;
	Venda?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarDepartamento = {
	Nome: Scalars["String"];
	Producao?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tecnico?: InputMaybe<Scalars["Status"]>;
	Vendas?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarDevolucao = {
	DadosPrestacaoDevolucao?: InputMaybe<Array<InputCadastrarPrestacaoDevolucao>>;
	DataHora: Scalars["DateTime"];
	IdEmpresa: Scalars["Int"];
	IdNFe?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	ItemDevolucaoCadastrar: Array<InputCadastrarItemDevolucao>;
	Tipo: TipoTransacao;
	ValorTotal?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarEmpresa = {
	ConfiguracoesCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarConfiguracaoEmpresa>>>;
	CpfCnpj: Scalars["String"];
	EmailPrincipal: Scalars["String"];
	NumNFe?: InputMaybe<Scalars["Int"]>;
	NumUsuarios?: InputMaybe<Scalars["Int"]>;
	PlanoContratado?: InputMaybe<PlanoContratado>;
	ProdutoEmpresa?: InputMaybe<ProdutoEmpresa>;
	Senha?: InputMaybe<Scalars["String"]>;
	Sistema?: InputMaybe<SistemaEmpresa>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarEnderecoAdicional = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	IdCidade: Scalars["Int"];
	IdPessoa: Scalars["Int"];
	Logradouro?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo: TipoEndereco;
};

export type InputCadastrarEventoNfe = {
	CorrelationId: Scalars["String"];
	DeletarFinanceiro?: InputMaybe<Scalars["Boolean"]>;
	IdNFe?: InputMaybe<Scalars["Int"]>;
	Modelo?: InputMaybe<Modelo>;
	NumNF?: InputMaybe<Scalars["Int"]>;
	Serie?: InputMaybe<Scalars["Int"]>;
	Texto: Scalars["String"];
	TipoEvento: TipoEventoNFe;
};

export type InputCadastrarExportacao = {
	ChaveNFe: Scalars["String"];
	NumeroDrawback?: InputMaybe<Scalars["String"]>;
	NumeroRegistroExportacao: Scalars["String"];
	QuantidadeExportacao: Scalars["Float"];
};

export type InputCadastrarFinanceiro = {
	CodigoFinanceiro?: InputMaybe<Scalars["String"]>;
	Desconto?: InputMaybe<Scalars["Float"]>;
	Historico?: InputMaybe<Scalars["String"]>;
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	IdLancamento: Scalars["Int"];
	IdNfe?: InputMaybe<Scalars["Int"]>;
	IdParcelamento?: InputMaybe<Scalars["Int"]>;
	IdVenda?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Juro?: InputMaybe<Scalars["Float"]>;
	LancaFuturo?: InputMaybe<Scalars["Int"]>;
	NumPrest?: InputMaybe<Scalars["Int"]>;
	NumTransacao?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarFinanceiroDevolucao = {
	Itens: Array<InputCadastrarItemDevolucao>;
	Prestacoes?: InputMaybe<Array<InputCadastrarPrestacaoDevolucao>>;
};

export type InputCadastrarFormaPagamento = {
	FormaVenda: FormasVenda;
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo: TipoFormaPagamento;
	TipoPagamentoNFe: TagPagamentoNFe;
};

export type InputCadastrarFornecedor = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Site?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarFornecedorPessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Site?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarGenero = {
	Descricao: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarGrade = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdLinha: Scalars["Int"];
	Preco1?: InputMaybe<Scalars["Float"]>;
	Preco2?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarGradeCampo = {
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo: TipoGradeCampo;
};

export type InputCadastrarGradeEixo = {
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGradeTipo: Scalars["Int"];
	IdLinha: Scalars["Int"];
};

export type InputCadastrarGradeFilial = {
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	IdGrade: Scalars["Int"];
	Preco1?: InputMaybe<Scalars["Float"]>;
	Preco2?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarGradeTipo = {
	CadastraGradeEixo: Array<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	NomeColuna?: InputMaybe<Scalars["String"]>;
	NomeGrade: Scalars["String"];
	NomeLinha?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo: TipoGrade;
};

export type InputCadastrarImportacao = {
	CNPJ?: InputMaybe<Scalars["String"]>;
	CadastraAdicaoImportacao?: InputMaybe<Array<InputCadastrarAdicaoImportacao>>;
	CodExportador: Scalars["String"];
	DataDesembaraco: Scalars["Date"];
	DataRegistro: Scalars["Date"];
	LocalDesembaraco: Scalars["String"];
	NumDocImportacao: Scalars["String"];
	TipoIntermedio: TipoIntermedio;
	TipoViaTransporte: TipoViaTransporte;
	UfDesembaraco: Scalars["String"];
	UfTerceiro?: InputMaybe<Scalars["String"]>;
	ValorAFRMM?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarIntermediador = {
	Cnpj?: InputMaybe<Scalars["String"]>;
	Identificador?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarItemCondicional = {
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	IdUnidade: Scalars["Int"];
	Quantidade: Scalars["Float"];
	Status?: InputMaybe<StatusItemCondicional>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorUnitario: Scalars["Float"];
	ValorUnitarioPrazo?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarItemDevolucao = {
	IdItemVenda: Scalars["Int"];
	Quantidade: Scalars["Float"];
};

export type InputCadastrarItemGrade = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	GradePreco1?: InputMaybe<Scalars["Float"]>;
	GradePreco2?: InputMaybe<Scalars["Float"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Valor?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarItemNfe = {
	AliqIcmsDif?: InputMaybe<Scalars["Float"]>;
	CadastraArma?: InputMaybe<Array<InputMaybe<InputCadastrarArma>>>;
	CadastraExportacao?: InputMaybe<Array<InputMaybe<InputCadastrarExportacao>>>;
	CadastraImportacao?: InputMaybe<Array<InputMaybe<InputCadastrarImportacao>>>;
	CadastraItemGrade?: InputMaybe<InputCadastrarItemGrade>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodBenef?: InputMaybe<Scalars["String"]>;
	CodEnqlIpi?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	CodListServ?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Descricao: Scalars["String"];
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	DetalhamentoItem?: InputMaybe<Scalars["String"]>;
	FatorConversao?: InputMaybe<Scalars["Float"]>;
	IdCest?: InputMaybe<Scalars["Int"]>;
	IdCfop: Scalars["Int"];
	IdCstCofins?: InputMaybe<Scalars["Int"]>;
	IdCstIcmsCompra?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPis?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	IdSituaTribut: Scalars["Int"];
	IdUnidade: Scalars["Int"];
	IdUnidadeCompra?: InputMaybe<Scalars["Int"]>;
	MotDesIcms?: InputMaybe<Scalars["Int"]>;
	NumItemPedido?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PedidoDeCompra?: InputMaybe<Scalars["String"]>;
	PorcentCofins?: InputMaybe<Scalars["Float"]>;
	PorcentCredSN?: InputMaybe<Scalars["Float"]>;
	PorcentDevol?: InputMaybe<Scalars["Float"]>;
	PorcentFcp?: InputMaybe<Scalars["Float"]>;
	PorcentFcpSt?: InputMaybe<Scalars["Float"]>;
	PorcentFcpStRet?: InputMaybe<Scalars["Float"]>;
	PorcentFcpUfDest?: InputMaybe<Scalars["Float"]>;
	PorcentIcms?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsEfet?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsInter?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsSt?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	PorcentIpi?: InputMaybe<Scalars["Float"]>;
	PorcentMvaSt?: InputMaybe<Scalars["Float"]>;
	PorcentPis?: InputMaybe<Scalars["Float"]>;
	PorcentRedBC?: InputMaybe<Scalars["Float"]>;
	PorcentRedBCEfet?: InputMaybe<Scalars["Float"]>;
	PorcentSt?: InputMaybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	QuantidadeBCCofins?: InputMaybe<Scalars["Float"]>;
	QuantidadeBCPis?: InputMaybe<Scalars["Float"]>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorBCCofins?: InputMaybe<Scalars["Float"]>;
	ValorBCEfet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcp?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorBCIcms?: InputMaybe<Scalars["Float"]>;
	ValorBCIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorBCIpi?: InputMaybe<Scalars["Float"]>;
	ValorBCPis?: InputMaybe<Scalars["Float"]>;
	ValorBCStRet?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorCredIcmsSN?: InputMaybe<Scalars["Float"]>;
	ValorCustoMedioUnitario: Scalars["Float"];
	ValorCustoUnitario: Scalars["Float"];
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFcp?: InputMaybe<Scalars["Float"]>;
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsDeson?: InputMaybe<Scalars["Float"]>;
	ValorIcmsEfet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsStRet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: InputMaybe<Scalars["Float"]>;
	ValorIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorUnitario: Scalars["Float"];
};

export type InputCadastrarItemNfeCompra = {
	AliqIcmsDif?: InputMaybe<Scalars["Float"]>;
	CadastraItemGrade?: InputMaybe<Array<InputCadastrarItemGrade>>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodBenef?: InputMaybe<Scalars["String"]>;
	CodEnqlIpi?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	CodListServ?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CodigoCfop: Scalars["String"];
	Descricao: Scalars["String"];
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	DetalhamentoItem?: InputMaybe<Scalars["String"]>;
	FatorConversao?: InputMaybe<Scalars["Float"]>;
	IdCest?: InputMaybe<Scalars["Int"]>;
	IdCstCofins?: InputMaybe<Scalars["Int"]>;
	IdCstIcmsCompra?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPis?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IdUnidade: Scalars["Int"];
	IdUnidadeCompra?: InputMaybe<Scalars["Int"]>;
	MargemLucro?: InputMaybe<Scalars["Float"]>;
	MotDesIcms?: InputMaybe<Scalars["Int"]>;
	NumItemPedido?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PedidoDeCompra?: InputMaybe<Scalars["String"]>;
	PorcentCofins?: InputMaybe<Scalars["Float"]>;
	PorcentCredSN?: InputMaybe<Scalars["Float"]>;
	PorcentDevol?: InputMaybe<Scalars["Float"]>;
	PorcentFcp?: InputMaybe<Scalars["Float"]>;
	PorcentFcpSt?: InputMaybe<Scalars["Float"]>;
	PorcentFcpStRet?: InputMaybe<Scalars["Float"]>;
	PorcentFcpUfDest?: InputMaybe<Scalars["Float"]>;
	PorcentIcms?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsEfet?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsInter?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsSt?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	PorcentIpi?: InputMaybe<Scalars["Float"]>;
	PorcentMvaSt?: InputMaybe<Scalars["Float"]>;
	PorcentPis?: InputMaybe<Scalars["Float"]>;
	PorcentRedBC?: InputMaybe<Scalars["Float"]>;
	PorcentRedBCEfet?: InputMaybe<Scalars["Float"]>;
	PorcentSt?: InputMaybe<Scalars["Float"]>;
	PrecoVenda?: InputMaybe<Scalars["Float"]>;
	PrecoVenda2?: InputMaybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	QuantidadeBCCofins?: InputMaybe<Scalars["Float"]>;
	QuantidadeBCPis?: InputMaybe<Scalars["Float"]>;
	QuantidadeCompra?: InputMaybe<Scalars["Float"]>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorBCCofins?: InputMaybe<Scalars["Float"]>;
	ValorBCEfet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcp?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorBCIcms?: InputMaybe<Scalars["Float"]>;
	ValorBCIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorBCIpi?: InputMaybe<Scalars["Float"]>;
	ValorBCPis?: InputMaybe<Scalars["Float"]>;
	ValorBCStRet?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorCredIcmsSN?: InputMaybe<Scalars["Float"]>;
	ValorCustoMedioUnitario: Scalars["Float"];
	ValorCustoUnitario: Scalars["Float"];
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFcp?: InputMaybe<Scalars["Float"]>;
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsDeson?: InputMaybe<Scalars["Float"]>;
	ValorIcmsEfet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsStRet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: InputMaybe<Scalars["Float"]>;
	ValorIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorUnitario: Scalars["Float"];
};

export type InputCadastrarItemVenda = {
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	Quantidade: Scalars["Float"];
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorUnitario: Scalars["Float"];
};

export type InputCadastrarLancamento = {
	Data: Scalars["Date"];
	Hora: Scalars["Time"];
	IdLancamento?: InputMaybe<Scalars["Int"]>;
	IdNivel: Scalars["Int"];
	IdOperacao: TipoOperacao;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	ValorTotal: Scalars["Float"];
};

export type InputCadastrarMarca = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarMeta = {
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	NomeMeta: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
	TipoBonificacao: TipoBonificacao;
	ValorBonificacao: Scalars["Float"];
	ValorMeta: Scalars["Float"];
};

export type InputCadastrarModuloEmpresa = {
	IdEmpresa: Scalars["Int"];
	IdModulo: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarMovimentoEstoque = {
	Data: Scalars["DateTime"];
	Descricao?: InputMaybe<Scalars["String"]>;
	Grade?: InputMaybe<Array<InputGradeMovimento>>;
	IdProduto: Scalars["Int"];
	Origem: OrigemMovimento;
	Quantidade: Scalars["Float"];
	Valor: Scalars["Float"];
};

export type InputCadastrarNcm = {
	AliqEstadual?: InputMaybe<Scalars["Float"]>;
	AliqImportacao?: InputMaybe<Scalars["Float"]>;
	AliqMunicipal?: InputMaybe<Scalars["Float"]>;
	AliqNacional?: InputMaybe<Scalars["Float"]>;
	CodNCM: Scalars["String"];
	Descricao: Scalars["String"];
	ExtIpi?: InputMaybe<Scalars["String"]>;
	IdEstado: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
	Tabela?: InputMaybe<TabelaNcm>;
};

export type InputCadastrarNcmRegraTributacao = {
	IdNcm: Scalars["Int"];
};

export type InputCadastrarNfe = {
	DataCompet?: InputMaybe<Scalars["Date"]>;
	DataHoraEmissao: Scalars["DateTime"];
	DataHoraSaiEnt: Scalars["DateTime"];
	Frete?: InputMaybe<TipoFrete>;
	IdCfop: Scalars["Int"];
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdEnderecoEntrega?: InputMaybe<Scalars["Int"]>;
	IdIntermediador?: InputMaybe<Scalars["Int"]>;
	IdNfeRef?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVeiculo?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	ItemNfeCadastrar: Array<InputCadastrarItemNfe>;
	ModCliente?: InputMaybe<ModalidadeCliente>;
	Modelo: Modelo;
	NatOperacao?: InputMaybe<Scalars["String"]>;
	NumNF?: InputMaybe<Scalars["Int"]>;
	ObservacaoComplementar?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoNfeCadastrar: Array<InputCadastrarPrestacaoNfe>;
	RefNfeCadastrar?: InputMaybe<Array<InputCadastrarReferenciaNfe>>;
	Serie?: InputMaybe<Scalars["Int"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorOutro?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
	VendaNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarVendaNfe>>>;
	VolumeNfeCadastrar?: InputMaybe<Array<InputCadastrarVolumeNfe>>;
};

export type InputCadastrarNfeCompra = {
	DataHoraEmissao: Scalars["DateTime"];
	DataHoraSaiEnt: Scalars["DateTime"];
	IdCfop: Scalars["Int"];
	IdPessoa: Scalars["Int"];
	ItemNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemNfeCompra>>>;
	ModCliente?: InputMaybe<ModalidadeCliente>;
	NatOperacao?: InputMaybe<Scalars["String"]>;
	NumNF: Scalars["Int"];
	ObservacaoComplementar?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PrestacaoNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoNfe>>>;
	Serie: Scalars["Int"];
	ValorBcIcms?: InputMaybe<Scalars["Float"]>;
	ValorBcIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorCofinsSt?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsUfDest?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorOutro?: InputMaybe<Scalars["Float"]>;
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorPisSt?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarNivel = {
	Comportamento: ComportamentoNivel;
	Descricao: Scalars["String"];
	IdDefinicao?: InputMaybe<TipoNivelDefinicao>;
	IdNivel?: InputMaybe<Scalars["Int"]>;
	IdNivelContra?: InputMaybe<Scalars["Int"]>;
	Ordem: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarNotificacao = {
	Conteudo: Scalars["String"];
	IdEmpresa: Scalars["Int"];
	IdsUsuarios?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	LocalExibicao: LocalExibicao;
	OutrasInformacoes?: InputMaybe<Scalars["String"]>;
	TipoNotificacao: TipoNotificacao;
	Titulo: Scalars["String"];
};

export type InputCadastrarParcelamento = {
	DataPgto?: InputMaybe<Scalars["Date"]>;
	DataVencimento: Scalars["Date"];
	DescontoPgto?: InputMaybe<Scalars["Float"]>;
	IdParcelamentoReferencia?: InputMaybe<Scalars["Int"]>;
	PgtoParcial?: InputMaybe<Scalars["Float"]>;
	Status?: InputMaybe<StatusParcelamento>;
	TaxaJuro?: InputMaybe<Scalars["Float"]>;
	ValorJuroPgto?: InputMaybe<Scalars["Float"]>;
	ValorParcela: Scalars["Float"];
	ValorTaxaCartao?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarPdv = {
	IdContaFinanceira: Scalars["Int"];
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarPedidoVenda = {
	Conferido?: InputMaybe<Scalars["Resposta"]>;
	DataEntrega?: InputMaybe<Scalars["Date"]>;
	DataHora: Scalars["DateTime"];
	Frete?: InputMaybe<TipoFrete>;
	IdCliente: Scalars["Int"];
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	ItemPedidoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemVenda>>>;
	Observacoes?: InputMaybe<Scalars["String"]>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoPedidoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoVenda>>>;
	Status: Scalars["Int"];
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorJuro?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarPessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarPrestacaoDevolucao = {
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	IdParcela?: InputMaybe<Scalars["Int"]>;
	TipoOperacao: TipoOperacaoDev;
	ValorAbatido: Scalars["Float"];
};

export type InputCadastrarPrestacaoNfe = {
	DataVencimento: Scalars["Date"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto: Scalars["Int"];
	NumDocumento?: InputMaybe<Scalars["String"]>;
	NumLancamento: Scalars["Int"];
	TotalLancamento: Scalars["Float"];
	ValorParcela: Scalars["Float"];
};

export type InputCadastrarPrestacaoVenda = {
	DataVencimento: Scalars["DateTime"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto: Scalars["Int"];
	NumDocumento?: InputMaybe<Scalars["String"]>;
	NumLancamento: Scalars["Int"];
	TotalLancamento: Scalars["Float"];
	ValorParcela: Scalars["Float"];
};

export type InputCadastrarProduto = {
	AtivoEcommerce?: InputMaybe<Scalars["Status"]>;
	AtualizaGradeTipo?: InputMaybe<InputAtualizaGradeTipo>;
	CestCodigo?: InputMaybe<Scalars["String"]>;
	CfopCodigo?: InputMaybe<Scalars["String"]>;
	CodAnp?: InputMaybe<Scalars["String"]>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodBenef?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	CodListServ?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CodigoSecundario?: InputMaybe<Scalars["String"]>;
	CofinsAliq?: InputMaybe<Scalars["Float"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	DescAnp?: InputMaybe<Scalars["String"]>;
	Desconto?: InputMaybe<Scalars["Float"]>;
	Descricao: Scalars["String"];
	FatorConversao?: InputMaybe<Scalars["Float"]>;
	FatorConversaoTribut?: InputMaybe<Scalars["Float"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	GradePreco?: InputMaybe<GradePreco>;
	GradesCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarGrade>>>;
	IIAliq?: InputMaybe<Scalars["Float"]>;
	IPIAliq?: InputMaybe<Scalars["Float"]>;
	IcmsAliq?: InputMaybe<Scalars["Float"]>;
	IcmsMva?: InputMaybe<Scalars["Float"]>;
	IcmsReducao?: InputMaybe<Scalars["Float"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdColecao?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPisCofins?: InputMaybe<Scalars["Int"]>;
	IdDefinicao: Scalars["Int"];
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdGradeTipo?: InputMaybe<Scalars["Int"]>;
	IdMarca?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IdSubcategoria?: InputMaybe<Scalars["Int"]>;
	IdUnidade: Scalars["Int"];
	IdUnidadeCompra?: InputMaybe<Scalars["Int"]>;
	IdUnidadeTributaria?: InputMaybe<Scalars["Int"]>;
	IndEscala?: InputMaybe<IndicadorProducao>;
	IpiClasse?: InputMaybe<Scalars["String"]>;
	IpiCnpjProd?: InputMaybe<Scalars["String"]>;
	IpiCodEnq?: InputMaybe<Scalars["String"]>;
	IpiCodSelo?: InputMaybe<Scalars["String"]>;
	IpiQtdSelo?: InputMaybe<Scalars["Int"]>;
	MargemLucro?: InputMaybe<Scalars["Float"]>;
	NcmCodigo?: InputMaybe<Scalars["String"]>;
	Origem?: InputMaybe<Scalars["Int"]>;
	Peso?: InputMaybe<Scalars["Float"]>;
	PisAliq?: InputMaybe<Scalars["Float"]>;
	PorcentDifIcms?: InputMaybe<Scalars["Float"]>;
	ProdutosKitCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarProdutoKit>>>;
	QtdMinima?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaNormal?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaSimples?: InputMaybe<Scalars["Float"]>;
	SaldoIniQtd?: InputMaybe<Scalars["Float"]>;
	SkuEcommerce?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
	ValorVenda?: InputMaybe<Scalars["Float"]>;
	ValorVendaAlt?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarProdutoFilial = {
	CofinsAliq?: InputMaybe<Scalars["Float"]>;
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	Desconto?: InputMaybe<Scalars["Float"]>;
	IIAliq?: InputMaybe<Scalars["Float"]>;
	IPIAliq?: InputMaybe<Scalars["Float"]>;
	IcmsAliq?: InputMaybe<Scalars["Float"]>;
	IcmsMva?: InputMaybe<Scalars["Float"]>;
	IcmsReducao?: InputMaybe<Scalars["Float"]>;
	IdCest?: InputMaybe<Scalars["Int"]>;
	IdCfop?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPisCofins?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IpiClasse?: InputMaybe<Scalars["String"]>;
	IpiCodEnq?: InputMaybe<Scalars["String"]>;
	IpiCodSelo?: InputMaybe<Scalars["String"]>;
	IpiQtdSelo?: InputMaybe<Scalars["Int"]>;
	MargemLucro?: InputMaybe<Scalars["Float"]>;
	PisAliq?: InputMaybe<Scalars["Float"]>;
	PorcentDifIcms?: InputMaybe<Scalars["Float"]>;
	QtdMinima?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaNormal?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaSimples?: InputMaybe<Scalars["Float"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
	ValorVenda?: InputMaybe<Scalars["Float"]>;
	ValorVendaAlt?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarProdutoFornecedor = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodFornecedor: Scalars["String"];
	IdFornecedor: Scalars["Int"];
	IdProduto: Scalars["Int"];
};

export type InputCadastrarProdutoKit = {
	IdProduto: Scalars["Int"];
	Quantidade: Scalars["Float"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarReferenciaComercial = {
	Contato?: InputMaybe<Scalars["String"]>;
	Descricao: Scalars["String"];
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarReferenciaNFe1A = {
	Cnpj: Scalars["String"];
	DataEmissao: Scalars["Date"];
	IdEstado: Scalars["Int"];
	Modelo?: InputMaybe<Modelo1A>;
	NumNf: Scalars["Int"];
	Serie: Scalars["Int"];
};

export type InputCadastrarReferenciaNfe = {
	ChaveAcesso?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<Scalars["Date"]>;
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdNfce?: InputMaybe<Scalars["Int"]>;
	IdNfeRef?: InputMaybe<Scalars["Int"]>;
	IndEmitente?: InputMaybe<IndicadorEmitente>;
	IndOperacao?: InputMaybe<IndicadorOperacao>;
	NF1A?: InputMaybe<InputCadastrarReferenciaNFe1A>;
	NumNf?: InputMaybe<Scalars["Int"]>;
	Serie?: InputMaybe<Scalars["Int"]>;
	Tipo: TipoReferencia;
};

export type InputCadastrarRegraTributacao = {
	AliquotaCofinsAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaFcpAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaIcmsAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaIpiAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaMvaAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaPisAcao?: InputMaybe<Scalars["Float"]>;
	CadastraNCM?: InputMaybe<Array<InputMaybe<InputCadastrarNcmRegraTributacao>>>;
	DestacaDifalAcao?: InputMaybe<RespostaEnum>;
	IdCestAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopCondicao?: InputMaybe<Scalars["Int"]>;
	IdCstCofinsAcao?: InputMaybe<Scalars["Int"]>;
	IdCstIpiAcao?: InputMaybe<Scalars["Int"]>;
	IdCstPisAcao?: InputMaybe<Scalars["Int"]>;
	IdPessoaCondicao?: InputMaybe<Scalars["Int"]>;
	IdSituaTributAcao?: InputMaybe<Scalars["Int"]>;
	IdUFCondicao?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	ObservacaoNFAcao?: InputMaybe<Scalars["String"]>;
	ReducaoIcmsAcao?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaAcao?: InputMaybe<Scalars["Float"]>;
	RegimeTributarioCondicao?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMSCondicao?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo: TipoRegra;
	TipoConsumidorCondicao?: InputMaybe<ModalidadeCliente>;
	TipoUFCondicao?: InputMaybe<TipoUfDestino>;
};

export type InputCadastrarStatusVenda = {
	Descricao: Scalars["String"];
	Estagio: Estagio;
	Ordem: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarSubcategoria = {
	Descricao?: InputMaybe<Scalars["String"]>;
	IdCategoria: Scalars["Int"];
	Nome: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarTipoDependente = {
	Descricao: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarTokenTerceiro = {
	DataExpiracao: Scalars["DateTime"];
	JsonData?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status?: InputMaybe<StatusToken>;
};

export type InputCadastrarTransportadora = {
	Bairro?: InputMaybe<Scalars["String"]>;
	CadastraVeiculos?: InputMaybe<Array<InputCadastrarVeiculo>>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarTransportadoraPessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	CadastraVeiculos?: InputMaybe<Array<InputCadastrarVeiculo>>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputCadastrarUnidade = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Simbolo: Scalars["String"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarVeiculo = {
	IdEstado: Scalars["Int"];
	IdEstadoReboque?: InputMaybe<Scalars["Int"]>;
	Placa: Scalars["String"];
	ReboquePlaca?: InputMaybe<Scalars["String"]>;
	ReboqueRntc?: InputMaybe<Scalars["String"]>;
	Rntc?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputCadastrarVenda = {
	Conferido?: InputMaybe<Scalars["Resposta"]>;
	DataEntrega?: InputMaybe<Scalars["Date"]>;
	DataHora: Scalars["DateTime"];
	Frete?: InputMaybe<TipoFrete>;
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdIntermediador?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	ItemVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemVenda>>>;
	Observacoes?: InputMaybe<Scalars["String"]>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoVenda>>>;
	Status: Scalars["Int"];
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorJuro?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarVendaNfe = {
	IdVenda: Scalars["Int"];
};

export type InputCadastrarVendaPdv = {
	CondicionalVinculado?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	DataHora: Scalars["DateTime"];
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	ItemVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemVenda>>>;
	PedidoVinculado?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoVenda>>>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorJuro?: InputMaybe<Scalars["Float"]>;
};

export type InputCadastrarVolumeNfe = {
	Especie?: InputMaybe<Scalars["String"]>;
	Marca?: InputMaybe<Scalars["String"]>;
	Numeracao?: InputMaybe<Scalars["String"]>;
	PesoBruto?: InputMaybe<Scalars["Float"]>;
	PesoLiquido?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
};

export type InputCalculaImpostoNfe = {
	AtualizaIds?: InputMaybe<Scalars["Boolean"]>;
	DadosVenda?: InputMaybe<Scalars["Boolean"]>;
	IdCfop: Scalars["Int"];
	IdEnderecoEntrega?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	Itens: Array<InputCalculaImpostoNfeItens>;
	ModCliente?: InputMaybe<ModalidadeCliente>;
	Origem: OrigemNFe;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	TipoNF?: InputMaybe<TipoNFe>;
	ValorBCIss?: InputMaybe<Scalars["Float"]>;
	ValorBcIcms?: InputMaybe<Scalars["Float"]>;
	ValorBcIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: Scalars["Float"];
	ValorFrete?: Scalars["Float"];
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsDesonerado?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorIss?: InputMaybe<Scalars["Float"]>;
	ValorOutro?: Scalars["Float"];
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: Scalars["Float"];
};

export type InputCalculaImpostoNfeItens = {
	DetalhamentoItem?: InputMaybe<Scalars["String"]>;
	IdCfop: Scalars["Int"];
	IdCstCofins?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPis?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdOperacaoCfop?: InputMaybe<TipoCfopOperacao>;
	IdProduto: Scalars["Int"];
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	Index: Scalars["Int"];
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PorcentFcpStRet?: InputMaybe<Scalars["Float"]>;
	PorcentIcms?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsEfet?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsSt?: InputMaybe<Scalars["Float"]>;
	PorcentIpi?: InputMaybe<Scalars["Float"]>;
	PorcentRedBCEfet?: InputMaybe<Scalars["Float"]>;
	PorcentSt?: InputMaybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	ValorAcrescimo?: Scalars["Float"];
	ValorBCEfet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorBCIcms?: InputMaybe<Scalars["Float"]>;
	ValorBCIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorBCIpi?: InputMaybe<Scalars["Float"]>;
	ValorBCStRet?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: Scalars["Float"];
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsDeson?: InputMaybe<Scalars["Float"]>;
	ValorIcmsEfet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsStRet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorUnitario: Scalars["Float"];
};

export type InputConverterCondicionalEmVenda = {
	IdsCondicionais: Array<Scalars["Int"]>;
};

export type InputDadosSped = {
	Atividade: AtividadeSped;
	Dados: Array<InputMaybe<DadosSped>>;
	FinalidadeArquivo: FinalidadeArquivoSped;
	Perfil: PerfilSped;
};

export type InputDadosTransmissaoNFe = {
	CorrelationId: Array<Scalars["String"]>;
	Operacao: OpcaoTransmissaoNFe;
};

export type InputDeletaLancamento = {
	IdLancamento: Scalars["Int"];
};

export type InputDevolucaoNfeCompra = {
	DestacaIcms?: InputMaybe<Scalars["Boolean"]>;
	DestacaIcmsSt?: InputMaybe<Scalars["Boolean"]>;
	DestacaIpi?: InputMaybe<Scalars["Boolean"]>;
	IdsNfe: Array<Scalars["Int"]>;
	ItemNfeDevolucao?: InputMaybe<Array<InputMaybe<InputItemNfeDevolucaoCompra>>>;
};

export type InputEditarAdicaoImportacao = {
	CodFabricante?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	NumAdicao?: InputMaybe<Scalars["Int"]>;
	NumDrawback?: InputMaybe<Scalars["String"]>;
	NumSequencialAdicao?: InputMaybe<Scalars["Int"]>;
	ValorDescDI?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarAdminCartao = {
	DiasRecebimentoCredito?: InputMaybe<Scalars["Int"]>;
	DiasRecebimentoDebito?: InputMaybe<Scalars["Int"]>;
	Id: Scalars["Int"];
	IdBandeira?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	TaxaCred1?: InputMaybe<Scalars["Float"]>;
	TaxaCred2?: InputMaybe<Scalars["Float"]>;
	TaxaCred3?: InputMaybe<Scalars["Float"]>;
	TaxaCred4?: InputMaybe<Scalars["Float"]>;
	TaxaCred5?: InputMaybe<Scalars["Float"]>;
	TaxaCred6?: InputMaybe<Scalars["Float"]>;
	TaxaCred7?: InputMaybe<Scalars["Float"]>;
	TaxaCred8?: InputMaybe<Scalars["Float"]>;
	TaxaCred9?: InputMaybe<Scalars["Float"]>;
	TaxaCred10?: InputMaybe<Scalars["Float"]>;
	TaxaCred11?: InputMaybe<Scalars["Float"]>;
	TaxaCred12?: InputMaybe<Scalars["Float"]>;
	TaxaDebito?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarAliquotaIcms = {
	Aliquota: Scalars["Float"];
	Id: Scalars["Int"];
};

export type InputEditarArma = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	NumCano?: InputMaybe<Scalars["String"]>;
	NumSerie?: InputMaybe<Scalars["String"]>;
	TipoArma?: InputMaybe<TipoArmaFogo>;
};

export type InputEditarCategoria = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarCategoriaCliente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarCest = {
	CodCest?: InputMaybe<Scalars["String"]>;
	CodNcm?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Item?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarCfop = {
	Codigo?: InputMaybe<Scalars["String"]>;
	CreditoIcmsCompra?: InputMaybe<Scalars["Status"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdOperacao?: InputMaybe<TipoCfopOperacao>;
	MovimentaEstoque?: InputMaybe<Scalars["Status"]>;
	MovimentaFinanceiro?: InputMaybe<Scalars["Status"]>;
	PrecoPadrao?: InputMaybe<PrecoPadraoCfop>;
	Status?: InputMaybe<Scalars["Status"]>;
	TipoNFe?: InputMaybe<TipoNFe>;
};

export type InputEditarCliente = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cargo?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	ContatosCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarContatoPessoa>>>;
	ContatosEditar?: InputMaybe<Array<InputMaybe<InputEditarContatoPessoa>>>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<Scalars["Date"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	EnderecoComercial?: InputMaybe<Scalars["String"]>;
	EstadoCivil?: InputMaybe<EstadoCivil>;
	FoneComercial?: InputMaybe<Scalars["String"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdEcommerce?: InputMaybe<Scalars["Int"]>;
	IdGenero?: InputMaybe<Scalars["Int"]>;
	IdPaiDependente?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	IdTipoDependente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	LimiteCredito?: InputMaybe<Scalars["Float"]>;
	LocalTrabalho?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	PermitirVenda?: InputMaybe<Scalars["Resposta"]>;
	RG?: InputMaybe<Scalars["String"]>;
	ReferenciasComerciaisCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarReferenciaComercial>>>;
	ReferenciasComerciaisEditar?: InputMaybe<Array<InputMaybe<InputEditarReferenciaComercial>>>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	RendaMensal?: InputMaybe<Scalars["Float"]>;
	SpcSerasa?: InputMaybe<Scalars["Resposta"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarColaborador = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cargo?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	ConfirmarSenha?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<Scalars["Date"]>;
	DataDemissao?: InputMaybe<Scalars["Date"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdDepartamento?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Login?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	NivelFuncao?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	Permissoes?: InputMaybe<Array<InputMaybe<InputPermissaoColaborador>>>;
	Pis?: InputMaybe<Scalars["String"]>;
	PorcentComis?: InputMaybe<Scalars["Float"]>;
	PorcentDescMax?: InputMaybe<Scalars["Float"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Senha?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarColecao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarComissaoFormaPgto = {
	Id: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
	Valor?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarCondicional = {
	Data?: InputMaybe<Scalars["Date"]>;
	DataPrevRetorno?: InputMaybe<Scalars["Date"]>;
	Id: Scalars["Int"];
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	ItensCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemCondicional>>>;
	ItensEditar?: InputMaybe<Array<InputMaybe<InputEditarItemCondicional>>>;
	ItensRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Observacoes?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<StatusCondicional>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarContaFinanceira = {
	DvAgencia?: InputMaybe<Scalars["String"]>;
	DvConta?: InputMaybe<Scalars["String"]>;
	Email?: InputMaybe<Scalars["String"]>;
	Gerente?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdNivel?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeTitular?: InputMaybe<Scalars["String"]>;
	NumAgencia?: InputMaybe<Scalars["String"]>;
	NumConta?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
	Tipo?: InputMaybe<TipoContaFinanceira>;
};

export type InputEditarContador = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	Cpf?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Crc?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarContatoPessoa = {
	Contato?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoContato>;
};

export type InputEditarDefinicao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	MateriaPrima?: InputMaybe<Scalars["Status"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Producao?: InputMaybe<Scalars["Status"]>;
	Servico?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	UsoConsumo?: InputMaybe<Scalars["Status"]>;
	Venda?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarDepartamento = {
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Producao?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tecnico?: InputMaybe<Scalars["Status"]>;
	Vendas?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarDevolucao = {
	Id: Scalars["Int"];
	IdNFe?: InputMaybe<Scalars["Int"]>;
};

export type InputEditarEmpresa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataFundacao?: InputMaybe<Scalars["Date"]>;
	EmailAdministrativo?: InputMaybe<Scalars["String"]>;
	EmailFinanceiro?: InputMaybe<Scalars["String"]>;
	EmailPrincipal?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCidade?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	LogoEmpresa?: InputMaybe<Scalars["Upload"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NomeFilial?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	NumNFe?: InputMaybe<Scalars["Int"]>;
	NumUsuarios?: InputMaybe<Scalars["Int"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	PlanoContratado?: InputMaybe<PlanoContratado>;
	ProdutoEmpresa?: InputMaybe<ProdutoEmpresa>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Responsavel?: InputMaybe<Scalars["String"]>;
	Sistema?: InputMaybe<SistemaEmpresa>;
	Status?: InputMaybe<StatusEmpresa>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarEnderecoAdicional = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCidade?: InputMaybe<Scalars["Int"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoEndereco>;
};

export type InputEditarExportacao = {
	ChaveNFe?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	NumeroDrawback?: InputMaybe<Scalars["String"]>;
	NumeroRegistroExportacao?: InputMaybe<Scalars["String"]>;
	QuantidadeExportacao?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarFinanceiro = {
	CodigoFinanceiro?: InputMaybe<Scalars["String"]>;
	Desconto?: InputMaybe<Scalars["Float"]>;
	Historico?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	IdLancamento?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
	IdParcelamento?: InputMaybe<Scalars["Int"]>;
	IdVenda?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Juro?: InputMaybe<Scalars["Float"]>;
	LancaFuturo?: InputMaybe<Scalars["Int"]>;
	NumPrest?: InputMaybe<Scalars["Int"]>;
};

export type InputEditarFormaPagamento = {
	FormaVenda?: InputMaybe<FormasVenda>;
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoFormaPagamento>;
	TipoPagamentoNFe?: InputMaybe<TagPagamentoNFe>;
};

export type InputEditarFornecedor = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Site?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarGenero = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarGrade = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	Preco1?: InputMaybe<Scalars["Float"]>;
	Preco2?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarGradeCampo = {
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoGradeCampo>;
};

export type InputEditarGradeEixo = {
	Id: Scalars["Int"];
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGradeTipo: Scalars["Int"];
	IdLinha?: InputMaybe<Scalars["Int"]>;
};

export type InputEditarGradeFilial = {
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	IdGrade: Scalars["Int"];
	Preco1?: InputMaybe<Scalars["Float"]>;
	Preco2?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarGradeTipo = {
	CadastraGradeEixo?: InputMaybe<Array<Scalars["Int"]>>;
	Id: Scalars["Int"];
	NomeColuna?: InputMaybe<Scalars["String"]>;
	NomeGrade?: InputMaybe<Scalars["String"]>;
	NomeLinha?: InputMaybe<Scalars["String"]>;
	RemoveGradeEixo?: InputMaybe<Array<Scalars["Int"]>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarImportacao = {
	CNPJ?: InputMaybe<Scalars["String"]>;
	CadastraAdicaoImportacao?: InputMaybe<Array<InputCadastrarAdicaoImportacao>>;
	CodExportador?: InputMaybe<Scalars["String"]>;
	DataDesembaraco?: InputMaybe<Scalars["Date"]>;
	DataRegistro?: InputMaybe<Scalars["Date"]>;
	EditaAdicaoImportacao?: InputMaybe<Array<InputEditarAdicaoImportacao>>;
	Id: Scalars["Int"];
	LocalDesembaraco?: InputMaybe<Scalars["String"]>;
	NumDocImportacao?: InputMaybe<Scalars["String"]>;
	RemoveAdicaoImportacao?: InputMaybe<Array<Scalars["Int"]>>;
	TipoIntermedio?: InputMaybe<TipoIntermedio>;
	TipoViaTransporte?: InputMaybe<TipoViaTransporte>;
	UfDesembaraco?: InputMaybe<Scalars["String"]>;
	UfTerceiro?: InputMaybe<Scalars["String"]>;
	ValorAFRMM?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarIntermediador = {
	Cnpj?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Identificador?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarItemCondicional = {
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Status?: InputMaybe<StatusItemCondicional>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorUnitario?: InputMaybe<Scalars["Float"]>;
	ValorUnitarioPrazo?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarItemDevolucao = {
	Id: Scalars["Int"];
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdItemVenda?: InputMaybe<Scalars["Int"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarItemGrade = {
	Id: Scalars["Int"];
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Valor?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarItemNfe = {
	AliqIcmsDif?: InputMaybe<Scalars["Float"]>;
	CadastraArma?: InputMaybe<Array<InputCadastrarArma>>;
	CadastraExportacao?: InputMaybe<Array<InputCadastrarExportacao>>;
	CadastraImportacao?: InputMaybe<Array<InputCadastrarImportacao>>;
	CadastraItemGrade?: InputMaybe<InputCadastrarItemGrade>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodBenef?: InputMaybe<Scalars["String"]>;
	CodEnqlIpi?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	CodListServ?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	DetalhamentoItem?: InputMaybe<Scalars["String"]>;
	EditaArma?: InputMaybe<Array<InputEditarArma>>;
	EditaExportacao?: InputMaybe<Array<InputEditarExportacao>>;
	EditaImportacao?: InputMaybe<Array<InputEditarImportacao>>;
	EditaItemGrade?: InputMaybe<InputEditarItemGrade>;
	FatorConversao?: InputMaybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	IdCest?: InputMaybe<Scalars["Int"]>;
	IdCfop?: InputMaybe<Scalars["Int"]>;
	IdCstCofins?: InputMaybe<Scalars["Int"]>;
	IdCstIcmsCompra?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPis?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IdUnidade?: InputMaybe<Scalars["Int"]>;
	IdUnidadeCompra?: InputMaybe<Scalars["Int"]>;
	MotDesIcms?: InputMaybe<Scalars["Int"]>;
	NumItemPedido?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PedidoDeCompra?: InputMaybe<Scalars["String"]>;
	PorcentCofins?: InputMaybe<Scalars["Float"]>;
	PorcentCredSN?: InputMaybe<Scalars["Float"]>;
	PorcentDevol?: InputMaybe<Scalars["Float"]>;
	PorcentFcp?: InputMaybe<Scalars["Float"]>;
	PorcentFcpSt?: InputMaybe<Scalars["Float"]>;
	PorcentFcpStRet?: InputMaybe<Scalars["Float"]>;
	PorcentFcpUfDest?: InputMaybe<Scalars["Float"]>;
	PorcentIcms?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsEfet?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsInter?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsSt?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	PorcentIpi?: InputMaybe<Scalars["Float"]>;
	PorcentMvaSt?: InputMaybe<Scalars["Float"]>;
	PorcentPis?: InputMaybe<Scalars["Float"]>;
	PorcentRedBC?: InputMaybe<Scalars["Float"]>;
	PorcentRedBCEfet?: InputMaybe<Scalars["Float"]>;
	PorcentSt?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	QuantidadeBCCofins?: InputMaybe<Scalars["Float"]>;
	QuantidadeBCPis?: InputMaybe<Scalars["Float"]>;
	RemoveArma?: InputMaybe<Array<Scalars["Int"]>>;
	RemoveExportacao?: InputMaybe<Array<Scalars["Int"]>>;
	RemoveImportacao?: InputMaybe<Array<Scalars["Int"]>>;
	RemoveItemGrade?: InputMaybe<Scalars["Int"]>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorBCCofins?: InputMaybe<Scalars["Float"]>;
	ValorBCEfet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcp?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorBCIcms?: InputMaybe<Scalars["Float"]>;
	ValorBCIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorBCIpi?: InputMaybe<Scalars["Float"]>;
	ValorBCPis?: InputMaybe<Scalars["Float"]>;
	ValorBCStRet?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorCredIcmsSN?: InputMaybe<Scalars["Float"]>;
	ValorCustoMedioUnitario?: InputMaybe<Scalars["Float"]>;
	ValorCustoUnitario?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFcp?: InputMaybe<Scalars["Float"]>;
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsDeson?: InputMaybe<Scalars["Float"]>;
	ValorIcmsEfet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsStRet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: InputMaybe<Scalars["Float"]>;
	ValorIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorUnitario?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarItemNfeCompra = {
	AliqIcmsDif?: InputMaybe<Scalars["Float"]>;
	CadastraItemGrade?: InputMaybe<Array<InputCadastrarItemGrade>>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodBenef?: InputMaybe<Scalars["String"]>;
	CodEnqlIpi?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	CodListServ?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CodigoCfop?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	DetalhamentoItem?: InputMaybe<Scalars["String"]>;
	EditaItemGrade?: InputMaybe<Array<InputEditarItemGrade>>;
	FatorConversao?: InputMaybe<Scalars["Float"]>;
	GradePreco?: InputMaybe<GradePreco>;
	GradesCadastrar?: InputMaybe<Array<InputCadastrarGrade>>;
	Id: Scalars["Int"];
	IdCest?: InputMaybe<Scalars["Int"]>;
	IdCstCofins?: InputMaybe<Scalars["Int"]>;
	IdCstIcmsCompra?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPis?: InputMaybe<Scalars["Int"]>;
	IdGradeTipo?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IdUnidade?: InputMaybe<Scalars["Int"]>;
	IdUnidadeCompra?: InputMaybe<Scalars["Int"]>;
	MargemLucro?: InputMaybe<Scalars["Float"]>;
	MotDesIcms?: InputMaybe<Scalars["Int"]>;
	NumItemPedido?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PedidoDeCompra?: InputMaybe<Scalars["String"]>;
	PorcentCofins?: InputMaybe<Scalars["Float"]>;
	PorcentCredSN?: InputMaybe<Scalars["Float"]>;
	PorcentDevol?: InputMaybe<Scalars["Float"]>;
	PorcentFcp?: InputMaybe<Scalars["Float"]>;
	PorcentFcpSt?: InputMaybe<Scalars["Float"]>;
	PorcentFcpStRet?: InputMaybe<Scalars["Float"]>;
	PorcentFcpUfDest?: InputMaybe<Scalars["Float"]>;
	PorcentIcms?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsEfet?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsInter?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsSt?: InputMaybe<Scalars["Float"]>;
	PorcentIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	PorcentIpi?: InputMaybe<Scalars["Float"]>;
	PorcentMvaSt?: InputMaybe<Scalars["Float"]>;
	PorcentPis?: InputMaybe<Scalars["Float"]>;
	PorcentRedBC?: InputMaybe<Scalars["Float"]>;
	PorcentRedBCEfet?: InputMaybe<Scalars["Float"]>;
	PorcentSt?: InputMaybe<Scalars["Float"]>;
	PrecoVenda?: InputMaybe<Scalars["Float"]>;
	PrecoVenda2?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	QuantidadeBCCofins?: InputMaybe<Scalars["Float"]>;
	QuantidadeBCPis?: InputMaybe<Scalars["Float"]>;
	QuantidadeCompra?: InputMaybe<Scalars["Float"]>;
	RemoveItemGrade?: InputMaybe<Array<Scalars["Int"]>>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorBCCofins?: InputMaybe<Scalars["Float"]>;
	ValorBCEfet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcp?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorBCFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorBCIcms?: InputMaybe<Scalars["Float"]>;
	ValorBCIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorBCIpi?: InputMaybe<Scalars["Float"]>;
	ValorBCPis?: InputMaybe<Scalars["Float"]>;
	ValorBCStRet?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorCredIcmsSN?: InputMaybe<Scalars["Float"]>;
	ValorCustoMedioUnitario?: InputMaybe<Scalars["Float"]>;
	ValorCustoUnitario?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFcp?: InputMaybe<Scalars["Float"]>;
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpStRet?: InputMaybe<Scalars["Float"]>;
	ValorFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsDeson?: InputMaybe<Scalars["Float"]>;
	ValorIcmsEfet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsStRet?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: InputMaybe<Scalars["Float"]>;
	ValorIcmsUFDest?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorUnitario?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarItemVenda = {
	DescricaoComp?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	QtdConferida?: InputMaybe<Scalars["Float"]>;
	QtdFaturada?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	ValorAcrescimo?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorUnitario?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarLancamento = {
	Data?: InputMaybe<Scalars["Date"]>;
	Hora?: InputMaybe<Scalars["Time"]>;
	Id: Scalars["Int"];
	IdLancamento?: InputMaybe<Scalars["Int"]>;
	IdNivel?: InputMaybe<Scalars["Int"]>;
	IdOperacao?: InputMaybe<TipoOperacao>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	ValorTotal?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarLancamentoDespesa = {
	DataVencimento?: InputMaybe<Scalars["Date"]>;
	Historico?: InputMaybe<Scalars["String"]>;
	IdParcelamento: Scalars["Int"];
	Motivo?: InputMaybe<Scalars["String"]>;
	ValorParcela?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarLancamentoReceita = {
	DataVencimento?: InputMaybe<Scalars["Date"]>;
	Historico?: InputMaybe<Scalars["String"]>;
	IdParcelamento: Scalars["Int"];
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Motivo?: InputMaybe<Scalars["String"]>;
	ValorParcela?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarManifesto = {
	Chave?: InputMaybe<Scalars["String"]>;
	Cnpj?: InputMaybe<Scalars["String"]>;
	Emissao?: InputMaybe<Scalars["DateTime"]>;
	Evento?: InputMaybe<EventoManifestoNFe>;
	FinalidadeNfe?: InputMaybe<FinalidadeNFe>;
	Id: Scalars["Int"];
	JustificativaEvento?: InputMaybe<Scalars["String"]>;
	NaturezaOperacao?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NumNfe?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusManifestoNFe>;
	Valor?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarMarca = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarMeta = {
	Id: Scalars["Int"];
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	NomeMeta?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	TipoBonificacao?: InputMaybe<TipoBonificacao>;
	ValorBonificacao?: InputMaybe<Scalars["Float"]>;
	ValorMeta?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarMovimentoEstoque = {
	Data?: InputMaybe<Scalars["DateTime"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Origem?: InputMaybe<OrigemMovimento>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Valor?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarNcm = {
	AliqEstadual?: InputMaybe<Scalars["Float"]>;
	AliqImportacao?: InputMaybe<Scalars["Float"]>;
	AliqMunicipal?: InputMaybe<Scalars["Float"]>;
	AliqNacional?: InputMaybe<Scalars["Float"]>;
	CodNCM?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	ExtIpi?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEstado?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tabela?: InputMaybe<TabelaNcm>;
};

export type InputEditarNcmRegraTributacao = {
	Id: Scalars["Int"];
	IdNcm: Scalars["Int"];
};

export type InputEditarNfe = {
	DataCancelamento?: InputMaybe<Scalars["DateTime"]>;
	DataCompet?: InputMaybe<Scalars["Date"]>;
	DataHoraEmissao?: InputMaybe<Scalars["DateTime"]>;
	DataHoraSaiEnt?: InputMaybe<Scalars["DateTime"]>;
	DataInutilizada?: InputMaybe<Scalars["DateTime"]>;
	Frete?: InputMaybe<TipoFrete>;
	Id: Scalars["Int"];
	IdCfop?: InputMaybe<Scalars["Int"]>;
	IdEnderecoEntrega?: InputMaybe<Scalars["Int"]>;
	IdIntermediador?: InputMaybe<Scalars["Int"]>;
	IdNfeRef?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVeiculo?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	ItemNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemNfe>>>;
	ItemNfeEditar?: InputMaybe<Array<InputMaybe<InputEditarItemNfe>>>;
	ItemNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	ModCliente?: InputMaybe<ModalidadeCliente>;
	NatOperacao?: InputMaybe<Scalars["String"]>;
	NumNF?: InputMaybe<Scalars["Int"]>;
	ObservacaoComplementar?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoNfe>>>;
	PrestacaoNfeEditar?: InputMaybe<Array<InputMaybe<InputEditarPrestacaoNfe>>>;
	PrestacaoNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	RefNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarReferenciaNfe>>>;
	RefNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Serie?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusNfe>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorOutro?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
	VendaNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarVendaNfe>>>;
	VendaNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	VolumeNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarVolumeNfe>>>;
	VolumeNfeEditar?: InputMaybe<Array<InputMaybe<InputEditarVolumeNfe>>>;
	VolumeNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputEditarNfeCompra = {
	DataHoraEmissao?: InputMaybe<Scalars["DateTime"]>;
	DataHoraSaiEnt?: InputMaybe<Scalars["DateTime"]>;
	Id: Scalars["Int"];
	IdCfop?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	ItemNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemNfeCompra>>>;
	ItemNfeEditar?: InputMaybe<Array<InputMaybe<InputEditarItemNfeCompra>>>;
	ItemNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	ModCliente?: InputMaybe<ModalidadeCliente>;
	NatOperacao?: InputMaybe<Scalars["String"]>;
	NumNF?: InputMaybe<Scalars["Int"]>;
	ObservacaoComplementar?: InputMaybe<Scalars["String"]>;
	OutrasOpcoes?: InputMaybe<Scalars["String"]>;
	PrestacaoNfeCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoNfe>>>;
	PrestacaoNfeEditar?: InputMaybe<Array<InputMaybe<InputEditarPrestacaoNfe>>>;
	PrestacaoNfeRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Serie?: InputMaybe<Scalars["Int"]>;
	ValorBcIcms?: InputMaybe<Scalars["Float"]>;
	ValorBcIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorCofins?: InputMaybe<Scalars["Float"]>;
	ValorCofinsSt?: InputMaybe<Scalars["Float"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFcpSt?: InputMaybe<Scalars["Float"]>;
	ValorFcpUfDest?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorIcms?: InputMaybe<Scalars["Float"]>;
	ValorIcmsSt?: InputMaybe<Scalars["Float"]>;
	ValorIcmsUfDest?: InputMaybe<Scalars["Float"]>;
	ValorIi?: InputMaybe<Scalars["Float"]>;
	ValorIpi?: InputMaybe<Scalars["Float"]>;
	ValorIpiDevol?: InputMaybe<Scalars["Float"]>;
	ValorOutro?: InputMaybe<Scalars["Float"]>;
	ValorPis?: InputMaybe<Scalars["Float"]>;
	ValorPisSt?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarNivel = {
	Comportamento?: InputMaybe<ComportamentoNivel>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdDefinicao?: InputMaybe<TipoNivelDefinicao>;
	IdNivel?: InputMaybe<Scalars["Int"]>;
	IdNivelContra?: InputMaybe<Scalars["Int"]>;
	Ordem: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarParcelamento = {
	DataPgto?: InputMaybe<Scalars["Date"]>;
	DataVencimento?: InputMaybe<Scalars["Date"]>;
	DescontoPgto?: InputMaybe<Scalars["Float"]>;
	EmailAvisoVencimento?: InputMaybe<Scalars["Resposta"]>;
	HistoricoAlteracao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	PgtoParcial?: InputMaybe<Scalars["Float"]>;
	Status?: InputMaybe<StatusParcelamento>;
	TaxaJuro?: InputMaybe<Scalars["Float"]>;
	ValorJuroPgto?: InputMaybe<Scalars["Float"]>;
	ValorParcela?: InputMaybe<Scalars["Float"]>;
	ValorTaxaCartao?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarPdv = {
	Id: Scalars["Int"];
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarPedidoVenda = {
	Conferido?: InputMaybe<Scalars["Resposta"]>;
	DataEntrega?: InputMaybe<Scalars["Date"]>;
	DataHora?: InputMaybe<Scalars["DateTime"]>;
	Frete?: InputMaybe<TipoFrete>;
	Id: Scalars["Int"];
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	ItemPedidoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemVenda>>>;
	ItemPedidoVendaEditar?: InputMaybe<Array<InputMaybe<InputEditarItemVenda>>>;
	ItemPedidoVendaRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Observacoes?: InputMaybe<Scalars["String"]>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoPedidoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoVenda>>>;
	PrestacaoPedidoVendaEditar?: InputMaybe<Array<InputMaybe<InputEditarPrestacaoVenda>>>;
	PrestacaoPedidoVendaRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Int"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorJuro?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarPessoa = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	Email?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarPrestacaoNfe = {
	DataVencimento?: InputMaybe<Scalars["Date"]>;
	Id: Scalars["Int"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	NumDocumento?: InputMaybe<Scalars["String"]>;
	NumLancamento?: InputMaybe<Scalars["Int"]>;
	TotalLancamento?: InputMaybe<Scalars["Float"]>;
	ValorParcela?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarPrestacaoVenda = {
	DataVencimento?: InputMaybe<Scalars["DateTime"]>;
	Id: Scalars["Int"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	NumDocumento?: InputMaybe<Scalars["String"]>;
	NumLancamento?: InputMaybe<Scalars["Int"]>;
	TotalLancamento?: InputMaybe<Scalars["Float"]>;
	ValorParcela?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarProduto = {
	AtivoEcommerce?: InputMaybe<Scalars["Status"]>;
	AtualizaGradeTipo?: InputMaybe<InputAtualizaGradeTipo>;
	CestCodigo?: InputMaybe<Scalars["String"]>;
	CfopCodigo?: InputMaybe<Scalars["String"]>;
	CodAnp?: InputMaybe<Scalars["String"]>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodBenef?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	CodListServ?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CodigoSecundario?: InputMaybe<Scalars["String"]>;
	CofinsAliq?: InputMaybe<Scalars["Float"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	DescAnp?: InputMaybe<Scalars["String"]>;
	Desconto?: InputMaybe<Scalars["Float"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FatorConversao?: InputMaybe<Scalars["Float"]>;
	FatorConversaoTribut?: InputMaybe<Scalars["Float"]>;
	Foto?: InputMaybe<Scalars["Upload"]>;
	GradePreco?: InputMaybe<GradePreco>;
	GradesCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarGrade>>>;
	GradesEditar?: InputMaybe<Array<InputMaybe<InputEditarGrade>>>;
	IIAliq?: InputMaybe<Scalars["Float"]>;
	IPIAliq?: InputMaybe<Scalars["Float"]>;
	IcmsAliq?: InputMaybe<Scalars["Float"]>;
	IcmsMva?: InputMaybe<Scalars["Float"]>;
	IcmsReducao?: InputMaybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdColecao?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPisCofins?: InputMaybe<Scalars["Int"]>;
	IdDefinicao?: InputMaybe<Scalars["Int"]>;
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdGradeTipo?: InputMaybe<Scalars["Int"]>;
	IdMarca?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IdSubcategoria?: InputMaybe<Scalars["Int"]>;
	IdUnidade?: InputMaybe<Scalars["Int"]>;
	IdUnidadeCompra?: InputMaybe<Scalars["Int"]>;
	IdUnidadeTributaria?: InputMaybe<Scalars["Int"]>;
	IndEscala?: InputMaybe<IndicadorProducao>;
	IpiClasse?: InputMaybe<Scalars["String"]>;
	IpiCnpjProd?: InputMaybe<Scalars["String"]>;
	IpiCodEnq?: InputMaybe<Scalars["String"]>;
	IpiCodSelo?: InputMaybe<Scalars["String"]>;
	IpiQtdSelo?: InputMaybe<Scalars["Int"]>;
	MargemLucro?: InputMaybe<Scalars["Float"]>;
	NcmCodigo?: InputMaybe<Scalars["String"]>;
	Origem?: InputMaybe<Scalars["Int"]>;
	Peso?: InputMaybe<Scalars["Float"]>;
	PisAliq?: InputMaybe<Scalars["Float"]>;
	PorcentDifIcms?: InputMaybe<Scalars["Float"]>;
	ProdutosKitCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarProdutoKit>>>;
	ProdutosKitEditar?: InputMaybe<Array<InputMaybe<InputEditarProdutoKit>>>;
	QtdMinima?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaNormal?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaSimples?: InputMaybe<Scalars["Float"]>;
	SaldoIniQtd?: InputMaybe<Scalars["Float"]>;
	SkuEcommerce?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
	ValorVenda?: InputMaybe<Scalars["Float"]>;
	ValorVendaAlt?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarProdutoFilial = {
	CofinsAliq?: InputMaybe<Scalars["Float"]>;
	CustoMedio?: InputMaybe<Scalars["Float"]>;
	Desconto?: InputMaybe<Scalars["Float"]>;
	IIAliq?: InputMaybe<Scalars["Float"]>;
	IPIAliq?: InputMaybe<Scalars["Float"]>;
	IcmsAliq?: InputMaybe<Scalars["Float"]>;
	IcmsMva?: InputMaybe<Scalars["Float"]>;
	IcmsReducao?: InputMaybe<Scalars["Float"]>;
	IdCest?: InputMaybe<Scalars["Int"]>;
	IdCfop?: InputMaybe<Scalars["Int"]>;
	IdCstIpi?: InputMaybe<Scalars["Int"]>;
	IdCstPisCofins?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdSituaTribut?: InputMaybe<Scalars["Int"]>;
	IpiClasse?: InputMaybe<Scalars["String"]>;
	IpiCodEnq?: InputMaybe<Scalars["String"]>;
	IpiCodSelo?: InputMaybe<Scalars["String"]>;
	IpiQtdSelo?: InputMaybe<Scalars["Int"]>;
	MargemLucro?: InputMaybe<Scalars["Float"]>;
	PisAliq?: InputMaybe<Scalars["Float"]>;
	PorcentDifIcms?: InputMaybe<Scalars["Float"]>;
	QtdMinima?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaNormal?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaSimples?: InputMaybe<Scalars["Float"]>;
	ValorCusto?: InputMaybe<Scalars["Float"]>;
	ValorVenda?: InputMaybe<Scalars["Float"]>;
	ValorVendaAlt?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarProdutoFornecedor = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
};

export type InputEditarProdutoKit = {
	AtualizarValores?: InputMaybe<Scalars["Boolean"]>;
	Id: Scalars["Int"];
	Quantidade?: InputMaybe<Scalars["Float"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarReferenciaComercial = {
	Contato?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarReferenciaNFe1A = {
	Cnpj?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<Scalars["Date"]>;
	IdEstado?: InputMaybe<Scalars["Int"]>;
	Modelo?: InputMaybe<Modelo1A>;
	NumNf?: InputMaybe<Scalars["Int"]>;
	Serie?: InputMaybe<Scalars["Int"]>;
};

export type InputEditarReferenciaNfe = {
	ChaveAcesso?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<Scalars["Date"]>;
	Id: Scalars["Int"];
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdNfce?: InputMaybe<Scalars["Int"]>;
	IdNfeRef?: InputMaybe<Scalars["Int"]>;
	IndEmitente?: InputMaybe<IndicadorEmitente>;
	IndOperacao?: InputMaybe<IndicadorOperacao>;
	NF1A?: InputMaybe<InputEditarReferenciaNFe1A>;
	NumNf?: InputMaybe<Scalars["Int"]>;
	Serie?: InputMaybe<Scalars["Int"]>;
};

export type InputEditarRegraTributacao = {
	AliquotaCofinsAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaFcpAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaIcmsAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaIpiAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaMvaAcao?: InputMaybe<Scalars["Float"]>;
	AliquotaPisAcao?: InputMaybe<Scalars["Float"]>;
	CadastraNCM?: InputMaybe<Array<InputMaybe<InputCadastrarNcmRegraTributacao>>>;
	DestacaDifalAcao?: InputMaybe<RespostaEnum>;
	EditaNcm?: InputMaybe<Array<InputMaybe<InputEditarNcmRegraTributacao>>>;
	ExcluiNcm?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Id: Scalars["Int"];
	IdCestAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopCondicao?: InputMaybe<Scalars["Int"]>;
	IdCstCofinsAcao?: InputMaybe<Scalars["Int"]>;
	IdCstIpiAcao?: InputMaybe<Scalars["Int"]>;
	IdCstPisAcao?: InputMaybe<Scalars["Int"]>;
	IdPessoaCondicao?: InputMaybe<Scalars["Int"]>;
	IdSituaTributAcao?: InputMaybe<Scalars["Int"]>;
	IdUFCondicao?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	ObservacaoNFAcao?: InputMaybe<Scalars["String"]>;
	ReducaoIcmsAcao?: InputMaybe<Scalars["Float"]>;
	ReducaoMvaAcao?: InputMaybe<Scalars["Float"]>;
	RegimeTributarioCondicao?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMSCondicao?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoRegra>;
	TipoConsumidorCondicao?: InputMaybe<ModalidadeCliente>;
	TipoUFCondicao?: InputMaybe<TipoUfDestino>;
};

export type InputEditarSituacaoTributaria = {
	Id: Scalars["Int"];
	Status: Scalars["Status"];
};

export type InputEditarStatusVenda = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Estagio?: InputMaybe<Estagio>;
	Id: Scalars["Int"];
	Ordem?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarSubcategoria = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarTipoDependente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarTransportadora = {
	Bairro?: InputMaybe<Scalars["String"]>;
	CadastraVeiculos?: InputMaybe<Array<InputCadastrarVeiculo>>;
	Celular?: InputMaybe<Scalars["String"]>;
	Celular2?: InputMaybe<Scalars["String"]>;
	Cep?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Complemento?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataNascimento?: InputMaybe<Scalars["Date"]>;
	EditaVeiculos?: InputMaybe<Array<InputEditarVeiculo>>;
	Email?: InputMaybe<Scalars["String"]>;
	IdCidade?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: InputMaybe<Scalars["String"]>;
	InscricaoMunicipal?: InputMaybe<Scalars["String"]>;
	Logradouro?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	NumEndereco?: InputMaybe<Scalars["String"]>;
	Observacao?: InputMaybe<Scalars["String"]>;
	RG?: InputMaybe<Scalars["String"]>;
	RegimeTributario?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMS?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputEditarUnidade = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Simbolo?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarVeiculo = {
	Id: Scalars["Int"];
	IdEstado?: InputMaybe<Scalars["Int"]>;
	IdEstadoReboque?: InputMaybe<Scalars["Int"]>;
	Placa?: InputMaybe<Scalars["String"]>;
	ReboquePlaca?: InputMaybe<Scalars["String"]>;
	ReboqueRntc?: InputMaybe<Scalars["String"]>;
	Rntc?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputEditarVenda = {
	Conferido?: InputMaybe<Scalars["Resposta"]>;
	Data?: InputMaybe<Scalars["Date"]>;
	DataEntrega?: InputMaybe<Scalars["Date"]>;
	DataHora?: InputMaybe<Scalars["DateTime"]>;
	Frete?: InputMaybe<TipoFrete>;
	Hora?: InputMaybe<Scalars["Time"]>;
	Id: Scalars["Int"];
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdIntermediador?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	InformacoesAdicionais?: InputMaybe<Scalars["String"]>;
	ItemVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarItemVenda>>>;
	ItemVendaEditar?: InputMaybe<Array<InputMaybe<InputEditarItemVenda>>>;
	ItemVendaRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Observacoes?: InputMaybe<Scalars["String"]>;
	PrecoUsado?: InputMaybe<PrecoUsado>;
	PrestacaoVendaCadastrar?: InputMaybe<Array<InputMaybe<InputCadastrarPrestacaoVenda>>>;
	PrestacaoVendaEditar?: InputMaybe<Array<InputMaybe<InputEditarPrestacaoVenda>>>;
	PrestacaoVendaRemover?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Int"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
	ValorJuro?: InputMaybe<Scalars["Float"]>;
	ValorSeguro?: InputMaybe<Scalars["Float"]>;
};

export type InputEditarVolumeNfe = {
	Especie?: InputMaybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Marca?: InputMaybe<Scalars["String"]>;
	Numeracao?: InputMaybe<Scalars["String"]>;
	PesoBruto?: InputMaybe<Scalars["Float"]>;
	PesoLiquido?: InputMaybe<Scalars["Float"]>;
	Quantidade?: InputMaybe<Scalars["Float"]>;
};

export type InputEnviaCertificado = {
	Certificado: Scalars["Upload"];
	Senha: Scalars["String"];
};

export type InputEnviaCsv = {
	Arquivos: Array<InputMaybe<Scalars["Upload"]>>;
};

export type InputEsqueciSenha = {
	Email: Scalars["String"];
};

export type InputEstornoParcelaReceita = {
	IdParcela: Scalars["Int"];
	Motivo?: InputMaybe<Scalars["String"]>;
	TipoEstorno: TipoEstornoReceita;
};

export type InputFiltroAdicaoImportacao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdImportacao?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroAdicoesImportacao = {
	IdImportacao?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroAdminCartao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdBandeira?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroAdminsCartao = {
	IdBandeira?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroAliquotaIcms = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdUfDestino?: InputMaybe<Scalars["Int"]>;
	IdUfOrigem?: InputMaybe<Scalars["Int"]>;
	SiglaUfDestino?: InputMaybe<Scalars["String"]>;
	SiglaUfOrigem?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroAliquotasIcms = {
	IdUfDestino?: InputMaybe<Scalars["Int"]>;
	IdUfOrigem?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	SiglaUfDestino?: InputMaybe<Scalars["String"]>;
	SiglaUfOrigem?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroArma = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroArmas = {
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroArquivo = {
	Periodo: DateRange;
	Sped?: InputMaybe<InputDadosSped>;
	TipoArquivo: Array<InputMaybe<TipoArquivo>>;
};

export type InputFiltroBalanceteDemonstrativo = {
	Ano?: InputMaybe<Scalars["Int"]>;
	SomenteComMovimentacao?: InputMaybe<Scalars["Boolean"]>;
};

export type InputFiltroBandeiraCartao = {
	Codigo?: InputMaybe<Scalars["Int"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Operadora?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroBandeirasCartao = {
	Codigo?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Operadora?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroCategoria = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCategoriaCliente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCategorias = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCategoriasCliente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCep = {
	Cep: Scalars["String"];
};

export type InputFiltroCest = {
	CodCest?: InputMaybe<Scalars["String"]>;
	CodNcm?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Item?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
};

export type InputFiltroCests = {
	CodCest?: InputMaybe<Scalars["String"]>;
	CodNcm?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Item?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
};

export type InputFiltroCfop = {
	Codigo?: InputMaybe<Scalars["String"]>;
	CreditoIcmsCompra?: InputMaybe<Scalars["Status"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdOperacao?: InputMaybe<TipoCfopOperacao>;
	MovimentaEstoque?: InputMaybe<Scalars["Status"]>;
	MovimentaFinanceiro?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	TipoNFe?: InputMaybe<Array<InputMaybe<TipoNFe>>>;
};

export type InputFiltroCfopOperacao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCfopOperacoes = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCfops = {
	Codigo?: InputMaybe<Scalars["String"]>;
	Codigos?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	CreditoIcmsCompra?: InputMaybe<Scalars["Status"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsOperacao?: InputMaybe<Array<InputMaybe<TipoCfopOperacao>>>;
	MovimentaEstoque?: InputMaybe<Scalars["Status"]>;
	MovimentaFinanceiro?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	TipoNFe?: InputMaybe<Array<InputMaybe<TipoNFe>>>;
};

export type InputFiltroCidade = {
	CodIBGE?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdEstado?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Uf?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroCidades = {
	CodIBGE?: InputMaybe<Scalars["String"]>;
	IdEstado?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Uf?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroCliente = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cidade?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Email?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	IdsEstado?: InputMaybe<Array<Scalars["Int"]>>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroClientes = {
	Bairro?: InputMaybe<Scalars["String"]>;
	Cidade?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Email?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdsEstado?: InputMaybe<Array<Scalars["Int"]>>;
	IdsPessoas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroColaborador = {
	Codigo?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdDepartamento?: InputMaybe<Scalars["Int"]>;
	ProducaoDepartamento?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	StatusDepartamento?: InputMaybe<Scalars["Status"]>;
	TecnicoDepartamento?: InputMaybe<Scalars["Status"]>;
	VendasDepartamento?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroColaboradorEmpresa = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdColaborador?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroColaboradores = {
	Celular?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	DataAdmissao?: InputMaybe<DateTimeRange>;
	DataDemissao?: InputMaybe<DateTimeRange>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdDepartamento?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Login?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	ProducaoDepartamento?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	StatusDepartamento?: InputMaybe<Scalars["Status"]>;
	TecnicoDepartamento?: InputMaybe<Scalars["Status"]>;
	VendasDepartamento?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroColecao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroColecoes = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroComissaoFormaPgto = {
	Id?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroComissoes = {
	CalculoPorFormaPgto?: InputMaybe<Scalars["Boolean"]>;
	ConsiderarDevolucao?: InputMaybe<Scalars["Boolean"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Periodo: DateRange;
};

export type InputFiltroComissoesFormaPgto = {
	IdColaborador?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdFormaPgto?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroCondicionais = {
	Codigo?: InputMaybe<Scalars["String"]>;
	Data?: InputMaybe<DateRange>;
	DataPrevRetorno?: InputMaybe<DateRange>;
	DataRetorno?: InputMaybe<DateRange>;
	DocFiscal?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	HashLink?: InputMaybe<Scalars["String"]>;
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomeCliente?: InputMaybe<Scalars["String"]>;
	QtdItens?: InputMaybe<FloatRange>;
	Status?: InputMaybe<Array<InputMaybe<StatusCondicional>>>;
	ValorTotal?: InputMaybe<FloatRange>;
};

export type InputFiltroCondicional = {
	Codigo?: InputMaybe<Scalars["String"]>;
	Data?: InputMaybe<DateRange>;
	DataPrevRetorno?: InputMaybe<DateRange>;
	DataRetorno?: InputMaybe<DateRange>;
	DocFiscal?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	HashLink?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomeCliente?: InputMaybe<Scalars["String"]>;
	QtdItens?: InputMaybe<FloatRange>;
	Status?: InputMaybe<Array<InputMaybe<StatusCondicional>>>;
	ValorTotal?: InputMaybe<FloatRange>;
};

export type InputFiltroConfiguracao = {
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroConfiguracaoEmpresa = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdConfig?: InputMaybe<Configuracoes>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroConfiguracoes = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroConfiguracoesEmpresa = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsConfig?: InputMaybe<Array<InputMaybe<Configuracoes>>>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroContaFinanceira = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNivel?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoContaFinanceira>;
};

export type InputFiltroContador = {
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroContadores = {
	IdsPessoas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroContasFinanceiras = {
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdNivel?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoContaFinanceira>>>;
};

export type InputFiltroContatoPessoa = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoContato>>>;
};

export type InputFiltroDefinicao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	MateriaPrima?: InputMaybe<Scalars["Status"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Producao?: InputMaybe<Scalars["Status"]>;
	Servico?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	UsoConsumo?: InputMaybe<Scalars["Status"]>;
	Venda?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroDefinicoes = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	MateriaPrima?: InputMaybe<Scalars["Status"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Producao?: InputMaybe<Scalars["Status"]>;
	Servico?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	UsoConsumo?: InputMaybe<Scalars["Status"]>;
	Venda?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroDepartamento = {
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Producao?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tecnico?: InputMaybe<Scalars["Status"]>;
	Vendas?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroDepartamentos = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Producao?: InputMaybe<Scalars["Status"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tecnico?: InputMaybe<Scalars["Status"]>;
	Vendas?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroDevolucao = {
	Codigo?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdNFe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroDevolucoes = {
	Codigos?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	IdNFes?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroEmpresa = {
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusEmpresa>;
};

export type InputFiltroEmpresas = {
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<StatusEmpresa>;
};

export type InputFiltroEnderecoAdicional = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroEstado = {
	CodUfIBGE?: InputMaybe<Scalars["Int"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	UF?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroEstados = {
	CodUfIBGE?: InputMaybe<Scalars["Int"]>;
	IdPais?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	UF?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroEventoNfe = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNFe?: InputMaybe<Scalars["Int"]>;
	Retorno?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<StatusEventoNFe>;
	TipoEvento?: InputMaybe<TipoEventoNFe>;
};

export type InputFiltroEventosNfe = {
	DataRegistro?: InputMaybe<DateRange>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdNFe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Modelo?: InputMaybe<Modelo>;
	NumNF?: InputMaybe<Scalars["Int"]>;
	Retornos?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	Serie?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusEventoNFe>;
	TipoEvento?: InputMaybe<TipoEventoNFe>;
};

export type InputFiltroExportacao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroExportacoes = {
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroFinanceiro = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	IdLancamento?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
	IdParcelamento?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	LancaFuturo?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroFinanceiros = {
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdFormaPgto?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
	IdParcelamento?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsLancamento?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	LancaFuturo?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroFormaPagamento = {
	FormaVenda?: InputMaybe<FormasVenda>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoFormaPagamento>;
	TipoPagamentoNFe?: InputMaybe<TagPagamentoNFe>;
};

export type InputFiltroFormasPagamento = {
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	FormaVenda?: InputMaybe<Array<InputMaybe<FormasVenda>>>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoFormaPagamento>>>;
	TipoPagamentoNFe?: InputMaybe<Array<InputMaybe<TagPagamentoNFe>>>;
};

export type InputFiltroFornecedor = {
	Codigo?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroFornecedores = {
	Codigos?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdsPessoas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroGenero = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroGeneros = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroGrade = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroGradeCampo = {
	Codigo?: InputMaybe<Scalars["Int"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoGradeCampo>;
};

export type InputFiltroGradeCampos = {
	Codigos?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoGradeCampo>;
};

export type InputFiltroGradeEixo = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGradeTipo?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroGradeEixos = {
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsGradeTipo?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroGradeFilial = {
	IdGrade?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroGradeTipo = {
	Id?: InputMaybe<Scalars["Int"]>;
	NomeColuna?: InputMaybe<Scalars["String"]>;
	NomeGrade?: InputMaybe<Scalars["String"]>;
	NomeLinha?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoGrade>;
	Visibilidade?: InputMaybe<TipoVisibilidade>;
};

export type InputFiltroGradeTipos = {
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomeColuna?: InputMaybe<Scalars["String"]>;
	NomeGrade?: InputMaybe<Scalars["String"]>;
	NomeLinha?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoGrade>;
	Visibilidade?: InputMaybe<TipoVisibilidade>;
};

export type InputFiltroGrades = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroGradesFiliais = {
	IdsGrades?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroImportacao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroImportacoes = {
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroIntermediador = {
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroIntermediadores = {
	Cnpj?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
};

export type InputFiltroInventario = {
	IncluirAjusteEstoque?: InputMaybe<Scalars["Boolean"]>;
	IncluirInativos?: InputMaybe<Scalars["Boolean"]>;
	Periodo: Scalars["Date"];
	TipoCusto: TipoCusto;
};

export type InputFiltroItemCondicional = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdCondicional?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusItemCondicional>;
};

export type InputFiltroItemDevolucao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdDevolucao?: InputMaybe<Scalars["Int"]>;
	IdItemVenda?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroItemGrade = {
	Id?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroItemNfe = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroItemNfe2 = {
	IdItemNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroItemVenda = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdVenda?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroItensCondicional = {
	IdCondicional?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsGrades?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsProdutos?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<StatusItemCondicional>;
};

export type InputFiltroItensDevolucoes = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsDevolucao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsItensVenda?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroItensGrade = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsItemNfe?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroItensNfe = {
	IdNfe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsNfe?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroItensNfe2 = {
	IdItensNfe?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroItensVendas = {
	IdVenda?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroLancamento = {
	Data?: InputMaybe<DateRange>;
	DataHoraRegistro?: InputMaybe<DateTimeRange>;
	Historico?: InputMaybe<Scalars["String"]>;
	Hora?: InputMaybe<TimeRange>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdLancamento?: InputMaybe<Scalars["Int"]>;
	IdNivel?: InputMaybe<Scalars["Int"]>;
	IdOperacao?: InputMaybe<TipoOperacao>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroLancamentos = {
	Data?: InputMaybe<DateRange>;
	DataHoraRegistro?: InputMaybe<DateTimeRange>;
	Historico?: InputMaybe<Scalars["String"]>;
	Hora?: InputMaybe<TimeRange>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdLancamento?: InputMaybe<Scalars["Int"]>;
	IdNivel?: InputMaybe<Scalars["Int"]>;
	IdOperacao?: InputMaybe<TipoOperacao>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroLancamentosVenda = {
	IdVendas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsDevolucao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroManifesto = {
	Chave?: InputMaybe<Scalars["String"]>;
	Cnpj?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<DateRange>;
	FinalidadeNfe?: InputMaybe<FinalidadeNFe>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NumNfe?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusManifestoNFe>;
};

export type InputFiltroManifestos = {
	Chave?: InputMaybe<Scalars["String"]>;
	Cnpj?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<DateRange>;
	FinalidadeNfe?: InputMaybe<FinalidadeNFe>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	NumNfe?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusManifestoNFe>;
};

export type InputFiltroMarca = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroMarcas = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroMeta = {
	Id?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroMetas = {
	IdVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomeMeta?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroModulo = {
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroModuloEmpresa = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdModulo?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroModulos = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroModulosEmpresa = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsModulos?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroMovimentoBanco = {
	Banco?: InputMaybe<Scalars["Int"]>;
	Data?: InputMaybe<DateRange>;
	Hora?: InputMaybe<TimeRange>;
	Usuario?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroMovimentoCaixa = {
	Caixa?: InputMaybe<Scalars["Int"]>;
	Data?: InputMaybe<DateRange>;
	Hora?: InputMaybe<TimeRange>;
	Usuario?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroMovimentoEstoque = {
	Data?: InputMaybe<DateTimeRange>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdUsuario?: InputMaybe<Scalars["Int"]>;
	Origem?: InputMaybe<OrigemMovimento>;
};

export type InputFiltroMovimentoFinanceiro = {
	CodigoFinanceiro?: InputMaybe<Scalars["String"]>;
	Conta?: InputMaybe<Scalars["Int"]>;
	Data?: InputMaybe<DateRange>;
	EntradaSaida?: InputMaybe<FloatRange>;
	FormaPgto?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Historico?: InputMaybe<Scalars["String"]>;
	Hora?: InputMaybe<TimeRange>;
	IdPlanoConta?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NivelPlanoConta?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomePessoa?: InputMaybe<Scalars["String"]>;
	Operacao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Usuario?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroMovimentoProduto = {
	IdProduto: Scalars["Int"];
	NumDoc?: InputMaybe<Scalars["String"]>;
	Origem?: InputMaybe<Scalars["String"]>;
	Periodo?: InputMaybe<DateRange>;
};

export type InputFiltroMovimentosEstoque = {
	Data?: InputMaybe<DateTimeRange>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	IdUsuario?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Origem?: InputMaybe<OrigemMovimento>;
};

export type InputFiltroNcm = {
	CodNCM?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	ExtIpi?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdEstado?: InputMaybe<Scalars["Int"]>;
	IdsEstado?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
};

export type InputFiltroNcmRegraTributacao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdRegraTributacao?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroNcmRegrasTributacao = {
	IdNcm?: InputMaybe<Scalars["Int"]>;
	IdRegraTributacao?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroNcms = {
	CodNCM?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	ExtIpi?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsEstado?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
};

export type InputFiltroNfe = {
	ChaveAcesso?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<DateRange>;
	DataSaiEnt?: InputMaybe<DateRange>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCfop?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Modelo?: InputMaybe<Modelo>;
	NatOperacao?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NumNF?: InputMaybe<Scalars["String"]>;
	Origem?: InputMaybe<Array<InputMaybe<OrigemNFe>>>;
	Serie?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Array<InputMaybe<StatusNfe>>>;
	TipoNF?: InputMaybe<Array<InputMaybe<TipoNFe>>>;
	ValorTotalNfe?: InputMaybe<FloatRange>;
};

export type InputFiltroNfes = {
	ChaveAcesso?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<DateRange>;
	DataSaiEnt?: InputMaybe<DateRange>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCfop?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsPessoa?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsTransportadora?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Modelo?: InputMaybe<Modelo>;
	NatOperacao?: InputMaybe<Scalars["String"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NumNF?: InputMaybe<Scalars["String"]>;
	Origem?: InputMaybe<Array<InputMaybe<OrigemNFe>>>;
	Serie?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Array<InputMaybe<StatusNfe>>>;
	TipoNF?: InputMaybe<Array<InputMaybe<TipoNFe>>>;
	ValorTotalNfe?: InputMaybe<FloatRange>;
};

export type InputFiltroNiveis = {
	ApenasNiveisFinal?: InputMaybe<Scalars["Boolean"]>;
	Comportamento?: InputMaybe<ComportamentoNivel>;
	DefinicoesSuperiores?: InputMaybe<Array<InputMaybe<TipoNivelDefinicao>>>;
	DefinicoesSuperioresNot?: InputMaybe<Array<InputMaybe<TipoNivelDefinicao>>>;
	Descricao?: InputMaybe<Scalars["String"]>;
	IdNivelNot?: InputMaybe<Array<InputMaybe<TipoNivelDefinicao>>>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsNivel?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NiveisDefinicao?: InputMaybe<Array<InputMaybe<TipoNivelDefinicao>>>;
	Posicao?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<Tipo>;
	TipoPessoa?: InputMaybe<TipoPessoa>;
};

export type InputFiltroNiveisDefinicao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Niveis?: InputMaybe<Array<InputMaybe<TipoNivelDefinicao>>>;
};

export type InputFiltroNivel = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	NivelDefinicao?: InputMaybe<TipoNivelDefinicao>;
	NivelDefinicaoSuperior?: InputMaybe<TipoNivelDefinicao>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroNivelDefinicao = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Nivel?: InputMaybe<TipoNivelDefinicao>;
};

export type InputFiltroOperacao = {
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroOperacoes = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroPagamentos = {
	CodigoFinanceiro?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<DateRange>;
	DataPagamento?: InputMaybe<DateRange>;
	DataVencimento?: InputMaybe<DateRange>;
	FiltroRapido?: InputMaybe<Array<InputMaybe<FiltroRapidoDespesas>>>;
	Historico?: InputMaybe<Scalars["String"]>;
	IdConta?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdFornecedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsEmpresas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Array<InputMaybe<StatusParcelamento>>>;
	ValorTotal?: InputMaybe<FloatRange>;
};

export type InputFiltroPais = {
	Codigo?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroPaises = {
	Codigo?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroParcelamento = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdFinanceiro?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<StatusParcelamento>;
};

export type InputFiltroParcelamentos = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsFinanceiro?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<StatusParcelamento>;
};

export type InputFiltroPdv = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeContaFinanceira?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroPdvs = {
	IdContaFinanceira?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeContaFinanceira?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroPedidoVenda = {
	CodigoPedidoVenda?: InputMaybe<Scalars["String"]>;
	DataEntrega?: InputMaybe<DateRange>;
	DataHora?: InputMaybe<DateTimeRange>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroPedidosVenda = {
	Codigo?: InputMaybe<Scalars["String"]>;
	DataEntrega?: InputMaybe<DateRange>;
	DataHora?: InputMaybe<DateTimeRange>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCliente?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomeCliente?: InputMaybe<Scalars["String"]>;
	QtdItens?: InputMaybe<FloatRange>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	ValorTotal?: InputMaybe<FloatRange>;
	VendaGerada?: InputMaybe<Scalars["Boolean"]>;
};

export type InputFiltroPermissao = {
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoPermissao>>>;
};

export type InputFiltroPermissaoColaborador = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdColaborador?: InputMaybe<Scalars["Int"]>;
	IdPermissao?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroPermissoes = {
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoPermissao>>>;
};

export type InputFiltroPermissoesColaborador = {
	IdColaborador?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsPermissoes?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroPessoa = {
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroPessoas = {
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeFantasia?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroPrestacaoNfe = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroPrestacaoVenda = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdVenda?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroPrestacoesNfe = {
	IdNfe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroPrestacoesVenda = {
	IdVenda?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroProduto = {
	Cest?: InputMaybe<Scalars["String"]>;
	Cfop?: InputMaybe<Scalars["String"]>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CodigoSecundario?: InputMaybe<Scalars["String"]>;
	CustoMedio?: InputMaybe<FloatRange>;
	DataAlteracao?: InputMaybe<DateTimeRange>;
	DataCadastro?: InputMaybe<DateTimeRange>;
	DefinicaoMateriaPrima?: InputMaybe<Scalars["Status"]>;
	DefinicaoProducao?: InputMaybe<Scalars["Status"]>;
	DefinicaoServico?: InputMaybe<Scalars["Status"]>;
	DefinicaoUsoConsumo?: InputMaybe<Scalars["Status"]>;
	DefinicaoVenda?: InputMaybe<Scalars["Status"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FiltroCodigo?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	FornecedorVinculado?: InputMaybe<Scalars["Int"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCategoria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsColecao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCstIpi?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCstPisCofins?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsDefinicao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsFornecedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsGradeTipo?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsMarca?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsSituaTribut?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsSubcategoria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsUnidade?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsUnidadeCompra?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsUnidadeTributaria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Ncm?: InputMaybe<Scalars["String"]>;
	Quantidade?: InputMaybe<FloatRange>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	ValorCusto?: InputMaybe<FloatRange>;
	ValorVenda?: InputMaybe<FloatRange>;
	ValorVendaAlt?: InputMaybe<FloatRange>;
};

export type InputFiltroProdutoFiliais = {
	BuscaFiliais?: InputMaybe<Scalars["Boolean"]>;
	IdsProdutos?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroProdutoFilial = {
	IdProduto?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroProdutoFornecedor = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroProdutoKit = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdKit?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroProdutos = {
	Cest?: InputMaybe<Scalars["String"]>;
	Cfop?: InputMaybe<Scalars["String"]>;
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	Codigo?: InputMaybe<Scalars["String"]>;
	CodigoSecundario?: InputMaybe<Scalars["String"]>;
	CustoMedio?: InputMaybe<FloatRange>;
	DataAlteracao?: InputMaybe<DateTimeRange>;
	DataCadastro?: InputMaybe<DateTimeRange>;
	DefinicaoMateriaPrima?: InputMaybe<Scalars["Status"]>;
	DefinicaoProducao?: InputMaybe<Scalars["Status"]>;
	DefinicaoServico?: InputMaybe<Scalars["Status"]>;
	DefinicaoUsoConsumo?: InputMaybe<Scalars["Status"]>;
	DefinicaoVenda?: InputMaybe<Scalars["Status"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	FiltroCodigo?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	FornecedorVinculado?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCategoria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsColecao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCstIpi?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCstPisCofins?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsDefinicao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsFornecedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsGradeTipo?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsMarca?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsSituaTribut?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsSubcategoria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsUnidade?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsUnidadeCompra?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsUnidadeTributaria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Ncm?: InputMaybe<Scalars["String"]>;
	Quantidade?: InputMaybe<FloatRange>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Status"]>>>;
	ValorCusto?: InputMaybe<FloatRange>;
	ValorVenda?: InputMaybe<FloatRange>;
	ValorVendaAlt?: InputMaybe<FloatRange>;
};

export type InputFiltroProdutosFornecedor = {
	CodBarras?: InputMaybe<Scalars["String"]>;
	CodFornecedor?: InputMaybe<Scalars["String"]>;
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroProdutosKit = {
	IdKit?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroQueryCredito = {
	Hash?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroQueryCreditoCliente = {
	IdPessoa: Scalars["Int"];
};

export type InputFiltroQueryCreditos = {
	CodigoFinanceiro?: InputMaybe<Scalars["String"]>;
	Data?: InputMaybe<DateRange>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	Hash?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	PessoaNome?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<StatusCredito>>>;
};

export type InputFiltroQueryLancamento = {
	Data?: InputMaybe<DateRange>;
	Historico?: InputMaybe<Scalars["String"]>;
	IdConta?: InputMaybe<Scalars["Int"]>;
	IdParcelamento?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	IdsOperacoes?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NivelDefinicao?: InputMaybe<Array<InputMaybe<TipoNivelDefinicao>>>;
	SomentePrimeiroReg?: InputMaybe<Scalars["Boolean"]>;
};

export type InputFiltroRankingVenda = {
	Limit?: InputMaybe<Scalars["Int"]>;
	Periodo: RequiredDateRange;
};

export type InputFiltroRecebimentoCartao = {
	DataVencimentoCartao: Scalars["Date"];
	IdOperadoraCartao: Scalars["Int"];
	IdsEmpresas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroRecebimentos = {
	CodigoFinanceiro?: InputMaybe<Scalars["String"]>;
	DataEmissao?: InputMaybe<DateRange>;
	DataRecebimento?: InputMaybe<DateRange>;
	DataVencimento?: InputMaybe<DateRange>;
	FiltroRapido?: InputMaybe<Array<InputMaybe<FiltroRapidoReceita>>>;
	Historico?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCliente?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdConta?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsEmpresas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Array<InputMaybe<StatusParcelamento>>>;
	ValorTotal?: InputMaybe<FloatRange>;
};

export type InputFiltroReferenciaComercial = {
	Contato?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCliente?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
	Telefone?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroReferenciaNfe = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdEstado?: InputMaybe<Scalars["Int"]>;
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroReferenciasNfe = {
	IdEstado?: InputMaybe<Scalars["Int"]>;
	IdFornecedor?: InputMaybe<Scalars["Int"]>;
	IdNfe?: InputMaybe<Scalars["Int"]>;
	IdNfeRef?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroRegistrosPaf = {
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Periodo: DateRange;
};

export type InputFiltroRegraAtualizada = {
	Tipo?: InputMaybe<TipoRegra>;
};

export type InputFiltroRegraTributacao = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdCestAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopCondicao?: InputMaybe<Scalars["Int"]>;
	IdSituaTributAcao?: InputMaybe<Scalars["Int"]>;
	IdUFCondicao?: InputMaybe<Scalars["Int"]>;
	RegimeTributarioCondicao?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMSCondicao?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoRegra>;
	TipoUFCondicao?: InputMaybe<TipoUfDestino>;
};

export type InputFiltroRegrasTributacao = {
	IdCestAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopAcao?: InputMaybe<Scalars["Int"]>;
	IdCfopCondicao?: InputMaybe<Scalars["Int"]>;
	IdSituaTributAcao?: InputMaybe<Scalars["Int"]>;
	IdUFCondicao?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	RegimeTributarioCondicao?: InputMaybe<RegimeTributario>;
	RegimeTributarioICMSCondicao?: InputMaybe<RegimeTributarioIcms>;
	Status?: InputMaybe<Scalars["Status"]>;
	Tipo?: InputMaybe<TipoRegra>;
	TipoCadastro?: InputMaybe<TipoCadastroRegra>;
	TipoUFCondicao?: InputMaybe<TipoUfDestino>;
};

export type InputFiltroSaldoContasFinanceiras = {
	IdsContasFinanceiras?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	TiposContasFinanceiras?: InputMaybe<Array<InputMaybe<TipoContaFinanceira>>>;
};

export type InputFiltroSintegra = {
	Finalidade: FinalidadeSintegra;
	IncluirAjusteEstoque?: InputMaybe<Scalars["Boolean"]>;
	IncluirInativos?: InputMaybe<Scalars["Boolean"]>;
	Periodo: Scalars["Date"];
	TipoCusto: TipoCusto;
};

export type InputFiltroSituacaoTributaria = {
	Categoria?: InputMaybe<CategoriaSituacao>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroSituacoesTributarias = {
	Categoria?: InputMaybe<CategoriaSituacao>;
	Categorias?: InputMaybe<Array<InputMaybe<CategoriaSituacao>>>;
	Codigo?: InputMaybe<Scalars["String"]>;
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroSpedFiscal = {
	Atividade: AtividadeSped;
	Dados: Array<InputMaybe<DadosSped>>;
	FinalidadeArquivo: FinalidadeArquivoSped;
	Perfil: PerfilSped;
	Periodo: DateRange;
};

export type InputFiltroStatusVenda = {
	Estagio?: InputMaybe<Estagio>;
	Id?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroStatusVendas = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Estagio?: InputMaybe<Estagio>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroSubcategoria = {
	Descricao?: InputMaybe<Scalars["String"]>;
	DescricaoCategoria?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeCategoria?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroSubcategorias = {
	Descricao?: InputMaybe<Scalars["String"]>;
	DescricaoCategoria?: InputMaybe<Scalars["String"]>;
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Nome?: InputMaybe<Scalars["String"]>;
	NomeCategoria?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroTipoDependente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroTiposDependente = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroToken = {
	DataExpiracao?: InputMaybe<Scalars["DateTime"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Array<InputMaybe<StatusToken>>>;
	Tipo?: InputMaybe<TipoToken>;
	Token1?: InputMaybe<Scalars["String"]>;
	Token2?: InputMaybe<Scalars["String"]>;
	Token3?: InputMaybe<Scalars["String"]>;
	TokenRefresh?: InputMaybe<Scalars["String"]>;
	TokenTerceiro?: InputMaybe<Scalars["String"]>;
};

export type InputFiltroTotalizadorTrocasDevolucao = {
	Periodo: RequiredDateRange;
};

export type InputFiltroTotalizadorVenda = {
	Periodo: RequiredDateRange;
};

export type InputFiltroTransportadora = {
	Codigo?: InputMaybe<Scalars["String"]>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroTransportadoras = {
	Codigos?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	CpfCnpj?: InputMaybe<Scalars["String"]>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	IdsPessoas?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroUnidade = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	Simbolo?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroUnidades = {
	Descricao?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Simbolo?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroVeiculo = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	Placa?: InputMaybe<Scalars["String"]>;
	Rntc?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroVeiculos = {
	IdTransportadora?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Placa?: InputMaybe<Scalars["String"]>;
	Rntc?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Scalars["Status"]>;
};

export type InputFiltroVenda = {
	Codigo?: InputMaybe<Scalars["String"]>;
	DataEntrega?: InputMaybe<DateRange>;
	DataHora?: InputMaybe<DateTimeRange>;
	EstagioStatus?: InputMaybe<Estagio>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	HashLink?: InputMaybe<Scalars["String"]>;
	Id?: InputMaybe<Scalars["Int"]>;
	IdCliente?: InputMaybe<Scalars["Int"]>;
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	NomeCliente?: InputMaybe<Scalars["String"]>;
	NomeVendedor?: InputMaybe<Scalars["String"]>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoVenda>>>;
};

export type InputFiltroVendaNfe = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNFe?: InputMaybe<Scalars["Int"]>;
	IdVenda?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroVendas = {
	Codigo?: InputMaybe<Scalars["String"]>;
	DataEntrega?: InputMaybe<DateRange>;
	DataHora?: InputMaybe<DateTimeRange>;
	DocFiscal?: InputMaybe<Scalars["String"]>;
	EstagioStatus?: InputMaybe<Estagio>;
	FiltroGeral?: InputMaybe<Scalars["String"]>;
	HashLink?: InputMaybe<Scalars["String"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsCliente?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	NomeCliente?: InputMaybe<Scalars["String"]>;
	NomeVendedor?: InputMaybe<Scalars["String"]>;
	QtdItens?: InputMaybe<FloatRange>;
	Status?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Tipo?: InputMaybe<Array<InputMaybe<TipoVenda>>>;
	ValorTotal?: InputMaybe<FloatRange>;
};

export type InputFiltroVendasNfe = {
	IdNFe?: InputMaybe<Scalars["Int"]>;
	IdVenda?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFiltroVendasPorProduto = {
	Agrupamento?: InputMaybe<AgrupamentoRelatorioVendasPorProduto>;
	Custo: CustoRelatorioVendasPorProduto;
	IdsCategoria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsColecao?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsMarca?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsProduto?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsSubcategoria?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdsVendedor?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Periodo: DateRange;
	Ranking?: InputMaybe<RankingRelatorioVendasPorProduto>;
};

export type InputFiltroVendasPorTributacao = {
	DataEmissao: DateRange;
	DetalhamentoItens?: InputMaybe<Scalars["Boolean"]>;
	IdCfop?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdCstCofins?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdCstIcms?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdCstIpi?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	IdCstPis?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
	Modelo?: InputMaybe<Array<InputMaybe<Modelo>>>;
};

export type InputFiltroVolumeNfe = {
	Id?: InputMaybe<Scalars["Int"]>;
	IdNFe?: InputMaybe<Scalars["Int"]>;
};

export type InputFiltroVolumeVenda = {
	DiasComValor?: InputMaybe<Scalars["Boolean"]>;
	Periodo: RequiredDateRange;
	TipoFiltro: TipoFiltroVolumeVenda;
};

export type InputFiltroVolumesNfe = {
	IdNFe?: InputMaybe<Scalars["Int"]>;
	Ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type InputFornecerAcessoColaboradorEmpresa = {
	Email: Scalars["String"];
};

export type InputGerarVenda = {
	IdPedidoVenda: Scalars["Int"];
};

export type InputGradeMovimento = {
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	Quantidade: Scalars["Float"];
};

export type InputItemGradeDevolucaoCompra = {
	IdColuna?: InputMaybe<Scalars["Int"]>;
	IdGrade?: InputMaybe<Scalars["Int"]>;
	IdLinha?: InputMaybe<Scalars["Int"]>;
	QuantidadeDevolucao: Scalars["Float"];
};

export type InputItemNfeDevolucaoCompra = {
	Id: Scalars["Int"];
	ItemGrade?: InputMaybe<Array<InputItemGradeDevolucaoCompra>>;
	QuantidadeDevolucao: Scalars["Float"];
};

export type InputLancamentoCredito = {
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdCliente: Scalars["Int"];
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdFormaPagamento: Scalars["Int"];
	NumTransacao?: InputMaybe<Scalars["String"]>;
	Parcelas?: InputMaybe<Array<InputParcelasPrazo>>;
	Valor: Scalars["Float"];
};

export type InputLancamentoDespesa = {
	ContaFinanceira?: InputMaybe<Scalars["Int"]>;
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdContaDespesa: Scalars["Int"];
	IdFormaPagamento: Scalars["Int"];
	IdPessoa?: InputMaybe<Scalars["Int"]>;
	Parcelas?: InputMaybe<Array<InputParcelasPrazo>>;
	TaxaJuro?: InputMaybe<Scalars["Float"]>;
	ValorTotal: Scalars["Float"];
};

export type InputLancamentoReceita = {
	ContaFinanceira?: InputMaybe<Scalars["Int"]>;
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaReceita: Scalars["Int"];
	IdFormaPagamento: Scalars["Int"];
	IdPessoa: Scalars["Int"];
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	NumTransacao?: InputMaybe<Scalars["String"]>;
	Parcelas?: InputMaybe<Array<InputParcelasPrazo>>;
	TaxaJuro?: InputMaybe<Scalars["Float"]>;
	ValorTotal: Scalars["Float"];
};

export type InputOrdenacao = {
	Campo: Scalars["String"];
	Direcao?: InputMaybe<DirecaoOrdenacao>;
};

export type InputPagamentoDespesa = {
	DataPagamento: Scalars["DateTime"];
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdFormaPagamento: Scalars["Int"];
	IdParcelamento: Scalars["Int"];
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorJuros?: InputMaybe<Scalars["Float"]>;
};

export type InputPagamentoParcialDespesa = {
	DataPagamento: Scalars["DateTime"];
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdFormaPagamento: Scalars["Int"];
	IdParcelamento: Scalars["Int"];
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorJuros?: InputMaybe<Scalars["Float"]>;
	ValorPago: Scalars["Float"];
};

export type InputPaginacao = {
	Ordenacao?: InputMaybe<InputOrdenacao>;
	Pagina?: InputMaybe<Scalars["Int"]>;
	RegistrosPorPagina?: InputMaybe<Scalars["Int"]>;
};

export type InputParcelasPrazo = {
	DataVencimento: Scalars["Date"];
	JuroParcela?: InputMaybe<Scalars["Float"]>;
	ValorParcela: Scalars["Float"];
};

export type InputPermissaoColaborador = {
	IdPermissao: Scalars["Int"];
	Opcoes: Array<InputMaybe<Scalars["String"]>>;
};

export type InputRecalculaNfe = {
	Itens: Array<InputRecalculaNfeItens>;
	Origem: OrigemNFe;
	ValorDesconto?: Scalars["Float"];
	ValorFrete?: Scalars["Float"];
	ValorIcmsSt?: Scalars["Float"];
	ValorIi?: Scalars["Float"];
	ValorIpi?: Scalars["Float"];
	ValorOutro?: Scalars["Float"];
	ValorSeguro?: Scalars["Float"];
};

export type InputRecalculaNfeItens = {
	IdProduto?: InputMaybe<Scalars["Int"]>;
	Index: Scalars["Int"];
	Quantidade: Scalars["Float"];
	ValorAcrescimo?: Scalars["Float"];
	ValorDesconto?: Scalars["Float"];
	ValorFcpSt: Scalars["Float"];
	ValorIcmsSt: Scalars["Float"];
	ValorIi: Scalars["Float"];
	ValorIpi: Scalars["Float"];
	ValorUnitario: Scalars["Float"];
};

export type InputRecebimentoParcialReceita = {
	ConversaoOutroSistema?: InputMaybe<Scalars["Boolean"]>;
	DataPagamento: Scalars["DateTime"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdFormaPagamento: Scalars["Int"];
	IdParcela: Scalars["Int"];
	NumeroPrestacoes?: InputMaybe<Scalars["Int"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorJuros?: InputMaybe<Scalars["Float"]>;
	ValorRecebido: Scalars["Float"];
};

export type InputRecebimentoReceita = {
	DataPagamento: Scalars["Date"];
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdContaFinanceiro?: InputMaybe<Scalars["Int"]>;
	IdFormaPagamento?: InputMaybe<Scalars["Int"]>;
	IdParcela: Scalars["Int"];
	NumeroPrestacoes?: InputMaybe<Scalars["Int"]>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorJuros?: InputMaybe<Scalars["Float"]>;
};

export type InputRemoverAcessoColaboradorEmpresa = {
	IdColaboradorEmpresa: Scalars["Int"];
};

export type InputRemoverUsuario = {
	Id: Scalars["Int"];
};

export type InputRenegociacaoDespesa = {
	ContaFinanceira?: InputMaybe<Scalars["Int"]>;
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdFormaPagamento: Scalars["Int"];
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	IdsParcelas: Array<Scalars["Int"]>;
	Parcelas?: InputMaybe<Array<InputParcelasPrazo>>;
	TaxaJuro?: InputMaybe<Scalars["Float"]>;
	ValorTotal: Scalars["Float"];
};

export type InputRenegociacaoReceita = {
	ContaFinanceira?: InputMaybe<Scalars["Int"]>;
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdAdminCartao?: InputMaybe<Scalars["Int"]>;
	IdFormaPagamento: Scalars["Int"];
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	IdsParcelas: Array<Scalars["Int"]>;
	Parcelas?: InputMaybe<Array<InputParcelasPrazo>>;
	TaxaJuro?: InputMaybe<Scalars["Float"]>;
	ValorTotal: Scalars["Float"];
};

export type InputRequisicaoAcesso = {
	CorrelationId: Scalars["String"];
	IdEmpresa: Scalars["Int"];
};

export type InputResetarDadosEmpresa = {
	DeletaEmpresa?: InputMaybe<Scalars["Boolean"]>;
	IdEmpresa: Scalars["Int"];
};

export type InputRespostaAcesso = {
	CorrelationId: Scalars["String"];
	IdSuporte: Scalars["Int"];
	Resposta: Scalars["Boolean"];
};

export type InputRetornoCondicional = {
	DataRetorno?: InputMaybe<Scalars["DateTime"]>;
	Id: Scalars["Int"];
	ItensRetorno: Array<InputMaybe<InputRetornoItemCondicional>>;
	Status?: InputMaybe<StatusCondicional>;
	ValorDesconto?: InputMaybe<Scalars["Float"]>;
	ValorFrete?: InputMaybe<Scalars["Float"]>;
};

export type InputRetornoItemCondicional = {
	Id: Scalars["Int"];
	QtdDevolvida?: InputMaybe<Scalars["Float"]>;
	QtdFaturada?: InputMaybe<Scalars["Float"]>;
};

export type InputSaldoInicialContaFinanceira = {
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdConta: Scalars["Int"];
	Valor: Scalars["Float"];
};

export type InputTransferenciaContaFinanceira = {
	DataLancamento: Scalars["DateTime"];
	Historico?: InputMaybe<Scalars["String"]>;
	IdContaDestino: Scalars["Int"];
	IdContaOrigem: Scalars["Int"];
	Valor: Scalars["Float"];
};

export type Intermediador = {
	__typename?: "Intermediador";
	Cnpj?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	Identificador?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	Status: Scalars["Status"];
};

export type ItemCondicional = {
	__typename?: "ItemCondicional";
	CodBarras?: Maybe<Scalars["String"]>;
	CodBarrasGrade?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	CodigoGrade?: Maybe<Scalars["String"]>;
	CodigoSecundario?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	Descricao: Scalars["String"];
	DescricaoComp?: Maybe<Scalars["String"]>;
	DescricaoGrade?: Maybe<Scalars["String"]>;
	DescricaoProduto: Scalars["String"];
	Id: Scalars["Int"];
	IdColuna?: Maybe<Scalars["Int"]>;
	IdCondicional: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdGrade?: Maybe<Scalars["Int"]>;
	IdLinha?: Maybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	IdUnidade: Scalars["Int"];
	QtdDevolvida?: Maybe<Scalars["Float"]>;
	QtdFaturada?: Maybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	QuantidadeEstoque: Scalars["Float"];
	QuantidadeEstoqueGrade?: Maybe<Scalars["Float"]>;
	SimboloUnidade: Scalars["String"];
	Status: StatusItemCondicional;
	ValorAcrescimo: Scalars["Float"];
	ValorCustoMedioUnitario: Scalars["Float"];
	ValorCustoUnitario: Scalars["Float"];
	ValorDesconto: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorSubTotalPrazo: Scalars["Float"];
	ValorTotalItem: Scalars["Float"];
	ValorTotalItemPrazo: Scalars["Float"];
	ValorUnitario: Scalars["Float"];
	ValorUnitarioPrazo: Scalars["Float"];
};

export type ItemDevolucao = {
	__typename?: "ItemDevolucao";
	Id: Scalars["Int"];
	IdDevolucao: Scalars["Int"];
	IdGrade?: Maybe<Scalars["Int"]>;
	IdItemVenda: Scalars["Int"];
	IdNFe?: Maybe<Scalars["Int"]>;
	IdProduto?: Maybe<Scalars["Int"]>;
	Quantidade?: Maybe<Scalars["Float"]>;
};

export type ItemGrade = {
	__typename?: "ItemGrade";
	ColunaCodigo?: Maybe<Scalars["Int"]>;
	ColunaId?: Maybe<Scalars["Int"]>;
	ColunaNome?: Maybe<Scalars["String"]>;
	GradeCodBarras?: Maybe<Scalars["String"]>;
	GradePreco1?: Maybe<Scalars["Float"]>;
	GradePreco2?: Maybe<Scalars["Float"]>;
	GradeQuantidade?: Maybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdGrade: Scalars["Int"];
	IdItemNfe?: Maybe<Scalars["Int"]>;
	LinhaCodigo: Scalars["Int"];
	LinhaId: Scalars["Int"];
	LinhaNome: Scalars["String"];
	Quantidade?: Maybe<Scalars["Float"]>;
	Valor?: Maybe<Scalars["Float"]>;
};

export enum ItemMovimentoEstoque {
	Movimentou = "MOVIMENTOU",
	NaoMovimentou = "NAO_MOVIMENTOU",
}

export type ItemNfe = {
	__typename?: "ItemNfe";
	AliqIcmsDif?: Maybe<Scalars["Float"]>;
	Armas?: Maybe<Array<Maybe<Arma>>>;
	CodBarras?: Maybe<Scalars["String"]>;
	CodBarrasGrade?: Maybe<Scalars["String"]>;
	CodBenef?: Maybe<Scalars["String"]>;
	CodEnqlIpi?: Maybe<Scalars["String"]>;
	CodFornecedor?: Maybe<Scalars["String"]>;
	CodListServ?: Maybe<Scalars["String"]>;
	Codigo?: Maybe<Scalars["String"]>;
	CodigoCfop?: Maybe<Scalars["String"]>;
	CodigoSituaTribut?: Maybe<Scalars["String"]>;
	CodigoSituaTributCofins?: Maybe<Scalars["String"]>;
	CodigoSituaTributCompra?: Maybe<Scalars["String"]>;
	CodigoSituaTributIpi?: Maybe<Scalars["String"]>;
	CodigoSituaTributPis?: Maybe<Scalars["String"]>;
	DefinicaoMateriaPrima?: Maybe<Scalars["Status"]>;
	DefinicaoProducao?: Maybe<Scalars["Status"]>;
	DefinicaoServico?: Maybe<Scalars["Status"]>;
	DefinicaoUsoConsumo?: Maybe<Scalars["Status"]>;
	DefinicaoVenda?: Maybe<Scalars["Status"]>;
	Descricao: Scalars["String"];
	DescricaoComp?: Maybe<Scalars["String"]>;
	DescricaoGrade?: Maybe<Scalars["String"]>;
	DescricaoProduto: Scalars["String"];
	DescricaoSituaTribut?: Maybe<Scalars["String"]>;
	DescricaoSituaTributCofins?: Maybe<Scalars["String"]>;
	DescricaoSituaTributCompra?: Maybe<Scalars["String"]>;
	DescricaoSituaTributIpi?: Maybe<Scalars["String"]>;
	DescricaoSituaTributPis?: Maybe<Scalars["String"]>;
	DetalhamentoItem?: Maybe<Scalars["String"]>;
	Exportacoes?: Maybe<Array<Maybe<Exportacao>>>;
	FatorConversao?: Maybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	IdCest?: Maybe<Scalars["Int"]>;
	IdCfop: Scalars["Int"];
	IdColuna?: Maybe<Scalars["Int"]>;
	IdCstCofins?: Maybe<Scalars["Int"]>;
	IdCstIcmsCompra?: Maybe<Scalars["Int"]>;
	IdCstIpi?: Maybe<Scalars["Int"]>;
	IdCstPis?: Maybe<Scalars["Int"]>;
	IdDefinicao?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdGrade?: Maybe<Scalars["Int"]>;
	IdGradeTipo?: Maybe<Scalars["Int"]>;
	IdLinha?: Maybe<Scalars["Int"]>;
	IdNcm?: Maybe<Scalars["Int"]>;
	IdNfe: Scalars["Int"];
	IdOperacaoCfop?: Maybe<TipoCfopOperacao>;
	IdProduto?: Maybe<Scalars["Int"]>;
	IdSituaTribut?: Maybe<Scalars["Int"]>;
	IdUnidade: Scalars["Int"];
	IdUnidadeCompra?: Maybe<Scalars["Int"]>;
	Importacoes?: Maybe<Array<Maybe<Importacao>>>;
	ItensGrade?: Maybe<Array<Maybe<ItemGrade>>>;
	MargemLucro?: Maybe<Scalars["Float"]>;
	MotDesIcms?: Maybe<Scalars["Int"]>;
	MovEstoqueCfop?: Maybe<Scalars["Status"]>;
	MovFinanceiroCfop?: Maybe<Scalars["Status"]>;
	NumItemPedido?: Maybe<Scalars["String"]>;
	OutrasOpcoes?: Maybe<Scalars["String"]>;
	PedidoDeCompra?: Maybe<Scalars["String"]>;
	PorcentCofins?: Maybe<Scalars["Float"]>;
	PorcentCredSN?: Maybe<Scalars["Float"]>;
	PorcentDevol?: Maybe<Scalars["Float"]>;
	PorcentFcp?: Maybe<Scalars["Float"]>;
	PorcentFcpSt?: Maybe<Scalars["Float"]>;
	PorcentFcpStRet?: Maybe<Scalars["Float"]>;
	PorcentFcpUfDest?: Maybe<Scalars["Float"]>;
	PorcentIcms?: Maybe<Scalars["Float"]>;
	PorcentIcmsEfet?: Maybe<Scalars["Float"]>;
	PorcentIcmsInter?: Maybe<Scalars["Float"]>;
	PorcentIcmsSt?: Maybe<Scalars["Float"]>;
	PorcentIcmsUFDest?: Maybe<Scalars["Float"]>;
	PorcentIpi?: Maybe<Scalars["Float"]>;
	PorcentMvaSt?: Maybe<Scalars["Float"]>;
	PorcentPis?: Maybe<Scalars["Float"]>;
	PorcentRedBC?: Maybe<Scalars["Float"]>;
	PorcentRedBCEfet?: Maybe<Scalars["Float"]>;
	PorcentSt?: Maybe<Scalars["Float"]>;
	PrecoVenda?: Maybe<Scalars["Float"]>;
	PrecoVenda2?: Maybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	QuantidadeBCCofins?: Maybe<Scalars["Float"]>;
	QuantidadeBCPis?: Maybe<Scalars["Float"]>;
	QuantidadeCompra?: Maybe<Scalars["Float"]>;
	QuantidadeEstoque: Scalars["Float"];
	QuantidadeEstoqueGrade?: Maybe<Scalars["Float"]>;
	SimboloUnidade: Scalars["String"];
	SimboloUnidadeCompra?: Maybe<Scalars["String"]>;
	ValorAcrescimo?: Maybe<Scalars["Float"]>;
	ValorBCCofins?: Maybe<Scalars["Float"]>;
	ValorBCEfet?: Maybe<Scalars["Float"]>;
	ValorBCFcp?: Maybe<Scalars["Float"]>;
	ValorBCFcpSt?: Maybe<Scalars["Float"]>;
	ValorBCFcpStRet?: Maybe<Scalars["Float"]>;
	ValorBCFcpUfDest?: Maybe<Scalars["Float"]>;
	ValorBCIcms?: Maybe<Scalars["Float"]>;
	ValorBCIcmsSt?: Maybe<Scalars["Float"]>;
	ValorBCIpi?: Maybe<Scalars["Float"]>;
	ValorBCPis?: Maybe<Scalars["Float"]>;
	ValorBCStRet?: Maybe<Scalars["Float"]>;
	ValorCofins?: Maybe<Scalars["Float"]>;
	ValorCredIcmsSN?: Maybe<Scalars["Float"]>;
	ValorCustoMedioUnitario: Scalars["Float"];
	ValorCustoUnitario: Scalars["Float"];
	ValorDesconto: Scalars["Float"];
	ValorDescontoTotal: Scalars["Float"];
	ValorFcp?: Maybe<Scalars["Float"]>;
	ValorFcpSt?: Maybe<Scalars["Float"]>;
	ValorFcpStRet?: Maybe<Scalars["Float"]>;
	ValorFcpUfDest?: Maybe<Scalars["Float"]>;
	ValorFrete: Scalars["Float"];
	ValorIcms?: Maybe<Scalars["Float"]>;
	ValorIcmsDeson?: Maybe<Scalars["Float"]>;
	ValorIcmsEfet?: Maybe<Scalars["Float"]>;
	ValorIcmsSt?: Maybe<Scalars["Float"]>;
	ValorIcmsStRet?: Maybe<Scalars["Float"]>;
	ValorIcmsSubstituto?: Maybe<Scalars["Float"]>;
	ValorIcmsUFDest?: Maybe<Scalars["Float"]>;
	ValorIi?: Maybe<Scalars["Float"]>;
	ValorIpi?: Maybe<Scalars["Float"]>;
	ValorIpiDevol?: Maybe<Scalars["Float"]>;
	ValorOutro: Scalars["Float"];
	ValorPis?: Maybe<Scalars["Float"]>;
	ValorSeguro: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorTotalItem: Scalars["Float"];
	ValorUnitario: Scalars["Float"];
};

export type ItemVenda = {
	__typename?: "ItemVenda";
	CodBarras?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	DataAlteracao?: Maybe<Scalars["DateTime"]>;
	DataCadastro?: Maybe<Scalars["DateTime"]>;
	DefinicaoProducao?: Maybe<Scalars["Status"]>;
	DefinicaoServico?: Maybe<Scalars["Status"]>;
	DefinicaoVenda?: Maybe<Scalars["Status"]>;
	Descricao: Scalars["String"];
	DescricaoComp?: Maybe<Scalars["String"]>;
	DescricaoGrade?: Maybe<Scalars["String"]>;
	DescricaoProduto: Scalars["String"];
	Id: Scalars["Int"];
	IdColuna?: Maybe<Scalars["Int"]>;
	IdGrade?: Maybe<Scalars["Int"]>;
	IdLinha?: Maybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	IdUnidade: Scalars["Int"];
	IdVenda: Scalars["Int"];
	InformacoesAdicionais?: Maybe<Scalars["String"]>;
	QtdConferida?: Maybe<Scalars["Float"]>;
	QtdFaturada?: Maybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	QuantidadeEstoque: Scalars["Float"];
	QuantidadeEstoqueGrade?: Maybe<Scalars["Float"]>;
	SimboloUnidade: Scalars["String"];
	ValorAcrescimo?: Maybe<Scalars["Float"]>;
	ValorCustoMedioUnitario?: Maybe<Scalars["Float"]>;
	ValorCustoUnitario?: Maybe<Scalars["Float"]>;
	ValorDesconto?: Maybe<Scalars["Float"]>;
	ValorDescontoTotal?: Maybe<Scalars["Float"]>;
	ValorFrete?: Maybe<Scalars["Float"]>;
	ValorJuro?: Maybe<Scalars["Float"]>;
	ValorSeguro?: Maybe<Scalars["Float"]>;
	ValorSubTotal: Scalars["Float"];
	ValorTotalItem: Scalars["Float"];
	ValorUnitario: Scalars["Float"];
};

export enum Lancamentos_Permissao {
	Cadastrar = "CADASTRAR",
	Deletar = "DELETAR",
	Editar = "EDITAR",
	EfetuarLancamentosFuturos = "EFETUAR_LANCAMENTOS_FUTUROS",
	EfetuarLancamentosRetroativos = "EFETUAR_LANCAMENTOS_RETROATIVOS",
}

export type Lancamento = {
	__typename?: "Lancamento";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	DataHoraRegistro: Scalars["DateTime"];
	DataLancamento: Scalars["DateTime"];
	FormaPgtoNome?: Maybe<Scalars["String"]>;
	FormaPgtoPrest?: Maybe<Scalars["String"]>;
	Historico?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdAdminCartao?: Maybe<Scalars["Int"]>;
	IdContaFinanceiro?: Maybe<Scalars["Int"]>;
	IdDevolucao?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdFinanceiro?: Maybe<Scalars["Int"]>;
	IdFormaPgto?: Maybe<Scalars["Int"]>;
	IdLancamento: Scalars["Int"];
	IdNFe?: Maybe<Scalars["Int"]>;
	IdNivel: Scalars["Int"];
	IdOperacao: TipoOperacao;
	IdParcelamento?: Maybe<Scalars["Int"]>;
	IdPessoa?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	IdVenda?: Maybe<Scalars["Int"]>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	NivelDescricao: Scalars["String"];
	NumPrest?: Maybe<Scalars["Int"]>;
	OperacaoNome: Scalars["String"];
	PessoaNome?: Maybe<Scalars["String"]>;
	PessoaNomeFantasia?: Maybe<Scalars["String"]>;
	UsuarioNome: Scalars["String"];
	ValorTotal: Scalars["Float"];
};

export enum LocalExibicao {
	Alerta = "ALERTA",
	Outro = "OUTRO",
	Popover = "POPOVER",
	Toast = "TOAST",
}

export enum Marca_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Movimento_Contas_Financeiras_Permissao {
	Visualizar = "VISUALIZAR",
}

export type Manifesto = {
	__typename?: "Manifesto";
	Chave: Scalars["String"];
	Cnpj: Scalars["String"];
	Emissao: Scalars["DateTime"];
	Evento?: Maybe<EventoManifestoNFe>;
	FinalidadeNfe?: Maybe<FinalidadeNFe>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	JustificativaEvento?: Maybe<Scalars["String"]>;
	NaturezaOperacao?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NumNfe: Scalars["Int"];
	Status: StatusManifestoNFe;
	Valor: Scalars["Float"];
};

export type Marca = {
	__typename?: "Marca";
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	Status: Scalars["Status"];
};

export enum Meses {
	Abril = "ABRIL",
	Agosto = "AGOSTO",
	Dezembro = "DEZEMBRO",
	Fevereiro = "FEVEREIRO",
	Janeiro = "JANEIRO",
	Julho = "JULHO",
	Junho = "JUNHO",
	Maio = "MAIO",
	Marco = "MARCO",
	Novembro = "NOVEMBRO",
	Outubro = "OUTUBRO",
	Setembro = "SETEMBRO",
}

export type Meta = {
	__typename?: "Meta";
	Id: Scalars["Int"];
	IdVendedor?: Maybe<Scalars["Int"]>;
	NomeMeta: Scalars["String"];
	NomeVendedor?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	TipoBonificacao: TipoBonificacao;
	ValorBonificacao: Scalars["Float"];
	ValorMeta: Scalars["Float"];
};

export enum ModalidadeCliente {
	ConsumidorFinal = "CONSUMIDOR_FINAL",
	Revendedor = "REVENDEDOR",
}

export enum Modelo {
	Nfce = "NFCE",
	Nfe = "NFE",
}

export enum Modelo1A {
	Mod_01 = "MOD_01",
	Mod_02 = "MOD_02",
}

export enum ModeloReferencia {
	Mod_01 = "MOD_01",
	Mod_02 = "MOD_02",
	Nfce = "NFCE",
	Nfe = "NFE",
}

export type Modulo = {
	__typename?: "Modulo";
	Id: Scalars["Int"];
	Nome: Scalars["String"];
	Observacao: Scalars["String"];
};

export type ModuloEmpresa = {
	__typename?: "ModuloEmpresa";
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdModulo: Scalars["Int"];
	Nome: Scalars["String"];
	Observacao: Scalars["String"];
	Status: Scalars["Status"];
};

export enum Modulos {
	Cadastro = "CADASTRO",
	Comercial = "COMERCIAL",
	Estoque = "ESTOQUE",
	Financeiro = "FINANCEIRO",
	Fiscal = "FISCAL",
	Geral = "GERAL",
}

export type MovimentoContaFinanceira = {
	__typename?: "MovimentoContaFinanceira";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	Data: Scalars["Date"];
	EntradaSaida: Scalars["Float"];
	Historico?: Maybe<Scalars["String"]>;
	Hora: Scalars["Time"];
	Id: Scalars["Int"];
	IdConta?: Maybe<Scalars["Int"]>;
	IdFormaPgto?: Maybe<Scalars["Int"]>;
	IdLancamento: Scalars["Int"];
	IdNivelDefinicao?: Maybe<Scalars["Int"]>;
	IdNivelPlanoContas?: Maybe<Scalars["Int"]>;
	IdPessoa?: Maybe<Scalars["Int"]>;
	IdPlanoContas?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	NomeConta?: Maybe<Scalars["String"]>;
	NomeFormaPgto?: Maybe<Scalars["String"]>;
	NomeNivelPlanoContas?: Maybe<Scalars["String"]>;
	NomeOperacao: Scalars["String"];
	NomePessoa?: Maybe<Scalars["String"]>;
	NomeUsuario: Scalars["String"];
	Saldo: Scalars["Float"];
	SaldoAnterior: Scalars["Float"];
	TipoLancamento: Scalars["Int"];
};

export type MovimentoEstoque = {
	__typename?: "MovimentoEstoque";
	CodigoProduto: Scalars["String"];
	Data: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	DescricaoProduto: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdGrade?: Maybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	IdUsuario: Scalars["Int"];
	NomeColuna?: Maybe<Scalars["String"]>;
	NomeLinha?: Maybe<Scalars["String"]>;
	NomeUsuario: Scalars["String"];
	Origem: OrigemMovimento;
	Quantidade: Scalars["Float"];
	Valor: Scalars["Float"];
};

export type Mutation = {
	__typename?: "Mutation";
	alterarSenha: Scalars["RetornoPadrao"];
	atualizaConfiguracaoEmpresa: Scalars["RetornoPadrao"];
	atualizaConfiguracoesEmpresa: Scalars["RetornoPadrao"];
	atualizaModuloEmpresa: Scalars["RetornoPadrao"];
	atualizarAcesso: Scalars["RetornoTarefa"];
	baixaCredito: Scalars["RetornoPadrao"];
	cadastraAdminCartao: Scalars["RetornoPadrao"];
	cadastraCategoria: Scalars["RetornoPadrao"];
	cadastraCategoriaCliente: Scalars["RetornoPadrao"];
	cadastraCest: Scalars["RetornoPadrao"];
	cadastraCfop: Scalars["RetornoPadrao"];
	cadastraCliente: Scalars["RetornoPadrao"];
	cadastraClienteVinculaPessoa: Scalars["RetornoPadrao"];
	cadastraColaborador: Scalars["RetornoPadrao"];
	cadastraColaboradorVinculaPessoa: Scalars["RetornoPadrao"];
	cadastraColecao: Scalars["RetornoPadrao"];
	cadastraComissoesFormaPgto: Scalars["RetornoPadrao"];
	cadastraCondicional: Scalars["RetornoPadrao"];
	cadastraContaFinanceira: Scalars["RetornoPadrao"];
	cadastraContador: Scalars["RetornoPadrao"];
	cadastraContadorPessoa: Scalars["RetornoPadrao"];
	cadastraContatoPessoa: Scalars["RetornoPadrao"];
	cadastraDefinicao: Scalars["RetornoPadrao"];
	cadastraDepartamento: Scalars["RetornoPadrao"];
	cadastraDevolucao: Scalars["RetornoPadrao"];
	cadastraEmpresa: Empresa;
	cadastraEnderecoAdicional: Scalars["RetornoPadrao"];
	cadastraEventoNfe: Scalars["RetornoPadrao"];
	cadastraFormaPagamento: Scalars["RetornoPadrao"];
	cadastraFornecedor: Scalars["RetornoPadrao"];
	cadastraFornecedorVinculaPessoa: Scalars["RetornoPadrao"];
	cadastraGenero: Scalars["RetornoPadrao"];
	cadastraGradeCampo: Scalars["RetornoPadrao"];
	cadastraGradeEixo: Scalars["RetornoPadrao"];
	cadastraGradeTipo: Scalars["RetornoPadrao"];
	cadastraIntermediador: Scalars["RetornoPadrao"];
	cadastraMarca: Scalars["RetornoPadrao"];
	cadastraMeta: Scalars["RetornoPadrao"];
	cadastraMovimentoEstoque: Scalars["RetornoPadrao"];
	cadastraNcm: Scalars["RetornoPadrao"];
	cadastraNfe?: Maybe<Scalars["RetornoPadrao"]>;
	cadastraNfeCompra?: Maybe<Scalars["RetornoPadrao"]>;
	cadastraNivel: Scalars["RetornoPadrao"];
	cadastraNotificacao: Scalars["RetornoPadrao"];
	cadastraPdv: Scalars["RetornoPadrao"];
	cadastraPedidoVenda: Scalars["RetornoPadrao"];
	cadastraPessoa: Scalars["RetornoPadrao"];
	cadastraProduto: Scalars["RetornoPadrao"];
	cadastraProdutoFornecedor: Scalars["RetornoPadrao"];
	cadastraRegraTributacao: Scalars["RetornoPadrao"];
	cadastraStatusVenda: Scalars["RetornoPadrao"];
	cadastraSubcategoria: Scalars["RetornoPadrao"];
	cadastraTipoDependente: Scalars["RetornoPadrao"];
	cadastraTokenTerceiro?: Maybe<Scalars["RetornoPadrao"]>;
	cadastraTransportadora: Scalars["RetornoPadrao"];
	cadastraTransportadoraPessoa: Scalars["RetornoPadrao"];
	cadastraUnidade: Scalars["RetornoPadrao"];
	cadastraVenda: Scalars["RetornoPadrao"];
	cadastraVendaPdv: Scalars["RetornoPadrao"];
	cancelaVenda: Scalars["RetornoPadrao"];
	cancelarAcesso: Scalars["RetornoPadrao"];
	confirmaNfeCompra?: Maybe<Scalars["RetornoPadrao"]>;
	confirmaVenda: Scalars["RetornoPadrao"];
	converteCondicionalEmVenda: Scalars["RetornoPadrao"];
	deletaLancamento: Scalars["RetornoPadrao"];
	editaAdminCartao: Scalars["RetornoPadrao"];
	editaAliquotaIcms: Scalars["RetornoPadrao"];
	editaCategoria: Scalars["RetornoPadrao"];
	editaCategoriaCliente: Scalars["RetornoPadrao"];
	editaCest: Scalars["RetornoPadrao"];
	editaCfop: Scalars["RetornoPadrao"];
	editaCliente: Scalars["RetornoPadrao"];
	editaColaborador: Scalars["RetornoPadrao"];
	editaColecao: Scalars["RetornoPadrao"];
	editaComissoesFormaPgto?: Maybe<Scalars["RetornoPadrao"]>;
	editaCondicional: Scalars["RetornoPadrao"];
	editaContaFinanceira: Scalars["RetornoPadrao"];
	editaContador: Scalars["RetornoPadrao"];
	editaContatoPessoa: Scalars["RetornoPadrao"];
	editaDefinicao: Scalars["RetornoPadrao"];
	editaDepartamento: Scalars["RetornoPadrao"];
	editaDevolucao?: Maybe<Scalars["RetornoPadrao"]>;
	editaEmpresa: Scalars["RetornoPadrao"];
	editaEmpresas: Scalars["RetornoPadrao"];
	editaEnderecoAdicional: Scalars["RetornoPadrao"];
	editaFormaPagamento: Scalars["RetornoPadrao"];
	editaFornecedor: Scalars["RetornoPadrao"];
	editaGenero: Scalars["RetornoPadrao"];
	editaGradeCampo: Scalars["RetornoPadrao"];
	editaGradeEixo: Scalars["RetornoPadrao"];
	editaGradeTipo: Scalars["RetornoPadrao"];
	editaIntermediador: Scalars["RetornoPadrao"];
	editaLancamentoDespesa: Scalars["RetornoPadrao"];
	editaLancamentoReceita: Scalars["RetornoPadrao"];
	editaManifesto: Scalars["RetornoPadrao"];
	editaMarca: Scalars["RetornoPadrao"];
	editaMeta?: Maybe<Scalars["RetornoPadrao"]>;
	editaNcm: Scalars["RetornoPadrao"];
	editaNfe?: Maybe<Scalars["RetornoPadrao"]>;
	editaNfeCompra?: Maybe<Scalars["RetornoPadrao"]>;
	editaNivel: Scalars["RetornoPadrao"];
	editaPdv: Scalars["RetornoPadrao"];
	editaPedidoVenda: Scalars["RetornoPadrao"];
	editaPessoa: Scalars["RetornoPadrao"];
	editaProduto: Scalars["RetornoPadrao"];
	editaProdutoFornecedor: Scalars["RetornoPadrao"];
	editaRegraTributacao: Scalars["RetornoPadrao"];
	editaSituacaoTributaria: Scalars["RetornoPadrao"];
	editaStatusVenda: Scalars["RetornoPadrao"];
	editaSubcategoria: Scalars["RetornoPadrao"];
	editaTipoDependente: Scalars["RetornoPadrao"];
	editaTransportadora: Scalars["RetornoPadrao"];
	editaUnidade: Scalars["RetornoPadrao"];
	editaVenda: Scalars["RetornoPadrao"];
	enviarCertificado: Scalars["RetornoPadrao"];
	enviarCsv: Array<Maybe<Scalars["RetornoEnvioCsv"]>>;
	enviarXMLCompra?: Maybe<Scalars["RetornoTarefa"]>;
	esqueciSenha: Scalars["RetornoPadrao"];
	estornoParcelaReceita: Scalars["RetornoPadrao"];
	excluirCertificado: Scalars["RetornoPadrao"];
	faturaVenda: Scalars["RetornoPadrao"];
	fornecerAcessoColaboradorEmpresa: Scalars["RetornoPadrao"];
	geraFinanceiroDevolucao: Scalars["RetornoPadrao"];
	geraNfceDaVendaPdv: Scalars["RetornoPadrao"];
	geraNfeDaVenda: Scalars["RetornoPadrao"];
	gerarDANFe?: Maybe<Scalars["RetornoTarefa"]>;
	gerarVenda: Scalars["RetornoPadrao"];
	lancamentoCredito: Scalars["RetornoPadrao"];
	lancamentoDespesa: Scalars["RetornoPadrao"];
	lancamentoReceita: Scalars["RetornoPadrao"];
	login?: Maybe<Scalars["RetornoPadrao"]>;
	logout?: Maybe<Scalars["RetornoPadrao"]>;
	pagamentoDespesa: Scalars["RetornoPadrao"];
	pagamentoParcialDespesa: Scalars["RetornoPadrao"];
	recalculaDadosNfeCompra: RecalculaNfe;
	recalculaDadosNfeEmissao: CalculaImpostoNfe;
	recalcularNfe?: Maybe<Scalars["RetornoPadrao"]>;
	recebimentoParcialReceita: Scalars["RetornoPadrao"];
	recebimentoReceita: Scalars["RetornoPadrao"];
	removeGradeEixo: Scalars["RetornoPadrao"];
	removeMovimentoEstoque: Scalars["RetornoPadrao"];
	removeNfeCompra?: Maybe<Scalars["RetornoPadrao"]>;
	removerAcesso: Scalars["RetornoTarefa"];
	removerAcessoColaboradorEmpresa: Scalars["RetornoPadrao"];
	removerUsuario: Scalars["RetornoPadrao"];
	renegociacaoParcelasDespesa: Scalars["RetornoPadrao"];
	renegociacaoParcelasReceita: Scalars["RetornoPadrao"];
	requisicaoAcesso: Scalars["RetornoTarefa"];
	resetaDadosDemonstrativos: Scalars["RetornoPadrao"];
	respostaAcesso: Scalars["RetornoPadrao"];
	retornoCondicional: Scalars["RetornoPadrao"];
	saldoInicialContaFinanceira: Scalars["RetornoPadrao"];
	transferenciaContaFinanceira: Scalars["RetornoPadrao"];
	transformaCondicionalVenda: Scalars["RetornoPadrao"];
	visualizarNotificacao: Scalars["RetornoPadrao"];
	visualizarTodasNotificacoes: Scalars["RetornoPadrao"];
};

export type MutationAlterarSenhaArgs = {
	Dados: InputAlterarSenha;
};

export type MutationAtualizaConfiguracaoEmpresaArgs = {
	Dados: InputCadastrarConfiguracaoEmpresa;
};

export type MutationAtualizaConfiguracoesEmpresaArgs = {
	Dados: Array<InputCadastrarConfiguracaoEmpresa>;
};

export type MutationAtualizaModuloEmpresaArgs = {
	Dados: InputCadastrarModuloEmpresa;
};

export type MutationAtualizarAcessoArgs = {
	CorrelationId: Scalars["String"];
};

export type MutationBaixaCreditoArgs = {
	Dados: InputBaixaCredito;
};

export type MutationCadastraAdminCartaoArgs = {
	Dados: InputCadastrarAdminCartao;
};

export type MutationCadastraCategoriaArgs = {
	Dados: InputCadastrarCategoria;
};

export type MutationCadastraCategoriaClienteArgs = {
	Dados: InputCadastrarCategoriaCliente;
};

export type MutationCadastraCestArgs = {
	Dados: InputCadastrarCest;
};

export type MutationCadastraCfopArgs = {
	Dados: InputCadastrarCfop;
};

export type MutationCadastraClienteArgs = {
	Dados: InputCadastrarCliente;
};

export type MutationCadastraClienteVinculaPessoaArgs = {
	Dados: InputCadastrarClientePessoa;
};

export type MutationCadastraColaboradorArgs = {
	Dados: InputCadastrarColaborador;
};

export type MutationCadastraColaboradorVinculaPessoaArgs = {
	Dados: InputCadastrarColaboradorPessoa;
};

export type MutationCadastraColecaoArgs = {
	Dados: InputCadastrarColecao;
};

export type MutationCadastraComissoesFormaPgtoArgs = {
	Dados: InputCadastrarComissaoFormaPgto;
};

export type MutationCadastraCondicionalArgs = {
	Dados: InputCadastrarCondicional;
};

export type MutationCadastraContaFinanceiraArgs = {
	Dados: InputCadastrarContaFinanceira;
};

export type MutationCadastraContadorArgs = {
	Dados: InputCadastrarContador;
};

export type MutationCadastraContadorPessoaArgs = {
	Dados: InputCadastrarContadorPessoa;
};

export type MutationCadastraContatoPessoaArgs = {
	Dados: Array<InputCadastrarContatoPessoa>;
};

export type MutationCadastraDefinicaoArgs = {
	Dados: InputCadastrarDefinicao;
};

export type MutationCadastraDepartamentoArgs = {
	Dados: InputCadastrarDepartamento;
};

export type MutationCadastraDevolucaoArgs = {
	Dados: InputCadastrarDevolucao;
};

export type MutationCadastraEmpresaArgs = {
	Dados: InputCadastrarEmpresa;
};

export type MutationCadastraEnderecoAdicionalArgs = {
	Dados: InputCadastrarEnderecoAdicional;
};

export type MutationCadastraEventoNfeArgs = {
	Dados: InputCadastrarEventoNfe;
};

export type MutationCadastraFormaPagamentoArgs = {
	Dados: InputCadastrarFormaPagamento;
};

export type MutationCadastraFornecedorArgs = {
	Dados: InputCadastrarFornecedor;
};

export type MutationCadastraFornecedorVinculaPessoaArgs = {
	Dados: InputCadastrarFornecedorPessoa;
};

export type MutationCadastraGeneroArgs = {
	Dados: InputCadastrarGenero;
};

export type MutationCadastraGradeCampoArgs = {
	Dados: InputCadastrarGradeCampo;
};

export type MutationCadastraGradeEixoArgs = {
	Dados: InputCadastrarGradeEixo;
};

export type MutationCadastraGradeTipoArgs = {
	Dados: InputCadastrarGradeTipo;
};

export type MutationCadastraIntermediadorArgs = {
	Dados: InputCadastrarIntermediador;
};

export type MutationCadastraMarcaArgs = {
	Dados: InputCadastrarMarca;
};

export type MutationCadastraMetaArgs = {
	Dados: InputCadastrarMeta;
};

export type MutationCadastraMovimentoEstoqueArgs = {
	Dados: InputCadastrarMovimentoEstoque;
};

export type MutationCadastraNcmArgs = {
	Dados: InputCadastrarNcm;
};

export type MutationCadastraNfeArgs = {
	Dados: InputCadastrarNfe;
	DadosTransmissao?: InputMaybe<InputDadosTransmissaoNFe>;
};

export type MutationCadastraNfeCompraArgs = {
	Dados: InputCadastrarNfeCompra;
};

export type MutationCadastraNivelArgs = {
	Dados: InputCadastrarNivel;
};

export type MutationCadastraNotificacaoArgs = {
	Dados: InputCadastrarNotificacao;
};

export type MutationCadastraPdvArgs = {
	Dados: InputCadastrarPdv;
};

export type MutationCadastraPedidoVendaArgs = {
	Dados: InputCadastrarPedidoVenda;
};

export type MutationCadastraPessoaArgs = {
	Dados: InputCadastrarPessoa;
};

export type MutationCadastraProdutoArgs = {
	Dados: InputCadastrarProduto;
};

export type MutationCadastraProdutoFornecedorArgs = {
	Dados: InputCadastrarProdutoFornecedor;
};

export type MutationCadastraRegraTributacaoArgs = {
	Dados: InputCadastrarRegraTributacao;
};

export type MutationCadastraStatusVendaArgs = {
	Dados: InputCadastrarStatusVenda;
};

export type MutationCadastraSubcategoriaArgs = {
	Dados: InputCadastrarSubcategoria;
};

export type MutationCadastraTipoDependenteArgs = {
	Dados: InputCadastrarTipoDependente;
};

export type MutationCadastraTokenTerceiroArgs = {
	Dados: InputCadastrarTokenTerceiro;
};

export type MutationCadastraTransportadoraArgs = {
	Dados: InputCadastrarTransportadora;
};

export type MutationCadastraTransportadoraPessoaArgs = {
	Dados: InputCadastrarTransportadoraPessoa;
};

export type MutationCadastraUnidadeArgs = {
	Dados: InputCadastrarUnidade;
};

export type MutationCadastraVendaArgs = {
	Dados: InputCadastrarVenda;
};

export type MutationCadastraVendaPdvArgs = {
	CorrelationIdNFCe?: InputMaybe<Array<Scalars["String"]>>;
	Dados: InputCadastrarVendaPdv;
};

export type MutationCancelaVendaArgs = {
	CorrelationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	Id: Scalars["Int"];
};

export type MutationCancelarAcessoArgs = {
	Dados: InputRequisicaoAcesso;
};

export type MutationConfirmaNfeCompraArgs = {
	Id: Scalars["Int"];
};

export type MutationConfirmaVendaArgs = {
	CorrelationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	Id: Scalars["Int"];
};

export type MutationConverteCondicionalEmVendaArgs = {
	Dados: InputConverterCondicionalEmVenda;
};

export type MutationDeletaLancamentoArgs = {
	Dados: InputDeletaLancamento;
};

export type MutationEditaAdminCartaoArgs = {
	Dados: InputEditarAdminCartao;
};

export type MutationEditaAliquotaIcmsArgs = {
	Dados: InputEditarAliquotaIcms;
};

export type MutationEditaCategoriaArgs = {
	Dados: InputEditarCategoria;
};

export type MutationEditaCategoriaClienteArgs = {
	Dados: InputEditarCategoriaCliente;
};

export type MutationEditaCestArgs = {
	Dados: InputEditarCest;
};

export type MutationEditaCfopArgs = {
	Dados: InputEditarCfop;
};

export type MutationEditaClienteArgs = {
	Dados: InputEditarCliente;
};

export type MutationEditaColaboradorArgs = {
	Dados: InputEditarColaborador;
};

export type MutationEditaColecaoArgs = {
	Dados: InputEditarColecao;
};

export type MutationEditaComissoesFormaPgtoArgs = {
	Dados: InputEditarComissaoFormaPgto;
};

export type MutationEditaCondicionalArgs = {
	Dados: InputEditarCondicional;
};

export type MutationEditaContaFinanceiraArgs = {
	Dados: InputEditarContaFinanceira;
};

export type MutationEditaContadorArgs = {
	Dados: InputEditarContador;
};

export type MutationEditaContatoPessoaArgs = {
	Dados: Array<InputEditarContatoPessoa>;
};

export type MutationEditaDefinicaoArgs = {
	Dados: InputEditarDefinicao;
};

export type MutationEditaDepartamentoArgs = {
	Dados: InputEditarDepartamento;
};

export type MutationEditaDevolucaoArgs = {
	Dados: InputEditarDevolucao;
};

export type MutationEditaEmpresaArgs = {
	Dados: InputEditarEmpresa;
};

export type MutationEditaEmpresasArgs = {
	Dados: InputEditarEmpresa;
};

export type MutationEditaEnderecoAdicionalArgs = {
	Dados: InputEditarEnderecoAdicional;
};

export type MutationEditaFormaPagamentoArgs = {
	Dados: InputEditarFormaPagamento;
};

export type MutationEditaFornecedorArgs = {
	Dados: InputEditarFornecedor;
};

export type MutationEditaGeneroArgs = {
	Dados: InputEditarGenero;
};

export type MutationEditaGradeCampoArgs = {
	Dados: InputEditarGradeCampo;
};

export type MutationEditaGradeEixoArgs = {
	Dados: InputEditarGradeEixo;
};

export type MutationEditaGradeTipoArgs = {
	Dados: InputEditarGradeTipo;
};

export type MutationEditaIntermediadorArgs = {
	Dados: InputEditarIntermediador;
};

export type MutationEditaLancamentoDespesaArgs = {
	Dados: InputEditarLancamentoDespesa;
};

export type MutationEditaLancamentoReceitaArgs = {
	Dados: InputEditarLancamentoReceita;
};

export type MutationEditaManifestoArgs = {
	Dados: InputEditarManifesto;
};

export type MutationEditaMarcaArgs = {
	Dados: InputEditarMarca;
};

export type MutationEditaMetaArgs = {
	Dados: InputEditarMeta;
};

export type MutationEditaNcmArgs = {
	Dados: InputEditarNcm;
};

export type MutationEditaNfeArgs = {
	Dados: InputEditarNfe;
	DadosTransmissao?: InputMaybe<InputDadosTransmissaoNFe>;
};

export type MutationEditaNfeCompraArgs = {
	Dados: InputEditarNfeCompra;
};

export type MutationEditaNivelArgs = {
	Dados: InputEditarNivel;
};

export type MutationEditaPdvArgs = {
	Dados: InputEditarPdv;
};

export type MutationEditaPedidoVendaArgs = {
	Dados: InputEditarPedidoVenda;
};

export type MutationEditaPessoaArgs = {
	Dados: InputEditarPessoa;
};

export type MutationEditaProdutoArgs = {
	Dados: InputEditarProduto;
};

export type MutationEditaProdutoFornecedorArgs = {
	Dados: InputEditarProdutoFornecedor;
};

export type MutationEditaRegraTributacaoArgs = {
	Dados: InputEditarRegraTributacao;
};

export type MutationEditaSituacaoTributariaArgs = {
	Dados: InputEditarSituacaoTributaria;
};

export type MutationEditaStatusVendaArgs = {
	Dados: InputEditarStatusVenda;
};

export type MutationEditaSubcategoriaArgs = {
	Dados: InputEditarSubcategoria;
};

export type MutationEditaTipoDependenteArgs = {
	Dados: InputEditarTipoDependente;
};

export type MutationEditaTransportadoraArgs = {
	Dados: InputEditarTransportadora;
};

export type MutationEditaUnidadeArgs = {
	Dados: InputEditarUnidade;
};

export type MutationEditaVendaArgs = {
	Dados: InputEditarVenda;
};

export type MutationEnviarCertificadoArgs = {
	Dados: InputEnviaCertificado;
};

export type MutationEnviarCsvArgs = {
	Dados: InputEnviaCsv;
};

export type MutationEnviarXmlCompraArgs = {
	CorrelationId: Scalars["String"];
	XML: Scalars["Upload"];
};

export type MutationEsqueciSenhaArgs = {
	Dados: InputEsqueciSenha;
};

export type MutationEstornoParcelaReceitaArgs = {
	Dados: InputEstornoParcelaReceita;
};

export type MutationFaturaVendaArgs = {
	CorrelationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	Id: Scalars["Int"];
};

export type MutationFornecerAcessoColaboradorEmpresaArgs = {
	Dados: InputFornecerAcessoColaboradorEmpresa;
};

export type MutationGeraFinanceiroDevolucaoArgs = {
	Dados: InputCadastrarFinanceiroDevolucao;
};

export type MutationGeraNfceDaVendaPdvArgs = {
	CorrelationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	Id: Scalars["Int"];
};

export type MutationGeraNfeDaVendaArgs = {
	CorrelationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
	Id: Scalars["Int"];
};

export type MutationGerarDanFeArgs = {
	IdNFe: Scalars["Int"];
};

export type MutationGerarVendaArgs = {
	Dados: InputGerarVenda;
};

export type MutationLancamentoCreditoArgs = {
	Dados: InputLancamentoCredito;
};

export type MutationLancamentoDespesaArgs = {
	Dados: InputLancamentoDespesa;
};

export type MutationLancamentoReceitaArgs = {
	Dados: InputLancamentoReceita;
};

export type MutationLoginArgs = {
	CorrelationId?: InputMaybe<Scalars["String"]>;
	Login: Scalars["String"];
	Senha: Scalars["String"];
};

export type MutationPagamentoDespesaArgs = {
	Dados: InputPagamentoDespesa;
};

export type MutationPagamentoParcialDespesaArgs = {
	Dados: InputPagamentoParcialDespesa;
};

export type MutationRecalculaDadosNfeCompraArgs = {
	Dados?: InputMaybe<InputRecalculaNfe>;
};

export type MutationRecalculaDadosNfeEmissaoArgs = {
	Dados?: InputMaybe<InputCalculaImpostoNfe>;
};

export type MutationRecalcularNfeArgs = {
	DadosTransmissao?: InputMaybe<InputDadosTransmissaoNFe>;
	Id: Scalars["Int"];
};

export type MutationRecebimentoParcialReceitaArgs = {
	Dados: InputRecebimentoParcialReceita;
};

export type MutationRecebimentoReceitaArgs = {
	Dados: InputRecebimentoReceita;
};

export type MutationRemoveGradeEixoArgs = {
	Id: Scalars["Int"];
};

export type MutationRemoveMovimentoEstoqueArgs = {
	Id: Scalars["Int"];
};

export type MutationRemoveNfeCompraArgs = {
	Id: Scalars["Int"];
};

export type MutationRemoverAcessoArgs = {
	CorrelationId: Scalars["String"];
};

export type MutationRemoverAcessoColaboradorEmpresaArgs = {
	Dados: InputRemoverAcessoColaboradorEmpresa;
};

export type MutationRemoverUsuarioArgs = {
	Dados: InputRemoverUsuario;
};

export type MutationRenegociacaoParcelasDespesaArgs = {
	Dados: InputRenegociacaoDespesa;
};

export type MutationRenegociacaoParcelasReceitaArgs = {
	Dados: InputRenegociacaoReceita;
};

export type MutationRequisicaoAcessoArgs = {
	Dados: InputRequisicaoAcesso;
};

export type MutationResetaDadosDemonstrativosArgs = {
	Dados: InputResetarDadosEmpresa;
};

export type MutationRespostaAcessoArgs = {
	Dados: InputRespostaAcesso;
};

export type MutationRetornoCondicionalArgs = {
	Dados: InputRetornoCondicional;
};

export type MutationSaldoInicialContaFinanceiraArgs = {
	Dados: InputSaldoInicialContaFinanceira;
};

export type MutationTransferenciaContaFinanceiraArgs = {
	Dados: InputTransferenciaContaFinanceira;
};

export type MutationTransformaCondicionalVendaArgs = {
	IdCondicional: Scalars["Int"];
};

export type MutationVisualizarNotificacaoArgs = {
	Id: Scalars["Int"];
};

export enum Notas_Fiscais_Compra_Permissao {
	Cadastrar = "CADASTRAR",
	Deletar = "DELETAR",
	EfetuarManifestoNfe = "EFETUAR_MANIFESTO_NFE",
	Visualizar = "VISUALIZAR",
}

export enum Notas_Fiscais_Eletronicas_Permissao {
	Cadastrar = "CADASTRAR",
	Cancelar = "CANCELAR",
	CartaCorrecao = "CARTA_CORRECAO",
	Editar = "EDITAR",
	Inutilizar = "INUTILIZAR",
	Visualizar = "VISUALIZAR",
}

export type Ncm = {
	__typename?: "Ncm";
	AliqEstadual: Scalars["Float"];
	AliqImportacao: Scalars["Float"];
	AliqMunicipal: Scalars["Float"];
	AliqNacional: Scalars["Float"];
	CodNCM: Scalars["String"];
	DataAlteracao: Scalars["DateTime"];
	Descricao: Scalars["String"];
	ExtIpi?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdEstado: Scalars["Int"];
	IdOriginal: Scalars["Int"];
	Status: Scalars["Status"];
	Tabela: TabelaNcm;
	Uf: Scalars["String"];
};

export type NcmRegraTributacao = {
	__typename?: "NcmRegraTributacao";
	CodNCM: Scalars["String"];
	ExtIpi?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdNcm: Scalars["Int"];
	IdRegraTributacao: Scalars["Int"];
};

export type Nfe = {
	__typename?: "Nfe";
	ArquivoPdf?: Maybe<Scalars["String"]>;
	ArquivoXml?: Maybe<Scalars["String"]>;
	BairroPessoa?: Maybe<Scalars["String"]>;
	BairroTransportadora?: Maybe<Scalars["String"]>;
	CFOP: Scalars["String"];
	CFOPOperacao: TipoCfopOperacao;
	CelularPessoa?: Maybe<Scalars["String"]>;
	CelularTransportadora?: Maybe<Scalars["String"]>;
	ChaveAcesso?: Maybe<Scalars["String"]>;
	CidadePessoa?: Maybe<Scalars["String"]>;
	CidadeTransportadora?: Maybe<Scalars["String"]>;
	ComplementoPessoa?: Maybe<Scalars["String"]>;
	ComplementoTransportadora?: Maybe<Scalars["String"]>;
	CpfCnpjPessoa?: Maybe<Scalars["String"]>;
	CpfCnpjTransportadora?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataCancelamento?: Maybe<Scalars["DateTime"]>;
	DataCompet?: Maybe<Scalars["Date"]>;
	DataEmissao: Scalars["Date"];
	DataHoraEmissao: Scalars["DateTime"];
	DataHoraSaiEnt: Scalars["DateTime"];
	DataInutilizada?: Maybe<Scalars["DateTime"]>;
	DataSaiEnt: Scalars["Date"];
	DescricaoOperacao: Scalars["String"];
	EmailPessoa?: Maybe<Scalars["String"]>;
	EmailTransportadora?: Maybe<Scalars["String"]>;
	EstadoPessoa?: Maybe<Scalars["String"]>;
	EstadoTransportadora?: Maybe<Scalars["String"]>;
	Frete: TipoFrete;
	HoraEmissao: Scalars["Time"];
	HoraSaiEnt: Scalars["Time"];
	Id: Scalars["Int"];
	IdCfop: Scalars["Int"];
	IdCidadePessoa?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstadoPessoa?: Maybe<Scalars["Int"]>;
	IdIntermediador?: Maybe<Scalars["Int"]>;
	IdNfeRef?: Maybe<Scalars["Int"]>;
	IdPessoa?: Maybe<Scalars["Int"]>;
	IdTransportadora?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	IdVeiculo?: Maybe<Scalars["Int"]>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	ItemNfe?: Maybe<Array<ItemNfe>>;
	ModCliente?: Maybe<ModalidadeCliente>;
	Modelo: Modelo;
	NatOperacao?: Maybe<Scalars["String"]>;
	NaturezaOperacao: Scalars["String"];
	NomeFantasiaPessoa?: Maybe<Scalars["String"]>;
	NomeFantasiaTransportadora?: Maybe<Scalars["String"]>;
	NomePessoa?: Maybe<Scalars["String"]>;
	NomeTransportadora?: Maybe<Scalars["String"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	NumNF: Scalars["Int"];
	NumeroPessoa?: Maybe<Scalars["String"]>;
	NumeroTransportadora?: Maybe<Scalars["String"]>;
	ObservacaoComplementar?: Maybe<Scalars["String"]>;
	Origem: OrigemNFe;
	OutrasOpcoes?: Maybe<Scalars["String"]>;
	PlacaReboqueVeiculo?: Maybe<Scalars["String"]>;
	PlacaVeiculo?: Maybe<Scalars["String"]>;
	PrecoUsado: PrecoUsado;
	PrestacaoNfe?: Maybe<Array<PrestacaoNfe>>;
	RNTCReboqueVeiculo?: Maybe<Scalars["String"]>;
	RNTCVeiculo?: Maybe<Scalars["String"]>;
	ReferenciaNfe?: Maybe<Array<Maybe<ReferenciaNfe>>>;
	RuaPessoa?: Maybe<Scalars["String"]>;
	RuaTransportadora?: Maybe<Scalars["String"]>;
	Serie: Scalars["Int"];
	Status: StatusNfe;
	TelefonePessoa?: Maybe<Scalars["String"]>;
	TelefoneTransportadora?: Maybe<Scalars["String"]>;
	TipoNF: TipoNFe;
	ValorBcIcms: Scalars["Float"];
	ValorBcIcmsSt: Scalars["Float"];
	ValorCofins: Scalars["Float"];
	ValorCofinsSt: Scalars["Float"];
	ValorDesconto: Scalars["Float"];
	ValorFcpSt?: Maybe<Scalars["Float"]>;
	ValorFcpUfDest: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorIcms: Scalars["Float"];
	ValorIcmsSt: Scalars["Float"];
	ValorIcmsUfDest: Scalars["Float"];
	ValorIi: Scalars["Float"];
	ValorIpi: Scalars["Float"];
	ValorIpiDevol: Scalars["Float"];
	ValorOutro: Scalars["Float"];
	ValorPis: Scalars["Float"];
	ValorPisSt: Scalars["Float"];
	ValorSeguro: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorTotalNfe: Scalars["Float"];
	VendaNfe?: Maybe<Array<Maybe<VendaNfe>>>;
	VolumeNfe?: Maybe<Array<Maybe<VolumeNfe>>>;
};

export type NfeRetorno = {
	__typename?: "NfeRetorno";
	ArquivoPdf?: Maybe<Scalars["String"]>;
	ArquivoXml?: Maybe<Scalars["String"]>;
	BairroPessoa?: Maybe<Scalars["String"]>;
	BairroTransportadora?: Maybe<Scalars["String"]>;
	CFOP: Scalars["String"];
	CFOPOperacao: TipoCfopOperacao;
	CelularPessoa?: Maybe<Scalars["String"]>;
	CelularTransportadora?: Maybe<Scalars["String"]>;
	ChaveAcesso?: Maybe<Scalars["String"]>;
	CidadePessoa?: Maybe<Scalars["String"]>;
	CidadeTransportadora?: Maybe<Scalars["String"]>;
	ComplementoPessoa?: Maybe<Scalars["String"]>;
	ComplementoTransportadora?: Maybe<Scalars["String"]>;
	CpfCnpjPessoa?: Maybe<Scalars["String"]>;
	CpfCnpjTransportadora?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataCancelamento?: Maybe<Scalars["DateTime"]>;
	DataCompet?: Maybe<Scalars["Date"]>;
	DataEmissao: Scalars["Date"];
	DataHoraEmissao: Scalars["DateTime"];
	DataHoraSaiEnt: Scalars["DateTime"];
	DataInutilizada?: Maybe<Scalars["DateTime"]>;
	DataSaiEnt: Scalars["Date"];
	DescricaoOperacao: Scalars["String"];
	EmailPessoa?: Maybe<Scalars["String"]>;
	EmailTransportadora?: Maybe<Scalars["String"]>;
	EstadoPessoa?: Maybe<Scalars["String"]>;
	EstadoTransportadora?: Maybe<Scalars["String"]>;
	Frete: TipoFrete;
	HoraEmissao: Scalars["Time"];
	HoraSaiEnt: Scalars["Time"];
	Id: Scalars["Int"];
	IdCfop: Scalars["Int"];
	IdCidadePessoa?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstadoPessoa?: Maybe<Scalars["Int"]>;
	IdNfeRef?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	IdTransportadora?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	IdVeiculo?: Maybe<Scalars["Int"]>;
	IdVendedor?: Maybe<Scalars["Int"]>;
	ItemNfe?: Maybe<Array<ItemNfe>>;
	ModCliente?: Maybe<ModalidadeCliente>;
	Modelo: Modelo;
	NatOperacao?: Maybe<Scalars["String"]>;
	NaturezaOperacao: Scalars["String"];
	NomeFantasiaPessoa?: Maybe<Scalars["String"]>;
	NomeFantasiaTransportadora?: Maybe<Scalars["String"]>;
	NomePessoa: Scalars["String"];
	NomeTransportadora?: Maybe<Scalars["String"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	NumNF: Scalars["Int"];
	NumeroPessoa?: Maybe<Scalars["String"]>;
	NumeroTransportadora?: Maybe<Scalars["String"]>;
	ObservacaoComplementar?: Maybe<Scalars["String"]>;
	Origem: OrigemNFe;
	OutrasOpcoes?: Maybe<Scalars["String"]>;
	PlacaReboqueVeiculo?: Maybe<Scalars["String"]>;
	PlacaVeiculo?: Maybe<Scalars["String"]>;
	PrecoUsado: PrecoUsado;
	PrestacaoNfe?: Maybe<Array<PrestacaoNfe>>;
	RNTCReboqueVeiculo?: Maybe<Scalars["String"]>;
	RNTCVeiculo?: Maybe<Scalars["String"]>;
	ReferenciaNfe?: Maybe<Array<Maybe<ReferenciaNfe>>>;
	RuaPessoa?: Maybe<Scalars["String"]>;
	RuaTransportadora?: Maybe<Scalars["String"]>;
	Serie: Scalars["Int"];
	Status: StatusNfe;
	TelefonePessoa?: Maybe<Scalars["String"]>;
	TelefoneTransportadora?: Maybe<Scalars["String"]>;
	TipoNF: TipoNFe;
	ValorBcIcms: Scalars["Float"];
	ValorBcIcmsSt: Scalars["Float"];
	ValorCofins: Scalars["Float"];
	ValorCofinsSt: Scalars["Float"];
	ValorDesconto: Scalars["Float"];
	ValorFcpSt?: Maybe<Scalars["Float"]>;
	ValorFcpUfDest: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorIcms: Scalars["Float"];
	ValorIcmsSt: Scalars["Float"];
	ValorIcmsUfDest: Scalars["Float"];
	ValorIi: Scalars["Float"];
	ValorIpi: Scalars["Float"];
	ValorIpiDevol: Scalars["Float"];
	ValorOutro: Scalars["Float"];
	ValorPis: Scalars["Float"];
	ValorPisSt: Scalars["Float"];
	ValorSeguro: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorTotalNfe: Scalars["Float"];
	VendaNfe?: Maybe<Array<Maybe<VendaNfe>>>;
	VolumeNfe?: Maybe<Array<Maybe<VolumeNfe>>>;
};

export type Nivel = {
	__typename?: "Nivel";
	Comportamento: ComportamentoNivel;
	Descricao: Scalars["String"];
	DescricaoDefinicao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdNivel?: Maybe<Scalars["Int"]>;
	IdNivelContra?: Maybe<Scalars["Int"]>;
	NivelDefinicao?: Maybe<TipoNivelDefinicao>;
	Ordem: Scalars["Int"];
	Posicao: Scalars["String"];
	Status: Scalars["Status"];
	Tipo?: Maybe<Tipo>;
	TipoPessoa?: Maybe<TipoPessoa>;
};

export type NivelDefinicao = {
	__typename?: "NivelDefinicao";
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	Nivel: TipoNivelDefinicao;
};

export type Notificacao = {
	__typename?: "Notificacao";
	Conteudo: Scalars["String"];
	DataCriacao: Scalars["DateTime"];
	Id: Scalars["Int"];
	LocalExibicao: LocalExibicao;
	OutrasInformacoes?: Maybe<Scalars["String"]>;
	Status: StatusNotificacao;
	TipoNotificacao: TipoNotificacao;
	Titulo: Scalars["String"];
};

export enum OpcaoTransmissaoNFe {
	GerarDanfe = "GERAR_DANFE",
	Transmitir = "TRANSMITIR",
}

export type Operacao = {
	__typename?: "Operacao";
	Id: Scalars["Int"];
	Nome: Scalars["String"];
	TipoOperacao: TipoOperacao;
};

export enum OrigemMovimento {
	AjusteEstoque = "AJUSTE_ESTOQUE",
	AlteracaoGrade = "ALTERACAO_GRADE",
	MontagemKit = "MONTAGEM_KIT",
	SaldoFinalGrade = "SALDO_FINAL_GRADE",
	SaldoFinalProd = "SALDO_FINAL_PROD",
	SaldoInicialGrade = "SALDO_INICIAL_GRADE",
	SaldoInicialGradeInv = "SALDO_INICIAL_GRADE_INV",
	SaldoInicialProd = "SALDO_INICIAL_PROD",
	SaldoInicialProdInv = "SALDO_INICIAL_PROD_INV",
	TrocasDevolucoes = "TROCAS_DEVOLUCOES",
}

export enum OrigemNFe {
	CadastradaManualmente = "CADASTRADA_MANUALMENTE",
	NotaTransmitida = "NOTA_TRANSMITIDA",
	XmlImportado = "XML_IMPORTADO",
}

export enum Pagamentos_Permissao {
	Editar = "EDITAR",
	EfetuarPagamento = "EFETUAR_PAGAMENTO",
	EfetuarRenegociacao = "EFETUAR_RENEGOCIACAO",
	Visualizar = "VISUALIZAR",
}

export enum Pdv_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Pedido_Venda_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Permissoes_Colaborador_Permissao {
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Plano_Contas_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Preco_Dos_Itens_Venda_Permissao {
	AlterarValorUnitario = "ALTERAR_VALOR_UNITARIO",
	ConcederDesconto = "CONCEDER_DESCONTO",
	InserirAcrescimo = "INSERIR_ACRESCIMO",
}

export enum Produto_Permissao {
	AjustarQuantidadeEstoque = "AJUSTAR_QUANTIDADE_ESTOQUE",
	AlterarLimiteDesconto = "ALTERAR_LIMITE_DESCONTO",
	AlterarPrecoCusto = "ALTERAR_PRECO_CUSTO",
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
	VisualizarPrecoCusto = "VISUALIZAR_PRECO_CUSTO",
}

export type Pagamento = {
	__typename?: "Pagamento";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	CodigoVenda?: Maybe<Scalars["String"]>;
	DataEmissao: Scalars["Date"];
	DataPagamento?: Maybe<Scalars["Date"]>;
	DataVencimento: Scalars["Date"];
	FormaVendaFormaPagamento: FormasVenda;
	Historico?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdConta: Scalars["Int"];
	IdContaFinanceira?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdFornecedor?: Maybe<Scalars["Int"]>;
	IdLancamento?: Maybe<Scalars["Int"]>;
	IdNfe?: Maybe<Scalars["Int"]>;
	IdParcela?: Maybe<Scalars["Int"]>;
	IdVenda?: Maybe<Scalars["Int"]>;
	NomeConta: Scalars["String"];
	NomeContaFinanceira?: Maybe<Scalars["String"]>;
	NomeEmpresa: Scalars["String"];
	NomeFormaPagamento: Scalars["String"];
	NomeFornecedor?: Maybe<Scalars["String"]>;
	NumeroNFe?: Maybe<Scalars["String"]>;
	PgtoParcial: Scalars["Float"];
	StatusPagamento: StatusPagamento;
	StatusParcela: StatusParcelamento;
	TipoFormaPagamento: TipoFormaPagamento;
	TipoLancamento: FormasVenda;
	TipoNivelDefinicao?: Maybe<TipoNivelDefinicao>;
	TipoPagamentoNFeFormaPagamento: TagPagamentoNFe;
	ValorDesconto: Scalars["Float"];
	ValorJuroPgto: Scalars["Float"];
	ValorPagar: Scalars["Float"];
	ValorPago: Scalars["Float"];
	ValorTotal: Scalars["Float"];
};

export type PaginaCest = {
	__typename?: "PaginaCest";
	Data: Array<Cest>;
	Paginacao: Paginacao;
};

export type PaginaCfop = {
	__typename?: "PaginaCfop";
	Data: Array<Cfop>;
	Paginacao: Paginacao;
};

export type PaginaCliente = {
	__typename?: "PaginaCliente";
	Data: Array<Cliente>;
	Paginacao: Paginacao;
};

export type PaginaCondicional = {
	__typename?: "PaginaCondicional";
	Data: Array<Condicional>;
	Paginacao: PaginacaoCondicional;
};

export type PaginaCredito = {
	__typename?: "PaginaCredito";
	Data: Array<Credito>;
	Paginacao: Paginacao;
};

export type PaginaDevolucao = {
	__typename?: "PaginaDevolucao";
	Data: Array<Devolucao>;
	Paginacao: Paginacao;
};

export type PaginaNcm = {
	__typename?: "PaginaNcm";
	Data: Array<Ncm>;
	Paginacao: Paginacao;
};

export type PaginaNfe = {
	__typename?: "PaginaNfe";
	Data: Array<Nfe>;
	Paginacao: PaginacaoNfe;
};

export type PaginaPagamento = {
	__typename?: "PaginaPagamento";
	Data: Array<Pagamento>;
	Paginacao: PaginacaoPagamento;
};

export type PaginaPedidoVenda = {
	__typename?: "PaginaPedidoVenda";
	Data: Array<PedidoVenda>;
	Paginacao: PaginacaoPedidoVenda;
};

export type PaginaPessoa = {
	__typename?: "PaginaPessoa";
	Data: Array<Pessoa>;
	Paginacao: Paginacao;
};

export type PaginaProduto = {
	__typename?: "PaginaProduto";
	Data: Array<Produto>;
	Paginacao: PaginacaoProdutos;
};

export type PaginaReceita = {
	__typename?: "PaginaReceita";
	Data: Array<Receita>;
	Paginacao: PaginacaoReceita;
};

export type PaginaVenda = {
	__typename?: "PaginaVenda";
	Data: Array<Venda>;
	Paginacao: PaginacaoVenda;
};

export type PaginaVendasPorProduto = {
	__typename?: "PaginaVendasPorProduto";
	Data: Array<VendasPorProduto>;
	Paginacao: TotalizadoresRelatorioVendaPorProdutos;
};

export type Paginacao = {
	__typename?: "Paginacao";
	TotalRegistros: Scalars["Int"];
};

export type PaginacaoCondicional = {
	__typename?: "PaginacaoCondicional";
	TotalQtdCondicionais: Scalars["Float"];
	TotalQtdItensCondicional: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
	TotalValorCondicional: Scalars["Float"];
	TotalValorFaturado: Scalars["Float"];
};

export type PaginacaoNfe = {
	__typename?: "PaginacaoNfe";
	QuantidadeItensTransmitidos: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
	TotalRegistrosTransmitido: Scalars["Int"];
	TotalTransmitido: Scalars["Float"];
};

export type PaginacaoPagamento = {
	__typename?: "PaginacaoPagamento";
	TotalAtraso: Scalars["Float"];
	TotalPago: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
	TotalVenceHoje: Scalars["Float"];
	TotalVencer: Scalars["Float"];
};

export type PaginacaoPedidoVenda = {
	__typename?: "PaginacaoPedidoVenda";
	NumPedidos: Scalars["Int"];
	TotalItens: Scalars["Float"];
	TotalPedido: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
};

export type PaginacaoProdutos = {
	__typename?: "PaginacaoProdutos";
	QtdTotalEstoque: Scalars["Float"];
	TotalCustoMedio: Scalars["Float"];
	TotalPrecoVenda1: Scalars["Float"];
	TotalPrecoVenda2: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
	TotalValorCusto: Scalars["Float"];
};

export type PaginacaoReceita = {
	__typename?: "PaginacaoReceita";
	TotalAtraso: Scalars["Float"];
	TotalAtrasoJuros: Scalars["Float"];
	TotalQuitado: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
	TotalVenceHoje: Scalars["Float"];
	TotalVencer: Scalars["Float"];
};

export type PaginacaoVenda = {
	__typename?: "PaginacaoVenda";
	TotalFormasPagamento?: Maybe<Array<VendaTotalPorFormaPgto>>;
	/** Total de itens vendidos (ignora status) */
	TotalItens: Scalars["Float"];
	/** Total de itens vendidos (status faturado) */
	TotalItensVendidos: Scalars["Float"];
	/** Número de registros (ignora status) */
	TotalRegistros: Scalars["Int"];
	/** Número de registros (status faturado) */
	TotalRegistrosVendidos: Scalars["Int"];
	/** Total de venda (ignora status) */
	TotalVendas: Scalars["Float"];
	/** Total de vendas realizadas (status faturado) */
	TotalVendido: Scalars["Float"];
};

export type Pais = {
	__typename?: "Pais";
	Codigo: Scalars["String"];
	Id: Scalars["Int"];
	Nome: Scalars["String"];
};

export type Parcelamento = {
	__typename?: "Parcelamento";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	DataLancamento: Scalars["Date"];
	DataPgto?: Maybe<Scalars["Date"]>;
	DataVencimento: Scalars["Date"];
	DescontoPgto: Scalars["Float"];
	DescricaoNivel: Scalars["String"];
	EmailAvisoVencimento?: Maybe<Scalars["Resposta"]>;
	HistoricoAlteracao?: Maybe<Scalars["String"]>;
	HistoricoFinanceiro?: Maybe<Scalars["String"]>;
	HoraLancamento: Scalars["Time"];
	Id: Scalars["Int"];
	IdAdminCartao?: Maybe<Scalars["Int"]>;
	IdContaFinanceiro?: Maybe<Scalars["Int"]>;
	IdFinanceiro: Scalars["Int"];
	IdFormaPgtoFinanceiro?: Maybe<Scalars["Int"]>;
	IdLancamentoFinanceiro: Scalars["Int"];
	IdLancamentoPrincipal: Scalars["Int"];
	IdNFe?: Maybe<Scalars["Int"]>;
	IdNivelDefinicao?: Maybe<TipoNivelDefinicao>;
	IdNivelLancamento: Scalars["Int"];
	IdNivelTaxaCartao?: Maybe<Scalars["Int"]>;
	IdOperacaoLancamento: TipoOperacao;
	IdParcelamentoReferencia?: Maybe<Scalars["Int"]>;
	IdParcelamentoTaxaCartao?: Maybe<Scalars["Int"]>;
	IdPessoaLancamento?: Maybe<Scalars["Int"]>;
	IdVenda?: Maybe<Scalars["Int"]>;
	IdVendedorFinanceiro?: Maybe<Scalars["Int"]>;
	NomeFantasiaPessoa?: Maybe<Scalars["String"]>;
	NomeOperacao: Scalars["String"];
	NomePessoa?: Maybe<Scalars["String"]>;
	PgtoParcial: Scalars["Float"];
	Status: StatusParcelamento;
	TaxaJuro: Scalars["Float"];
	ValorJuroPgto: Scalars["Float"];
	ValorParcela: Scalars["Float"];
	ValorTaxaCartao: Scalars["Float"];
	ValorTotalLancamento: Scalars["Float"];
};

export type Pdv = {
	__typename?: "Pdv";
	Id: Scalars["Int"];
	IdContaFinanceira: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	NomeContaFinanceira: Scalars["String"];
	Status: Scalars["Status"];
};

export type PedidoVenda = {
	__typename?: "PedidoVenda";
	Codigo: Scalars["String"];
	CodigoVenda?: Maybe<Scalars["String"]>;
	Conferido: Scalars["Resposta"];
	Data: Scalars["Date"];
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataEntrega?: Maybe<Scalars["Date"]>;
	DataHora: Scalars["DateTime"];
	DescricaoStatus: Scalars["String"];
	EstagioStatus: Estagio;
	Frete: TipoFrete;
	HashLink: Scalars["String"];
	Hora: Scalars["Time"];
	Id: Scalars["Int"];
	IdCliente: Scalars["Int"];
	IdEcommerce?: Maybe<Scalars["Int"]>;
	IdEnderecoEntrega?: Maybe<Scalars["Int"]>;
	IdTransportadora?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	IdVendedor?: Maybe<Scalars["Int"]>;
	InformacoesAdicionais?: Maybe<Scalars["String"]>;
	ItemVenda?: Maybe<Array<Maybe<ItemVenda>>>;
	NomeCliente?: Maybe<Scalars["String"]>;
	NomeFantasiaCliente?: Maybe<Scalars["String"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	Observacoes?: Maybe<Scalars["String"]>;
	PrecoUsado: PrecoUsado;
	PrestacaoVenda?: Maybe<Array<Maybe<PrestacaoVenda>>>;
	QtdItens?: Maybe<Scalars["Float"]>;
	Status: Scalars["Int"];
	ValorDesconto: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorJuro: Scalars["Float"];
	ValorSeguro: Scalars["Float"];
	ValorTotal: Scalars["Float"];
};

export enum PerfilAcessoColaborador {
	Suporte = "SUPORTE",
	Usuario = "USUARIO",
}

export enum PerfilSped {
	Detalhado = "DETALHADO",
	SimplesNacional = "SIMPLES_NACIONAL",
	Sintetico = "SINTETICO",
}

export type Permissao = {
	__typename?: "Permissao";
	Id: Scalars["Int"];
	IdModulo: Scalars["Int"];
	IdPermissaoPai?: Maybe<Scalars["Int"]>;
	Nome: Scalars["String"];
	NomeModulo: Scalars["String"];
	Opcoes: Scalars["String"];
	OpcoesLista: Array<Scalars["String"]>;
	Tipo: TipoPermissao;
};

export type PermissaoColaborador = {
	__typename?: "PermissaoColaborador";
	DataAlteracao: Scalars["DateTime"];
	Id: Scalars["Int"];
	IdColaborador?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdPermissao: Scalars["Int"];
	NomePermissao: Scalars["String"];
	OpcoesPermissaoLista: Array<Scalars["String"]>;
	Status: Scalars["Int"];
};

export type PermissaoColaboradorTerceiro = {
	__typename?: "PermissaoColaboradorTerceiro";
	DataAlteracao: Scalars["DateTime"];
	Id: Scalars["Int"];
	IdColaborador?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdPermissao: Scalars["Int"];
	IdTokenTerceiro?: Maybe<Scalars["Int"]>;
	NumeroOpcoes: Scalars["Int"];
	Status: Scalars["Status"];
};

export enum Permissoes {
	AdministradorasCartao = "ADMINISTRADORAS_CARTAO",
	CaptacaoEmprestimos = "CAPTACAO_EMPRESTIMOS",
	Categoria = "CATEGORIA",
	CategoriaCliente = "CATEGORIA_CLIENTE",
	Cliente = "CLIENTE",
	Colaborador = "COLABORADOR",
	Colecao = "COLECAO",
	Condicional = "CONDICIONAL",
	ConfiguracoesGerais = "CONFIGURACOES_GERAIS",
	Contador = "CONTADOR",
	ContaFinanceira = "CONTA_FINANCEIRA",
	CupomFiscal = "CUPOM_FISCAL",
	Definicao = "DEFINICAO",
	DepartamentosColaborador = "DEPARTAMENTOS_COLABORADOR",
	DevolucoesTrocas = "DEVOLUCOES_TROCAS",
	Empresa = "EMPRESA",
	FormaPagamento = "FORMA_PAGAMENTO",
	Fornecedor = "FORNECEDOR",
	Genero = "GENERO",
	Grades = "GRADES",
	IntermediadoresVenda = "INTERMEDIADORES_VENDA",
	Lancamentos = "LANCAMENTOS",
	Marca = "MARCA",
	MovimentoContasFinanceiras = "MOVIMENTO_CONTAS_FINANCEIRAS",
	NotasFiscaisCompra = "NOTAS_FISCAIS_COMPRA",
	NotasFiscaisEletronicas = "NOTAS_FISCAIS_ELETRONICAS",
	Pagamentos = "PAGAMENTOS",
	Pdv = "PDV",
	PedidoVenda = "PEDIDO_VENDA",
	PermissoesColaborador = "PERMISSOES_COLABORADOR",
	PlanoContas = "PLANO_CONTAS",
	PrecoDosItensVenda = "PRECO_DOS_ITENS_VENDA",
	Produto = "PRODUTO",
	Recebimentos = "RECEBIMENTOS",
	RecebimentoCartoes = "RECEBIMENTO_CARTOES",
	RegraTributacao = "REGRA_TRIBUTACAO",
	RelatoriosComerciais = "RELATORIOS_COMERCIAIS",
	RelatoriosFiscais = "RELATORIOS_FISCAIS",
	StatusDasVendas = "STATUS_DAS_VENDAS",
	TabelaPreco = "TABELA_PRECO",
	Totalizadores = "TOTALIZADORES",
	TransferenciasContasFinanceiras = "TRANSFERENCIAS_CONTAS_FINANCEIRAS",
	Transportadora = "TRANSPORTADORA",
	Tributacoes = "TRIBUTACOES",
	Unidade = "UNIDADE",
	Usuario = "USUARIO",
	ValeCredito = "VALE_CREDITO",
	Venda = "VENDA",
	VisualizarSaldos = "VISUALIZAR_SALDOS",
}

export enum PesquisarPor {
	Clientes = "CLIENTES",
	OperadorasCartao = "OPERADORAS_CARTAO",
	Todos = "TODOS",
}

export type Pessoa = {
	__typename?: "Pessoa";
	Bairro?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Complemento?: Maybe<Scalars["String"]>;
	CpfCnpj?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataNascimento?: Maybe<Scalars["Date"]>;
	Email?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCidade?: Maybe<Scalars["Int"]>;
	IdCliente?: Maybe<Scalars["Int"]>;
	IdColaborador?: Maybe<Scalars["Int"]>;
	IdContador?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdFornecedor?: Maybe<Scalars["Int"]>;
	IdPais?: Maybe<Scalars["Int"]>;
	IdTransportadora?: Maybe<Scalars["Int"]>;
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Observacao?: Maybe<Scalars["String"]>;
	RG?: Maybe<Scalars["String"]>;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	Status: Scalars["Status"];
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
};

export type PicoVenda = {
	__typename?: "PicoVenda";
	PicoDiaMes: PicoVendaValoresMes;
	PicoDiaSemana: PicoVendaValoresSemana;
};

export type PicoVendaValoresMes = {
	__typename?: "PicoVendaValoresMes";
	MaiorDia: Scalars["Int"];
	MaiorQtd: Scalars["Float"];
	MaiorValor: Scalars["Float"];
	MenorDia: Scalars["Int"];
	MenorQtd: Scalars["Float"];
	MenorValor: Scalars["Float"];
};

export type PicoVendaValoresSemana = {
	__typename?: "PicoVendaValoresSemana";
	MaiorDia: DiaSemana;
	MaiorQtd: Scalars["Float"];
	MaiorValor: Scalars["Float"];
	MenorDia: DiaSemana;
	MenorQtd: Scalars["Float"];
	MenorValor: Scalars["Float"];
};

export enum PlanoContratado {
	Business = "BUSINESS",
	Pro = "PRO",
	Start = "START",
}

export enum PrecoPadraoCfop {
	PrecoCusto = "PRECO_CUSTO",
	ValorVenda = "VALOR_VENDA",
	ValorVendaAlt = "VALOR_VENDA_ALT",
}

export enum PrecoUsado {
	PrecoAlternativo = "PRECO_ALTERNATIVO",
	PrecoBase = "PRECO_BASE",
}

export type PrestacaoDevolucao = {
	__typename?: "PrestacaoDevolucao";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataVencimento: Scalars["Date"];
	Id: Scalars["Int"];
	IdAdminCartao?: Maybe<Scalars["Int"]>;
	IdContaFinanceiro?: Maybe<Scalars["Int"]>;
	IdDevolucao?: Maybe<Scalars["Int"]>;
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdFormaPgto: Scalars["Int"];
	IdLancamento?: Maybe<Scalars["Int"]>;
	IdNFe?: Maybe<Scalars["Int"]>;
	IdParcelamento?: Maybe<Scalars["Int"]>;
	IdVenda?: Maybe<Scalars["Int"]>;
	NomeFormaPgto?: Maybe<Scalars["String"]>;
	NumLancamento: Scalars["Int"];
	TagPgtoNFe: TagPagamentoNFe;
	TipoFormaPgto: TipoFormaPagamento;
	ValorTaxaCartao?: Maybe<Scalars["Float"]>;
	ValorTotal: Scalars["Float"];
};

export type PrestacaoNfe = {
	__typename?: "PrestacaoNfe";
	DataAlteracao: Scalars["DateTime"];
	DataVencimento: Scalars["Date"];
	Id: Scalars["Int"];
	IdAdminCartao?: Maybe<Scalars["Int"]>;
	IdContaFinanceira?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdFormaPgto: Scalars["Int"];
	IdNfe: Scalars["Int"];
	NomeFormaPgto?: Maybe<Scalars["String"]>;
	NumDocumento?: Maybe<Scalars["String"]>;
	NumLancamento: Scalars["Int"];
	TagPgtoNFe: TagPagamentoNFe;
	TipoFormaPgto: TipoFormaPagamento;
	TotalLancamento: Scalars["Float"];
	ValorParcela: Scalars["Float"];
};

export type PrestacaoVenda = {
	__typename?: "PrestacaoVenda";
	DataAlteracao: Scalars["DateTime"];
	DataVencimento: Scalars["DateTime"];
	Id: Scalars["Int"];
	IdAdminCartao?: Maybe<Scalars["Int"]>;
	IdContaFinanceira?: Maybe<Scalars["Int"]>;
	IdFormaPgto: Scalars["Int"];
	IdVenda: Scalars["Int"];
	NomeFormaPgto?: Maybe<Scalars["String"]>;
	NumDocumento?: Maybe<Scalars["String"]>;
	NumLancamento: Scalars["Int"];
	TagPgtoNFe: TagPagamentoNFe;
	TipoFormaPgto: TipoFormaPagamento;
	TotalLancamento: Scalars["Float"];
	ValorParcela: Scalars["Float"];
};

export type Produto = {
	__typename?: "Produto";
	AtivoEcommerce: Scalars["Status"];
	CategoriaDescricao?: Maybe<Scalars["String"]>;
	CategoriaNome?: Maybe<Scalars["String"]>;
	CategoriaStatus?: Maybe<Scalars["Status"]>;
	CestCodigo?: Maybe<Scalars["String"]>;
	CfopCodigo?: Maybe<Scalars["String"]>;
	CodAnp?: Maybe<Scalars["String"]>;
	CodBarras?: Maybe<Scalars["String"]>;
	CodBenef?: Maybe<Scalars["String"]>;
	CodFornecedor?: Maybe<Scalars["String"]>;
	CodListServ?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	CodigoSecundario?: Maybe<Scalars["String"]>;
	CofinsAliq?: Maybe<Scalars["Float"]>;
	ColecaoDescricao?: Maybe<Scalars["String"]>;
	ColecaoNome?: Maybe<Scalars["String"]>;
	ColecaoStatus?: Maybe<Scalars["Status"]>;
	Complemento?: Maybe<Scalars["String"]>;
	CustoMedio?: Maybe<Scalars["Float"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DefinicaoDescricao?: Maybe<Scalars["String"]>;
	DefinicaoMateriaPrima?: Maybe<Scalars["Status"]>;
	DefinicaoNome?: Maybe<Scalars["String"]>;
	DefinicaoProducao?: Maybe<Scalars["Status"]>;
	DefinicaoServico?: Maybe<Scalars["Status"]>;
	DefinicaoUsoConsumo?: Maybe<Scalars["Status"]>;
	DefinicaoVenda?: Maybe<Scalars["Status"]>;
	DescAnp?: Maybe<Scalars["String"]>;
	Desconto?: Maybe<Scalars["Float"]>;
	Descricao: Scalars["String"];
	EstoqueAjustado?: Maybe<Scalars["Resposta"]>;
	FatorConversao?: Maybe<Scalars["Float"]>;
	FatorConversaoTribut?: Maybe<Scalars["Float"]>;
	FornecedorCnpj?: Maybe<Scalars["String"]>;
	FornecedorNome?: Maybe<Scalars["String"]>;
	FornecedorNomeFantasia?: Maybe<Scalars["String"]>;
	Foto?: Maybe<Scalars["String"]>;
	Grade?: Maybe<Array<Maybe<Grade>>>;
	GradeNome?: Maybe<Scalars["String"]>;
	GradeNomeColuna?: Maybe<Scalars["String"]>;
	GradeNomeLinha?: Maybe<Scalars["String"]>;
	GradePreco: GradePreco;
	GradeStatus?: Maybe<Scalars["Status"]>;
	GradeTipo?: Maybe<TipoGrade>;
	GradeVisibilidade?: Maybe<TipoVisibilidade>;
	IIAliq?: Maybe<Scalars["Float"]>;
	IPIAliq?: Maybe<Scalars["Float"]>;
	IcmsAliq?: Maybe<Scalars["Float"]>;
	IcmsMva?: Maybe<Scalars["Float"]>;
	IcmsReducao?: Maybe<Scalars["Float"]>;
	Id: Scalars["Int"];
	IdCategoria?: Maybe<Scalars["Int"]>;
	IdCest?: Maybe<Scalars["Int"]>;
	IdCfop?: Maybe<Scalars["Int"]>;
	IdColecao?: Maybe<Scalars["Int"]>;
	IdCstIpi?: Maybe<Scalars["Int"]>;
	IdCstPisCofins?: Maybe<Scalars["Int"]>;
	IdDefinicao: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdFornecedor?: Maybe<Scalars["Int"]>;
	IdGradeTipo?: Maybe<Scalars["Int"]>;
	IdMarca?: Maybe<Scalars["Int"]>;
	IdNcm?: Maybe<Scalars["Int"]>;
	IdSituaTribut?: Maybe<Scalars["Int"]>;
	IdSubcategoria?: Maybe<Scalars["Int"]>;
	IdUnidade: Scalars["Int"];
	IdUnidadeCompra?: Maybe<Scalars["Int"]>;
	IdUnidadeTributaria?: Maybe<Scalars["Int"]>;
	IndEscala: IndicadorProducao;
	IpiClasse?: Maybe<Scalars["String"]>;
	IpiCnpjProd?: Maybe<Scalars["String"]>;
	IpiCodEnq?: Maybe<Scalars["String"]>;
	IpiCodSelo?: Maybe<Scalars["String"]>;
	IpiQtdSelo?: Maybe<Scalars["Int"]>;
	MarcaDescricao?: Maybe<Scalars["String"]>;
	MarcaNome?: Maybe<Scalars["String"]>;
	MarcaStatus?: Maybe<Scalars["Status"]>;
	MargemLucro?: Maybe<Scalars["Float"]>;
	NcmCodigo?: Maybe<Scalars["String"]>;
	Origem: Scalars["Int"];
	Peso?: Maybe<Scalars["Float"]>;
	PisAliq?: Maybe<Scalars["Float"]>;
	PorcentDifIcms?: Maybe<Scalars["Float"]>;
	ProdutoFilial?: Maybe<Array<Maybe<ProdutoFilial>>>;
	ProdutosKit?: Maybe<Array<Maybe<ProdutoKit>>>;
	QtdMinima?: Maybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	ReducaoMvaNormal?: Maybe<Scalars["Float"]>;
	ReducaoMvaSimples?: Maybe<Scalars["Float"]>;
	SaldoIniQtd: Scalars["Float"];
	SituaTributCodDescricao?: Maybe<Scalars["String"]>;
	SkuEcommerce?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	SubcategoriaDescricao?: Maybe<Scalars["String"]>;
	SubcategoriaNome?: Maybe<Scalars["String"]>;
	SubcategoriaStatus?: Maybe<Scalars["Status"]>;
	UnidadeCompraDescricao?: Maybe<Scalars["String"]>;
	UnidadeCompraSimbolo?: Maybe<Scalars["String"]>;
	UnidadeCompraStatus?: Maybe<Scalars["Status"]>;
	UnidadeDescricao?: Maybe<Scalars["String"]>;
	UnidadeSimbolo?: Maybe<Scalars["String"]>;
	UnidadeStatus?: Maybe<Scalars["Status"]>;
	ValorCusto?: Maybe<Scalars["Float"]>;
	ValorVenda: Scalars["Float"];
	ValorVendaAlt: Scalars["Float"];
};

export type ProdutoGradeArgs = {
	Filtro?: InputMaybe<InputFiltroGrades>;
};

export type ProdutoProdutoFilialArgs = {
	Filtro?: InputMaybe<InputFiltroProdutoFilial>;
};

export type ProdutoProdutosKitArgs = {
	Filtro?: InputMaybe<InputFiltroProdutoKit>;
};

export enum ProdutoEmpresa {
	Industrial = "INDUSTRIAL",
	Moda = "MODA",
	Textil = "TEXTIL",
	Varejo = "VAREJO",
}

export type ProdutoFilial = {
	__typename?: "ProdutoFilial";
	CofinsAliq?: Maybe<Scalars["Float"]>;
	CustoMedio: Scalars["Float"];
	Desconto?: Maybe<Scalars["Float"]>;
	IIAliq?: Maybe<Scalars["Float"]>;
	IPIAliq?: Maybe<Scalars["Float"]>;
	IcmsAliq?: Maybe<Scalars["Float"]>;
	IcmsMva?: Maybe<Scalars["Float"]>;
	IcmsReducao?: Maybe<Scalars["Float"]>;
	IdCest?: Maybe<Scalars["Int"]>;
	IdCfop?: Maybe<Scalars["Int"]>;
	IdCstIpi?: Maybe<Scalars["Int"]>;
	IdCstPisCofins?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdNcm?: Maybe<Scalars["Int"]>;
	IdProduto: Scalars["Int"];
	IdSituaTribut?: Maybe<Scalars["Int"]>;
	IpiClasse?: Maybe<Scalars["String"]>;
	IpiCodEnq?: Maybe<Scalars["String"]>;
	IpiCodSelo?: Maybe<Scalars["String"]>;
	IpiQtdSelo?: Maybe<Scalars["Int"]>;
	MargemLucro?: Maybe<Scalars["Float"]>;
	PisAliq?: Maybe<Scalars["Float"]>;
	PorcentDifIcms?: Maybe<Scalars["Float"]>;
	QtdMinima?: Maybe<Scalars["Float"]>;
	Quantidade: Scalars["Float"];
	ReducaoMvaNormal?: Maybe<Scalars["Float"]>;
	ReducaoMvaSimples?: Maybe<Scalars["Float"]>;
	SaldoIniQtd: Scalars["Float"];
	ValorCusto: Scalars["Float"];
	ValorVenda: Scalars["Float"];
	ValorVendaAlt: Scalars["Float"];
};

export type ProdutoFornecedor = {
	__typename?: "ProdutoFornecedor";
	CodBarras?: Maybe<Scalars["String"]>;
	CodFornecedor: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdFornecedor: Scalars["Int"];
	IdProduto: Scalars["Int"];
};

export type ProdutoKit = {
	__typename?: "ProdutoKit";
	Codigo: Scalars["String"];
	CustoMedio: Scalars["Float"];
	CustoUltCompra: Scalars["Float"];
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdKit: Scalars["Int"];
	IdProduto: Scalars["Int"];
	PrecoVenda: Scalars["Float"];
	PrecoVendaAlt: Scalars["Float"];
	Quantidade: Scalars["Float"];
	Status: Scalars["Status"];
	TotalCustoMedio: Scalars["Float"];
	TotalCustoUltCompra: Scalars["Float"];
	TotalPrecoVenda: Scalars["Float"];
	TotalPrecoVendaAlt: Scalars["Float"];
};

export type Query = {
	__typename?: "Query";
	acessoChamadoTomTicket: Scalars["String"];
	acessoChatTomTicket: Scalars["String"];
	acessosEmpresasColaborador: Array<ColaboradorEmpresa>;
	adminCartao: AdminCartao;
	adminsCartoes: Array<AdminCartao>;
	aliquotaIcms: AliquotaIcms;
	aliquotasIcms: Array<AliquotaIcms>;
	balanceteDemonstrativo: Scalars["RetornoBalanceteDemonstrativo"];
	bandeiraCartao: BandeiraCartao;
	bandeirasCartoes: Array<BandeiraCartao>;
	buscarEmpresa: Empresa;
	categoria: Categoria;
	categoriaCliente: CategoriaCliente;
	categorias: Array<Categoria>;
	categoriasCliente: Array<CategoriaCliente>;
	cep: Cep;
	cest: Cest;
	cests: PaginaCest;
	cfop: Cfop;
	cfopOperacao: CfopOperacao;
	cfopOperacoes: Array<CfopOperacao>;
	cfops: PaginaCfop;
	cidade: Cidade;
	cidades: Array<Cidade>;
	cliente: Cliente;
	clientes: PaginaCliente;
	codigoProximaTransportadora: Scalars["Int"];
	codigoProximaVenda: Scalars["Int"];
	codigoProximoCliente: Scalars["Int"];
	codigoProximoColaborador: Scalars["Int"];
	codigoProximoCondicional: Scalars["Int"];
	codigoProximoFornecedor: Scalars["Int"];
	codigoProximoPedidoVenda: Scalars["Int"];
	codigoProximoProduto: Scalars["Int"];
	colaborador: Colaborador;
	colaboradores: Array<Colaborador>;
	colecao: Colecao;
	colecoes: Array<Colecao>;
	comissaoFormaPgto: ComissaoFormaPgto;
	comissoes: Array<Maybe<Comissoes>>;
	comissoesFormaPgto: Array<Maybe<ComissaoFormaPgto>>;
	condicionais: PaginaCondicional;
	condicional: Condicional;
	configuracao: Configuracao;
	configuracaoEmpresa: ConfiguracaoEmpresa;
	configuracoes: Array<Configuracao>;
	configuracoesEmpresa: Array<ConfiguracaoEmpresa>;
	contaFinanceira: ContaFinanceira;
	contador: Contador;
	contadores: Array<Contador>;
	contasFinanceiras: Array<ContaFinanceira>;
	contatoPessoa: Array<ContatoPessoa>;
	credito: Credito;
	creditoCliente?: Maybe<Scalars["Float"]>;
	creditos: PaginaCredito;
	definicao: Definicao;
	definicoes: Array<Definicao>;
	departamento: Departamento;
	departamentos: Array<Departamento>;
	devolucao: Devolucao;
	devolucaoNfeCompra: NfeRetorno;
	devolucaoNfeVenda: NfeRetorno;
	devolucoes: PaginaDevolucao;
	empresa: Empresa;
	empresas: Array<Empresa>;
	empresasColaborador: Array<ColaboradorEmpresa>;
	enderecoAdicional: EnderecoAdicional;
	estado: Estado;
	estados: Array<Estado>;
	estornoNfe: NfeRetorno;
	eventoNfe: EventoNfe;
	eventosNfe: Array<EventoNfe>;
	formaPagamento: FormaPagamento;
	formasPagamento: Array<FormaPagamento>;
	fornecedor: Fornecedor;
	fornecedores: Array<Fornecedor>;
	genero: Genero;
	generos: Array<Genero>;
	geraNfeDevolucoes: NfeRetorno;
	grade: Grade;
	gradeCampo: GradeCampo;
	gradeEixo: GradeEixo;
	gradeTipo: GradeTipo;
	grades: Array<Grade>;
	gradesCampos: Array<GradeCampo>;
	gradesEixos: Array<GradeEixo>;
	gradesTipos: Array<GradeTipo>;
	impressaoCondicional: ImpressaoCondicional;
	impressaoVenda: ImpressaoVenda;
	intermediador: Intermediador;
	intermediadores: Array<Intermediador>;
	inventarioEstoque?: Maybe<Scalars["RetornoInventarioEstoque"]>;
	itensNfe?: Maybe<Array<Maybe<ItemNfe>>>;
	/** Retorna True caso a Nfe possua relação com outro lançamento(recebimento, estorno, renegociação) */
	lancamentoVinculadoNfe: Scalars["Boolean"];
	lancamentos: Array<Lancamento>;
	listaPermissoesColaborador: Scalars["RetornoPermissao"];
	manifesto: Manifesto;
	manifestos: Array<Manifesto>;
	marca: Marca;
	marcas: Array<Marca>;
	meta: Meta;
	metas: Array<Meta>;
	modulo: Modulo;
	moduloEmpresa: ModuloEmpresa;
	modulos: Array<Modulo>;
	modulosEmpresa: Array<ModuloEmpresa>;
	movimentoBanco: Array<MovimentoContaFinanceira>;
	movimentoCaixa: Array<MovimentoContaFinanceira>;
	movimentoEstoque: MovimentoEstoque;
	movimentoFinanceiro: Array<MovimentoContaFinanceira>;
	movimentoProduto?: Maybe<Scalars["RetornoMovimentoProduto"]>;
	movimentosEstoque: Array<MovimentoEstoque>;
	ncm: Ncm;
	ncms: PaginaNcm;
	nfe: Nfe;
	nfes: PaginaNfe;
	niveis: Array<Nivel>;
	nivel: Nivel;
	nivelDefinicao: NivelDefinicao;
	nivelDefinicoes: Array<NivelDefinicao>;
	notificacoes: Array<Notificacao>;
	operacao: Operacao;
	operacoes: Array<Operacao>;
	pagamentos: PaginaPagamento;
	pais: Pais;
	paises: Array<Pais>;
	pdv: Pdv;
	pdvs: Array<Pdv>;
	pedidoVenda: PedidoVenda;
	pedidosVenda: PaginaPedidoVenda;
	permissoes: Array<Permissao>;
	pessoa: Pessoa;
	pessoas: PaginaPessoa;
	/** Retorna as datas de pico de vendas, com base no valor e na quantidade vendida (somente dias que possuem vendas). */
	picoVendas: PicoVenda;
	produto: Produto;
	produtoFornecedor: ProdutoFornecedor;
	produtos: PaginaProduto;
	produtosFornecedores: Array<ProdutoFornecedor>;
	/** Retorna o ranking de produtos mais vendidos em um período, com base no valor vendido e na quantidade vendida. */
	rankingProdutos: Array<RankingProduto>;
	recebimentoCartao: Array<Receita>;
	recebimentos: PaginaReceita;
	referenciaComercial: ReferenciaComercial;
	refreshToken?: Maybe<Scalars["RetornoPadrao"]>;
	regraTributacao: RegraTributacao;
	regrasTributacoes: Array<RegraTributacao>;
	saldoContasFinanceiras: Array<SaldoContaFinanceira>;
	situacaoTributaria: SituacaoTributaria;
	situacoesTributarias: Array<SituacaoTributaria>;
	statusVenda: StatusVenda;
	statusVendas: Array<StatusVenda>;
	subcategoria: Subcategoria;
	subcategorias: Array<Subcategoria>;
	tipoDependente: TipoDependente;
	tiposDependente: Array<TipoDependente>;
	/** Retorna os totais de devoluções de um período */
	totalizadoresTrocasDevolucoes: TotalizadorTrocasDevolucao;
	/** Retorna os totais de vendas de um período, comparando com o período anterior e com o mesmo período do ano anterior. */
	totalizadoresVendas: TotalizadorVenda;
	transportadora: Transportadora;
	transportadoras: Array<Transportadora>;
	ultimaRegraAtualizada: Scalars["DateTime"];
	unidade: Unidade;
	unidades: Array<Unidade>;
	usuarioAtual: Colaborador;
	veiculo: Veiculo;
	veiculos: Array<Veiculo>;
	venda: Venda;
	vendas: PaginaVenda;
	/** Retorna os totais de vendas por forma de pagamento, e a quantidade de vendas em que essa forma de pagamento foi utilizada. */
	vendasFormaPagamento: Array<RankingFormaPagamento>;
	vendasPorProduto: PaginaVendasPorProduto;
	vendasPorTributacao: Array<Maybe<VendasPorTributacao>>;
	/** Retorna um array de quantidades e valores de vendas por dia/mês/ano de um período. */
	volumeVendas: Array<VolumeVenda>;
};

export type QueryAcessosEmpresasColaboradorArgs = {
	Filtro?: InputMaybe<InputFiltroColaboradorEmpresa>;
};

export type QueryAdminCartaoArgs = {
	Filtro?: InputMaybe<InputFiltroAdminCartao>;
};

export type QueryAdminsCartoesArgs = {
	Filtro?: InputMaybe<InputFiltroAdminsCartao>;
};

export type QueryAliquotaIcmsArgs = {
	Filtro?: InputMaybe<InputFiltroAliquotaIcms>;
};

export type QueryAliquotasIcmsArgs = {
	Filtro?: InputMaybe<InputFiltroAliquotasIcms>;
};

export type QueryBalanceteDemonstrativoArgs = {
	Dados?: InputMaybe<InputFiltroBalanceteDemonstrativo>;
};

export type QueryBandeiraCartaoArgs = {
	Filtro?: InputMaybe<InputFiltroBandeiraCartao>;
};

export type QueryBandeirasCartoesArgs = {
	Filtro?: InputMaybe<InputFiltroBandeirasCartao>;
};

export type QueryBuscarEmpresaArgs = {
	Filtro?: InputMaybe<InputFiltroEmpresa>;
};

export type QueryCategoriaArgs = {
	Filtro?: InputMaybe<InputFiltroCategoria>;
};

export type QueryCategoriaClienteArgs = {
	Filtro?: InputMaybe<InputFiltroCategoriaCliente>;
};

export type QueryCategoriasArgs = {
	Filtro?: InputMaybe<InputFiltroCategorias>;
};

export type QueryCategoriasClienteArgs = {
	Filtro?: InputMaybe<InputFiltroCategoriasCliente>;
};

export type QueryCepArgs = {
	Filtro: InputFiltroCep;
};

export type QueryCestArgs = {
	Filtro?: InputMaybe<InputFiltroCest>;
};

export type QueryCestsArgs = {
	Filtro?: InputMaybe<InputFiltroCests>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryCfopArgs = {
	Filtro?: InputMaybe<InputFiltroCfop>;
};

export type QueryCfopOperacaoArgs = {
	Filtro?: InputMaybe<InputFiltroCfopOperacao>;
};

export type QueryCfopOperacoesArgs = {
	Filtro?: InputMaybe<InputFiltroCfopOperacoes>;
};

export type QueryCfopsArgs = {
	Filtro?: InputMaybe<InputFiltroCfops>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryCidadeArgs = {
	Filtro?: InputMaybe<InputFiltroCidade>;
};

export type QueryCidadesArgs = {
	Filtro?: InputMaybe<InputFiltroCidades>;
};

export type QueryClienteArgs = {
	Filtro?: InputMaybe<InputFiltroCliente>;
};

export type QueryClientesArgs = {
	Filtro?: InputMaybe<InputFiltroClientes>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryColaboradorArgs = {
	Filtro?: InputMaybe<InputFiltroColaborador>;
};

export type QueryColaboradoresArgs = {
	Filtro?: InputMaybe<InputFiltroColaboradores>;
};

export type QueryColecaoArgs = {
	Filtro?: InputMaybe<InputFiltroColecao>;
};

export type QueryColecoesArgs = {
	Filtro?: InputMaybe<InputFiltroColecoes>;
};

export type QueryComissaoFormaPgtoArgs = {
	Filtro?: InputMaybe<InputFiltroComissaoFormaPgto>;
};

export type QueryComissoesArgs = {
	Filtro: InputFiltroComissoes;
};

export type QueryComissoesFormaPgtoArgs = {
	Filtro?: InputMaybe<InputFiltroComissoesFormaPgto>;
};

export type QueryCondicionaisArgs = {
	Filtro?: InputMaybe<InputFiltroCondicionais>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryCondicionalArgs = {
	Filtro?: InputMaybe<InputFiltroCondicional>;
};

export type QueryConfiguracaoArgs = {
	Filtro?: InputMaybe<InputFiltroConfiguracao>;
};

export type QueryConfiguracaoEmpresaArgs = {
	Filtro?: InputMaybe<InputFiltroConfiguracaoEmpresa>;
};

export type QueryConfiguracoesArgs = {
	Filtro?: InputMaybe<InputFiltroConfiguracoes>;
};

export type QueryConfiguracoesEmpresaArgs = {
	Filtro?: InputMaybe<InputFiltroConfiguracoesEmpresa>;
};

export type QueryContaFinanceiraArgs = {
	Filtro?: InputMaybe<InputFiltroContaFinanceira>;
};

export type QueryContadorArgs = {
	Filtro?: InputMaybe<InputFiltroContador>;
};

export type QueryContadoresArgs = {
	Filtro?: InputMaybe<InputFiltroContadores>;
};

export type QueryContasFinanceirasArgs = {
	Filtro?: InputMaybe<InputFiltroContasFinanceiras>;
};

export type QueryContatoPessoaArgs = {
	Filtro: InputFiltroContatoPessoa;
};

export type QueryCreditoArgs = {
	Filtro?: InputMaybe<InputFiltroQueryCredito>;
};

export type QueryCreditoClienteArgs = {
	Filtro: InputFiltroQueryCreditoCliente;
};

export type QueryCreditosArgs = {
	Filtro?: InputMaybe<InputFiltroQueryCreditos>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryDefinicaoArgs = {
	Filtro?: InputMaybe<InputFiltroDefinicao>;
};

export type QueryDefinicoesArgs = {
	Filtro?: InputMaybe<InputFiltroDefinicoes>;
};

export type QueryDepartamentoArgs = {
	Filtro?: InputMaybe<InputFiltroDepartamento>;
};

export type QueryDepartamentosArgs = {
	Filtro?: InputMaybe<InputFiltroDepartamentos>;
};

export type QueryDevolucaoArgs = {
	Filtro?: InputMaybe<InputFiltroDevolucao>;
};

export type QueryDevolucaoNfeCompraArgs = {
	Dados: InputDevolucaoNfeCompra;
};

export type QueryDevolucaoNfeVendaArgs = {
	IdNFe: Scalars["Int"];
};

export type QueryDevolucoesArgs = {
	Filtro?: InputMaybe<InputFiltroDevolucoes>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryEmpresaArgs = {
	Filtro?: InputMaybe<InputFiltroEmpresa>;
};

export type QueryEmpresasArgs = {
	Filtro?: InputMaybe<InputFiltroEmpresas>;
};

export type QueryEmpresasColaboradorArgs = {
	Filtro?: InputMaybe<InputFiltroColaboradorEmpresa>;
};

export type QueryEnderecoAdicionalArgs = {
	Filtro?: InputMaybe<InputFiltroEnderecoAdicional>;
};

export type QueryEstadoArgs = {
	Filtro?: InputMaybe<InputFiltroEstado>;
};

export type QueryEstadosArgs = {
	Filtro?: InputMaybe<InputFiltroEstado>;
};

export type QueryEstornoNfeArgs = {
	IdNFe: Scalars["Int"];
};

export type QueryEventoNfeArgs = {
	Filtro?: InputMaybe<InputFiltroEventoNfe>;
};

export type QueryEventosNfeArgs = {
	Filtro?: InputMaybe<InputFiltroEventosNfe>;
};

export type QueryFormaPagamentoArgs = {
	Filtro?: InputMaybe<InputFiltroFormaPagamento>;
};

export type QueryFormasPagamentoArgs = {
	Filtro?: InputMaybe<InputFiltroFormasPagamento>;
};

export type QueryFornecedorArgs = {
	Filtro?: InputMaybe<InputFiltroFornecedor>;
};

export type QueryFornecedoresArgs = {
	Filtro?: InputMaybe<InputFiltroFornecedores>;
};

export type QueryGeneroArgs = {
	Filtro?: InputMaybe<InputFiltroGenero>;
};

export type QueryGenerosArgs = {
	Filtro?: InputMaybe<InputFiltroGeneros>;
};

export type QueryGeraNfeDevolucoesArgs = {
	IdsDevolucao: Array<Scalars["Int"]>;
};

export type QueryGradeArgs = {
	Filtro?: InputMaybe<InputFiltroGrade>;
};

export type QueryGradeCampoArgs = {
	Filtro?: InputMaybe<InputFiltroGradeCampo>;
};

export type QueryGradeEixoArgs = {
	Filtro?: InputMaybe<InputFiltroGradeEixo>;
};

export type QueryGradeTipoArgs = {
	Filtro?: InputMaybe<InputFiltroGradeTipo>;
};

export type QueryGradesArgs = {
	Filtro?: InputMaybe<InputFiltroGrades>;
};

export type QueryGradesCamposArgs = {
	Filtro?: InputMaybe<InputFiltroGradeCampos>;
};

export type QueryGradesEixosArgs = {
	Filtro?: InputMaybe<InputFiltroGradeEixos>;
};

export type QueryGradesTiposArgs = {
	Filtro?: InputMaybe<InputFiltroGradeTipos>;
};

export type QueryImpressaoCondicionalArgs = {
	Hash: Scalars["String"];
	View?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryImpressaoVendaArgs = {
	Hash: Scalars["String"];
	View?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryIntermediadorArgs = {
	Filtro?: InputMaybe<InputFiltroIntermediador>;
};

export type QueryIntermediadoresArgs = {
	Filtro?: InputMaybe<InputFiltroIntermediadores>;
};

export type QueryInventarioEstoqueArgs = {
	Filtro: InputFiltroInventario;
};

export type QueryItensNfeArgs = {
	Filtro?: InputMaybe<InputFiltroItensNfe>;
};

export type QueryLancamentoVinculadoNfeArgs = {
	IdNfe: Scalars["Int"];
};

export type QueryLancamentosArgs = {
	Filtro?: InputMaybe<InputFiltroQueryLancamento>;
};

export type QueryManifestoArgs = {
	Filtro?: InputMaybe<InputFiltroManifesto>;
};

export type QueryManifestosArgs = {
	Filtro?: InputMaybe<InputFiltroManifestos>;
};

export type QueryMarcaArgs = {
	Filtro?: InputMaybe<InputFiltroMarca>;
};

export type QueryMarcasArgs = {
	Filtro?: InputMaybe<InputFiltroMarcas>;
};

export type QueryMetaArgs = {
	Filtro?: InputMaybe<InputFiltroMeta>;
};

export type QueryMetasArgs = {
	Filtro?: InputMaybe<InputFiltroMetas>;
};

export type QueryModuloArgs = {
	Filtro?: InputMaybe<InputFiltroModulo>;
};

export type QueryModuloEmpresaArgs = {
	Filtro?: InputMaybe<InputFiltroModuloEmpresa>;
};

export type QueryModulosArgs = {
	Filtro?: InputMaybe<InputFiltroModulos>;
};

export type QueryModulosEmpresaArgs = {
	Filtro?: InputMaybe<InputFiltroModulosEmpresa>;
};

export type QueryMovimentoBancoArgs = {
	Filtro?: InputMaybe<InputFiltroMovimentoBanco>;
};

export type QueryMovimentoCaixaArgs = {
	Filtro?: InputMaybe<InputFiltroMovimentoCaixa>;
};

export type QueryMovimentoEstoqueArgs = {
	Filtro?: InputMaybe<InputFiltroMovimentoEstoque>;
};

export type QueryMovimentoFinanceiroArgs = {
	Filtro?: InputMaybe<InputFiltroMovimentoFinanceiro>;
};

export type QueryMovimentoProdutoArgs = {
	Dados: InputFiltroMovimentoProduto;
};

export type QueryMovimentosEstoqueArgs = {
	Filtro?: InputMaybe<InputFiltroMovimentosEstoque>;
};

export type QueryNcmArgs = {
	Filtro?: InputMaybe<InputFiltroNcm>;
};

export type QueryNcmsArgs = {
	Filtro?: InputMaybe<InputFiltroNcms>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryNfeArgs = {
	Filtro?: InputMaybe<InputFiltroNfe>;
};

export type QueryNfesArgs = {
	Filtro?: InputMaybe<InputFiltroNfes>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryNiveisArgs = {
	Filtro?: InputMaybe<InputFiltroNiveis>;
};

export type QueryNivelArgs = {
	Filtro?: InputMaybe<InputFiltroNivel>;
};

export type QueryNivelDefinicaoArgs = {
	Filtro?: InputMaybe<InputFiltroNivelDefinicao>;
};

export type QueryNivelDefinicoesArgs = {
	Filtro?: InputMaybe<InputFiltroNiveisDefinicao>;
};

export type QueryOperacaoArgs = {
	Filtro?: InputMaybe<InputFiltroOperacao>;
};

export type QueryOperacoesArgs = {
	Filtro?: InputMaybe<InputFiltroOperacoes>;
};

export type QueryPagamentosArgs = {
	Filtro?: InputMaybe<InputFiltroPagamentos>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryPaisArgs = {
	Filtro?: InputMaybe<InputFiltroPais>;
};

export type QueryPaisesArgs = {
	Filtro?: InputMaybe<InputFiltroPaises>;
};

export type QueryPdvArgs = {
	Filtro?: InputMaybe<InputFiltroPdv>;
};

export type QueryPdvsArgs = {
	Filtro?: InputMaybe<InputFiltroPdvs>;
};

export type QueryPedidoVendaArgs = {
	Filtro?: InputMaybe<InputFiltroPedidoVenda>;
};

export type QueryPedidosVendaArgs = {
	Filtro?: InputMaybe<InputFiltroPedidosVenda>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryPermissoesArgs = {
	Filtro?: InputMaybe<InputFiltroPermissoes>;
};

export type QueryPessoaArgs = {
	Filtro?: InputMaybe<InputFiltroPessoa>;
};

export type QueryPessoasArgs = {
	Filtro?: InputMaybe<InputFiltroPessoas>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryPicoVendasArgs = {
	Filtro: InputFiltroTotalizadorVenda;
};

export type QueryProdutoArgs = {
	Filtro?: InputMaybe<InputFiltroProduto>;
};

export type QueryProdutoFornecedorArgs = {
	Filtro?: InputMaybe<InputFiltroProdutoFornecedor>;
};

export type QueryProdutosArgs = {
	Filtro?: InputMaybe<InputFiltroProdutos>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryProdutosFornecedoresArgs = {
	Filtro?: InputMaybe<InputFiltroProdutosFornecedor>;
};

export type QueryRankingProdutosArgs = {
	Filtro: InputFiltroRankingVenda;
};

export type QueryRecebimentoCartaoArgs = {
	Filtro?: InputMaybe<InputFiltroRecebimentoCartao>;
};

export type QueryRecebimentosArgs = {
	Filtro?: InputMaybe<InputFiltroRecebimentos>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryReferenciaComercialArgs = {
	Filtro?: InputMaybe<InputFiltroReferenciaComercial>;
};

export type QueryRegraTributacaoArgs = {
	Filtro?: InputMaybe<InputFiltroRegraTributacao>;
};

export type QueryRegrasTributacoesArgs = {
	Filtro?: InputMaybe<InputFiltroRegrasTributacao>;
};

export type QuerySaldoContasFinanceirasArgs = {
	Filtro?: InputMaybe<InputFiltroSaldoContasFinanceiras>;
};

export type QuerySituacaoTributariaArgs = {
	Filtro?: InputMaybe<InputFiltroSituacaoTributaria>;
};

export type QuerySituacoesTributariasArgs = {
	Filtro?: InputMaybe<InputFiltroSituacoesTributarias>;
};

export type QueryStatusVendaArgs = {
	Filtro?: InputMaybe<InputFiltroStatusVenda>;
};

export type QueryStatusVendasArgs = {
	Filtro?: InputMaybe<InputFiltroStatusVendas>;
};

export type QuerySubcategoriaArgs = {
	Filtro?: InputMaybe<InputFiltroSubcategoria>;
};

export type QuerySubcategoriasArgs = {
	Filtro?: InputMaybe<InputFiltroSubcategorias>;
};

export type QueryTipoDependenteArgs = {
	Filtro?: InputMaybe<InputFiltroTipoDependente>;
};

export type QueryTiposDependenteArgs = {
	Filtro?: InputMaybe<InputFiltroTiposDependente>;
};

export type QueryTotalizadoresTrocasDevolucoesArgs = {
	Filtro: InputFiltroTotalizadorTrocasDevolucao;
};

export type QueryTotalizadoresVendasArgs = {
	Filtro: InputFiltroTotalizadorVenda;
};

export type QueryTransportadoraArgs = {
	Filtro?: InputMaybe<InputFiltroTransportadora>;
};

export type QueryTransportadorasArgs = {
	Filtro?: InputMaybe<InputFiltroTransportadoras>;
};

export type QueryUltimaRegraAtualizadaArgs = {
	Filtro?: InputMaybe<InputFiltroRegraAtualizada>;
};

export type QueryUnidadeArgs = {
	Filtro?: InputMaybe<InputFiltroUnidade>;
};

export type QueryUnidadesArgs = {
	Filtro?: InputMaybe<InputFiltroUnidades>;
};

export type QueryVeiculoArgs = {
	Filtro?: InputMaybe<InputFiltroVeiculo>;
};

export type QueryVeiculosArgs = {
	Filtro?: InputMaybe<InputFiltroVeiculos>;
};

export type QueryVendaArgs = {
	Filtro?: InputMaybe<InputFiltroVenda>;
};

export type QueryVendasArgs = {
	Filtro?: InputMaybe<InputFiltroVendas>;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryVendasFormaPagamentoArgs = {
	Filtro: InputFiltroTotalizadorVenda;
};

export type QueryVendasPorProdutoArgs = {
	Filtro: InputFiltroVendasPorProduto;
	Paginacao?: InputMaybe<InputPaginacao>;
};

export type QueryVendasPorTributacaoArgs = {
	Filtro: InputFiltroVendasPorTributacao;
};

export type QueryVolumeVendasArgs = {
	Filtro: InputFiltroVolumeVenda;
};

export enum Recebimentos_Permissao {
	ConcederDescontoRecebimento = "CONCEDER_DESCONTO_RECEBIMENTO",
	Editar = "EDITAR",
	EfetuarRecebimento = "EFETUAR_RECEBIMENTO",
	EfetuarRenegociacao = "EFETUAR_RENEGOCIACAO",
	EstornarParcelas = "ESTORNAR_PARCELAS",
	Visualizar = "VISUALIZAR",
}

export enum Recebimento_Cartoes_Permissao {
	Receber = "RECEBER",
	Visualizar = "VISUALIZAR",
}

export enum Regra_Tributacao_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Relatorios_Comerciais_Permissao {
	Comissao = "COMISSAO",
	ComissaoTodos = "COMISSAO_TODOS",
	Dashboard = "DASHBOARD",
	EditarMetasComissoes = "EDITAR_METAS_COMISSOES",
	RankingVendasPorProduto = "RANKING_VENDAS_POR_PRODUTO",
}

export enum Relatorios_Fiscais_Permissao {
	SpedFiscal = "SPED_FISCAL",
	VendasPorTributacao = "VENDAS_POR_TRIBUTACAO",
}

export type RankingFormaPagamento = {
	__typename?: "RankingFormaPagamento";
	IdFormaPgto: Scalars["Int"];
	Nome: Scalars["String"];
	Porcentagem: Scalars["Float"];
	QtdVendas: Scalars["Int"];
	ValorVendido: Scalars["Float"];
};

export type RankingProduto = {
	__typename?: "RankingProduto";
	Codigo: Scalars["String"];
	Descricao: Scalars["String"];
	IdProduto: Scalars["Int"];
	QtdVendida: Scalars["Float"];
	ValorVendido: Scalars["Float"];
};

export enum RankingRelatorioVendasPorProduto {
	Custo = "CUSTO",
	Devolucoes = "DEVOLUCOES",
	MaioresVendas = "MAIORES_VENDAS",
	MargemLucro = "MARGEM_LUCRO",
	Quantidade = "QUANTIDADE",
	TicketMedio = "TICKET_MEDIO",
}

export type RecalculaNfe = {
	__typename?: "RecalculaNfe";
	Itens: Array<RecalculaNfeItens>;
	ValorDesconto: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorIcmsSt: Scalars["Float"];
	ValorIi: Scalars["Float"];
	ValorIpi: Scalars["Float"];
	ValorOutro: Scalars["Float"];
	ValorSeguro: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorTotalNfe: Scalars["Float"];
};

export type RecalculaNfeItens = {
	__typename?: "RecalculaNfeItens";
	IdProduto?: Maybe<Scalars["Int"]>;
	Index: Scalars["Int"];
	Quantidade: Scalars["Float"];
	ValorAcrescimo: Scalars["Float"];
	ValorCustoUnitario: Scalars["Float"];
	ValorDesconto: Scalars["Float"];
	ValorDescontoTotal: Scalars["Float"];
	ValorFcpSt: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorIcmsSt: Scalars["Float"];
	ValorIi: Scalars["Float"];
	ValorIpi: Scalars["Float"];
	ValorOutro: Scalars["Float"];
	ValorSeguro: Scalars["Float"];
	ValorSubTotal: Scalars["Float"];
	ValorTotalItem: Scalars["Float"];
	ValorUnitario: Scalars["Float"];
};

export type Receita = {
	__typename?: "Receita";
	CodigoFinanceiro?: Maybe<Scalars["String"]>;
	CodigoVenda?: Maybe<Scalars["String"]>;
	DataEmissao: Scalars["Date"];
	DataRecebimento?: Maybe<Scalars["Date"]>;
	DataVencimento: Scalars["Date"];
	FormaVendaFormaPagamento: FormasVenda;
	Historico?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCliente?: Maybe<Scalars["Int"]>;
	IdConta: Scalars["Int"];
	IdContaFinanceira?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdLancamento?: Maybe<Scalars["Int"]>;
	IdNfe?: Maybe<Scalars["Int"]>;
	IdParcela?: Maybe<Scalars["Int"]>;
	IdVenda?: Maybe<Scalars["Int"]>;
	NomeCliente?: Maybe<Scalars["String"]>;
	NomeConta: Scalars["String"];
	NomeContaFinanceira?: Maybe<Scalars["String"]>;
	NomeEmpresa: Scalars["String"];
	NomeFormaPagamento: Scalars["String"];
	NumeroNFe?: Maybe<Scalars["String"]>;
	PgtoParcial: Scalars["Float"];
	StatusParcela: StatusParcelamento;
	StatusRecebimento: StatusRecebimento;
	TipoFormaPagamento: TipoFormaPagamento;
	TipoLancamento: FormasVenda;
	TipoNivelDefinicao?: Maybe<TipoNivelDefinicao>;
	TipoPagamentoNFeFormaPagamento: TagPagamentoNFe;
	ValorDesconto: Scalars["Float"];
	ValorJuroPgto: Scalars["Float"];
	ValorReceber: Scalars["Float"];
	ValorReceberJuros: Scalars["Float"];
	ValorRecebido: Scalars["Float"];
	ValorTotal: Scalars["Float"];
	ValorTotalJuros: Scalars["Float"];
};

export type ReferenciaComercial = {
	__typename?: "ReferenciaComercial";
	Contato?: Maybe<Scalars["String"]>;
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	IdCliente: Scalars["Int"];
	Status?: Maybe<Scalars["Status"]>;
	Telefone?: Maybe<Scalars["String"]>;
};

export type ReferenciaNfe = {
	__typename?: "ReferenciaNfe";
	ChaveAcesso?: Maybe<Scalars["String"]>;
	CnpjEmitente?: Maybe<Scalars["String"]>;
	DataEmissao?: Maybe<Scalars["Date"]>;
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdFornecedor?: Maybe<Scalars["Int"]>;
	IdNfe: Scalars["Int"];
	IdNfeRef?: Maybe<Scalars["Int"]>;
	IndEmitente?: Maybe<IndicadorEmitente>;
	IndOperacao?: Maybe<IndicadorOperacao>;
	Modelo?: Maybe<ModeloReferencia>;
	NumNf?: Maybe<Scalars["Int"]>;
	Serie?: Maybe<Scalars["Int"]>;
	Tipo?: Maybe<TipoReferencia>;
};

export enum RegimeTributario {
	RegimeNormal = "REGIME_NORMAL",
	SimplesNacional = "SIMPLES_NACIONAL",
	SimplesNacionalExcessoDeSublimite = "SIMPLES_NACIONAL_EXCESSO_DE_SUBLIMITE",
	SimplesNacionalMei = "SIMPLES_NACIONAL_MEI",
}

export enum RegimeTributarioIcms {
	ContribuinteIcms = "CONTRIBUINTE_ICMS",
	ContribuinteIsento = "CONTRIBUINTE_ISENTO",
	NaoContribuinte = "NAO_CONTRIBUINTE",
}

export type RegraTributacao = {
	__typename?: "RegraTributacao";
	AliquotaCofinsAcao?: Maybe<Scalars["Float"]>;
	AliquotaFcpAcao?: Maybe<Scalars["Float"]>;
	AliquotaIcmsAcao?: Maybe<Scalars["Float"]>;
	AliquotaIpiAcao?: Maybe<Scalars["Float"]>;
	AliquotaMvaAcao?: Maybe<Scalars["Float"]>;
	AliquotaPisAcao?: Maybe<Scalars["Float"]>;
	CestAcaoCodigo?: Maybe<Scalars["String"]>;
	CestAcaoDescricao?: Maybe<Scalars["String"]>;
	CfopAcaoCodigo?: Maybe<Scalars["String"]>;
	CfopAcaoDescricao?: Maybe<Scalars["String"]>;
	CfopCondicaoCodigo?: Maybe<Scalars["String"]>;
	CfopCondicaoDescricao?: Maybe<Scalars["String"]>;
	CstCofinsAcaoCodigo?: Maybe<Scalars["String"]>;
	CstCofinsAcaoDescricao?: Maybe<Scalars["String"]>;
	CstIpiAcaoCodigo?: Maybe<Scalars["String"]>;
	CstIpiAcaoDescricao?: Maybe<Scalars["String"]>;
	CstPisAcaoCodigo?: Maybe<Scalars["String"]>;
	CstPisAcaoDescricao?: Maybe<Scalars["String"]>;
	DestacaDifalAcao?: Maybe<RespostaEnum>;
	Id: Scalars["Int"];
	IdCestAcao?: Maybe<Scalars["Int"]>;
	IdCfopAcao?: Maybe<Scalars["Int"]>;
	IdCfopCondicao?: Maybe<Scalars["Int"]>;
	IdCstCofinsAcao?: Maybe<Scalars["Int"]>;
	IdCstIpiAcao?: Maybe<Scalars["Int"]>;
	IdCstPisAcao?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdPessoaCondicao?: Maybe<Scalars["Int"]>;
	IdSituaTributAcao?: Maybe<Scalars["Int"]>;
	IdUFCondicao?: Maybe<Scalars["Int"]>;
	Ncms?: Maybe<Array<Maybe<NcmRegraTributacao>>>;
	Nome?: Maybe<Scalars["String"]>;
	ObservacaoNFAcao?: Maybe<Scalars["String"]>;
	PessoaCondicaoNome?: Maybe<Scalars["String"]>;
	PessoaCondicaoNomeFantasia?: Maybe<Scalars["String"]>;
	ReducaoIcmsAcao?: Maybe<Scalars["Float"]>;
	ReducaoMvaAcao?: Maybe<Scalars["Float"]>;
	RegimeTributarioCondicao?: Maybe<RegimeTributario>;
	RegimeTributarioICMSCondicao?: Maybe<RegimeTributarioIcms>;
	SituaTributAcaoCodigo?: Maybe<Scalars["String"]>;
	SituaTributAcaoDescricao?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	Tipo: TipoRegra;
	TipoCadastro?: Maybe<TipoCadastroRegra>;
	TipoConsumidorCondicao?: Maybe<ModalidadeCliente>;
	TipoUFCondicao?: Maybe<TipoUfDestino>;
	UFCondicaoDescricao?: Maybe<Scalars["String"]>;
};

export type RelatorioComissoesCliente = {
	__typename?: "RelatorioComissoesCliente";
	Id?: Maybe<Scalars["Int"]>;
	Nome?: Maybe<Scalars["String"]>;
};

export type RelatorioComissoesDevolucoes = {
	__typename?: "RelatorioComissoesDevolucoes";
	Cliente?: Maybe<RelatorioComissoesCliente>;
	Codigo?: Maybe<Scalars["String"]>;
	CodigoVenda?: Maybe<Scalars["String"]>;
	DataVenda?: Maybe<Scalars["Date"]>;
	IdVenda?: Maybe<Scalars["Int"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	QtdItens?: Maybe<Scalars["Float"]>;
	ValorTotal?: Maybe<Scalars["Float"]>;
};

export type RelatorioComissoesFormaPgto = {
	__typename?: "RelatorioComissoesFormaPgto";
	ComissaoPagar?: Maybe<Scalars["Float"]>;
	ComissaoPercentual?: Maybe<Scalars["Float"]>;
	IdFormaPgto?: Maybe<Scalars["Int"]>;
	NomeFormaPgto?: Maybe<Scalars["String"]>;
	TotalVendido?: Maybe<Scalars["Float"]>;
};

export type RelatorioComissoesMeta = {
	__typename?: "RelatorioComissoesMeta";
	AdicionalComissao?: Maybe<Scalars["Float"]>;
	NomeMeta?: Maybe<Scalars["String"]>;
	PercAtingido?: Maybe<Scalars["Float"]>;
	TipoBonificacao?: Maybe<TipoBonificacao>;
	ValorBonificacao?: Maybe<Scalars["Float"]>;
	ValorMeta?: Maybe<Scalars["Float"]>;
};

export type RelatorioComissoesVenda = {
	__typename?: "RelatorioComissoesVenda";
	Cliente?: Maybe<RelatorioComissoesCliente>;
	CodigoVenda?: Maybe<Scalars["Int"]>;
	DataVenda?: Maybe<Scalars["Date"]>;
	FormaPgto?: Maybe<Scalars["Int"]>;
	NomeFormaPgto?: Maybe<Scalars["String"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	QtdItens?: Maybe<Scalars["Float"]>;
	ValorComissao?: Maybe<Scalars["Float"]>;
	ValorDevolvido?: Maybe<Scalars["Float"]>;
	ValorTotal?: Maybe<Scalars["Float"]>;
};

export type RelatorioVendasPorProdutoItens = {
	__typename?: "RelatorioVendasPorProdutoItens";
	Codigo?: Maybe<Scalars["String"]>;
	Custo?: Maybe<Scalars["Float"]>;
	Descricao?: Maybe<Scalars["String"]>;
	IdProduto?: Maybe<Scalars["Int"]>;
	MargemLucro?: Maybe<Scalars["Float"]>;
	Quantidade?: Maybe<Scalars["Float"]>;
	ValorTotal?: Maybe<Scalars["Float"]>;
};

export type RequiredDateRange = {
	DataFim: Scalars["Date"];
	DataInicio: Scalars["Date"];
};

export type RequiredDateTimeRange = {
	DataFim: Scalars["DateTime"];
	DataInicio: Scalars["DateTime"];
};

export type RequiredTimeRange = {
	DataFim: Scalars["Time"];
	DataInicio: Scalars["Time"];
};

export enum RespostaEnum {
	Nao = "NAO",
	Sim = "SIM",
}

export enum Status_Das_Vendas_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type SaldoContaFinanceira = {
	__typename?: "SaldoContaFinanceira";
	Id: Scalars["Int"];
	Nome: Scalars["String"];
	Tipo: TipoContaFinanceira;
	ValorTotal: Scalars["Float"];
};

export enum SistemaEmpresa {
	Gluk = "GLUK",
	NotaPratica = "NOTA_PRATICA",
}

export type SituacaoTributaria = {
	__typename?: "SituacaoTributaria";
	Categoria: CategoriaSituacao;
	Codigo: Scalars["String"];
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdOriginal: Scalars["Int"];
	Status: Scalars["Status"];
};

export enum StatusCondicional {
	Cancelado = "CANCELADO",
	Devolvido = "DEVOLVIDO",
	EmCondicional = "EM_CONDICIONAL",
	Faturado = "FATURADO",
}

export enum StatusCredito {
	Aberto = "ABERTO",
	Quitado = "QUITADO",
}

export enum StatusEnum {
	Ativo = "ATIVO",
	Inativo = "INATIVO",
}

export enum StatusEmpresa {
	Ativo = "ATIVO",
	Bloqueado = "BLOQUEADO",
	Inativo = "INATIVO",
}

export enum StatusEventoNFe {
	Gerado = "GERADO",
	Transmitido = "TRANSMITIDO",
}

export enum StatusItemCondicional {
	Devolvido = "DEVOLVIDO",
	EmCondicional = "EM_CONDICIONAL",
	Faturado = "FATURADO",
}

export enum StatusManifestoNFe {
	ManifestoExcluido = "MANIFESTO_EXCLUIDO",
	NotaCancelada = "NOTA_CANCELADA",
	NotaImportada = "NOTA_IMPORTADA",
	Pendente = "PENDENTE",
	XmlBaixado = "XML_BAIXADO",
}

export enum StatusNfe {
	Cancelada = "CANCELADA",
	Contingencia = "CONTINGENCIA",
	Gerada = "GERADA",
	ImportadaPendente = "IMPORTADA_PENDENTE",
	Inutilizada = "INUTILIZADA",
	Transmitida = "TRANSMITIDA",
}

export enum StatusNotificacao {
	NaoVisto = "NAO_VISTO",
	Visto = "VISTO",
}

export enum StatusPagamento {
	Atrasado = "ATRASADO",
	AVencer = "A_VENCER",
	Pago = "PAGO",
	VenceHoje = "VENCE_HOJE",
}

export enum StatusParcelamento {
	Aberto = "ABERTO",
	EstornadoAberto = "ESTORNADO_ABERTO",
	EstornadoQuitado = "ESTORNADO_QUITADO",
	Quitado = "QUITADO",
	Renegociado = "RENEGOCIADO",
}

export enum StatusRecebimento {
	Atrasado = "ATRASADO",
	AVencer = "A_VENCER",
	Recebido = "RECEBIDO",
	VenceHoje = "VENCE_HOJE",
}

export enum StatusToken {
	Ativo = "ATIVO",
	ExpiraEm_10Segundos = "EXPIRA_EM_10_SEGUNDOS",
	Inativo = "INATIVO",
}

export type StatusVenda = {
	__typename?: "StatusVenda";
	Descricao: Scalars["String"];
	Estagio: Estagio;
	Id: Scalars["Int"];
	Ordem: Scalars["Int"];
	Status: Scalars["Status"];
};

export type Subcategoria = {
	__typename?: "Subcategoria";
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	DescricaoCategoria?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdCategoria: Scalars["Int"];
	IdEcommerce?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	Nome: Scalars["String"];
	NomeCategoria: Scalars["String"];
	Status: Scalars["Status"];
	StatusCategoria: Scalars["Status"];
};

export type Subscription = {
	__typename?: "Subscription";
	aguardaEventoNfe: Scalars["RetornoTarefa"];
	aguardaTransmissaoNfe?: Maybe<Scalars["RetornoTarefa"]>;
	atualizaCache: AtualizaCache;
	atualizaCacheSuporte: AtualizaCache;
	baixarXmlManifestoNFe: Scalars["RetornoTarefa"];
	configuracaoEmpresaAtualizada: Array<ConfiguracaoEmpresa>;
	consultaStatusNFe?: Maybe<Scalars["RetornoTarefa"]>;
	consultaStatusSEFAZ?: Maybe<Scalars["RetornoTarefa"]>;
	consultarManifestoNFeSefaz: Scalars["RetornoTarefa"];
	downloadArquivosContador: Scalars["RetornoTarefa"];
	enviaEmailCondicional?: Maybe<Scalars["RetornoTarefa"]>;
	enviaEmailNFe?: Maybe<Scalars["RetornoTarefa"]>;
	enviaEmailVenda?: Maybe<Scalars["RetornoTarefa"]>;
	eventoManifestoNFe: Scalars["RetornoTarefa"];
	gerarPDFCartaCorecao?: Maybe<Scalars["RetornoTarefa"]>;
	gerarRegistrosPaf?: Maybe<Scalars["RetornoTarefa"]>;
	gerarSpedFiscal?: Maybe<Scalars["RetornoTarefa"]>;
	gerarXML?: Maybe<Scalars["RetornoTarefa"]>;
	inutilizarNumeracaoNFe?: Maybe<Scalars["RetornoTarefa"]>;
	lerXMLCompra?: Maybe<Scalars["RetornoTarefa"]>;
	lerXMLManifestoNFe: Scalars["RetornoTarefa"];
	notificacao: Notificacao;
	permissaoColaboradorAtualizada?: Maybe<Array<PermissaoColaborador>>;
	sintegraEstoque?: Maybe<Scalars["RetornoTarefa"]>;
	transmiteNfe?: Maybe<Scalars["RetornoTarefa"]>;
};

export type SubscriptionAguardaEventoNfeArgs = {
	CorrelationId: Scalars["String"];
};

export type SubscriptionAguardaTransmissaoNfeArgs = {
	CorrelationId: Scalars["String"];
};

export type SubscriptionAtualizaCacheSuporteArgs = {
	IdEmpresa: Scalars["Int"];
};

export type SubscriptionBaixarXmlManifestoNFeArgs = {
	IdsManifesto: Array<InputMaybe<Scalars["Int"]>>;
};

export type SubscriptionConsultaStatusNFeArgs = {
	IdNFe: Scalars["Int"];
};

export type SubscriptionConsultarManifestoNFeSefazArgs = {
	ManifestarCienciaOperacao: Scalars["Boolean"];
};

export type SubscriptionDownloadArquivosContadorArgs = {
	Filtro: InputFiltroArquivo;
};

export type SubscriptionEnviaEmailCondicionalArgs = {
	IdCondicional: Scalars["Int"];
};

export type SubscriptionEnviaEmailNFeArgs = {
	IdNFe: Scalars["Int"];
};

export type SubscriptionEnviaEmailVendaArgs = {
	IdVenda: Scalars["Int"];
};

export type SubscriptionEventoManifestoNFeArgs = {
	Evento: EventoManifestoNFe;
	IdsManifesto: Array<InputMaybe<Scalars["Int"]>>;
	JustificativaEvento?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionGerarPdfCartaCorecaoArgs = {
	IdEvento: Scalars["Int"];
	IdNFe: Scalars["Int"];
};

export type SubscriptionGerarRegistrosPafArgs = {
	Filtro: InputFiltroRegistrosPaf;
};

export type SubscriptionGerarSpedFiscalArgs = {
	Filtro: InputFiltroSpedFiscal;
};

export type SubscriptionGerarXmlArgs = {
	IdNFe: Scalars["Int"];
};

export type SubscriptionInutilizarNumeracaoNFeArgs = {
	IdEvento: Scalars["Int"];
};

export type SubscriptionLerXmlCompraArgs = {
	CorrelationId: Scalars["String"];
};

export type SubscriptionLerXmlManifestoNFeArgs = {
	IdManifesto: Scalars["Int"];
};

export type SubscriptionSintegraEstoqueArgs = {
	Filtro: InputFiltroSintegra;
};

export type SubscriptionTransmiteNfeArgs = {
	IdNFe: Scalars["Int"];
};

export enum Tabela_Preco_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Totalizadores_Permissao {
	VisualizarSaldosTotalizadores = "VISUALIZAR_SALDOS_TOTALIZADORES",
}

export enum Transferencias_Contas_Financeiras_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Transportadora_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Tributacoes_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum TabelaNcm {
	Lc116 = "LC116",
	Nbs = "NBS",
	Ncm = "NCM",
}

export enum TagPagamentoNFe {
	BoletoBancario = "BOLETO_BANCARIO",
	CartaoCredito = "CARTAO_CREDITO",
	CartaoDebito = "CARTAO_DEBITO",
	Cheque = "CHEQUE",
	CreditoLoja = "CREDITO_LOJA",
	DepositoBancario = "DEPOSITO_BANCARIO",
	Dinheiro = "DINHEIRO",
	DuplicataMercantil = "DUPLICATA_MERCANTIL",
	FidelidadeCashbackCreditoVirtual = "FIDELIDADE_CASHBACK_CREDITO_VIRTUAL",
	Outros = "OUTROS",
	Pix = "PIX",
	SemPagamento = "SEM_PAGAMENTO",
	TransferenciaCarteiraDigital = "TRANSFERENCIA_CARTEIRA_DIGITAL",
	ValeAlimentacao = "VALE_ALIMENTACAO",
	ValeCombustivel = "VALE_COMBUSTIVEL",
	ValePresente = "VALE_PRESENTE",
	ValeRefeicao = "VALE_REFEICAO",
}

export type TimeRange = {
	DataFim?: InputMaybe<Scalars["Time"]>;
	DataInicio?: InputMaybe<Scalars["Time"]>;
};

export enum Tipo {
	Despesa = "DESPESA",
	Receita = "RECEITA",
}

export enum TipoArmaFogo {
	UsoPermitido = "USO_PERMITIDO",
	UsoRestrito = "USO_RESTRITO",
}

export enum TipoArquivo {
	Nfces = "NFCES",
	NotasImportadas = "NOTAS_IMPORTADAS",
	NotasTransmitidas = "NOTAS_TRANSMITIDAS",
}

export enum TipoArquivoConversao {
	Apagar = "APAGAR",
	Areceber = "ARECEBER",
	Colaboradores = "COLABORADORES",
	Definicoes = "DEFINICOES",
	Fornecedores = "FORNECEDORES",
	Grades = "GRADES",
	Produtos = "PRODUTOS",
	Transportadoras = "TRANSPORTADORAS",
	Veiculos = "VEICULOS",
}

export enum TipoAtualizaGrade {
	AtualizaGrade = "ATUALIZA_GRADE",
	CadastraProduto = "CADASTRA_PRODUTO",
}

export enum TipoBonificacao {
	Porcentagem = "PORCENTAGEM",
	Valor = "VALOR",
}

export enum TipoCadastroRegra {
	Sistema = "SISTEMA",
	Usuario = "USUARIO",
}

export enum TipoCfopOperacao {
	AmostraGratisBrindeBonificacao = "AMOSTRA_GRATIS_BRINDE_BONIFICACAO",
	Anulacao = "ANULACAO",
	AquisicaoServico = "AQUISICAO_SERVICO",
	Compras = "COMPRAS",
	DevolucaoCompra = "DEVOLUCAO_COMPRA",
	DevolucaoVenda = "DEVOLUCAO_VENDA",
	Entrada = "ENTRADA",
	Exportacao = "EXPORTACAO",
	ImportacaoCupom = "IMPORTACAO_CUPOM",
	Industrializacao = "INDUSTRIALIZACAO",
	Lancamento = "LANCAMENTO",
	NfeComplemento = "NFE_COMPLEMENTO",
	NfeEstorno = "NFE_ESTORNO",
	Outros = "OUTROS",
	PrestacaoServico = "PRESTACAO_SERVICO",
	Remessa = "REMESSA",
	Retorno = "RETORNO",
	Transferencia = "TRANSFERENCIA",
	Vendas = "VENDAS",
}

export enum TipoContaFinanceira {
	Banco = "BANCO",
	Caixa = "CAIXA",
}

export enum TipoContaLancamento {
	AdminCartao = "ADMIN_CARTAO",
	Cliente = "CLIENTE",
	ContaFinanceira = "CONTA_FINANCEIRA",
	Definicao = "DEFINICAO",
	Fornecedor = "FORNECEDOR",
	Nivel = "NIVEL",
}

export enum TipoContato {
	Email = "EMAIL",
	EmailAdicionalNfe = "EMAIL_ADICIONAL_NFE",
	EmailClienteNfe = "EMAIL_CLIENTE_NFE",
	Telefone = "TELEFONE",
}

export enum TipoCusto {
	CustoMedio = "CUSTO_MEDIO",
	UltimaCompra = "ULTIMA_COMPRA",
}

export type TipoDependente = {
	__typename?: "TipoDependente";
	DataAlteracao: Scalars["DateTime"];
	Descricao: Scalars["String"];
	Id: Scalars["Int"];
	Status: Scalars["Status"];
};

export enum TipoEndereco {
	Entrega = "ENTREGA",
}

export enum TipoEstornoReceita {
	AcrescimoDesconto = "ACRESCIMO_DESCONTO",
	Perda = "PERDA",
}

export enum TipoEventoNFe {
	Cancelamento = "CANCELAMENTO",
	CartaCorrecao = "CARTA_CORRECAO",
	Inutilizacao = "INUTILIZACAO",
}

export enum TipoFiltroVolumeVenda {
	Ano = "ANO",
	Dia = "DIA",
	Mes = "MES",
}

export enum TipoFormaPagamento {
	CartaoCredito = "CARTAO_CREDITO",
	CartaoDebito = "CARTAO_DEBITO",
	Financeiro = "FINANCEIRO",
	Parcelamento = "PARCELAMENTO",
	ValeCredito = "VALE_CREDITO",
}

export enum TipoFrete {
	Destinatario = "DESTINATARIO",
	Emitente = "EMITENTE",
	ProprioDestinatario = "PROPRIO_DESTINATARIO",
	ProprioEmitente = "PROPRIO_EMITENTE",
	SemFrete = "SEM_FRETE",
	Terceiros = "TERCEIROS",
}

export enum TipoGrade {
	Linha = "LINHA",
	LinhaEColuna = "LINHA_E_COLUNA",
}

export enum TipoGradeCampo {
	Coluna = "COLUNA",
	Linha = "LINHA",
}

export enum TipoIntermedio {
	ContaOrdem = "CONTA_ORDEM",
	ContaPropria = "CONTA_PROPRIA",
	Encomenda = "ENCOMENDA",
}

export enum TipoNFe {
	Entrada = "ENTRADA",
	Saida = "SAIDA",
}

export enum TipoNivelDefinicao {
	AcumuladoAnoAnterior = "ACUMULADO_ANO_ANTERIOR",
	AdiantamentoCliente = "ADIANTAMENTO_CLIENTE",
	AdminCartao = "ADMIN_CARTAO",
	Ativo = "ATIVO",
	BeneficiosColaborador = "BENEFICIOS_COLABORADOR",
	CapitalSocial = "CAPITAL_SOCIAL",
	CirculanteAtivo = "CIRCULANTE_ATIVO",
	CirculantePassivo = "CIRCULANTE_PASSIVO",
	Cliente = "CLIENTE",
	Cofins = "COFINS",
	CofinsRecolher = "COFINS_RECOLHER",
	Comissao = "COMISSAO",
	ContaFinanceira = "CONTA_FINANCEIRA",
	ContaPagar = "CONTA_PAGAR",
	ContaReceber = "CONTA_RECEBER",
	ContribuicaoRecolher = "CONTRIBUICAO_RECOLHER",
	ContribuicaoSocial = "CONTRIBUICAO_SOCIAL",
	Conversao = "CONVERSAO",
	CustoMateriaPrima = "CUSTO_MATERIA_PRIMA",
	CustoMercadoriaVendida = "CUSTO_MERCADORIA_VENDIDA",
	CustoProducao = "CUSTO_PRODUCAO",
	CustoProducaoVendida = "CUSTO_PRODUCAO_VENDIDA",
	CustoProdAcabado = "CUSTO_PROD_ACABADO",
	CustoVariavel = "CUSTO_VARIAVEL",
	DecimoTerceiroFerias = "DECIMO_TERCEIRO_FERIAS",
	DeducaoVenda = "DEDUCAO_VENDA",
	DescontoPagamento = "DESCONTO_PAGAMENTO",
	DescontoRecebimento = "DESCONTO_RECEBIMENTO",
	DescontoVenda = "DESCONTO_VENDA",
	DespesaAdministrativa = "DESPESA_ADMINISTRATIVA",
	DespesaFinanceira = "DESPESA_FINANCEIRA",
	DespesaFixa = "DESPESA_FIXA",
	DevolucaoTroca = "DEVOLUCAO_TROCA",
	Disponivel = "DISPONIVEL",
	Emprestimo = "EMPRESTIMO",
	Estoque = "ESTOQUE",
	Faturamento = "FATURAMENTO",
	FgtsColaborador = "FGTS_COLABORADOR",
	FgtsRecolher = "FGTS_RECOLHER",
	Fornecedor = "FORNECEDOR",
	FreteAdm = "FRETE_ADM",
	FreteProducao = "FRETE_PRODUCAO",
	FreteVenda = "FRETE_VENDA",
	Icms = "ICMS",
	IcmsRecolher = "ICMS_RECOLHER",
	Imobilizado = "IMOBILIZADO",
	ImpostoNormal = "IMPOSTO_NORMAL",
	ImpostoSn = "IMPOSTO_SN",
	InssColaborador = "INSS_COLABORADOR",
	InssRecolher = "INSS_RECOLHER",
	Investimento = "INVESTIMENTO",
	Ipi = "IPI",
	IpiRecolher = "IPI_RECOLHER",
	Irpj = "IRPJ",
	IrpjRecolher = "IRPJ_RECOLHER",
	Iss = "ISS",
	IssRecolher = "ISS_RECOLHER",
	JuroPagamento = "JURO_PAGAMENTO",
	JuroRecebimento = "JURO_RECEBIMENTO",
	LucroBruto = "LUCRO_BRUTO",
	MateriaPrima = "MATERIA_PRIMA",
	Mercadoria = "MERCADORIA",
	NaoCirculanteAtivo = "NAO_CIRCULANTE_ATIVO",
	NaoCirculantePassivo = "NAO_CIRCULANTE_PASSIVO",
	ObrigacaoFiscal = "OBRIGACAO_FISCAL",
	ObrigacaoTrabalhista = "OBRIGACAO_TRABALHISTA",
	OutraTrabalhista = "OUTRA_TRABALHISTA",
	OutroImposto = "OUTRO_IMPOSTO",
	OutroImpostoRecolher = "OUTRO_IMPOSTO_RECOLHER",
	OutroLongoPrazo = "OUTRO_LONGO_PRAZO",
	Passivo = "PASSIVO",
	PatrimonioLiquido = "PATRIMONIO_LIQUIDO",
	PerdaCredito = "PERDA_CREDITO",
	Pis = "PIS",
	PisRecolher = "PIS_RECOLHER",
	ProLabore = "PRO_LABORE",
	ProLaborePagar = "PRO_LABORE_PAGAR",
	ReceitaFinanceira = "RECEITA_FINANCEIRA",
	ReceitaLiquida = "RECEITA_LIQUIDA",
	ResultadoAcumulado = "RESULTADO_ACUMULADO",
	ResultadoBruto = "RESULTADO_BRUTO",
	ResultadoFinanceiro = "RESULTADO_FINANCEIRO",
	ResultadoLiquido = "RESULTADO_LIQUIDO",
	Salario = "SALARIO",
	SalarioPagar = "SALARIO_PAGAR",
	SnRecolher = "SN_RECOLHER",
	TarifaCobranca = "TARIFA_COBRANCA",
	VendaMercadoria = "VENDA_MERCADORIA",
	VendaProducao = "VENDA_PRODUCAO",
	VendaServico = "VENDA_SERVICO",
}

export enum TipoNotificacao {
	AcessoSuporte = "ACESSO_SUPORTE",
	Aviso = "AVISO",
	Erro = "ERRO",
	Informacao = "INFORMACAO",
	Sucesso = "SUCESSO",
}

export enum TipoOperacao {
	AcrescimoValorContaPagar = "ACRESCIMO_VALOR_CONTA_PAGAR",
	AcrescimoValorContaReceber = "ACRESCIMO_VALOR_CONTA_RECEBER",
	BaixaCreditoCliente = "BAIXA_CREDITO_CLIENTE",
	CancelamentoVenda = "CANCELAMENTO_VENDA",
	CaptacaoEmprestimo = "CAPTACAO_EMPRESTIMO",
	ComprasDespesas = "COMPRAS_DESPESAS",
	DescontoValorContaPagar = "DESCONTO_VALOR_CONTA_PAGAR",
	DescontoValorContaReceber = "DESCONTO_VALOR_CONTA_RECEBER",
	DevolucaoCompra = "DEVOLUCAO_COMPRA",
	DevolucaoVenda = "DEVOLUCAO_VENDA",
	EstornarCancelarConta = "ESTORNAR_CANCELAR_CONTA",
	IntegralizacaoCapital = "INTEGRALIZACAO_CAPITAL",
	OutrasReceitas = "OUTRAS_RECEITAS",
	PagamentoContasPagar = "PAGAMENTO_CONTAS_PAGAR",
	PagamentoParcialContasPagar = "PAGAMENTO_PARCIAL_CONTAS_PAGAR",
	RecebimentoContasReceber = "RECEBIMENTO_CONTAS_RECEBER",
	RecebimentoParcialContasReceber = "RECEBIMENTO_PARCIAL_CONTAS_RECEBER",
	RenegociacaoContasPagar = "RENEGOCIACAO_CONTAS_PAGAR",
	RenegociacaoContasPagarParcelas = "RENEGOCIACAO_CONTAS_PAGAR_PARCELAS",
	RenegociacaoContasReceber = "RENEGOCIACAO_CONTAS_RECEBER",
	RenegociacaoContasReceberParcelas = "RENEGOCIACAO_CONTAS_RECEBER_PARCELAS",
	TransferenciasEntreContasFinanceirasEntrada = "TRANSFERENCIAS_ENTRE_CONTAS_FINANCEIRAS_ENTRADA",
	TransferenciasEntreContasFinanceirasSaida = "TRANSFERENCIAS_ENTRE_CONTAS_FINANCEIRAS_SAIDA",
	TransfCustoMercadVendidaEstoqueMercadoria = "TRANSF_CUSTO_MERCAD_VENDIDA_ESTOQUE_MERCADORIA",
	TransfCustoProducaoEstoqueMercadoria = "TRANSF_CUSTO_PRODUCAO_ESTOQUE_MERCADORIA",
	TransfEstoqueMatPrimaCustoMatPrima = "TRANSF_ESTOQUE_MAT_PRIMA_CUSTO_MAT_PRIMA",
	TransfEstoqueMercadoriaCustoMercadVendida = "TRANSF_ESTOQUE_MERCADORIA_CUSTO_MERCAD_VENDIDA",
	Vendas = "VENDAS",
	VendasAntecipadasValeCredito = "VENDAS_ANTECIPADAS_VALE_CREDITO",
}

export enum TipoOperacaoDev {
	Abatimentoparcela = "ABATIMENTOPARCELA",
	Credito = "CREDITO",
	Dinheiro = "DINHEIRO",
}

export enum TipoPermissao {
	Cliente = "CLIENTE",
	Terceiro = "TERCEIRO",
	Todos = "TODOS",
}

export enum TipoPessoa {
	Cliente = "CLIENTE",
	Colaborador = "COLABORADOR",
	Fornecedor = "FORNECEDOR",
	Nenhuma = "NENHUMA",
}

export enum TipoReferencia {
	Nfce = "NFCE",
	Nfe = "NFE",
	Nf_1A = "NF_1A",
	NfManual = "NF_MANUAL",
}

export enum TipoRegra {
	Entrada = "ENTRADA",
	ModeloTributacao = "MODELO_TRIBUTACAO",
	Saida = "SAIDA",
}

export enum TipoToken {
	RecuperarSenha = "RECUPERAR_SENHA",
	Terceiro = "TERCEIRO",
	Usuario = "USUARIO",
	Websocket = "WEBSOCKET",
}

export enum TipoTransacao {
	Devolucao = "DEVOLUCAO",
	Troca = "TROCA",
}

export enum TipoUfDestino {
	Estado = "ESTADO",
	Interestadual = "INTERESTADUAL",
	Todos = "TODOS",
}

export enum TipoVenda {
	Pedido = "PEDIDO",
	PreVenda = "PRE_VENDA",
	Venda = "VENDA",
	VendaPdv = "VENDA_PDV",
}

export enum TipoViaTransporte {
	Aerea = "AEREA",
	Conduto = "CONDUTO",
	Courier = "COURIER",
	EmMaos = "EM_MAOS",
	EntradaSaidaFicta = "ENTRADA_SAIDA_FICTA",
	Ferroviaria = "FERROVIARIA",
	Fluvial = "FLUVIAL",
	Lacustre = "LACUSTRE",
	Maritima = "MARITIMA",
	MeiosProprios = "MEIOS_PROPRIOS",
	Postal = "POSTAL",
	Reboque = "REBOQUE",
	Rodoviaria = "RODOVIARIA",
}

export enum TipoVisibilidade {
	Produto = "PRODUTO",
	Total = "TOTAL",
}

export type Token = {
	__typename?: "Token";
	CpfCnpj?: Maybe<Scalars["String"]>;
	DataCriacao: Scalars["DateTime"];
	DataExpiracao: Scalars["DateTime"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdPessoa?: Maybe<Scalars["Int"]>;
	JsonData?: Maybe<Scalars["String"]>;
	RefreshToken?: Maybe<Scalars["String"]>;
	SistemaEmpresa: SistemaEmpresa;
	Status: StatusToken;
	Tipo: TipoToken;
	Token1?: Maybe<Scalars["String"]>;
	Token2?: Maybe<Scalars["String"]>;
	Token3?: Maybe<Scalars["String"]>;
	TokenTerceiro?: Maybe<Scalars["String"]>;
};

export type TotaisVendas = {
	__typename?: "TotaisVendas";
	QtdItens: Scalars["Float"];
	QtdVendas: Scalars["Int"];
	TicketMedioItens: Scalars["Float"];
	TicketMedioVendas: Scalars["Float"];
	TotalVendas: Scalars["Float"];
};

export type TotalizadorTrocasDevolucao = {
	__typename?: "TotalizadorTrocasDevolucao";
	QuantidadeDevolucoes: Scalars["Int"];
	QuantidadeTrocas: Scalars["Int"];
	ValorDevolucoes: Scalars["Float"];
	ValorTrocas: Scalars["Float"];
};

export type TotalizadorVenda = {
	__typename?: "TotalizadorVenda";
	ValorPeriodo: TotaisVendas;
	ValorPeriodoAnoAnterior: TotaisVendas;
	ValorPeriodoAnterior: TotaisVendas;
};

export type TotalizadoresRelatorioVendaPorProdutos = {
	__typename?: "TotalizadoresRelatorioVendaPorProdutos";
	QtdItensVendidos: Scalars["Float"];
	TicketMedio: Scalars["Float"];
	TotalCusto: Scalars["Float"];
	TotalDevolucoes: Scalars["Float"];
	TotalLucro: Scalars["Float"];
	TotalRegistros: Scalars["Int"];
	TotalVendido: Scalars["Float"];
};

export type Transportadora = {
	__typename?: "Transportadora";
	Bairro?: Maybe<Scalars["String"]>;
	Celular?: Maybe<Scalars["String"]>;
	Celular2?: Maybe<Scalars["String"]>;
	Cep?: Maybe<Scalars["String"]>;
	CidadeNome?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	Complemento?: Maybe<Scalars["String"]>;
	CpfCnpj?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataNascimento?: Maybe<Scalars["Date"]>;
	Email?: Maybe<Scalars["String"]>;
	IdCidade?: Maybe<Scalars["Int"]>;
	IdEmpresa: Scalars["Int"];
	IdEstado?: Maybe<Scalars["Int"]>;
	IdPais?: Maybe<Scalars["Int"]>;
	IdPessoa: Scalars["Int"];
	InscricaoEstadual?: Maybe<Scalars["String"]>;
	InscricaoMunicipal?: Maybe<Scalars["String"]>;
	Logradouro?: Maybe<Scalars["String"]>;
	Nome: Scalars["String"];
	NomeFantasia?: Maybe<Scalars["String"]>;
	NumEndereco?: Maybe<Scalars["String"]>;
	Observacao?: Maybe<Scalars["String"]>;
	RG?: Maybe<Scalars["String"]>;
	RegimeTributario?: Maybe<RegimeTributario>;
	RegimeTributarioICMS?: Maybe<RegimeTributarioIcms>;
	Status: Scalars["Status"];
	Telefone?: Maybe<Scalars["String"]>;
	Uf?: Maybe<Scalars["String"]>;
	Veiculos?: Maybe<Array<Veiculo>>;
};

export enum Unidade_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Usuario_Permissao {
	Cadastrar = "CADASTRAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export type Unidade = {
	__typename?: "Unidade";
	DataAlteracao: Scalars["DateTime"];
	Descricao?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdEmpresa?: Maybe<Scalars["Int"]>;
	IdOriginal: Scalars["Int"];
	Simbolo: Scalars["String"];
	Status: Scalars["Status"];
};

export enum Vale_Credito_Permissao {
	BaixarCredito = "BAIXAR_CREDITO",
	Cadastrar = "CADASTRAR",
	Visualizar = "VISUALIZAR",
}

export enum Venda_Permissao {
	Cadastrar = "CADASTRAR",
	Cancelar = "CANCELAR",
	Editar = "EDITAR",
	Visualizar = "VISUALIZAR",
}

export enum Visualizar_Saldos_Permissao {
	Bancos = "BANCOS",
	Caixas = "CAIXAS",
}

export type ValoresPermissoes = {
	ADMINISTRADORAS_CARTAO: Array<Administradoras_Cartao_Permissao>;
	CAPTACAO_EMPRESTIMOS: Array<Captacao_Emprestimos_Permissao>;
	CATEGORIA: Array<Categoria_Permissao>;
	CATEGORIA_CLIENTE: Array<Categoria_Cliente_Permissao>;
	CLIENTE: Array<Cliente_Permissao>;
	COLABORADOR: Array<Colaborador_Permissao>;
	COLECAO: Array<Colecao_Permissao>;
	CONDICIONAL: Array<Condicional_Permissao>;
	CONFIGURACOES_GERAIS: Array<Configuracoes_Gerais_Permissao>;
	CONTADOR: Array<Contador_Permissao>;
	CONTA_FINANCEIRA: Array<Conta_Financeira_Permissao>;
	CUPOM_FISCAL: Array<Cupom_Fiscal_Permissao>;
	DEFINICAO: Array<Definicao_Permissao>;
	DEPARTAMENTOS_COLABORADOR: Array<Departamentos_Colaborador_Permissao>;
	DEVOLUCOES_TROCAS: Array<Devolucoes_Trocas_Permissao>;
	EMPRESA: Array<Empresa_Permissao>;
	FORMA_PAGAMENTO: Array<Forma_Pagamento_Permissao>;
	FORNECEDOR: Array<Fornecedor_Permissao>;
	GENERO: Array<Genero_Permissao>;
	GRADES: Array<Grades_Permissao>;
	INTERMEDIADORES_VENDA: Array<Intermediadores_Venda_Permissao>;
	LANCAMENTOS: Array<Lancamentos_Permissao>;
	MARCA: Array<Marca_Permissao>;
	MOVIMENTO_CONTAS_FINANCEIRAS: Array<Movimento_Contas_Financeiras_Permissao>;
	NOTAS_FISCAIS_COMPRA: Array<Notas_Fiscais_Compra_Permissao>;
	NOTAS_FISCAIS_ELETRONICAS: Array<Notas_Fiscais_Eletronicas_Permissao>;
	PAGAMENTOS: Array<Pagamentos_Permissao>;
	PDV: Array<Pdv_Permissao>;
	PEDIDO_VENDA: Array<Pedido_Venda_Permissao>;
	PERMISSOES_COLABORADOR: Array<Permissoes_Colaborador_Permissao>;
	PLANO_CONTAS: Array<Plano_Contas_Permissao>;
	PRECO_DOS_ITENS_VENDA: Array<Preco_Dos_Itens_Venda_Permissao>;
	PRODUTO: Array<Produto_Permissao>;
	RECEBIMENTOS: Array<Recebimentos_Permissao>;
	RECEBIMENTO_CARTOES: Array<Recebimento_Cartoes_Permissao>;
	REGRA_TRIBUTACAO: Array<Regra_Tributacao_Permissao>;
	RELATORIOS_COMERCIAIS: Array<Relatorios_Comerciais_Permissao>;
	RELATORIOS_FISCAIS: Array<Relatorios_Fiscais_Permissao>;
	STATUS_DAS_VENDAS: Array<Status_Das_Vendas_Permissao>;
	TABELA_PRECO: Array<Tabela_Preco_Permissao>;
	TOTALIZADORES: Array<Totalizadores_Permissao>;
	TRANSFERENCIAS_CONTAS_FINANCEIRAS: Array<Transferencias_Contas_Financeiras_Permissao>;
	TRANSPORTADORA: Array<Transportadora_Permissao>;
	TRIBUTACOES: Array<Tributacoes_Permissao>;
	UNIDADE: Array<Unidade_Permissao>;
	USUARIO: Array<Usuario_Permissao>;
	VALE_CREDITO: Array<Vale_Credito_Permissao>;
	VENDA: Array<Venda_Permissao>;
	VISUALIZAR_SALDOS: Array<Visualizar_Saldos_Permissao>;
};

export enum ValoresTributacoes {
	Icms_00TributadaIntegralmente = "ICMS_00_TRIBUTADA_INTEGRALMENTE",
	Icms_10TributadaEComCobrancaDoIcmsPorSt = "ICMS_10_TRIBUTADA_E_COM_COBRANCA_DO_ICMS_POR_ST",
	Icms_20ComReducaoDeBaseDeCalculo = "ICMS_20_COM_REDUCAO_DE_BASE_DE_CALCULO",
	Icms_30IsentaOuNaoTributadaEComCobrancaDoIcmsPorSt = "ICMS_30_ISENTA_OU_NAO_TRIBUTADA_E_COM_COBRANCA_DO_ICMS_POR_ST",
	Icms_40Isenta = "ICMS_40_ISENTA",
	Icms_41NaoTributada = "ICMS_41_NAO_TRIBUTADA",
	Icms_50Suspensao = "ICMS_50_SUSPENSAO",
	Icms_51Diferimento = "ICMS_51_DIFERIMENTO",
	Icms_60IcmsCobradoAnteriormentePorSt = "ICMS_60_ICMS_COBRADO_ANTERIORMENTE_POR_ST",
	Icms_70ComReducaoDeBaseDeCalculoECobrancaDoIcmsPorSt = "ICMS_70_COM_REDUCAO_DE_BASE_DE_CALCULO_E_COBRANCA_DO_ICMS_POR_ST",
	Icms_90Outras = "ICMS_90_OUTRAS",
	Icms_101TributadaPeloSimplesNacionalComPermissaoDeCredito = "ICMS_101_TRIBUTADA_PELO_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO",
	Icms_102TributadaPeloSimplesNacionalSemPermissaoDeCredito = "ICMS_102_TRIBUTADA_PELO_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO",
	Icms_103IsencaoDoIcmsNoSimplesNacionalParaFaixaDeReceitaBruta = "ICMS_103_ISENCAO_DO_ICMS_NO_SIMPLES_NACIONAL_PARA_FAIXA_DE_RECEITA_BRUTA",
	Icms_201TributadaPeloSimplesNacionalComPermissaoDeCreditoEComCobrancaDoIcmsPorSt = "ICMS_201_TRIBUTADA_PELO_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO_E_COM_COBRANCA_DO_ICMS_POR_ST",
	Icms_202TributadaPeloSimplesNacionalSemPermissaoDeCreditoEComCobrancaDoIcmsPorSt = "ICMS_202_TRIBUTADA_PELO_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO_E_COM_COBRANCA_DO_ICMS_POR_ST",
	Icms_203IsencaoDoIcmsNoSimplesNacionalParaFaixaDeReceitaBrutaEComCobrancaDoIcmsPorSt = "ICMS_203_ISENCAO_DO_ICMS_NO_SIMPLES_NACIONAL_PARA_FAIXA_DE_RECEITA_BRUTA_E_COM_COBRANCA_DO_ICMS_POR_ST",
	Icms_300Imune = "ICMS_300_IMUNE",
	Icms_400NaoTributadaPeloSimplesNacional = "ICMS_400_NAO_TRIBUTADA_PELO_SIMPLES_NACIONAL",
	Icms_500IcmsCobradoAnteriormentePorStOuPorAntecipacao = "ICMS_500_ICMS_COBRADO_ANTERIORMENTE_POR_ST_OU_POR_ANTECIPACAO",
	Icms_900Outros = "ICMS_900_OUTROS",
	Ipi_00EntradaComRecuperacaoDeCredito = "IPI_00_ENTRADA_COM_RECUPERACAO_DE_CREDITO",
	Ipi_01EntradaTributadaComAliquotaZero = "IPI_01_ENTRADA_TRIBUTADA_COM_ALIQUOTA_ZERO",
	Ipi_02EntradaIsenta = "IPI_02_ENTRADA_ISENTA",
	Ipi_03EntradaNaoTributada = "IPI_03_ENTRADA_NAO_TRIBUTADA",
	Ipi_04EntradaImune = "IPI_04_ENTRADA_IMUNE",
	Ipi_05EntradaComSuspensao = "IPI_05_ENTRADA_COM_SUSPENSAO",
	Ipi_49OutrasEntradas = "IPI_49_OUTRAS_ENTRADAS",
	Ipi_50SaidaTributada = "IPI_50_SAIDA_TRIBUTADA",
	Ipi_51SaidaTributadaComAliquotaZero = "IPI_51_SAIDA_TRIBUTADA_COM_ALIQUOTA_ZERO",
	Ipi_52SaidaIsenta = "IPI_52_SAIDA_ISENTA",
	Ipi_53SaidaNaoTributada = "IPI_53_SAIDA_NAO_TRIBUTADA",
	Ipi_54SaidaImune = "IPI_54_SAIDA_IMUNE",
	Ipi_55SaidaComSuspensao = "IPI_55_SAIDA_COM_SUSPENSAO",
	Ipi_99OutrasSaidas = "IPI_99_OUTRAS_SAIDAS",
	PisCofins_01OperacaoTributavelComAliquotaBasica = "PIS_COFINS_01_OPERACAO_TRIBUTAVEL_COM_ALIQUOTA_BASICA",
	PisCofins_02OperacaoTributavelComAliquotaDiferenciada = "PIS_COFINS_02_OPERACAO_TRIBUTAVEL_COM_ALIQUOTA_DIFERENCIADA",
	PisCofins_03OperacaoTributavelComAliquotaPorUnidadeDeMedidaDeProduto = "PIS_COFINS_03_OPERACAO_TRIBUTAVEL_COM_ALIQUOTA_POR_UNIDADE_DE_MEDIDA_DE_PRODUTO",
	PisCofins_04OperacaoTributavelMonofasicaRevendaAAliquotaZero = "PIS_COFINS_04_OPERACAO_TRIBUTAVEL_MONOFASICA_REVENDA_A_ALIQUOTA_ZERO",
	PisCofins_05OperacaoTributavelPorSubstituicaoTributaria = "PIS_COFINS_05_OPERACAO_TRIBUTAVEL_POR_SUBSTITUICAO_TRIBUTARIA",
	PisCofins_06OperacaoTributavelAAliquotaZero = "PIS_COFINS_06_OPERACAO_TRIBUTAVEL_A_ALIQUOTA_ZERO",
	PisCofins_07OperacaoIsentaDaContribuicao = "PIS_COFINS_07_OPERACAO_ISENTA_DA_CONTRIBUICAO",
	PisCofins_08OperacaoSemIncidenciaDaContribuicao = "PIS_COFINS_08_OPERACAO_SEM_INCIDENCIA_DA_CONTRIBUICAO",
	PisCofins_09OperacaoComSuspensaoDaContribuicao = "PIS_COFINS_09_OPERACAO_COM_SUSPENSAO_DA_CONTRIBUICAO",
	PisCofins_49OutrasOperacoesDeSaida = "PIS_COFINS_49_OUTRAS_OPERACOES_DE_SAIDA",
	PisCofins_50OperacaoComDireitoACreditoVinculadaExclusivamenteAReceitaTributadaNoMercadoInterno = "PIS_COFINS_50_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_TRIBUTADA_NO_MERCADO_INTERNO",
	PisCofins_51OperacaoComDireitoACreditoVinculadaExclusivamenteAReceitaNaoTributadaNoMercadoInterno = "PIS_COFINS_51_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_NO_MERCADO_INTERNO",
	PisCofins_52OperacaoComDireitoACreditoVinculadaExclusivamenteAReceitaDeExportacao = "PIS_COFINS_52_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_DE_EXPORTACAO",
	PisCofins_53OperacaoComDireitoACreditoVinculadaAReceitasTributadasENaoTributadasNoMercadoInterno = "PIS_COFINS_53_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_A_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_NO_MERCADO_INTERNO",
	PisCofins_54OperacaoComDireitoACreditoVinculadaAReceitasTributadasNoMercadoInternoEDeExportacao = "PIS_COFINS_54_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_A_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_E_DE_EXPORTACAO",
	PisCofins_55OperacaoComDireitoACreditoVinculadaAReceitasNaoTributadasNoMercadoInternoEDeExportacao = "PIS_COFINS_55_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_A_RECEITAS_NAO_TRIBUTADAS_NO_MERCADO_INTERNO_E_DE_EXPORTACAO",
	PisCofins_56OperacaoComDireitoACreditoVinculadaAReceitasTributadasENaoTributadasNoMercadoInternoEDeExportacao = "PIS_COFINS_56_OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_A_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_NO_MERCADO_INTERNO_E_DE_EXPORTACAO",
	PisCofins_60CreditoPresumidoOperacaoDeAquisicaoVinculadaExclusivamenteAReceitaTributadaNoMercadoInterno = "PIS_COFINS_60_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_TRIBUTADA_NO_MERCADO_INTERNO",
	PisCofins_61CreditoPresumidoOperacaoDeAquisicaoVinculadaExclusivamenteAReceitaNaoTributadaNoMercadoInterno = "PIS_COFINS_61_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_NO_MERCADO_INTERNO",
	PisCofins_62CreditoPresumidoOperacaoDeAquisicaoVinculadaExclusivamenteAReceitaDeExportacao = "PIS_COFINS_62_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_DE_EXPORTACAO",
	PisCofins_63CreditoPresumidoOperacaoDeAquisicaoVinculadaAReceitasTributadasENaoTributadasNoMercadoInterno = "PIS_COFINS_63_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_A_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_NO_MERCADO_INTERNO",
	PisCofins_64CreditoPresumidoOperacaoDeAquisicaoVinculadaAReceitasTributadasNoMercadoInternoEDeExportacao = "PIS_COFINS_64_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_A_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_E_DE_EXPORTACAO",
	PisCofins_65CreditoPresumidoOperacaoDeAquisicaoVinculadaAReceitasNaoTributadasNoMercadoInternoEDeExportacao = "PIS_COFINS_65_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_A_RECEITAS_NAO_TRIBUTADAS_NO_MERCADO_INTERNO_E_DE_EXPORTACAO",
	PisCofins_66CreditoPresumidoOperacaoDeAquisicaoVinculadaAReceitasTributadasENaoTributadasNoMercadoInternoEDeExportacao = "PIS_COFINS_66_CREDITO_PRESUMIDO_OPERACAO_DE_AQUISICAO_VINCULADA_A_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_NO_MERCADO_INTERNO_E_DE_EXPORTACAO",
	PisCofins_67CreditoPresumidoOutrasOperacoes = "PIS_COFINS_67_CREDITO_PRESUMIDO_OUTRAS_OPERACOES",
	PisCofins_70OperacaoDeAquisicaoSemDireitoACredito = "PIS_COFINS_70_OPERACAO_DE_AQUISICAO_SEM_DIREITO_A_CREDITO",
	PisCofins_71OperacaoDeAquisicaoComIsencao = "PIS_COFINS_71_OPERACAO_DE_AQUISICAO_COM_ISENCAO",
	PisCofins_72OperacaoDeAquisicaoComSuspensao = "PIS_COFINS_72_OPERACAO_DE_AQUISICAO_COM_SUSPENSAO",
	PisCofins_73OperacaoDeAquisicaoAAliquotaZero = "PIS_COFINS_73_OPERACAO_DE_AQUISICAO_A_ALIQUOTA_ZERO",
	PisCofins_74OperacaoDeAquisicaoSemIncidenciaDaContribuicao = "PIS_COFINS_74_OPERACAO_DE_AQUISICAO_SEM_INCIDENCIA_DA_CONTRIBUICAO",
	PisCofins_75OperacaoDeAquisicaoPorSubstituicaoTributaria = "PIS_COFINS_75_OPERACAO_DE_AQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA",
	PisCofins_98OutrasOperacoesDeEntrada = "PIS_COFINS_98_OUTRAS_OPERACOES_DE_ENTRADA",
	PisCofins_99OutrasOperacoes = "PIS_COFINS_99_OUTRAS_OPERACOES",
}

export type Veiculo = {
	__typename?: "Veiculo";
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdEstado: Scalars["Int"];
	IdEstadoReboque?: Maybe<Scalars["Int"]>;
	IdTransportadora: Scalars["Int"];
	Placa: Scalars["String"];
	ReboquePlaca?: Maybe<Scalars["String"]>;
	ReboqueRntc?: Maybe<Scalars["String"]>;
	Rntc?: Maybe<Scalars["String"]>;
	Status: Scalars["Status"];
	UF: Scalars["String"];
};

export type Venda = {
	__typename?: "Venda";
	BairroCliente?: Maybe<Scalars["String"]>;
	CelularCliente?: Maybe<Scalars["String"]>;
	CepCliente?: Maybe<Scalars["String"]>;
	CidadeCliente?: Maybe<Scalars["String"]>;
	Codigo: Scalars["String"];
	ComplementoCliente?: Maybe<Scalars["String"]>;
	Conferido: Scalars["Resposta"];
	CpfCnpjCliente?: Maybe<Scalars["String"]>;
	DataAlteracao: Scalars["DateTime"];
	DataCadastro: Scalars["DateTime"];
	DataEntrega?: Maybe<Scalars["Date"]>;
	DataHora: Scalars["DateTime"];
	DescricaoStatus: Scalars["String"];
	EmailCliente?: Maybe<Scalars["String"]>;
	EstagioStatus: Estagio;
	FinanceiroGerado: Scalars["Resposta"];
	Frete: TipoFrete;
	HashLink: Scalars["String"];
	Id: Scalars["Int"];
	IdCliente?: Maybe<Scalars["Int"]>;
	IdEcommerce?: Maybe<Scalars["Int"]>;
	IdEnderecoEntrega?: Maybe<Scalars["Int"]>;
	IdIntermediador?: Maybe<Scalars["Int"]>;
	IdTransportadora?: Maybe<Scalars["Int"]>;
	IdUsuario: Scalars["Int"];
	IdVendedor?: Maybe<Scalars["Int"]>;
	InformacoesAdicionais?: Maybe<Scalars["String"]>;
	ItemVenda?: Maybe<Array<Maybe<ItemVenda>>>;
	LogradouroCliente?: Maybe<Scalars["String"]>;
	NomeCliente?: Maybe<Scalars["String"]>;
	NomeFantasiaCliente?: Maybe<Scalars["String"]>;
	NomeTransportadora?: Maybe<Scalars["String"]>;
	NomeVendedor?: Maybe<Scalars["String"]>;
	NumeroCliente?: Maybe<Scalars["String"]>;
	Observacoes?: Maybe<Scalars["String"]>;
	PrecoUsado: PrecoUsado;
	PrestacaoVenda?: Maybe<Array<Maybe<PrestacaoVenda>>>;
	QtdItens?: Maybe<Scalars["Float"]>;
	Status: Scalars["Int"];
	TelefoneCliente?: Maybe<Scalars["String"]>;
	Tipo: TipoVenda;
	UFCliente?: Maybe<Scalars["String"]>;
	ValorDesconto: Scalars["Float"];
	ValorFrete: Scalars["Float"];
	ValorJuro: Scalars["Float"];
	ValorSeguro: Scalars["Float"];
	ValorTotal: Scalars["Float"];
	VendaNfe?: Maybe<Array<Maybe<VendaNfe>>>;
};

export type VendaNfe = {
	__typename?: "VendaNfe";
	CodigoVenda: Scalars["String"];
	Id: Scalars["Int"];
	IdEmpresa: Scalars["Int"];
	IdNFe: Scalars["Int"];
	IdVenda: Scalars["Int"];
	NumeroNFe: Scalars["String"];
	ObservacoesVenda?: Maybe<Scalars["String"]>;
	SerieNFe: Scalars["String"];
	StatusNFe: StatusNfe;
	StatusVenda: Scalars["Int"];
};

export type VendaTotalPorFormaPgto = {
	__typename?: "VendaTotalPorFormaPgto";
	IdFormaPgto: Scalars["Int"];
	NomeFormaPgto: Scalars["String"];
	TotalVendas: Scalars["Float"];
	TotalVendido: Scalars["Float"];
};

export type VendasPorProduto = {
	__typename?: "VendasPorProduto";
	Agrupamento?: Maybe<Scalars["String"]>;
	ClassificacaoRanking?: Maybe<Scalars["Int"]>;
	Codigo?: Maybe<Scalars["String"]>;
	Itens: Array<RelatorioVendasPorProdutoItens>;
	QtdItensVendidos: Scalars["Float"];
	TicketMedio: Scalars["Float"];
	TotalCusto: Scalars["Float"];
	TotalDevolucoes: Scalars["Float"];
	TotalLucro: Scalars["Float"];
	TotalVendido: Scalars["Float"];
};

export type VendasPorTributacao = {
	__typename?: "VendasPorTributacao";
	DataEmissao?: Maybe<Scalars["Date"]>;
	Itens?: Maybe<Array<Maybe<VendasPorTributacaoProdutos>>>;
	QuantidadeTotal?: Maybe<Scalars["Float"]>;
	ValorTotal?: Maybe<Scalars["Float"]>;
};

export type VendasPorTributacaoProdutos = {
	__typename?: "VendasPorTributacaoProdutos";
	Codigo?: Maybe<Scalars["String"]>;
	Descricao?: Maybe<Scalars["String"]>;
	IdProduto?: Maybe<Scalars["Int"]>;
	Quantidade?: Maybe<Scalars["Float"]>;
	ValorTotal?: Maybe<Scalars["Float"]>;
};

export type VolumeNfe = {
	__typename?: "VolumeNfe";
	Especie?: Maybe<Scalars["String"]>;
	Id: Scalars["Int"];
	IdNFe: Scalars["Int"];
	Marca?: Maybe<Scalars["String"]>;
	Numeracao?: Maybe<Scalars["String"]>;
	PesoBruto: Scalars["Float"];
	PesoLiquido: Scalars["Float"];
	Quantidade: Scalars["Float"];
};

export type VolumeVenda = {
	__typename?: "VolumeVenda";
	Data: Scalars["Date"];
	QtdVenda: Scalars["Int"];
	ValorTotal: Scalars["Float"];
};

export type CadastraCategoriaClienteMutationVariables = Exact<{
	dados: InputCadastrarCategoriaCliente;
}>;

export type CadastraCategoriaClienteMutation = { __typename?: "Mutation"; cadastraCategoriaCliente: RetornoPadrao };

export type EditaCategoriaClienteMutationVariables = Exact<{
	dados: InputEditarCategoriaCliente;
}>;

export type EditaCategoriaClienteMutation = { __typename?: "Mutation"; editaCategoriaCliente: RetornoPadrao };

export type ToggleStatusCategoriaClienteMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusCategoriaClienteMutation = { __typename?: "Mutation"; editaCategoriaCliente: RetornoPadrao };

export type CategoriaClienteQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCategoriaCliente>;
}>;

export type CategoriaClienteQuery = { __typename?: "Query"; categoriaCliente: { __typename?: "CategoriaCliente"; Nome: string; Status: StatusEnum; Descricao?: string | null } };

export type CategoriasClienteQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCategoriasCliente>;
}>;

export type CategoriasClienteQuery = {
	__typename?: "Query";
	categoriasCliente: Array<{ __typename?: "CategoriaCliente"; Nome: string; Status: StatusEnum; IdEmpresa: number; Id: number; Descricao?: string | null; DataAlteracao: Date }>;
};

export type CategoriaClienteByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type CategoriaClienteByIdQuery = { __typename?: "Query"; categoriaCliente: { __typename?: "CategoriaCliente"; Nome: string; Status: StatusEnum; Descricao?: string | null } };

export type CategoriasClienteSelectQueryVariables = Exact<{
	IdCategoriaCliente?: InputMaybe<Scalars["Int"]>;
}>;

export type CategoriasClienteSelectQuery = {
	__typename?: "Query";
	categoriasCliente: Array<{ __typename?: "CategoriaCliente"; Nome: string; Status: StatusEnum; IdEmpresa: number; Id: number; Descricao?: string | null; DataAlteracao: Date }>;
};

export type CadastraGeneroMutationVariables = Exact<{
	dados: InputCadastrarGenero;
}>;

export type CadastraGeneroMutation = { __typename?: "Mutation"; cadastraGenero: RetornoPadrao };

export type EditaGeneroMutationVariables = Exact<{
	dados: InputEditarGenero;
}>;

export type EditaGeneroMutation = { __typename?: "Mutation"; editaGenero: RetornoPadrao };

export type ToggleStatusGeneroMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusGeneroMutation = { __typename?: "Mutation"; editaGenero: RetornoPadrao };

export type GeneroQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroGenero>;
}>;

export type GeneroQuery = { __typename?: "Query"; genero: { __typename?: "Genero"; Descricao: string; Id: number; Status: StatusEnum } };

export type GenerosQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroGeneros>;
}>;

export type GenerosQuery = { __typename?: "Query"; generos: Array<{ __typename?: "Genero"; Id: number; Descricao: string; IdEmpresa: number; Status: StatusEnum }> };

export type GeneroByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type GeneroByIdQuery = { __typename?: "Query"; genero: { __typename?: "Genero"; Descricao: string; Id: number; Status: StatusEnum } };

export type GenerosSelectQueryVariables = Exact<{
	IdGenero?: InputMaybe<Scalars["Int"]>;
}>;

export type GenerosSelectQuery = { __typename?: "Query"; generos: Array<{ __typename?: "Genero"; Id: number; Descricao: string; IdEmpresa: number; Status: StatusEnum }> };

export type CadastraClienteMutationVariables = Exact<{
	dados: InputCadastrarCliente;
}>;

export type CadastraClienteMutation = { __typename?: "Mutation"; cadastraCliente: RetornoPadrao };

export type CadastraClienteVinculaPessoaMutationVariables = Exact<{
	dados: InputCadastrarClientePessoa;
}>;

export type CadastraClienteVinculaPessoaMutation = { __typename?: "Mutation"; cadastraClienteVinculaPessoa: RetornoPadrao };

export type EditaClienteMutationVariables = Exact<{
	dados: InputEditarCliente;
}>;

export type EditaClienteMutation = { __typename?: "Mutation"; editaCliente: RetornoPadrao };

export type ToggleStatusClienteMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusClienteMutation = { __typename?: "Mutation"; editaCliente: RetornoPadrao };

export type ClienteQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCliente>;
}>;

export type ClienteQuery = {
	__typename?: "Query";
	cliente: {
		__typename?: "Cliente";
		Bairro?: string | null;
		Cargo?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Cep?: string | null;
		CidadeNome?: string | null;
		Codigo: string;
		Uf?: string | null;
		Complemento?: string | null;
		CpfCnpj?: string | null;
		DataAdmissao?: Date | null;
		DataAlteracao: Date;
		IdPessoa: number;
		DataCadastro: Date;
		DataNascimento?: Date | null;
		Email?: string | null;
		EnderecoComercial?: string | null;
		EstadoCivil?: EstadoCivil | null;
		FoneComercial?: string | null;
		Foto?: string | null;
		IdCategoria?: number | null;
		IdCidade?: number | null;
		IdEstado?: number | null;
		IdGenero?: number | null;
		IdPais?: number | null;
		IdVendedor?: number | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		LimiteCredito?: number | null;
		LocalTrabalho?: string | null;
		Logradouro?: string | null;
		Nome: string;
		NomeFantasia?: string | null;
		NumEndereco?: string | null;
		Observacao?: string | null;
		PermitirVenda?: RespostaEnum | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		RendaMensal?: number | null;
		RG?: string | null;
		SpcSerasa?: RespostaEnum | null;
		Status: StatusEnum;
		Telefone?: string | null;
		Contatos?: Array<{ __typename?: "ContatoPessoa"; Id: number; Contato: string; Descricao?: string | null; Tipo: TipoContato } | null> | null;
		ReferenciasComerciais?: Array<{ __typename?: "ReferenciaComercial"; Id: number; Contato?: string | null; Descricao: string; Telefone?: string | null } | null> | null;
	};
};

export type ClientesQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroClientes>;
}>;

export type ClientesQuery = {
	__typename?: "Query";
	clientes: {
		__typename?: "PaginaCliente";
		Data: Array<{
			__typename?: "Cliente";
			IdPessoa: number;
			Codigo: string;
			Nome: string;
			NomeFantasia?: string | null;
			IdPais?: number | null;
			IdCidade?: number | null;
			CpfCnpj?: string | null;
			Telefone?: string | null;
			Email?: string | null;
			Bairro?: string | null;
			CidadeNome?: string | null;
			Uf?: string | null;
			IdEstado?: number | null;
			Status: StatusEnum;
			RegimeTributarioICMS?: RegimeTributarioIcms | null;
			InscricaoEstadual?: string | null;
			InscricaoMunicipal?: string | null;
			RG?: string | null;
			NomeCategoria?: string | null;
			DescricaoGenero?: string | null;
			EstadoCivil?: EstadoCivil | null;
			RegimeTributario?: RegimeTributario | null;
			DataNascimento?: Date | null;
			DataCadastro: Date;
			DataAlteracao: Date;
			Observacao?: string | null;
			Cep?: string | null;
			Logradouro?: string | null;
			NumEndereco?: string | null;
			Complemento?: string | null;
			Celular?: string | null;
			Celular2?: string | null;
			LocalTrabalho?: string | null;
			FoneComercial?: string | null;
			EnderecoComercial?: string | null;
			Cargo?: string | null;
			RendaMensal?: number | null;
			DataAdmissao?: Date | null;
			LimiteCredito?: number | null;
			SpcSerasa?: RespostaEnum | null;
			PermitirVenda?: RespostaEnum | null;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type ClientesVendaQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroClientes>;
}>;

export type ClientesVendaQuery = {
	__typename?: "Query";
	clientes: {
		__typename?: "PaginaCliente";
		Data: Array<{
			__typename?: "Cliente";
			IdPessoa: number;
			Codigo: string;
			Nome: string;
			NomeFantasia?: string | null;
			IdPais?: number | null;
			IdCidade?: number | null;
			CpfCnpj?: string | null;
			Telefone?: string | null;
			Email?: string | null;
			Bairro?: string | null;
			CidadeNome?: string | null;
			Uf?: string | null;
			IdEstado?: number | null;
			Status: StatusEnum;
			RegimeTributarioICMS?: RegimeTributarioIcms | null;
			InscricaoEstadual?: string | null;
			InscricaoMunicipal?: string | null;
			RG?: string | null;
			NomeCategoria?: string | null;
			DescricaoGenero?: string | null;
			EstadoCivil?: EstadoCivil | null;
			RegimeTributario?: RegimeTributario | null;
			DataNascimento?: Date | null;
			DataCadastro: Date;
			DataAlteracao: Date;
			Observacao?: string | null;
			Cep?: string | null;
			Logradouro?: string | null;
			NumEndereco?: string | null;
			Complemento?: string | null;
			Celular?: string | null;
			Celular2?: string | null;
			LocalTrabalho?: string | null;
			FoneComercial?: string | null;
			EnderecoComercial?: string | null;
			Cargo?: string | null;
			RendaMensal?: number | null;
			DataAdmissao?: Date | null;
			LimiteCredito?: number | null;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type CodigoProxClienteQueryVariables = Exact<{ [key: string]: never }>;

export type CodigoProxClienteQuery = { __typename?: "Query"; codigoProximoCliente: number };

export type CadastraDepartamentoMutationVariables = Exact<{
	dados: InputCadastrarDepartamento;
}>;

export type CadastraDepartamentoMutation = { __typename?: "Mutation"; cadastraDepartamento: RetornoPadrao };

export type EditaDepartamentoMutationVariables = Exact<{
	dados: InputEditarDepartamento;
}>;

export type EditaDepartamentoMutation = { __typename?: "Mutation"; editaDepartamento: RetornoPadrao };

export type ToggleStatusDepartamentoMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusDepartamentoMutation = { __typename?: "Mutation"; editaDepartamento: RetornoPadrao };

export type DepartamentoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroDepartamento>;
}>;

export type DepartamentoQuery = {
	__typename?: "Query";
	departamento: { __typename?: "Departamento"; Id: number; Nome: string; Producao: StatusEnum; Vendas: StatusEnum; Tecnico: StatusEnum; Status: StatusEnum };
};

export type DepartamentosQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroDepartamentos>;
}>;

export type DepartamentosQuery = {
	__typename?: "Query";
	departamentos: Array<{ __typename?: "Departamento"; Id: number; Nome: string; Producao: StatusEnum; Vendas: StatusEnum; Tecnico: StatusEnum; Status: StatusEnum }>;
};

export type DepartamentoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type DepartamentoByIdQuery = {
	__typename?: "Query";
	departamento: { __typename?: "Departamento"; Id: number; Nome: string; Producao: StatusEnum; Vendas: StatusEnum; Tecnico: StatusEnum; Status: StatusEnum };
};

export type CadastraColaboradorMutationVariables = Exact<{
	dados: InputCadastrarColaborador;
}>;

export type CadastraColaboradorMutation = { __typename?: "Mutation"; cadastraColaborador: RetornoPadrao };

export type RemoverUsuarioMutationVariables = Exact<{
	dados: InputRemoverUsuario;
}>;

export type RemoverUsuarioMutation = { __typename?: "Mutation"; removerUsuario: RetornoPadrao };

export type EditaColaboradorMutationVariables = Exact<{
	dados: InputEditarColaborador;
}>;

export type EditaColaboradorMutation = { __typename?: "Mutation"; editaColaborador: RetornoPadrao };

export type CadastraColaboradorVinculaPessoaMutationVariables = Exact<{
	dados: InputCadastrarColaboradorPessoa;
}>;

export type CadastraColaboradorVinculaPessoaMutation = { __typename?: "Mutation"; cadastraColaboradorVinculaPessoa: RetornoPadrao };

export type RemoverAcessoColaboradorEmpresaMutationVariables = Exact<{
	dados: InputRemoverAcessoColaboradorEmpresa;
}>;

export type RemoverAcessoColaboradorEmpresaMutation = { __typename?: "Mutation"; removerAcessoColaboradorEmpresa: RetornoPadrao };

export type FornecerAcessoColaboradorEmpresaMutationVariables = Exact<{
	dados: InputFornecerAcessoColaboradorEmpresa;
}>;

export type FornecerAcessoColaboradorEmpresaMutation = { __typename?: "Mutation"; fornecerAcessoColaboradorEmpresa: RetornoPadrao };

export type ColaboradorQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroColaborador>;
}>;

export type ColaboradorQuery = {
	__typename?: "Query";
	colaborador: {
		__typename?: "Colaborador";
		IdPessoa: number;
		Bairro?: string | null;
		Cargo?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Cep?: string | null;
		RG?: string | null;
		Foto?: string | null;
		Observacao?: string | null;
		IdPais?: number | null;
		IdEstado?: number | null;
		IdCidade?: number | null;
		Logradouro?: string | null;
		Uf?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		IdDepartamento?: number | null;
		Pis?: string | null;
		DataAdmissao?: Date | null;
		DataDemissao?: Date | null;
		PorcentComis?: number | null;
		PorcentDescMax?: number | null;
		Status: StatusEnum;
		Codigo: string;
		CpfCnpj?: string | null;
		DataNascimento?: Date | null;
		Email?: string | null;
		Login?: string | null;
		Nome: string;
		Permissoes?: Array<{ __typename?: "PermissaoColaborador"; Status: number; NomePermissao: string; IdPermissao: number; OpcoesPermissaoLista: Array<string> } | null> | null;
	};
};

export type ColaboradoresQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroColaboradores>;
}>;

export type ColaboradoresQuery = {
	__typename?: "Query";
	colaboradores: Array<{
		__typename?: "Colaborador";
		Bairro?: string | null;
		Cargo?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Cep?: string | null;
		CidadeNome?: string | null;
		Codigo: string;
		Complemento?: string | null;
		CpfCnpj?: string | null;
		DataAdmissao?: Date | null;
		DataDemissao?: Date | null;
		DataNascimento?: Date | null;
		Email?: string | null;
		Foto?: string | null;
		IdCidade?: number | null;
		IdDepartamento?: number | null;
		IdEmpresa: number;
		IdEstado?: number | null;
		IdPais?: number | null;
		IdPessoa: number;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Login?: string | null;
		Senha?: string | null;
		Pis?: string | null;
		PorcentComis?: number | null;
		PorcentDescMax?: number | null;
		NivelFuncao: number;
		PerfilAcesso: PerfilAcessoColaborador;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Telefone?: string | null;
		RG?: string | null;
		Observacao?: string | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		NomeDepartamento?: string | null;
		VendasDepartamento?: StatusEnum | null;
		ProducaoDepartamento?: StatusEnum | null;
		TecnicoDepartamento?: StatusEnum | null;
		StatusDepartamento?: StatusEnum | null;
		Uf?: string | null;
	}>;
};

export type CodigoProxColaboradorQueryVariables = Exact<{ [key: string]: never }>;

export type CodigoProxColaboradorQuery = { __typename?: "Query"; codigoProximoColaborador: number };

export type ListaPermissoesQueryVariables = Exact<{ [key: string]: never }>;

export type ListaPermissoesQuery = { __typename?: "Query"; listaPermissoesColaborador: RetornoPermissao };

export type VendedoresQueryVariables = Exact<{
	IdVendedor?: InputMaybe<Scalars["Int"]>;
	Codigo?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type VendedoresQuery = {
	__typename?: "Query";
	colaboradores: Array<{ __typename?: "Colaborador"; IdPessoa: number; Codigo: string; Nome: string; NomeFantasia?: string | null; CpfCnpj?: string | null }>;
};

export type AcessosEmpresasColaboradorQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroColaboradorEmpresa>;
}>;

export type AcessosEmpresasColaboradorQuery = {
	__typename?: "Query";
	acessosEmpresasColaborador: Array<{
		__typename?: "ColaboradorEmpresa";
		Id: number;
		IdEmpresa: number;
		IdColaborador: number;
		Nome: string;
		NomeFantasia?: string | null;
		NomeFilial?: string | null;
		CpfCnpj: string;
		NomeColaborador: string;
		NomeFantasiaColaborador?: string | null;
		CpfCnpjColaborador?: string | null;
		LoginColaborador: string;
		IdEmpresaColaborador: number;
	}>;
};

export type CadastraContadorMutationVariables = Exact<{
	dados: InputCadastrarContador;
}>;

export type CadastraContadorMutation = { __typename?: "Mutation"; cadastraContador: RetornoPadrao };

export type EditaContadorMutationVariables = Exact<{
	dados: InputEditarContador;
}>;

export type EditaContadorMutation = { __typename?: "Mutation"; editaContador: RetornoPadrao };

export type ToggleStatusContadorMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusContadorMutation = { __typename?: "Mutation"; editaContador: RetornoPadrao };

export type ContadorQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroContador>;
}>;

export type ContadorQuery = {
	__typename?: "Query";
	contador: {
		__typename?: "Contador";
		IdPessoa: number;
		Crc?: string | null;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		Cpf?: string | null;
		IdPais?: number | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		Uf?: string | null;
		IdEstado?: number | null;
	};
};

export type CadastraFornecedorMutationVariables = Exact<{
	dados: InputCadastrarFornecedor;
}>;

export type CadastraFornecedorMutation = { __typename?: "Mutation"; cadastraFornecedor: RetornoPadrao };

export type EditaFornecedorMutationVariables = Exact<{
	dados: InputEditarFornecedor;
}>;

export type EditaFornecedorMutation = { __typename?: "Mutation"; editaFornecedor: RetornoPadrao };

export type ToggleStatusFornecedorMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusFornecedorMutation = { __typename?: "Mutation"; editaFornecedor: RetornoPadrao };

export type CadastraFornecedorVinculaPessoaMutationVariables = Exact<{
	dados: InputCadastrarFornecedorPessoa;
}>;

export type CadastraFornecedorVinculaPessoaMutation = { __typename?: "Mutation"; cadastraFornecedorVinculaPessoa: RetornoPadrao };

export type FornecedorQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroFornecedor>;
}>;

export type FornecedorQuery = {
	__typename?: "Query";
	fornecedor: {
		__typename?: "Fornecedor";
		IdPessoa: number;
		Codigo: string;
		Site?: string | null;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdPais?: number | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		DataCadastro: Date;
		DataAlteracao: Date;
	};
};

export type FornecedoresQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroFornecedores>;
}>;

export type FornecedoresQuery = {
	__typename?: "Query";
	fornecedores: Array<{
		__typename?: "Fornecedor";
		IdPessoa: number;
		Codigo: string;
		Site?: string | null;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdPais?: number | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		DataCadastro: Date;
		DataAlteracao: Date;
	}>;
};

export type FornecedorByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type FornecedorByIdQuery = {
	__typename?: "Query";
	fornecedor: {
		__typename?: "Fornecedor";
		IdPessoa: number;
		Codigo: string;
		Site?: string | null;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdPais?: number | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		DataCadastro: Date;
		DataAlteracao: Date;
	};
};

export type CodProxFornecedorQueryVariables = Exact<{ [key: string]: never }>;

export type CodProxFornecedorQuery = { __typename?: "Query"; codigoProximoFornecedor: number };

export type PessoaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroPessoa>;
}>;

export type PessoaQuery = {
	__typename?: "Query";
	pessoa: {
		__typename?: "Pessoa";
		Id: number;
		IdEmpresa: number;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdPais?: number | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		Status: StatusEnum;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		IdCliente?: number | null;
		IdColaborador?: number | null;
		IdContador?: number | null;
		IdFornecedor?: number | null;
		IdTransportadora?: number | null;
	};
};

export type PessoasQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroPessoas>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type PessoasQuery = {
	__typename?: "Query";
	pessoas: {
		__typename?: "PaginaPessoa";
		Data: Array<{
			__typename?: "Pessoa";
			Id: number;
			IdEmpresa: number;
			Nome: string;
			NomeFantasia?: string | null;
			CpfCnpj?: string | null;
			IdPais?: number | null;
			IdCidade?: number | null;
			Cep?: string | null;
			Bairro?: string | null;
			Logradouro?: string | null;
			NumEndereco?: string | null;
			Complemento?: string | null;
			Telefone?: string | null;
			Celular?: string | null;
			Celular2?: string | null;
			Email?: string | null;
			RG?: string | null;
			InscricaoEstadual?: string | null;
			InscricaoMunicipal?: string | null;
			Observacao?: string | null;
			DataNascimento?: Date | null;
			DataCadastro: Date;
			DataAlteracao: Date;
			Status: StatusEnum;
			RegimeTributario?: RegimeTributario | null;
			RegimeTributarioICMS?: RegimeTributarioIcms | null;
			CidadeNome?: string | null;
			Uf?: string | null;
			IdEstado?: number | null;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type CadastraTransportadoraMutationVariables = Exact<{
	dados: InputCadastrarTransportadora;
}>;

export type CadastraTransportadoraMutation = { __typename?: "Mutation"; cadastraTransportadora: RetornoPadrao };

export type EditaTransportadoraMutationVariables = Exact<{
	dados: InputEditarTransportadora;
}>;

export type EditaTransportadoraMutation = { __typename?: "Mutation"; editaTransportadora: RetornoPadrao };

export type ToggleStatusTransportadoraMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusTransportadoraMutation = { __typename?: "Mutation"; editaTransportadora: RetornoPadrao };

export type TransportadoraQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroTransportadora>;
}>;

export type TransportadoraQuery = {
	__typename?: "Query";
	transportadora: {
		__typename?: "Transportadora";
		IdPessoa: number;
		IdEmpresa: number;
		Codigo: string;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdCidade?: number | null;
		IdPais?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		Veiculos?: Array<{
			__typename?: "Veiculo";
			Id: number;
			IdTransportadora: number;
			IdEstado: number;
			IdEstadoReboque?: number | null;
			Placa: string;
			Rntc?: string | null;
			ReboquePlaca?: string | null;
			ReboqueRntc?: string | null;
			Status: StatusEnum;
			UF: string;
		}> | null;
	};
};

export type TransportadorasQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroTransportadoras>;
}>;

export type TransportadorasQuery = {
	__typename?: "Query";
	transportadoras: Array<{
		__typename?: "Transportadora";
		IdPessoa: number;
		Codigo: string;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		IdPais?: number | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		Veiculos?: Array<{
			__typename?: "Veiculo";
			Id: number;
			IdTransportadora: number;
			IdEstado: number;
			IdEstadoReboque?: number | null;
			Placa: string;
			Rntc?: string | null;
			ReboquePlaca?: string | null;
			ReboqueRntc?: string | null;
			Status: StatusEnum;
			UF: string;
		}> | null;
	}>;
};

export type TransportadoraByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type TransportadoraByIdQuery = {
	__typename?: "Query";
	transportadora: {
		__typename?: "Transportadora";
		IdPessoa: number;
		IdEmpresa: number;
		Codigo: string;
		Status: StatusEnum;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj?: string | null;
		IdCidade?: number | null;
		Cep?: string | null;
		Bairro?: string | null;
		Logradouro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		Email?: string | null;
		RG?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		Observacao?: string | null;
		DataNascimento?: Date | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		IdEstado?: number | null;
		Veiculos?: Array<{
			__typename?: "Veiculo";
			Id: number;
			IdTransportadora: number;
			IdEstado: number;
			IdEstadoReboque?: number | null;
			Placa: string;
			Rntc?: string | null;
			ReboquePlaca?: string | null;
			ReboqueRntc?: string | null;
			Status: StatusEnum;
			UF: string;
		}> | null;
	};
};

export type CodProxTransportadoraQueryVariables = Exact<{ [key: string]: never }>;

export type CodProxTransportadoraQuery = { __typename?: "Query"; codigoProximaTransportadora: number };

export type VeiculosQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroVeiculos>;
}>;

export type VeiculosQuery = {
	__typename?: "Query";
	veiculos: Array<{
		__typename?: "Veiculo";
		Id: number;
		IdTransportadora: number;
		IdEstado: number;
		IdEstadoReboque?: number | null;
		Placa: string;
		Rntc?: string | null;
		ReboquePlaca?: string | null;
		ReboqueRntc?: string | null;
		Status: StatusEnum;
		UF: string;
	}>;
};

export type VisualizarNotificacaoMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type VisualizarNotificacaoMutation = { __typename?: "Mutation"; visualizarNotificacao: RetornoPadrao };

export type VisualizarTodasNotificacoesMutationVariables = Exact<{ [key: string]: never }>;

export type VisualizarTodasNotificacoesMutation = { __typename?: "Mutation"; visualizarTodasNotificacoes: RetornoPadrao };

export type RespostaAcessoMutationVariables = Exact<{
	dados: InputRespostaAcesso;
}>;

export type RespostaAcessoMutation = { __typename?: "Mutation"; respostaAcesso: RetornoPadrao };

export type CancelarAcessoMutationVariables = Exact<{
	dados: InputRequisicaoAcesso;
}>;

export type CancelarAcessoMutation = { __typename?: "Mutation"; cancelarAcesso: RetornoPadrao };

export type RequisicaoAcessoMutationVariables = Exact<{
	dados: InputRequisicaoAcesso;
}>;

export type RequisicaoAcessoMutation = { __typename?: "Mutation"; requisicaoAcesso: RetornoTarefa };

export type RemoverAcessoMutationVariables = Exact<{
	correlationId: Scalars["String"];
}>;

export type RemoverAcessoMutation = { __typename?: "Mutation"; removerAcesso: RetornoTarefa };

export type AtualizarAcessoMutationVariables = Exact<{
	correlationId: Scalars["String"];
}>;

export type AtualizarAcessoMutation = { __typename?: "Mutation"; atualizarAcesso: RetornoTarefa };

export type NotificacoesQueryVariables = Exact<{ [key: string]: never }>;

export type NotificacoesQuery = {
	__typename?: "Query";
	notificacoes: Array<{
		__typename?: "Notificacao";
		Id: number;
		TipoNotificacao: TipoNotificacao;
		LocalExibicao: LocalExibicao;
		Titulo: string;
		Conteudo: string;
		OutrasInformacoes?: string | null;
		DataCriacao: Date;
		Status: StatusNotificacao;
	}>;
};

export type AcessoChamadoTomTicketQueryVariables = Exact<{ [key: string]: never }>;

export type AcessoChamadoTomTicketQuery = { __typename?: "Query"; acessoChamadoTomTicket: string };

export type AcessoChatTomTicketQueryVariables = Exact<{ [key: string]: never }>;

export type AcessoChatTomTicketQuery = { __typename?: "Query"; acessoChatTomTicket: string };

export type ConfigEmpresaSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ConfigEmpresaSubscription = {
	__typename?: "Subscription";
	configuracaoEmpresaAtualizada: Array<{ __typename?: "ConfiguracaoEmpresa"; Id?: number | null; IdConfig: Configuracoes; Nome: string; NomeLegivel: string; Valor?: string | null }>;
};

export type NotificacaoSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NotificacaoSubscription = {
	__typename?: "Subscription";
	notificacao: {
		__typename?: "Notificacao";
		Id: number;
		TipoNotificacao: TipoNotificacao;
		LocalExibicao: LocalExibicao;
		Titulo: string;
		Conteudo: string;
		OutrasInformacoes?: string | null;
		DataCriacao: Date;
		Status: StatusNotificacao;
	};
};

export type AtualizaCacheSubscriptionVariables = Exact<{ [key: string]: never }>;

export type AtualizaCacheSubscription = { __typename?: "Subscription"; atualizaCache: { __typename?: "AtualizaCache"; FieldNames: Array<string> } };

export type AtualizaCacheSuporteSubscriptionVariables = Exact<{
	idEmpresa: Scalars["Int"];
}>;

export type AtualizaCacheSuporteSubscription = { __typename?: "Subscription"; atualizaCacheSuporte: { __typename?: "AtualizaCache"; FieldNames: Array<string> } };

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation"; logout?: RetornoPadrao | null };

export type EmpresasColaboradorQueryVariables = Exact<{ [key: string]: never }>;

export type EmpresasColaboradorQuery = {
	__typename?: "Query";
	empresasColaborador: Array<{ __typename?: "ColaboradorEmpresa"; Id: number; IdEmpresa: number; Nome: string; NomeFantasia?: string | null; NomeFilial?: string | null; CpfCnpj: string }>;
};

export type CadastraCategoriaMutationVariables = Exact<{
	dados: InputCadastrarCategoria;
}>;

export type CadastraCategoriaMutation = { __typename?: "Mutation"; cadastraCategoria: RetornoPadrao };

export type EditaCategoriaMutationVariables = Exact<{
	dados: InputEditarCategoria;
}>;

export type EditaCategoriaMutation = { __typename?: "Mutation"; editaCategoria: RetornoPadrao };

export type ToggleStatusCategoriaMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusCategoriaMutation = { __typename?: "Mutation"; editaCategoria: RetornoPadrao };

export type CategoriaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCategoria>;
}>;

export type CategoriaQuery = { __typename?: "Query"; categoria: { __typename?: "Categoria"; Nome: string; Descricao?: string | null; Id: number; Status: StatusEnum } };

export type CategoriasQueryVariables = Exact<{ [key: string]: never }>;

export type CategoriasQuery = { __typename?: "Query"; categorias: Array<{ __typename?: "Categoria"; Id: number; Nome: string; Status: StatusEnum; Descricao?: string | null }> };

export type CategoriaByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type CategoriaByIdQuery = { __typename?: "Query"; categoria: { __typename?: "Categoria"; Nome: string; Descricao?: string | null; Id: number; Status: StatusEnum } };

export type CategoriasSelectQueryVariables = Exact<{
	IdCategoria?: InputMaybe<Scalars["Int"]>;
	IdSubcategoria?: InputMaybe<Scalars["Int"]>;
}>;

export type CategoriasSelectQuery = {
	__typename?: "Query";
	categorias: Array<{
		__typename?: "Categoria";
		Id: number;
		Nome: string;
		Status: StatusEnum;
		Subcategorias?: Array<{ __typename?: "Subcategoria"; Id: number; Nome: string; Status: StatusEnum }> | null;
	}>;
};

export type CadastraColecaoMutationVariables = Exact<{
	dados: InputCadastrarColecao;
}>;

export type CadastraColecaoMutation = { __typename?: "Mutation"; cadastraColecao: RetornoPadrao };

export type EditaColecaoMutationVariables = Exact<{
	dados: InputEditarColecao;
}>;

export type EditaColecaoMutation = { __typename?: "Mutation"; editaColecao: RetornoPadrao };

export type ToggleStatusColecaoMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusColecaoMutation = { __typename?: "Mutation"; editaColecao: RetornoPadrao };

export type ColecaoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroColecao>;
}>;

export type ColecaoQuery = { __typename?: "Query"; colecao: { __typename?: "Colecao"; Nome: string; Id: number; Descricao?: string | null; Status: StatusEnum } };

export type ColecoesQueryVariables = Exact<{ [key: string]: never }>;

export type ColecoesQuery = { __typename?: "Query"; colecoes: Array<{ __typename?: "Colecao"; Id: number; Nome: string; Status: StatusEnum; Descricao?: string | null }> };

export type ColecaoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type ColecaoByIdQuery = { __typename?: "Query"; colecao: { __typename?: "Colecao"; Nome: string; Descricao?: string | null; Id: number; Status: StatusEnum } };

export type ColecoesSelectQueryVariables = Exact<{
	IdColecao?: InputMaybe<Scalars["Int"]>;
}>;

export type ColecoesSelectQuery = { __typename?: "Query"; colecoes: Array<{ __typename?: "Colecao"; Id: number; Nome: string; Status: StatusEnum }> };

export type CadastraDefinicaoMutationVariables = Exact<{
	dados: InputCadastrarDefinicao;
}>;

export type CadastraDefinicaoMutation = { __typename?: "Mutation"; cadastraDefinicao: RetornoPadrao };

export type EditaDefinicaoMutationVariables = Exact<{
	dados: InputEditarDefinicao;
}>;

export type EditaDefinicaoMutation = { __typename?: "Mutation"; editaDefinicao: RetornoPadrao };

export type ToggleStatusDefinicaoMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusDefinicaoMutation = { __typename?: "Mutation"; editaDefinicao: RetornoPadrao };

export type DefinicaoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroDefinicao>;
}>;

export type DefinicaoQuery = {
	__typename?: "Query";
	definicao: {
		__typename?: "Definicao";
		Id: number;
		Nome: string;
		Descricao?: string | null;
		Venda: StatusEnum;
		MateriaPrima: StatusEnum;
		UsoConsumo: StatusEnum;
		Producao: StatusEnum;
		Servico: StatusEnum;
		Status: StatusEnum;
	};
};

export type DefinicoesQueryVariables = Exact<{ [key: string]: never }>;

export type DefinicoesQuery = {
	__typename?: "Query";
	definicoes: Array<{
		__typename?: "Definicao";
		Descricao?: string | null;
		Id: number;
		Nome: string;
		MateriaPrima: StatusEnum;
		Producao: StatusEnum;
		Servico: StatusEnum;
		UsoConsumo: StatusEnum;
		Venda: StatusEnum;
		Status: StatusEnum;
	}>;
};

export type DefinicaoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type DefinicaoByIdQuery = {
	__typename?: "Query";
	definicao: {
		__typename?: "Definicao";
		Id: number;
		Nome: string;
		Descricao?: string | null;
		Venda: StatusEnum;
		MateriaPrima: StatusEnum;
		UsoConsumo: StatusEnum;
		Producao: StatusEnum;
		Servico: StatusEnum;
		Status: StatusEnum;
	};
};

export type DefinicoesSelectQueryVariables = Exact<{
	IdDefinicao?: InputMaybe<Scalars["Int"]>;
}>;

export type DefinicoesSelectQuery = { __typename?: "Query"; definicoes: Array<{ __typename?: "Definicao"; Id: number; Nome: string; Status: StatusEnum }> };

export type ToggleStatusModeloGradeMutationVariables = Exact<{
	Id: Scalars["Int"];
	Status: Scalars["Status"];
}>;

export type ToggleStatusModeloGradeMutation = { __typename?: "Mutation"; editaGradeTipo: RetornoPadrao };

export type CadastraModeloGradeMutationVariables = Exact<{
	dados: InputCadastrarGradeTipo;
}>;

export type CadastraModeloGradeMutation = { __typename?: "Mutation"; cadastraGradeTipo: RetornoPadrao };

export type EditaModeloGradeMutationVariables = Exact<{
	dados: InputEditarGradeTipo;
}>;

export type EditaModeloGradeMutation = { __typename?: "Mutation"; editaGradeTipo: RetornoPadrao };

export type ModelosGradeQueryVariables = Exact<{ [key: string]: never }>;

export type ModelosGradeQuery = {
	__typename?: "Query";
	gradesTipos: Array<{ __typename?: "GradeTipo"; Id: number; NomeGrade: string; NomeLinha?: string | null; NomeColuna?: string | null; Tipo: TipoGrade; Status: StatusEnum }>;
};

export type ModeloGradeQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type ModeloGradeQuery = {
	__typename?: "Query";
	gradeTipo: { __typename?: "GradeTipo"; Id: number; NomeGrade: string; NomeLinha?: string | null; NomeColuna?: string | null; Tipo: TipoGrade; Status: StatusEnum };
};

export type ModeloGradeByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type ModeloGradeByIdQuery = {
	__typename?: "Query";
	gradeTipo: {
		__typename?: "GradeTipo";
		Id: number;
		IdEmpresa: number;
		IdProduto?: number | null;
		NomeGrade: string;
		NomeLinha?: string | null;
		NomeColuna?: string | null;
		Tipo: TipoGrade;
		Status: StatusEnum;
		DataAlteracao: Date;
		GradeEixo: Array<{
			__typename?: "GradeEixo";
			Id: number;
			IdColuna?: number | null;
			ColunaNome?: string | null;
			ColunaCodigo?: number | null;
			IdLinha: number;
			LinhaCodigo: number;
			LinhaNome: string;
		}>;
	};
};

export type ModelosGradeSelectQueryVariables = Exact<{
	IdProduto?: InputMaybe<Scalars["Int"]>;
}>;

export type ModelosGradeSelectQuery = {
	__typename?: "Query";
	gradesTipos: Array<{
		__typename?: "GradeTipo";
		Id: number;
		NomeColuna?: string | null;
		NomeGrade: string;
		NomeLinha?: string | null;
		IdProduto?: number | null;
		Status: StatusEnum;
		GradeEixo: Array<{
			__typename?: "GradeEixo";
			Id: number;
			IdLinha: number;
			LinhaNome: string;
			LinhaCodigo: number;
			IdColuna?: number | null;
			ColunaNome?: string | null;
			ColunaCodigo?: number | null;
			GradeTipoNome: string;
			GradeTipoNomeColuna?: string | null;
			GradeTipoNomeLinha: string;
		}>;
	}>;
};

export type ToggleStatusGradeCampoMutationVariables = Exact<{
	id: Scalars["Int"];
	status?: InputMaybe<Scalars["Status"]>;
}>;

export type ToggleStatusGradeCampoMutation = { __typename?: "Mutation"; editaGradeCampo: RetornoPadrao };

export type CadastraGradeCampoMutationVariables = Exact<{
	dados: InputCadastrarGradeCampo;
}>;

export type CadastraGradeCampoMutation = { __typename?: "Mutation"; cadastraGradeCampo: RetornoPadrao };

export type EditarGradeCampoMutationVariables = Exact<{
	dados: InputEditarGradeCampo;
}>;

export type EditarGradeCampoMutation = { __typename?: "Mutation"; editaGradeCampo: RetornoPadrao };

export type GradesCamposQueryVariables = Exact<{
	tipo?: InputMaybe<TipoGradeCampo>;
	status?: InputMaybe<Scalars["Status"]>;
}>;

export type GradesCamposQuery = { __typename?: "Query"; gradesCampos: Array<{ __typename?: "GradeCampo"; Id: number; Nome: string; Codigo: number; Tipo: TipoGradeCampo; Status: StatusEnum }> };

export type GradeCampoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type GradeCampoByIdQuery = { __typename?: "Query"; gradeCampo: { __typename?: "GradeCampo"; Id: number; Nome: string; Codigo: number; Tipo: TipoGradeCampo; Status: StatusEnum } };

export type InventarioEstoqueQueryVariables = Exact<{
	filtro: InputFiltroInventario;
}>;

export type InventarioEstoqueQuery = { __typename?: "Query"; inventarioEstoque?: InventarioEstoque | null };

export type SintegraEstoqueSubscriptionVariables = Exact<{
	filtro: InputFiltroSintegra;
}>;

export type SintegraEstoqueSubscription = { __typename?: "Subscription"; sintegraEstoque?: RetornoTarefa | null };

export type CadastraMarcaMutationVariables = Exact<{
	dados: InputCadastrarMarca;
}>;

export type CadastraMarcaMutation = { __typename?: "Mutation"; cadastraMarca: RetornoPadrao };

export type EditaMarcaMutationVariables = Exact<{
	dados: InputEditarMarca;
}>;

export type EditaMarcaMutation = { __typename?: "Mutation"; editaMarca: RetornoPadrao };

export type ToggleStatusMarcaMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusMarcaMutation = { __typename?: "Mutation"; editaMarca: RetornoPadrao };

export type MarcaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroMarca>;
}>;

export type MarcaQuery = { __typename?: "Query"; marca: { __typename?: "Marca"; Id: number; Nome: string; Status: StatusEnum; Descricao?: string | null } };

export type MarcasQueryVariables = Exact<{ [key: string]: never }>;

export type MarcasQuery = { __typename?: "Query"; marcas: Array<{ __typename?: "Marca"; Id: number; Nome: string; Status: StatusEnum; Descricao?: string | null }> };

export type MarcaByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type MarcaByIdQuery = { __typename?: "Query"; marca: { __typename?: "Marca"; Nome: string; Descricao?: string | null; Id: number; Status: StatusEnum } };

export type MarcasSelectQueryVariables = Exact<{
	IdMarca?: InputMaybe<Scalars["Int"]>;
}>;

export type MarcasSelectQuery = { __typename?: "Query"; marcas: Array<{ __typename?: "Marca"; Id: number; Nome: string; Status: StatusEnum }> };

export type CadastraProdutoMutationVariables = Exact<{
	dados: InputCadastrarProduto;
}>;

export type CadastraProdutoMutation = { __typename?: "Mutation"; cadastraProduto: RetornoPadrao };

export type EditaProdutoMutationVariables = Exact<{
	dados: InputEditarProduto;
}>;

export type EditaProdutoMutation = { __typename?: "Mutation"; editaProduto: RetornoPadrao };

export type ToggleStatusProdutoMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusProdutoMutation = { __typename?: "Mutation"; editaProduto: RetornoPadrao };

export type CadastraMovimentEstoqueMutationVariables = Exact<{
	dados: InputCadastrarMovimentoEstoque;
}>;

export type CadastraMovimentEstoqueMutation = { __typename?: "Mutation"; cadastraMovimentoEstoque: RetornoPadrao };

export type ProdutosQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroProdutos>;
}>;

export type ProdutosQuery = {
	__typename?: "Query";
	produtos: {
		__typename?: "PaginaProduto";
		Data: Array<{
			__typename?: "Produto";
			Id: number;
			Codigo: string;
			Descricao: string;
			CodBarras?: string | null;
			CodigoSecundario?: string | null;
			Quantidade: number;
			UnidadeSimbolo?: string | null;
			ValorVenda: number;
			ValorVendaAlt: number;
			ValorCusto?: number | null;
			CustoMedio?: number | null;
			CategoriaNome?: string | null;
			SubcategoriaNome?: string | null;
			MarcaNome?: string | null;
			ColecaoNome?: string | null;
			DefinicaoNome?: string | null;
			SituaTributCodDescricao?: string | null;
			NcmCodigo?: string | null;
			DataCadastro: Date;
			FatorConversao?: number | null;
			DataAlteracao: Date;
			Status: StatusEnum;
			Foto?: string | null;
			GradePreco: GradePreco;
			IdCategoria?: number | null;
			IdSubcategoria?: number | null;
			IdMarca?: number | null;
			IdColecao?: number | null;
			IdDefinicao: number;
			IdNcm?: number | null;
			IdSituaTribut?: number | null;
			IdUnidade: number;
			IdUnidadeCompra?: number | null;
			IdGradeTipo?: number | null;
			MargemLucro?: number | null;
		}>;
		Paginacao: {
			__typename?: "PaginacaoProdutos";
			TotalRegistros: number;
			QtdTotalEstoque: number;
			TotalValorCusto: number;
			TotalCustoMedio: number;
			TotalPrecoVenda1: number;
			TotalPrecoVenda2: number;
		};
	};
};

export type ProdutoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type ProdutoByIdQuery = {
	__typename?: "Query";
	produto: {
		__typename?: "Produto";
		Id: number;
		IdMarca?: number | null;
		IdEmpresa: number;
		IdUnidade: number;
		IdUnidadeCompra?: number | null;
		IdColecao?: number | null;
		IdCategoria?: number | null;
		IdDefinicao: number;
		IdGradeTipo?: number | null;
		IdFornecedor?: number | null;
		IdSubcategoria?: number | null;
		IdUnidadeTributaria?: number | null;
		Foto?: string | null;
		Codigo: string;
		CodAnp?: string | null;
		DescAnp?: string | null;
		CodBenef?: string | null;
		Descricao: string;
		CodBarras?: string | null;
		CodigoSecundario?: string | null;
		Complemento?: string | null;
		IpiCnpjProd?: string | null;
		CodListServ?: string | null;
		SkuEcommerce?: string | null;
		CodFornecedor?: string | null;
		Origem: number;
		Peso?: number | null;
		FatorConversao?: number | null;
		FatorConversaoTribut?: number | null;
		Status: StatusEnum;
		Desconto?: number | null;
		AtivoEcommerce: StatusEnum;
		EstoqueAjustado?: RespostaEnum | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		GradePreco: GradePreco;
		IndEscala: IndicadorProducao;
		UnidadeSimbolo?: string | null;
		UnidadeDescricao?: string | null;
		UnidadeStatus?: StatusEnum | null;
		CategoriaNome?: string | null;
		CategoriaDescricao?: string | null;
		CategoriaStatus?: StatusEnum | null;
		SubcategoriaNome?: string | null;
		SubcategoriaDescricao?: string | null;
		SubcategoriaStatus?: StatusEnum | null;
		DefinicaoNome?: string | null;
		DefinicaoDescricao?: string | null;
		DefinicaoVenda?: StatusEnum | null;
		DefinicaoMateriaPrima?: StatusEnum | null;
		DefinicaoUsoConsumo?: StatusEnum | null;
		DefinicaoProducao?: StatusEnum | null;
		DefinicaoServico?: StatusEnum | null;
		UnidadeCompraSimbolo?: string | null;
		UnidadeCompraDescricao?: string | null;
		UnidadeCompraStatus?: StatusEnum | null;
		MarcaNome?: string | null;
		MarcaDescricao?: string | null;
		MarcaStatus?: StatusEnum | null;
		ColecaoNome?: string | null;
		ColecaoDescricao?: string | null;
		ColecaoStatus?: StatusEnum | null;
		FornecedorNome?: string | null;
		FornecedorNomeFantasia?: string | null;
		FornecedorCnpj?: string | null;
		IdSituaTribut?: number | null;
		IdCstIpi?: number | null;
		IdCstPisCofins?: number | null;
		IdNcm?: number | null;
		NcmCodigo?: string | null;
		IdCfop?: number | null;
		CestCodigo?: string | null;
		IdCest?: number | null;
		CfopCodigo?: string | null;
		Quantidade: number;
		QtdMinima?: number | null;
		ValorVenda: number;
		ValorVendaAlt: number;
		MargemLucro?: number | null;
		IcmsMva?: number | null;
		IcmsAliq?: number | null;
		IcmsReducao?: number | null;
		IPIAliq?: number | null;
		PisAliq?: number | null;
		CofinsAliq?: number | null;
		IIAliq?: number | null;
		IpiClasse?: string | null;
		IpiCodSelo?: string | null;
		IpiQtdSelo?: number | null;
		IpiCodEnq?: string | null;
		SaldoIniQtd: number;
		ReducaoMvaNormal?: number | null;
		ReducaoMvaSimples?: number | null;
		PorcentDifIcms?: number | null;
		ValorCusto?: number | null;
		CustoMedio?: number | null;
		GradeTipo?: TipoGrade | null;
		SituaTributCodDescricao?: string | null;
		Grade?: Array<{
			__typename?: "Grade";
			Id?: number | null;
			Codigo: string;
			CodBarras?: string | null;
			IdColuna?: number | null;
			ColunaNome?: string | null;
			ColunaCodigo?: number | null;
			IdLinha: number;
			LinhaNome: string;
			LinhaCodigo: number;
			Preco1: number;
			Preco2: number;
			Quantidade: number;
			ValorCusto: number;
		} | null> | null;
	};
};

export type ProdutoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroProduto>;
}>;

export type ProdutoQuery = {
	__typename?: "Query";
	produto: {
		__typename?: "Produto";
		Id: number;
		Codigo: string;
		Descricao: string;
		CodBarras?: string | null;
		CodigoSecundario?: string | null;
		Quantidade: number;
		UnidadeSimbolo?: string | null;
		ValorVenda: number;
		ValorVendaAlt: number;
		CfopCodigo?: string | null;
		ValorCusto?: number | null;
		CustoMedio?: number | null;
		CategoriaNome?: string | null;
		IdCfop?: number | null;
		UnidadeCompraSimbolo?: string | null;
		SubcategoriaNome?: string | null;
		MarcaNome?: string | null;
		ColecaoNome?: string | null;
		DefinicaoNome?: string | null;
		SituaTributCodDescricao?: string | null;
		NcmCodigo?: string | null;
		DataCadastro: Date;
		IdCest?: number | null;
		IdCstIpi?: number | null;
		IPIAliq?: number | null;
		IdCstPisCofins?: number | null;
		IcmsAliq?: number | null;
		FatorConversao?: number | null;
		DataAlteracao: Date;
		Status: StatusEnum;
		Foto?: string | null;
		GradePreco: GradePreco;
		IdCategoria?: number | null;
		IdSubcategoria?: number | null;
		IdMarca?: number | null;
		IdColecao?: number | null;
		IdDefinicao: number;
		IdNcm?: number | null;
		IdSituaTribut?: number | null;
		IdUnidade: number;
		IdUnidadeCompra?: number | null;
		IdGradeTipo?: number | null;
		MargemLucro?: number | null;
		Grade?: Array<{
			__typename?: "Grade";
			Id?: number | null;
			IdLinha: number;
			IdColuna?: number | null;
			Codigo: string;
			CodBarras?: string | null;
			ValorCusto: number;
			CustoMedio: number;
			Quantidade: number;
			LinhaNome: string;
			ColunaNome?: string | null;
			Preco1: number;
			Preco2: number;
		} | null> | null;
	};
};

export type ItemGradeQueryVariables = Exact<{
	IdProduto: Scalars["Int"];
}>;

export type ItemGradeQuery = {
	__typename?: "Query";
	produto: {
		__typename?: "Produto";
		Id: number;
		IdGradeTipo?: number | null;
		ValorVenda: number;
		ValorVendaAlt: number;
		GradeTipo?: TipoGrade | null;
		GradePreco: GradePreco;
		GradeNomeColuna?: string | null;
		GradeNomeLinha?: string | null;
		Grade?: Array<{
			__typename?: "Grade";
			Id?: number | null;
			Codigo: string;
			CodBarras?: string | null;
			IdColuna?: number | null;
			ColunaNome?: string | null;
			ColunaCodigo?: number | null;
			IdLinha: number;
			LinhaNome: string;
			LinhaCodigo: number;
			ValorCusto: number;
			GradePreco1: number;
			GradePreco2: number;
			GradeQuantidade: number;
			GradeCodBarras?: string | null;
		} | null> | null;
	};
};

export type CodigoProximoProdutoQueryVariables = Exact<{ [key: string]: never }>;

export type CodigoProximoProdutoQuery = { __typename?: "Query"; codigoProximoProduto: number };

export type SituacoesTributariasQueryVariables = Exact<{
	categoria?: InputMaybe<CategoriaSituacao>;
	ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>>;
	status?: InputMaybe<Scalars["Status"]>;
}>;

export type SituacoesTributariasQuery = {
	__typename?: "Query";
	situacoesTributarias: Array<{ __typename?: "SituacaoTributaria"; Id: number; Categoria: CategoriaSituacao; Codigo: string; Descricao: string; Status: StatusEnum }>;
};

export type SituacoesTributariasSelectQueryVariables = Exact<{
	IdSituaTribut?: InputMaybe<Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>>;
}>;

export type SituacoesTributariasSelectQuery = {
	__typename?: "Query";
	situacoesTributarias: Array<{ __typename?: "SituacaoTributaria"; Id: number; Categoria: CategoriaSituacao; Codigo: string; Descricao: string; Status: StatusEnum }>;
};

export type AliquotaIcmsByUfQueryVariables = Exact<{
	IdUf: Scalars["Int"];
}>;

export type AliquotaIcmsByUfQuery = { __typename?: "Query"; aliquotaIcms: { __typename?: "AliquotaIcms"; Aliquota: number } };

export type ProdutosGradeQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroProdutos>;
}>;

export type ProdutosGradeQuery = {
	__typename?: "Query";
	produtos: {
		__typename?: "PaginaProduto";
		Data: Array<{
			__typename?: "Produto";
			Id: number;
			Codigo: string;
			Descricao: string;
			CodBarras?: string | null;
			CodFornecedor?: string | null;
			CodigoSecundario?: string | null;
			Quantidade: number;
			UnidadeSimbolo?: string | null;
			ValorVenda: number;
			ValorVendaAlt: number;
			CfopCodigo?: string | null;
			ValorCusto?: number | null;
			CustoMedio?: number | null;
			CategoriaNome?: string | null;
			IdCfop?: number | null;
			UnidadeCompraSimbolo?: string | null;
			SubcategoriaNome?: string | null;
			MarcaNome?: string | null;
			ColecaoNome?: string | null;
			DefinicaoNome?: string | null;
			SituaTributCodDescricao?: string | null;
			NcmCodigo?: string | null;
			DataCadastro: Date;
			IdCest?: number | null;
			IdCstIpi?: number | null;
			IPIAliq?: number | null;
			IdCstPisCofins?: number | null;
			IcmsAliq?: number | null;
			FatorConversao?: number | null;
			DataAlteracao: Date;
			Status: StatusEnum;
			Foto?: string | null;
			GradePreco: GradePreco;
			IdCategoria?: number | null;
			IdSubcategoria?: number | null;
			IdMarca?: number | null;
			IdColecao?: number | null;
			IdDefinicao: number;
			IdNcm?: number | null;
			IdSituaTribut?: number | null;
			IdUnidade: number;
			IdUnidadeCompra?: number | null;
			IdGradeTipo?: number | null;
			MargemLucro?: number | null;
			Grade?: Array<{
				__typename?: "Grade";
				Id?: number | null;
				IdLinha: number;
				IdColuna?: number | null;
				Codigo: string;
				CodBarras?: string | null;
				ValorCusto: number;
				CustoMedio: number;
				Quantidade: number;
				LinhaNome: string;
				ColunaNome?: string | null;
				Preco1: number;
				Preco2: number;
			} | null> | null;
		}>;
		Paginacao: { __typename?: "PaginacaoProdutos"; TotalRegistros: number };
	};
};

export type MovimentoProdutoQueryVariables = Exact<{
	dados: InputFiltroMovimentoProduto;
}>;

export type MovimentoProdutoQuery = { __typename?: "Query"; movimentoProduto?: MovimentoProduto | null };

export type CadastraSubcategoriaMutationVariables = Exact<{
	dados: InputCadastrarSubcategoria;
}>;

export type CadastraSubcategoriaMutation = { __typename?: "Mutation"; cadastraSubcategoria: RetornoPadrao };

export type EditaSubcategoriaMutationVariables = Exact<{
	dados: InputEditarSubcategoria;
}>;

export type EditaSubcategoriaMutation = { __typename?: "Mutation"; editaSubcategoria: RetornoPadrao };

export type ToggleStatusSubcategoriaMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusSubcategoriaMutation = { __typename?: "Mutation"; editaSubcategoria: RetornoPadrao };

export type SubcategoriasQueryVariables = Exact<{ [key: string]: never }>;

export type SubcategoriasQuery = {
	__typename?: "Query";
	subcategorias: Array<{
		__typename?: "Subcategoria";
		Descricao?: string | null;
		DescricaoCategoria?: string | null;
		Id: number;
		IdCategoria: number;
		Nome: string;
		NomeCategoria: string;
		Status: StatusEnum;
		StatusCategoria: StatusEnum;
	}>;
};

export type SubcategoriaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroSubcategoria>;
}>;

export type SubcategoriaQuery = {
	__typename?: "Query";
	subcategoria: {
		__typename?: "Subcategoria";
		Descricao?: string | null;
		DescricaoCategoria?: string | null;
		Id: number;
		IdCategoria: number;
		Nome: string;
		NomeCategoria: string;
		Status: StatusEnum;
		StatusCategoria: StatusEnum;
	};
};

export type SubcategoriaByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type SubcategoriaByIdQuery = {
	__typename?: "Query";
	subcategoria: {
		__typename?: "Subcategoria";
		Descricao?: string | null;
		DescricaoCategoria?: string | null;
		Id: number;
		IdCategoria: number;
		Nome: string;
		NomeCategoria: string;
		Status: StatusEnum;
		StatusCategoria: StatusEnum;
	};
};

export type CadastraUnidadeMutationVariables = Exact<{
	dados: InputCadastrarUnidade;
}>;

export type CadastraUnidadeMutation = { __typename?: "Mutation"; cadastraUnidade: RetornoPadrao };

export type EditaUnidadeMutationVariables = Exact<{
	dados: InputEditarUnidade;
}>;

export type EditaUnidadeMutation = { __typename?: "Mutation"; editaUnidade: RetornoPadrao };

export type ToggleStatusUnidadeMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusUnidadeMutation = { __typename?: "Mutation"; editaUnidade: RetornoPadrao };

export type UnidadesQueryVariables = Exact<{ [key: string]: never }>;

export type UnidadesQuery = { __typename?: "Query"; unidades: Array<{ __typename?: "Unidade"; Descricao?: string | null; Simbolo: string; Status: StatusEnum; Id: number }> };

export type UnidadeQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroUnidade>;
}>;

export type UnidadeQuery = { __typename?: "Query"; unidade: { __typename?: "Unidade"; Descricao?: string | null; Id: number; Simbolo: string; Status: StatusEnum } };

export type UnidadeByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type UnidadeByIdQuery = { __typename?: "Query"; unidade: { __typename?: "Unidade"; Descricao?: string | null; Id: number; Simbolo: string; Status: StatusEnum } };

export type UnidadesSelectQueryVariables = Exact<{
	IdUnidade?: InputMaybe<Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>>;
}>;

export type UnidadesSelectQuery = { __typename?: "Query"; unidades: Array<{ __typename?: "Unidade"; Id: number; Simbolo: string; Status: StatusEnum }> };

export type RecebimentoReceitaMutationVariables = Exact<{
	dados: InputRecebimentoReceita;
}>;

export type RecebimentoReceitaMutation = { __typename?: "Mutation"; recebimentoReceita: RetornoPadrao };

export type RecebimentoParcialReceitaMutationVariables = Exact<{
	dados: InputRecebimentoParcialReceita;
}>;

export type RecebimentoParcialReceitaMutation = { __typename?: "Mutation"; recebimentoParcialReceita: RetornoPadrao };

export type PagamentoDespesaMutationVariables = Exact<{
	dados: InputPagamentoDespesa;
}>;

export type PagamentoDespesaMutation = { __typename?: "Mutation"; pagamentoDespesa: RetornoPadrao };

export type PagamentoParcialDespesaMutationVariables = Exact<{
	dados: InputPagamentoParcialDespesa;
}>;

export type PagamentoParcialDespesaMutation = { __typename?: "Mutation"; pagamentoParcialDespesa: RetornoPadrao };

export type EditaLancamentoDespesaMutationVariables = Exact<{
	dados: InputEditarLancamentoDespesa;
}>;

export type EditaLancamentoDespesaMutation = { __typename?: "Mutation"; editaLancamentoDespesa: RetornoPadrao };

export type EditaLancamentoReceitaMutationVariables = Exact<{
	dados: InputEditarLancamentoReceita;
}>;

export type EditaLancamentoReceitaMutation = { __typename?: "Mutation"; editaLancamentoReceita: RetornoPadrao };

export type CadastraContaFinanceiraMutationVariables = Exact<{
	dados: InputCadastrarContaFinanceira;
}>;

export type CadastraContaFinanceiraMutation = { __typename?: "Mutation"; cadastraContaFinanceira: RetornoPadrao };

export type EditaContaFinanceiraMutationVariables = Exact<{
	dados: InputEditarContaFinanceira;
}>;

export type EditaContaFinanceiraMutation = { __typename?: "Mutation"; editaContaFinanceira: RetornoPadrao };

export type ToggleStatusContaFinanceiraMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusContaFinanceiraMutation = { __typename?: "Mutation"; editaContaFinanceira: RetornoPadrao };

export type SaldoInicialContaFinanceiraMutationVariables = Exact<{
	dados: InputSaldoInicialContaFinanceira;
}>;

export type SaldoInicialContaFinanceiraMutation = { __typename?: "Mutation"; saldoInicialContaFinanceira: RetornoPadrao };

export type ContasFinanceirasSelectQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroContasFinanceiras>;
}>;

export type ContasFinanceirasSelectQuery = {
	__typename?: "Query";
	contasFinanceiras: Array<{ __typename?: "ContaFinanceira"; Id: number; Nome: string; IdNivel: number; Status: StatusEnum; Tipo: TipoContaFinanceira }>;
};

export type ContaFinanceiraQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroContaFinanceira>;
}>;

export type ContaFinanceiraQuery = {
	__typename?: "Query";
	contaFinanceira: {
		__typename?: "ContaFinanceira";
		Tipo: TipoContaFinanceira;
		Nome: string;
		Status: StatusEnum;
		NumConta?: string | null;
		DvConta?: string | null;
		NumAgencia?: string | null;
		DvAgencia?: string | null;
		NomeTitular?: string | null;
		Gerente?: string | null;
		Telefone?: string | null;
		Email?: string | null;
	};
};

export type CadastraFormaPagamentoMutationVariables = Exact<{
	dados: InputCadastrarFormaPagamento;
}>;

export type CadastraFormaPagamentoMutation = { __typename?: "Mutation"; cadastraFormaPagamento: RetornoPadrao };

export type EditaFormaPagamentoMutationVariables = Exact<{
	dados: InputEditarFormaPagamento;
}>;

export type EditaFormaPagamentoMutation = { __typename?: "Mutation"; editaFormaPagamento: RetornoPadrao };

export type ToggleStatusFormaPagamentoMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusFormaPagamentoMutation = { __typename?: "Mutation"; editaFormaPagamento: RetornoPadrao };

export type FormasPagamentoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroFormasPagamento>;
}>;

export type FormasPagamentoQuery = {
	__typename?: "Query";
	formasPagamento: Array<{ __typename?: "FormaPagamento"; Id: number; Nome: string; FormaVenda: FormasVenda; Tipo: TipoFormaPagamento; TipoPagamentoNFe: TagPagamentoNFe; Status: StatusEnum }>;
};

export type FormaPagamentoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroFormaPagamento>;
}>;

export type FormaPagamentoQuery = {
	__typename?: "Query";
	formaPagamento: { __typename?: "FormaPagamento"; Id: number; Nome: string; FormaVenda: FormasVenda; Tipo: TipoFormaPagamento; TipoPagamentoNFe: TagPagamentoNFe; Status: StatusEnum };
};

export type LancamentoDespesaMutationVariables = Exact<{
	dados: InputLancamentoDespesa;
}>;

export type LancamentoDespesaMutation = { __typename?: "Mutation"; lancamentoDespesa: RetornoPadrao };

export type LancamentoReceitaMutationVariables = Exact<{
	dados: InputLancamentoReceita;
}>;

export type LancamentoReceitaMutation = { __typename?: "Mutation"; lancamentoReceita: RetornoPadrao };

export type ToggleDeletaLancamentoMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type ToggleDeletaLancamentoMutation = { __typename?: "Mutation"; deletaLancamento: RetornoPadrao };

export type TransferenciaContaFinanceiraMutationVariables = Exact<{
	dados: InputTransferenciaContaFinanceira;
}>;

export type TransferenciaContaFinanceiraMutation = { __typename?: "Mutation"; transferenciaContaFinanceira: RetornoPadrao };

export type MovimentoFinanceiroQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroMovimentoFinanceiro>;
}>;

export type MovimentoFinanceiroQuery = {
	__typename?: "Query";
	movimentoFinanceiro: Array<{
		__typename?: "MovimentoContaFinanceira";
		Id: number;
		IdLancamento: number;
		Data: Date;
		Historico?: string | null;
		EntradaSaida: number;
		NomeUsuario: string;
		NomePessoa?: string | null;
		NomeConta?: string | null;
		Saldo: number;
		NomeNivelPlanoContas?: string | null;
		SaldoAnterior: number;
		IdNivelDefinicao?: number | null;
		TipoLancamento: number;
		CodigoFinanceiro?: string | null;
		IdFormaPgto?: number | null;
	}>;
};

export type OperacoesQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroOperacoes>;
}>;

export type OperacoesQuery = { __typename?: "Query"; operacoes: Array<{ __typename?: "Operacao"; Id: number; Nome: string; TipoOperacao: TipoOperacao }> };

export type PagamentosQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroPagamentos>;
}>;

export type PagamentosQuery = {
	__typename?: "Query";
	pagamentos: {
		__typename?: "PaginaPagamento";
		Data: Array<{
			__typename?: "Pagamento";
			Id: number;
			IdParcela?: number | null;
			IdNfe?: number | null;
			IdLancamento?: number | null;
			NumeroNFe?: string | null;
			IdVenda?: number | null;
			CodigoVenda?: string | null;
			DataEmissao: Date;
			DataVencimento: Date;
			DataPagamento?: Date | null;
			IdConta: number;
			NomeConta: string;
			NomeContaFinanceira?: string | null;
			IdFornecedor?: number | null;
			NomeFornecedor?: string | null;
			Historico?: string | null;
			CodigoFinanceiro?: string | null;
			ValorTotal: number;
			PgtoParcial: number;
			ValorJuroPgto: number;
			ValorDesconto: number;
			ValorPago: number;
			ValorPagar: number;
			IdEmpresa: number;
			NomeEmpresa: string;
			TipoNivelDefinicao?: TipoNivelDefinicao | null;
			StatusParcela: StatusParcelamento;
			TipoLancamento: FormasVenda;
			NomeFormaPagamento: string;
			StatusPagamento: StatusPagamento;
		}>;
		Paginacao: { __typename?: "PaginacaoPagamento"; TotalRegistros: number; TotalPago: number; TotalAtraso: number; TotalVenceHoje: number; TotalVencer: number };
	};
};

export type RecebimentosQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroRecebimentos>;
}>;

export type RecebimentosQuery = {
	__typename?: "Query";
	recebimentos: {
		__typename?: "PaginaReceita";
		Data: Array<{
			__typename?: "Receita";
			Id: number;
			IdParcela?: number | null;
			IdNfe?: number | null;
			IdLancamento?: number | null;
			NumeroNFe?: string | null;
			IdVenda?: number | null;
			CodigoVenda?: string | null;
			DataEmissao: Date;
			DataVencimento: Date;
			DataRecebimento?: Date | null;
			IdConta: number;
			NomeConta: string;
			NomeContaFinanceira?: string | null;
			IdCliente?: number | null;
			NomeCliente?: string | null;
			Historico?: string | null;
			CodigoFinanceiro?: string | null;
			ValorTotal: number;
			ValorTotalJuros: number;
			PgtoParcial: number;
			ValorJuroPgto: number;
			ValorDesconto: number;
			ValorRecebido: number;
			ValorReceber: number;
			ValorReceberJuros: number;
			IdEmpresa: number;
			NomeEmpresa: string;
			TipoNivelDefinicao?: TipoNivelDefinicao | null;
			StatusParcela: StatusParcelamento;
			TipoLancamento: FormasVenda;
			NomeFormaPagamento: string;
			StatusRecebimento: StatusRecebimento;
		}>;
		Paginacao: { __typename?: "PaginacaoReceita"; TotalRegistros: number; TotalQuitado: number; TotalAtraso: number; TotalVenceHoje: number; TotalVencer: number; TotalAtrasoJuros: number };
	};
};

export type LancamentosQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroQueryLancamento>;
}>;

export type LancamentosQuery = { __typename?: "Query"; lancamentos: Array<{ __typename?: "Lancamento"; DataLancamento: Date; ValorTotal: number; FormaPgtoPrest?: string | null }> };

export type GerarSpedFiscalSubscriptionVariables = Exact<{
	filtro: InputFiltroSpedFiscal;
}>;

export type GerarSpedFiscalSubscription = { __typename?: "Subscription"; gerarSpedFiscal?: RetornoTarefa | null };

export type DownloadArquivosContadorSubscriptionVariables = Exact<{
	filtro: InputFiltroArquivo;
}>;

export type DownloadArquivosContadorSubscription = { __typename?: "Subscription"; downloadArquivosContador: RetornoTarefa };

export type TransmiteNfeSubscriptionVariables = Exact<{
	idNfe: Scalars["Int"];
}>;

export type TransmiteNfeSubscription = { __typename?: "Subscription"; transmiteNfe?: RetornoTarefa | null };

export type ConsultaStatusNfeSubscriptionVariables = Exact<{
	idNfe: Scalars["Int"];
}>;

export type ConsultaStatusNfeSubscription = { __typename?: "Subscription"; consultaStatusNFe?: RetornoTarefa | null };

export type ExcluiManifestoMutationVariables = Exact<{
	IdManifesto: Scalars["Int"];
}>;

export type ExcluiManifestoMutation = { __typename?: "Mutation"; editaManifesto: RetornoPadrao };

export type RecalculaDadosNfeCompraMutationVariables = Exact<{
	dados?: InputMaybe<InputRecalculaNfe>;
}>;

export type RecalculaDadosNfeCompraMutation = {
	__typename?: "Mutation";
	recalculaDadosNfeCompra: {
		__typename?: "RecalculaNfe";
		ValorDesconto: number;
		ValorSeguro: number;
		ValorFrete: number;
		ValorOutro: number;
		ValorIcmsSt: number;
		ValorIi: number;
		ValorIpi: number;
		ValorSubTotal: number;
		ValorTotalNfe: number;
		Itens: Array<{
			__typename?: "RecalculaNfeItens";
			Index: number;
			IdProduto?: number | null;
			ValorUnitario: number;
			ValorCustoUnitario: number;
			ValorDesconto: number;
			ValorDescontoTotal: number;
			ValorAcrescimo: number;
			ValorFrete: number;
			ValorSeguro: number;
			ValorOutro: number;
			ValorIcmsSt: number;
			ValorIi: number;
			ValorIpi: number;
			ValorSubTotal: number;
			ValorTotalItem: number;
			Quantidade: number;
		}>;
	};
};

export type RemoveNfeCompraMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type RemoveNfeCompraMutation = { __typename?: "Mutation"; removeNfeCompra?: RetornoPadrao | null };

export type EnviarXmlCompraMutationVariables = Exact<{
	CorrelationId: Scalars["String"];
	XML: Scalars["Upload"];
}>;

export type EnviarXmlCompraMutation = { __typename?: "Mutation"; enviarXMLCompra?: RetornoTarefa | null };

export type CadastraNfeCompraMutationVariables = Exact<{
	dados: InputCadastrarNfeCompra;
}>;

export type CadastraNfeCompraMutation = { __typename?: "Mutation"; cadastraNfeCompra?: RetornoPadrao | null };

export type EditaNfeCompraMutationVariables = Exact<{
	dados: InputEditarNfeCompra;
}>;

export type EditaNfeCompraMutation = { __typename?: "Mutation"; editaNfeCompra?: RetornoPadrao | null };

export type FinalizarNfeCompraMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type FinalizarNfeCompraMutation = { __typename?: "Mutation"; confirmaNfeCompra?: RetornoPadrao | null };

export type ManifestosQueryVariables = Exact<{ [key: string]: never }>;

export type ManifestosQuery = {
	__typename?: "Query";
	manifestos: Array<{
		__typename?: "Manifesto";
		Id: number;
		IdEmpresa: number;
		Nome: string;
		Cnpj: string;
		NumNfe: number;
		Chave: string;
		Emissao: Date;
		Valor: number;
		Status: StatusManifestoNFe;
		Evento?: EventoManifestoNFe | null;
		FinalidadeNfe?: FinalidadeNFe | null;
		NaturezaOperacao?: string | null;
	}>;
};

export type ManifestoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type ManifestoByIdQuery = {
	__typename?: "Query";
	manifesto: {
		__typename?: "Manifesto";
		Id: number;
		IdEmpresa: number;
		Nome: string;
		Cnpj: string;
		NumNfe: number;
		Chave: string;
		Emissao: Date;
		Valor: number;
		Status: StatusManifestoNFe;
		Evento?: EventoManifestoNFe | null;
		FinalidadeNfe?: FinalidadeNFe | null;
		NaturezaOperacao?: string | null;
	};
};

export type NfeCompraQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNfe>;
}>;

export type NfeCompraQuery = {
	__typename?: "Query";
	nfe: {
		__typename?: "Nfe";
		Id: number;
		NumNF: number;
		Serie: number;
		IdPessoa?: number | null;
		NomePessoa?: string | null;
		NomeFantasiaPessoa?: string | null;
		IdCfop: number;
		NatOperacao?: string | null;
		ValorTotalNfe: number;
		ValorDesconto: number;
		ValorFrete: number;
		ValorOutro: number;
		ValorSeguro: number;
		ValorIcmsSt: number;
		ValorIpi: number;
		ValorIi: number;
		ValorFcpSt?: number | null;
		Status: StatusNfe;
		Modelo: Modelo;
		Origem: OrigemNFe;
		DataHoraSaiEnt: Date;
		DataHoraEmissao: Date;
		CodigoCfop: string;
		ItemNfe?: Array<{
			__typename?: "ItemNfe";
			Id: number;
			IdProduto?: number | null;
			IdGradeTipo?: number | null;
			IdCfop: number;
			IdNcm?: number | null;
			IdSituaTribut?: number | null;
			Codigo?: string | null;
			CodFornecedor?: string | null;
			Descricao: string;
			Quantidade: number;
			QuantidadeCompra?: number | null;
			ValorCustoUnitario: number;
			ValorCustoMedioUnitario: number;
			ValorUnitario: number;
			CodBarras?: string | null;
			FatorConversao?: number | null;
			IdUnidade: number;
			IdUnidadeCompra?: number | null;
			ValorDesconto: number;
			ValorAcrescimo?: number | null;
			ValorDescontoTotal: number;
			ValorSeguro: number;
			ValorOutro: number;
			ValorFrete: number;
			ValorIcmsSt?: number | null;
			ValorIpi?: number | null;
			ValorIi?: number | null;
			ValorFcpSt?: number | null;
			DetalhamentoItem?: string | null;
			PrecoVenda?: number | null;
			PrecoVenda2?: number | null;
			MargemLucro?: number | null;
			CodigoCfop?: string | null;
			SimboloUnidade: string;
			SimboloUnidadeCompra?: string | null;
			QuantidadeEstoque: number;
			ItensGrade?: Array<{
				__typename?: "ItemGrade";
				Id: number;
				IdItemNfe?: number | null;
				IdGrade: number;
				Quantidade?: number | null;
				Valor?: number | null;
				LinhaId: number;
				LinhaCodigo: number;
				LinhaNome: string;
				ColunaId?: number | null;
				ColunaCodigo?: number | null;
				ColunaNome?: string | null;
				GradePreco1?: number | null;
				GradePreco2?: number | null;
				GradeCodBarras?: string | null;
				GradeQuantidade?: number | null;
			} | null> | null;
		}> | null;
		PrestacaoNfe?: Array<{
			__typename?: "PrestacaoNfe";
			Id: number;
			IdFormaPgto: number;
			NomeFormaPgto?: string | null;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			NumLancamento: number;
			NumDocumento?: string | null;
			DataVencimento: Date;
			TipoFormaPgto: TipoFormaPagamento;
			TagPgtoNFe: TagPagamentoNFe;
		}> | null;
	};
};

export type NfesCompraQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNfes>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type NfesCompraQuery = {
	__typename?: "Query";
	nfes: {
		__typename?: "PaginaNfe";
		Data: Array<{
			__typename?: "Nfe";
			Id: number;
			NumNF: number;
			Serie: number;
			ValorDesconto: number;
			ValorFrete: number;
			ValorOutro: number;
			ValorSeguro: number;
			DataSaiEnt: Date;
			DataEmissao: Date;
			HoraEmissao: Date;
			NomePessoa?: string | null;
			IdPessoa?: number | null;
			NaturezaOperacao: string;
			ValorTotalNfe: number;
			ChaveAcesso?: string | null;
			Status: StatusNfe;
			TipoNF: TipoNFe;
			Origem: OrigemNFe;
			DataHoraSaiEnt: Date;
			DataHoraEmissao: Date;
		}>;
		Paginacao: { __typename?: "PaginacaoNfe"; TotalRegistros: number; TotalTransmitido: number; TotalRegistrosTransmitido: number; QuantidadeItensTransmitidos: number };
	};
};

export type CodigoCfopByIdQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCfop>;
}>;

export type CodigoCfopByIdQuery = { __typename?: "Query"; cfop: { __typename?: "Cfop"; Codigo: string; Id: number; IdOriginal: number } };

export type ContasFinanceirasQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroContasFinanceiras>;
}>;

export type ContasFinanceirasQuery = {
	__typename?: "Query";
	contasFinanceiras: Array<{
		__typename?: "ContaFinanceira";
		Id: number;
		Nome: string;
		Tipo: TipoContaFinanceira;
		Status: StatusEnum;
		NumConta?: string | null;
		DvConta?: string | null;
		NumAgencia?: string | null;
		DvAgencia?: string | null;
	}>;
};

export type AdminsCartoesQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroAdminsCartao>;
}>;

export type AdminsCartoesQuery = { __typename?: "Query"; adminsCartoes: Array<{ __typename?: "AdminCartao"; Id: number; Nome: string }> };

export type ItensNfeQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroItensNfe>;
}>;

export type ItensNfeQuery = {
	__typename?: "Query";
	itensNfe?: Array<{
		__typename?: "ItemNfe";
		Id: number;
		IdProduto?: number | null;
		IdGradeTipo?: number | null;
		Codigo?: string | null;
		CodBarras?: string | null;
		CodFornecedor?: string | null;
		Descricao: string;
		Quantidade: number;
		SimboloUnidade: string;
		PrecoVenda?: number | null;
		PrecoVenda2?: number | null;
		ItensGrade?: Array<{
			__typename?: "ItemGrade";
			Id: number;
			IdItemNfe?: number | null;
			IdGrade: number;
			Quantidade?: number | null;
			Valor?: number | null;
			LinhaId: number;
			LinhaCodigo: number;
			LinhaNome: string;
			ColunaId?: number | null;
			ColunaCodigo?: number | null;
			ColunaNome?: string | null;
			GradePreco1?: number | null;
			GradePreco2?: number | null;
			GradeCodBarras?: string | null;
			GradeQuantidade?: number | null;
		} | null> | null;
	} | null> | null;
};

export type DevolucaoNfeCompraQueryVariables = Exact<{
	Dados: InputDevolucaoNfeCompra;
}>;

export type DevolucaoNfeCompraQuery = {
	__typename?: "Query";
	devolucaoNfeCompra: {
		__typename?: "NfeRetorno";
		IdCfop: number;
		CFOP: string;
		CFOPOperacao: TipoCfopOperacao;
		DescricaoOperacao: string;
		NatOperacao?: string | null;
		DataHoraEmissao: Date;
		DataHoraSaiEnt: Date;
		ModCliente?: ModalidadeCliente | null;
		Status: StatusNfe;
		IdPessoa: number;
		NomePessoa: string;
		NomeFantasiaPessoa?: string | null;
		CpfCnpjPessoa?: string | null;
		IdNfeRef?: number | null;
		TelefonePessoa?: string | null;
		CelularPessoa?: string | null;
		EmailPessoa?: string | null;
		CidadePessoa?: string | null;
		EstadoPessoa?: string | null;
		ChaveAcesso?: string | null;
		RuaPessoa?: string | null;
		BairroPessoa?: string | null;
		NumeroPessoa?: string | null;
		ComplementoPessoa?: string | null;
		ArquivoPdf?: string | null;
		ValorDesconto: number;
		ValorSeguro: number;
		ValorOutro: number;
		ValorFrete: number;
		Frete: TipoFrete;
		IdVendedor?: number | null;
		OutrasOpcoes?: string | null;
		IdTransportadora?: number | null;
		NomeTransportadora?: string | null;
		NomeFantasiaTransportadora?: string | null;
		CpfCnpjTransportadora?: string | null;
		TelefoneTransportadora?: string | null;
		CelularTransportadora?: string | null;
		EmailTransportadora?: string | null;
		CidadeTransportadora?: string | null;
		EstadoTransportadora?: string | null;
		RuaTransportadora?: string | null;
		BairroTransportadora?: string | null;
		NumeroTransportadora?: string | null;
		ComplementoTransportadora?: string | null;
		IdVeiculo?: number | null;
		PlacaVeiculo?: string | null;
		RNTCVeiculo?: string | null;
		PlacaReboqueVeiculo?: string | null;
		RNTCReboqueVeiculo?: string | null;
		ValorIcms: number;
		ValorIcmsSt: number;
		ValorIpi: number;
		ValorFcpSt?: number | null;
		ValorIi: number;
		ObservacaoComplementar?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		TipoNFe: TipoNFe;
		ItemNfe?: Array<{
			__typename?: "ItemNfe";
			IdProduto?: number | null;
			IdUnidade: number;
			IdSituaTribut?: number | null;
			IdCfop: number;
			IdNcm?: number | null;
			IdCest?: number | null;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			Codigo?: string | null;
			Descricao: string;
			CodigoCfop?: string | null;
			Quantidade: number;
			SimboloUnidade: string;
			ValorUnitario: number;
			ValorDesconto: number;
			ValorAcrescimo?: number | null;
			ValorCustoMedioUnitario: number;
			ValorCustoUnitario: number;
			FatorConversao?: number | null;
			CodBarras?: string | null;
			CodBarrasGrade?: string | null;
			DescricaoComp?: string | null;
			DescricaoGrade?: string | null;
			DescricaoProduto: string;
			CodBenef?: string | null;
			PedidoDeCompra?: string | null;
			NumItemPedido?: string | null;
			ValorBCIcms?: number | null;
			PorcentIcms?: number | null;
			ValorIcms?: number | null;
			ValorBCIcmsSt?: number | null;
			PorcentIcmsSt?: number | null;
			ValorIcmsSt?: number | null;
			ValorBCIpi?: number | null;
			PorcentIpi?: number | null;
			ValorIpi?: number | null;
			IdCstIpi?: number | null;
			PorcentDevol?: number | null;
			IdCstPis?: number | null;
			IdCstCofins?: number | null;
			ValorBCFcpStRet?: number | null;
			PorcentFcpStRet?: number | null;
			ValorFcpStRet?: number | null;
			ValorIcmsSubstituto?: number | null;
			ValorBCEfet?: number | null;
			PorcentIcmsEfet?: number | null;
			PorcentRedBCEfet?: number | null;
			ValorBCStRet?: number | null;
			PorcentSt?: number | null;
			ValorIcmsStRet?: number | null;
			ValorIcmsDeson?: number | null;
			MotDesIcms?: number | null;
			ValorIcmsEfet?: number | null;
			ValorIi?: number | null;
			ValorFcpSt?: number | null;
			ValorIpiDevol?: number | null;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			OutrasOpcoes?: string | null;
		}> | null;
		VolumeNfe?: Array<{
			__typename?: "VolumeNfe";
			Quantidade: number;
			Especie?: string | null;
			Marca?: string | null;
			Numeracao?: string | null;
			PesoLiquido: number;
			PesoBruto: number;
		} | null> | null;
		PrestacaoNfe?: Array<{
			__typename?: "PrestacaoNfe";
			IdFormaPgto: number;
			NomeFormaPgto?: string | null;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			NumLancamento: number;
			NumDocumento?: string | null;
			DataVencimento: Date;
			TipoFormaPgto: TipoFormaPagamento;
			TagPgtoNFe: TagPagamentoNFe;
		}> | null;
		VendaNfe?: Array<{ __typename?: "VendaNfe"; Id: number } | null> | null;
		ReferenciaNfe?: Array<{
			__typename?: "ReferenciaNfe";
			IdEmpresa: number;
			IdNfeRef?: number | null;
			IdEstado?: number | null;
			IdFornecedor?: number | null;
			ChaveAcesso?: string | null;
			CnpjEmitente?: string | null;
			Modelo?: ModeloReferencia | null;
			Serie?: number | null;
			NumNf?: number | null;
			DataEmissao?: Date | null;
			Tipo?: TipoReferencia | null;
			IndEmitente?: IndicadorEmitente | null;
			IndOperacao?: IndicadorOperacao | null;
		} | null> | null;
	};
};

export type LerXmlManifestoSubscriptionVariables = Exact<{
	idManifesto: Scalars["Int"];
}>;

export type LerXmlManifestoSubscription = { __typename?: "Subscription"; lerXMLManifestoNFe: RetornoTarefa };

export type EventoManifestoNfeSubscriptionVariables = Exact<{
	idsManifesto: Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>;
	evento: EventoManifestoNFe;
	justificativaEvento?: InputMaybe<Scalars["String"]>;
}>;

export type EventoManifestoNfeSubscription = { __typename?: "Subscription"; eventoManifestoNFe: RetornoTarefa };

export type ConsultarManifestoNFeSefazSubscriptionVariables = Exact<{
	manifestarCienciaOperacao: Scalars["Boolean"];
}>;

export type ConsultarManifestoNFeSefazSubscription = { __typename?: "Subscription"; consultarManifestoNFeSefaz: RetornoTarefa };

export type CadastraNfeMutationVariables = Exact<{
	dados: InputCadastrarNfe;
	dadosTransmissao?: InputMaybe<InputDadosTransmissaoNFe>;
}>;

export type CadastraNfeMutation = { __typename?: "Mutation"; cadastraNfe?: RetornoPadrao | null };

export type EditaNfeMutationVariables = Exact<{
	dados: InputEditarNfe;
	dadosTransmissao?: InputMaybe<InputDadosTransmissaoNFe>;
}>;

export type EditaNfeMutation = { __typename?: "Mutation"; editaNfe?: RetornoPadrao | null };

export type CadastraEventoNfeMutationVariables = Exact<{
	dados: InputCadastrarEventoNfe;
}>;

export type CadastraEventoNfeMutation = { __typename?: "Mutation"; cadastraEventoNfe: RetornoPadrao };

export type RecalculaDadosNfeEmissaoMutationVariables = Exact<{
	dados?: InputMaybe<InputCalculaImpostoNfe>;
}>;

export type RecalculaDadosNfeEmissaoMutation = {
	__typename?: "Mutation";
	recalculaDadosNfeEmissao: {
		__typename?: "CalculaImpostoNfe";
		ValorDesconto: number;
		ValorSeguro: number;
		ValorFrete: number;
		ValorOutro: number;
		IdPessoa?: number | null;
		TipoNF?: TipoNFe | null;
		IdCfop?: number | null;
		ModCliente?: ModalidadeCliente | null;
		IdEnderecoEntrega?: number | null;
		OutrasOpcoes?: string | null;
		ValorIpiDevol?: number | null;
		ValorBcIcms?: number | null;
		ValorIcms?: number | null;
		ValorBcIcmsSt?: number | null;
		ValorIcmsSt?: number | null;
		ValorIi?: number | null;
		ValorPis?: number | null;
		ValorCofins?: number | null;
		ValorIcmsDesonerado?: number | null;
		ValorIpi?: number | null;
		ValorBCIss?: number | null;
		ValorIss?: number | null;
		ValorFcpSt?: number | null;
		ValorSubTotal: number;
		ValorTotalNfe: number;
		ObservacaoComplementar?: string | null;
		Itens: Array<{
			__typename?: "CalculaImpostoNfeItens";
			Index: number;
			IdProduto: number;
			IdNcm?: number | null;
			IdCfop: number;
			Quantidade: number;
			ValorUnitario: number;
			ValorDesconto: number;
			ValorAcrescimo: number;
			IdSituaTribut?: number | null;
			IdCstIpi?: number | null;
			IdCstPis?: number | null;
			IdCstCofins?: number | null;
			IdOperacaoCfop?: TipoCfopOperacao | null;
			DetalhamentoItem?: string | null;
			ValorIcms?: number | null;
			ValorBCIcms?: number | null;
			PorcentIcms?: number | null;
			ValorBCIcmsSt?: number | null;
			PorcentIcmsSt?: number | null;
			ValorIcmsSt?: number | null;
			PorcentSt?: number | null;
			ValorIcmsDeson?: number | null;
			ValorIpi?: number | null;
			ValorBCIpi?: number | null;
			PorcentIpi?: number | null;
			ValorIi?: number | null;
			ValorIcmsEfet?: number | null;
			ValorBCEfet?: number | null;
			PorcentIcmsEfet?: number | null;
			PorcentRedBCEfet?: number | null;
			ValorIcmsStRet?: number | null;
			ValorIcmsSubstituto?: number | null;
			ValorFcpSt?: number | null;
			ValorFcpStRet?: number | null;
			PorcentFcpStRet?: number | null;
			ValorBCFcpStRet?: number | null;
			ValorBCStRet?: number | null;
			ValorIpiDevol?: number | null;
			CodigoCfop?: string | null;
			IdCest?: number | null;
			OutrasOpcoes?: string | null;
		}>;
	};
};

export type GerarDanFeMutationVariables = Exact<{
	idNFe: Scalars["Int"];
}>;

export type GerarDanFeMutation = { __typename?: "Mutation"; gerarDANFe?: RetornoTarefa | null };

export type NfeQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNfe>;
}>;

export type NfeQuery = {
	__typename?: "Query";
	nfe: {
		__typename?: "Nfe";
		Id: number;
		NumNF: number;
		Serie: number;
		IdCfop: number;
		CFOP: string;
		CFOPOperacao: TipoCfopOperacao;
		DescricaoOperacao: string;
		NatOperacao?: string | null;
		DataHoraEmissao: Date;
		DataHoraSaiEnt: Date;
		ModCliente?: ModalidadeCliente | null;
		Status: StatusNfe;
		IdPessoa?: number | null;
		NomePessoa?: string | null;
		NomeFantasiaPessoa?: string | null;
		CpfCnpjPessoa?: string | null;
		IdNfeRef?: number | null;
		TelefonePessoa?: string | null;
		CelularPessoa?: string | null;
		EmailPessoa?: string | null;
		CidadePessoa?: string | null;
		EstadoPessoa?: string | null;
		ChaveAcesso?: string | null;
		RuaPessoa?: string | null;
		BairroPessoa?: string | null;
		NumeroPessoa?: string | null;
		ComplementoPessoa?: string | null;
		ArquivoPdf?: string | null;
		ValorDesconto: number;
		ValorSeguro: number;
		ValorOutro: number;
		ValorFrete: number;
		Frete: TipoFrete;
		IdVendedor?: number | null;
		IdIntermediador?: number | null;
		OutrasOpcoes?: string | null;
		IdTransportadora?: number | null;
		NomeTransportadora?: string | null;
		NomeFantasiaTransportadora?: string | null;
		CpfCnpjTransportadora?: string | null;
		TelefoneTransportadora?: string | null;
		CelularTransportadora?: string | null;
		EmailTransportadora?: string | null;
		CidadeTransportadora?: string | null;
		EstadoTransportadora?: string | null;
		RuaTransportadora?: string | null;
		BairroTransportadora?: string | null;
		NumeroTransportadora?: string | null;
		ComplementoTransportadora?: string | null;
		IdVeiculo?: number | null;
		PlacaVeiculo?: string | null;
		RNTCVeiculo?: string | null;
		PlacaReboqueVeiculo?: string | null;
		RNTCReboqueVeiculo?: string | null;
		ValorIcms: number;
		ValorIcmsSt: number;
		ValorIpi: number;
		ValorFcpSt?: number | null;
		ValorIi: number;
		ObservacaoComplementar?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		TipoNFe: TipoNFe;
		ItemNfe?: Array<{
			__typename?: "ItemNfe";
			Id: number;
			IdProduto?: number | null;
			IdUnidade: number;
			IdSituaTribut?: number | null;
			IdCfop: number;
			IdNcm?: number | null;
			IdCest?: number | null;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			Codigo?: string | null;
			Descricao: string;
			CodigoCfop?: string | null;
			Quantidade: number;
			SimboloUnidade: string;
			ValorUnitario: number;
			ValorDesconto: number;
			ValorAcrescimo?: number | null;
			ValorCustoMedioUnitario: number;
			ValorCustoUnitario: number;
			FatorConversao?: number | null;
			CodBarras?: string | null;
			CodBarrasGrade?: string | null;
			DescricaoComp?: string | null;
			DescricaoGrade?: string | null;
			DescricaoProduto: string;
			CodBenef?: string | null;
			PedidoDeCompra?: string | null;
			NumItemPedido?: string | null;
			ValorBCIcms?: number | null;
			PorcentIcms?: number | null;
			ValorIcms?: number | null;
			ValorBCIcmsSt?: number | null;
			PorcentIcmsSt?: number | null;
			ValorIcmsSt?: number | null;
			ValorBCIpi?: number | null;
			PorcentIpi?: number | null;
			ValorIpi?: number | null;
			IdCstIpi?: number | null;
			PorcentDevol?: number | null;
			IdCstPis?: number | null;
			IdCstCofins?: number | null;
			ValorBCFcpStRet?: number | null;
			PorcentFcpStRet?: number | null;
			ValorFcpStRet?: number | null;
			ValorIcmsSubstituto?: number | null;
			ValorBCEfet?: number | null;
			PorcentIcmsEfet?: number | null;
			PorcentRedBCEfet?: number | null;
			ValorBCStRet?: number | null;
			PorcentSt?: number | null;
			ValorIcmsStRet?: number | null;
			ValorIcmsDeson?: number | null;
			MotDesIcms?: number | null;
			ValorIcmsEfet?: number | null;
			ValorIi?: number | null;
			ValorFcpSt?: number | null;
			ValorIpiDevol?: number | null;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			OutrasOpcoes?: string | null;
		}> | null;
		VolumeNfe?: Array<{
			__typename?: "VolumeNfe";
			Id: number;
			Quantidade: number;
			Especie?: string | null;
			Marca?: string | null;
			Numeracao?: string | null;
			PesoLiquido: number;
			PesoBruto: number;
		} | null> | null;
		PrestacaoNfe?: Array<{
			__typename?: "PrestacaoNfe";
			Id: number;
			IdFormaPgto: number;
			NomeFormaPgto?: string | null;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			NumLancamento: number;
			NumDocumento?: string | null;
			DataVencimento: Date;
			TipoFormaPgto: TipoFormaPagamento;
			TagPgtoNFe: TagPagamentoNFe;
		}> | null;
		VendaNfe?: Array<{ __typename?: "VendaNfe"; Id: number; CodigoVenda: string } | null> | null;
		ReferenciaNfe?: Array<{
			__typename?: "ReferenciaNfe";
			Id: number;
			IdEmpresa: number;
			IdNfe: number;
			IdNfeRef?: number | null;
			IdEstado?: number | null;
			IdFornecedor?: number | null;
			ChaveAcesso?: string | null;
			CnpjEmitente?: string | null;
			Modelo?: ModeloReferencia | null;
			Serie?: number | null;
			NumNf?: number | null;
			DataEmissao?: Date | null;
			Tipo?: TipoReferencia | null;
			IndEmitente?: IndicadorEmitente | null;
			IndOperacao?: IndicadorOperacao | null;
		} | null> | null;
	};
};

export type NfesQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNfes>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type NfesQuery = {
	__typename?: "Query";
	nfes: {
		__typename?: "PaginaNfe";
		Paginacao: { __typename?: "PaginacaoNfe"; TotalRegistros: number; TotalTransmitido: number; TotalRegistrosTransmitido: number; QuantidadeItensTransmitidos: number };
		Data: Array<{
			__typename?: "Nfe";
			Id: number;
			IdNfeRef?: number | null;
			NumNF: number;
			DataEmissao: Date;
			DataSaiEnt: Date;
			Origem: OrigemNFe;
			CFOP: string;
			Serie: number;
			TipoNF: TipoNFe;
			IdPessoa?: number | null;
			NomePessoa?: string | null;
			NomeFantasiaPessoa?: string | null;
			ValorTotalNfe: number;
			NatOperacao?: string | null;
			ChaveAcesso?: string | null;
			ArquivoPdf?: string | null;
			ArquivoXml?: string | null;
			IdCfop: number;
			Status: StatusNfe;
			CFOPOperacao: TipoCfopOperacao;
			DataHoraEmissao: Date;
			DataHoraSaiEnt: Date;
		}>;
	};
};

export type EventosNfeQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroEventosNfe>;
}>;

export type EventosNfeQuery = {
	__typename?: "Query";
	eventosNfe: Array<{
		__typename?: "EventoNfe";
		Id: number;
		TipoEvento: TipoEventoNFe;
		Texto?: string | null;
		Status: StatusEventoNFe;
		IdNFe?: number | null;
		Serie?: number | null;
		NumNF?: number | null;
		DataRegistro: Date;
	}>;
};

export type EventoNfeQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroEventoNfe>;
}>;

export type EventoNfeQuery = {
	__typename?: "Query";
	eventoNfe: {
		__typename?: "EventoNfe";
		Id: number;
		TipoEvento: TipoEventoNFe;
		Texto?: string | null;
		Status: StatusEventoNFe;
		IdNFe?: number | null;
		Serie?: number | null;
		NumNF?: number | null;
		DataRegistro: Date;
	};
};

export type NiveisQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNiveis>;
}>;

export type NiveisQuery = { __typename?: "Query"; niveis: Array<{ __typename?: "Nivel"; Id: number; Descricao: string; Tipo?: Tipo | null; TipoPessoa?: TipoPessoa | null }> };

export type UpdateNfeQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNfe>;
}>;

export type UpdateNfeQuery = {
	__typename?: "Query";
	nfe: {
		__typename?: "Nfe";
		Id: number;
		NumNF: number;
		DataEmissao: Date;
		DataSaiEnt: Date;
		Origem: OrigemNFe;
		CFOP: string;
		Serie: number;
		TipoNF: TipoNFe;
		IdPessoa?: number | null;
		NomePessoa?: string | null;
		ValorTotalNfe: number;
		NatOperacao?: string | null;
		ChaveAcesso?: string | null;
		ArquivoPdf?: string | null;
		ArquivoXml?: string | null;
		IdCfop: number;
		Status: StatusNfe;
	};
};

export type LancamentoVinculadoNfeQueryVariables = Exact<{
	idNfe: Scalars["Int"];
}>;

export type LancamentoVinculadoNfeQuery = { __typename?: "Query"; lancamentoVinculadoNfe: boolean };

export type UltimaRegraAtualizadaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroRegraAtualizada>;
}>;

export type UltimaRegraAtualizadaQuery = { __typename?: "Query"; ultimaRegraAtualizada: Date };

export type EstornoNfeQueryVariables = Exact<{
	idNFe: Scalars["Int"];
}>;

export type EstornoNfeQuery = {
	__typename?: "Query";
	estornoNfe: {
		__typename?: "NfeRetorno";
		IdCfop: number;
		CFOP: string;
		CFOPOperacao: TipoCfopOperacao;
		DescricaoOperacao: string;
		NatOperacao?: string | null;
		DataHoraEmissao: Date;
		DataHoraSaiEnt: Date;
		ModCliente?: ModalidadeCliente | null;
		Status: StatusNfe;
		IdPessoa: number;
		NomePessoa: string;
		NomeFantasiaPessoa?: string | null;
		CpfCnpjPessoa?: string | null;
		IdNfeRef?: number | null;
		TelefonePessoa?: string | null;
		CelularPessoa?: string | null;
		EmailPessoa?: string | null;
		CidadePessoa?: string | null;
		EstadoPessoa?: string | null;
		ChaveAcesso?: string | null;
		RuaPessoa?: string | null;
		BairroPessoa?: string | null;
		NumeroPessoa?: string | null;
		ComplementoPessoa?: string | null;
		ArquivoPdf?: string | null;
		ValorDesconto: number;
		ValorSeguro: number;
		ValorOutro: number;
		ValorFrete: number;
		Frete: TipoFrete;
		IdVendedor?: number | null;
		OutrasOpcoes?: string | null;
		IdTransportadora?: number | null;
		NomeTransportadora?: string | null;
		NomeFantasiaTransportadora?: string | null;
		CpfCnpjTransportadora?: string | null;
		TelefoneTransportadora?: string | null;
		CelularTransportadora?: string | null;
		EmailTransportadora?: string | null;
		CidadeTransportadora?: string | null;
		EstadoTransportadora?: string | null;
		RuaTransportadora?: string | null;
		BairroTransportadora?: string | null;
		NumeroTransportadora?: string | null;
		ComplementoTransportadora?: string | null;
		IdVeiculo?: number | null;
		PlacaVeiculo?: string | null;
		RNTCVeiculo?: string | null;
		PlacaReboqueVeiculo?: string | null;
		RNTCReboqueVeiculo?: string | null;
		ValorIcms: number;
		ValorIcmsSt: number;
		ValorIpi: number;
		ValorFcpSt?: number | null;
		ValorIi: number;
		ObservacaoComplementar?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		TipoNFe: TipoNFe;
		ItemNfe?: Array<{
			__typename?: "ItemNfe";
			IdProduto?: number | null;
			IdUnidade: number;
			IdSituaTribut?: number | null;
			IdCfop: number;
			IdNcm?: number | null;
			IdCest?: number | null;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			Codigo?: string | null;
			Descricao: string;
			CodigoCfop?: string | null;
			Quantidade: number;
			SimboloUnidade: string;
			ValorUnitario: number;
			ValorDesconto: number;
			ValorAcrescimo?: number | null;
			ValorCustoMedioUnitario: number;
			ValorCustoUnitario: number;
			CodBarras?: string | null;
			CodBarrasGrade?: string | null;
			DescricaoComp?: string | null;
			DescricaoGrade?: string | null;
			DescricaoProduto: string;
			CodBenef?: string | null;
			PedidoDeCompra?: string | null;
			NumItemPedido?: string | null;
			ValorBCIcms?: number | null;
			PorcentIcms?: number | null;
			ValorIcms?: number | null;
			ValorBCIcmsSt?: number | null;
			PorcentIcmsSt?: number | null;
			ValorIcmsSt?: number | null;
			ValorBCIpi?: number | null;
			PorcentIpi?: number | null;
			ValorIpi?: number | null;
			IdCstIpi?: number | null;
			PorcentDevol?: number | null;
			IdCstPis?: number | null;
			IdCstCofins?: number | null;
			ValorBCFcpStRet?: number | null;
			PorcentFcpStRet?: number | null;
			ValorFcpStRet?: number | null;
			ValorIcmsSubstituto?: number | null;
			ValorBCEfet?: number | null;
			PorcentIcmsEfet?: number | null;
			PorcentRedBCEfet?: number | null;
			ValorBCStRet?: number | null;
			PorcentSt?: number | null;
			ValorIcmsStRet?: number | null;
			ValorIcmsDeson?: number | null;
			MotDesIcms?: number | null;
			ValorIcmsEfet?: number | null;
			ValorIi?: number | null;
			ValorFcpSt?: number | null;
			ValorIpiDevol?: number | null;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			OutrasOpcoes?: string | null;
		}> | null;
		VolumeNfe?: Array<{
			__typename?: "VolumeNfe";
			Quantidade: number;
			Especie?: string | null;
			Marca?: string | null;
			Numeracao?: string | null;
			PesoLiquido: number;
			PesoBruto: number;
		} | null> | null;
		PrestacaoNfe?: Array<{
			__typename?: "PrestacaoNfe";
			IdFormaPgto: number;
			NomeFormaPgto?: string | null;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			NumLancamento: number;
			NumDocumento?: string | null;
			DataVencimento: Date;
			TipoFormaPgto: TipoFormaPagamento;
			TagPgtoNFe: TagPagamentoNFe;
		}> | null;
		VendaNfe?: Array<{ __typename?: "VendaNfe"; Id: number } | null> | null;
		ReferenciaNfe?: Array<{
			__typename?: "ReferenciaNfe";
			IdEmpresa: number;
			IdNfeRef?: number | null;
			IdEstado?: number | null;
			IdFornecedor?: number | null;
			ChaveAcesso?: string | null;
			CnpjEmitente?: string | null;
			Modelo?: ModeloReferencia | null;
			Serie?: number | null;
			NumNf?: number | null;
			DataEmissao?: Date | null;
			Tipo?: TipoReferencia | null;
			IndEmitente?: IndicadorEmitente | null;
			IndOperacao?: IndicadorOperacao | null;
		} | null> | null;
	};
};

export type DevolucaoNfeVendaQueryVariables = Exact<{
	idNFe: Scalars["Int"];
}>;

export type DevolucaoNfeVendaQuery = {
	__typename?: "Query";
	devolucaoNfeVenda: {
		__typename?: "NfeRetorno";
		IdCfop: number;
		CFOP: string;
		CFOPOperacao: TipoCfopOperacao;
		DescricaoOperacao: string;
		NatOperacao?: string | null;
		DataHoraEmissao: Date;
		DataHoraSaiEnt: Date;
		ModCliente?: ModalidadeCliente | null;
		Status: StatusNfe;
		IdPessoa: number;
		NomePessoa: string;
		NomeFantasiaPessoa?: string | null;
		CpfCnpjPessoa?: string | null;
		IdNfeRef?: number | null;
		TelefonePessoa?: string | null;
		CelularPessoa?: string | null;
		EmailPessoa?: string | null;
		CidadePessoa?: string | null;
		EstadoPessoa?: string | null;
		ChaveAcesso?: string | null;
		RuaPessoa?: string | null;
		BairroPessoa?: string | null;
		NumeroPessoa?: string | null;
		ComplementoPessoa?: string | null;
		ArquivoPdf?: string | null;
		ValorDesconto: number;
		ValorSeguro: number;
		ValorOutro: number;
		ValorFrete: number;
		Frete: TipoFrete;
		IdVendedor?: number | null;
		OutrasOpcoes?: string | null;
		IdTransportadora?: number | null;
		NomeTransportadora?: string | null;
		NomeFantasiaTransportadora?: string | null;
		CpfCnpjTransportadora?: string | null;
		TelefoneTransportadora?: string | null;
		CelularTransportadora?: string | null;
		EmailTransportadora?: string | null;
		CidadeTransportadora?: string | null;
		EstadoTransportadora?: string | null;
		RuaTransportadora?: string | null;
		BairroTransportadora?: string | null;
		NumeroTransportadora?: string | null;
		ComplementoTransportadora?: string | null;
		IdVeiculo?: number | null;
		PlacaVeiculo?: string | null;
		RNTCVeiculo?: string | null;
		PlacaReboqueVeiculo?: string | null;
		RNTCReboqueVeiculo?: string | null;
		ValorIcms: number;
		ValorIcmsSt: number;
		ValorIpi: number;
		ValorFcpSt?: number | null;
		ValorIi: number;
		ObservacaoComplementar?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		TipoNFe: TipoNFe;
		ItemNfe?: Array<{
			__typename?: "ItemNfe";
			IdProduto?: number | null;
			IdUnidade: number;
			IdSituaTribut?: number | null;
			IdCfop: number;
			IdNcm?: number | null;
			IdCest?: number | null;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			Codigo?: string | null;
			Descricao: string;
			CodigoCfop?: string | null;
			Quantidade: number;
			SimboloUnidade: string;
			ValorUnitario: number;
			ValorDesconto: number;
			ValorAcrescimo?: number | null;
			ValorCustoMedioUnitario: number;
			ValorCustoUnitario: number;
			CodBarras?: string | null;
			CodBarrasGrade?: string | null;
			DescricaoComp?: string | null;
			DescricaoGrade?: string | null;
			DescricaoProduto: string;
			CodBenef?: string | null;
			PedidoDeCompra?: string | null;
			NumItemPedido?: string | null;
			ValorBCIcms?: number | null;
			PorcentIcms?: number | null;
			ValorIcms?: number | null;
			ValorBCIcmsSt?: number | null;
			PorcentIcmsSt?: number | null;
			ValorIcmsSt?: number | null;
			ValorBCIpi?: number | null;
			PorcentIpi?: number | null;
			ValorIpi?: number | null;
			IdCstIpi?: number | null;
			PorcentDevol?: number | null;
			IdCstPis?: number | null;
			IdCstCofins?: number | null;
			ValorBCFcpStRet?: number | null;
			PorcentFcpStRet?: number | null;
			ValorFcpStRet?: number | null;
			ValorIcmsSubstituto?: number | null;
			ValorBCEfet?: number | null;
			PorcentIcmsEfet?: number | null;
			PorcentRedBCEfet?: number | null;
			ValorBCStRet?: number | null;
			PorcentSt?: number | null;
			ValorIcmsStRet?: number | null;
			ValorIcmsDeson?: number | null;
			MotDesIcms?: number | null;
			ValorIcmsEfet?: number | null;
			ValorIi?: number | null;
			ValorFcpSt?: number | null;
			ValorIpiDevol?: number | null;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			OutrasOpcoes?: string | null;
		}> | null;
		VolumeNfe?: Array<{
			__typename?: "VolumeNfe";
			Quantidade: number;
			Especie?: string | null;
			Marca?: string | null;
			Numeracao?: string | null;
			PesoLiquido: number;
			PesoBruto: number;
		} | null> | null;
		PrestacaoNfe?: Array<{
			__typename?: "PrestacaoNfe";
			IdFormaPgto: number;
			NomeFormaPgto?: string | null;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			NumLancamento: number;
			NumDocumento?: string | null;
			DataVencimento: Date;
			TipoFormaPgto: TipoFormaPagamento;
			TagPgtoNFe: TagPagamentoNFe;
		}> | null;
		VendaNfe?: Array<{ __typename?: "VendaNfe"; Id: number } | null> | null;
		ReferenciaNfe?: Array<{
			__typename?: "ReferenciaNfe";
			IdEmpresa: number;
			IdNfeRef?: number | null;
			IdEstado?: number | null;
			IdFornecedor?: number | null;
			ChaveAcesso?: string | null;
			CnpjEmitente?: string | null;
			Modelo?: ModeloReferencia | null;
			Serie?: number | null;
			NumNf?: number | null;
			DataEmissao?: Date | null;
			Tipo?: TipoReferencia | null;
			IndEmitente?: IndicadorEmitente | null;
			IndOperacao?: IndicadorOperacao | null;
		} | null> | null;
	};
};

export type EnviaEmailNfeSubscriptionVariables = Exact<{
	idNFe: Scalars["Int"];
}>;

export type EnviaEmailNfeSubscription = { __typename?: "Subscription"; enviaEmailNFe?: RetornoTarefa | null };

export type AguardaEventoNfeSubscriptionVariables = Exact<{
	correlationId: Scalars["String"];
}>;

export type AguardaEventoNfeSubscription = { __typename?: "Subscription"; aguardaEventoNfe: RetornoTarefa };

export type CadastraIntermediadorMutationVariables = Exact<{
	dados: InputCadastrarIntermediador;
}>;

export type CadastraIntermediadorMutation = { __typename?: "Mutation"; cadastraIntermediador: RetornoPadrao };

export type EditaIntermediadorMutationVariables = Exact<{
	dados: InputEditarIntermediador;
}>;

export type EditaIntermediadorMutation = { __typename?: "Mutation"; editaIntermediador: RetornoPadrao };

export type ToggleStatusIntermediadorMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusIntermediadorMutation = { __typename?: "Mutation"; editaIntermediador: RetornoPadrao };

export type IntermediadoresQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroIntermediadores>;
}>;

export type IntermediadoresQuery = {
	__typename?: "Query";
	intermediadores: Array<{ __typename?: "Intermediador"; Id: number; Nome: string; Cnpj?: string | null; Identificador?: string | null; Status: StatusEnum }>;
};

export type IntermediadorQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type IntermediadorQuery = {
	__typename?: "Query";
	intermediador: { __typename?: "Intermediador"; Id: number; Nome: string; Cnpj?: string | null; Identificador?: string | null; Status: StatusEnum };
};

export type GerarRegistrosPafSubscriptionVariables = Exact<{
	filtro: InputFiltroRegistrosPaf;
}>;

export type GerarRegistrosPafSubscription = { __typename?: "Subscription"; gerarRegistrosPaf?: RetornoTarefa | null };

export type CadastraCestMutationVariables = Exact<{
	dados: InputCadastrarCest;
}>;

export type CadastraCestMutation = { __typename?: "Mutation"; cadastraCest: RetornoPadrao };

export type EditaCestMutationVariables = Exact<{
	dados: InputEditarCest;
}>;

export type EditaCestMutation = { __typename?: "Mutation"; editaCest: RetornoPadrao };

export type ToggleStatusCestMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusCestMutation = { __typename?: "Mutation"; editaCest: RetornoPadrao };

export type CestsQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroCests>;
}>;

export type CestsQuery = {
	__typename?: "Query";
	cests: {
		__typename?: "PaginaCest";
		Data: Array<{ __typename?: "Cest"; CodCest: string; CodNcm?: string | null; Descricao?: string | null; Id: number; IdOriginal: number; Item?: string | null; Status: StatusEnum }>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type CestQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type CestQuery = {
	__typename?: "Query";
	cest: { __typename?: "Cest"; CodCest: string; CodNcm?: string | null; Descricao?: string | null; Id: number; IdOriginal: number; Item?: string | null; Status: StatusEnum };
};

export type CestByCodigoQueryVariables = Exact<{
	Codigo: Scalars["String"];
}>;

export type CestByCodigoQuery = { __typename?: "Query"; cest: { __typename?: "Cest"; Id: number; CodCest: string; IdOriginal: number } };

export type CadastraCfopMutationVariables = Exact<{
	dados: InputCadastrarCfop;
}>;

export type CadastraCfopMutation = { __typename?: "Mutation"; cadastraCfop: RetornoPadrao };

export type EditaCfopMutationVariables = Exact<{
	dados: InputEditarCfop;
}>;

export type EditaCfopMutation = { __typename?: "Mutation"; editaCfop: RetornoPadrao };

export type ToggleStatusCfopMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusCfopMutation = { __typename?: "Mutation"; editaCfop: RetornoPadrao };

export type CfopsQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroCfops>;
}>;

export type CfopsQuery = {
	__typename?: "Query";
	cfops: {
		__typename?: "PaginaCfop";
		Data: Array<{
			__typename?: "Cfop";
			Codigo: string;
			CreditoIcmsCompra: StatusEnum;
			Descricao: string;
			DescricaoOperacao: string;
			Id: number;
			IdOriginal: number;
			IdOperacao: TipoCfopOperacao;
			PrecoPadrao: PrecoPadraoCfop;
			MovimentaEstoque: StatusEnum;
			MovimentaFinanceiro: StatusEnum;
			Status: StatusEnum;
			TipoNFe: TipoNFe;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type CfopQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCfop>;
}>;

export type CfopQuery = {
	__typename?: "Query";
	cfop: {
		__typename?: "Cfop";
		Codigo: string;
		CreditoIcmsCompra: StatusEnum;
		Descricao: string;
		DescricaoOperacao: string;
		Id: number;
		IdOriginal: number;
		PrecoPadrao: PrecoPadraoCfop;
		IdOperacao: TipoCfopOperacao;
		MovimentaEstoque: StatusEnum;
		MovimentaFinanceiro: StatusEnum;
		Status: StatusEnum;
		TipoNFe: TipoNFe;
	};
};

export type CfopOperacoesQueryVariables = Exact<{ [key: string]: never }>;

export type CfopOperacoesQuery = { __typename?: "Query"; cfopOperacoes: Array<{ __typename?: "CfopOperacao"; Descricao: string; Id: number; Status: StatusEnum }> };

export type CfopByCodigoQueryVariables = Exact<{
	Codigo: Scalars["String"];
	Tipo?: InputMaybe<Array<InputMaybe<TipoNFe>> | InputMaybe<TipoNFe>>;
}>;

export type CfopByCodigoQuery = {
	__typename?: "Query";
	cfop: {
		__typename?: "Cfop";
		Id: number;
		Codigo: string;
		IdOriginal: number;
		Descricao: string;
		DescricaoOperacao: string;
		TipoNFe: TipoNFe;
		MovimentaEstoque: StatusEnum;
		IdOperacao: TipoCfopOperacao;
		MovimentaFinanceiro: StatusEnum;
		PrecoPadrao: PrecoPadraoCfop;
	};
};

export type CadastraNcmMutationVariables = Exact<{
	dados: InputCadastrarNcm;
}>;

export type CadastraNcmMutation = { __typename?: "Mutation"; cadastraNcm: RetornoPadrao };

export type EditaNcmMutationVariables = Exact<{
	dados: InputEditarNcm;
}>;

export type EditaNcmMutation = { __typename?: "Mutation"; editaNcm: RetornoPadrao };

export type ToggleStatusNcmMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusNcmMutation = { __typename?: "Mutation"; editaNcm: RetornoPadrao };

export type NcmsQueryVariables = Exact<{
	paginacao?: InputMaybe<InputPaginacao>;
	filtro?: InputMaybe<InputFiltroNcms>;
}>;

export type NcmsQuery = {
	__typename?: "Query";
	ncms: {
		__typename?: "PaginaNcm";
		Data: Array<{
			__typename?: "Ncm";
			Id: number;
			Status: StatusEnum;
			CodNCM: string;
			ExtIpi?: string | null;
			AliqEstadual: number;
			AliqMunicipal: number;
			AliqNacional: number;
			AliqImportacao: number;
			Uf: string;
			Tabela: TabelaNcm;
			Descricao: string;
			IdOriginal: number;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type NcmQueryVariables = Exact<{
	Id: Scalars["Int"];
	ExtIpi?: InputMaybe<Scalars["String"]>;
}>;

export type NcmQuery = {
	__typename?: "Query";
	ncm: {
		__typename?: "Ncm";
		Id: number;
		IdOriginal: number;
		IdEstado: number;
		IdEmpresa?: number | null;
		CodNCM: string;
		ExtIpi?: string | null;
		AliqEstadual: number;
		AliqMunicipal: number;
		AliqNacional: number;
		AliqImportacao: number;
		Descricao: string;
		DataAlteracao: Date;
		Status: StatusEnum;
		Uf: string;
		Tabela: TabelaNcm;
	};
};

export type NcmByCodigoQueryVariables = Exact<{
	Codigo: Scalars["String"];
}>;

export type NcmByCodigoQuery = { __typename?: "Query"; ncm: { __typename?: "Ncm"; Id: number; CodNCM: string; ExtIpi?: string | null; IdOriginal: number } };

export type NcmsByEstadoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroNcms>;
}>;

export type NcmsByEstadoQuery = {
	__typename?: "Query";
	ncms: { __typename?: "PaginaNcm"; Data: Array<{ __typename?: "Ncm"; Id: number; CodNCM: string; Descricao: string; ExtIpi?: string | null; Uf: string; IdOriginal: number }> };
};

export type CadastraRegraTributacaoMutationVariables = Exact<{
	dados: InputCadastrarRegraTributacao;
}>;

export type CadastraRegraTributacaoMutation = { __typename?: "Mutation"; cadastraRegraTributacao: RetornoPadrao };

export type EditaRegraTributacaoMutationVariables = Exact<{
	dados: InputEditarRegraTributacao;
}>;

export type EditaRegraTributacaoMutation = { __typename?: "Mutation"; editaRegraTributacao: RetornoPadrao };

export type ToggleStatusRegraTributacaoMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusRegraTributacaoMutation = { __typename?: "Mutation"; editaRegraTributacao: RetornoPadrao };

export type RegrasTributacoesQueryVariables = Exact<{ [key: string]: never }>;

export type RegrasTributacoesQuery = {
	__typename?: "Query";
	regrasTributacoes: Array<{
		__typename?: "RegraTributacao";
		Id: number;
		IdUFCondicao?: number | null;
		UFCondicaoDescricao?: string | null;
		TipoUFCondicao?: TipoUfDestino | null;
		IdCfopCondicao?: number | null;
		CfopCondicaoCodigo?: string | null;
		CfopCondicaoDescricao?: string | null;
		RegimeTributarioCondicao?: RegimeTributario | null;
		RegimeTributarioICMSCondicao?: RegimeTributarioIcms | null;
		Tipo: TipoRegra;
		Nome?: string | null;
		TipoConsumidorCondicao?: ModalidadeCliente | null;
		IdPessoaCondicao?: number | null;
		PessoaCondicaoNome?: string | null;
		PessoaCondicaoNomeFantasia?: string | null;
		IdCfopAcao?: number | null;
		CfopAcaoCodigo?: string | null;
		CfopAcaoDescricao?: string | null;
		IdCestAcao?: number | null;
		CestAcaoCodigo?: string | null;
		CestAcaoDescricao?: string | null;
		IdSituaTributAcao?: number | null;
		SituaTributAcaoCodigo?: string | null;
		SituaTributAcaoDescricao?: string | null;
		DestacaDifalAcao?: RespostaEnum | null;
		AliquotaIcmsAcao?: number | null;
		ReducaoIcmsAcao?: number | null;
		AliquotaMvaAcao?: number | null;
		ReducaoMvaAcao?: number | null;
		AliquotaFcpAcao?: number | null;
		AliquotaPisAcao?: number | null;
		IdCstPisAcao?: number | null;
		CstPisAcaoCodigo?: string | null;
		CstPisAcaoDescricao?: string | null;
		AliquotaCofinsAcao?: number | null;
		IdCstCofinsAcao?: number | null;
		CstCofinsAcaoCodigo?: string | null;
		CstCofinsAcaoDescricao?: string | null;
		AliquotaIpiAcao?: number | null;
		IdCstIpiAcao?: number | null;
		CstIpiAcaoCodigo?: string | null;
		CstIpiAcaoDescricao?: string | null;
		ObservacaoNFAcao?: string | null;
		TipoCadastro?: TipoCadastroRegra | null;
		Status: StatusEnum;
		Ncms?: Array<{ __typename?: "NcmRegraTributacao"; Id: number; IdRegraTributacao: number; IdNcm: number; CodNCM: string; ExtIpi?: string | null } | null> | null;
	}>;
};

export type RegraTributacaoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroRegraTributacao>;
}>;

export type RegraTributacaoQuery = {
	__typename?: "Query";
	regraTributacao: {
		__typename?: "RegraTributacao";
		Id: number;
		IdUFCondicao?: number | null;
		UFCondicaoDescricao?: string | null;
		TipoUFCondicao?: TipoUfDestino | null;
		IdCfopCondicao?: number | null;
		CfopCondicaoCodigo?: string | null;
		CfopCondicaoDescricao?: string | null;
		RegimeTributarioCondicao?: RegimeTributario | null;
		RegimeTributarioICMSCondicao?: RegimeTributarioIcms | null;
		Tipo: TipoRegra;
		Nome?: string | null;
		TipoConsumidorCondicao?: ModalidadeCliente | null;
		IdPessoaCondicao?: number | null;
		PessoaCondicaoNome?: string | null;
		PessoaCondicaoNomeFantasia?: string | null;
		IdCfopAcao?: number | null;
		CfopAcaoCodigo?: string | null;
		CfopAcaoDescricao?: string | null;
		IdCestAcao?: number | null;
		CestAcaoCodigo?: string | null;
		CestAcaoDescricao?: string | null;
		IdSituaTributAcao?: number | null;
		SituaTributAcaoCodigo?: string | null;
		SituaTributAcaoDescricao?: string | null;
		AliquotaIcmsAcao?: number | null;
		ReducaoIcmsAcao?: number | null;
		AliquotaMvaAcao?: number | null;
		DestacaDifalAcao?: RespostaEnum | null;
		ReducaoMvaAcao?: number | null;
		AliquotaFcpAcao?: number | null;
		AliquotaPisAcao?: number | null;
		IdCstPisAcao?: number | null;
		CstPisAcaoCodigo?: string | null;
		CstPisAcaoDescricao?: string | null;
		AliquotaCofinsAcao?: number | null;
		IdCstCofinsAcao?: number | null;
		CstCofinsAcaoCodigo?: string | null;
		CstCofinsAcaoDescricao?: string | null;
		AliquotaIpiAcao?: number | null;
		IdCstIpiAcao?: number | null;
		CstIpiAcaoCodigo?: string | null;
		CstIpiAcaoDescricao?: string | null;
		ObservacaoNFAcao?: string | null;
		Status: StatusEnum;
		Ncms?: Array<{ __typename?: "NcmRegraTributacao"; Id: number; IdRegraTributacao: number; IdNcm: number; CodNCM: string; ExtIpi?: string | null } | null> | null;
	};
};

export type RegraTributacaoByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type RegraTributacaoByIdQuery = {
	__typename?: "Query";
	regraTributacao: {
		__typename?: "RegraTributacao";
		Id: number;
		IdUFCondicao?: number | null;
		TipoUFCondicao?: TipoUfDestino | null;
		IdCfopCondicao?: number | null;
		RegimeTributarioCondicao?: RegimeTributario | null;
		RegimeTributarioICMSCondicao?: RegimeTributarioIcms | null;
		TipoConsumidorCondicao?: ModalidadeCliente | null;
		IdPessoaCondicao?: number | null;
		IdCfopAcao?: number | null;
		IdCestAcao?: number | null;
		IdSituaTributAcao?: number | null;
		AliquotaIcmsAcao?: number | null;
		ReducaoIcmsAcao?: number | null;
		AliquotaMvaAcao?: number | null;
		ReducaoMvaAcao?: number | null;
		AliquotaFcpAcao?: number | null;
		AliquotaPisAcao?: number | null;
		IdCstPisAcao?: number | null;
		AliquotaCofinsAcao?: number | null;
		IdCstCofinsAcao?: number | null;
		AliquotaIpiAcao?: number | null;
		IdCstIpiAcao?: number | null;
		ObservacaoNFAcao?: string | null;
		Status: StatusEnum;
		Ncms?: Array<{ __typename?: "NcmRegraTributacao"; Id: number; IdRegraTributacao: number; IdNcm: number; CodNCM: string; ExtIpi?: string | null } | null> | null;
	};
};

export type LoginMutationVariables = Exact<{
	login: Scalars["String"];
	senha: Scalars["String"];
	correlationId?: InputMaybe<Scalars["String"]>;
}>;

export type LoginMutation = { __typename?: "Mutation"; login?: RetornoPadrao | null };

export type EnviarEmailMutationVariables = Exact<{
	dados: InputEsqueciSenha;
}>;

export type EnviarEmailMutation = { __typename?: "Mutation"; esqueciSenha: RetornoPadrao };

export type AlterarSenhaMutationVariables = Exact<{
	dados: InputAlterarSenha;
}>;

export type AlterarSenhaMutation = { __typename?: "Mutation"; alterarSenha: RetornoPadrao };

export type EditarConfiguracoesMutationVariables = Exact<{
	dados: Array<InputCadastrarConfiguracaoEmpresa> | InputCadastrarConfiguracaoEmpresa;
}>;

export type EditarConfiguracoesMutation = { __typename?: "Mutation"; atualizaConfiguracoesEmpresa: RetornoPadrao };

export type EditarEmpresaMutationVariables = Exact<{
	dados: InputEditarEmpresa;
}>;

export type EditarEmpresaMutation = { __typename?: "Mutation"; editaEmpresa: RetornoPadrao };

export type EnviarCertificadoMutationVariables = Exact<{
	dados: InputEnviaCertificado;
}>;

export type EnviarCertificadoMutation = { __typename?: "Mutation"; enviarCertificado: RetornoPadrao };

export type EnviarCsvMutationVariables = Exact<{
	dados: InputEnviaCsv;
}>;

export type EnviarCsvMutation = { __typename?: "Mutation"; enviarCsv: Array<any | null> };

export type ExcluirCertificadoMutationVariables = Exact<{ [key: string]: never }>;

export type ExcluirCertificadoMutation = { __typename?: "Mutation"; excluirCertificado: RetornoPadrao };

export type CadastraComissoesFormaPgtoMutationVariables = Exact<{
	dados: InputCadastrarComissaoFormaPgto;
}>;

export type CadastraComissoesFormaPgtoMutation = { __typename?: "Mutation"; cadastraComissoesFormaPgto: RetornoPadrao };

export type EditaComissoesFormaPgtoMutationVariables = Exact<{
	dados: InputEditarComissaoFormaPgto;
}>;

export type EditaComissoesFormaPgtoMutation = { __typename?: "Mutation"; editaComissoesFormaPgto?: RetornoPadrao | null };

export type ComissoesFormaPgtoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroComissoesFormaPgto>;
}>;

export type ComissoesFormaPgtoQuery = {
	__typename?: "Query";
	comissoesFormaPgto: Array<{
		__typename?: "ComissaoFormaPgto";
		Id?: number | null;
		TipoFormaPgto?: TipoFormaPagamento | null;
		IdColaborador?: number | null;
		IdFormaPgto?: number | null;
		Valor?: number | null;
		Status?: StatusEnum | null;
	} | null>;
};

export type CadastraPdvMutationVariables = Exact<{
	dados: InputCadastrarPdv;
}>;

export type CadastraPdvMutation = { __typename?: "Mutation"; cadastraPdv: RetornoPadrao };

export type EditaPdvMutationVariables = Exact<{
	dados: InputEditarPdv;
}>;

export type EditaPdvMutation = { __typename?: "Mutation"; editaPdv: RetornoPadrao };

export type ToggleStatusPdvMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusPdvMutation = { __typename?: "Mutation"; editaPdv: RetornoPadrao };

export type ResetaDadosDemonstrativosMutationVariables = Exact<{
	dados: InputResetarDadosEmpresa;
}>;

export type ResetaDadosDemonstrativosMutation = { __typename?: "Mutation"; resetaDadosDemonstrativos: RetornoPadrao };

export type EmpresaQueryVariables = Exact<{
	skipCidade?: Scalars["Boolean"];
}>;

export type EmpresaQuery = {
	__typename?: "Query";
	empresa: {
		__typename?: "Empresa";
		Id: number;
		CpfCnpj: string;
		Nome: string;
		NomeFantasia?: string | null;
		NomeFilial?: string | null;
		Responsavel?: string | null;
		InscricaoEstadual?: string | null;
		InscricaoMunicipal?: string | null;
		Cep?: string | null;
		IdEstado?: number | null;
		IdCidade?: number | null;
		DataCadastro: Date;
		Logradouro?: string | null;
		Bairro?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		EmailPrincipal?: string | null;
		EmailAdministrativo?: string | null;
		EmailFinanceiro?: string | null;
		RegimeTributario?: RegimeTributario | null;
		RegimeTributarioICMS?: RegimeTributarioIcms | null;
		Observacao?: string | null;
		LogoEmpresa?: string | null;
		PossuiFilial: boolean;
		ProdutoEmpresa: ProdutoEmpresa;
		PlanoContratado: PlanoContratado;
		CidadeNome?: string | null;
		Uf?: string | null;
		Configuracoes: Array<{ __typename?: "ConfiguracaoEmpresa"; Id?: number | null; Valor?: string | null; IdConfig: Configuracoes; Nome: string; NomeLegivel: string }>;
	};
};

export type DataEmpresaRelatorioQueryVariables = Exact<{ [key: string]: never }>;

export type DataEmpresaRelatorioQuery = {
	__typename?: "Query";
	empresa: {
		__typename?: "Empresa";
		Id: number;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj: string;
		LogoEmpresa?: string | null;
		CidadeNome?: string | null;
		Uf?: string | null;
		Logradouro?: string | null;
		Bairro?: string | null;
		Cep?: string | null;
		NumEndereco?: string | null;
		Complemento?: string | null;
		Celular?: string | null;
		Telefone?: string | null;
		EmailPrincipal?: string | null;
	};
};

export type UsuarioAtualQueryVariables = Exact<{ [key: string]: never }>;

export type UsuarioAtualQuery = {
	__typename?: "Query";
	usuarioAtual: {
		__typename?: "Colaborador";
		IdPessoa: number;
		IdEmpresa: number;
		Nome: string;
		NomeFantasia?: string | null;
		PerfilAcesso: PerfilAcessoColaborador;
		Permissoes?: Array<{ __typename?: "PermissaoColaborador"; Id: number; NomePermissao: string; OpcoesPermissaoLista: Array<string> } | null> | null;
	};
};

export type CepQueryVariables = Exact<{
	filtro: InputFiltroCep;
}>;

export type CepQuery = {
	__typename?: "Query";
	cep: { __typename?: "Cep"; Bairro?: string | null; Cidade?: string | null; Complemento?: string | null; IdCidade: number; IdEstado: number; Uf?: string | null; Logradouro?: string | null };
};

export type RefreshTokenQueryVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenQuery = { __typename?: "Query"; refreshToken?: RetornoPadrao | null };

export type PaisesQueryVariables = Exact<{ [key: string]: never }>;

export type PaisesQuery = { __typename?: "Query"; paises: Array<{ __typename?: "Pais"; Id: number; Nome: string }> };

export type EstadosQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroEstado>;
}>;

export type EstadosQuery = { __typename?: "Query"; estados: Array<{ __typename?: "Estado"; Id: number; Nome: string; UF: string; CodUfIBGE: number }> };

export type CidadesByIdEstadoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCidades>;
}>;

export type CidadesByIdEstadoQuery = { __typename?: "Query"; cidades: Array<{ __typename?: "Cidade"; Id: number; Nome: string }> };

export type CidadesByCodIbgeQueryVariables = Exact<{
	IdIbge: Scalars["String"];
}>;

export type CidadesByCodIbgeQuery = { __typename?: "Query"; cidades: Array<{ __typename?: "Cidade"; Id: number; Nome: string }> };

export type BuscarEmpresaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroEmpresa>;
}>;

export type BuscarEmpresaQuery = {
	__typename?: "Query";
	buscarEmpresa: {
		__typename?: "Empresa";
		Id: number;
		Nome: string;
		NomeFantasia?: string | null;
		CpfCnpj: string;
		Logradouro?: string | null;
		Bairro?: string | null;
		Complemento?: string | null;
		NumEndereco?: string | null;
		Telefone?: string | null;
		Celular?: string | null;
		Celular2?: string | null;
		DataCadastro: Date;
		CidadeNome?: string | null;
		Uf?: string | null;
	};
};

export type PdvsQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroPdvs>;
}>;

export type PdvsQuery = {
	__typename?: "Query";
	pdvs: Array<{ __typename?: "Pdv"; Id: number; IdEmpresa: number; IdContaFinanceira: number; Nome: string; Status: StatusEnum; NomeContaFinanceira: string }>;
};

export type PdvQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroPdv>;
}>;

export type PdvQuery = { __typename?: "Query"; pdv: { __typename?: "Pdv"; Id: number; IdEmpresa: number; IdContaFinanceira: number; Nome: string; Status: StatusEnum; NomeContaFinanceira: string } };

export type PdvByIdQueryVariables = Exact<{
	Id: Scalars["Int"];
}>;

export type PdvByIdQuery = {
	__typename?: "Query";
	pdv: { __typename?: "Pdv"; Id: number; IdEmpresa: number; IdContaFinanceira: number; Nome: string; Status: StatusEnum; NomeContaFinanceira: string };
};

export type PermissaoColaboradorAtualizadaSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PermissaoColaboradorAtualizadaSubscription = {
	__typename?: "Subscription";
	permissaoColaboradorAtualizada?: Array<{ __typename?: "PermissaoColaborador"; Id: number; NomePermissao: string; OpcoesPermissaoLista: Array<string> }> | null;
};

export type BaixaCreditoMutationVariables = Exact<{
	dados: InputBaixaCredito;
}>;

export type BaixaCreditoMutation = { __typename?: "Mutation"; baixaCredito: RetornoPadrao };

export type CreditosQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroQueryCreditos>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type CreditosQuery = {
	__typename?: "Query";
	creditos: {
		__typename?: "PaginaCredito";
		Data: Array<{
			__typename?: "Credito";
			Id: number;
			IdPessoa?: number | null;
			IdUsuario: number;
			DataLancamento: Date;
			ValorTotal: number;
			ValorAberto: number;
			ValorUtilizado: number;
			IdContaFinanceiro?: number | null;
			CodigoFinanceiro?: string | null;
			Historico?: string | null;
			PessoaNome?: string | null;
			UsuarioNome: string;
			Status?: StatusCredito | null;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type CreditoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroQueryCredito>;
}>;

export type CreditoQuery = {
	__typename?: "Query";
	credito: {
		__typename?: "Credito";
		Id: number;
		IdPessoa?: number | null;
		IdUsuario: number;
		DataLancamento: Date;
		ValorTotal: number;
		ValorAberto: number;
		ValorUtilizado: number;
		IdContaFinanceiro?: number | null;
		CodigoFinanceiro?: string | null;
		Historico?: string | null;
		PessoaNome?: string | null;
		UsuarioNome: string;
		Status?: StatusCredito | null;
		Hash: string;
	};
};

export type CreditoClienteQueryVariables = Exact<{
	filtro: InputFiltroQueryCreditoCliente;
}>;

export type CreditoClienteQuery = { __typename?: "Query"; creditoCliente?: number | null };

export type LancamentoCreditoMutationVariables = Exact<{
	dados: InputLancamentoCredito;
}>;

export type LancamentoCreditoMutation = { __typename?: "Mutation"; lancamentoCredito: RetornoPadrao };

export type CadastraMetaMutationVariables = Exact<{
	dados: InputCadastrarMeta;
}>;

export type CadastraMetaMutation = { __typename?: "Mutation"; cadastraMeta: RetornoPadrao };

export type EditaMetaMutationVariables = Exact<{
	dados: InputEditarMeta;
}>;

export type EditaMetaMutation = { __typename?: "Mutation"; editaMeta?: RetornoPadrao | null };

export type ToggleStatusMetaMutationVariables = Exact<{
	id: Scalars["Int"];
	status: Scalars["Status"];
}>;

export type ToggleStatusMetaMutation = { __typename?: "Mutation"; editaMeta?: RetornoPadrao | null };

export type MetaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroMeta>;
}>;

export type MetaQuery = {
	__typename?: "Query";
	meta: {
		__typename?: "Meta";
		Id: number;
		IdVendedor?: number | null;
		NomeVendedor?: string | null;
		NomeMeta: string;
		ValorMeta: number;
		TipoBonificacao: TipoBonificacao;
		ValorBonificacao: number;
		Status: StatusEnum;
	};
};

export type MetasQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroMetas>;
}>;

export type MetasQuery = {
	__typename?: "Query";
	metas: Array<{
		__typename?: "Meta";
		Id: number;
		IdVendedor?: number | null;
		NomeVendedor?: string | null;
		NomeMeta: string;
		ValorMeta: number;
		TipoBonificacao: TipoBonificacao;
		ValorBonificacao: number;
		Status: StatusEnum;
	}>;
};

export type CadastraPedidoCondicionalMutationVariables = Exact<{
	dados: InputCadastrarCondicional;
}>;

export type CadastraPedidoCondicionalMutation = { __typename?: "Mutation"; cadastraCondicional: RetornoPadrao };

export type EditaPedidoCondicionalMutationVariables = Exact<{
	dados: InputEditarCondicional;
}>;

export type EditaPedidoCondicionalMutation = { __typename?: "Mutation"; editaCondicional: RetornoPadrao };

export type RetornoCondicionalMutationVariables = Exact<{
	dados: InputRetornoCondicional;
}>;

export type RetornoCondicionalMutation = { __typename?: "Mutation"; retornoCondicional: RetornoPadrao };

export type ConverteCondicionalEmVendaMutationVariables = Exact<{
	dados: InputConverterCondicionalEmVenda;
}>;

export type ConverteCondicionalEmVendaMutation = { __typename?: "Mutation"; converteCondicionalEmVenda: RetornoPadrao };

export type PedidoCondicionalQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCondicional>;
}>;

export type PedidoCondicionalQuery = {
	__typename?: "Query";
	condicional: {
		__typename?: "Condicional";
		Id: number;
		IdEmpresa: number;
		IdCliente: number;
		IdVendedor?: number | null;
		IdUsuarioSaida: number;
		IdUsuarioRetorno?: number | null;
		Codigo: string;
		Status: StatusCondicional;
		Data: Date;
		DataPrevRetorno?: Date | null;
		DataRetorno?: Date | null;
		Observacoes?: string | null;
		ValorDesconto: number;
		ValorFrete: number;
		ValorTotal: number;
		ValorTotalPrazo: number;
		DataCadastro: Date;
		DataAlteracao: Date;
		HashLink?: string | null;
		IdVenda?: number | null;
		NomeCliente?: string | null;
		NomeFantasiaCliente?: string | null;
		CpfCnpjCliente?: string | null;
		TelefoneCliente?: string | null;
		CelularCliente?: string | null;
		CidadeCliente?: string | null;
		UFCliente?: string | null;
		LogradouroCliente?: string | null;
		NumeroCliente?: string | null;
		ComplementoCliente?: string | null;
		BairroCliente?: string | null;
		CepCliente?: string | null;
		EmailCliente?: string | null;
		NomeVendedor?: string | null;
		DocFiscal?: string | null;
		ItemCondicional: Array<{
			__typename?: "ItemCondicional";
			Id: number;
			IdEmpresa: number;
			IdCondicional: number;
			IdProduto: number;
			IdUnidade: number;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			Codigo: string;
			CodBarras?: string | null;
			Descricao: string;
			DescricaoProduto: string;
			DescricaoGrade?: string | null;
			DescricaoComp?: string | null;
			CodigoSecundario?: string | null;
			CodigoGrade?: string | null;
			CodBarrasGrade?: string | null;
			Status: StatusItemCondicional;
			ValorDesconto: number;
			ValorAcrescimo: number;
			SimboloUnidade: string;
			Quantidade: number;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			ValorUnitario: number;
			ValorSubTotal: number;
			ValorTotalItem: number;
			ValorUnitarioPrazo: number;
			ValorSubTotalPrazo: number;
			ValorTotalItemPrazo: number;
			QtdDevolvida?: number | null;
			QtdFaturada?: number | null;
			ValorCustoMedioUnitario: number;
			ValorCustoUnitario: number;
			DataCadastro: Date;
			DataAlteracao: Date;
		}>;
	};
};

export type PedidosCondicionalQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroCondicionais>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type PedidosCondicionalQuery = {
	__typename?: "Query";
	condicionais: {
		__typename?: "PaginaCondicional";
		Paginacao: {
			__typename?: "PaginacaoCondicional";
			TotalRegistros: number;
			TotalValorCondicional: number;
			TotalValorFaturado: number;
			TotalQtdItensCondicional: number;
			TotalQtdCondicionais: number;
		};
		Data: Array<{
			__typename?: "Condicional";
			Id: number;
			IdEmpresa: number;
			IdCliente: number;
			IdVendedor?: number | null;
			IdUsuarioSaida: number;
			IdUsuarioRetorno?: number | null;
			Codigo: string;
			Status: StatusCondicional;
			Data: Date;
			DataPrevRetorno?: Date | null;
			DataRetorno?: Date | null;
			Observacoes?: string | null;
			ValorDesconto: number;
			ValorFrete: number;
			ValorTotal: number;
			ValorTotalPrazo: number;
			DataCadastro: Date;
			DataAlteracao: Date;
			HashLink?: string | null;
			IdVenda?: number | null;
			NomeCliente?: string | null;
			NomeFantasiaCliente?: string | null;
			CpfCnpjCliente?: string | null;
			TelefoneCliente?: string | null;
			CelularCliente?: string | null;
			CidadeCliente?: string | null;
			UFCliente?: string | null;
			LogradouroCliente?: string | null;
			NumeroCliente?: string | null;
			ComplementoCliente?: string | null;
			BairroCliente?: string | null;
			CepCliente?: string | null;
			EmailCliente?: string | null;
			NomeVendedor?: string | null;
			DocFiscal?: string | null;
			CodigoVenda?: string | null;
			ItemCondicional: Array<{
				__typename?: "ItemCondicional";
				Id: number;
				IdEmpresa: number;
				IdCondicional: number;
				IdProduto: number;
				IdUnidade: number;
				IdGrade?: number | null;
				IdLinha?: number | null;
				IdColuna?: number | null;
				Codigo: string;
				CodBarras?: string | null;
				Descricao: string;
				DescricaoProduto: string;
				DescricaoGrade?: string | null;
				DescricaoComp?: string | null;
				CodigoSecundario?: string | null;
				CodigoGrade?: string | null;
				CodBarrasGrade?: string | null;
				Status: StatusItemCondicional;
				ValorDesconto: number;
				ValorAcrescimo: number;
				SimboloUnidade: string;
				Quantidade: number;
				QuantidadeEstoque: number;
				QuantidadeEstoqueGrade?: number | null;
				ValorUnitario: number;
				ValorSubTotal: number;
				ValorTotalItem: number;
				ValorUnitarioPrazo: number;
				ValorSubTotalPrazo: number;
				ValorTotalItemPrazo: number;
				QtdDevolvida?: number | null;
				QtdFaturada?: number | null;
				ValorCustoMedioUnitario: number;
				ValorCustoUnitario: number;
				DataCadastro: Date;
				DataAlteracao: Date;
			}>;
		}>;
	};
};

export type CodigoProximoPedidoCondicionalQueryVariables = Exact<{ [key: string]: never }>;

export type CodigoProximoPedidoCondicionalQuery = { __typename?: "Query"; codigoProximoCondicional: number };

export type ImpressaoPedidoCondicionalQueryVariables = Exact<{
	hash: Scalars["String"];
	view?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ImpressaoPedidoCondicionalQuery = {
	__typename?: "Query";
	impressaoCondicional: {
		__typename?: "ImpressaoCondicional";
		DuasVias?: boolean | null;
		DataCondicional: {
			__typename?: "Condicional";
			Id: number;
			IdEmpresa: number;
			IdCliente: number;
			IdVendedor?: number | null;
			IdUsuarioSaida: number;
			IdUsuarioRetorno?: number | null;
			Codigo: string;
			Status: StatusCondicional;
			Data: Date;
			DataPrevRetorno?: Date | null;
			DataRetorno?: Date | null;
			Observacoes?: string | null;
			ValorDesconto: number;
			ValorFrete: number;
			ValorTotal: number;
			ValorTotalPrazo: number;
			DataCadastro: Date;
			DataAlteracao: Date;
			HashLink?: string | null;
			IdVenda?: number | null;
			NomeCliente?: string | null;
			NomeFantasiaCliente?: string | null;
			CpfCnpjCliente?: string | null;
			TelefoneCliente?: string | null;
			CelularCliente?: string | null;
			CidadeCliente?: string | null;
			UFCliente?: string | null;
			LogradouroCliente?: string | null;
			NumeroCliente?: string | null;
			ComplementoCliente?: string | null;
			BairroCliente?: string | null;
			CepCliente?: string | null;
			EmailCliente?: string | null;
			NomeVendedor?: string | null;
			DocFiscal?: string | null;
			ItemCondicional: Array<{
				__typename?: "ItemCondicional";
				Id: number;
				IdEmpresa: number;
				IdCondicional: number;
				IdProduto: number;
				IdUnidade: number;
				IdGrade?: number | null;
				IdLinha?: number | null;
				IdColuna?: number | null;
				Codigo: string;
				CodBarras?: string | null;
				Descricao: string;
				DescricaoProduto: string;
				DescricaoGrade?: string | null;
				DescricaoComp?: string | null;
				CodigoSecundario?: string | null;
				CodigoGrade?: string | null;
				CodBarrasGrade?: string | null;
				Status: StatusItemCondicional;
				ValorDesconto: number;
				ValorAcrescimo: number;
				SimboloUnidade: string;
				Quantidade: number;
				QuantidadeEstoque: number;
				QuantidadeEstoqueGrade?: number | null;
				ValorUnitario: number;
				ValorSubTotal: number;
				ValorTotalItem: number;
				ValorUnitarioPrazo: number;
				ValorSubTotalPrazo: number;
				ValorTotalItemPrazo: number;
				QtdDevolvida?: number | null;
				QtdFaturada?: number | null;
				ValorCustoMedioUnitario: number;
				ValorCustoUnitario: number;
				DataCadastro: Date;
				DataAlteracao: Date;
			}>;
		};
		DataEmpresa: {
			__typename?: "Empresa";
			Id: number;
			Nome: string;
			NomeFantasia?: string | null;
			CpfCnpj: string;
			LogoEmpresa?: string | null;
			CidadeNome?: string | null;
			Uf?: string | null;
			Logradouro?: string | null;
			Bairro?: string | null;
			Cep?: string | null;
			NumEndereco?: string | null;
			Complemento?: string | null;
			Celular?: string | null;
			Telefone?: string | null;
			EmailPrincipal?: string | null;
			ProdutoEmpresa: ProdutoEmpresa;
			Configuracoes: Array<{ __typename?: "ConfiguracaoEmpresa"; Id?: number | null; Valor?: string | null; IdConfig: Configuracoes; Nome: string; NomeLegivel: string }>;
		};
	};
};

export type EnviaEmailCondicionalSubscriptionVariables = Exact<{
	idCondicional: Scalars["Int"];
}>;

export type EnviaEmailCondicionalSubscription = { __typename?: "Subscription"; enviaEmailCondicional?: RetornoTarefa | null };

export type CadastraPedidoVendaMutationVariables = Exact<{
	dados: InputCadastrarPedidoVenda;
}>;

export type CadastraPedidoVendaMutation = { __typename?: "Mutation"; cadastraPedidoVenda: RetornoPadrao };

export type EditaPedidoVendaMutationVariables = Exact<{
	dados: InputEditarPedidoVenda;
}>;

export type EditaPedidoVendaMutation = { __typename?: "Mutation"; editaPedidoVenda: RetornoPadrao };

export type GerarVendaMutationVariables = Exact<{
	dados: InputGerarVenda;
}>;

export type GerarVendaMutation = { __typename?: "Mutation"; gerarVenda: RetornoPadrao };

export type PedidosVendaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroPedidosVenda>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type PedidosVendaQuery = {
	__typename?: "Query";
	pedidosVenda: {
		__typename?: "PaginaPedidoVenda";
		Data: Array<{
			__typename?: "PedidoVenda";
			Id: number;
			IdCliente: number;
			IdVendedor?: number | null;
			IdUsuario: number;
			IdTransportadora?: number | null;
			IdEnderecoEntrega?: number | null;
			Status: number;
			Codigo: string;
			CodigoVenda?: string | null;
			Data: Date;
			QtdItens?: number | null;
			Hora: Date;
			DataHora: Date;
			DataEntrega?: Date | null;
			ValorJuro: number;
			ValorFrete: number;
			DescricaoStatus: string;
			EstagioStatus: Estagio;
			NomeVendedor?: string | null;
			NomeFantasiaCliente?: string | null;
			NomeCliente?: string | null;
			HashLink: string;
			DataAlteracao: Date;
			DataCadastro: Date;
			IdEcommerce?: number | null;
			PrecoUsado: PrecoUsado;
			Frete: TipoFrete;
			Conferido: RespostaEnum;
			Observacoes?: string | null;
			ValorTotal: number;
			ValorSeguro: number;
			ValorDesconto: number;
			ItemVenda?: Array<{
				__typename?: "ItemVenda";
				ValorSubTotal: number;
				SimboloUnidade: string;
				DefinicaoServico?: StatusEnum | null;
				DefinicaoProducao?: StatusEnum | null;
				DefinicaoVenda?: StatusEnum | null;
				DataAlteracao?: Date | null;
				DataCadastro?: Date | null;
				ValorCustoUnitario?: number | null;
				ValorCustoMedioUnitario?: number | null;
				QtdFaturada?: number | null;
				QtdConferida?: number | null;
				ValorTotalItem: number;
				ValorUnitario: number;
				QuantidadeEstoqueGrade?: number | null;
				QuantidadeEstoque: number;
				Quantidade: number;
				ValorJuro?: number | null;
				ValorSeguro?: number | null;
				ValorFrete?: number | null;
				ValorDescontoTotal?: number | null;
				ValorAcrescimo?: number | null;
				ValorDesconto?: number | null;
				InformacoesAdicionais?: string | null;
				DescricaoComp?: string | null;
				DescricaoGrade?: string | null;
				DescricaoProduto: string;
				Descricao: string;
				CodBarras?: string | null;
				Codigo: string;
				IdColuna?: number | null;
				IdLinha?: number | null;
				IdGrade?: number | null;
				IdUnidade: number;
				IdProduto: number;
				IdVenda: number;
				Id: number;
			} | null> | null;
			PrestacaoVenda?: Array<{
				__typename?: "PrestacaoVenda";
				TotalLancamento: number;
				TipoFormaPgto: TipoFormaPagamento;
				TagPgtoNFe: TagPagamentoNFe;
				NumLancamento: number;
				NumDocumento?: string | null;
				NomeFormaPgto?: string | null;
				DataAlteracao: Date;
				ValorParcela: number;
				DataVencimento: Date;
				IdFormaPgto: number;
				IdAdminCartao?: number | null;
				IdContaFinanceira?: number | null;
				IdVenda: number;
				Id: number;
			} | null> | null;
		}>;
		Paginacao: { __typename?: "PaginacaoPedidoVenda"; NumPedidos: number; TotalItens: number; TotalPedido: number; TotalRegistros: number };
	};
};

export type PedidoVendaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroPedidoVenda>;
}>;

export type PedidoVendaQuery = {
	__typename?: "Query";
	pedidoVenda: {
		__typename?: "PedidoVenda";
		Id: number;
		IdCliente: number;
		IdTransportadora?: number | null;
		IdVendedor?: number | null;
		Codigo: string;
		CodigoVenda?: string | null;
		DataHora: Date;
		DataEntrega?: Date | null;
		Status: number;
		Frete: TipoFrete;
		ValorJuro: number;
		ValorFrete: number;
		ValorDesconto: number;
		ValorSeguro: number;
		Observacoes?: string | null;
		DataCadastro: Date;
		DataAlteracao: Date;
		PrestacaoVenda?: Array<{
			__typename?: "PrestacaoVenda";
			Id: number;
			IdFormaPgto: number;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			DataVencimento: Date;
			NumDocumento?: string | null;
			NumLancamento: number;
			NomeFormaPgto?: string | null;
			TagPgtoNFe: TagPagamentoNFe;
			TipoFormaPgto: TipoFormaPagamento;
		} | null> | null;
		ItemVenda?: Array<{
			__typename?: "ItemVenda";
			Id: number;
			IdProduto: number;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			IdUnidade: number;
			SimboloUnidade: string;
			Descricao: string;
			DescricaoGrade?: string | null;
			DescricaoProduto: string;
			Codigo: string;
			CodBarras?: string | null;
			Quantidade: number;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			ValorUnitario: number;
			ValorDesconto?: number | null;
			ValorAcrescimo?: number | null;
			ValorDescontoTotal?: number | null;
			DescricaoComp?: string | null;
			InformacoesAdicionais?: string | null;
		} | null> | null;
	};
};

export type CodigoProximoPedidoVendaQueryVariables = Exact<{ [key: string]: never }>;

export type CodigoProximoPedidoVendaQuery = { __typename?: "Query"; codigoProximoPedidoVenda: number };

export type ComissoesQueryVariables = Exact<{
	filtro: InputFiltroComissoes;
}>;

export type ComissoesQuery = {
	__typename?: "Query";
	comissoes: Array<{
		__typename?: "Comissoes";
		IdVendedor?: number | null;
		ValorTotalVendas?: number | null;
		ValorTotalDevolucao?: number | null;
		ValorTotalComissao?: number | null;
		Venda?: Array<{
			__typename?: "RelatorioComissoesVenda";
			DataVenda?: Date | null;
			CodigoVenda?: number | null;
			ValorTotal?: number | null;
			ValorComissao?: number | null;
			ValorDevolvido?: number | null;
			FormaPgto?: number | null;
			NomeFormaPgto?: string | null;
			QtdItens?: number | null;
			NomeVendedor?: string | null;
			Cliente?: { __typename?: "RelatorioComissoesCliente"; Id?: number | null; Nome?: string | null } | null;
		} | null> | null;
		Devolucao?: Array<{
			__typename?: "RelatorioComissoesDevolucoes";
			DataVenda?: Date | null;
			IdVenda?: number | null;
			Codigo?: string | null;
			QtdItens?: number | null;
			ValorTotal?: number | null;
			NomeVendedor?: string | null;
			Cliente?: { __typename?: "RelatorioComissoesCliente"; Id?: number | null; Nome?: string | null } | null;
		} | null> | null;
		Metas?: Array<{
			__typename?: "RelatorioComissoesMeta";
			NomeMeta?: string | null;
			ValorMeta?: number | null;
			PercAtingido?: number | null;
			AdicionalComissao?: number | null;
			TipoBonificacao?: TipoBonificacao | null;
			ValorBonificacao?: number | null;
		} | null> | null;
		ComissoesFormaPgto?: Array<{
			__typename?: "RelatorioComissoesFormaPgto";
			IdFormaPgto?: number | null;
			NomeFormaPgto?: string | null;
			TotalVendido?: number | null;
			ComissaoPercentual?: number | null;
			ComissaoPagar?: number | null;
		} | null> | null;
	} | null>;
};

export type VendasPorProdutoQueryVariables = Exact<{
	filtro: InputFiltroVendasPorProduto;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type VendasPorProdutoQuery = {
	__typename?: "Query";
	vendasPorProduto: {
		__typename?: "PaginaVendasPorProduto";
		Data: Array<{
			__typename?: "VendasPorProduto";
			ClassificacaoRanking?: number | null;
			Codigo?: string | null;
			Agrupamento?: string | null;
			TotalVendido: number;
			TotalCusto: number;
			TotalDevolucoes: number;
			TotalLucro: number;
			TicketMedio: number;
			QtdItensVendidos: number;
			Itens: Array<{
				__typename?: "RelatorioVendasPorProdutoItens";
				IdProduto?: number | null;
				Codigo?: string | null;
				Descricao?: string | null;
				Quantidade?: number | null;
				Custo?: number | null;
				ValorTotal?: number | null;
				MargemLucro?: number | null;
			}>;
		}>;
		Paginacao: {
			__typename?: "TotalizadoresRelatorioVendaPorProdutos";
			TotalRegistros: number;
			TotalVendido: number;
			TotalCusto: number;
			TotalDevolucoes: number;
			TotalLucro: number;
			TicketMedio: number;
			QtdItensVendidos: number;
		};
	};
};

export type VendasPorTributacaoQueryVariables = Exact<{
	filtro: InputFiltroVendasPorTributacao;
}>;

export type VendasPorTributacaoQuery = {
	__typename?: "Query";
	vendasPorTributacao: Array<{
		__typename?: "VendasPorTributacao";
		DataEmissao?: Date | null;
		ValorTotal?: number | null;
		QuantidadeTotal?: number | null;
		Itens?: Array<{
			__typename?: "VendasPorTributacaoProdutos";
			IdProduto?: number | null;
			Codigo?: string | null;
			Descricao?: string | null;
			ValorTotal?: number | null;
			Quantidade?: number | null;
		} | null> | null;
	} | null>;
};

export type CadastraDevolucaoMutationVariables = Exact<{
	dados: InputCadastrarDevolucao;
}>;

export type CadastraDevolucaoMutation = { __typename?: "Mutation"; cadastraDevolucao: RetornoPadrao };

export type DevolucaoQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroDevolucao>;
}>;

export type DevolucaoQuery = {
	__typename?: "Query";
	devolucao: {
		__typename?: "Devolucao";
		Id: number;
		IdUsuario: number;
		IdPessoa: number;
		IdNFe?: number | null;
		Tipo?: TipoTransacao | null;
		Codigo?: string | null;
		Data?: Date | null;
		Hora?: Date | null;
		ValorTotal?: number | null;
		NomePessoa: string;
		NomeFantasiaPessoa?: string | null;
		CpfCnpjPessoa?: string | null;
		TelefonePessoa?: string | null;
		CelularPessoa?: string | null;
		EmailPessoa?: string | null;
		IdCidadePessoa?: number | null;
		CidadePessoa?: string | null;
		IdEstadoPessoa?: number | null;
		EstadoPessoa?: string | null;
		RuaPessoa?: string | null;
		BairroPessoa?: string | null;
		NumeroPessoa?: string | null;
		ComplementoPessoa?: string | null;
	};
};

export type DevolucoesQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroDevolucoes>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type DevolucoesQuery = {
	__typename?: "Query";
	devolucoes: {
		__typename?: "PaginaDevolucao";
		Data: Array<{
			__typename?: "Devolucao";
			Id: number;
			IdUsuario: number;
			IdPessoa: number;
			IdNFe?: number | null;
			Tipo?: TipoTransacao | null;
			Codigo?: string | null;
			Data?: Date | null;
			Hora?: Date | null;
			ValorTotal?: number | null;
			NomePessoa: string;
			NomeFantasiaPessoa?: string | null;
			ItemDevolucao?: Array<{ __typename?: "ItemDevolucao"; IdNFe?: number | null } | null> | null;
		}>;
		Paginacao: { __typename?: "Paginacao"; TotalRegistros: number };
	};
};

export type CadastraVendaMutationVariables = Exact<{
	dados: InputCadastrarVenda;
}>;

export type CadastraVendaMutation = { __typename?: "Mutation"; cadastraVenda: RetornoPadrao };

export type EditaVendaMutationVariables = Exact<{
	dados: InputEditarVenda;
}>;

export type EditaVendaMutation = { __typename?: "Mutation"; editaVenda: RetornoPadrao };

export type ConfirmaVendaMutationVariables = Exact<{
	id: Scalars["Int"];
	correlationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type ConfirmaVendaMutation = { __typename?: "Mutation"; confirmaVenda: RetornoPadrao };

export type GeraNfeDaVendaMutationVariables = Exact<{
	id: Scalars["Int"];
	correlationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GeraNfeDaVendaMutation = { __typename?: "Mutation"; geraNfeDaVenda: RetornoPadrao };

export type FaturaVendaMutationVariables = Exact<{
	id: Scalars["Int"];
	correlationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type FaturaVendaMutation = { __typename?: "Mutation"; faturaVenda: RetornoPadrao };

export type CancelaVendaMutationVariables = Exact<{
	id: Scalars["Int"];
}>;

export type CancelaVendaMutation = { __typename?: "Mutation"; cancelaVenda: RetornoPadrao };

export type StatusVendasQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroStatusVendas>;
}>;

export type StatusVendasQuery = { __typename?: "Query"; statusVendas: Array<{ __typename?: "StatusVenda"; Id: number; Descricao: string; Estagio: Estagio; Status: StatusEnum; Ordem: number }> };

export type VendasQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroVendas>;
	paginacao?: InputMaybe<InputPaginacao>;
}>;

export type VendasQuery = {
	__typename?: "Query";
	vendas: {
		__typename?: "PaginaVenda";
		Data: Array<{
			__typename?: "Venda";
			Id: number;
			IdCliente?: number | null;
			IdVendedor?: number | null;
			Codigo: string;
			Status: number;
			DescricaoStatus: string;
			DataHora: Date;
			DataEntrega?: Date | null;
			NomeCliente?: string | null;
			NomeFantasiaCliente?: string | null;
			NomeVendedor?: string | null;
			ValorTotal: number;
			QtdItens?: number | null;
			HashLink: string;
			EstagioStatus: Estagio;
			FinanceiroGerado: RespostaEnum;
			Tipo: TipoVenda;
			DataAlteracao: Date;
			DataCadastro: Date;
			Conferido: RespostaEnum;
			Frete: TipoFrete;
			Observacoes?: string | null;
			PrecoUsado: PrecoUsado;
			ValorDesconto: number;
			ValorFrete: number;
			ValorJuro: number;
			ValorSeguro: number;
			VendaNfe?: Array<{ __typename?: "VendaNfe"; IdNFe: number; NumeroNFe: string; StatusNFe: StatusNfe } | null> | null;
			PrestacaoVenda?: Array<{
				__typename?: "PrestacaoVenda";
				Id: number;
				IdFormaPgto: number;
				IdAdminCartao?: number | null;
				IdContaFinanceira?: number | null;
				ValorParcela: number;
				TotalLancamento: number;
				DataVencimento: Date;
				NumDocumento?: string | null;
				NumLancamento: number;
				NomeFormaPgto?: string | null;
				TagPgtoNFe: TagPagamentoNFe;
				TipoFormaPgto: TipoFormaPagamento;
			} | null> | null;
			ItemVenda?: Array<{
				__typename?: "ItemVenda";
				Id: number;
				IdProduto: number;
				IdGrade?: number | null;
				IdLinha?: number | null;
				IdColuna?: number | null;
				IdUnidade: number;
				SimboloUnidade: string;
				Descricao: string;
				DescricaoGrade?: string | null;
				DescricaoProduto: string;
				Codigo: string;
				CodBarras?: string | null;
				Quantidade: number;
				QuantidadeEstoque: number;
				QuantidadeEstoqueGrade?: number | null;
				ValorUnitario: number;
				ValorDesconto?: number | null;
				ValorAcrescimo?: number | null;
				ValorDescontoTotal?: number | null;
				DescricaoComp?: string | null;
				InformacoesAdicionais?: string | null;
			} | null> | null;
		}>;
		Paginacao: {
			__typename?: "PaginacaoVenda";
			TotalRegistrosVendidos: number;
			TotalVendido: number;
			TotalItensVendidos: number;
			TotalRegistros: number;
			TotalVendas: number;
			TotalItens: number;
			TotalFormasPagamento?: Array<{ __typename?: "VendaTotalPorFormaPgto"; TotalVendas: number; TotalVendido: number; IdFormaPgto: number; NomeFormaPgto: string }> | null;
		};
	};
};

export type VendaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroVenda>;
}>;

export type VendaQuery = {
	__typename?: "Query";
	venda: {
		__typename?: "Venda";
		Id: number;
		IdCliente?: number | null;
		IdTransportadora?: number | null;
		IdVendedor?: number | null;
		IdIntermediador?: number | null;
		Codigo: string;
		DataHora: Date;
		DataEntrega?: Date | null;
		Status: number;
		Frete: TipoFrete;
		ValorJuro: number;
		ValorFrete: number;
		ValorDesconto: number;
		ValorSeguro: number;
		Observacoes?: string | null;
		InformacoesAdicionais?: string | null;
		NomeCliente?: string | null;
		NomeFantasiaCliente?: string | null;
		CpfCnpjCliente?: string | null;
		TelefoneCliente?: string | null;
		CelularCliente?: string | null;
		EmailCliente?: string | null;
		CidadeCliente?: string | null;
		UFCliente?: string | null;
		LogradouroCliente?: string | null;
		BairroCliente?: string | null;
		NumeroCliente?: string | null;
		ComplementoCliente?: string | null;
		HashLink: string;
		DataCadastro: Date;
		DataAlteracao: Date;
		PrestacaoVenda?: Array<{
			__typename?: "PrestacaoVenda";
			Id: number;
			IdFormaPgto: number;
			IdAdminCartao?: number | null;
			IdContaFinanceira?: number | null;
			ValorParcela: number;
			TotalLancamento: number;
			DataVencimento: Date;
			NumDocumento?: string | null;
			NumLancamento: number;
			NomeFormaPgto?: string | null;
			TagPgtoNFe: TagPagamentoNFe;
			TipoFormaPgto: TipoFormaPagamento;
		} | null> | null;
		ItemVenda?: Array<{
			__typename?: "ItemVenda";
			Id: number;
			IdProduto: number;
			IdGrade?: number | null;
			IdLinha?: number | null;
			IdColuna?: number | null;
			IdUnidade: number;
			SimboloUnidade: string;
			Descricao: string;
			DescricaoGrade?: string | null;
			DescricaoProduto: string;
			Codigo: string;
			CodBarras?: string | null;
			Quantidade: number;
			QuantidadeEstoque: number;
			QuantidadeEstoqueGrade?: number | null;
			ValorUnitario: number;
			ValorDesconto?: number | null;
			ValorAcrescimo?: number | null;
			ValorDescontoTotal?: number | null;
			DescricaoComp?: string | null;
			InformacoesAdicionais?: string | null;
		} | null> | null;
		VendaNfe?: Array<{ __typename?: "VendaNfe"; IdNFe: number; NumeroNFe: string; StatusNFe: StatusNfe } | null> | null;
	};
};

export type StatusVendaQueryVariables = Exact<{
	filtro?: InputMaybe<InputFiltroStatusVenda>;
}>;

export type StatusVendaQuery = { __typename?: "Query"; statusVenda: { __typename?: "StatusVenda"; Descricao: string; Estagio: Estagio; Ordem: number } };

export type CodigoProximaVendaQueryVariables = Exact<{ [key: string]: never }>;

export type CodigoProximaVendaQuery = { __typename?: "Query"; codigoProximaVenda: number };

export type ImpressaoVendaQueryVariables = Exact<{
	hash: Scalars["String"];
	view?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ImpressaoVendaQuery = {
	__typename?: "Query";
	impressaoVenda: {
		__typename?: "ImpressaoVenda";
		DataVenda: {
			__typename?: "Venda";
			Id: number;
			Codigo: string;
			IdVendedor?: number | null;
			NomeVendedor?: string | null;
			DataHora: Date;
			DataEntrega?: Date | null;
			IdCliente?: number | null;
			NomeCliente?: string | null;
			NomeFantasiaCliente?: string | null;
			CpfCnpjCliente?: string | null;
			TelefoneCliente?: string | null;
			CelularCliente?: string | null;
			CidadeCliente?: string | null;
			UFCliente?: string | null;
			LogradouroCliente?: string | null;
			NumeroCliente?: string | null;
			ComplementoCliente?: string | null;
			BairroCliente?: string | null;
			CepCliente?: string | null;
			Tipo: TipoVenda;
			QtdItens?: number | null;
			ValorJuro: number;
			ValorFrete: number;
			ValorDesconto: number;
			ValorTotal: number;
			Frete: TipoFrete;
			IdTransportadora?: number | null;
			NomeTransportadora?: string | null;
			Observacoes?: string | null;
			ItemVenda?: Array<{
				__typename?: "ItemVenda";
				Id: number;
				Codigo: string;
				Descricao: string;
				DescricaoComp?: string | null;
				Quantidade: number;
				SimboloUnidade: string;
				ValorUnitario: number;
				ValorAcrescimo?: number | null;
				ValorDesconto?: number | null;
				ValorTotalItem: number;
			} | null> | null;
			PrestacaoVenda?: Array<{ __typename?: "PrestacaoVenda"; Id: number; NomeFormaPgto?: string | null; DataVencimento: Date; ValorParcela: number; NumLancamento: number } | null> | null;
		};
		DataEmpresa: {
			__typename?: "Empresa";
			Id: number;
			Nome: string;
			NomeFantasia?: string | null;
			CpfCnpj: string;
			LogoEmpresa?: string | null;
			CidadeNome?: string | null;
			Uf?: string | null;
			Logradouro?: string | null;
			Bairro?: string | null;
			Cep?: string | null;
			NumEndereco?: string | null;
			Complemento?: string | null;
			Celular?: string | null;
			Telefone?: string | null;
			EmailPrincipal?: string | null;
			ProdutoEmpresa: ProdutoEmpresa;
		};
	};
};

export type TotalizadoresVendasQueryVariables = Exact<{
	filtro: InputFiltroTotalizadorVenda;
}>;

export type TotalizadoresVendasQuery = {
	__typename?: "Query";
	totalizadoresVendas: {
		__typename?: "TotalizadorVenda";
		ValorPeriodo: { __typename?: "TotaisVendas"; TotalVendas: number; QtdVendas: number; QtdItens: number; TicketMedioItens: number; TicketMedioVendas: number };
		ValorPeriodoAnterior: { __typename?: "TotaisVendas"; TotalVendas: number; QtdVendas: number; QtdItens: number; TicketMedioItens: number; TicketMedioVendas: number };
		ValorPeriodoAnoAnterior: { __typename?: "TotaisVendas"; TotalVendas: number; QtdVendas: number; QtdItens: number; TicketMedioItens: number; TicketMedioVendas: number };
	};
};

export type VolumeVendasQueryVariables = Exact<{
	filtro: InputFiltroVolumeVenda;
}>;

export type VolumeVendasQuery = { __typename?: "Query"; volumeVendas: Array<{ __typename?: "VolumeVenda"; ValorTotal: number; QtdVenda: number; Data: Date }> };

export type RankingProdutosQueryVariables = Exact<{
	filtro: InputFiltroRankingVenda;
}>;

export type RankingProdutosQuery = {
	__typename?: "Query";
	rankingProdutos: Array<{ __typename?: "RankingProduto"; QtdVendida: number; ValorVendido: number; Descricao: string; Codigo: string; IdProduto: number }>;
};

export type VendasFormaPagamentoQueryVariables = Exact<{
	filtro: InputFiltroTotalizadorVenda;
}>;

export type VendasFormaPagamentoQuery = {
	__typename?: "Query";
	vendasFormaPagamento: Array<{ __typename?: "RankingFormaPagamento"; IdFormaPgto: number; Nome: string; ValorVendido: number; QtdVendas: number; Porcentagem: number }>;
};

export type PicoVendasQueryVariables = Exact<{
	filtro: InputFiltroTotalizadorVenda;
}>;

export type PicoVendasQuery = {
	__typename?: "Query";
	picoVendas: {
		__typename?: "PicoVenda";
		PicoDiaMes: { __typename?: "PicoVendaValoresMes"; MaiorDia: number; MenorDia: number };
		PicoDiaSemana: { __typename?: "PicoVendaValoresSemana"; MaiorDia: DiaSemana; MenorDia: DiaSemana };
	};
};

export type TotalizadoresTrocasDevolucoesQueryVariables = Exact<{
	filtro: InputFiltroTotalizadorTrocasDevolucao;
}>;

export type TotalizadoresTrocasDevolucoesQuery = {
	__typename?: "Query";
	totalizadoresTrocasDevolucoes: { __typename?: "TotalizadorTrocasDevolucao"; QuantidadeDevolucoes: number; ValorDevolucoes: number; QuantidadeTrocas: number; ValorTrocas: number };
};

export type AguardaTransmissaoNfeSubscriptionVariables = Exact<{
	correlationId: Scalars["String"];
}>;

export type AguardaTransmissaoNfeSubscription = { __typename?: "Subscription"; aguardaTransmissaoNfe?: RetornoTarefa | null };

export type EnviaEmailVendaSubscriptionVariables = Exact<{
	idVenda: Scalars["Int"];
}>;

export type EnviaEmailVendaSubscription = { __typename?: "Subscription"; enviaEmailVenda?: RetornoTarefa | null };

export type CadastraVendaPdvMutationVariables = Exact<{
	dados: InputCadastrarVendaPdv;
	correlationIdNFCe?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CadastraVendaPdvMutation = { __typename?: "Mutation"; cadastraVendaPdv: RetornoPadrao };

export type GeraNfceDaVendaPdvMutationVariables = Exact<{
	id: Scalars["Int"];
	correlationId?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GeraNfceDaVendaPdvMutation = { __typename?: "Mutation"; geraNfceDaVendaPdv: RetornoPadrao };

export const CadastraCategoriaClienteDocument = gql`
	mutation CadastraCategoriaCliente($dados: InputCadastrarCategoriaCliente!) {
		cadastraCategoriaCliente(Dados: $dados)
	}
`;
export type CadastraCategoriaClienteMutationFn = Apollo.MutationFunction<CadastraCategoriaClienteMutation, CadastraCategoriaClienteMutationVariables>;

/**
 * __useCadastraCategoriaClienteMutation__
 *
 * To run a mutation, you first call `useCadastraCategoriaClienteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraCategoriaClienteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraCategoriaClienteMutation, { data, loading, error }] = useCadastraCategoriaClienteMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraCategoriaClienteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraCategoriaClienteMutation, CadastraCategoriaClienteMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraCategoriaClienteMutation, CadastraCategoriaClienteMutationVariables>(CadastraCategoriaClienteDocument, options);
}
export type CadastraCategoriaClienteMutationHookResult = ReturnType<typeof useCadastraCategoriaClienteMutation>;
export type CadastraCategoriaClienteMutationResult = Apollo.MutationResult<CadastraCategoriaClienteMutation>;
export type CadastraCategoriaClienteMutationOptions = Apollo.BaseMutationOptions<CadastraCategoriaClienteMutation, CadastraCategoriaClienteMutationVariables>;
export const EditaCategoriaClienteDocument = gql`
	mutation EditaCategoriaCliente($dados: InputEditarCategoriaCliente!) {
		editaCategoriaCliente(Dados: $dados)
	}
`;
export type EditaCategoriaClienteMutationFn = Apollo.MutationFunction<EditaCategoriaClienteMutation, EditaCategoriaClienteMutationVariables>;

/**
 * __useEditaCategoriaClienteMutation__
 *
 * To run a mutation, you first call `useEditaCategoriaClienteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaCategoriaClienteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaCategoriaClienteMutation, { data, loading, error }] = useEditaCategoriaClienteMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaCategoriaClienteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaCategoriaClienteMutation, EditaCategoriaClienteMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaCategoriaClienteMutation, EditaCategoriaClienteMutationVariables>(EditaCategoriaClienteDocument, options);
}
export type EditaCategoriaClienteMutationHookResult = ReturnType<typeof useEditaCategoriaClienteMutation>;
export type EditaCategoriaClienteMutationResult = Apollo.MutationResult<EditaCategoriaClienteMutation>;
export type EditaCategoriaClienteMutationOptions = Apollo.BaseMutationOptions<EditaCategoriaClienteMutation, EditaCategoriaClienteMutationVariables>;
export const ToggleStatusCategoriaClienteDocument = gql`
	mutation ToggleStatusCategoriaCliente($id: Int!, $status: Status!) {
		editaCategoriaCliente(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusCategoriaClienteMutationFn = Apollo.MutationFunction<ToggleStatusCategoriaClienteMutation, ToggleStatusCategoriaClienteMutationVariables>;

/**
 * __useToggleStatusCategoriaClienteMutation__
 *
 * To run a mutation, you first call `useToggleStatusCategoriaClienteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusCategoriaClienteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusCategoriaClienteMutation, { data, loading, error }] = useToggleStatusCategoriaClienteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusCategoriaClienteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusCategoriaClienteMutation, ToggleStatusCategoriaClienteMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusCategoriaClienteMutation, ToggleStatusCategoriaClienteMutationVariables>(ToggleStatusCategoriaClienteDocument, options);
}
export type ToggleStatusCategoriaClienteMutationHookResult = ReturnType<typeof useToggleStatusCategoriaClienteMutation>;
export type ToggleStatusCategoriaClienteMutationResult = Apollo.MutationResult<ToggleStatusCategoriaClienteMutation>;
export type ToggleStatusCategoriaClienteMutationOptions = Apollo.BaseMutationOptions<ToggleStatusCategoriaClienteMutation, ToggleStatusCategoriaClienteMutationVariables>;
export const CategoriaClienteDocument = gql`
	query CategoriaCliente($filtro: InputFiltroCategoriaCliente) {
		categoriaCliente(Filtro: $filtro) {
			Nome
			Status
			Descricao
		}
	}
`;

/**
 * __useCategoriaClienteQuery__
 *
 * To run a query within a React component, call `useCategoriaClienteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriaClienteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriaClienteQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCategoriaClienteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriaClienteQuery, CategoriaClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriaClienteQuery, CategoriaClienteQueryVariables>(CategoriaClienteDocument, options);
}
export function useCategoriaClienteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriaClienteQuery, CategoriaClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriaClienteQuery, CategoriaClienteQueryVariables>(CategoriaClienteDocument, options);
}
export type CategoriaClienteQueryHookResult = ReturnType<typeof useCategoriaClienteQuery>;
export type CategoriaClienteLazyQueryHookResult = ReturnType<typeof useCategoriaClienteLazyQuery>;
export type CategoriaClienteQueryResult = Apollo.QueryResult<CategoriaClienteQuery, CategoriaClienteQueryVariables>;
export const CategoriasClienteDocument = gql`
	query CategoriasCliente($filtro: InputFiltroCategoriasCliente) {
		categoriasCliente(Filtro: $filtro) {
			Nome
			Status
			IdEmpresa
			Id
			Descricao
			DataAlteracao
		}
	}
`;

/**
 * __useCategoriasClienteQuery__
 *
 * To run a query within a React component, call `useCategoriasClienteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriasClienteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriasClienteQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCategoriasClienteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriasClienteQuery, CategoriasClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriasClienteQuery, CategoriasClienteQueryVariables>(CategoriasClienteDocument, options);
}
export function useCategoriasClienteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriasClienteQuery, CategoriasClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriasClienteQuery, CategoriasClienteQueryVariables>(CategoriasClienteDocument, options);
}
export type CategoriasClienteQueryHookResult = ReturnType<typeof useCategoriasClienteQuery>;
export type CategoriasClienteLazyQueryHookResult = ReturnType<typeof useCategoriasClienteLazyQuery>;
export type CategoriasClienteQueryResult = Apollo.QueryResult<CategoriasClienteQuery, CategoriasClienteQueryVariables>;
export const CategoriaClienteByIdDocument = gql`
	query CategoriaClienteById($Id: Int!) {
		categoriaCliente(Filtro: { Id: $Id }) {
			Nome
			Status
			Descricao
		}
	}
`;

/**
 * __useCategoriaClienteByIdQuery__
 *
 * To run a query within a React component, call `useCategoriaClienteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriaClienteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriaClienteByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useCategoriaClienteByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CategoriaClienteByIdQuery, CategoriaClienteByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriaClienteByIdQuery, CategoriaClienteByIdQueryVariables>(CategoriaClienteByIdDocument, options);
}
export function useCategoriaClienteByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriaClienteByIdQuery, CategoriaClienteByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriaClienteByIdQuery, CategoriaClienteByIdQueryVariables>(CategoriaClienteByIdDocument, options);
}
export type CategoriaClienteByIdQueryHookResult = ReturnType<typeof useCategoriaClienteByIdQuery>;
export type CategoriaClienteByIdLazyQueryHookResult = ReturnType<typeof useCategoriaClienteByIdLazyQuery>;
export type CategoriaClienteByIdQueryResult = Apollo.QueryResult<CategoriaClienteByIdQuery, CategoriaClienteByIdQueryVariables>;
export const CategoriasClienteSelectDocument = gql`
	query CategoriasClienteSelect($IdCategoriaCliente: Int) {
		categoriasCliente(Filtro: { Status: "ATIVO", Ids: [$IdCategoriaCliente] }) {
			Nome
			Status
			IdEmpresa
			Id
			Descricao
			DataAlteracao
		}
	}
`;

/**
 * __useCategoriasClienteSelectQuery__
 *
 * To run a query within a React component, call `useCategoriasClienteSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriasClienteSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriasClienteSelectQuery({
 *   variables: {
 *      IdCategoriaCliente: // value for 'IdCategoriaCliente'
 *   },
 * });
 */
export function useCategoriasClienteSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriasClienteSelectQuery, CategoriasClienteSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriasClienteSelectQuery, CategoriasClienteSelectQueryVariables>(CategoriasClienteSelectDocument, options);
}
export function useCategoriasClienteSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriasClienteSelectQuery, CategoriasClienteSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriasClienteSelectQuery, CategoriasClienteSelectQueryVariables>(CategoriasClienteSelectDocument, options);
}
export type CategoriasClienteSelectQueryHookResult = ReturnType<typeof useCategoriasClienteSelectQuery>;
export type CategoriasClienteSelectLazyQueryHookResult = ReturnType<typeof useCategoriasClienteSelectLazyQuery>;
export type CategoriasClienteSelectQueryResult = Apollo.QueryResult<CategoriasClienteSelectQuery, CategoriasClienteSelectQueryVariables>;
export const CadastraGeneroDocument = gql`
	mutation CadastraGenero($dados: InputCadastrarGenero!) {
		cadastraGenero(Dados: $dados)
	}
`;
export type CadastraGeneroMutationFn = Apollo.MutationFunction<CadastraGeneroMutation, CadastraGeneroMutationVariables>;

/**
 * __useCadastraGeneroMutation__
 *
 * To run a mutation, you first call `useCadastraGeneroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraGeneroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraGeneroMutation, { data, loading, error }] = useCadastraGeneroMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraGeneroMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraGeneroMutation, CadastraGeneroMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraGeneroMutation, CadastraGeneroMutationVariables>(CadastraGeneroDocument, options);
}
export type CadastraGeneroMutationHookResult = ReturnType<typeof useCadastraGeneroMutation>;
export type CadastraGeneroMutationResult = Apollo.MutationResult<CadastraGeneroMutation>;
export type CadastraGeneroMutationOptions = Apollo.BaseMutationOptions<CadastraGeneroMutation, CadastraGeneroMutationVariables>;
export const EditaGeneroDocument = gql`
	mutation EditaGenero($dados: InputEditarGenero!) {
		editaGenero(Dados: $dados)
	}
`;
export type EditaGeneroMutationFn = Apollo.MutationFunction<EditaGeneroMutation, EditaGeneroMutationVariables>;

/**
 * __useEditaGeneroMutation__
 *
 * To run a mutation, you first call `useEditaGeneroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaGeneroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaGeneroMutation, { data, loading, error }] = useEditaGeneroMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaGeneroMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaGeneroMutation, EditaGeneroMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaGeneroMutation, EditaGeneroMutationVariables>(EditaGeneroDocument, options);
}
export type EditaGeneroMutationHookResult = ReturnType<typeof useEditaGeneroMutation>;
export type EditaGeneroMutationResult = Apollo.MutationResult<EditaGeneroMutation>;
export type EditaGeneroMutationOptions = Apollo.BaseMutationOptions<EditaGeneroMutation, EditaGeneroMutationVariables>;
export const ToggleStatusGeneroDocument = gql`
	mutation ToggleStatusGenero($id: Int!, $status: Status!) {
		editaGenero(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusGeneroMutationFn = Apollo.MutationFunction<ToggleStatusGeneroMutation, ToggleStatusGeneroMutationVariables>;

/**
 * __useToggleStatusGeneroMutation__
 *
 * To run a mutation, you first call `useToggleStatusGeneroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusGeneroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusGeneroMutation, { data, loading, error }] = useToggleStatusGeneroMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusGeneroMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusGeneroMutation, ToggleStatusGeneroMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusGeneroMutation, ToggleStatusGeneroMutationVariables>(ToggleStatusGeneroDocument, options);
}
export type ToggleStatusGeneroMutationHookResult = ReturnType<typeof useToggleStatusGeneroMutation>;
export type ToggleStatusGeneroMutationResult = Apollo.MutationResult<ToggleStatusGeneroMutation>;
export type ToggleStatusGeneroMutationOptions = Apollo.BaseMutationOptions<ToggleStatusGeneroMutation, ToggleStatusGeneroMutationVariables>;
export const GeneroDocument = gql`
	query Genero($filtro: InputFiltroGenero) {
		genero(Filtro: $filtro) {
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useGeneroQuery__
 *
 * To run a query within a React component, call `useGeneroQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneroQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useGeneroQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GeneroQuery, GeneroQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<GeneroQuery, GeneroQueryVariables>(GeneroDocument, options);
}
export function useGeneroLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeneroQuery, GeneroQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<GeneroQuery, GeneroQueryVariables>(GeneroDocument, options);
}
export type GeneroQueryHookResult = ReturnType<typeof useGeneroQuery>;
export type GeneroLazyQueryHookResult = ReturnType<typeof useGeneroLazyQuery>;
export type GeneroQueryResult = Apollo.QueryResult<GeneroQuery, GeneroQueryVariables>;
export const GenerosDocument = gql`
	query Generos($filtro: InputFiltroGeneros) {
		generos(Filtro: $filtro) {
			Id
			Descricao
			IdEmpresa
			Status
		}
	}
`;

/**
 * __useGenerosQuery__
 *
 * To run a query within a React component, call `useGenerosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useGenerosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerosQuery, GenerosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<GenerosQuery, GenerosQueryVariables>(GenerosDocument, options);
}
export function useGenerosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerosQuery, GenerosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<GenerosQuery, GenerosQueryVariables>(GenerosDocument, options);
}
export type GenerosQueryHookResult = ReturnType<typeof useGenerosQuery>;
export type GenerosLazyQueryHookResult = ReturnType<typeof useGenerosLazyQuery>;
export type GenerosQueryResult = Apollo.QueryResult<GenerosQuery, GenerosQueryVariables>;
export const GeneroByIdDocument = gql`
	query GeneroById($Id: Int!) {
		genero(Filtro: { Id: $Id }) {
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useGeneroByIdQuery__
 *
 * To run a query within a React component, call `useGeneroByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneroByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneroByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useGeneroByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GeneroByIdQuery, GeneroByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<GeneroByIdQuery, GeneroByIdQueryVariables>(GeneroByIdDocument, options);
}
export function useGeneroByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeneroByIdQuery, GeneroByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<GeneroByIdQuery, GeneroByIdQueryVariables>(GeneroByIdDocument, options);
}
export type GeneroByIdQueryHookResult = ReturnType<typeof useGeneroByIdQuery>;
export type GeneroByIdLazyQueryHookResult = ReturnType<typeof useGeneroByIdLazyQuery>;
export type GeneroByIdQueryResult = Apollo.QueryResult<GeneroByIdQuery, GeneroByIdQueryVariables>;
export const GenerosSelectDocument = gql`
	query GenerosSelect($IdGenero: Int) {
		generos(Filtro: { Status: "ATIVO", Ids: [$IdGenero] }) {
			Id
			Descricao
			IdEmpresa
			Status
		}
	}
`;

/**
 * __useGenerosSelectQuery__
 *
 * To run a query within a React component, call `useGenerosSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerosSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerosSelectQuery({
 *   variables: {
 *      IdGenero: // value for 'IdGenero'
 *   },
 * });
 */
export function useGenerosSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerosSelectQuery, GenerosSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<GenerosSelectQuery, GenerosSelectQueryVariables>(GenerosSelectDocument, options);
}
export function useGenerosSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerosSelectQuery, GenerosSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<GenerosSelectQuery, GenerosSelectQueryVariables>(GenerosSelectDocument, options);
}
export type GenerosSelectQueryHookResult = ReturnType<typeof useGenerosSelectQuery>;
export type GenerosSelectLazyQueryHookResult = ReturnType<typeof useGenerosSelectLazyQuery>;
export type GenerosSelectQueryResult = Apollo.QueryResult<GenerosSelectQuery, GenerosSelectQueryVariables>;
export const CadastraClienteDocument = gql`
	mutation CadastraCliente($dados: InputCadastrarCliente!) {
		cadastraCliente(Dados: $dados)
	}
`;
export type CadastraClienteMutationFn = Apollo.MutationFunction<CadastraClienteMutation, CadastraClienteMutationVariables>;

/**
 * __useCadastraClienteMutation__
 *
 * To run a mutation, you first call `useCadastraClienteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraClienteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraClienteMutation, { data, loading, error }] = useCadastraClienteMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraClienteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraClienteMutation, CadastraClienteMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraClienteMutation, CadastraClienteMutationVariables>(CadastraClienteDocument, options);
}
export type CadastraClienteMutationHookResult = ReturnType<typeof useCadastraClienteMutation>;
export type CadastraClienteMutationResult = Apollo.MutationResult<CadastraClienteMutation>;
export type CadastraClienteMutationOptions = Apollo.BaseMutationOptions<CadastraClienteMutation, CadastraClienteMutationVariables>;
export const CadastraClienteVinculaPessoaDocument = gql`
	mutation CadastraClienteVinculaPessoa($dados: InputCadastrarClientePessoa!) {
		cadastraClienteVinculaPessoa(Dados: $dados)
	}
`;
export type CadastraClienteVinculaPessoaMutationFn = Apollo.MutationFunction<CadastraClienteVinculaPessoaMutation, CadastraClienteVinculaPessoaMutationVariables>;

/**
 * __useCadastraClienteVinculaPessoaMutation__
 *
 * To run a mutation, you first call `useCadastraClienteVinculaPessoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraClienteVinculaPessoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraClienteVinculaPessoaMutation, { data, loading, error }] = useCadastraClienteVinculaPessoaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraClienteVinculaPessoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraClienteVinculaPessoaMutation, CadastraClienteVinculaPessoaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraClienteVinculaPessoaMutation, CadastraClienteVinculaPessoaMutationVariables>(CadastraClienteVinculaPessoaDocument, options);
}
export type CadastraClienteVinculaPessoaMutationHookResult = ReturnType<typeof useCadastraClienteVinculaPessoaMutation>;
export type CadastraClienteVinculaPessoaMutationResult = Apollo.MutationResult<CadastraClienteVinculaPessoaMutation>;
export type CadastraClienteVinculaPessoaMutationOptions = Apollo.BaseMutationOptions<CadastraClienteVinculaPessoaMutation, CadastraClienteVinculaPessoaMutationVariables>;
export const EditaClienteDocument = gql`
	mutation EditaCliente($dados: InputEditarCliente!) {
		editaCliente(Dados: $dados)
	}
`;
export type EditaClienteMutationFn = Apollo.MutationFunction<EditaClienteMutation, EditaClienteMutationVariables>;

/**
 * __useEditaClienteMutation__
 *
 * To run a mutation, you first call `useEditaClienteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaClienteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaClienteMutation, { data, loading, error }] = useEditaClienteMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaClienteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaClienteMutation, EditaClienteMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaClienteMutation, EditaClienteMutationVariables>(EditaClienteDocument, options);
}
export type EditaClienteMutationHookResult = ReturnType<typeof useEditaClienteMutation>;
export type EditaClienteMutationResult = Apollo.MutationResult<EditaClienteMutation>;
export type EditaClienteMutationOptions = Apollo.BaseMutationOptions<EditaClienteMutation, EditaClienteMutationVariables>;
export const ToggleStatusClienteDocument = gql`
	mutation ToggleStatusCliente($id: Int!, $status: Status!) {
		editaCliente(Dados: { IdPessoa: $id, Status: $status })
	}
`;
export type ToggleStatusClienteMutationFn = Apollo.MutationFunction<ToggleStatusClienteMutation, ToggleStatusClienteMutationVariables>;

/**
 * __useToggleStatusClienteMutation__
 *
 * To run a mutation, you first call `useToggleStatusClienteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusClienteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusClienteMutation, { data, loading, error }] = useToggleStatusClienteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusClienteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusClienteMutation, ToggleStatusClienteMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusClienteMutation, ToggleStatusClienteMutationVariables>(ToggleStatusClienteDocument, options);
}
export type ToggleStatusClienteMutationHookResult = ReturnType<typeof useToggleStatusClienteMutation>;
export type ToggleStatusClienteMutationResult = Apollo.MutationResult<ToggleStatusClienteMutation>;
export type ToggleStatusClienteMutationOptions = Apollo.BaseMutationOptions<ToggleStatusClienteMutation, ToggleStatusClienteMutationVariables>;
export const ClienteDocument = gql`
	query Cliente($filtro: InputFiltroCliente) {
		cliente(Filtro: $filtro) {
			Bairro
			Cargo
			Celular
			Celular2
			Cep
			CidadeNome
			Codigo
			Uf
			Complemento
			Contatos {
				Id
				Contato
				Descricao
				Tipo
			}
			CpfCnpj
			DataAdmissao
			DataAlteracao
			IdPessoa
			DataCadastro
			DataNascimento
			Email
			EnderecoComercial
			EstadoCivil
			FoneComercial
			Foto
			IdCategoria
			IdCidade
			IdEstado
			IdGenero
			IdPais
			IdVendedor
			InscricaoEstadual
			InscricaoMunicipal
			LimiteCredito
			LocalTrabalho
			Logradouro
			Nome
			NomeFantasia
			NumEndereco
			Observacao
			PermitirVenda
			ReferenciasComerciais {
				Id
				Contato
				Descricao
				Telefone
			}
			RegimeTributario
			RegimeTributarioICMS
			RendaMensal
			RG
			SpcSerasa
			Status
			Telefone
		}
	}
`;

/**
 * __useClienteQuery__
 *
 * To run a query within a React component, call `useClienteQuery` and pass it any options that fit your needs.
 * When your component renders, `useClienteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClienteQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useClienteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClienteQuery, ClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ClienteQuery, ClienteQueryVariables>(ClienteDocument, options);
}
export function useClienteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClienteQuery, ClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ClienteQuery, ClienteQueryVariables>(ClienteDocument, options);
}
export type ClienteQueryHookResult = ReturnType<typeof useClienteQuery>;
export type ClienteLazyQueryHookResult = ReturnType<typeof useClienteLazyQuery>;
export type ClienteQueryResult = Apollo.QueryResult<ClienteQuery, ClienteQueryVariables>;
export const ClientesDocument = gql`
	query Clientes($paginacao: InputPaginacao, $filtro: InputFiltroClientes) {
		clientes(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				IdPessoa
				Codigo
				Nome
				NomeFantasia
				IdPais
				IdCidade
				CpfCnpj
				Telefone
				Email
				Bairro
				CidadeNome
				Uf
				IdEstado
				Status
				RegimeTributarioICMS
				InscricaoEstadual
				InscricaoMunicipal
				RG
				NomeCategoria
				DescricaoGenero
				EstadoCivil
				RegimeTributario
				DataNascimento
				DataCadastro
				DataAlteracao
				Observacao
				Cep
				Logradouro
				NumEndereco
				Complemento
				Celular
				Celular2
				LocalTrabalho
				FoneComercial
				EnderecoComercial
				Cargo
				RendaMensal
				DataAdmissao
				LimiteCredito
				SpcSerasa
				PermitirVenda
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useClientesQuery__
 *
 * To run a query within a React component, call `useClientesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientesQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useClientesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClientesQuery, ClientesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ClientesQuery, ClientesQueryVariables>(ClientesDocument, options);
}
export function useClientesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientesQuery, ClientesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ClientesQuery, ClientesQueryVariables>(ClientesDocument, options);
}
export type ClientesQueryHookResult = ReturnType<typeof useClientesQuery>;
export type ClientesLazyQueryHookResult = ReturnType<typeof useClientesLazyQuery>;
export type ClientesQueryResult = Apollo.QueryResult<ClientesQuery, ClientesQueryVariables>;
export const ClientesVendaDocument = gql`
	query ClientesVenda($paginacao: InputPaginacao, $filtro: InputFiltroClientes) {
		clientes(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				IdPessoa
				Codigo
				Nome
				NomeFantasia
				IdPais
				IdCidade
				CpfCnpj
				Telefone
				Email
				Bairro
				CidadeNome
				Uf
				IdEstado
				Status
				RegimeTributarioICMS
				InscricaoEstadual
				InscricaoMunicipal
				RG
				NomeCategoria
				DescricaoGenero
				EstadoCivil
				RegimeTributario
				DataNascimento
				DataCadastro
				DataAlteracao
				Observacao
				Cep
				Logradouro
				NumEndereco
				Complemento
				Celular
				Celular2
				LocalTrabalho
				FoneComercial
				EnderecoComercial
				Cargo
				RendaMensal
				DataAdmissao
				LimiteCredito
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useClientesVendaQuery__
 *
 * To run a query within a React component, call `useClientesVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientesVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientesVendaQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useClientesVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClientesVendaQuery, ClientesVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ClientesVendaQuery, ClientesVendaQueryVariables>(ClientesVendaDocument, options);
}
export function useClientesVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientesVendaQuery, ClientesVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ClientesVendaQuery, ClientesVendaQueryVariables>(ClientesVendaDocument, options);
}
export type ClientesVendaQueryHookResult = ReturnType<typeof useClientesVendaQuery>;
export type ClientesVendaLazyQueryHookResult = ReturnType<typeof useClientesVendaLazyQuery>;
export type ClientesVendaQueryResult = Apollo.QueryResult<ClientesVendaQuery, ClientesVendaQueryVariables>;
export const CodigoProxClienteDocument = gql`
	query CodigoProxCliente {
		codigoProximoCliente
	}
`;

/**
 * __useCodigoProxClienteQuery__
 *
 * To run a query within a React component, call `useCodigoProxClienteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoProxClienteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoProxClienteQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodigoProxClienteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoProxClienteQuery, CodigoProxClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoProxClienteQuery, CodigoProxClienteQueryVariables>(CodigoProxClienteDocument, options);
}
export function useCodigoProxClienteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoProxClienteQuery, CodigoProxClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoProxClienteQuery, CodigoProxClienteQueryVariables>(CodigoProxClienteDocument, options);
}
export type CodigoProxClienteQueryHookResult = ReturnType<typeof useCodigoProxClienteQuery>;
export type CodigoProxClienteLazyQueryHookResult = ReturnType<typeof useCodigoProxClienteLazyQuery>;
export type CodigoProxClienteQueryResult = Apollo.QueryResult<CodigoProxClienteQuery, CodigoProxClienteQueryVariables>;
export const CadastraDepartamentoDocument = gql`
	mutation CadastraDepartamento($dados: InputCadastrarDepartamento!) {
		cadastraDepartamento(Dados: $dados)
	}
`;
export type CadastraDepartamentoMutationFn = Apollo.MutationFunction<CadastraDepartamentoMutation, CadastraDepartamentoMutationVariables>;

/**
 * __useCadastraDepartamentoMutation__
 *
 * To run a mutation, you first call `useCadastraDepartamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraDepartamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraDepartamentoMutation, { data, loading, error }] = useCadastraDepartamentoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraDepartamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraDepartamentoMutation, CadastraDepartamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraDepartamentoMutation, CadastraDepartamentoMutationVariables>(CadastraDepartamentoDocument, options);
}
export type CadastraDepartamentoMutationHookResult = ReturnType<typeof useCadastraDepartamentoMutation>;
export type CadastraDepartamentoMutationResult = Apollo.MutationResult<CadastraDepartamentoMutation>;
export type CadastraDepartamentoMutationOptions = Apollo.BaseMutationOptions<CadastraDepartamentoMutation, CadastraDepartamentoMutationVariables>;
export const EditaDepartamentoDocument = gql`
	mutation EditaDepartamento($dados: InputEditarDepartamento!) {
		editaDepartamento(Dados: $dados)
	}
`;
export type EditaDepartamentoMutationFn = Apollo.MutationFunction<EditaDepartamentoMutation, EditaDepartamentoMutationVariables>;

/**
 * __useEditaDepartamentoMutation__
 *
 * To run a mutation, you first call `useEditaDepartamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaDepartamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaDepartamentoMutation, { data, loading, error }] = useEditaDepartamentoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaDepartamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaDepartamentoMutation, EditaDepartamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaDepartamentoMutation, EditaDepartamentoMutationVariables>(EditaDepartamentoDocument, options);
}
export type EditaDepartamentoMutationHookResult = ReturnType<typeof useEditaDepartamentoMutation>;
export type EditaDepartamentoMutationResult = Apollo.MutationResult<EditaDepartamentoMutation>;
export type EditaDepartamentoMutationOptions = Apollo.BaseMutationOptions<EditaDepartamentoMutation, EditaDepartamentoMutationVariables>;
export const ToggleStatusDepartamentoDocument = gql`
	mutation ToggleStatusDepartamento($id: Int!, $status: Status!) {
		editaDepartamento(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusDepartamentoMutationFn = Apollo.MutationFunction<ToggleStatusDepartamentoMutation, ToggleStatusDepartamentoMutationVariables>;

/**
 * __useToggleStatusDepartamentoMutation__
 *
 * To run a mutation, you first call `useToggleStatusDepartamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusDepartamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusDepartamentoMutation, { data, loading, error }] = useToggleStatusDepartamentoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusDepartamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusDepartamentoMutation, ToggleStatusDepartamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusDepartamentoMutation, ToggleStatusDepartamentoMutationVariables>(ToggleStatusDepartamentoDocument, options);
}
export type ToggleStatusDepartamentoMutationHookResult = ReturnType<typeof useToggleStatusDepartamentoMutation>;
export type ToggleStatusDepartamentoMutationResult = Apollo.MutationResult<ToggleStatusDepartamentoMutation>;
export type ToggleStatusDepartamentoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusDepartamentoMutation, ToggleStatusDepartamentoMutationVariables>;
export const DepartamentoDocument = gql`
	query Departamento($filtro: InputFiltroDepartamento) {
		departamento(Filtro: $filtro) {
			Id
			Nome
			Producao
			Vendas
			Tecnico
			Status
		}
	}
`;

/**
 * __useDepartamentoQuery__
 *
 * To run a query within a React component, call `useDepartamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartamentoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useDepartamentoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartamentoQuery, DepartamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DepartamentoQuery, DepartamentoQueryVariables>(DepartamentoDocument, options);
}
export function useDepartamentoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartamentoQuery, DepartamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DepartamentoQuery, DepartamentoQueryVariables>(DepartamentoDocument, options);
}
export type DepartamentoQueryHookResult = ReturnType<typeof useDepartamentoQuery>;
export type DepartamentoLazyQueryHookResult = ReturnType<typeof useDepartamentoLazyQuery>;
export type DepartamentoQueryResult = Apollo.QueryResult<DepartamentoQuery, DepartamentoQueryVariables>;
export const DepartamentosDocument = gql`
	query Departamentos($filtro: InputFiltroDepartamentos) {
		departamentos(Filtro: $filtro) {
			Id
			Nome
			Producao
			Vendas
			Tecnico
			Status
		}
	}
`;

/**
 * __useDepartamentosQuery__
 *
 * To run a query within a React component, call `useDepartamentosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartamentosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartamentosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useDepartamentosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartamentosQuery, DepartamentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DepartamentosQuery, DepartamentosQueryVariables>(DepartamentosDocument, options);
}
export function useDepartamentosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartamentosQuery, DepartamentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DepartamentosQuery, DepartamentosQueryVariables>(DepartamentosDocument, options);
}
export type DepartamentosQueryHookResult = ReturnType<typeof useDepartamentosQuery>;
export type DepartamentosLazyQueryHookResult = ReturnType<typeof useDepartamentosLazyQuery>;
export type DepartamentosQueryResult = Apollo.QueryResult<DepartamentosQuery, DepartamentosQueryVariables>;
export const DepartamentoByIdDocument = gql`
	query DepartamentoById($Id: Int!) {
		departamento(Filtro: { Id: $Id }) {
			Id
			Nome
			Producao
			Vendas
			Tecnico
			Status
		}
	}
`;

/**
 * __useDepartamentoByIdQuery__
 *
 * To run a query within a React component, call `useDepartamentoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartamentoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartamentoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDepartamentoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DepartamentoByIdQuery, DepartamentoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DepartamentoByIdQuery, DepartamentoByIdQueryVariables>(DepartamentoByIdDocument, options);
}
export function useDepartamentoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartamentoByIdQuery, DepartamentoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DepartamentoByIdQuery, DepartamentoByIdQueryVariables>(DepartamentoByIdDocument, options);
}
export type DepartamentoByIdQueryHookResult = ReturnType<typeof useDepartamentoByIdQuery>;
export type DepartamentoByIdLazyQueryHookResult = ReturnType<typeof useDepartamentoByIdLazyQuery>;
export type DepartamentoByIdQueryResult = Apollo.QueryResult<DepartamentoByIdQuery, DepartamentoByIdQueryVariables>;
export const CadastraColaboradorDocument = gql`
	mutation CadastraColaborador($dados: InputCadastrarColaborador!) {
		cadastraColaborador(Dados: $dados)
	}
`;
export type CadastraColaboradorMutationFn = Apollo.MutationFunction<CadastraColaboradorMutation, CadastraColaboradorMutationVariables>;

/**
 * __useCadastraColaboradorMutation__
 *
 * To run a mutation, you first call `useCadastraColaboradorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraColaboradorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraColaboradorMutation, { data, loading, error }] = useCadastraColaboradorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraColaboradorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraColaboradorMutation, CadastraColaboradorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraColaboradorMutation, CadastraColaboradorMutationVariables>(CadastraColaboradorDocument, options);
}
export type CadastraColaboradorMutationHookResult = ReturnType<typeof useCadastraColaboradorMutation>;
export type CadastraColaboradorMutationResult = Apollo.MutationResult<CadastraColaboradorMutation>;
export type CadastraColaboradorMutationOptions = Apollo.BaseMutationOptions<CadastraColaboradorMutation, CadastraColaboradorMutationVariables>;
export const RemoverUsuarioDocument = gql`
	mutation RemoverUsuario($dados: InputRemoverUsuario!) {
		removerUsuario(Dados: $dados)
	}
`;
export type RemoverUsuarioMutationFn = Apollo.MutationFunction<RemoverUsuarioMutation, RemoverUsuarioMutationVariables>;

/**
 * __useRemoverUsuarioMutation__
 *
 * To run a mutation, you first call `useRemoverUsuarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverUsuarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerUsuarioMutation, { data, loading, error }] = useRemoverUsuarioMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRemoverUsuarioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoverUsuarioMutation, RemoverUsuarioMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RemoverUsuarioMutation, RemoverUsuarioMutationVariables>(RemoverUsuarioDocument, options);
}
export type RemoverUsuarioMutationHookResult = ReturnType<typeof useRemoverUsuarioMutation>;
export type RemoverUsuarioMutationResult = Apollo.MutationResult<RemoverUsuarioMutation>;
export type RemoverUsuarioMutationOptions = Apollo.BaseMutationOptions<RemoverUsuarioMutation, RemoverUsuarioMutationVariables>;
export const EditaColaboradorDocument = gql`
	mutation EditaColaborador($dados: InputEditarColaborador!) {
		editaColaborador(Dados: $dados)
	}
`;
export type EditaColaboradorMutationFn = Apollo.MutationFunction<EditaColaboradorMutation, EditaColaboradorMutationVariables>;

/**
 * __useEditaColaboradorMutation__
 *
 * To run a mutation, you first call `useEditaColaboradorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaColaboradorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaColaboradorMutation, { data, loading, error }] = useEditaColaboradorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaColaboradorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaColaboradorMutation, EditaColaboradorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaColaboradorMutation, EditaColaboradorMutationVariables>(EditaColaboradorDocument, options);
}
export type EditaColaboradorMutationHookResult = ReturnType<typeof useEditaColaboradorMutation>;
export type EditaColaboradorMutationResult = Apollo.MutationResult<EditaColaboradorMutation>;
export type EditaColaboradorMutationOptions = Apollo.BaseMutationOptions<EditaColaboradorMutation, EditaColaboradorMutationVariables>;
export const CadastraColaboradorVinculaPessoaDocument = gql`
	mutation CadastraColaboradorVinculaPessoa($dados: InputCadastrarColaboradorPessoa!) {
		cadastraColaboradorVinculaPessoa(Dados: $dados)
	}
`;
export type CadastraColaboradorVinculaPessoaMutationFn = Apollo.MutationFunction<CadastraColaboradorVinculaPessoaMutation, CadastraColaboradorVinculaPessoaMutationVariables>;

/**
 * __useCadastraColaboradorVinculaPessoaMutation__
 *
 * To run a mutation, you first call `useCadastraColaboradorVinculaPessoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraColaboradorVinculaPessoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraColaboradorVinculaPessoaMutation, { data, loading, error }] = useCadastraColaboradorVinculaPessoaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraColaboradorVinculaPessoaMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraColaboradorVinculaPessoaMutation, CadastraColaboradorVinculaPessoaMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraColaboradorVinculaPessoaMutation, CadastraColaboradorVinculaPessoaMutationVariables>(CadastraColaboradorVinculaPessoaDocument, options);
}
export type CadastraColaboradorVinculaPessoaMutationHookResult = ReturnType<typeof useCadastraColaboradorVinculaPessoaMutation>;
export type CadastraColaboradorVinculaPessoaMutationResult = Apollo.MutationResult<CadastraColaboradorVinculaPessoaMutation>;
export type CadastraColaboradorVinculaPessoaMutationOptions = Apollo.BaseMutationOptions<CadastraColaboradorVinculaPessoaMutation, CadastraColaboradorVinculaPessoaMutationVariables>;
export const RemoverAcessoColaboradorEmpresaDocument = gql`
	mutation RemoverAcessoColaboradorEmpresa($dados: InputRemoverAcessoColaboradorEmpresa!) {
		removerAcessoColaboradorEmpresa(Dados: $dados)
	}
`;
export type RemoverAcessoColaboradorEmpresaMutationFn = Apollo.MutationFunction<RemoverAcessoColaboradorEmpresaMutation, RemoverAcessoColaboradorEmpresaMutationVariables>;

/**
 * __useRemoverAcessoColaboradorEmpresaMutation__
 *
 * To run a mutation, you first call `useRemoverAcessoColaboradorEmpresaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverAcessoColaboradorEmpresaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerAcessoColaboradorEmpresaMutation, { data, loading, error }] = useRemoverAcessoColaboradorEmpresaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRemoverAcessoColaboradorEmpresaMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<RemoverAcessoColaboradorEmpresaMutation, RemoverAcessoColaboradorEmpresaMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RemoverAcessoColaboradorEmpresaMutation, RemoverAcessoColaboradorEmpresaMutationVariables>(RemoverAcessoColaboradorEmpresaDocument, options);
}
export type RemoverAcessoColaboradorEmpresaMutationHookResult = ReturnType<typeof useRemoverAcessoColaboradorEmpresaMutation>;
export type RemoverAcessoColaboradorEmpresaMutationResult = Apollo.MutationResult<RemoverAcessoColaboradorEmpresaMutation>;
export type RemoverAcessoColaboradorEmpresaMutationOptions = Apollo.BaseMutationOptions<RemoverAcessoColaboradorEmpresaMutation, RemoverAcessoColaboradorEmpresaMutationVariables>;
export const FornecerAcessoColaboradorEmpresaDocument = gql`
	mutation FornecerAcessoColaboradorEmpresa($dados: InputFornecerAcessoColaboradorEmpresa!) {
		fornecerAcessoColaboradorEmpresa(Dados: $dados)
	}
`;
export type FornecerAcessoColaboradorEmpresaMutationFn = Apollo.MutationFunction<FornecerAcessoColaboradorEmpresaMutation, FornecerAcessoColaboradorEmpresaMutationVariables>;

/**
 * __useFornecerAcessoColaboradorEmpresaMutation__
 *
 * To run a mutation, you first call `useFornecerAcessoColaboradorEmpresaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFornecerAcessoColaboradorEmpresaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fornecerAcessoColaboradorEmpresaMutation, { data, loading, error }] = useFornecerAcessoColaboradorEmpresaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useFornecerAcessoColaboradorEmpresaMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<FornecerAcessoColaboradorEmpresaMutation, FornecerAcessoColaboradorEmpresaMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<FornecerAcessoColaboradorEmpresaMutation, FornecerAcessoColaboradorEmpresaMutationVariables>(FornecerAcessoColaboradorEmpresaDocument, options);
}
export type FornecerAcessoColaboradorEmpresaMutationHookResult = ReturnType<typeof useFornecerAcessoColaboradorEmpresaMutation>;
export type FornecerAcessoColaboradorEmpresaMutationResult = Apollo.MutationResult<FornecerAcessoColaboradorEmpresaMutation>;
export type FornecerAcessoColaboradorEmpresaMutationOptions = Apollo.BaseMutationOptions<FornecerAcessoColaboradorEmpresaMutation, FornecerAcessoColaboradorEmpresaMutationVariables>;
export const ColaboradorDocument = gql`
	query Colaborador($filtro: InputFiltroColaborador) {
		colaborador(Filtro: $filtro) {
			IdPessoa
			Bairro
			Cargo
			Celular
			Celular2
			Cep
			RG
			Foto
			Observacao
			IdPais
			IdEstado
			IdCidade
			Bairro
			Logradouro
			Uf
			NumEndereco
			Complemento
			Celular
			Celular2
			Telefone
			IdDepartamento
			Pis
			Cargo
			DataAdmissao
			DataDemissao
			PorcentComis
			PorcentDescMax
			Status
			Codigo
			Complemento
			CpfCnpj
			DataAdmissao
			DataDemissao
			DataNascimento
			Email
			Login
			Nome
			Permissoes {
				Status
				NomePermissao
				IdPermissao
				OpcoesPermissaoLista
			}
		}
	}
`;

/**
 * __useColaboradorQuery__
 *
 * To run a query within a React component, call `useColaboradorQuery` and pass it any options that fit your needs.
 * When your component renders, `useColaboradorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColaboradorQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useColaboradorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ColaboradorQuery, ColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ColaboradorQuery, ColaboradorQueryVariables>(ColaboradorDocument, options);
}
export function useColaboradorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ColaboradorQuery, ColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ColaboradorQuery, ColaboradorQueryVariables>(ColaboradorDocument, options);
}
export type ColaboradorQueryHookResult = ReturnType<typeof useColaboradorQuery>;
export type ColaboradorLazyQueryHookResult = ReturnType<typeof useColaboradorLazyQuery>;
export type ColaboradorQueryResult = Apollo.QueryResult<ColaboradorQuery, ColaboradorQueryVariables>;
export const ColaboradoresDocument = gql`
	query Colaboradores($filtro: InputFiltroColaboradores) {
		colaboradores(Filtro: $filtro) {
			Bairro
			Cargo
			Celular
			Celular2
			Cep
			CidadeNome
			Codigo
			Complemento
			CpfCnpj
			DataAdmissao
			DataDemissao
			DataNascimento
			Email
			Foto
			IdCidade
			IdDepartamento
			IdEmpresa
			IdEstado
			IdPais
			IdPessoa
			InscricaoEstadual
			InscricaoMunicipal
			Login
			Senha
			Pis
			PorcentComis
			PorcentDescMax
			NivelFuncao
			PerfilAcesso
			Status
			Nome
			NomeFantasia
			Logradouro
			NumEndereco
			Telefone
			RG
			Observacao
			RegimeTributario
			RegimeTributarioICMS
			NomeDepartamento
			VendasDepartamento
			ProducaoDepartamento
			TecnicoDepartamento
			StatusDepartamento
			Uf
		}
	}
`;

/**
 * __useColaboradoresQuery__
 *
 * To run a query within a React component, call `useColaboradoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useColaboradoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColaboradoresQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useColaboradoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ColaboradoresQuery, ColaboradoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ColaboradoresQuery, ColaboradoresQueryVariables>(ColaboradoresDocument, options);
}
export function useColaboradoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ColaboradoresQuery, ColaboradoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ColaboradoresQuery, ColaboradoresQueryVariables>(ColaboradoresDocument, options);
}
export type ColaboradoresQueryHookResult = ReturnType<typeof useColaboradoresQuery>;
export type ColaboradoresLazyQueryHookResult = ReturnType<typeof useColaboradoresLazyQuery>;
export type ColaboradoresQueryResult = Apollo.QueryResult<ColaboradoresQuery, ColaboradoresQueryVariables>;
export const CodigoProxColaboradorDocument = gql`
	query CodigoProxColaborador {
		codigoProximoColaborador
	}
`;

/**
 * __useCodigoProxColaboradorQuery__
 *
 * To run a query within a React component, call `useCodigoProxColaboradorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoProxColaboradorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoProxColaboradorQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodigoProxColaboradorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoProxColaboradorQuery, CodigoProxColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoProxColaboradorQuery, CodigoProxColaboradorQueryVariables>(CodigoProxColaboradorDocument, options);
}
export function useCodigoProxColaboradorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoProxColaboradorQuery, CodigoProxColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoProxColaboradorQuery, CodigoProxColaboradorQueryVariables>(CodigoProxColaboradorDocument, options);
}
export type CodigoProxColaboradorQueryHookResult = ReturnType<typeof useCodigoProxColaboradorQuery>;
export type CodigoProxColaboradorLazyQueryHookResult = ReturnType<typeof useCodigoProxColaboradorLazyQuery>;
export type CodigoProxColaboradorQueryResult = Apollo.QueryResult<CodigoProxColaboradorQuery, CodigoProxColaboradorQueryVariables>;
export const ListaPermissoesDocument = gql`
	query ListaPermissoes {
		listaPermissoesColaborador
	}
`;

/**
 * __useListaPermissoesQuery__
 *
 * To run a query within a React component, call `useListaPermissoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaPermissoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaPermissoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListaPermissoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListaPermissoesQuery, ListaPermissoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ListaPermissoesQuery, ListaPermissoesQueryVariables>(ListaPermissoesDocument, options);
}
export function useListaPermissoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListaPermissoesQuery, ListaPermissoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ListaPermissoesQuery, ListaPermissoesQueryVariables>(ListaPermissoesDocument, options);
}
export type ListaPermissoesQueryHookResult = ReturnType<typeof useListaPermissoesQuery>;
export type ListaPermissoesLazyQueryHookResult = ReturnType<typeof useListaPermissoesLazyQuery>;
export type ListaPermissoesQueryResult = Apollo.QueryResult<ListaPermissoesQuery, ListaPermissoesQueryVariables>;
export const VendedoresDocument = gql`
	query Vendedores($IdVendedor: Int, $Codigo: [String]) {
		colaboradores(Filtro: { VendasDepartamento: "ATIVO", Status: "ATIVO", Ids: [$IdVendedor], Codigo: $Codigo }) {
			IdPessoa
			Codigo
			Nome
			NomeFantasia
			CpfCnpj
		}
	}
`;

/**
 * __useVendedoresQuery__
 *
 * To run a query within a React component, call `useVendedoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendedoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendedoresQuery({
 *   variables: {
 *      IdVendedor: // value for 'IdVendedor'
 *      Codigo: // value for 'Codigo'
 *   },
 * });
 */
export function useVendedoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VendedoresQuery, VendedoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VendedoresQuery, VendedoresQueryVariables>(VendedoresDocument, options);
}
export function useVendedoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendedoresQuery, VendedoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VendedoresQuery, VendedoresQueryVariables>(VendedoresDocument, options);
}
export type VendedoresQueryHookResult = ReturnType<typeof useVendedoresQuery>;
export type VendedoresLazyQueryHookResult = ReturnType<typeof useVendedoresLazyQuery>;
export type VendedoresQueryResult = Apollo.QueryResult<VendedoresQuery, VendedoresQueryVariables>;
export const AcessosEmpresasColaboradorDocument = gql`
	query AcessosEmpresasColaborador($filtro: InputFiltroColaboradorEmpresa) {
		acessosEmpresasColaborador(Filtro: $filtro) {
			Id
			IdEmpresa
			IdColaborador
			Nome
			NomeFantasia
			NomeFilial
			CpfCnpj
			NomeColaborador
			NomeFantasiaColaborador
			CpfCnpjColaborador
			LoginColaborador
			IdEmpresaColaborador
		}
	}
`;

/**
 * __useAcessosEmpresasColaboradorQuery__
 *
 * To run a query within a React component, call `useAcessosEmpresasColaboradorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessosEmpresasColaboradorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessosEmpresasColaboradorQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useAcessosEmpresasColaboradorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AcessosEmpresasColaboradorQuery, AcessosEmpresasColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<AcessosEmpresasColaboradorQuery, AcessosEmpresasColaboradorQueryVariables>(AcessosEmpresasColaboradorDocument, options);
}
export function useAcessosEmpresasColaboradorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcessosEmpresasColaboradorQuery, AcessosEmpresasColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<AcessosEmpresasColaboradorQuery, AcessosEmpresasColaboradorQueryVariables>(AcessosEmpresasColaboradorDocument, options);
}
export type AcessosEmpresasColaboradorQueryHookResult = ReturnType<typeof useAcessosEmpresasColaboradorQuery>;
export type AcessosEmpresasColaboradorLazyQueryHookResult = ReturnType<typeof useAcessosEmpresasColaboradorLazyQuery>;
export type AcessosEmpresasColaboradorQueryResult = Apollo.QueryResult<AcessosEmpresasColaboradorQuery, AcessosEmpresasColaboradorQueryVariables>;
export const CadastraContadorDocument = gql`
	mutation CadastraContador($dados: InputCadastrarContador!) {
		cadastraContador(Dados: $dados)
	}
`;
export type CadastraContadorMutationFn = Apollo.MutationFunction<CadastraContadorMutation, CadastraContadorMutationVariables>;

/**
 * __useCadastraContadorMutation__
 *
 * To run a mutation, you first call `useCadastraContadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraContadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraContadorMutation, { data, loading, error }] = useCadastraContadorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraContadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraContadorMutation, CadastraContadorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraContadorMutation, CadastraContadorMutationVariables>(CadastraContadorDocument, options);
}
export type CadastraContadorMutationHookResult = ReturnType<typeof useCadastraContadorMutation>;
export type CadastraContadorMutationResult = Apollo.MutationResult<CadastraContadorMutation>;
export type CadastraContadorMutationOptions = Apollo.BaseMutationOptions<CadastraContadorMutation, CadastraContadorMutationVariables>;
export const EditaContadorDocument = gql`
	mutation EditaContador($dados: InputEditarContador!) {
		editaContador(Dados: $dados)
	}
`;
export type EditaContadorMutationFn = Apollo.MutationFunction<EditaContadorMutation, EditaContadorMutationVariables>;

/**
 * __useEditaContadorMutation__
 *
 * To run a mutation, you first call `useEditaContadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaContadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaContadorMutation, { data, loading, error }] = useEditaContadorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaContadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaContadorMutation, EditaContadorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaContadorMutation, EditaContadorMutationVariables>(EditaContadorDocument, options);
}
export type EditaContadorMutationHookResult = ReturnType<typeof useEditaContadorMutation>;
export type EditaContadorMutationResult = Apollo.MutationResult<EditaContadorMutation>;
export type EditaContadorMutationOptions = Apollo.BaseMutationOptions<EditaContadorMutation, EditaContadorMutationVariables>;
export const ToggleStatusContadorDocument = gql`
	mutation ToggleStatusContador($id: Int!, $status: Status!) {
		editaContador(Dados: { IdPessoa: $id, Status: $status })
	}
`;
export type ToggleStatusContadorMutationFn = Apollo.MutationFunction<ToggleStatusContadorMutation, ToggleStatusContadorMutationVariables>;

/**
 * __useToggleStatusContadorMutation__
 *
 * To run a mutation, you first call `useToggleStatusContadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusContadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusContadorMutation, { data, loading, error }] = useToggleStatusContadorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusContadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusContadorMutation, ToggleStatusContadorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusContadorMutation, ToggleStatusContadorMutationVariables>(ToggleStatusContadorDocument, options);
}
export type ToggleStatusContadorMutationHookResult = ReturnType<typeof useToggleStatusContadorMutation>;
export type ToggleStatusContadorMutationResult = Apollo.MutationResult<ToggleStatusContadorMutation>;
export type ToggleStatusContadorMutationOptions = Apollo.BaseMutationOptions<ToggleStatusContadorMutation, ToggleStatusContadorMutationVariables>;
export const ContadorDocument = gql`
	query Contador($filtro: InputFiltroContador) {
		contador(Filtro: $filtro) {
			IdPessoa
			Crc
			Status
			Nome
			NomeFantasia
			CpfCnpj
			Cpf
			IdPais
			IdCidade
			Cep
			Bairro
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			DataCadastro
			DataAlteracao
			Uf
			IdEstado
		}
	}
`;

/**
 * __useContadorQuery__
 *
 * To run a query within a React component, call `useContadorQuery` and pass it any options that fit your needs.
 * When your component renders, `useContadorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContadorQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useContadorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContadorQuery, ContadorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ContadorQuery, ContadorQueryVariables>(ContadorDocument, options);
}
export function useContadorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContadorQuery, ContadorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ContadorQuery, ContadorQueryVariables>(ContadorDocument, options);
}
export type ContadorQueryHookResult = ReturnType<typeof useContadorQuery>;
export type ContadorLazyQueryHookResult = ReturnType<typeof useContadorLazyQuery>;
export type ContadorQueryResult = Apollo.QueryResult<ContadorQuery, ContadorQueryVariables>;
export const CadastraFornecedorDocument = gql`
	mutation CadastraFornecedor($dados: InputCadastrarFornecedor!) {
		cadastraFornecedor(Dados: $dados)
	}
`;
export type CadastraFornecedorMutationFn = Apollo.MutationFunction<CadastraFornecedorMutation, CadastraFornecedorMutationVariables>;

/**
 * __useCadastraFornecedorMutation__
 *
 * To run a mutation, you first call `useCadastraFornecedorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraFornecedorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraFornecedorMutation, { data, loading, error }] = useCadastraFornecedorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraFornecedorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraFornecedorMutation, CadastraFornecedorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraFornecedorMutation, CadastraFornecedorMutationVariables>(CadastraFornecedorDocument, options);
}
export type CadastraFornecedorMutationHookResult = ReturnType<typeof useCadastraFornecedorMutation>;
export type CadastraFornecedorMutationResult = Apollo.MutationResult<CadastraFornecedorMutation>;
export type CadastraFornecedorMutationOptions = Apollo.BaseMutationOptions<CadastraFornecedorMutation, CadastraFornecedorMutationVariables>;
export const EditaFornecedorDocument = gql`
	mutation EditaFornecedor($dados: InputEditarFornecedor!) {
		editaFornecedor(Dados: $dados)
	}
`;
export type EditaFornecedorMutationFn = Apollo.MutationFunction<EditaFornecedorMutation, EditaFornecedorMutationVariables>;

/**
 * __useEditaFornecedorMutation__
 *
 * To run a mutation, you first call `useEditaFornecedorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaFornecedorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaFornecedorMutation, { data, loading, error }] = useEditaFornecedorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaFornecedorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaFornecedorMutation, EditaFornecedorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaFornecedorMutation, EditaFornecedorMutationVariables>(EditaFornecedorDocument, options);
}
export type EditaFornecedorMutationHookResult = ReturnType<typeof useEditaFornecedorMutation>;
export type EditaFornecedorMutationResult = Apollo.MutationResult<EditaFornecedorMutation>;
export type EditaFornecedorMutationOptions = Apollo.BaseMutationOptions<EditaFornecedorMutation, EditaFornecedorMutationVariables>;
export const ToggleStatusFornecedorDocument = gql`
	mutation ToggleStatusFornecedor($id: Int!, $status: Status!) {
		editaFornecedor(Dados: { IdPessoa: $id, Status: $status })
	}
`;
export type ToggleStatusFornecedorMutationFn = Apollo.MutationFunction<ToggleStatusFornecedorMutation, ToggleStatusFornecedorMutationVariables>;

/**
 * __useToggleStatusFornecedorMutation__
 *
 * To run a mutation, you first call `useToggleStatusFornecedorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusFornecedorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusFornecedorMutation, { data, loading, error }] = useToggleStatusFornecedorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusFornecedorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusFornecedorMutation, ToggleStatusFornecedorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusFornecedorMutation, ToggleStatusFornecedorMutationVariables>(ToggleStatusFornecedorDocument, options);
}
export type ToggleStatusFornecedorMutationHookResult = ReturnType<typeof useToggleStatusFornecedorMutation>;
export type ToggleStatusFornecedorMutationResult = Apollo.MutationResult<ToggleStatusFornecedorMutation>;
export type ToggleStatusFornecedorMutationOptions = Apollo.BaseMutationOptions<ToggleStatusFornecedorMutation, ToggleStatusFornecedorMutationVariables>;
export const CadastraFornecedorVinculaPessoaDocument = gql`
	mutation CadastraFornecedorVinculaPessoa($dados: InputCadastrarFornecedorPessoa!) {
		cadastraFornecedorVinculaPessoa(Dados: $dados)
	}
`;
export type CadastraFornecedorVinculaPessoaMutationFn = Apollo.MutationFunction<CadastraFornecedorVinculaPessoaMutation, CadastraFornecedorVinculaPessoaMutationVariables>;

/**
 * __useCadastraFornecedorVinculaPessoaMutation__
 *
 * To run a mutation, you first call `useCadastraFornecedorVinculaPessoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraFornecedorVinculaPessoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraFornecedorVinculaPessoaMutation, { data, loading, error }] = useCadastraFornecedorVinculaPessoaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraFornecedorVinculaPessoaMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraFornecedorVinculaPessoaMutation, CadastraFornecedorVinculaPessoaMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraFornecedorVinculaPessoaMutation, CadastraFornecedorVinculaPessoaMutationVariables>(CadastraFornecedorVinculaPessoaDocument, options);
}
export type CadastraFornecedorVinculaPessoaMutationHookResult = ReturnType<typeof useCadastraFornecedorVinculaPessoaMutation>;
export type CadastraFornecedorVinculaPessoaMutationResult = Apollo.MutationResult<CadastraFornecedorVinculaPessoaMutation>;
export type CadastraFornecedorVinculaPessoaMutationOptions = Apollo.BaseMutationOptions<CadastraFornecedorVinculaPessoaMutation, CadastraFornecedorVinculaPessoaMutationVariables>;
export const FornecedorDocument = gql`
	query Fornecedor($filtro: InputFiltroFornecedor) {
		fornecedor(Filtro: $filtro) {
			IdPessoa
			Codigo
			Site
			Status
			Nome
			NomeFantasia
			CpfCnpj
			IdPais
			IdCidade
			Cep
			Bairro
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useFornecedorQuery__
 *
 * To run a query within a React component, call `useFornecedorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFornecedorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFornecedorQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useFornecedorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FornecedorQuery, FornecedorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<FornecedorQuery, FornecedorQueryVariables>(FornecedorDocument, options);
}
export function useFornecedorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FornecedorQuery, FornecedorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<FornecedorQuery, FornecedorQueryVariables>(FornecedorDocument, options);
}
export type FornecedorQueryHookResult = ReturnType<typeof useFornecedorQuery>;
export type FornecedorLazyQueryHookResult = ReturnType<typeof useFornecedorLazyQuery>;
export type FornecedorQueryResult = Apollo.QueryResult<FornecedorQuery, FornecedorQueryVariables>;
export const FornecedoresDocument = gql`
	query Fornecedores($filtro: InputFiltroFornecedores) {
		fornecedores(Filtro: $filtro) {
			IdPessoa
			Codigo
			Site
			Status
			Nome
			NomeFantasia
			CpfCnpj
			IdPais
			IdCidade
			Cep
			Bairro
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useFornecedoresQuery__
 *
 * To run a query within a React component, call `useFornecedoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useFornecedoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFornecedoresQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useFornecedoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FornecedoresQuery, FornecedoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<FornecedoresQuery, FornecedoresQueryVariables>(FornecedoresDocument, options);
}
export function useFornecedoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FornecedoresQuery, FornecedoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<FornecedoresQuery, FornecedoresQueryVariables>(FornecedoresDocument, options);
}
export type FornecedoresQueryHookResult = ReturnType<typeof useFornecedoresQuery>;
export type FornecedoresLazyQueryHookResult = ReturnType<typeof useFornecedoresLazyQuery>;
export type FornecedoresQueryResult = Apollo.QueryResult<FornecedoresQuery, FornecedoresQueryVariables>;
export const FornecedorByIdDocument = gql`
	query FornecedorById($Id: Int!) {
		fornecedor(Filtro: { IdPessoa: $Id }) {
			IdPessoa
			Codigo
			Site
			Status
			Nome
			NomeFantasia
			CpfCnpj
			IdPais
			IdCidade
			Cep
			Bairro
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useFornecedorByIdQuery__
 *
 * To run a query within a React component, call `useFornecedorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFornecedorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFornecedorByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useFornecedorByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FornecedorByIdQuery, FornecedorByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<FornecedorByIdQuery, FornecedorByIdQueryVariables>(FornecedorByIdDocument, options);
}
export function useFornecedorByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FornecedorByIdQuery, FornecedorByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<FornecedorByIdQuery, FornecedorByIdQueryVariables>(FornecedorByIdDocument, options);
}
export type FornecedorByIdQueryHookResult = ReturnType<typeof useFornecedorByIdQuery>;
export type FornecedorByIdLazyQueryHookResult = ReturnType<typeof useFornecedorByIdLazyQuery>;
export type FornecedorByIdQueryResult = Apollo.QueryResult<FornecedorByIdQuery, FornecedorByIdQueryVariables>;
export const CodProxFornecedorDocument = gql`
	query CodProxFornecedor {
		codigoProximoFornecedor
	}
`;

/**
 * __useCodProxFornecedorQuery__
 *
 * To run a query within a React component, call `useCodProxFornecedorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodProxFornecedorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodProxFornecedorQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodProxFornecedorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodProxFornecedorQuery, CodProxFornecedorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodProxFornecedorQuery, CodProxFornecedorQueryVariables>(CodProxFornecedorDocument, options);
}
export function useCodProxFornecedorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodProxFornecedorQuery, CodProxFornecedorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodProxFornecedorQuery, CodProxFornecedorQueryVariables>(CodProxFornecedorDocument, options);
}
export type CodProxFornecedorQueryHookResult = ReturnType<typeof useCodProxFornecedorQuery>;
export type CodProxFornecedorLazyQueryHookResult = ReturnType<typeof useCodProxFornecedorLazyQuery>;
export type CodProxFornecedorQueryResult = Apollo.QueryResult<CodProxFornecedorQuery, CodProxFornecedorQueryVariables>;
export const PessoaDocument = gql`
	query Pessoa($filtro: InputFiltroPessoa) {
		pessoa(Filtro: $filtro) {
			Id
			IdEmpresa
			Nome
			NomeFantasia
			CpfCnpj
			IdPais
			IdCidade
			Cep
			Bairro
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			DataCadastro
			DataAlteracao
			Status
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
			IdCliente
			IdColaborador
			IdContador
			IdFornecedor
			IdTransportadora
		}
	}
`;

/**
 * __usePessoaQuery__
 *
 * To run a query within a React component, call `usePessoaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePessoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePessoaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePessoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PessoaQuery, PessoaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PessoaQuery, PessoaQueryVariables>(PessoaDocument, options);
}
export function usePessoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PessoaQuery, PessoaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PessoaQuery, PessoaQueryVariables>(PessoaDocument, options);
}
export type PessoaQueryHookResult = ReturnType<typeof usePessoaQuery>;
export type PessoaLazyQueryHookResult = ReturnType<typeof usePessoaLazyQuery>;
export type PessoaQueryResult = Apollo.QueryResult<PessoaQuery, PessoaQueryVariables>;
export const PessoasDocument = gql`
	query Pessoas($filtro: InputFiltroPessoas, $paginacao: InputPaginacao) {
		pessoas(Filtro: $filtro, Paginacao: $paginacao) {
			Data {
				Id
				IdEmpresa
				Nome
				NomeFantasia
				CpfCnpj
				IdPais
				IdCidade
				Cep
				Bairro
				Logradouro
				NumEndereco
				Complemento
				Telefone
				Celular
				Celular2
				Email
				RG
				InscricaoEstadual
				InscricaoMunicipal
				Observacao
				DataNascimento
				DataCadastro
				DataAlteracao
				Status
				RegimeTributario
				RegimeTributarioICMS
				CidadeNome
				Uf
				IdEstado
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __usePessoasQuery__
 *
 * To run a query within a React component, call `usePessoasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePessoasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePessoasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function usePessoasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PessoasQuery, PessoasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PessoasQuery, PessoasQueryVariables>(PessoasDocument, options);
}
export function usePessoasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PessoasQuery, PessoasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PessoasQuery, PessoasQueryVariables>(PessoasDocument, options);
}
export type PessoasQueryHookResult = ReturnType<typeof usePessoasQuery>;
export type PessoasLazyQueryHookResult = ReturnType<typeof usePessoasLazyQuery>;
export type PessoasQueryResult = Apollo.QueryResult<PessoasQuery, PessoasQueryVariables>;
export const CadastraTransportadoraDocument = gql`
	mutation CadastraTransportadora($dados: InputCadastrarTransportadora!) {
		cadastraTransportadora(Dados: $dados)
	}
`;
export type CadastraTransportadoraMutationFn = Apollo.MutationFunction<CadastraTransportadoraMutation, CadastraTransportadoraMutationVariables>;

/**
 * __useCadastraTransportadoraMutation__
 *
 * To run a mutation, you first call `useCadastraTransportadoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraTransportadoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraTransportadoraMutation, { data, loading, error }] = useCadastraTransportadoraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraTransportadoraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraTransportadoraMutation, CadastraTransportadoraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraTransportadoraMutation, CadastraTransportadoraMutationVariables>(CadastraTransportadoraDocument, options);
}
export type CadastraTransportadoraMutationHookResult = ReturnType<typeof useCadastraTransportadoraMutation>;
export type CadastraTransportadoraMutationResult = Apollo.MutationResult<CadastraTransportadoraMutation>;
export type CadastraTransportadoraMutationOptions = Apollo.BaseMutationOptions<CadastraTransportadoraMutation, CadastraTransportadoraMutationVariables>;
export const EditaTransportadoraDocument = gql`
	mutation EditaTransportadora($dados: InputEditarTransportadora!) {
		editaTransportadora(Dados: $dados)
	}
`;
export type EditaTransportadoraMutationFn = Apollo.MutationFunction<EditaTransportadoraMutation, EditaTransportadoraMutationVariables>;

/**
 * __useEditaTransportadoraMutation__
 *
 * To run a mutation, you first call `useEditaTransportadoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaTransportadoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaTransportadoraMutation, { data, loading, error }] = useEditaTransportadoraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaTransportadoraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaTransportadoraMutation, EditaTransportadoraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaTransportadoraMutation, EditaTransportadoraMutationVariables>(EditaTransportadoraDocument, options);
}
export type EditaTransportadoraMutationHookResult = ReturnType<typeof useEditaTransportadoraMutation>;
export type EditaTransportadoraMutationResult = Apollo.MutationResult<EditaTransportadoraMutation>;
export type EditaTransportadoraMutationOptions = Apollo.BaseMutationOptions<EditaTransportadoraMutation, EditaTransportadoraMutationVariables>;
export const ToggleStatusTransportadoraDocument = gql`
	mutation ToggleStatusTransportadora($id: Int!, $status: Status!) {
		editaTransportadora(Dados: { IdPessoa: $id, Status: $status })
	}
`;
export type ToggleStatusTransportadoraMutationFn = Apollo.MutationFunction<ToggleStatusTransportadoraMutation, ToggleStatusTransportadoraMutationVariables>;

/**
 * __useToggleStatusTransportadoraMutation__
 *
 * To run a mutation, you first call `useToggleStatusTransportadoraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusTransportadoraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusTransportadoraMutation, { data, loading, error }] = useToggleStatusTransportadoraMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusTransportadoraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusTransportadoraMutation, ToggleStatusTransportadoraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusTransportadoraMutation, ToggleStatusTransportadoraMutationVariables>(ToggleStatusTransportadoraDocument, options);
}
export type ToggleStatusTransportadoraMutationHookResult = ReturnType<typeof useToggleStatusTransportadoraMutation>;
export type ToggleStatusTransportadoraMutationResult = Apollo.MutationResult<ToggleStatusTransportadoraMutation>;
export type ToggleStatusTransportadoraMutationOptions = Apollo.BaseMutationOptions<ToggleStatusTransportadoraMutation, ToggleStatusTransportadoraMutationVariables>;
export const TransportadoraDocument = gql`
	query Transportadora($filtro: InputFiltroTransportadora) {
		transportadora(Filtro: $filtro) {
			IdPessoa
			IdEmpresa
			Codigo
			Status
			Nome
			NomeFantasia
			CpfCnpj
			IdCidade
			IdPais
			Cep
			Bairro
			Logradouro
			NumEndereco
			Veiculos {
				Id
				IdTransportadora
				IdEstado
				IdEstadoReboque
				Placa
				Rntc
				ReboquePlaca
				ReboqueRntc
				Status
				UF
			}
			Complemento
			Telefone
			Celular
			Celular2
			Email
			DataCadastro
			DataAlteracao
			RG
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
		}
	}
`;

/**
 * __useTransportadoraQuery__
 *
 * To run a query within a React component, call `useTransportadoraQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportadoraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportadoraQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useTransportadoraQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransportadoraQuery, TransportadoraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<TransportadoraQuery, TransportadoraQueryVariables>(TransportadoraDocument, options);
}
export function useTransportadoraLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransportadoraQuery, TransportadoraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<TransportadoraQuery, TransportadoraQueryVariables>(TransportadoraDocument, options);
}
export type TransportadoraQueryHookResult = ReturnType<typeof useTransportadoraQuery>;
export type TransportadoraLazyQueryHookResult = ReturnType<typeof useTransportadoraLazyQuery>;
export type TransportadoraQueryResult = Apollo.QueryResult<TransportadoraQuery, TransportadoraQueryVariables>;
export const TransportadorasDocument = gql`
	query Transportadoras($filtro: InputFiltroTransportadoras) {
		transportadoras(Filtro: $filtro) {
			IdPessoa
			Codigo
			Status
			Nome
			NomeFantasia
			CpfCnpj
			IdCidade
			Cep
			Bairro
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			IdPais
			Veiculos {
				Id
				IdTransportadora
				IdEstado
				IdEstadoReboque
				Placa
				Rntc
				ReboquePlaca
				ReboqueRntc
				Status
				UF
			}
			InscricaoEstadual
			InscricaoMunicipal
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
		}
	}
`;

/**
 * __useTransportadorasQuery__
 *
 * To run a query within a React component, call `useTransportadorasQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportadorasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportadorasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useTransportadorasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransportadorasQuery, TransportadorasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<TransportadorasQuery, TransportadorasQueryVariables>(TransportadorasDocument, options);
}
export function useTransportadorasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransportadorasQuery, TransportadorasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<TransportadorasQuery, TransportadorasQueryVariables>(TransportadorasDocument, options);
}
export type TransportadorasQueryHookResult = ReturnType<typeof useTransportadorasQuery>;
export type TransportadorasLazyQueryHookResult = ReturnType<typeof useTransportadorasLazyQuery>;
export type TransportadorasQueryResult = Apollo.QueryResult<TransportadorasQuery, TransportadorasQueryVariables>;
export const TransportadoraByIdDocument = gql`
	query TransportadoraById($Id: Int!) {
		transportadora(Filtro: { IdPessoa: $Id }) {
			IdPessoa
			IdEmpresa
			Codigo
			Status
			Nome
			NomeFantasia
			CpfCnpj
			IdCidade
			Cep
			Bairro
			Veiculos {
				Id
				IdTransportadora
				IdEstado
				IdEstadoReboque
				Placa
				Rntc
				ReboquePlaca
				ReboqueRntc
				Status
				UF
			}
			Logradouro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			Email
			RG
			InscricaoEstadual
			InscricaoMunicipal
			DataCadastro
			DataAlteracao
			Observacao
			DataNascimento
			RegimeTributario
			RegimeTributarioICMS
			CidadeNome
			Uf
			IdEstado
		}
	}
`;

/**
 * __useTransportadoraByIdQuery__
 *
 * To run a query within a React component, call `useTransportadoraByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportadoraByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportadoraByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useTransportadoraByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TransportadoraByIdQuery, TransportadoraByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<TransportadoraByIdQuery, TransportadoraByIdQueryVariables>(TransportadoraByIdDocument, options);
}
export function useTransportadoraByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransportadoraByIdQuery, TransportadoraByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<TransportadoraByIdQuery, TransportadoraByIdQueryVariables>(TransportadoraByIdDocument, options);
}
export type TransportadoraByIdQueryHookResult = ReturnType<typeof useTransportadoraByIdQuery>;
export type TransportadoraByIdLazyQueryHookResult = ReturnType<typeof useTransportadoraByIdLazyQuery>;
export type TransportadoraByIdQueryResult = Apollo.QueryResult<TransportadoraByIdQuery, TransportadoraByIdQueryVariables>;
export const CodProxTransportadoraDocument = gql`
	query CodProxTransportadora {
		codigoProximaTransportadora
	}
`;

/**
 * __useCodProxTransportadoraQuery__
 *
 * To run a query within a React component, call `useCodProxTransportadoraQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodProxTransportadoraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodProxTransportadoraQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodProxTransportadoraQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodProxTransportadoraQuery, CodProxTransportadoraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodProxTransportadoraQuery, CodProxTransportadoraQueryVariables>(CodProxTransportadoraDocument, options);
}
export function useCodProxTransportadoraLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodProxTransportadoraQuery, CodProxTransportadoraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodProxTransportadoraQuery, CodProxTransportadoraQueryVariables>(CodProxTransportadoraDocument, options);
}
export type CodProxTransportadoraQueryHookResult = ReturnType<typeof useCodProxTransportadoraQuery>;
export type CodProxTransportadoraLazyQueryHookResult = ReturnType<typeof useCodProxTransportadoraLazyQuery>;
export type CodProxTransportadoraQueryResult = Apollo.QueryResult<CodProxTransportadoraQuery, CodProxTransportadoraQueryVariables>;
export const VeiculosDocument = gql`
	query Veiculos($filtro: InputFiltroVeiculos) {
		veiculos(Filtro: $filtro) {
			Id
			IdTransportadora
			IdEstado
			IdEstadoReboque
			Placa
			Rntc
			ReboquePlaca
			ReboqueRntc
			Status
			UF
		}
	}
`;

/**
 * __useVeiculosQuery__
 *
 * To run a query within a React component, call `useVeiculosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVeiculosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVeiculosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useVeiculosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VeiculosQuery, VeiculosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VeiculosQuery, VeiculosQueryVariables>(VeiculosDocument, options);
}
export function useVeiculosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VeiculosQuery, VeiculosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VeiculosQuery, VeiculosQueryVariables>(VeiculosDocument, options);
}
export type VeiculosQueryHookResult = ReturnType<typeof useVeiculosQuery>;
export type VeiculosLazyQueryHookResult = ReturnType<typeof useVeiculosLazyQuery>;
export type VeiculosQueryResult = Apollo.QueryResult<VeiculosQuery, VeiculosQueryVariables>;
export const VisualizarNotificacaoDocument = gql`
	mutation VisualizarNotificacao($id: Int!) {
		visualizarNotificacao(Id: $id)
	}
`;
export type VisualizarNotificacaoMutationFn = Apollo.MutationFunction<VisualizarNotificacaoMutation, VisualizarNotificacaoMutationVariables>;

/**
 * __useVisualizarNotificacaoMutation__
 *
 * To run a mutation, you first call `useVisualizarNotificacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisualizarNotificacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visualizarNotificacaoMutation, { data, loading, error }] = useVisualizarNotificacaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisualizarNotificacaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VisualizarNotificacaoMutation, VisualizarNotificacaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<VisualizarNotificacaoMutation, VisualizarNotificacaoMutationVariables>(VisualizarNotificacaoDocument, options);
}
export type VisualizarNotificacaoMutationHookResult = ReturnType<typeof useVisualizarNotificacaoMutation>;
export type VisualizarNotificacaoMutationResult = Apollo.MutationResult<VisualizarNotificacaoMutation>;
export type VisualizarNotificacaoMutationOptions = Apollo.BaseMutationOptions<VisualizarNotificacaoMutation, VisualizarNotificacaoMutationVariables>;
export const VisualizarTodasNotificacoesDocument = gql`
	mutation VisualizarTodasNotificacoes {
		visualizarTodasNotificacoes
	}
`;
export type VisualizarTodasNotificacoesMutationFn = Apollo.MutationFunction<VisualizarTodasNotificacoesMutation, VisualizarTodasNotificacoesMutationVariables>;

/**
 * __useVisualizarTodasNotificacoesMutation__
 *
 * To run a mutation, you first call `useVisualizarTodasNotificacoesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisualizarTodasNotificacoesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visualizarTodasNotificacoesMutation, { data, loading, error }] = useVisualizarTodasNotificacoesMutation({
 *   variables: {
 *   },
 * });
 */
export function useVisualizarTodasNotificacoesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VisualizarTodasNotificacoesMutation, VisualizarTodasNotificacoesMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<VisualizarTodasNotificacoesMutation, VisualizarTodasNotificacoesMutationVariables>(VisualizarTodasNotificacoesDocument, options);
}
export type VisualizarTodasNotificacoesMutationHookResult = ReturnType<typeof useVisualizarTodasNotificacoesMutation>;
export type VisualizarTodasNotificacoesMutationResult = Apollo.MutationResult<VisualizarTodasNotificacoesMutation>;
export type VisualizarTodasNotificacoesMutationOptions = Apollo.BaseMutationOptions<VisualizarTodasNotificacoesMutation, VisualizarTodasNotificacoesMutationVariables>;
export const RespostaAcessoDocument = gql`
	mutation RespostaAcesso($dados: InputRespostaAcesso!) {
		respostaAcesso(Dados: $dados)
	}
`;
export type RespostaAcessoMutationFn = Apollo.MutationFunction<RespostaAcessoMutation, RespostaAcessoMutationVariables>;

/**
 * __useRespostaAcessoMutation__
 *
 * To run a mutation, you first call `useRespostaAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespostaAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respostaAcessoMutation, { data, loading, error }] = useRespostaAcessoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRespostaAcessoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespostaAcessoMutation, RespostaAcessoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RespostaAcessoMutation, RespostaAcessoMutationVariables>(RespostaAcessoDocument, options);
}
export type RespostaAcessoMutationHookResult = ReturnType<typeof useRespostaAcessoMutation>;
export type RespostaAcessoMutationResult = Apollo.MutationResult<RespostaAcessoMutation>;
export type RespostaAcessoMutationOptions = Apollo.BaseMutationOptions<RespostaAcessoMutation, RespostaAcessoMutationVariables>;
export const CancelarAcessoDocument = gql`
	mutation CancelarAcesso($dados: InputRequisicaoAcesso!) {
		cancelarAcesso(Dados: $dados)
	}
`;
export type CancelarAcessoMutationFn = Apollo.MutationFunction<CancelarAcessoMutation, CancelarAcessoMutationVariables>;

/**
 * __useCancelarAcessoMutation__
 *
 * To run a mutation, you first call `useCancelarAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelarAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelarAcessoMutation, { data, loading, error }] = useCancelarAcessoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCancelarAcessoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelarAcessoMutation, CancelarAcessoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CancelarAcessoMutation, CancelarAcessoMutationVariables>(CancelarAcessoDocument, options);
}
export type CancelarAcessoMutationHookResult = ReturnType<typeof useCancelarAcessoMutation>;
export type CancelarAcessoMutationResult = Apollo.MutationResult<CancelarAcessoMutation>;
export type CancelarAcessoMutationOptions = Apollo.BaseMutationOptions<CancelarAcessoMutation, CancelarAcessoMutationVariables>;
export const RequisicaoAcessoDocument = gql`
	mutation RequisicaoAcesso($dados: InputRequisicaoAcesso!) {
		requisicaoAcesso(Dados: $dados)
	}
`;
export type RequisicaoAcessoMutationFn = Apollo.MutationFunction<RequisicaoAcessoMutation, RequisicaoAcessoMutationVariables>;

/**
 * __useRequisicaoAcessoMutation__
 *
 * To run a mutation, you first call `useRequisicaoAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisicaoAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisicaoAcessoMutation, { data, loading, error }] = useRequisicaoAcessoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRequisicaoAcessoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequisicaoAcessoMutation, RequisicaoAcessoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RequisicaoAcessoMutation, RequisicaoAcessoMutationVariables>(RequisicaoAcessoDocument, options);
}
export type RequisicaoAcessoMutationHookResult = ReturnType<typeof useRequisicaoAcessoMutation>;
export type RequisicaoAcessoMutationResult = Apollo.MutationResult<RequisicaoAcessoMutation>;
export type RequisicaoAcessoMutationOptions = Apollo.BaseMutationOptions<RequisicaoAcessoMutation, RequisicaoAcessoMutationVariables>;
export const RemoverAcessoDocument = gql`
	mutation RemoverAcesso($correlationId: String!) {
		removerAcesso(CorrelationId: $correlationId)
	}
`;
export type RemoverAcessoMutationFn = Apollo.MutationFunction<RemoverAcessoMutation, RemoverAcessoMutationVariables>;

/**
 * __useRemoverAcessoMutation__
 *
 * To run a mutation, you first call `useRemoverAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerAcessoMutation, { data, loading, error }] = useRemoverAcessoMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useRemoverAcessoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoverAcessoMutation, RemoverAcessoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RemoverAcessoMutation, RemoverAcessoMutationVariables>(RemoverAcessoDocument, options);
}
export type RemoverAcessoMutationHookResult = ReturnType<typeof useRemoverAcessoMutation>;
export type RemoverAcessoMutationResult = Apollo.MutationResult<RemoverAcessoMutation>;
export type RemoverAcessoMutationOptions = Apollo.BaseMutationOptions<RemoverAcessoMutation, RemoverAcessoMutationVariables>;
export const AtualizarAcessoDocument = gql`
	mutation AtualizarAcesso($correlationId: String!) {
		atualizarAcesso(CorrelationId: $correlationId)
	}
`;
export type AtualizarAcessoMutationFn = Apollo.MutationFunction<AtualizarAcessoMutation, AtualizarAcessoMutationVariables>;

/**
 * __useAtualizarAcessoMutation__
 *
 * To run a mutation, you first call `useAtualizarAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarAcessoMutation, { data, loading, error }] = useAtualizarAcessoMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useAtualizarAcessoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AtualizarAcessoMutation, AtualizarAcessoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<AtualizarAcessoMutation, AtualizarAcessoMutationVariables>(AtualizarAcessoDocument, options);
}
export type AtualizarAcessoMutationHookResult = ReturnType<typeof useAtualizarAcessoMutation>;
export type AtualizarAcessoMutationResult = Apollo.MutationResult<AtualizarAcessoMutation>;
export type AtualizarAcessoMutationOptions = Apollo.BaseMutationOptions<AtualizarAcessoMutation, AtualizarAcessoMutationVariables>;
export const NotificacoesDocument = gql`
	query Notificacoes {
		notificacoes {
			Id
			TipoNotificacao
			LocalExibicao
			Titulo
			Conteudo
			OutrasInformacoes
			DataCriacao
			Status
		}
	}
`;

/**
 * __useNotificacoesQuery__
 *
 * To run a query within a React component, call `useNotificacoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificacoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificacoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificacoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificacoesQuery, NotificacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NotificacoesQuery, NotificacoesQueryVariables>(NotificacoesDocument, options);
}
export function useNotificacoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificacoesQuery, NotificacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NotificacoesQuery, NotificacoesQueryVariables>(NotificacoesDocument, options);
}
export type NotificacoesQueryHookResult = ReturnType<typeof useNotificacoesQuery>;
export type NotificacoesLazyQueryHookResult = ReturnType<typeof useNotificacoesLazyQuery>;
export type NotificacoesQueryResult = Apollo.QueryResult<NotificacoesQuery, NotificacoesQueryVariables>;
export const AcessoChamadoTomTicketDocument = gql`
	query AcessoChamadoTomTicket {
		acessoChamadoTomTicket
	}
`;

/**
 * __useAcessoChamadoTomTicketQuery__
 *
 * To run a query within a React component, call `useAcessoChamadoTomTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoChamadoTomTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoChamadoTomTicketQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcessoChamadoTomTicketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AcessoChamadoTomTicketQuery, AcessoChamadoTomTicketQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<AcessoChamadoTomTicketQuery, AcessoChamadoTomTicketQueryVariables>(AcessoChamadoTomTicketDocument, options);
}
export function useAcessoChamadoTomTicketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcessoChamadoTomTicketQuery, AcessoChamadoTomTicketQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<AcessoChamadoTomTicketQuery, AcessoChamadoTomTicketQueryVariables>(AcessoChamadoTomTicketDocument, options);
}
export type AcessoChamadoTomTicketQueryHookResult = ReturnType<typeof useAcessoChamadoTomTicketQuery>;
export type AcessoChamadoTomTicketLazyQueryHookResult = ReturnType<typeof useAcessoChamadoTomTicketLazyQuery>;
export type AcessoChamadoTomTicketQueryResult = Apollo.QueryResult<AcessoChamadoTomTicketQuery, AcessoChamadoTomTicketQueryVariables>;
export const AcessoChatTomTicketDocument = gql`
	query AcessoChatTomTicket {
		acessoChatTomTicket
	}
`;

/**
 * __useAcessoChatTomTicketQuery__
 *
 * To run a query within a React component, call `useAcessoChatTomTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoChatTomTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoChatTomTicketQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcessoChatTomTicketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AcessoChatTomTicketQuery, AcessoChatTomTicketQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<AcessoChatTomTicketQuery, AcessoChatTomTicketQueryVariables>(AcessoChatTomTicketDocument, options);
}
export function useAcessoChatTomTicketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcessoChatTomTicketQuery, AcessoChatTomTicketQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<AcessoChatTomTicketQuery, AcessoChatTomTicketQueryVariables>(AcessoChatTomTicketDocument, options);
}
export type AcessoChatTomTicketQueryHookResult = ReturnType<typeof useAcessoChatTomTicketQuery>;
export type AcessoChatTomTicketLazyQueryHookResult = ReturnType<typeof useAcessoChatTomTicketLazyQuery>;
export type AcessoChatTomTicketQueryResult = Apollo.QueryResult<AcessoChatTomTicketQuery, AcessoChatTomTicketQueryVariables>;
export const ConfigEmpresaDocument = gql`
	subscription ConfigEmpresa {
		configuracaoEmpresaAtualizada {
			Id
			IdConfig
			Nome
			NomeLegivel
			Valor
		}
	}
`;

/**
 * __useConfigEmpresaSubscription__
 *
 * To run a query within a React component, call `useConfigEmpresaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConfigEmpresaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigEmpresaSubscription({
 *   variables: {
 *   },
 * });
 */
export function useConfigEmpresaSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ConfigEmpresaSubscription, ConfigEmpresaSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<ConfigEmpresaSubscription, ConfigEmpresaSubscriptionVariables>(ConfigEmpresaDocument, options);
}
export type ConfigEmpresaSubscriptionHookResult = ReturnType<typeof useConfigEmpresaSubscription>;
export type ConfigEmpresaSubscriptionResult = Apollo.SubscriptionResult<ConfigEmpresaSubscription>;
export const NotificacaoDocument = gql`
	subscription Notificacao {
		notificacao {
			Id
			TipoNotificacao
			LocalExibicao
			Titulo
			Conteudo
			OutrasInformacoes
			DataCriacao
			Status
		}
	}
`;

/**
 * __useNotificacaoSubscription__
 *
 * To run a query within a React component, call `useNotificacaoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificacaoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificacaoSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificacaoSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NotificacaoSubscription, NotificacaoSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<NotificacaoSubscription, NotificacaoSubscriptionVariables>(NotificacaoDocument, options);
}
export type NotificacaoSubscriptionHookResult = ReturnType<typeof useNotificacaoSubscription>;
export type NotificacaoSubscriptionResult = Apollo.SubscriptionResult<NotificacaoSubscription>;
export const AtualizaCacheDocument = gql`
	subscription AtualizaCache {
		atualizaCache {
			FieldNames
		}
	}
`;

/**
 * __useAtualizaCacheSubscription__
 *
 * To run a query within a React component, call `useAtualizaCacheSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAtualizaCacheSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtualizaCacheSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAtualizaCacheSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AtualizaCacheSubscription, AtualizaCacheSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<AtualizaCacheSubscription, AtualizaCacheSubscriptionVariables>(AtualizaCacheDocument, options);
}
export type AtualizaCacheSubscriptionHookResult = ReturnType<typeof useAtualizaCacheSubscription>;
export type AtualizaCacheSubscriptionResult = Apollo.SubscriptionResult<AtualizaCacheSubscription>;
export const AtualizaCacheSuporteDocument = gql`
	subscription AtualizaCacheSuporte($idEmpresa: Int!) {
		atualizaCacheSuporte(IdEmpresa: $idEmpresa) {
			FieldNames
		}
	}
`;

/**
 * __useAtualizaCacheSuporteSubscription__
 *
 * To run a query within a React component, call `useAtualizaCacheSuporteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAtualizaCacheSuporteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtualizaCacheSuporteSubscription({
 *   variables: {
 *      idEmpresa: // value for 'idEmpresa'
 *   },
 * });
 */
export function useAtualizaCacheSuporteSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<AtualizaCacheSuporteSubscription, AtualizaCacheSuporteSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<AtualizaCacheSuporteSubscription, AtualizaCacheSuporteSubscriptionVariables>(AtualizaCacheSuporteDocument, options);
}
export type AtualizaCacheSuporteSubscriptionHookResult = ReturnType<typeof useAtualizaCacheSuporteSubscription>;
export type AtualizaCacheSuporteSubscriptionResult = Apollo.SubscriptionResult<AtualizaCacheSuporteSubscription>;
export const LogoutDocument = gql`
	mutation Logout {
		logout
	}
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EmpresasColaboradorDocument = gql`
	query EmpresasColaborador {
		empresasColaborador {
			Id
			IdEmpresa
			Nome
			NomeFantasia
			NomeFilial
			CpfCnpj
		}
	}
`;

/**
 * __useEmpresasColaboradorQuery__
 *
 * To run a query within a React component, call `useEmpresasColaboradorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmpresasColaboradorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmpresasColaboradorQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmpresasColaboradorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmpresasColaboradorQuery, EmpresasColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<EmpresasColaboradorQuery, EmpresasColaboradorQueryVariables>(EmpresasColaboradorDocument, options);
}
export function useEmpresasColaboradorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmpresasColaboradorQuery, EmpresasColaboradorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<EmpresasColaboradorQuery, EmpresasColaboradorQueryVariables>(EmpresasColaboradorDocument, options);
}
export type EmpresasColaboradorQueryHookResult = ReturnType<typeof useEmpresasColaboradorQuery>;
export type EmpresasColaboradorLazyQueryHookResult = ReturnType<typeof useEmpresasColaboradorLazyQuery>;
export type EmpresasColaboradorQueryResult = Apollo.QueryResult<EmpresasColaboradorQuery, EmpresasColaboradorQueryVariables>;
export const CadastraCategoriaDocument = gql`
	mutation CadastraCategoria($dados: InputCadastrarCategoria!) {
		cadastraCategoria(Dados: $dados)
	}
`;
export type CadastraCategoriaMutationFn = Apollo.MutationFunction<CadastraCategoriaMutation, CadastraCategoriaMutationVariables>;

/**
 * __useCadastraCategoriaMutation__
 *
 * To run a mutation, you first call `useCadastraCategoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraCategoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraCategoriaMutation, { data, loading, error }] = useCadastraCategoriaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraCategoriaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraCategoriaMutation, CadastraCategoriaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraCategoriaMutation, CadastraCategoriaMutationVariables>(CadastraCategoriaDocument, options);
}
export type CadastraCategoriaMutationHookResult = ReturnType<typeof useCadastraCategoriaMutation>;
export type CadastraCategoriaMutationResult = Apollo.MutationResult<CadastraCategoriaMutation>;
export type CadastraCategoriaMutationOptions = Apollo.BaseMutationOptions<CadastraCategoriaMutation, CadastraCategoriaMutationVariables>;
export const EditaCategoriaDocument = gql`
	mutation EditaCategoria($dados: InputEditarCategoria!) {
		editaCategoria(Dados: $dados)
	}
`;
export type EditaCategoriaMutationFn = Apollo.MutationFunction<EditaCategoriaMutation, EditaCategoriaMutationVariables>;

/**
 * __useEditaCategoriaMutation__
 *
 * To run a mutation, you first call `useEditaCategoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaCategoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaCategoriaMutation, { data, loading, error }] = useEditaCategoriaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaCategoriaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaCategoriaMutation, EditaCategoriaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaCategoriaMutation, EditaCategoriaMutationVariables>(EditaCategoriaDocument, options);
}
export type EditaCategoriaMutationHookResult = ReturnType<typeof useEditaCategoriaMutation>;
export type EditaCategoriaMutationResult = Apollo.MutationResult<EditaCategoriaMutation>;
export type EditaCategoriaMutationOptions = Apollo.BaseMutationOptions<EditaCategoriaMutation, EditaCategoriaMutationVariables>;
export const ToggleStatusCategoriaDocument = gql`
	mutation ToggleStatusCategoria($id: Int!, $status: Status!) {
		editaCategoria(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusCategoriaMutationFn = Apollo.MutationFunction<ToggleStatusCategoriaMutation, ToggleStatusCategoriaMutationVariables>;

/**
 * __useToggleStatusCategoriaMutation__
 *
 * To run a mutation, you first call `useToggleStatusCategoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusCategoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusCategoriaMutation, { data, loading, error }] = useToggleStatusCategoriaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusCategoriaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusCategoriaMutation, ToggleStatusCategoriaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusCategoriaMutation, ToggleStatusCategoriaMutationVariables>(ToggleStatusCategoriaDocument, options);
}
export type ToggleStatusCategoriaMutationHookResult = ReturnType<typeof useToggleStatusCategoriaMutation>;
export type ToggleStatusCategoriaMutationResult = Apollo.MutationResult<ToggleStatusCategoriaMutation>;
export type ToggleStatusCategoriaMutationOptions = Apollo.BaseMutationOptions<ToggleStatusCategoriaMutation, ToggleStatusCategoriaMutationVariables>;
export const CategoriaDocument = gql`
	query Categoria($filtro: InputFiltroCategoria) {
		categoria(Filtro: $filtro) {
			Nome
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useCategoriaQuery__
 *
 * To run a query within a React component, call `useCategoriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCategoriaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriaQuery, CategoriaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriaQuery, CategoriaQueryVariables>(CategoriaDocument, options);
}
export function useCategoriaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriaQuery, CategoriaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriaQuery, CategoriaQueryVariables>(CategoriaDocument, options);
}
export type CategoriaQueryHookResult = ReturnType<typeof useCategoriaQuery>;
export type CategoriaLazyQueryHookResult = ReturnType<typeof useCategoriaLazyQuery>;
export type CategoriaQueryResult = Apollo.QueryResult<CategoriaQuery, CategoriaQueryVariables>;
export const CategoriasDocument = gql`
	query Categorias {
		categorias {
			Id
			Nome
			Status
			Descricao
		}
	}
`;

/**
 * __useCategoriasQuery__
 *
 * To run a query within a React component, call `useCategoriasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriasQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriasQuery, CategoriasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriasQuery, CategoriasQueryVariables>(CategoriasDocument, options);
}
export function useCategoriasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriasQuery, CategoriasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriasQuery, CategoriasQueryVariables>(CategoriasDocument, options);
}
export type CategoriasQueryHookResult = ReturnType<typeof useCategoriasQuery>;
export type CategoriasLazyQueryHookResult = ReturnType<typeof useCategoriasLazyQuery>;
export type CategoriasQueryResult = Apollo.QueryResult<CategoriasQuery, CategoriasQueryVariables>;
export const CategoriaByIdDocument = gql`
	query CategoriaById($Id: Int!) {
		categoria(Filtro: { Id: $Id }) {
			Nome
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useCategoriaByIdQuery__
 *
 * To run a query within a React component, call `useCategoriaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriaByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useCategoriaByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CategoriaByIdQuery, CategoriaByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriaByIdQuery, CategoriaByIdQueryVariables>(CategoriaByIdDocument, options);
}
export function useCategoriaByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriaByIdQuery, CategoriaByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriaByIdQuery, CategoriaByIdQueryVariables>(CategoriaByIdDocument, options);
}
export type CategoriaByIdQueryHookResult = ReturnType<typeof useCategoriaByIdQuery>;
export type CategoriaByIdLazyQueryHookResult = ReturnType<typeof useCategoriaByIdLazyQuery>;
export type CategoriaByIdQueryResult = Apollo.QueryResult<CategoriaByIdQuery, CategoriaByIdQueryVariables>;
export const CategoriasSelectDocument = gql`
	query CategoriasSelect($IdCategoria: Int, $IdSubcategoria: Int) {
		categorias(Filtro: { Status: "ATIVO", Ids: [$IdCategoria] }) {
			Id
			Nome
			Status
			Subcategorias(Filtro: { Status: "ATIVO", Ids: [$IdSubcategoria] }) {
				Id
				Nome
				Status
			}
		}
	}
`;

/**
 * __useCategoriasSelectQuery__
 *
 * To run a query within a React component, call `useCategoriasSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriasSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriasSelectQuery({
 *   variables: {
 *      IdCategoria: // value for 'IdCategoria'
 *      IdSubcategoria: // value for 'IdSubcategoria'
 *   },
 * });
 */
export function useCategoriasSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriasSelectQuery, CategoriasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CategoriasSelectQuery, CategoriasSelectQueryVariables>(CategoriasSelectDocument, options);
}
export function useCategoriasSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriasSelectQuery, CategoriasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CategoriasSelectQuery, CategoriasSelectQueryVariables>(CategoriasSelectDocument, options);
}
export type CategoriasSelectQueryHookResult = ReturnType<typeof useCategoriasSelectQuery>;
export type CategoriasSelectLazyQueryHookResult = ReturnType<typeof useCategoriasSelectLazyQuery>;
export type CategoriasSelectQueryResult = Apollo.QueryResult<CategoriasSelectQuery, CategoriasSelectQueryVariables>;
export const CadastraColecaoDocument = gql`
	mutation CadastraColecao($dados: InputCadastrarColecao!) {
		cadastraColecao(Dados: $dados)
	}
`;
export type CadastraColecaoMutationFn = Apollo.MutationFunction<CadastraColecaoMutation, CadastraColecaoMutationVariables>;

/**
 * __useCadastraColecaoMutation__
 *
 * To run a mutation, you first call `useCadastraColecaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraColecaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraColecaoMutation, { data, loading, error }] = useCadastraColecaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraColecaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraColecaoMutation, CadastraColecaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraColecaoMutation, CadastraColecaoMutationVariables>(CadastraColecaoDocument, options);
}
export type CadastraColecaoMutationHookResult = ReturnType<typeof useCadastraColecaoMutation>;
export type CadastraColecaoMutationResult = Apollo.MutationResult<CadastraColecaoMutation>;
export type CadastraColecaoMutationOptions = Apollo.BaseMutationOptions<CadastraColecaoMutation, CadastraColecaoMutationVariables>;
export const EditaColecaoDocument = gql`
	mutation EditaColecao($dados: InputEditarColecao!) {
		editaColecao(Dados: $dados)
	}
`;
export type EditaColecaoMutationFn = Apollo.MutationFunction<EditaColecaoMutation, EditaColecaoMutationVariables>;

/**
 * __useEditaColecaoMutation__
 *
 * To run a mutation, you first call `useEditaColecaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaColecaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaColecaoMutation, { data, loading, error }] = useEditaColecaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaColecaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaColecaoMutation, EditaColecaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaColecaoMutation, EditaColecaoMutationVariables>(EditaColecaoDocument, options);
}
export type EditaColecaoMutationHookResult = ReturnType<typeof useEditaColecaoMutation>;
export type EditaColecaoMutationResult = Apollo.MutationResult<EditaColecaoMutation>;
export type EditaColecaoMutationOptions = Apollo.BaseMutationOptions<EditaColecaoMutation, EditaColecaoMutationVariables>;
export const ToggleStatusColecaoDocument = gql`
	mutation ToggleStatusColecao($id: Int!, $status: Status!) {
		editaColecao(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusColecaoMutationFn = Apollo.MutationFunction<ToggleStatusColecaoMutation, ToggleStatusColecaoMutationVariables>;

/**
 * __useToggleStatusColecaoMutation__
 *
 * To run a mutation, you first call `useToggleStatusColecaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusColecaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusColecaoMutation, { data, loading, error }] = useToggleStatusColecaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusColecaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusColecaoMutation, ToggleStatusColecaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusColecaoMutation, ToggleStatusColecaoMutationVariables>(ToggleStatusColecaoDocument, options);
}
export type ToggleStatusColecaoMutationHookResult = ReturnType<typeof useToggleStatusColecaoMutation>;
export type ToggleStatusColecaoMutationResult = Apollo.MutationResult<ToggleStatusColecaoMutation>;
export type ToggleStatusColecaoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusColecaoMutation, ToggleStatusColecaoMutationVariables>;
export const ColecaoDocument = gql`
	query Colecao($filtro: InputFiltroColecao) {
		colecao(Filtro: $filtro) {
			Nome
			Id
			Descricao
			Status
		}
	}
`;

/**
 * __useColecaoQuery__
 *
 * To run a query within a React component, call `useColecaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useColecaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColecaoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useColecaoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ColecaoQuery, ColecaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ColecaoQuery, ColecaoQueryVariables>(ColecaoDocument, options);
}
export function useColecaoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ColecaoQuery, ColecaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ColecaoQuery, ColecaoQueryVariables>(ColecaoDocument, options);
}
export type ColecaoQueryHookResult = ReturnType<typeof useColecaoQuery>;
export type ColecaoLazyQueryHookResult = ReturnType<typeof useColecaoLazyQuery>;
export type ColecaoQueryResult = Apollo.QueryResult<ColecaoQuery, ColecaoQueryVariables>;
export const ColecoesDocument = gql`
	query Colecoes {
		colecoes {
			Id
			Nome
			Status
			Descricao
		}
	}
`;

/**
 * __useColecoesQuery__
 *
 * To run a query within a React component, call `useColecoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useColecoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColecoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useColecoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ColecoesQuery, ColecoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ColecoesQuery, ColecoesQueryVariables>(ColecoesDocument, options);
}
export function useColecoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ColecoesQuery, ColecoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ColecoesQuery, ColecoesQueryVariables>(ColecoesDocument, options);
}
export type ColecoesQueryHookResult = ReturnType<typeof useColecoesQuery>;
export type ColecoesLazyQueryHookResult = ReturnType<typeof useColecoesLazyQuery>;
export type ColecoesQueryResult = Apollo.QueryResult<ColecoesQuery, ColecoesQueryVariables>;
export const ColecaoByIdDocument = gql`
	query ColecaoById($Id: Int!) {
		colecao(Filtro: { Id: $Id }) {
			Nome
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useColecaoByIdQuery__
 *
 * To run a query within a React component, call `useColecaoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useColecaoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColecaoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useColecaoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ColecaoByIdQuery, ColecaoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ColecaoByIdQuery, ColecaoByIdQueryVariables>(ColecaoByIdDocument, options);
}
export function useColecaoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ColecaoByIdQuery, ColecaoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ColecaoByIdQuery, ColecaoByIdQueryVariables>(ColecaoByIdDocument, options);
}
export type ColecaoByIdQueryHookResult = ReturnType<typeof useColecaoByIdQuery>;
export type ColecaoByIdLazyQueryHookResult = ReturnType<typeof useColecaoByIdLazyQuery>;
export type ColecaoByIdQueryResult = Apollo.QueryResult<ColecaoByIdQuery, ColecaoByIdQueryVariables>;
export const ColecoesSelectDocument = gql`
	query ColecoesSelect($IdColecao: Int) {
		colecoes(Filtro: { Status: "ATIVO", Ids: [$IdColecao] }) {
			Id
			Nome
			Status
		}
	}
`;

/**
 * __useColecoesSelectQuery__
 *
 * To run a query within a React component, call `useColecoesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useColecoesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColecoesSelectQuery({
 *   variables: {
 *      IdColecao: // value for 'IdColecao'
 *   },
 * });
 */
export function useColecoesSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ColecoesSelectQuery, ColecoesSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ColecoesSelectQuery, ColecoesSelectQueryVariables>(ColecoesSelectDocument, options);
}
export function useColecoesSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ColecoesSelectQuery, ColecoesSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ColecoesSelectQuery, ColecoesSelectQueryVariables>(ColecoesSelectDocument, options);
}
export type ColecoesSelectQueryHookResult = ReturnType<typeof useColecoesSelectQuery>;
export type ColecoesSelectLazyQueryHookResult = ReturnType<typeof useColecoesSelectLazyQuery>;
export type ColecoesSelectQueryResult = Apollo.QueryResult<ColecoesSelectQuery, ColecoesSelectQueryVariables>;
export const CadastraDefinicaoDocument = gql`
	mutation CadastraDefinicao($dados: InputCadastrarDefinicao!) {
		cadastraDefinicao(Dados: $dados)
	}
`;
export type CadastraDefinicaoMutationFn = Apollo.MutationFunction<CadastraDefinicaoMutation, CadastraDefinicaoMutationVariables>;

/**
 * __useCadastraDefinicaoMutation__
 *
 * To run a mutation, you first call `useCadastraDefinicaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraDefinicaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraDefinicaoMutation, { data, loading, error }] = useCadastraDefinicaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraDefinicaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraDefinicaoMutation, CadastraDefinicaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraDefinicaoMutation, CadastraDefinicaoMutationVariables>(CadastraDefinicaoDocument, options);
}
export type CadastraDefinicaoMutationHookResult = ReturnType<typeof useCadastraDefinicaoMutation>;
export type CadastraDefinicaoMutationResult = Apollo.MutationResult<CadastraDefinicaoMutation>;
export type CadastraDefinicaoMutationOptions = Apollo.BaseMutationOptions<CadastraDefinicaoMutation, CadastraDefinicaoMutationVariables>;
export const EditaDefinicaoDocument = gql`
	mutation EditaDefinicao($dados: InputEditarDefinicao!) {
		editaDefinicao(Dados: $dados)
	}
`;
export type EditaDefinicaoMutationFn = Apollo.MutationFunction<EditaDefinicaoMutation, EditaDefinicaoMutationVariables>;

/**
 * __useEditaDefinicaoMutation__
 *
 * To run a mutation, you first call `useEditaDefinicaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaDefinicaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaDefinicaoMutation, { data, loading, error }] = useEditaDefinicaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaDefinicaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaDefinicaoMutation, EditaDefinicaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaDefinicaoMutation, EditaDefinicaoMutationVariables>(EditaDefinicaoDocument, options);
}
export type EditaDefinicaoMutationHookResult = ReturnType<typeof useEditaDefinicaoMutation>;
export type EditaDefinicaoMutationResult = Apollo.MutationResult<EditaDefinicaoMutation>;
export type EditaDefinicaoMutationOptions = Apollo.BaseMutationOptions<EditaDefinicaoMutation, EditaDefinicaoMutationVariables>;
export const ToggleStatusDefinicaoDocument = gql`
	mutation ToggleStatusDefinicao($id: Int!, $status: Status!) {
		editaDefinicao(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusDefinicaoMutationFn = Apollo.MutationFunction<ToggleStatusDefinicaoMutation, ToggleStatusDefinicaoMutationVariables>;

/**
 * __useToggleStatusDefinicaoMutation__
 *
 * To run a mutation, you first call `useToggleStatusDefinicaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusDefinicaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusDefinicaoMutation, { data, loading, error }] = useToggleStatusDefinicaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusDefinicaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusDefinicaoMutation, ToggleStatusDefinicaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusDefinicaoMutation, ToggleStatusDefinicaoMutationVariables>(ToggleStatusDefinicaoDocument, options);
}
export type ToggleStatusDefinicaoMutationHookResult = ReturnType<typeof useToggleStatusDefinicaoMutation>;
export type ToggleStatusDefinicaoMutationResult = Apollo.MutationResult<ToggleStatusDefinicaoMutation>;
export type ToggleStatusDefinicaoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusDefinicaoMutation, ToggleStatusDefinicaoMutationVariables>;
export const DefinicaoDocument = gql`
	query Definicao($filtro: InputFiltroDefinicao) {
		definicao(Filtro: $filtro) {
			Id
			Nome
			Descricao
			Venda
			MateriaPrima
			UsoConsumo
			Producao
			Servico
			Status
		}
	}
`;

/**
 * __useDefinicaoQuery__
 *
 * To run a query within a React component, call `useDefinicaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefinicaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefinicaoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useDefinicaoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefinicaoQuery, DefinicaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DefinicaoQuery, DefinicaoQueryVariables>(DefinicaoDocument, options);
}
export function useDefinicaoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefinicaoQuery, DefinicaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DefinicaoQuery, DefinicaoQueryVariables>(DefinicaoDocument, options);
}
export type DefinicaoQueryHookResult = ReturnType<typeof useDefinicaoQuery>;
export type DefinicaoLazyQueryHookResult = ReturnType<typeof useDefinicaoLazyQuery>;
export type DefinicaoQueryResult = Apollo.QueryResult<DefinicaoQuery, DefinicaoQueryVariables>;
export const DefinicoesDocument = gql`
	query Definicoes {
		definicoes {
			Descricao
			Id
			Nome
			MateriaPrima
			Producao
			Servico
			UsoConsumo
			Venda
			Status
		}
	}
`;

/**
 * __useDefinicoesQuery__
 *
 * To run a query within a React component, call `useDefinicoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefinicoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefinicoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefinicoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefinicoesQuery, DefinicoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DefinicoesQuery, DefinicoesQueryVariables>(DefinicoesDocument, options);
}
export function useDefinicoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefinicoesQuery, DefinicoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DefinicoesQuery, DefinicoesQueryVariables>(DefinicoesDocument, options);
}
export type DefinicoesQueryHookResult = ReturnType<typeof useDefinicoesQuery>;
export type DefinicoesLazyQueryHookResult = ReturnType<typeof useDefinicoesLazyQuery>;
export type DefinicoesQueryResult = Apollo.QueryResult<DefinicoesQuery, DefinicoesQueryVariables>;
export const DefinicaoByIdDocument = gql`
	query DefinicaoById($Id: Int!) {
		definicao(Filtro: { Id: $Id }) {
			Id
			Nome
			Descricao
			Venda
			MateriaPrima
			UsoConsumo
			Producao
			Servico
			Status
		}
	}
`;

/**
 * __useDefinicaoByIdQuery__
 *
 * To run a query within a React component, call `useDefinicaoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefinicaoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefinicaoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDefinicaoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DefinicaoByIdQuery, DefinicaoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DefinicaoByIdQuery, DefinicaoByIdQueryVariables>(DefinicaoByIdDocument, options);
}
export function useDefinicaoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefinicaoByIdQuery, DefinicaoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DefinicaoByIdQuery, DefinicaoByIdQueryVariables>(DefinicaoByIdDocument, options);
}
export type DefinicaoByIdQueryHookResult = ReturnType<typeof useDefinicaoByIdQuery>;
export type DefinicaoByIdLazyQueryHookResult = ReturnType<typeof useDefinicaoByIdLazyQuery>;
export type DefinicaoByIdQueryResult = Apollo.QueryResult<DefinicaoByIdQuery, DefinicaoByIdQueryVariables>;
export const DefinicoesSelectDocument = gql`
	query DefinicoesSelect($IdDefinicao: Int) {
		definicoes(Filtro: { Status: "ATIVO", Ids: [$IdDefinicao] }) {
			Id
			Nome
			Status
		}
	}
`;

/**
 * __useDefinicoesSelectQuery__
 *
 * To run a query within a React component, call `useDefinicoesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefinicoesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefinicoesSelectQuery({
 *   variables: {
 *      IdDefinicao: // value for 'IdDefinicao'
 *   },
 * });
 */
export function useDefinicoesSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefinicoesSelectQuery, DefinicoesSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DefinicoesSelectQuery, DefinicoesSelectQueryVariables>(DefinicoesSelectDocument, options);
}
export function useDefinicoesSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefinicoesSelectQuery, DefinicoesSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DefinicoesSelectQuery, DefinicoesSelectQueryVariables>(DefinicoesSelectDocument, options);
}
export type DefinicoesSelectQueryHookResult = ReturnType<typeof useDefinicoesSelectQuery>;
export type DefinicoesSelectLazyQueryHookResult = ReturnType<typeof useDefinicoesSelectLazyQuery>;
export type DefinicoesSelectQueryResult = Apollo.QueryResult<DefinicoesSelectQuery, DefinicoesSelectQueryVariables>;
export const ToggleStatusModeloGradeDocument = gql`
	mutation ToggleStatusModeloGrade($Id: Int!, $Status: Status!) {
		editaGradeTipo(Dados: { Id: $Id, Status: $Status })
	}
`;
export type ToggleStatusModeloGradeMutationFn = Apollo.MutationFunction<ToggleStatusModeloGradeMutation, ToggleStatusModeloGradeMutationVariables>;

/**
 * __useToggleStatusModeloGradeMutation__
 *
 * To run a mutation, you first call `useToggleStatusModeloGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusModeloGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusModeloGradeMutation, { data, loading, error }] = useToggleStatusModeloGradeMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useToggleStatusModeloGradeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusModeloGradeMutation, ToggleStatusModeloGradeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusModeloGradeMutation, ToggleStatusModeloGradeMutationVariables>(ToggleStatusModeloGradeDocument, options);
}
export type ToggleStatusModeloGradeMutationHookResult = ReturnType<typeof useToggleStatusModeloGradeMutation>;
export type ToggleStatusModeloGradeMutationResult = Apollo.MutationResult<ToggleStatusModeloGradeMutation>;
export type ToggleStatusModeloGradeMutationOptions = Apollo.BaseMutationOptions<ToggleStatusModeloGradeMutation, ToggleStatusModeloGradeMutationVariables>;
export const CadastraModeloGradeDocument = gql`
	mutation CadastraModeloGrade($dados: InputCadastrarGradeTipo!) {
		cadastraGradeTipo(Dados: $dados)
	}
`;
export type CadastraModeloGradeMutationFn = Apollo.MutationFunction<CadastraModeloGradeMutation, CadastraModeloGradeMutationVariables>;

/**
 * __useCadastraModeloGradeMutation__
 *
 * To run a mutation, you first call `useCadastraModeloGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraModeloGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraModeloGradeMutation, { data, loading, error }] = useCadastraModeloGradeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraModeloGradeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraModeloGradeMutation, CadastraModeloGradeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraModeloGradeMutation, CadastraModeloGradeMutationVariables>(CadastraModeloGradeDocument, options);
}
export type CadastraModeloGradeMutationHookResult = ReturnType<typeof useCadastraModeloGradeMutation>;
export type CadastraModeloGradeMutationResult = Apollo.MutationResult<CadastraModeloGradeMutation>;
export type CadastraModeloGradeMutationOptions = Apollo.BaseMutationOptions<CadastraModeloGradeMutation, CadastraModeloGradeMutationVariables>;
export const EditaModeloGradeDocument = gql`
	mutation EditaModeloGrade($dados: InputEditarGradeTipo!) {
		editaGradeTipo(Dados: $dados)
	}
`;
export type EditaModeloGradeMutationFn = Apollo.MutationFunction<EditaModeloGradeMutation, EditaModeloGradeMutationVariables>;

/**
 * __useEditaModeloGradeMutation__
 *
 * To run a mutation, you first call `useEditaModeloGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaModeloGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaModeloGradeMutation, { data, loading, error }] = useEditaModeloGradeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaModeloGradeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaModeloGradeMutation, EditaModeloGradeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaModeloGradeMutation, EditaModeloGradeMutationVariables>(EditaModeloGradeDocument, options);
}
export type EditaModeloGradeMutationHookResult = ReturnType<typeof useEditaModeloGradeMutation>;
export type EditaModeloGradeMutationResult = Apollo.MutationResult<EditaModeloGradeMutation>;
export type EditaModeloGradeMutationOptions = Apollo.BaseMutationOptions<EditaModeloGradeMutation, EditaModeloGradeMutationVariables>;
export const ModelosGradeDocument = gql`
	query ModelosGrade {
		gradesTipos(Filtro: { Visibilidade: TOTAL }) {
			Id
			NomeGrade
			NomeLinha
			NomeColuna
			Tipo
			Status
		}
	}
`;

/**
 * __useModelosGradeQuery__
 *
 * To run a query within a React component, call `useModelosGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelosGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelosGradeQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelosGradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModelosGradeQuery, ModelosGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ModelosGradeQuery, ModelosGradeQueryVariables>(ModelosGradeDocument, options);
}
export function useModelosGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModelosGradeQuery, ModelosGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ModelosGradeQuery, ModelosGradeQueryVariables>(ModelosGradeDocument, options);
}
export type ModelosGradeQueryHookResult = ReturnType<typeof useModelosGradeQuery>;
export type ModelosGradeLazyQueryHookResult = ReturnType<typeof useModelosGradeLazyQuery>;
export type ModelosGradeQueryResult = Apollo.QueryResult<ModelosGradeQuery, ModelosGradeQueryVariables>;
export const ModeloGradeDocument = gql`
	query ModeloGrade($Id: Int!) {
		gradeTipo(Filtro: { Id: $Id }) {
			Id
			NomeGrade
			NomeLinha
			NomeColuna
			Tipo
			Status
		}
	}
`;

/**
 * __useModeloGradeQuery__
 *
 * To run a query within a React component, call `useModeloGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useModeloGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModeloGradeQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useModeloGradeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ModeloGradeQuery, ModeloGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ModeloGradeQuery, ModeloGradeQueryVariables>(ModeloGradeDocument, options);
}
export function useModeloGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModeloGradeQuery, ModeloGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ModeloGradeQuery, ModeloGradeQueryVariables>(ModeloGradeDocument, options);
}
export type ModeloGradeQueryHookResult = ReturnType<typeof useModeloGradeQuery>;
export type ModeloGradeLazyQueryHookResult = ReturnType<typeof useModeloGradeLazyQuery>;
export type ModeloGradeQueryResult = Apollo.QueryResult<ModeloGradeQuery, ModeloGradeQueryVariables>;
export const ModeloGradeByIdDocument = gql`
	query ModeloGradeById($Id: Int!) {
		gradeTipo(Filtro: { Id: $Id }) {
			Id
			IdEmpresa
			IdProduto
			NomeGrade
			NomeLinha
			NomeColuna
			Tipo
			Status
			DataAlteracao
			GradeEixo {
				Id
				IdColuna
				ColunaNome
				ColunaCodigo
				IdLinha
				LinhaCodigo
				LinhaNome
			}
		}
	}
`;

/**
 * __useModeloGradeByIdQuery__
 *
 * To run a query within a React component, call `useModeloGradeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useModeloGradeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModeloGradeByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useModeloGradeByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ModeloGradeByIdQuery, ModeloGradeByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ModeloGradeByIdQuery, ModeloGradeByIdQueryVariables>(ModeloGradeByIdDocument, options);
}
export function useModeloGradeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModeloGradeByIdQuery, ModeloGradeByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ModeloGradeByIdQuery, ModeloGradeByIdQueryVariables>(ModeloGradeByIdDocument, options);
}
export type ModeloGradeByIdQueryHookResult = ReturnType<typeof useModeloGradeByIdQuery>;
export type ModeloGradeByIdLazyQueryHookResult = ReturnType<typeof useModeloGradeByIdLazyQuery>;
export type ModeloGradeByIdQueryResult = Apollo.QueryResult<ModeloGradeByIdQuery, ModeloGradeByIdQueryVariables>;
export const ModelosGradeSelectDocument = gql`
	query ModelosGradeSelect($IdProduto: Int) {
		gradesTipos(Filtro: { Status: "ATIVO", Visibilidade: TOTAL, IdProduto: $IdProduto }) {
			Id
			NomeColuna
			NomeGrade
			NomeLinha
			IdProduto
			Status
			GradeEixo {
				Id
				IdLinha
				LinhaNome
				LinhaCodigo
				IdColuna
				ColunaNome
				ColunaCodigo
				GradeTipoNome
				GradeTipoNomeColuna
				GradeTipoNomeLinha
			}
		}
	}
`;

/**
 * __useModelosGradeSelectQuery__
 *
 * To run a query within a React component, call `useModelosGradeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelosGradeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelosGradeSelectQuery({
 *   variables: {
 *      IdProduto: // value for 'IdProduto'
 *   },
 * });
 */
export function useModelosGradeSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModelosGradeSelectQuery, ModelosGradeSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ModelosGradeSelectQuery, ModelosGradeSelectQueryVariables>(ModelosGradeSelectDocument, options);
}
export function useModelosGradeSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModelosGradeSelectQuery, ModelosGradeSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ModelosGradeSelectQuery, ModelosGradeSelectQueryVariables>(ModelosGradeSelectDocument, options);
}
export type ModelosGradeSelectQueryHookResult = ReturnType<typeof useModelosGradeSelectQuery>;
export type ModelosGradeSelectLazyQueryHookResult = ReturnType<typeof useModelosGradeSelectLazyQuery>;
export type ModelosGradeSelectQueryResult = Apollo.QueryResult<ModelosGradeSelectQuery, ModelosGradeSelectQueryVariables>;
export const ToggleStatusGradeCampoDocument = gql`
	mutation ToggleStatusGradeCampo($id: Int!, $status: Status) {
		editaGradeCampo(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusGradeCampoMutationFn = Apollo.MutationFunction<ToggleStatusGradeCampoMutation, ToggleStatusGradeCampoMutationVariables>;

/**
 * __useToggleStatusGradeCampoMutation__
 *
 * To run a mutation, you first call `useToggleStatusGradeCampoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusGradeCampoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusGradeCampoMutation, { data, loading, error }] = useToggleStatusGradeCampoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusGradeCampoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusGradeCampoMutation, ToggleStatusGradeCampoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusGradeCampoMutation, ToggleStatusGradeCampoMutationVariables>(ToggleStatusGradeCampoDocument, options);
}
export type ToggleStatusGradeCampoMutationHookResult = ReturnType<typeof useToggleStatusGradeCampoMutation>;
export type ToggleStatusGradeCampoMutationResult = Apollo.MutationResult<ToggleStatusGradeCampoMutation>;
export type ToggleStatusGradeCampoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusGradeCampoMutation, ToggleStatusGradeCampoMutationVariables>;
export const CadastraGradeCampoDocument = gql`
	mutation CadastraGradeCampo($dados: InputCadastrarGradeCampo!) {
		cadastraGradeCampo(Dados: $dados)
	}
`;
export type CadastraGradeCampoMutationFn = Apollo.MutationFunction<CadastraGradeCampoMutation, CadastraGradeCampoMutationVariables>;

/**
 * __useCadastraGradeCampoMutation__
 *
 * To run a mutation, you first call `useCadastraGradeCampoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraGradeCampoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraGradeCampoMutation, { data, loading, error }] = useCadastraGradeCampoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraGradeCampoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraGradeCampoMutation, CadastraGradeCampoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraGradeCampoMutation, CadastraGradeCampoMutationVariables>(CadastraGradeCampoDocument, options);
}
export type CadastraGradeCampoMutationHookResult = ReturnType<typeof useCadastraGradeCampoMutation>;
export type CadastraGradeCampoMutationResult = Apollo.MutationResult<CadastraGradeCampoMutation>;
export type CadastraGradeCampoMutationOptions = Apollo.BaseMutationOptions<CadastraGradeCampoMutation, CadastraGradeCampoMutationVariables>;
export const EditarGradeCampoDocument = gql`
	mutation EditarGradeCampo($dados: InputEditarGradeCampo!) {
		editaGradeCampo(Dados: $dados)
	}
`;
export type EditarGradeCampoMutationFn = Apollo.MutationFunction<EditarGradeCampoMutation, EditarGradeCampoMutationVariables>;

/**
 * __useEditarGradeCampoMutation__
 *
 * To run a mutation, you first call `useEditarGradeCampoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarGradeCampoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarGradeCampoMutation, { data, loading, error }] = useEditarGradeCampoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditarGradeCampoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditarGradeCampoMutation, EditarGradeCampoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditarGradeCampoMutation, EditarGradeCampoMutationVariables>(EditarGradeCampoDocument, options);
}
export type EditarGradeCampoMutationHookResult = ReturnType<typeof useEditarGradeCampoMutation>;
export type EditarGradeCampoMutationResult = Apollo.MutationResult<EditarGradeCampoMutation>;
export type EditarGradeCampoMutationOptions = Apollo.BaseMutationOptions<EditarGradeCampoMutation, EditarGradeCampoMutationVariables>;
export const GradesCamposDocument = gql`
	query GradesCampos($tipo: TipoGradeCampo, $status: Status) {
		gradesCampos(Filtro: { Tipo: $tipo, Status: $status }) {
			Id
			Nome
			Codigo
			Tipo
			Status
		}
	}
`;

/**
 * __useGradesCamposQuery__
 *
 * To run a query within a React component, call `useGradesCamposQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradesCamposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradesCamposQuery({
 *   variables: {
 *      tipo: // value for 'tipo'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGradesCamposQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GradesCamposQuery, GradesCamposQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<GradesCamposQuery, GradesCamposQueryVariables>(GradesCamposDocument, options);
}
export function useGradesCamposLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GradesCamposQuery, GradesCamposQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<GradesCamposQuery, GradesCamposQueryVariables>(GradesCamposDocument, options);
}
export type GradesCamposQueryHookResult = ReturnType<typeof useGradesCamposQuery>;
export type GradesCamposLazyQueryHookResult = ReturnType<typeof useGradesCamposLazyQuery>;
export type GradesCamposQueryResult = Apollo.QueryResult<GradesCamposQuery, GradesCamposQueryVariables>;
export const GradeCampoByIdDocument = gql`
	query GradeCampoById($Id: Int!) {
		gradeCampo(Filtro: { Id: $Id }) {
			Id
			Nome
			Codigo
			Tipo
			Status
		}
	}
`;

/**
 * __useGradeCampoByIdQuery__
 *
 * To run a query within a React component, call `useGradeCampoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradeCampoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradeCampoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useGradeCampoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GradeCampoByIdQuery, GradeCampoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<GradeCampoByIdQuery, GradeCampoByIdQueryVariables>(GradeCampoByIdDocument, options);
}
export function useGradeCampoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GradeCampoByIdQuery, GradeCampoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<GradeCampoByIdQuery, GradeCampoByIdQueryVariables>(GradeCampoByIdDocument, options);
}
export type GradeCampoByIdQueryHookResult = ReturnType<typeof useGradeCampoByIdQuery>;
export type GradeCampoByIdLazyQueryHookResult = ReturnType<typeof useGradeCampoByIdLazyQuery>;
export type GradeCampoByIdQueryResult = Apollo.QueryResult<GradeCampoByIdQuery, GradeCampoByIdQueryVariables>;
export const InventarioEstoqueDocument = gql`
	query InventarioEstoque($filtro: InputFiltroInventario!) {
		inventarioEstoque(Filtro: $filtro)
	}
`;

/**
 * __useInventarioEstoqueQuery__
 *
 * To run a query within a React component, call `useInventarioEstoqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventarioEstoqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventarioEstoqueQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useInventarioEstoqueQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InventarioEstoqueQuery, InventarioEstoqueQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<InventarioEstoqueQuery, InventarioEstoqueQueryVariables>(InventarioEstoqueDocument, options);
}
export function useInventarioEstoqueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InventarioEstoqueQuery, InventarioEstoqueQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<InventarioEstoqueQuery, InventarioEstoqueQueryVariables>(InventarioEstoqueDocument, options);
}
export type InventarioEstoqueQueryHookResult = ReturnType<typeof useInventarioEstoqueQuery>;
export type InventarioEstoqueLazyQueryHookResult = ReturnType<typeof useInventarioEstoqueLazyQuery>;
export type InventarioEstoqueQueryResult = Apollo.QueryResult<InventarioEstoqueQuery, InventarioEstoqueQueryVariables>;
export const SintegraEstoqueDocument = gql`
	subscription SintegraEstoque($filtro: InputFiltroSintegra!) {
		sintegraEstoque(Filtro: $filtro)
	}
`;

/**
 * __useSintegraEstoqueSubscription__
 *
 * To run a query within a React component, call `useSintegraEstoqueSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSintegraEstoqueSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSintegraEstoqueSubscription({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useSintegraEstoqueSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<SintegraEstoqueSubscription, SintegraEstoqueSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<SintegraEstoqueSubscription, SintegraEstoqueSubscriptionVariables>(SintegraEstoqueDocument, options);
}
export type SintegraEstoqueSubscriptionHookResult = ReturnType<typeof useSintegraEstoqueSubscription>;
export type SintegraEstoqueSubscriptionResult = Apollo.SubscriptionResult<SintegraEstoqueSubscription>;
export const CadastraMarcaDocument = gql`
	mutation CadastraMarca($dados: InputCadastrarMarca!) {
		cadastraMarca(Dados: $dados)
	}
`;
export type CadastraMarcaMutationFn = Apollo.MutationFunction<CadastraMarcaMutation, CadastraMarcaMutationVariables>;

/**
 * __useCadastraMarcaMutation__
 *
 * To run a mutation, you first call `useCadastraMarcaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraMarcaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraMarcaMutation, { data, loading, error }] = useCadastraMarcaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraMarcaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraMarcaMutation, CadastraMarcaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraMarcaMutation, CadastraMarcaMutationVariables>(CadastraMarcaDocument, options);
}
export type CadastraMarcaMutationHookResult = ReturnType<typeof useCadastraMarcaMutation>;
export type CadastraMarcaMutationResult = Apollo.MutationResult<CadastraMarcaMutation>;
export type CadastraMarcaMutationOptions = Apollo.BaseMutationOptions<CadastraMarcaMutation, CadastraMarcaMutationVariables>;
export const EditaMarcaDocument = gql`
	mutation EditaMarca($dados: InputEditarMarca!) {
		editaMarca(Dados: $dados)
	}
`;
export type EditaMarcaMutationFn = Apollo.MutationFunction<EditaMarcaMutation, EditaMarcaMutationVariables>;

/**
 * __useEditaMarcaMutation__
 *
 * To run a mutation, you first call `useEditaMarcaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaMarcaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaMarcaMutation, { data, loading, error }] = useEditaMarcaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaMarcaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaMarcaMutation, EditaMarcaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaMarcaMutation, EditaMarcaMutationVariables>(EditaMarcaDocument, options);
}
export type EditaMarcaMutationHookResult = ReturnType<typeof useEditaMarcaMutation>;
export type EditaMarcaMutationResult = Apollo.MutationResult<EditaMarcaMutation>;
export type EditaMarcaMutationOptions = Apollo.BaseMutationOptions<EditaMarcaMutation, EditaMarcaMutationVariables>;
export const ToggleStatusMarcaDocument = gql`
	mutation ToggleStatusMarca($id: Int!, $status: Status!) {
		editaMarca(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusMarcaMutationFn = Apollo.MutationFunction<ToggleStatusMarcaMutation, ToggleStatusMarcaMutationVariables>;

/**
 * __useToggleStatusMarcaMutation__
 *
 * To run a mutation, you first call `useToggleStatusMarcaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusMarcaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusMarcaMutation, { data, loading, error }] = useToggleStatusMarcaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusMarcaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusMarcaMutation, ToggleStatusMarcaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusMarcaMutation, ToggleStatusMarcaMutationVariables>(ToggleStatusMarcaDocument, options);
}
export type ToggleStatusMarcaMutationHookResult = ReturnType<typeof useToggleStatusMarcaMutation>;
export type ToggleStatusMarcaMutationResult = Apollo.MutationResult<ToggleStatusMarcaMutation>;
export type ToggleStatusMarcaMutationOptions = Apollo.BaseMutationOptions<ToggleStatusMarcaMutation, ToggleStatusMarcaMutationVariables>;
export const MarcaDocument = gql`
	query Marca($filtro: InputFiltroMarca) {
		marca(Filtro: $filtro) {
			Id
			Nome
			Status
			Descricao
		}
	}
`;

/**
 * __useMarcaQuery__
 *
 * To run a query within a React component, call `useMarcaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarcaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarcaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useMarcaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MarcaQuery, MarcaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MarcaQuery, MarcaQueryVariables>(MarcaDocument, options);
}
export function useMarcaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MarcaQuery, MarcaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MarcaQuery, MarcaQueryVariables>(MarcaDocument, options);
}
export type MarcaQueryHookResult = ReturnType<typeof useMarcaQuery>;
export type MarcaLazyQueryHookResult = ReturnType<typeof useMarcaLazyQuery>;
export type MarcaQueryResult = Apollo.QueryResult<MarcaQuery, MarcaQueryVariables>;
export const MarcasDocument = gql`
	query Marcas {
		marcas {
			Id
			Nome
			Status
			Descricao
		}
	}
`;

/**
 * __useMarcasQuery__
 *
 * To run a query within a React component, call `useMarcasQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarcasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarcasQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarcasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MarcasQuery, MarcasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MarcasQuery, MarcasQueryVariables>(MarcasDocument, options);
}
export function useMarcasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MarcasQuery, MarcasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MarcasQuery, MarcasQueryVariables>(MarcasDocument, options);
}
export type MarcasQueryHookResult = ReturnType<typeof useMarcasQuery>;
export type MarcasLazyQueryHookResult = ReturnType<typeof useMarcasLazyQuery>;
export type MarcasQueryResult = Apollo.QueryResult<MarcasQuery, MarcasQueryVariables>;
export const MarcaByIdDocument = gql`
	query MarcaById($Id: Int!) {
		marca(Filtro: { Id: $Id }) {
			Nome
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useMarcaByIdQuery__
 *
 * To run a query within a React component, call `useMarcaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarcaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarcaByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useMarcaByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MarcaByIdQuery, MarcaByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MarcaByIdQuery, MarcaByIdQueryVariables>(MarcaByIdDocument, options);
}
export function useMarcaByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MarcaByIdQuery, MarcaByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MarcaByIdQuery, MarcaByIdQueryVariables>(MarcaByIdDocument, options);
}
export type MarcaByIdQueryHookResult = ReturnType<typeof useMarcaByIdQuery>;
export type MarcaByIdLazyQueryHookResult = ReturnType<typeof useMarcaByIdLazyQuery>;
export type MarcaByIdQueryResult = Apollo.QueryResult<MarcaByIdQuery, MarcaByIdQueryVariables>;
export const MarcasSelectDocument = gql`
	query MarcasSelect($IdMarca: Int) {
		marcas(Filtro: { Status: "ATIVO", Ids: [$IdMarca] }) {
			Id
			Nome
			Status
		}
	}
`;

/**
 * __useMarcasSelectQuery__
 *
 * To run a query within a React component, call `useMarcasSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarcasSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarcasSelectQuery({
 *   variables: {
 *      IdMarca: // value for 'IdMarca'
 *   },
 * });
 */
export function useMarcasSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MarcasSelectQuery, MarcasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MarcasSelectQuery, MarcasSelectQueryVariables>(MarcasSelectDocument, options);
}
export function useMarcasSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MarcasSelectQuery, MarcasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MarcasSelectQuery, MarcasSelectQueryVariables>(MarcasSelectDocument, options);
}
export type MarcasSelectQueryHookResult = ReturnType<typeof useMarcasSelectQuery>;
export type MarcasSelectLazyQueryHookResult = ReturnType<typeof useMarcasSelectLazyQuery>;
export type MarcasSelectQueryResult = Apollo.QueryResult<MarcasSelectQuery, MarcasSelectQueryVariables>;
export const CadastraProdutoDocument = gql`
	mutation CadastraProduto($dados: InputCadastrarProduto!) {
		cadastraProduto(Dados: $dados)
	}
`;
export type CadastraProdutoMutationFn = Apollo.MutationFunction<CadastraProdutoMutation, CadastraProdutoMutationVariables>;

/**
 * __useCadastraProdutoMutation__
 *
 * To run a mutation, you first call `useCadastraProdutoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraProdutoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraProdutoMutation, { data, loading, error }] = useCadastraProdutoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraProdutoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraProdutoMutation, CadastraProdutoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraProdutoMutation, CadastraProdutoMutationVariables>(CadastraProdutoDocument, options);
}
export type CadastraProdutoMutationHookResult = ReturnType<typeof useCadastraProdutoMutation>;
export type CadastraProdutoMutationResult = Apollo.MutationResult<CadastraProdutoMutation>;
export type CadastraProdutoMutationOptions = Apollo.BaseMutationOptions<CadastraProdutoMutation, CadastraProdutoMutationVariables>;
export const EditaProdutoDocument = gql`
	mutation EditaProduto($dados: InputEditarProduto!) {
		editaProduto(Dados: $dados)
	}
`;
export type EditaProdutoMutationFn = Apollo.MutationFunction<EditaProdutoMutation, EditaProdutoMutationVariables>;

/**
 * __useEditaProdutoMutation__
 *
 * To run a mutation, you first call `useEditaProdutoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaProdutoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaProdutoMutation, { data, loading, error }] = useEditaProdutoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaProdutoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaProdutoMutation, EditaProdutoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaProdutoMutation, EditaProdutoMutationVariables>(EditaProdutoDocument, options);
}
export type EditaProdutoMutationHookResult = ReturnType<typeof useEditaProdutoMutation>;
export type EditaProdutoMutationResult = Apollo.MutationResult<EditaProdutoMutation>;
export type EditaProdutoMutationOptions = Apollo.BaseMutationOptions<EditaProdutoMutation, EditaProdutoMutationVariables>;
export const ToggleStatusProdutoDocument = gql`
	mutation ToggleStatusProduto($id: Int!, $status: Status!) {
		editaProduto(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusProdutoMutationFn = Apollo.MutationFunction<ToggleStatusProdutoMutation, ToggleStatusProdutoMutationVariables>;

/**
 * __useToggleStatusProdutoMutation__
 *
 * To run a mutation, you first call `useToggleStatusProdutoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusProdutoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusProdutoMutation, { data, loading, error }] = useToggleStatusProdutoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusProdutoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusProdutoMutation, ToggleStatusProdutoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusProdutoMutation, ToggleStatusProdutoMutationVariables>(ToggleStatusProdutoDocument, options);
}
export type ToggleStatusProdutoMutationHookResult = ReturnType<typeof useToggleStatusProdutoMutation>;
export type ToggleStatusProdutoMutationResult = Apollo.MutationResult<ToggleStatusProdutoMutation>;
export type ToggleStatusProdutoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusProdutoMutation, ToggleStatusProdutoMutationVariables>;
export const CadastraMovimentEstoqueDocument = gql`
	mutation CadastraMovimentEstoque($dados: InputCadastrarMovimentoEstoque!) {
		cadastraMovimentoEstoque(Dados: $dados)
	}
`;
export type CadastraMovimentEstoqueMutationFn = Apollo.MutationFunction<CadastraMovimentEstoqueMutation, CadastraMovimentEstoqueMutationVariables>;

/**
 * __useCadastraMovimentEstoqueMutation__
 *
 * To run a mutation, you first call `useCadastraMovimentEstoqueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraMovimentEstoqueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraMovimentEstoqueMutation, { data, loading, error }] = useCadastraMovimentEstoqueMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraMovimentEstoqueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraMovimentEstoqueMutation, CadastraMovimentEstoqueMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraMovimentEstoqueMutation, CadastraMovimentEstoqueMutationVariables>(CadastraMovimentEstoqueDocument, options);
}
export type CadastraMovimentEstoqueMutationHookResult = ReturnType<typeof useCadastraMovimentEstoqueMutation>;
export type CadastraMovimentEstoqueMutationResult = Apollo.MutationResult<CadastraMovimentEstoqueMutation>;
export type CadastraMovimentEstoqueMutationOptions = Apollo.BaseMutationOptions<CadastraMovimentEstoqueMutation, CadastraMovimentEstoqueMutationVariables>;
export const ProdutosDocument = gql`
	query Produtos($paginacao: InputPaginacao, $filtro: InputFiltroProdutos) {
		produtos(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Id
				Codigo
				Descricao
				CodBarras
				CodigoSecundario
				Quantidade
				UnidadeSimbolo
				ValorVenda
				ValorVendaAlt
				ValorCusto
				CustoMedio
				CategoriaNome
				SubcategoriaNome
				MarcaNome
				ColecaoNome
				DefinicaoNome
				SituaTributCodDescricao
				NcmCodigo
				DataCadastro
				FatorConversao
				DataAlteracao
				Status
				Foto
				GradePreco
				IdCategoria
				IdSubcategoria
				IdMarca
				IdColecao
				IdDefinicao
				IdNcm
				IdSituaTribut
				IdUnidade
				IdUnidadeCompra
				IdGradeTipo
				MargemLucro
			}
			Paginacao {
				TotalRegistros
				QtdTotalEstoque
				TotalValorCusto
				TotalCustoMedio
				TotalPrecoVenda1
				TotalPrecoVenda2
			}
		}
	}
`;

/**
 * __useProdutosQuery__
 *
 * To run a query within a React component, call `useProdutosQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdutosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdutosQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProdutosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProdutosQuery, ProdutosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ProdutosQuery, ProdutosQueryVariables>(ProdutosDocument, options);
}
export function useProdutosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProdutosQuery, ProdutosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ProdutosQuery, ProdutosQueryVariables>(ProdutosDocument, options);
}
export type ProdutosQueryHookResult = ReturnType<typeof useProdutosQuery>;
export type ProdutosLazyQueryHookResult = ReturnType<typeof useProdutosLazyQuery>;
export type ProdutosQueryResult = Apollo.QueryResult<ProdutosQuery, ProdutosQueryVariables>;
export const ProdutoByIdDocument = gql`
	query ProdutoById($Id: Int!) {
		produto(Filtro: { Id: $Id }) {
			Id
			IdMarca
			IdEmpresa
			IdUnidade
			IdUnidadeCompra
			IdColecao
			IdCategoria
			IdDefinicao
			IdGradeTipo
			IdFornecedor
			IdSubcategoria
			IdUnidadeCompra
			IdUnidadeTributaria
			Foto
			Codigo
			CodAnp
			DescAnp
			CodBenef
			Descricao
			CodBarras
			CodigoSecundario
			Complemento
			IpiCnpjProd
			CodListServ
			SkuEcommerce
			CodFornecedor
			Origem
			Peso
			FatorConversao
			FatorConversaoTribut
			Status
			Desconto
			AtivoEcommerce
			EstoqueAjustado
			DataCadastro
			DataAlteracao
			GradePreco
			IndEscala
			UnidadeSimbolo
			UnidadeDescricao
			UnidadeStatus
			CategoriaNome
			CategoriaDescricao
			CategoriaStatus
			SubcategoriaNome
			SubcategoriaDescricao
			SubcategoriaStatus
			DefinicaoNome
			DefinicaoDescricao
			DefinicaoVenda
			DefinicaoMateriaPrima
			DefinicaoUsoConsumo
			DefinicaoProducao
			DefinicaoServico
			UnidadeCompraSimbolo
			UnidadeCompraDescricao
			UnidadeCompraStatus
			MarcaNome
			MarcaDescricao
			MarcaStatus
			ColecaoNome
			ColecaoDescricao
			ColecaoStatus
			FornecedorNome
			FornecedorNomeFantasia
			FornecedorCnpj
			IdSituaTribut
			IdCstIpi
			IdCstPisCofins
			IdNcm
			NcmCodigo
			IdCfop
			CestCodigo
			IdCest
			CfopCodigo
			Quantidade
			QtdMinima
			ValorVenda
			ValorVendaAlt
			MargemLucro
			Desconto
			IcmsMva
			IcmsAliq
			IcmsReducao
			IPIAliq
			PisAliq
			CofinsAliq
			IIAliq
			IpiClasse
			IpiCodSelo
			IpiQtdSelo
			IpiCodEnq
			SaldoIniQtd
			ReducaoMvaNormal
			ReducaoMvaSimples
			PorcentDifIcms
			ValorCusto
			CustoMedio
			Grade(Filtro: { Status: "ATIVO" }) {
				Id
				Codigo
				CodBarras
				IdColuna
				ColunaNome
				ColunaCodigo
				IdLinha
				LinhaNome
				LinhaCodigo
				Preco1
				Preco2
				Quantidade
				ValorCusto
			}
			GradeTipo
			SituaTributCodDescricao
			NcmCodigo
		}
	}
`;

/**
 * __useProdutoByIdQuery__
 *
 * To run a query within a React component, call `useProdutoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdutoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdutoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useProdutoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProdutoByIdQuery, ProdutoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ProdutoByIdQuery, ProdutoByIdQueryVariables>(ProdutoByIdDocument, options);
}
export function useProdutoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProdutoByIdQuery, ProdutoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ProdutoByIdQuery, ProdutoByIdQueryVariables>(ProdutoByIdDocument, options);
}
export type ProdutoByIdQueryHookResult = ReturnType<typeof useProdutoByIdQuery>;
export type ProdutoByIdLazyQueryHookResult = ReturnType<typeof useProdutoByIdLazyQuery>;
export type ProdutoByIdQueryResult = Apollo.QueryResult<ProdutoByIdQuery, ProdutoByIdQueryVariables>;
export const ProdutoDocument = gql`
	query Produto($filtro: InputFiltroProduto) {
		produto(Filtro: $filtro) {
			Id
			Codigo
			Descricao
			CodBarras
			CodigoSecundario
			Quantidade
			UnidadeSimbolo
			ValorVenda
			ValorVendaAlt
			CfopCodigo
			ValorCusto
			CustoMedio
			CategoriaNome
			IdCfop
			UnidadeCompraSimbolo
			SubcategoriaNome
			MarcaNome
			ColecaoNome
			DefinicaoNome
			SituaTributCodDescricao
			NcmCodigo
			DataCadastro
			IdCest
			IdCstIpi
			IPIAliq
			IdCstPisCofins
			IcmsAliq
			FatorConversao
			DataAlteracao
			Status
			Foto
			GradePreco
			IdCategoria
			IdSubcategoria
			IdMarca
			IdColecao
			IdDefinicao
			IdNcm
			IdSituaTribut
			IdUnidade
			IdUnidadeCompra
			IdGradeTipo
			MargemLucro
			Grade(Filtro: { Status: "ATIVO" }) {
				Id
				IdLinha
				IdColuna
				Codigo
				CodBarras
				ValorCusto
				CustoMedio
				Quantidade
				LinhaNome
				ColunaNome
				Preco1
				Preco2
			}
		}
	}
`;

/**
 * __useProdutoQuery__
 *
 * To run a query within a React component, call `useProdutoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdutoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdutoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProdutoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProdutoQuery, ProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ProdutoQuery, ProdutoQueryVariables>(ProdutoDocument, options);
}
export function useProdutoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProdutoQuery, ProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ProdutoQuery, ProdutoQueryVariables>(ProdutoDocument, options);
}
export type ProdutoQueryHookResult = ReturnType<typeof useProdutoQuery>;
export type ProdutoLazyQueryHookResult = ReturnType<typeof useProdutoLazyQuery>;
export type ProdutoQueryResult = Apollo.QueryResult<ProdutoQuery, ProdutoQueryVariables>;
export const ItemGradeDocument = gql`
	query ItemGrade($IdProduto: Int!) {
		produto(Filtro: { Id: $IdProduto }) {
			Id
			IdGradeTipo
			ValorVenda
			ValorVendaAlt
			GradeTipo
			GradePreco
			GradeNomeColuna
			GradeNomeLinha
			Grade(Filtro: { Status: "ATIVO" }) {
				Id
				Codigo
				CodBarras
				IdColuna
				ColunaNome
				ColunaCodigo
				IdLinha
				LinhaNome
				LinhaCodigo
				GradePreco1: Preco1
				GradePreco2: Preco2
				GradeQuantidade: Quantidade
				GradeCodBarras: CodBarras
				ValorCusto
			}
		}
	}
`;

/**
 * __useItemGradeQuery__
 *
 * To run a query within a React component, call `useItemGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemGradeQuery({
 *   variables: {
 *      IdProduto: // value for 'IdProduto'
 *   },
 * });
 */
export function useItemGradeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ItemGradeQuery, ItemGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ItemGradeQuery, ItemGradeQueryVariables>(ItemGradeDocument, options);
}
export function useItemGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ItemGradeQuery, ItemGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ItemGradeQuery, ItemGradeQueryVariables>(ItemGradeDocument, options);
}
export type ItemGradeQueryHookResult = ReturnType<typeof useItemGradeQuery>;
export type ItemGradeLazyQueryHookResult = ReturnType<typeof useItemGradeLazyQuery>;
export type ItemGradeQueryResult = Apollo.QueryResult<ItemGradeQuery, ItemGradeQueryVariables>;
export const CodigoProximoProdutoDocument = gql`
	query CodigoProximoProduto {
		codigoProximoProduto
	}
`;

/**
 * __useCodigoProximoProdutoQuery__
 *
 * To run a query within a React component, call `useCodigoProximoProdutoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoProximoProdutoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoProximoProdutoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodigoProximoProdutoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoProximoProdutoQuery, CodigoProximoProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoProximoProdutoQuery, CodigoProximoProdutoQueryVariables>(CodigoProximoProdutoDocument, options);
}
export function useCodigoProximoProdutoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoProximoProdutoQuery, CodigoProximoProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoProximoProdutoQuery, CodigoProximoProdutoQueryVariables>(CodigoProximoProdutoDocument, options);
}
export type CodigoProximoProdutoQueryHookResult = ReturnType<typeof useCodigoProximoProdutoQuery>;
export type CodigoProximoProdutoLazyQueryHookResult = ReturnType<typeof useCodigoProximoProdutoLazyQuery>;
export type CodigoProximoProdutoQueryResult = Apollo.QueryResult<CodigoProximoProdutoQuery, CodigoProximoProdutoQueryVariables>;
export const SituacoesTributariasDocument = gql`
	query SituacoesTributarias($categoria: CategoriaSituacao, $ids: [Int], $status: Status) {
		situacoesTributarias(Filtro: { Ids: $ids, Status: $status, Categoria: $categoria }) {
			Id
			Categoria
			Codigo
			Descricao
			Status
		}
	}
`;

/**
 * __useSituacoesTributariasQuery__
 *
 * To run a query within a React component, call `useSituacoesTributariasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSituacoesTributariasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSituacoesTributariasQuery({
 *   variables: {
 *      categoria: // value for 'categoria'
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSituacoesTributariasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SituacoesTributariasQuery, SituacoesTributariasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<SituacoesTributariasQuery, SituacoesTributariasQueryVariables>(SituacoesTributariasDocument, options);
}
export function useSituacoesTributariasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SituacoesTributariasQuery, SituacoesTributariasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<SituacoesTributariasQuery, SituacoesTributariasQueryVariables>(SituacoesTributariasDocument, options);
}
export type SituacoesTributariasQueryHookResult = ReturnType<typeof useSituacoesTributariasQuery>;
export type SituacoesTributariasLazyQueryHookResult = ReturnType<typeof useSituacoesTributariasLazyQuery>;
export type SituacoesTributariasQueryResult = Apollo.QueryResult<SituacoesTributariasQuery, SituacoesTributariasQueryVariables>;
export const SituacoesTributariasSelectDocument = gql`
	query SituacoesTributariasSelect($IdSituaTribut: [Int]) {
		situacoesTributarias(Filtro: { Status: "ATIVO", Ids: $IdSituaTribut }) {
			Id
			Categoria
			Codigo
			Descricao
			Status
		}
	}
`;

/**
 * __useSituacoesTributariasSelectQuery__
 *
 * To run a query within a React component, call `useSituacoesTributariasSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSituacoesTributariasSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSituacoesTributariasSelectQuery({
 *   variables: {
 *      IdSituaTribut: // value for 'IdSituaTribut'
 *   },
 * });
 */
export function useSituacoesTributariasSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SituacoesTributariasSelectQuery, SituacoesTributariasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<SituacoesTributariasSelectQuery, SituacoesTributariasSelectQueryVariables>(SituacoesTributariasSelectDocument, options);
}
export function useSituacoesTributariasSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SituacoesTributariasSelectQuery, SituacoesTributariasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<SituacoesTributariasSelectQuery, SituacoesTributariasSelectQueryVariables>(SituacoesTributariasSelectDocument, options);
}
export type SituacoesTributariasSelectQueryHookResult = ReturnType<typeof useSituacoesTributariasSelectQuery>;
export type SituacoesTributariasSelectLazyQueryHookResult = ReturnType<typeof useSituacoesTributariasSelectLazyQuery>;
export type SituacoesTributariasSelectQueryResult = Apollo.QueryResult<SituacoesTributariasSelectQuery, SituacoesTributariasSelectQueryVariables>;
export const AliquotaIcmsByUfDocument = gql`
	query AliquotaIcmsByUf($IdUf: Int!) {
		aliquotaIcms(Filtro: { IdUfDestino: $IdUf, IdUfOrigem: $IdUf }) {
			Aliquota
		}
	}
`;

/**
 * __useAliquotaIcmsByUfQuery__
 *
 * To run a query within a React component, call `useAliquotaIcmsByUfQuery` and pass it any options that fit your needs.
 * When your component renders, `useAliquotaIcmsByUfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAliquotaIcmsByUfQuery({
 *   variables: {
 *      IdUf: // value for 'IdUf'
 *   },
 * });
 */
export function useAliquotaIcmsByUfQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AliquotaIcmsByUfQuery, AliquotaIcmsByUfQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<AliquotaIcmsByUfQuery, AliquotaIcmsByUfQueryVariables>(AliquotaIcmsByUfDocument, options);
}
export function useAliquotaIcmsByUfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AliquotaIcmsByUfQuery, AliquotaIcmsByUfQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<AliquotaIcmsByUfQuery, AliquotaIcmsByUfQueryVariables>(AliquotaIcmsByUfDocument, options);
}
export type AliquotaIcmsByUfQueryHookResult = ReturnType<typeof useAliquotaIcmsByUfQuery>;
export type AliquotaIcmsByUfLazyQueryHookResult = ReturnType<typeof useAliquotaIcmsByUfLazyQuery>;
export type AliquotaIcmsByUfQueryResult = Apollo.QueryResult<AliquotaIcmsByUfQuery, AliquotaIcmsByUfQueryVariables>;
export const ProdutosGradeDocument = gql`
	query ProdutosGrade($paginacao: InputPaginacao, $filtro: InputFiltroProdutos) {
		produtos(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Id
				Codigo
				Descricao
				CodBarras
				CodFornecedor
				CodigoSecundario
				Quantidade
				UnidadeSimbolo
				ValorVenda
				ValorVendaAlt
				CfopCodigo
				ValorCusto
				CustoMedio
				CategoriaNome
				IdCfop
				UnidadeCompraSimbolo
				SubcategoriaNome
				MarcaNome
				ColecaoNome
				DefinicaoNome
				SituaTributCodDescricao
				NcmCodigo
				DataCadastro
				IdCest
				IdCstIpi
				IPIAliq
				IdCstPisCofins
				IcmsAliq
				FatorConversao
				DataAlteracao
				Status
				Foto
				GradePreco
				IdCategoria
				IdSubcategoria
				IdMarca
				IdColecao
				IdDefinicao
				IdNcm
				IdSituaTribut
				IdUnidade
				IdUnidadeCompra
				IdGradeTipo
				MargemLucro
				Grade {
					Id
					IdLinha
					IdColuna
					Codigo
					CodBarras
					ValorCusto
					CustoMedio
					Quantidade
					LinhaNome
					ColunaNome
					Preco1
					Preco2
				}
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useProdutosGradeQuery__
 *
 * To run a query within a React component, call `useProdutosGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdutosGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdutosGradeQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProdutosGradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProdutosGradeQuery, ProdutosGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ProdutosGradeQuery, ProdutosGradeQueryVariables>(ProdutosGradeDocument, options);
}
export function useProdutosGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProdutosGradeQuery, ProdutosGradeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ProdutosGradeQuery, ProdutosGradeQueryVariables>(ProdutosGradeDocument, options);
}
export type ProdutosGradeQueryHookResult = ReturnType<typeof useProdutosGradeQuery>;
export type ProdutosGradeLazyQueryHookResult = ReturnType<typeof useProdutosGradeLazyQuery>;
export type ProdutosGradeQueryResult = Apollo.QueryResult<ProdutosGradeQuery, ProdutosGradeQueryVariables>;
export const MovimentoProdutoDocument = gql`
	query MovimentoProduto($dados: InputFiltroMovimentoProduto!) {
		movimentoProduto(Dados: $dados)
	}
`;

/**
 * __useMovimentoProdutoQuery__
 *
 * To run a query within a React component, call `useMovimentoProdutoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovimentoProdutoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovimentoProdutoQuery({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useMovimentoProdutoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MovimentoProdutoQuery, MovimentoProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MovimentoProdutoQuery, MovimentoProdutoQueryVariables>(MovimentoProdutoDocument, options);
}
export function useMovimentoProdutoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MovimentoProdutoQuery, MovimentoProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MovimentoProdutoQuery, MovimentoProdutoQueryVariables>(MovimentoProdutoDocument, options);
}
export type MovimentoProdutoQueryHookResult = ReturnType<typeof useMovimentoProdutoQuery>;
export type MovimentoProdutoLazyQueryHookResult = ReturnType<typeof useMovimentoProdutoLazyQuery>;
export type MovimentoProdutoQueryResult = Apollo.QueryResult<MovimentoProdutoQuery, MovimentoProdutoQueryVariables>;
export const CadastraSubcategoriaDocument = gql`
	mutation CadastraSubcategoria($dados: InputCadastrarSubcategoria!) {
		cadastraSubcategoria(Dados: $dados)
	}
`;
export type CadastraSubcategoriaMutationFn = Apollo.MutationFunction<CadastraSubcategoriaMutation, CadastraSubcategoriaMutationVariables>;

/**
 * __useCadastraSubcategoriaMutation__
 *
 * To run a mutation, you first call `useCadastraSubcategoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraSubcategoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraSubcategoriaMutation, { data, loading, error }] = useCadastraSubcategoriaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraSubcategoriaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraSubcategoriaMutation, CadastraSubcategoriaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraSubcategoriaMutation, CadastraSubcategoriaMutationVariables>(CadastraSubcategoriaDocument, options);
}
export type CadastraSubcategoriaMutationHookResult = ReturnType<typeof useCadastraSubcategoriaMutation>;
export type CadastraSubcategoriaMutationResult = Apollo.MutationResult<CadastraSubcategoriaMutation>;
export type CadastraSubcategoriaMutationOptions = Apollo.BaseMutationOptions<CadastraSubcategoriaMutation, CadastraSubcategoriaMutationVariables>;
export const EditaSubcategoriaDocument = gql`
	mutation EditaSubcategoria($dados: InputEditarSubcategoria!) {
		editaSubcategoria(Dados: $dados)
	}
`;
export type EditaSubcategoriaMutationFn = Apollo.MutationFunction<EditaSubcategoriaMutation, EditaSubcategoriaMutationVariables>;

/**
 * __useEditaSubcategoriaMutation__
 *
 * To run a mutation, you first call `useEditaSubcategoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaSubcategoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaSubcategoriaMutation, { data, loading, error }] = useEditaSubcategoriaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaSubcategoriaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaSubcategoriaMutation, EditaSubcategoriaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaSubcategoriaMutation, EditaSubcategoriaMutationVariables>(EditaSubcategoriaDocument, options);
}
export type EditaSubcategoriaMutationHookResult = ReturnType<typeof useEditaSubcategoriaMutation>;
export type EditaSubcategoriaMutationResult = Apollo.MutationResult<EditaSubcategoriaMutation>;
export type EditaSubcategoriaMutationOptions = Apollo.BaseMutationOptions<EditaSubcategoriaMutation, EditaSubcategoriaMutationVariables>;
export const ToggleStatusSubcategoriaDocument = gql`
	mutation ToggleStatusSubcategoria($id: Int!, $status: Status!) {
		editaSubcategoria(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusSubcategoriaMutationFn = Apollo.MutationFunction<ToggleStatusSubcategoriaMutation, ToggleStatusSubcategoriaMutationVariables>;

/**
 * __useToggleStatusSubcategoriaMutation__
 *
 * To run a mutation, you first call `useToggleStatusSubcategoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusSubcategoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusSubcategoriaMutation, { data, loading, error }] = useToggleStatusSubcategoriaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusSubcategoriaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusSubcategoriaMutation, ToggleStatusSubcategoriaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusSubcategoriaMutation, ToggleStatusSubcategoriaMutationVariables>(ToggleStatusSubcategoriaDocument, options);
}
export type ToggleStatusSubcategoriaMutationHookResult = ReturnType<typeof useToggleStatusSubcategoriaMutation>;
export type ToggleStatusSubcategoriaMutationResult = Apollo.MutationResult<ToggleStatusSubcategoriaMutation>;
export type ToggleStatusSubcategoriaMutationOptions = Apollo.BaseMutationOptions<ToggleStatusSubcategoriaMutation, ToggleStatusSubcategoriaMutationVariables>;
export const SubcategoriasDocument = gql`
	query Subcategorias {
		subcategorias {
			Descricao
			DescricaoCategoria
			Id
			IdCategoria
			Nome
			NomeCategoria
			Status
			StatusCategoria
		}
	}
`;

/**
 * __useSubcategoriasQuery__
 *
 * To run a query within a React component, call `useSubcategoriasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcategoriasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcategoriasQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubcategoriasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubcategoriasQuery, SubcategoriasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<SubcategoriasQuery, SubcategoriasQueryVariables>(SubcategoriasDocument, options);
}
export function useSubcategoriasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubcategoriasQuery, SubcategoriasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<SubcategoriasQuery, SubcategoriasQueryVariables>(SubcategoriasDocument, options);
}
export type SubcategoriasQueryHookResult = ReturnType<typeof useSubcategoriasQuery>;
export type SubcategoriasLazyQueryHookResult = ReturnType<typeof useSubcategoriasLazyQuery>;
export type SubcategoriasQueryResult = Apollo.QueryResult<SubcategoriasQuery, SubcategoriasQueryVariables>;
export const SubcategoriaDocument = gql`
	query Subcategoria($filtro: InputFiltroSubcategoria) {
		subcategoria(Filtro: $filtro) {
			Descricao
			DescricaoCategoria
			Id
			IdCategoria
			Nome
			NomeCategoria
			Status
			StatusCategoria
		}
	}
`;

/**
 * __useSubcategoriaQuery__
 *
 * To run a query within a React component, call `useSubcategoriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcategoriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcategoriaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useSubcategoriaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubcategoriaQuery, SubcategoriaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<SubcategoriaQuery, SubcategoriaQueryVariables>(SubcategoriaDocument, options);
}
export function useSubcategoriaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubcategoriaQuery, SubcategoriaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<SubcategoriaQuery, SubcategoriaQueryVariables>(SubcategoriaDocument, options);
}
export type SubcategoriaQueryHookResult = ReturnType<typeof useSubcategoriaQuery>;
export type SubcategoriaLazyQueryHookResult = ReturnType<typeof useSubcategoriaLazyQuery>;
export type SubcategoriaQueryResult = Apollo.QueryResult<SubcategoriaQuery, SubcategoriaQueryVariables>;
export const SubcategoriaByIdDocument = gql`
	query SubcategoriaById($Id: Int!) {
		subcategoria(Filtro: { Id: $Id }) {
			Descricao
			DescricaoCategoria
			Id
			IdCategoria
			Nome
			NomeCategoria
			Status
			StatusCategoria
		}
	}
`;

/**
 * __useSubcategoriaByIdQuery__
 *
 * To run a query within a React component, call `useSubcategoriaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcategoriaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcategoriaByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useSubcategoriaByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SubcategoriaByIdQuery, SubcategoriaByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<SubcategoriaByIdQuery, SubcategoriaByIdQueryVariables>(SubcategoriaByIdDocument, options);
}
export function useSubcategoriaByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubcategoriaByIdQuery, SubcategoriaByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<SubcategoriaByIdQuery, SubcategoriaByIdQueryVariables>(SubcategoriaByIdDocument, options);
}
export type SubcategoriaByIdQueryHookResult = ReturnType<typeof useSubcategoriaByIdQuery>;
export type SubcategoriaByIdLazyQueryHookResult = ReturnType<typeof useSubcategoriaByIdLazyQuery>;
export type SubcategoriaByIdQueryResult = Apollo.QueryResult<SubcategoriaByIdQuery, SubcategoriaByIdQueryVariables>;
export const CadastraUnidadeDocument = gql`
	mutation CadastraUnidade($dados: InputCadastrarUnidade!) {
		cadastraUnidade(Dados: $dados)
	}
`;
export type CadastraUnidadeMutationFn = Apollo.MutationFunction<CadastraUnidadeMutation, CadastraUnidadeMutationVariables>;

/**
 * __useCadastraUnidadeMutation__
 *
 * To run a mutation, you first call `useCadastraUnidadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraUnidadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraUnidadeMutation, { data, loading, error }] = useCadastraUnidadeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraUnidadeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraUnidadeMutation, CadastraUnidadeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraUnidadeMutation, CadastraUnidadeMutationVariables>(CadastraUnidadeDocument, options);
}
export type CadastraUnidadeMutationHookResult = ReturnType<typeof useCadastraUnidadeMutation>;
export type CadastraUnidadeMutationResult = Apollo.MutationResult<CadastraUnidadeMutation>;
export type CadastraUnidadeMutationOptions = Apollo.BaseMutationOptions<CadastraUnidadeMutation, CadastraUnidadeMutationVariables>;
export const EditaUnidadeDocument = gql`
	mutation EditaUnidade($dados: InputEditarUnidade!) {
		editaUnidade(Dados: $dados)
	}
`;
export type EditaUnidadeMutationFn = Apollo.MutationFunction<EditaUnidadeMutation, EditaUnidadeMutationVariables>;

/**
 * __useEditaUnidadeMutation__
 *
 * To run a mutation, you first call `useEditaUnidadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaUnidadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaUnidadeMutation, { data, loading, error }] = useEditaUnidadeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaUnidadeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaUnidadeMutation, EditaUnidadeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaUnidadeMutation, EditaUnidadeMutationVariables>(EditaUnidadeDocument, options);
}
export type EditaUnidadeMutationHookResult = ReturnType<typeof useEditaUnidadeMutation>;
export type EditaUnidadeMutationResult = Apollo.MutationResult<EditaUnidadeMutation>;
export type EditaUnidadeMutationOptions = Apollo.BaseMutationOptions<EditaUnidadeMutation, EditaUnidadeMutationVariables>;
export const ToggleStatusUnidadeDocument = gql`
	mutation ToggleStatusUnidade($id: Int!, $status: Status!) {
		editaUnidade(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusUnidadeMutationFn = Apollo.MutationFunction<ToggleStatusUnidadeMutation, ToggleStatusUnidadeMutationVariables>;

/**
 * __useToggleStatusUnidadeMutation__
 *
 * To run a mutation, you first call `useToggleStatusUnidadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusUnidadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusUnidadeMutation, { data, loading, error }] = useToggleStatusUnidadeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusUnidadeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusUnidadeMutation, ToggleStatusUnidadeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusUnidadeMutation, ToggleStatusUnidadeMutationVariables>(ToggleStatusUnidadeDocument, options);
}
export type ToggleStatusUnidadeMutationHookResult = ReturnType<typeof useToggleStatusUnidadeMutation>;
export type ToggleStatusUnidadeMutationResult = Apollo.MutationResult<ToggleStatusUnidadeMutation>;
export type ToggleStatusUnidadeMutationOptions = Apollo.BaseMutationOptions<ToggleStatusUnidadeMutation, ToggleStatusUnidadeMutationVariables>;
export const UnidadesDocument = gql`
	query Unidades {
		unidades {
			Descricao
			Simbolo
			Status
			Id
		}
	}
`;

/**
 * __useUnidadesQuery__
 *
 * To run a query within a React component, call `useUnidadesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnidadesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnidadesQuery, UnidadesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UnidadesQuery, UnidadesQueryVariables>(UnidadesDocument, options);
}
export function useUnidadesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnidadesQuery, UnidadesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UnidadesQuery, UnidadesQueryVariables>(UnidadesDocument, options);
}
export type UnidadesQueryHookResult = ReturnType<typeof useUnidadesQuery>;
export type UnidadesLazyQueryHookResult = ReturnType<typeof useUnidadesLazyQuery>;
export type UnidadesQueryResult = Apollo.QueryResult<UnidadesQuery, UnidadesQueryVariables>;
export const UnidadeDocument = gql`
	query Unidade($filtro: InputFiltroUnidade) {
		unidade(Filtro: $filtro) {
			Descricao
			Id
			Simbolo
			Status
		}
	}
`;

/**
 * __useUnidadeQuery__
 *
 * To run a query within a React component, call `useUnidadeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useUnidadeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnidadeQuery, UnidadeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UnidadeQuery, UnidadeQueryVariables>(UnidadeDocument, options);
}
export function useUnidadeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnidadeQuery, UnidadeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UnidadeQuery, UnidadeQueryVariables>(UnidadeDocument, options);
}
export type UnidadeQueryHookResult = ReturnType<typeof useUnidadeQuery>;
export type UnidadeLazyQueryHookResult = ReturnType<typeof useUnidadeLazyQuery>;
export type UnidadeQueryResult = Apollo.QueryResult<UnidadeQuery, UnidadeQueryVariables>;
export const UnidadeByIdDocument = gql`
	query UnidadeById($Id: Int!) {
		unidade(Filtro: { Id: $Id }) {
			Descricao
			Id
			Simbolo
			Status
		}
	}
`;

/**
 * __useUnidadeByIdQuery__
 *
 * To run a query within a React component, call `useUnidadeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useUnidadeByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UnidadeByIdQuery, UnidadeByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UnidadeByIdQuery, UnidadeByIdQueryVariables>(UnidadeByIdDocument, options);
}
export function useUnidadeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnidadeByIdQuery, UnidadeByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UnidadeByIdQuery, UnidadeByIdQueryVariables>(UnidadeByIdDocument, options);
}
export type UnidadeByIdQueryHookResult = ReturnType<typeof useUnidadeByIdQuery>;
export type UnidadeByIdLazyQueryHookResult = ReturnType<typeof useUnidadeByIdLazyQuery>;
export type UnidadeByIdQueryResult = Apollo.QueryResult<UnidadeByIdQuery, UnidadeByIdQueryVariables>;
export const UnidadesSelectDocument = gql`
	query UnidadesSelect($IdUnidade: [Int]) {
		unidades(Filtro: { Status: "ATIVO", Ids: $IdUnidade }) {
			Id
			Simbolo
			Status
		}
	}
`;

/**
 * __useUnidadesSelectQuery__
 *
 * To run a query within a React component, call `useUnidadesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadesSelectQuery({
 *   variables: {
 *      IdUnidade: // value for 'IdUnidade'
 *   },
 * });
 */
export function useUnidadesSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnidadesSelectQuery, UnidadesSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UnidadesSelectQuery, UnidadesSelectQueryVariables>(UnidadesSelectDocument, options);
}
export function useUnidadesSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnidadesSelectQuery, UnidadesSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UnidadesSelectQuery, UnidadesSelectQueryVariables>(UnidadesSelectDocument, options);
}
export type UnidadesSelectQueryHookResult = ReturnType<typeof useUnidadesSelectQuery>;
export type UnidadesSelectLazyQueryHookResult = ReturnType<typeof useUnidadesSelectLazyQuery>;
export type UnidadesSelectQueryResult = Apollo.QueryResult<UnidadesSelectQuery, UnidadesSelectQueryVariables>;
export const RecebimentoReceitaDocument = gql`
	mutation RecebimentoReceita($dados: InputRecebimentoReceita!) {
		recebimentoReceita(Dados: $dados)
	}
`;
export type RecebimentoReceitaMutationFn = Apollo.MutationFunction<RecebimentoReceitaMutation, RecebimentoReceitaMutationVariables>;

/**
 * __useRecebimentoReceitaMutation__
 *
 * To run a mutation, you first call `useRecebimentoReceitaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecebimentoReceitaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recebimentoReceitaMutation, { data, loading, error }] = useRecebimentoReceitaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRecebimentoReceitaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecebimentoReceitaMutation, RecebimentoReceitaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RecebimentoReceitaMutation, RecebimentoReceitaMutationVariables>(RecebimentoReceitaDocument, options);
}
export type RecebimentoReceitaMutationHookResult = ReturnType<typeof useRecebimentoReceitaMutation>;
export type RecebimentoReceitaMutationResult = Apollo.MutationResult<RecebimentoReceitaMutation>;
export type RecebimentoReceitaMutationOptions = Apollo.BaseMutationOptions<RecebimentoReceitaMutation, RecebimentoReceitaMutationVariables>;
export const RecebimentoParcialReceitaDocument = gql`
	mutation RecebimentoParcialReceita($dados: InputRecebimentoParcialReceita!) {
		recebimentoParcialReceita(Dados: $dados)
	}
`;
export type RecebimentoParcialReceitaMutationFn = Apollo.MutationFunction<RecebimentoParcialReceitaMutation, RecebimentoParcialReceitaMutationVariables>;

/**
 * __useRecebimentoParcialReceitaMutation__
 *
 * To run a mutation, you first call `useRecebimentoParcialReceitaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecebimentoParcialReceitaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recebimentoParcialReceitaMutation, { data, loading, error }] = useRecebimentoParcialReceitaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRecebimentoParcialReceitaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecebimentoParcialReceitaMutation, RecebimentoParcialReceitaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RecebimentoParcialReceitaMutation, RecebimentoParcialReceitaMutationVariables>(RecebimentoParcialReceitaDocument, options);
}
export type RecebimentoParcialReceitaMutationHookResult = ReturnType<typeof useRecebimentoParcialReceitaMutation>;
export type RecebimentoParcialReceitaMutationResult = Apollo.MutationResult<RecebimentoParcialReceitaMutation>;
export type RecebimentoParcialReceitaMutationOptions = Apollo.BaseMutationOptions<RecebimentoParcialReceitaMutation, RecebimentoParcialReceitaMutationVariables>;
export const PagamentoDespesaDocument = gql`
	mutation PagamentoDespesa($dados: InputPagamentoDespesa!) {
		pagamentoDespesa(Dados: $dados)
	}
`;
export type PagamentoDespesaMutationFn = Apollo.MutationFunction<PagamentoDespesaMutation, PagamentoDespesaMutationVariables>;

/**
 * __usePagamentoDespesaMutation__
 *
 * To run a mutation, you first call `usePagamentoDespesaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagamentoDespesaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagamentoDespesaMutation, { data, loading, error }] = usePagamentoDespesaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function usePagamentoDespesaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PagamentoDespesaMutation, PagamentoDespesaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<PagamentoDespesaMutation, PagamentoDespesaMutationVariables>(PagamentoDespesaDocument, options);
}
export type PagamentoDespesaMutationHookResult = ReturnType<typeof usePagamentoDespesaMutation>;
export type PagamentoDespesaMutationResult = Apollo.MutationResult<PagamentoDespesaMutation>;
export type PagamentoDespesaMutationOptions = Apollo.BaseMutationOptions<PagamentoDespesaMutation, PagamentoDespesaMutationVariables>;
export const PagamentoParcialDespesaDocument = gql`
	mutation PagamentoParcialDespesa($dados: InputPagamentoParcialDespesa!) {
		pagamentoParcialDespesa(Dados: $dados)
	}
`;
export type PagamentoParcialDespesaMutationFn = Apollo.MutationFunction<PagamentoParcialDespesaMutation, PagamentoParcialDespesaMutationVariables>;

/**
 * __usePagamentoParcialDespesaMutation__
 *
 * To run a mutation, you first call `usePagamentoParcialDespesaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagamentoParcialDespesaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagamentoParcialDespesaMutation, { data, loading, error }] = usePagamentoParcialDespesaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function usePagamentoParcialDespesaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PagamentoParcialDespesaMutation, PagamentoParcialDespesaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<PagamentoParcialDespesaMutation, PagamentoParcialDespesaMutationVariables>(PagamentoParcialDespesaDocument, options);
}
export type PagamentoParcialDespesaMutationHookResult = ReturnType<typeof usePagamentoParcialDespesaMutation>;
export type PagamentoParcialDespesaMutationResult = Apollo.MutationResult<PagamentoParcialDespesaMutation>;
export type PagamentoParcialDespesaMutationOptions = Apollo.BaseMutationOptions<PagamentoParcialDespesaMutation, PagamentoParcialDespesaMutationVariables>;
export const EditaLancamentoDespesaDocument = gql`
	mutation EditaLancamentoDespesa($dados: InputEditarLancamentoDespesa!) {
		editaLancamentoDespesa(Dados: $dados)
	}
`;
export type EditaLancamentoDespesaMutationFn = Apollo.MutationFunction<EditaLancamentoDespesaMutation, EditaLancamentoDespesaMutationVariables>;

/**
 * __useEditaLancamentoDespesaMutation__
 *
 * To run a mutation, you first call `useEditaLancamentoDespesaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaLancamentoDespesaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaLancamentoDespesaMutation, { data, loading, error }] = useEditaLancamentoDespesaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaLancamentoDespesaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaLancamentoDespesaMutation, EditaLancamentoDespesaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaLancamentoDespesaMutation, EditaLancamentoDespesaMutationVariables>(EditaLancamentoDespesaDocument, options);
}
export type EditaLancamentoDespesaMutationHookResult = ReturnType<typeof useEditaLancamentoDespesaMutation>;
export type EditaLancamentoDespesaMutationResult = Apollo.MutationResult<EditaLancamentoDespesaMutation>;
export type EditaLancamentoDespesaMutationOptions = Apollo.BaseMutationOptions<EditaLancamentoDespesaMutation, EditaLancamentoDespesaMutationVariables>;
export const EditaLancamentoReceitaDocument = gql`
	mutation EditaLancamentoReceita($dados: InputEditarLancamentoReceita!) {
		editaLancamentoReceita(Dados: $dados)
	}
`;
export type EditaLancamentoReceitaMutationFn = Apollo.MutationFunction<EditaLancamentoReceitaMutation, EditaLancamentoReceitaMutationVariables>;

/**
 * __useEditaLancamentoReceitaMutation__
 *
 * To run a mutation, you first call `useEditaLancamentoReceitaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaLancamentoReceitaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaLancamentoReceitaMutation, { data, loading, error }] = useEditaLancamentoReceitaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaLancamentoReceitaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaLancamentoReceitaMutation, EditaLancamentoReceitaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaLancamentoReceitaMutation, EditaLancamentoReceitaMutationVariables>(EditaLancamentoReceitaDocument, options);
}
export type EditaLancamentoReceitaMutationHookResult = ReturnType<typeof useEditaLancamentoReceitaMutation>;
export type EditaLancamentoReceitaMutationResult = Apollo.MutationResult<EditaLancamentoReceitaMutation>;
export type EditaLancamentoReceitaMutationOptions = Apollo.BaseMutationOptions<EditaLancamentoReceitaMutation, EditaLancamentoReceitaMutationVariables>;
export const CadastraContaFinanceiraDocument = gql`
	mutation CadastraContaFinanceira($dados: InputCadastrarContaFinanceira!) {
		cadastraContaFinanceira(Dados: $dados)
	}
`;
export type CadastraContaFinanceiraMutationFn = Apollo.MutationFunction<CadastraContaFinanceiraMutation, CadastraContaFinanceiraMutationVariables>;

/**
 * __useCadastraContaFinanceiraMutation__
 *
 * To run a mutation, you first call `useCadastraContaFinanceiraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraContaFinanceiraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraContaFinanceiraMutation, { data, loading, error }] = useCadastraContaFinanceiraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraContaFinanceiraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraContaFinanceiraMutation, CadastraContaFinanceiraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraContaFinanceiraMutation, CadastraContaFinanceiraMutationVariables>(CadastraContaFinanceiraDocument, options);
}
export type CadastraContaFinanceiraMutationHookResult = ReturnType<typeof useCadastraContaFinanceiraMutation>;
export type CadastraContaFinanceiraMutationResult = Apollo.MutationResult<CadastraContaFinanceiraMutation>;
export type CadastraContaFinanceiraMutationOptions = Apollo.BaseMutationOptions<CadastraContaFinanceiraMutation, CadastraContaFinanceiraMutationVariables>;
export const EditaContaFinanceiraDocument = gql`
	mutation EditaContaFinanceira($dados: InputEditarContaFinanceira!) {
		editaContaFinanceira(Dados: $dados)
	}
`;
export type EditaContaFinanceiraMutationFn = Apollo.MutationFunction<EditaContaFinanceiraMutation, EditaContaFinanceiraMutationVariables>;

/**
 * __useEditaContaFinanceiraMutation__
 *
 * To run a mutation, you first call `useEditaContaFinanceiraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaContaFinanceiraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaContaFinanceiraMutation, { data, loading, error }] = useEditaContaFinanceiraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaContaFinanceiraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaContaFinanceiraMutation, EditaContaFinanceiraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaContaFinanceiraMutation, EditaContaFinanceiraMutationVariables>(EditaContaFinanceiraDocument, options);
}
export type EditaContaFinanceiraMutationHookResult = ReturnType<typeof useEditaContaFinanceiraMutation>;
export type EditaContaFinanceiraMutationResult = Apollo.MutationResult<EditaContaFinanceiraMutation>;
export type EditaContaFinanceiraMutationOptions = Apollo.BaseMutationOptions<EditaContaFinanceiraMutation, EditaContaFinanceiraMutationVariables>;
export const ToggleStatusContaFinanceiraDocument = gql`
	mutation ToggleStatusContaFinanceira($id: Int!, $status: Status!) {
		editaContaFinanceira(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusContaFinanceiraMutationFn = Apollo.MutationFunction<ToggleStatusContaFinanceiraMutation, ToggleStatusContaFinanceiraMutationVariables>;

/**
 * __useToggleStatusContaFinanceiraMutation__
 *
 * To run a mutation, you first call `useToggleStatusContaFinanceiraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusContaFinanceiraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusContaFinanceiraMutation, { data, loading, error }] = useToggleStatusContaFinanceiraMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusContaFinanceiraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusContaFinanceiraMutation, ToggleStatusContaFinanceiraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusContaFinanceiraMutation, ToggleStatusContaFinanceiraMutationVariables>(ToggleStatusContaFinanceiraDocument, options);
}
export type ToggleStatusContaFinanceiraMutationHookResult = ReturnType<typeof useToggleStatusContaFinanceiraMutation>;
export type ToggleStatusContaFinanceiraMutationResult = Apollo.MutationResult<ToggleStatusContaFinanceiraMutation>;
export type ToggleStatusContaFinanceiraMutationOptions = Apollo.BaseMutationOptions<ToggleStatusContaFinanceiraMutation, ToggleStatusContaFinanceiraMutationVariables>;
export const SaldoInicialContaFinanceiraDocument = gql`
	mutation SaldoInicialContaFinanceira($dados: InputSaldoInicialContaFinanceira!) {
		saldoInicialContaFinanceira(Dados: $dados)
	}
`;
export type SaldoInicialContaFinanceiraMutationFn = Apollo.MutationFunction<SaldoInicialContaFinanceiraMutation, SaldoInicialContaFinanceiraMutationVariables>;

/**
 * __useSaldoInicialContaFinanceiraMutation__
 *
 * To run a mutation, you first call `useSaldoInicialContaFinanceiraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaldoInicialContaFinanceiraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saldoInicialContaFinanceiraMutation, { data, loading, error }] = useSaldoInicialContaFinanceiraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useSaldoInicialContaFinanceiraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaldoInicialContaFinanceiraMutation, SaldoInicialContaFinanceiraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<SaldoInicialContaFinanceiraMutation, SaldoInicialContaFinanceiraMutationVariables>(SaldoInicialContaFinanceiraDocument, options);
}
export type SaldoInicialContaFinanceiraMutationHookResult = ReturnType<typeof useSaldoInicialContaFinanceiraMutation>;
export type SaldoInicialContaFinanceiraMutationResult = Apollo.MutationResult<SaldoInicialContaFinanceiraMutation>;
export type SaldoInicialContaFinanceiraMutationOptions = Apollo.BaseMutationOptions<SaldoInicialContaFinanceiraMutation, SaldoInicialContaFinanceiraMutationVariables>;
export const ContasFinanceirasSelectDocument = gql`
	query ContasFinanceirasSelect($filtro: InputFiltroContasFinanceiras) {
		contasFinanceiras(Filtro: $filtro) {
			Id
			Nome
			IdNivel
			Status
			Tipo
		}
	}
`;

/**
 * __useContasFinanceirasSelectQuery__
 *
 * To run a query within a React component, call `useContasFinanceirasSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useContasFinanceirasSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContasFinanceirasSelectQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useContasFinanceirasSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContasFinanceirasSelectQuery, ContasFinanceirasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ContasFinanceirasSelectQuery, ContasFinanceirasSelectQueryVariables>(ContasFinanceirasSelectDocument, options);
}
export function useContasFinanceirasSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContasFinanceirasSelectQuery, ContasFinanceirasSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ContasFinanceirasSelectQuery, ContasFinanceirasSelectQueryVariables>(ContasFinanceirasSelectDocument, options);
}
export type ContasFinanceirasSelectQueryHookResult = ReturnType<typeof useContasFinanceirasSelectQuery>;
export type ContasFinanceirasSelectLazyQueryHookResult = ReturnType<typeof useContasFinanceirasSelectLazyQuery>;
export type ContasFinanceirasSelectQueryResult = Apollo.QueryResult<ContasFinanceirasSelectQuery, ContasFinanceirasSelectQueryVariables>;
export const ContaFinanceiraDocument = gql`
	query ContaFinanceira($filtro: InputFiltroContaFinanceira) {
		contaFinanceira(Filtro: $filtro) {
			Tipo
			Nome
			Status
			NumConta
			DvConta
			NumAgencia
			DvAgencia
			NomeTitular
			Gerente
			Telefone
			Email
		}
	}
`;

/**
 * __useContaFinanceiraQuery__
 *
 * To run a query within a React component, call `useContaFinanceiraQuery` and pass it any options that fit your needs.
 * When your component renders, `useContaFinanceiraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContaFinanceiraQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useContaFinanceiraQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContaFinanceiraQuery, ContaFinanceiraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ContaFinanceiraQuery, ContaFinanceiraQueryVariables>(ContaFinanceiraDocument, options);
}
export function useContaFinanceiraLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContaFinanceiraQuery, ContaFinanceiraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ContaFinanceiraQuery, ContaFinanceiraQueryVariables>(ContaFinanceiraDocument, options);
}
export type ContaFinanceiraQueryHookResult = ReturnType<typeof useContaFinanceiraQuery>;
export type ContaFinanceiraLazyQueryHookResult = ReturnType<typeof useContaFinanceiraLazyQuery>;
export type ContaFinanceiraQueryResult = Apollo.QueryResult<ContaFinanceiraQuery, ContaFinanceiraQueryVariables>;
export const CadastraFormaPagamentoDocument = gql`
	mutation CadastraFormaPagamento($dados: InputCadastrarFormaPagamento!) {
		cadastraFormaPagamento(Dados: $dados)
	}
`;
export type CadastraFormaPagamentoMutationFn = Apollo.MutationFunction<CadastraFormaPagamentoMutation, CadastraFormaPagamentoMutationVariables>;

/**
 * __useCadastraFormaPagamentoMutation__
 *
 * To run a mutation, you first call `useCadastraFormaPagamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraFormaPagamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraFormaPagamentoMutation, { data, loading, error }] = useCadastraFormaPagamentoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraFormaPagamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraFormaPagamentoMutation, CadastraFormaPagamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraFormaPagamentoMutation, CadastraFormaPagamentoMutationVariables>(CadastraFormaPagamentoDocument, options);
}
export type CadastraFormaPagamentoMutationHookResult = ReturnType<typeof useCadastraFormaPagamentoMutation>;
export type CadastraFormaPagamentoMutationResult = Apollo.MutationResult<CadastraFormaPagamentoMutation>;
export type CadastraFormaPagamentoMutationOptions = Apollo.BaseMutationOptions<CadastraFormaPagamentoMutation, CadastraFormaPagamentoMutationVariables>;
export const EditaFormaPagamentoDocument = gql`
	mutation EditaFormaPagamento($dados: InputEditarFormaPagamento!) {
		editaFormaPagamento(Dados: $dados)
	}
`;
export type EditaFormaPagamentoMutationFn = Apollo.MutationFunction<EditaFormaPagamentoMutation, EditaFormaPagamentoMutationVariables>;

/**
 * __useEditaFormaPagamentoMutation__
 *
 * To run a mutation, you first call `useEditaFormaPagamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaFormaPagamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaFormaPagamentoMutation, { data, loading, error }] = useEditaFormaPagamentoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaFormaPagamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaFormaPagamentoMutation, EditaFormaPagamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaFormaPagamentoMutation, EditaFormaPagamentoMutationVariables>(EditaFormaPagamentoDocument, options);
}
export type EditaFormaPagamentoMutationHookResult = ReturnType<typeof useEditaFormaPagamentoMutation>;
export type EditaFormaPagamentoMutationResult = Apollo.MutationResult<EditaFormaPagamentoMutation>;
export type EditaFormaPagamentoMutationOptions = Apollo.BaseMutationOptions<EditaFormaPagamentoMutation, EditaFormaPagamentoMutationVariables>;
export const ToggleStatusFormaPagamentoDocument = gql`
	mutation ToggleStatusFormaPagamento($id: Int!, $status: Status!) {
		editaFormaPagamento(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusFormaPagamentoMutationFn = Apollo.MutationFunction<ToggleStatusFormaPagamentoMutation, ToggleStatusFormaPagamentoMutationVariables>;

/**
 * __useToggleStatusFormaPagamentoMutation__
 *
 * To run a mutation, you first call `useToggleStatusFormaPagamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusFormaPagamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusFormaPagamentoMutation, { data, loading, error }] = useToggleStatusFormaPagamentoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusFormaPagamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusFormaPagamentoMutation, ToggleStatusFormaPagamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusFormaPagamentoMutation, ToggleStatusFormaPagamentoMutationVariables>(ToggleStatusFormaPagamentoDocument, options);
}
export type ToggleStatusFormaPagamentoMutationHookResult = ReturnType<typeof useToggleStatusFormaPagamentoMutation>;
export type ToggleStatusFormaPagamentoMutationResult = Apollo.MutationResult<ToggleStatusFormaPagamentoMutation>;
export type ToggleStatusFormaPagamentoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusFormaPagamentoMutation, ToggleStatusFormaPagamentoMutationVariables>;
export const FormasPagamentoDocument = gql`
	query FormasPagamento($filtro: InputFiltroFormasPagamento) {
		formasPagamento(Filtro: $filtro) {
			Id
			Nome
			FormaVenda
			Tipo
			TipoPagamentoNFe
			Status
		}
	}
`;

/**
 * __useFormasPagamentoQuery__
 *
 * To run a query within a React component, call `useFormasPagamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormasPagamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormasPagamentoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useFormasPagamentoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormasPagamentoQuery, FormasPagamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<FormasPagamentoQuery, FormasPagamentoQueryVariables>(FormasPagamentoDocument, options);
}
export function useFormasPagamentoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormasPagamentoQuery, FormasPagamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<FormasPagamentoQuery, FormasPagamentoQueryVariables>(FormasPagamentoDocument, options);
}
export type FormasPagamentoQueryHookResult = ReturnType<typeof useFormasPagamentoQuery>;
export type FormasPagamentoLazyQueryHookResult = ReturnType<typeof useFormasPagamentoLazyQuery>;
export type FormasPagamentoQueryResult = Apollo.QueryResult<FormasPagamentoQuery, FormasPagamentoQueryVariables>;
export const FormaPagamentoDocument = gql`
	query FormaPagamento($filtro: InputFiltroFormaPagamento) {
		formaPagamento(Filtro: $filtro) {
			Id
			Nome
			FormaVenda
			Tipo
			TipoPagamentoNFe
			Status
		}
	}
`;

/**
 * __useFormaPagamentoQuery__
 *
 * To run a query within a React component, call `useFormaPagamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormaPagamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormaPagamentoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useFormaPagamentoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormaPagamentoQuery, FormaPagamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<FormaPagamentoQuery, FormaPagamentoQueryVariables>(FormaPagamentoDocument, options);
}
export function useFormaPagamentoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormaPagamentoQuery, FormaPagamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<FormaPagamentoQuery, FormaPagamentoQueryVariables>(FormaPagamentoDocument, options);
}
export type FormaPagamentoQueryHookResult = ReturnType<typeof useFormaPagamentoQuery>;
export type FormaPagamentoLazyQueryHookResult = ReturnType<typeof useFormaPagamentoLazyQuery>;
export type FormaPagamentoQueryResult = Apollo.QueryResult<FormaPagamentoQuery, FormaPagamentoQueryVariables>;
export const LancamentoDespesaDocument = gql`
	mutation LancamentoDespesa($dados: InputLancamentoDespesa!) {
		lancamentoDespesa(Dados: $dados)
	}
`;
export type LancamentoDespesaMutationFn = Apollo.MutationFunction<LancamentoDespesaMutation, LancamentoDespesaMutationVariables>;

/**
 * __useLancamentoDespesaMutation__
 *
 * To run a mutation, you first call `useLancamentoDespesaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLancamentoDespesaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lancamentoDespesaMutation, { data, loading, error }] = useLancamentoDespesaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useLancamentoDespesaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LancamentoDespesaMutation, LancamentoDespesaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<LancamentoDespesaMutation, LancamentoDespesaMutationVariables>(LancamentoDespesaDocument, options);
}
export type LancamentoDespesaMutationHookResult = ReturnType<typeof useLancamentoDespesaMutation>;
export type LancamentoDespesaMutationResult = Apollo.MutationResult<LancamentoDespesaMutation>;
export type LancamentoDespesaMutationOptions = Apollo.BaseMutationOptions<LancamentoDespesaMutation, LancamentoDespesaMutationVariables>;
export const LancamentoReceitaDocument = gql`
	mutation LancamentoReceita($dados: InputLancamentoReceita!) {
		lancamentoReceita(Dados: $dados)
	}
`;
export type LancamentoReceitaMutationFn = Apollo.MutationFunction<LancamentoReceitaMutation, LancamentoReceitaMutationVariables>;

/**
 * __useLancamentoReceitaMutation__
 *
 * To run a mutation, you first call `useLancamentoReceitaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLancamentoReceitaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lancamentoReceitaMutation, { data, loading, error }] = useLancamentoReceitaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useLancamentoReceitaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LancamentoReceitaMutation, LancamentoReceitaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<LancamentoReceitaMutation, LancamentoReceitaMutationVariables>(LancamentoReceitaDocument, options);
}
export type LancamentoReceitaMutationHookResult = ReturnType<typeof useLancamentoReceitaMutation>;
export type LancamentoReceitaMutationResult = Apollo.MutationResult<LancamentoReceitaMutation>;
export type LancamentoReceitaMutationOptions = Apollo.BaseMutationOptions<LancamentoReceitaMutation, LancamentoReceitaMutationVariables>;
export const ToggleDeletaLancamentoDocument = gql`
	mutation ToggleDeletaLancamento($id: Int!) {
		deletaLancamento(Dados: { IdLancamento: $id })
	}
`;
export type ToggleDeletaLancamentoMutationFn = Apollo.MutationFunction<ToggleDeletaLancamentoMutation, ToggleDeletaLancamentoMutationVariables>;

/**
 * __useToggleDeletaLancamentoMutation__
 *
 * To run a mutation, you first call `useToggleDeletaLancamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDeletaLancamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDeletaLancamentoMutation, { data, loading, error }] = useToggleDeletaLancamentoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleDeletaLancamentoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleDeletaLancamentoMutation, ToggleDeletaLancamentoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleDeletaLancamentoMutation, ToggleDeletaLancamentoMutationVariables>(ToggleDeletaLancamentoDocument, options);
}
export type ToggleDeletaLancamentoMutationHookResult = ReturnType<typeof useToggleDeletaLancamentoMutation>;
export type ToggleDeletaLancamentoMutationResult = Apollo.MutationResult<ToggleDeletaLancamentoMutation>;
export type ToggleDeletaLancamentoMutationOptions = Apollo.BaseMutationOptions<ToggleDeletaLancamentoMutation, ToggleDeletaLancamentoMutationVariables>;
export const TransferenciaContaFinanceiraDocument = gql`
	mutation TransferenciaContaFinanceira($dados: InputTransferenciaContaFinanceira!) {
		transferenciaContaFinanceira(Dados: $dados)
	}
`;
export type TransferenciaContaFinanceiraMutationFn = Apollo.MutationFunction<TransferenciaContaFinanceiraMutation, TransferenciaContaFinanceiraMutationVariables>;

/**
 * __useTransferenciaContaFinanceiraMutation__
 *
 * To run a mutation, you first call `useTransferenciaContaFinanceiraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferenciaContaFinanceiraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferenciaContaFinanceiraMutation, { data, loading, error }] = useTransferenciaContaFinanceiraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useTransferenciaContaFinanceiraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferenciaContaFinanceiraMutation, TransferenciaContaFinanceiraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<TransferenciaContaFinanceiraMutation, TransferenciaContaFinanceiraMutationVariables>(TransferenciaContaFinanceiraDocument, options);
}
export type TransferenciaContaFinanceiraMutationHookResult = ReturnType<typeof useTransferenciaContaFinanceiraMutation>;
export type TransferenciaContaFinanceiraMutationResult = Apollo.MutationResult<TransferenciaContaFinanceiraMutation>;
export type TransferenciaContaFinanceiraMutationOptions = Apollo.BaseMutationOptions<TransferenciaContaFinanceiraMutation, TransferenciaContaFinanceiraMutationVariables>;
export const MovimentoFinanceiroDocument = gql`
	query MovimentoFinanceiro($filtro: InputFiltroMovimentoFinanceiro) {
		movimentoFinanceiro(Filtro: $filtro) {
			Id
			IdLancamento
			Data
			Historico
			EntradaSaida
			NomeUsuario
			NomePessoa
			NomeConta
			Saldo
			NomeNivelPlanoContas
			SaldoAnterior
			IdNivelDefinicao
			TipoLancamento
			CodigoFinanceiro
			IdFormaPgto
		}
	}
`;

/**
 * __useMovimentoFinanceiroQuery__
 *
 * To run a query within a React component, call `useMovimentoFinanceiroQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovimentoFinanceiroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovimentoFinanceiroQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useMovimentoFinanceiroQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MovimentoFinanceiroQuery, MovimentoFinanceiroQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MovimentoFinanceiroQuery, MovimentoFinanceiroQueryVariables>(MovimentoFinanceiroDocument, options);
}
export function useMovimentoFinanceiroLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MovimentoFinanceiroQuery, MovimentoFinanceiroQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MovimentoFinanceiroQuery, MovimentoFinanceiroQueryVariables>(MovimentoFinanceiroDocument, options);
}
export type MovimentoFinanceiroQueryHookResult = ReturnType<typeof useMovimentoFinanceiroQuery>;
export type MovimentoFinanceiroLazyQueryHookResult = ReturnType<typeof useMovimentoFinanceiroLazyQuery>;
export type MovimentoFinanceiroQueryResult = Apollo.QueryResult<MovimentoFinanceiroQuery, MovimentoFinanceiroQueryVariables>;
export const OperacoesDocument = gql`
	query Operacoes($filtro: InputFiltroOperacoes) {
		operacoes(Filtro: $filtro) {
			Id
			Nome
			TipoOperacao
		}
	}
`;

/**
 * __useOperacoesQuery__
 *
 * To run a query within a React component, call `useOperacoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperacoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperacoesQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useOperacoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OperacoesQuery, OperacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<OperacoesQuery, OperacoesQueryVariables>(OperacoesDocument, options);
}
export function useOperacoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OperacoesQuery, OperacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<OperacoesQuery, OperacoesQueryVariables>(OperacoesDocument, options);
}
export type OperacoesQueryHookResult = ReturnType<typeof useOperacoesQuery>;
export type OperacoesLazyQueryHookResult = ReturnType<typeof useOperacoesLazyQuery>;
export type OperacoesQueryResult = Apollo.QueryResult<OperacoesQuery, OperacoesQueryVariables>;
export const PagamentosDocument = gql`
	query Pagamentos($paginacao: InputPaginacao, $filtro: InputFiltroPagamentos) {
		pagamentos(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Id
				IdParcela
				IdNfe
				IdLancamento
				NumeroNFe
				IdVenda
				CodigoVenda
				DataEmissao
				DataVencimento
				DataPagamento
				IdConta
				NomeConta
				NomeContaFinanceira
				IdFornecedor
				NomeFornecedor
				Historico
				CodigoFinanceiro
				ValorTotal
				PgtoParcial
				ValorJuroPgto
				ValorDesconto
				ValorPago
				ValorPagar
				IdEmpresa
				NomeEmpresa
				TipoNivelDefinicao
				StatusParcela
				TipoLancamento
				NomeFormaPagamento
				StatusPagamento
			}
			Paginacao {
				TotalRegistros
				TotalPago
				TotalAtraso
				TotalVenceHoje
				TotalVencer
			}
		}
	}
`;

/**
 * __usePagamentosQuery__
 *
 * To run a query within a React component, call `usePagamentosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagamentosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagamentosQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePagamentosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PagamentosQuery, PagamentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PagamentosQuery, PagamentosQueryVariables>(PagamentosDocument, options);
}
export function usePagamentosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PagamentosQuery, PagamentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PagamentosQuery, PagamentosQueryVariables>(PagamentosDocument, options);
}
export type PagamentosQueryHookResult = ReturnType<typeof usePagamentosQuery>;
export type PagamentosLazyQueryHookResult = ReturnType<typeof usePagamentosLazyQuery>;
export type PagamentosQueryResult = Apollo.QueryResult<PagamentosQuery, PagamentosQueryVariables>;
export const RecebimentosDocument = gql`
	query Recebimentos($paginacao: InputPaginacao, $filtro: InputFiltroRecebimentos) {
		recebimentos(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Id
				IdParcela
				IdNfe
				IdLancamento
				NumeroNFe
				IdVenda
				CodigoVenda
				DataEmissao
				DataVencimento
				DataRecebimento
				IdConta
				NomeConta
				NomeContaFinanceira
				IdCliente
				NomeCliente
				Historico
				CodigoFinanceiro
				ValorTotal
				ValorTotalJuros
				PgtoParcial
				ValorJuroPgto
				ValorDesconto
				ValorRecebido
				ValorReceber
				ValorReceberJuros
				IdEmpresa
				NomeEmpresa
				TipoNivelDefinicao
				StatusParcela
				TipoLancamento
				NomeFormaPagamento
				StatusRecebimento
			}
			Paginacao {
				TotalRegistros
				TotalQuitado
				TotalAtraso
				TotalVenceHoje
				TotalVencer
				TotalAtrasoJuros
			}
		}
	}
`;

/**
 * __useRecebimentosQuery__
 *
 * To run a query within a React component, call `useRecebimentosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecebimentosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecebimentosQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useRecebimentosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecebimentosQuery, RecebimentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<RecebimentosQuery, RecebimentosQueryVariables>(RecebimentosDocument, options);
}
export function useRecebimentosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecebimentosQuery, RecebimentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<RecebimentosQuery, RecebimentosQueryVariables>(RecebimentosDocument, options);
}
export type RecebimentosQueryHookResult = ReturnType<typeof useRecebimentosQuery>;
export type RecebimentosLazyQueryHookResult = ReturnType<typeof useRecebimentosLazyQuery>;
export type RecebimentosQueryResult = Apollo.QueryResult<RecebimentosQuery, RecebimentosQueryVariables>;
export const LancamentosDocument = gql`
	query Lancamentos($filtro: InputFiltroQueryLancamento) {
		lancamentos(Filtro: $filtro) {
			DataLancamento
			ValorTotal
			FormaPgtoPrest
		}
	}
`;

/**
 * __useLancamentosQuery__
 *
 * To run a query within a React component, call `useLancamentosQuery` and pass it any options that fit your needs.
 * When your component renders, `useLancamentosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLancamentosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useLancamentosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LancamentosQuery, LancamentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<LancamentosQuery, LancamentosQueryVariables>(LancamentosDocument, options);
}
export function useLancamentosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LancamentosQuery, LancamentosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<LancamentosQuery, LancamentosQueryVariables>(LancamentosDocument, options);
}
export type LancamentosQueryHookResult = ReturnType<typeof useLancamentosQuery>;
export type LancamentosLazyQueryHookResult = ReturnType<typeof useLancamentosLazyQuery>;
export type LancamentosQueryResult = Apollo.QueryResult<LancamentosQuery, LancamentosQueryVariables>;
export const GerarSpedFiscalDocument = gql`
	subscription GerarSpedFiscal($filtro: InputFiltroSpedFiscal!) {
		gerarSpedFiscal(Filtro: $filtro)
	}
`;

/**
 * __useGerarSpedFiscalSubscription__
 *
 * To run a query within a React component, call `useGerarSpedFiscalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGerarSpedFiscalSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGerarSpedFiscalSubscription({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useGerarSpedFiscalSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<GerarSpedFiscalSubscription, GerarSpedFiscalSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<GerarSpedFiscalSubscription, GerarSpedFiscalSubscriptionVariables>(GerarSpedFiscalDocument, options);
}
export type GerarSpedFiscalSubscriptionHookResult = ReturnType<typeof useGerarSpedFiscalSubscription>;
export type GerarSpedFiscalSubscriptionResult = Apollo.SubscriptionResult<GerarSpedFiscalSubscription>;
export const DownloadArquivosContadorDocument = gql`
	subscription DownloadArquivosContador($filtro: InputFiltroArquivo!) {
		downloadArquivosContador(Filtro: $filtro)
	}
`;

/**
 * __useDownloadArquivosContadorSubscription__
 *
 * To run a query within a React component, call `useDownloadArquivosContadorSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDownloadArquivosContadorSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadArquivosContadorSubscription({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useDownloadArquivosContadorSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<DownloadArquivosContadorSubscription, DownloadArquivosContadorSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<DownloadArquivosContadorSubscription, DownloadArquivosContadorSubscriptionVariables>(DownloadArquivosContadorDocument, options);
}
export type DownloadArquivosContadorSubscriptionHookResult = ReturnType<typeof useDownloadArquivosContadorSubscription>;
export type DownloadArquivosContadorSubscriptionResult = Apollo.SubscriptionResult<DownloadArquivosContadorSubscription>;
export const TransmiteNfeDocument = gql`
	subscription TransmiteNfe($idNfe: Int!) {
		transmiteNfe(IdNFe: $idNfe)
	}
`;

/**
 * __useTransmiteNfeSubscription__
 *
 * To run a query within a React component, call `useTransmiteNfeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransmiteNfeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmiteNfeSubscription({
 *   variables: {
 *      idNfe: // value for 'idNfe'
 *   },
 * });
 */
export function useTransmiteNfeSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<TransmiteNfeSubscription, TransmiteNfeSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<TransmiteNfeSubscription, TransmiteNfeSubscriptionVariables>(TransmiteNfeDocument, options);
}
export type TransmiteNfeSubscriptionHookResult = ReturnType<typeof useTransmiteNfeSubscription>;
export type TransmiteNfeSubscriptionResult = Apollo.SubscriptionResult<TransmiteNfeSubscription>;
export const ConsultaStatusNfeDocument = gql`
	subscription ConsultaStatusNfe($idNfe: Int!) {
		consultaStatusNFe(IdNFe: $idNfe)
	}
`;

/**
 * __useConsultaStatusNfeSubscription__
 *
 * To run a query within a React component, call `useConsultaStatusNfeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConsultaStatusNfeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultaStatusNfeSubscription({
 *   variables: {
 *      idNfe: // value for 'idNfe'
 *   },
 * });
 */
export function useConsultaStatusNfeSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<ConsultaStatusNfeSubscription, ConsultaStatusNfeSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<ConsultaStatusNfeSubscription, ConsultaStatusNfeSubscriptionVariables>(ConsultaStatusNfeDocument, options);
}
export type ConsultaStatusNfeSubscriptionHookResult = ReturnType<typeof useConsultaStatusNfeSubscription>;
export type ConsultaStatusNfeSubscriptionResult = Apollo.SubscriptionResult<ConsultaStatusNfeSubscription>;
export const ExcluiManifestoDocument = gql`
	mutation ExcluiManifesto($IdManifesto: Int!) {
		editaManifesto(Dados: { Id: $IdManifesto, Status: MANIFESTO_EXCLUIDO })
	}
`;
export type ExcluiManifestoMutationFn = Apollo.MutationFunction<ExcluiManifestoMutation, ExcluiManifestoMutationVariables>;

/**
 * __useExcluiManifestoMutation__
 *
 * To run a mutation, you first call `useExcluiManifestoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluiManifestoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluiManifestoMutation, { data, loading, error }] = useExcluiManifestoMutation({
 *   variables: {
 *      IdManifesto: // value for 'IdManifesto'
 *   },
 * });
 */
export function useExcluiManifestoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExcluiManifestoMutation, ExcluiManifestoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ExcluiManifestoMutation, ExcluiManifestoMutationVariables>(ExcluiManifestoDocument, options);
}
export type ExcluiManifestoMutationHookResult = ReturnType<typeof useExcluiManifestoMutation>;
export type ExcluiManifestoMutationResult = Apollo.MutationResult<ExcluiManifestoMutation>;
export type ExcluiManifestoMutationOptions = Apollo.BaseMutationOptions<ExcluiManifestoMutation, ExcluiManifestoMutationVariables>;
export const RecalculaDadosNfeCompraDocument = gql`
	mutation RecalculaDadosNfeCompra($dados: InputRecalculaNfe) {
		recalculaDadosNfeCompra(Dados: $dados) {
			ValorDesconto
			ValorSeguro
			ValorFrete
			ValorOutro
			ValorIcmsSt
			ValorIi
			ValorIpi
			ValorSubTotal
			ValorTotalNfe
			Itens {
				Index
				IdProduto
				ValorUnitario
				ValorCustoUnitario
				ValorDesconto
				ValorDescontoTotal
				ValorAcrescimo
				ValorFrete
				ValorSeguro
				ValorOutro
				ValorIcmsSt
				ValorIi
				ValorIpi
				ValorSubTotal
				ValorTotalItem
				Quantidade
			}
		}
	}
`;
export type RecalculaDadosNfeCompraMutationFn = Apollo.MutationFunction<RecalculaDadosNfeCompraMutation, RecalculaDadosNfeCompraMutationVariables>;

/**
 * __useRecalculaDadosNfeCompraMutation__
 *
 * To run a mutation, you first call `useRecalculaDadosNfeCompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculaDadosNfeCompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculaDadosNfeCompraMutation, { data, loading, error }] = useRecalculaDadosNfeCompraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRecalculaDadosNfeCompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecalculaDadosNfeCompraMutation, RecalculaDadosNfeCompraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RecalculaDadosNfeCompraMutation, RecalculaDadosNfeCompraMutationVariables>(RecalculaDadosNfeCompraDocument, options);
}
export type RecalculaDadosNfeCompraMutationHookResult = ReturnType<typeof useRecalculaDadosNfeCompraMutation>;
export type RecalculaDadosNfeCompraMutationResult = Apollo.MutationResult<RecalculaDadosNfeCompraMutation>;
export type RecalculaDadosNfeCompraMutationOptions = Apollo.BaseMutationOptions<RecalculaDadosNfeCompraMutation, RecalculaDadosNfeCompraMutationVariables>;
export const RemoveNfeCompraDocument = gql`
	mutation RemoveNfeCompra($id: Int!) {
		removeNfeCompra(Id: $id)
	}
`;
export type RemoveNfeCompraMutationFn = Apollo.MutationFunction<RemoveNfeCompraMutation, RemoveNfeCompraMutationVariables>;

/**
 * __useRemoveNfeCompraMutation__
 *
 * To run a mutation, you first call `useRemoveNfeCompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNfeCompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNfeCompraMutation, { data, loading, error }] = useRemoveNfeCompraMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNfeCompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveNfeCompraMutation, RemoveNfeCompraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RemoveNfeCompraMutation, RemoveNfeCompraMutationVariables>(RemoveNfeCompraDocument, options);
}
export type RemoveNfeCompraMutationHookResult = ReturnType<typeof useRemoveNfeCompraMutation>;
export type RemoveNfeCompraMutationResult = Apollo.MutationResult<RemoveNfeCompraMutation>;
export type RemoveNfeCompraMutationOptions = Apollo.BaseMutationOptions<RemoveNfeCompraMutation, RemoveNfeCompraMutationVariables>;
export const EnviarXmlCompraDocument = gql`
	mutation EnviarXMLCompra($CorrelationId: String!, $XML: Upload!) {
		enviarXMLCompra(CorrelationId: $CorrelationId, XML: $XML)
	}
`;
export type EnviarXmlCompraMutationFn = Apollo.MutationFunction<EnviarXmlCompraMutation, EnviarXmlCompraMutationVariables>;

/**
 * __useEnviarXmlCompraMutation__
 *
 * To run a mutation, you first call `useEnviarXmlCompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnviarXmlCompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enviarXmlCompraMutation, { data, loading, error }] = useEnviarXmlCompraMutation({
 *   variables: {
 *      CorrelationId: // value for 'CorrelationId'
 *      XML: // value for 'XML'
 *   },
 * });
 */
export function useEnviarXmlCompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnviarXmlCompraMutation, EnviarXmlCompraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EnviarXmlCompraMutation, EnviarXmlCompraMutationVariables>(EnviarXmlCompraDocument, options);
}
export type EnviarXmlCompraMutationHookResult = ReturnType<typeof useEnviarXmlCompraMutation>;
export type EnviarXmlCompraMutationResult = Apollo.MutationResult<EnviarXmlCompraMutation>;
export type EnviarXmlCompraMutationOptions = Apollo.BaseMutationOptions<EnviarXmlCompraMutation, EnviarXmlCompraMutationVariables>;
export const CadastraNfeCompraDocument = gql`
	mutation CadastraNfeCompra($dados: InputCadastrarNfeCompra!) {
		cadastraNfeCompra(Dados: $dados)
	}
`;
export type CadastraNfeCompraMutationFn = Apollo.MutationFunction<CadastraNfeCompraMutation, CadastraNfeCompraMutationVariables>;

/**
 * __useCadastraNfeCompraMutation__
 *
 * To run a mutation, you first call `useCadastraNfeCompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraNfeCompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraNfeCompraMutation, { data, loading, error }] = useCadastraNfeCompraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraNfeCompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraNfeCompraMutation, CadastraNfeCompraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraNfeCompraMutation, CadastraNfeCompraMutationVariables>(CadastraNfeCompraDocument, options);
}
export type CadastraNfeCompraMutationHookResult = ReturnType<typeof useCadastraNfeCompraMutation>;
export type CadastraNfeCompraMutationResult = Apollo.MutationResult<CadastraNfeCompraMutation>;
export type CadastraNfeCompraMutationOptions = Apollo.BaseMutationOptions<CadastraNfeCompraMutation, CadastraNfeCompraMutationVariables>;
export const EditaNfeCompraDocument = gql`
	mutation EditaNfeCompra($dados: InputEditarNfeCompra!) {
		editaNfeCompra(Dados: $dados)
	}
`;
export type EditaNfeCompraMutationFn = Apollo.MutationFunction<EditaNfeCompraMutation, EditaNfeCompraMutationVariables>;

/**
 * __useEditaNfeCompraMutation__
 *
 * To run a mutation, you first call `useEditaNfeCompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaNfeCompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaNfeCompraMutation, { data, loading, error }] = useEditaNfeCompraMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaNfeCompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaNfeCompraMutation, EditaNfeCompraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaNfeCompraMutation, EditaNfeCompraMutationVariables>(EditaNfeCompraDocument, options);
}
export type EditaNfeCompraMutationHookResult = ReturnType<typeof useEditaNfeCompraMutation>;
export type EditaNfeCompraMutationResult = Apollo.MutationResult<EditaNfeCompraMutation>;
export type EditaNfeCompraMutationOptions = Apollo.BaseMutationOptions<EditaNfeCompraMutation, EditaNfeCompraMutationVariables>;
export const FinalizarNfeCompraDocument = gql`
	mutation FinalizarNfeCompra($id: Int!) {
		confirmaNfeCompra(Id: $id)
	}
`;
export type FinalizarNfeCompraMutationFn = Apollo.MutationFunction<FinalizarNfeCompraMutation, FinalizarNfeCompraMutationVariables>;

/**
 * __useFinalizarNfeCompraMutation__
 *
 * To run a mutation, you first call `useFinalizarNfeCompraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizarNfeCompraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizarNfeCompraMutation, { data, loading, error }] = useFinalizarNfeCompraMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizarNfeCompraMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalizarNfeCompraMutation, FinalizarNfeCompraMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<FinalizarNfeCompraMutation, FinalizarNfeCompraMutationVariables>(FinalizarNfeCompraDocument, options);
}
export type FinalizarNfeCompraMutationHookResult = ReturnType<typeof useFinalizarNfeCompraMutation>;
export type FinalizarNfeCompraMutationResult = Apollo.MutationResult<FinalizarNfeCompraMutation>;
export type FinalizarNfeCompraMutationOptions = Apollo.BaseMutationOptions<FinalizarNfeCompraMutation, FinalizarNfeCompraMutationVariables>;
export const ManifestosDocument = gql`
	query Manifestos {
		manifestos {
			Id
			IdEmpresa
			Nome
			Cnpj
			NumNfe
			Chave
			Emissao
			Valor
			Status
			Evento
			FinalidadeNfe
			NaturezaOperacao
		}
	}
`;

/**
 * __useManifestosQuery__
 *
 * To run a query within a React component, call `useManifestosQuery` and pass it any options that fit your needs.
 * When your component renders, `useManifestosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManifestosQuery({
 *   variables: {
 *   },
 * });
 */
export function useManifestosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ManifestosQuery, ManifestosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ManifestosQuery, ManifestosQueryVariables>(ManifestosDocument, options);
}
export function useManifestosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManifestosQuery, ManifestosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ManifestosQuery, ManifestosQueryVariables>(ManifestosDocument, options);
}
export type ManifestosQueryHookResult = ReturnType<typeof useManifestosQuery>;
export type ManifestosLazyQueryHookResult = ReturnType<typeof useManifestosLazyQuery>;
export type ManifestosQueryResult = Apollo.QueryResult<ManifestosQuery, ManifestosQueryVariables>;
export const ManifestoByIdDocument = gql`
	query ManifestoById($Id: Int!) {
		manifesto(Filtro: { Id: $Id }) {
			Id
			IdEmpresa
			Nome
			Cnpj
			NumNfe
			Chave
			Emissao
			Valor
			Status
			Evento
			FinalidadeNfe
			NaturezaOperacao
		}
	}
`;

/**
 * __useManifestoByIdQuery__
 *
 * To run a query within a React component, call `useManifestoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useManifestoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManifestoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useManifestoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ManifestoByIdQuery, ManifestoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ManifestoByIdQuery, ManifestoByIdQueryVariables>(ManifestoByIdDocument, options);
}
export function useManifestoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManifestoByIdQuery, ManifestoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ManifestoByIdQuery, ManifestoByIdQueryVariables>(ManifestoByIdDocument, options);
}
export type ManifestoByIdQueryHookResult = ReturnType<typeof useManifestoByIdQuery>;
export type ManifestoByIdLazyQueryHookResult = ReturnType<typeof useManifestoByIdLazyQuery>;
export type ManifestoByIdQueryResult = Apollo.QueryResult<ManifestoByIdQuery, ManifestoByIdQueryVariables>;
export const NfeCompraDocument = gql`
	query NfeCompra($filtro: InputFiltroNfe) {
		nfe(Filtro: $filtro) {
			Id
			NumNF
			Serie
			IdPessoa
			NomePessoa
			NomeFantasiaPessoa
			IdCfop
			CodigoCfop: CFOP
			NatOperacao
			ValorTotalNfe
			ValorDesconto
			ValorFrete
			ValorOutro
			ValorSeguro
			ValorIcmsSt
			ValorIpi
			ValorIi
			ValorFcpSt
			Status
			Modelo
			Origem
			DataHoraSaiEnt
			DataHoraEmissao
			ItemNfe {
				Id
				IdProduto
				IdGradeTipo
				IdCfop
				IdNcm
				IdSituaTribut
				Codigo
				CodFornecedor
				Descricao
				Quantidade
				QuantidadeCompra
				ValorCustoUnitario
				ValorCustoMedioUnitario
				ValorUnitario
				CodBarras
				FatorConversao
				IdUnidade
				IdUnidadeCompra
				ValorDesconto
				ValorAcrescimo
				ValorDescontoTotal
				ValorSeguro
				ValorOutro
				ValorFrete
				ValorIcmsSt
				ValorIpi
				ValorIi
				ValorFcpSt
				DetalhamentoItem
				PrecoVenda
				PrecoVenda2
				MargemLucro
				CodigoCfop
				SimboloUnidade
				SimboloUnidadeCompra
				QuantidadeEstoque
				ItensGrade {
					Id
					IdItemNfe
					IdGrade
					Quantidade
					Valor
					LinhaId
					LinhaCodigo
					LinhaNome
					ColunaId
					ColunaCodigo
					ColunaNome
					GradePreco1
					GradePreco2
					GradeCodBarras
					GradeQuantidade
				}
			}
			PrestacaoNfe {
				Id
				IdFormaPgto
				NomeFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				NumLancamento
				NumDocumento
				DataVencimento
				TipoFormaPgto
				TagPgtoNFe
			}
		}
	}
`;

/**
 * __useNfeCompraQuery__
 *
 * To run a query within a React component, call `useNfeCompraQuery` and pass it any options that fit your needs.
 * When your component renders, `useNfeCompraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNfeCompraQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useNfeCompraQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NfeCompraQuery, NfeCompraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NfeCompraQuery, NfeCompraQueryVariables>(NfeCompraDocument, options);
}
export function useNfeCompraLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NfeCompraQuery, NfeCompraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NfeCompraQuery, NfeCompraQueryVariables>(NfeCompraDocument, options);
}
export type NfeCompraQueryHookResult = ReturnType<typeof useNfeCompraQuery>;
export type NfeCompraLazyQueryHookResult = ReturnType<typeof useNfeCompraLazyQuery>;
export type NfeCompraQueryResult = Apollo.QueryResult<NfeCompraQuery, NfeCompraQueryVariables>;
export const NfesCompraDocument = gql`
	query NfesCompra($filtro: InputFiltroNfes, $paginacao: InputPaginacao) {
		nfes(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Id
				NumNF
				Serie
				ValorDesconto
				ValorFrete
				ValorOutro
				ValorSeguro
				DataSaiEnt
				DataEmissao
				HoraEmissao
				NomePessoa
				IdPessoa
				NaturezaOperacao
				ValorTotalNfe
				ChaveAcesso
				Status
				TipoNF
				Origem
				DataHoraSaiEnt
				DataHoraEmissao
			}
			Paginacao {
				TotalRegistros
				TotalTransmitido
				TotalRegistrosTransmitido
				QuantidadeItensTransmitidos
			}
		}
	}
`;

/**
 * __useNfesCompraQuery__
 *
 * To run a query within a React component, call `useNfesCompraQuery` and pass it any options that fit your needs.
 * When your component renders, `useNfesCompraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNfesCompraQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function useNfesCompraQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NfesCompraQuery, NfesCompraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NfesCompraQuery, NfesCompraQueryVariables>(NfesCompraDocument, options);
}
export function useNfesCompraLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NfesCompraQuery, NfesCompraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NfesCompraQuery, NfesCompraQueryVariables>(NfesCompraDocument, options);
}
export type NfesCompraQueryHookResult = ReturnType<typeof useNfesCompraQuery>;
export type NfesCompraLazyQueryHookResult = ReturnType<typeof useNfesCompraLazyQuery>;
export type NfesCompraQueryResult = Apollo.QueryResult<NfesCompraQuery, NfesCompraQueryVariables>;
export const CodigoCfopByIdDocument = gql`
	query CodigoCfopById($filtro: InputFiltroCfop) {
		cfop(Filtro: $filtro) {
			Codigo
			Id
			IdOriginal
		}
	}
`;

/**
 * __useCodigoCfopByIdQuery__
 *
 * To run a query within a React component, call `useCodigoCfopByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoCfopByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoCfopByIdQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCodigoCfopByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoCfopByIdQuery, CodigoCfopByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoCfopByIdQuery, CodigoCfopByIdQueryVariables>(CodigoCfopByIdDocument, options);
}
export function useCodigoCfopByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoCfopByIdQuery, CodigoCfopByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoCfopByIdQuery, CodigoCfopByIdQueryVariables>(CodigoCfopByIdDocument, options);
}
export type CodigoCfopByIdQueryHookResult = ReturnType<typeof useCodigoCfopByIdQuery>;
export type CodigoCfopByIdLazyQueryHookResult = ReturnType<typeof useCodigoCfopByIdLazyQuery>;
export type CodigoCfopByIdQueryResult = Apollo.QueryResult<CodigoCfopByIdQuery, CodigoCfopByIdQueryVariables>;
export const ContasFinanceirasDocument = gql`
	query ContasFinanceiras($filtro: InputFiltroContasFinanceiras) {
		contasFinanceiras(Filtro: $filtro) {
			Id
			Nome
			Tipo
			Status
			NumConta
			DvConta
			NumAgencia
			DvAgencia
		}
	}
`;

/**
 * __useContasFinanceirasQuery__
 *
 * To run a query within a React component, call `useContasFinanceirasQuery` and pass it any options that fit your needs.
 * When your component renders, `useContasFinanceirasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContasFinanceirasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useContasFinanceirasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContasFinanceirasQuery, ContasFinanceirasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ContasFinanceirasQuery, ContasFinanceirasQueryVariables>(ContasFinanceirasDocument, options);
}
export function useContasFinanceirasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContasFinanceirasQuery, ContasFinanceirasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ContasFinanceirasQuery, ContasFinanceirasQueryVariables>(ContasFinanceirasDocument, options);
}
export type ContasFinanceirasQueryHookResult = ReturnType<typeof useContasFinanceirasQuery>;
export type ContasFinanceirasLazyQueryHookResult = ReturnType<typeof useContasFinanceirasLazyQuery>;
export type ContasFinanceirasQueryResult = Apollo.QueryResult<ContasFinanceirasQuery, ContasFinanceirasQueryVariables>;
export const AdminsCartoesDocument = gql`
	query AdminsCartoes($filtro: InputFiltroAdminsCartao) {
		adminsCartoes(Filtro: $filtro) {
			Id
			Nome
		}
	}
`;

/**
 * __useAdminsCartoesQuery__
 *
 * To run a query within a React component, call `useAdminsCartoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsCartoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsCartoesQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useAdminsCartoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminsCartoesQuery, AdminsCartoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<AdminsCartoesQuery, AdminsCartoesQueryVariables>(AdminsCartoesDocument, options);
}
export function useAdminsCartoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminsCartoesQuery, AdminsCartoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<AdminsCartoesQuery, AdminsCartoesQueryVariables>(AdminsCartoesDocument, options);
}
export type AdminsCartoesQueryHookResult = ReturnType<typeof useAdminsCartoesQuery>;
export type AdminsCartoesLazyQueryHookResult = ReturnType<typeof useAdminsCartoesLazyQuery>;
export type AdminsCartoesQueryResult = Apollo.QueryResult<AdminsCartoesQuery, AdminsCartoesQueryVariables>;
export const ItensNfeDocument = gql`
	query ItensNfe($filtro: InputFiltroItensNfe) {
		itensNfe(Filtro: $filtro) {
			Id
			IdProduto
			IdGradeTipo
			Codigo
			CodBarras
			CodFornecedor
			Descricao
			Quantidade
			SimboloUnidade
			PrecoVenda
			PrecoVenda2
			ItensGrade {
				Id
				IdItemNfe
				IdGrade
				Quantidade
				Valor
				LinhaId
				LinhaCodigo
				LinhaNome
				ColunaId
				ColunaCodigo
				ColunaNome
				GradePreco1
				GradePreco2
				GradeCodBarras
				GradeQuantidade
			}
		}
	}
`;

/**
 * __useItensNfeQuery__
 *
 * To run a query within a React component, call `useItensNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useItensNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItensNfeQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useItensNfeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ItensNfeQuery, ItensNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ItensNfeQuery, ItensNfeQueryVariables>(ItensNfeDocument, options);
}
export function useItensNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ItensNfeQuery, ItensNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ItensNfeQuery, ItensNfeQueryVariables>(ItensNfeDocument, options);
}
export type ItensNfeQueryHookResult = ReturnType<typeof useItensNfeQuery>;
export type ItensNfeLazyQueryHookResult = ReturnType<typeof useItensNfeLazyQuery>;
export type ItensNfeQueryResult = Apollo.QueryResult<ItensNfeQuery, ItensNfeQueryVariables>;
export const DevolucaoNfeCompraDocument = gql`
	query DevolucaoNfeCompra($Dados: InputDevolucaoNfeCompra!) {
		devolucaoNfeCompra(Dados: $Dados) {
			IdCfop
			CFOP
			CFOPOperacao
			DescricaoOperacao
			NatOperacao
			DataHoraEmissao
			DataHoraSaiEnt
			ModCliente
			Status
			IdPessoa
			NomePessoa
			NomeFantasiaPessoa
			CpfCnpjPessoa
			IdNfeRef
			TelefonePessoa
			CelularPessoa
			EmailPessoa
			CidadePessoa
			EstadoPessoa
			ChaveAcesso
			RuaPessoa
			BairroPessoa
			NumeroPessoa
			ComplementoPessoa
			TipoNFe: TipoNF
			ChaveAcesso
			ArquivoPdf
			ItemNfe {
				IdProduto
				IdUnidade
				IdSituaTribut
				IdCfop
				IdNcm
				IdCest
				IdGrade
				IdLinha
				IdColuna
				Codigo
				Descricao
				CodigoCfop
				Quantidade
				SimboloUnidade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				ValorCustoMedioUnitario
				ValorCustoUnitario
				FatorConversao
				CodBarras
				CodBarrasGrade
				DescricaoComp
				DescricaoGrade
				DescricaoProduto
				CodBenef
				PedidoDeCompra
				NumItemPedido
				ValorBCIcms
				PorcentIcms
				ValorIcms
				ValorBCIcmsSt
				PorcentIcmsSt
				ValorIcmsSt
				ValorBCIpi
				PorcentIpi
				ValorIpi
				IdCstIpi
				PorcentDevol
				IdCstPis
				IdCstCofins
				ValorBCFcpStRet
				PorcentFcpStRet
				ValorFcpStRet
				ValorIcmsSubstituto
				ValorBCEfet
				PorcentIcmsEfet
				PorcentIcmsEfet
				PorcentRedBCEfet
				ValorBCStRet
				PorcentSt
				ValorIcmsStRet
				ValorIcmsDeson
				MotDesIcms
				ValorIcmsEfet
				ValorIi
				ValorFcpSt
				ValorIpiDevol
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				OutrasOpcoes
			}
			ValorDesconto
			ValorSeguro
			ValorOutro
			ValorFrete
			Frete
			IdVendedor
			OutrasOpcoes
			IdTransportadora
			NomeTransportadora
			NomeFantasiaTransportadora
			CpfCnpjTransportadora
			TelefoneTransportadora
			CelularTransportadora
			EmailTransportadora
			CidadeTransportadora
			EstadoTransportadora
			RuaTransportadora
			BairroTransportadora
			NumeroTransportadora
			ComplementoTransportadora
			IdVeiculo
			PlacaVeiculo
			RNTCVeiculo
			PlacaReboqueVeiculo
			RNTCReboqueVeiculo
			VolumeNfe {
				Quantidade
				Especie
				Marca
				Numeracao
				PesoLiquido
				PesoBruto
			}
			PrestacaoNfe {
				IdFormaPgto
				NomeFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				NumLancamento
				NumDocumento
				DataVencimento
				TipoFormaPgto
				TagPgtoNFe
			}
			VendaNfe {
				Id
			}
			ReferenciaNfe {
				IdEmpresa
				IdNfeRef
				IdEstado
				IdFornecedor
				ChaveAcesso
				CnpjEmitente
				Modelo
				Serie
				NumNf
				DataEmissao
				Tipo
				IndEmitente
				IndOperacao
			}
			ValorIcms
			ValorIcmsSt
			ValorIpi
			ValorFcpSt
			ValorIi
			ObservacaoComplementar
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useDevolucaoNfeCompraQuery__
 *
 * To run a query within a React component, call `useDevolucaoNfeCompraQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevolucaoNfeCompraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevolucaoNfeCompraQuery({
 *   variables: {
 *      Dados: // value for 'Dados'
 *   },
 * });
 */
export function useDevolucaoNfeCompraQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DevolucaoNfeCompraQuery, DevolucaoNfeCompraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DevolucaoNfeCompraQuery, DevolucaoNfeCompraQueryVariables>(DevolucaoNfeCompraDocument, options);
}
export function useDevolucaoNfeCompraLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevolucaoNfeCompraQuery, DevolucaoNfeCompraQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DevolucaoNfeCompraQuery, DevolucaoNfeCompraQueryVariables>(DevolucaoNfeCompraDocument, options);
}
export type DevolucaoNfeCompraQueryHookResult = ReturnType<typeof useDevolucaoNfeCompraQuery>;
export type DevolucaoNfeCompraLazyQueryHookResult = ReturnType<typeof useDevolucaoNfeCompraLazyQuery>;
export type DevolucaoNfeCompraQueryResult = Apollo.QueryResult<DevolucaoNfeCompraQuery, DevolucaoNfeCompraQueryVariables>;
export const LerXmlManifestoDocument = gql`
	subscription LerXMLManifesto($idManifesto: Int!) {
		lerXMLManifestoNFe(IdManifesto: $idManifesto)
	}
`;

/**
 * __useLerXmlManifestoSubscription__
 *
 * To run a query within a React component, call `useLerXmlManifestoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLerXmlManifestoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLerXmlManifestoSubscription({
 *   variables: {
 *      idManifesto: // value for 'idManifesto'
 *   },
 * });
 */
export function useLerXmlManifestoSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<LerXmlManifestoSubscription, LerXmlManifestoSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<LerXmlManifestoSubscription, LerXmlManifestoSubscriptionVariables>(LerXmlManifestoDocument, options);
}
export type LerXmlManifestoSubscriptionHookResult = ReturnType<typeof useLerXmlManifestoSubscription>;
export type LerXmlManifestoSubscriptionResult = Apollo.SubscriptionResult<LerXmlManifestoSubscription>;
export const EventoManifestoNfeDocument = gql`
	subscription EventoManifestoNfe($idsManifesto: [Int]!, $evento: EventoManifestoNFe!, $justificativaEvento: String) {
		eventoManifestoNFe(IdsManifesto: $idsManifesto, Evento: $evento, JustificativaEvento: $justificativaEvento)
	}
`;

/**
 * __useEventoManifestoNfeSubscription__
 *
 * To run a query within a React component, call `useEventoManifestoNfeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventoManifestoNfeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventoManifestoNfeSubscription({
 *   variables: {
 *      idsManifesto: // value for 'idsManifesto'
 *      evento: // value for 'evento'
 *      justificativaEvento: // value for 'justificativaEvento'
 *   },
 * });
 */
export function useEventoManifestoNfeSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<EventoManifestoNfeSubscription, EventoManifestoNfeSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<EventoManifestoNfeSubscription, EventoManifestoNfeSubscriptionVariables>(EventoManifestoNfeDocument, options);
}
export type EventoManifestoNfeSubscriptionHookResult = ReturnType<typeof useEventoManifestoNfeSubscription>;
export type EventoManifestoNfeSubscriptionResult = Apollo.SubscriptionResult<EventoManifestoNfeSubscription>;
export const ConsultarManifestoNFeSefazDocument = gql`
	subscription ConsultarManifestoNFeSefaz($manifestarCienciaOperacao: Boolean!) {
		consultarManifestoNFeSefaz(ManifestarCienciaOperacao: $manifestarCienciaOperacao)
	}
`;

/**
 * __useConsultarManifestoNFeSefazSubscription__
 *
 * To run a query within a React component, call `useConsultarManifestoNFeSefazSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConsultarManifestoNFeSefazSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultarManifestoNFeSefazSubscription({
 *   variables: {
 *      manifestarCienciaOperacao: // value for 'manifestarCienciaOperacao'
 *   },
 * });
 */
export function useConsultarManifestoNFeSefazSubscription(
	baseOptions: ApolloReactHooks.SubscriptionHookOptions<ConsultarManifestoNFeSefazSubscription, ConsultarManifestoNFeSefazSubscriptionVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<ConsultarManifestoNFeSefazSubscription, ConsultarManifestoNFeSefazSubscriptionVariables>(ConsultarManifestoNFeSefazDocument, options);
}
export type ConsultarManifestoNFeSefazSubscriptionHookResult = ReturnType<typeof useConsultarManifestoNFeSefazSubscription>;
export type ConsultarManifestoNFeSefazSubscriptionResult = Apollo.SubscriptionResult<ConsultarManifestoNFeSefazSubscription>;
export const CadastraNfeDocument = gql`
	mutation CadastraNfe($dados: InputCadastrarNfe!, $dadosTransmissao: InputDadosTransmissaoNFe) {
		cadastraNfe(Dados: $dados, DadosTransmissao: $dadosTransmissao)
	}
`;
export type CadastraNfeMutationFn = Apollo.MutationFunction<CadastraNfeMutation, CadastraNfeMutationVariables>;

/**
 * __useCadastraNfeMutation__
 *
 * To run a mutation, you first call `useCadastraNfeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraNfeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraNfeMutation, { data, loading, error }] = useCadastraNfeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *      dadosTransmissao: // value for 'dadosTransmissao'
 *   },
 * });
 */
export function useCadastraNfeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraNfeMutation, CadastraNfeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraNfeMutation, CadastraNfeMutationVariables>(CadastraNfeDocument, options);
}
export type CadastraNfeMutationHookResult = ReturnType<typeof useCadastraNfeMutation>;
export type CadastraNfeMutationResult = Apollo.MutationResult<CadastraNfeMutation>;
export type CadastraNfeMutationOptions = Apollo.BaseMutationOptions<CadastraNfeMutation, CadastraNfeMutationVariables>;
export const EditaNfeDocument = gql`
	mutation EditaNfe($dados: InputEditarNfe!, $dadosTransmissao: InputDadosTransmissaoNFe) {
		editaNfe(Dados: $dados, DadosTransmissao: $dadosTransmissao)
	}
`;
export type EditaNfeMutationFn = Apollo.MutationFunction<EditaNfeMutation, EditaNfeMutationVariables>;

/**
 * __useEditaNfeMutation__
 *
 * To run a mutation, you first call `useEditaNfeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaNfeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaNfeMutation, { data, loading, error }] = useEditaNfeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *      dadosTransmissao: // value for 'dadosTransmissao'
 *   },
 * });
 */
export function useEditaNfeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaNfeMutation, EditaNfeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaNfeMutation, EditaNfeMutationVariables>(EditaNfeDocument, options);
}
export type EditaNfeMutationHookResult = ReturnType<typeof useEditaNfeMutation>;
export type EditaNfeMutationResult = Apollo.MutationResult<EditaNfeMutation>;
export type EditaNfeMutationOptions = Apollo.BaseMutationOptions<EditaNfeMutation, EditaNfeMutationVariables>;
export const CadastraEventoNfeDocument = gql`
	mutation CadastraEventoNfe($dados: InputCadastrarEventoNfe!) {
		cadastraEventoNfe(Dados: $dados)
	}
`;
export type CadastraEventoNfeMutationFn = Apollo.MutationFunction<CadastraEventoNfeMutation, CadastraEventoNfeMutationVariables>;

/**
 * __useCadastraEventoNfeMutation__
 *
 * To run a mutation, you first call `useCadastraEventoNfeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraEventoNfeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraEventoNfeMutation, { data, loading, error }] = useCadastraEventoNfeMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraEventoNfeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraEventoNfeMutation, CadastraEventoNfeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraEventoNfeMutation, CadastraEventoNfeMutationVariables>(CadastraEventoNfeDocument, options);
}
export type CadastraEventoNfeMutationHookResult = ReturnType<typeof useCadastraEventoNfeMutation>;
export type CadastraEventoNfeMutationResult = Apollo.MutationResult<CadastraEventoNfeMutation>;
export type CadastraEventoNfeMutationOptions = Apollo.BaseMutationOptions<CadastraEventoNfeMutation, CadastraEventoNfeMutationVariables>;
export const RecalculaDadosNfeEmissaoDocument = gql`
	mutation RecalculaDadosNfeEmissao($dados: InputCalculaImpostoNfe) {
		recalculaDadosNfeEmissao(Dados: $dados) {
			ValorDesconto
			ValorSeguro
			ValorFrete
			ValorOutro
			IdPessoa
			TipoNF
			IdCfop
			ModCliente
			IdEnderecoEntrega
			OutrasOpcoes
			ValorIpiDevol
			ValorBcIcms
			ValorIcms
			ValorBcIcmsSt
			ValorIcmsSt
			ValorIi
			ValorPis
			ValorCofins
			ValorIcmsDesonerado
			ValorIpi
			ValorBCIss
			ValorIss
			ValorFcpSt
			ValorSubTotal
			ValorTotalNfe
			ObservacaoComplementar
			Itens {
				Index
				IdProduto
				IdNcm
				IdCfop
				Quantidade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				IdSituaTribut
				IdCstIpi
				IdCstPis
				IdCstCofins
				IdOperacaoCfop
				DetalhamentoItem
				ValorIcms
				ValorBCIcms
				PorcentIcms
				ValorBCIcmsSt
				PorcentIcmsSt
				ValorIcmsSt
				PorcentSt
				ValorIcmsDeson
				ValorIpi
				ValorBCIpi
				PorcentIpi
				ValorIi
				ValorIcmsEfet
				ValorBCEfet
				PorcentIcmsEfet
				PorcentRedBCEfet
				ValorIcmsStRet
				ValorIcmsSubstituto
				ValorFcpSt
				ValorFcpStRet
				PorcentFcpStRet
				ValorBCFcpStRet
				ValorBCStRet
				ValorIpiDevol
				CodigoCfop
				IdCest
				OutrasOpcoes
			}
		}
	}
`;
export type RecalculaDadosNfeEmissaoMutationFn = Apollo.MutationFunction<RecalculaDadosNfeEmissaoMutation, RecalculaDadosNfeEmissaoMutationVariables>;

/**
 * __useRecalculaDadosNfeEmissaoMutation__
 *
 * To run a mutation, you first call `useRecalculaDadosNfeEmissaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculaDadosNfeEmissaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculaDadosNfeEmissaoMutation, { data, loading, error }] = useRecalculaDadosNfeEmissaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRecalculaDadosNfeEmissaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecalculaDadosNfeEmissaoMutation, RecalculaDadosNfeEmissaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RecalculaDadosNfeEmissaoMutation, RecalculaDadosNfeEmissaoMutationVariables>(RecalculaDadosNfeEmissaoDocument, options);
}
export type RecalculaDadosNfeEmissaoMutationHookResult = ReturnType<typeof useRecalculaDadosNfeEmissaoMutation>;
export type RecalculaDadosNfeEmissaoMutationResult = Apollo.MutationResult<RecalculaDadosNfeEmissaoMutation>;
export type RecalculaDadosNfeEmissaoMutationOptions = Apollo.BaseMutationOptions<RecalculaDadosNfeEmissaoMutation, RecalculaDadosNfeEmissaoMutationVariables>;
export const GerarDanFeDocument = gql`
	mutation GerarDANFe($idNFe: Int!) {
		gerarDANFe(IdNFe: $idNFe)
	}
`;
export type GerarDanFeMutationFn = Apollo.MutationFunction<GerarDanFeMutation, GerarDanFeMutationVariables>;

/**
 * __useGerarDanFeMutation__
 *
 * To run a mutation, you first call `useGerarDanFeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarDanFeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarDanFeMutation, { data, loading, error }] = useGerarDanFeMutation({
 *   variables: {
 *      idNFe: // value for 'idNFe'
 *   },
 * });
 */
export function useGerarDanFeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GerarDanFeMutation, GerarDanFeMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<GerarDanFeMutation, GerarDanFeMutationVariables>(GerarDanFeDocument, options);
}
export type GerarDanFeMutationHookResult = ReturnType<typeof useGerarDanFeMutation>;
export type GerarDanFeMutationResult = Apollo.MutationResult<GerarDanFeMutation>;
export type GerarDanFeMutationOptions = Apollo.BaseMutationOptions<GerarDanFeMutation, GerarDanFeMutationVariables>;
export const NfeDocument = gql`
	query Nfe($filtro: InputFiltroNfe) {
		nfe(Filtro: $filtro) {
			Id
			NumNF
			Serie
			IdCfop
			CFOP
			CFOPOperacao
			DescricaoOperacao
			NatOperacao
			DataHoraEmissao
			DataHoraSaiEnt
			ModCliente
			Status
			IdPessoa
			NomePessoa
			NomeFantasiaPessoa
			CpfCnpjPessoa
			IdNfeRef
			TelefonePessoa
			CelularPessoa
			EmailPessoa
			CidadePessoa
			EstadoPessoa
			ChaveAcesso
			RuaPessoa
			BairroPessoa
			NumeroPessoa
			ComplementoPessoa
			TipoNFe: TipoNF
			ChaveAcesso
			ArquivoPdf
			ItemNfe {
				Id
				IdProduto
				IdUnidade
				IdSituaTribut
				IdCfop
				IdNcm
				IdCest
				IdGrade
				IdLinha
				IdColuna
				Codigo
				Descricao
				CodigoCfop
				Quantidade
				SimboloUnidade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				ValorCustoMedioUnitario
				ValorCustoUnitario
				FatorConversao
				CodBarras
				CodBarrasGrade
				DescricaoComp
				DescricaoGrade
				DescricaoProduto
				CodBenef
				PedidoDeCompra
				NumItemPedido
				ValorBCIcms
				PorcentIcms
				ValorIcms
				ValorBCIcmsSt
				PorcentIcmsSt
				ValorIcmsSt
				ValorBCIpi
				PorcentIpi
				ValorIpi
				IdCstIpi
				PorcentDevol
				IdCstPis
				IdCstCofins
				ValorBCFcpStRet
				PorcentFcpStRet
				ValorFcpStRet
				ValorIcmsSubstituto
				ValorBCEfet
				PorcentIcmsEfet
				PorcentIcmsEfet
				PorcentRedBCEfet
				ValorBCStRet
				PorcentSt
				ValorIcmsStRet
				ValorIcmsDeson
				MotDesIcms
				ValorIcmsEfet
				ValorIi
				ValorFcpSt
				ValorIpiDevol
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				OutrasOpcoes
			}
			ValorDesconto
			ValorSeguro
			ValorOutro
			ValorFrete
			Frete
			IdVendedor
			IdIntermediador
			OutrasOpcoes
			IdTransportadora
			NomeTransportadora
			NomeFantasiaTransportadora
			CpfCnpjTransportadora
			TelefoneTransportadora
			CelularTransportadora
			EmailTransportadora
			CidadeTransportadora
			EstadoTransportadora
			RuaTransportadora
			BairroTransportadora
			NumeroTransportadora
			ComplementoTransportadora
			IdVeiculo
			PlacaVeiculo
			RNTCVeiculo
			PlacaReboqueVeiculo
			RNTCReboqueVeiculo
			VolumeNfe {
				Id
				Quantidade
				Especie
				Marca
				Numeracao
				PesoLiquido
				PesoBruto
			}
			PrestacaoNfe {
				Id
				IdFormaPgto
				NomeFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				NumLancamento
				NumDocumento
				DataVencimento
				TipoFormaPgto
				TagPgtoNFe
			}
			VendaNfe {
				Id
				CodigoVenda
			}
			ReferenciaNfe {
				Id
				IdEmpresa
				IdNfe
				IdNfeRef
				IdEstado
				IdFornecedor
				ChaveAcesso
				CnpjEmitente
				Modelo
				Serie
				NumNf
				DataEmissao
				Tipo
				IndEmitente
				IndOperacao
			}
			ValorIcms
			ValorIcmsSt
			ValorIpi
			ValorFcpSt
			ValorIi
			ObservacaoComplementar
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useNfeQuery__
 *
 * To run a query within a React component, call `useNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNfeQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useNfeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NfeQuery, NfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NfeQuery, NfeQueryVariables>(NfeDocument, options);
}
export function useNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NfeQuery, NfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NfeQuery, NfeQueryVariables>(NfeDocument, options);
}
export type NfeQueryHookResult = ReturnType<typeof useNfeQuery>;
export type NfeLazyQueryHookResult = ReturnType<typeof useNfeLazyQuery>;
export type NfeQueryResult = Apollo.QueryResult<NfeQuery, NfeQueryVariables>;
export const NfesDocument = gql`
	query Nfes($filtro: InputFiltroNfes, $paginacao: InputPaginacao) {
		nfes(Paginacao: $paginacao, Filtro: $filtro) {
			Paginacao {
				TotalRegistros
				TotalTransmitido
				TotalRegistrosTransmitido
				QuantidadeItensTransmitidos
			}
			Data {
				Id
				IdNfeRef
				NumNF
				DataEmissao
				DataSaiEnt
				Origem
				CFOP
				Serie
				TipoNF
				IdPessoa
				NomePessoa
				NomeFantasiaPessoa
				ValorTotalNfe
				NatOperacao
				ChaveAcesso
				ArquivoPdf
				ArquivoXml
				IdCfop
				Status
				CFOPOperacao
				DataHoraEmissao
				DataHoraSaiEnt
			}
		}
	}
`;

/**
 * __useNfesQuery__
 *
 * To run a query within a React component, call `useNfesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNfesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNfesQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function useNfesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NfesQuery, NfesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NfesQuery, NfesQueryVariables>(NfesDocument, options);
}
export function useNfesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NfesQuery, NfesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NfesQuery, NfesQueryVariables>(NfesDocument, options);
}
export type NfesQueryHookResult = ReturnType<typeof useNfesQuery>;
export type NfesLazyQueryHookResult = ReturnType<typeof useNfesLazyQuery>;
export type NfesQueryResult = Apollo.QueryResult<NfesQuery, NfesQueryVariables>;
export const EventosNfeDocument = gql`
	query EventosNfe($filtro: InputFiltroEventosNfe) {
		eventosNfe(Filtro: $filtro) {
			Id
			TipoEvento
			Texto
			Status
			IdNFe
			Serie
			NumNF
			DataRegistro
		}
	}
`;

/**
 * __useEventosNfeQuery__
 *
 * To run a query within a React component, call `useEventosNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventosNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventosNfeQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useEventosNfeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventosNfeQuery, EventosNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<EventosNfeQuery, EventosNfeQueryVariables>(EventosNfeDocument, options);
}
export function useEventosNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventosNfeQuery, EventosNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<EventosNfeQuery, EventosNfeQueryVariables>(EventosNfeDocument, options);
}
export type EventosNfeQueryHookResult = ReturnType<typeof useEventosNfeQuery>;
export type EventosNfeLazyQueryHookResult = ReturnType<typeof useEventosNfeLazyQuery>;
export type EventosNfeQueryResult = Apollo.QueryResult<EventosNfeQuery, EventosNfeQueryVariables>;
export const EventoNfeDocument = gql`
	query EventoNfe($filtro: InputFiltroEventoNfe) {
		eventoNfe(Filtro: $filtro) {
			Id
			TipoEvento
			Texto
			Status
			IdNFe
			Serie
			NumNF
			DataRegistro
		}
	}
`;

/**
 * __useEventoNfeQuery__
 *
 * To run a query within a React component, call `useEventoNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventoNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventoNfeQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useEventoNfeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventoNfeQuery, EventoNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<EventoNfeQuery, EventoNfeQueryVariables>(EventoNfeDocument, options);
}
export function useEventoNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventoNfeQuery, EventoNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<EventoNfeQuery, EventoNfeQueryVariables>(EventoNfeDocument, options);
}
export type EventoNfeQueryHookResult = ReturnType<typeof useEventoNfeQuery>;
export type EventoNfeLazyQueryHookResult = ReturnType<typeof useEventoNfeLazyQuery>;
export type EventoNfeQueryResult = Apollo.QueryResult<EventoNfeQuery, EventoNfeQueryVariables>;
export const NiveisDocument = gql`
	query Niveis($filtro: InputFiltroNiveis) {
		niveis(Filtro: $filtro) {
			Id
			Descricao
			Tipo
			TipoPessoa
		}
	}
`;

/**
 * __useNiveisQuery__
 *
 * To run a query within a React component, call `useNiveisQuery` and pass it any options that fit your needs.
 * When your component renders, `useNiveisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNiveisQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useNiveisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NiveisQuery, NiveisQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NiveisQuery, NiveisQueryVariables>(NiveisDocument, options);
}
export function useNiveisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NiveisQuery, NiveisQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NiveisQuery, NiveisQueryVariables>(NiveisDocument, options);
}
export type NiveisQueryHookResult = ReturnType<typeof useNiveisQuery>;
export type NiveisLazyQueryHookResult = ReturnType<typeof useNiveisLazyQuery>;
export type NiveisQueryResult = Apollo.QueryResult<NiveisQuery, NiveisQueryVariables>;
export const UpdateNfeDocument = gql`
	query UpdateNfe($filtro: InputFiltroNfe) {
		nfe(Filtro: $filtro) {
			Id
			NumNF
			DataEmissao
			DataSaiEnt
			Origem
			CFOP
			Serie
			TipoNF
			IdPessoa
			NomePessoa
			ValorTotalNfe
			NatOperacao
			ChaveAcesso
			ArquivoPdf
			ArquivoXml
			IdCfop
			Status
		}
	}
`;

/**
 * __useUpdateNfeQuery__
 *
 * To run a query within a React component, call `useUpdateNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateNfeQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useUpdateNfeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpdateNfeQuery, UpdateNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UpdateNfeQuery, UpdateNfeQueryVariables>(UpdateNfeDocument, options);
}
export function useUpdateNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpdateNfeQuery, UpdateNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UpdateNfeQuery, UpdateNfeQueryVariables>(UpdateNfeDocument, options);
}
export type UpdateNfeQueryHookResult = ReturnType<typeof useUpdateNfeQuery>;
export type UpdateNfeLazyQueryHookResult = ReturnType<typeof useUpdateNfeLazyQuery>;
export type UpdateNfeQueryResult = Apollo.QueryResult<UpdateNfeQuery, UpdateNfeQueryVariables>;
export const LancamentoVinculadoNfeDocument = gql`
	query LancamentoVinculadoNfe($idNfe: Int!) {
		lancamentoVinculadoNfe(IdNfe: $idNfe)
	}
`;

/**
 * __useLancamentoVinculadoNfeQuery__
 *
 * To run a query within a React component, call `useLancamentoVinculadoNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLancamentoVinculadoNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLancamentoVinculadoNfeQuery({
 *   variables: {
 *      idNfe: // value for 'idNfe'
 *   },
 * });
 */
export function useLancamentoVinculadoNfeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LancamentoVinculadoNfeQuery, LancamentoVinculadoNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<LancamentoVinculadoNfeQuery, LancamentoVinculadoNfeQueryVariables>(LancamentoVinculadoNfeDocument, options);
}
export function useLancamentoVinculadoNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LancamentoVinculadoNfeQuery, LancamentoVinculadoNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<LancamentoVinculadoNfeQuery, LancamentoVinculadoNfeQueryVariables>(LancamentoVinculadoNfeDocument, options);
}
export type LancamentoVinculadoNfeQueryHookResult = ReturnType<typeof useLancamentoVinculadoNfeQuery>;
export type LancamentoVinculadoNfeLazyQueryHookResult = ReturnType<typeof useLancamentoVinculadoNfeLazyQuery>;
export type LancamentoVinculadoNfeQueryResult = Apollo.QueryResult<LancamentoVinculadoNfeQuery, LancamentoVinculadoNfeQueryVariables>;
export const UltimaRegraAtualizadaDocument = gql`
	query UltimaRegraAtualizada($filtro: InputFiltroRegraAtualizada) {
		ultimaRegraAtualizada(Filtro: $filtro)
	}
`;

/**
 * __useUltimaRegraAtualizadaQuery__
 *
 * To run a query within a React component, call `useUltimaRegraAtualizadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUltimaRegraAtualizadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUltimaRegraAtualizadaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useUltimaRegraAtualizadaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UltimaRegraAtualizadaQuery, UltimaRegraAtualizadaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UltimaRegraAtualizadaQuery, UltimaRegraAtualizadaQueryVariables>(UltimaRegraAtualizadaDocument, options);
}
export function useUltimaRegraAtualizadaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UltimaRegraAtualizadaQuery, UltimaRegraAtualizadaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UltimaRegraAtualizadaQuery, UltimaRegraAtualizadaQueryVariables>(UltimaRegraAtualizadaDocument, options);
}
export type UltimaRegraAtualizadaQueryHookResult = ReturnType<typeof useUltimaRegraAtualizadaQuery>;
export type UltimaRegraAtualizadaLazyQueryHookResult = ReturnType<typeof useUltimaRegraAtualizadaLazyQuery>;
export type UltimaRegraAtualizadaQueryResult = Apollo.QueryResult<UltimaRegraAtualizadaQuery, UltimaRegraAtualizadaQueryVariables>;
export const EstornoNfeDocument = gql`
	query EstornoNfe($idNFe: Int!) {
		estornoNfe(IdNFe: $idNFe) {
			IdCfop
			CFOP
			CFOPOperacao
			DescricaoOperacao
			NatOperacao
			DataHoraEmissao
			DataHoraSaiEnt
			ModCliente
			Status
			IdPessoa
			NomePessoa
			NomeFantasiaPessoa
			CpfCnpjPessoa
			IdNfeRef
			TelefonePessoa
			CelularPessoa
			EmailPessoa
			CidadePessoa
			EstadoPessoa
			ChaveAcesso
			RuaPessoa
			BairroPessoa
			NumeroPessoa
			ComplementoPessoa
			TipoNFe: TipoNF
			ChaveAcesso
			ArquivoPdf
			ItemNfe {
				IdProduto
				IdUnidade
				IdSituaTribut
				IdCfop
				IdNcm
				IdCest
				IdGrade
				IdLinha
				IdColuna
				Codigo
				Descricao
				CodigoCfop
				Quantidade
				SimboloUnidade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				ValorCustoMedioUnitario
				ValorCustoUnitario
				CodBarras
				CodBarrasGrade
				DescricaoComp
				DescricaoGrade
				DescricaoProduto
				CodBenef
				PedidoDeCompra
				NumItemPedido
				ValorBCIcms
				PorcentIcms
				ValorIcms
				ValorBCIcmsSt
				PorcentIcmsSt
				ValorIcmsSt
				ValorBCIpi
				PorcentIpi
				ValorIpi
				IdCstIpi
				PorcentDevol
				IdCstPis
				IdCstCofins
				ValorBCFcpStRet
				PorcentFcpStRet
				ValorFcpStRet
				ValorIcmsSubstituto
				ValorBCEfet
				PorcentIcmsEfet
				PorcentIcmsEfet
				PorcentRedBCEfet
				ValorBCStRet
				PorcentSt
				ValorIcmsStRet
				ValorIcmsDeson
				MotDesIcms
				ValorIcmsEfet
				ValorIi
				ValorFcpSt
				ValorIpiDevol
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				OutrasOpcoes
			}
			ValorDesconto
			ValorSeguro
			ValorOutro
			ValorFrete
			Frete
			IdVendedor
			OutrasOpcoes
			IdTransportadora
			NomeTransportadora
			NomeFantasiaTransportadora
			CpfCnpjTransportadora
			TelefoneTransportadora
			CelularTransportadora
			EmailTransportadora
			CidadeTransportadora
			EstadoTransportadora
			RuaTransportadora
			BairroTransportadora
			NumeroTransportadora
			ComplementoTransportadora
			IdVeiculo
			PlacaVeiculo
			RNTCVeiculo
			PlacaReboqueVeiculo
			RNTCReboqueVeiculo
			VolumeNfe {
				Quantidade
				Especie
				Marca
				Numeracao
				PesoLiquido
				PesoBruto
			}
			PrestacaoNfe {
				IdFormaPgto
				NomeFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				NumLancamento
				NumDocumento
				DataVencimento
				TipoFormaPgto
				TagPgtoNFe
			}
			VendaNfe {
				Id
			}
			ReferenciaNfe {
				IdEmpresa
				IdNfeRef
				IdEstado
				IdFornecedor
				ChaveAcesso
				CnpjEmitente
				Modelo
				Serie
				NumNf
				DataEmissao
				Tipo
				IndEmitente
				IndOperacao
			}
			ValorIcms
			ValorIcmsSt
			ValorIpi
			ValorFcpSt
			ValorIi
			ObservacaoComplementar
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useEstornoNfeQuery__
 *
 * To run a query within a React component, call `useEstornoNfeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstornoNfeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstornoNfeQuery({
 *   variables: {
 *      idNFe: // value for 'idNFe'
 *   },
 * });
 */
export function useEstornoNfeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EstornoNfeQuery, EstornoNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<EstornoNfeQuery, EstornoNfeQueryVariables>(EstornoNfeDocument, options);
}
export function useEstornoNfeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EstornoNfeQuery, EstornoNfeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<EstornoNfeQuery, EstornoNfeQueryVariables>(EstornoNfeDocument, options);
}
export type EstornoNfeQueryHookResult = ReturnType<typeof useEstornoNfeQuery>;
export type EstornoNfeLazyQueryHookResult = ReturnType<typeof useEstornoNfeLazyQuery>;
export type EstornoNfeQueryResult = Apollo.QueryResult<EstornoNfeQuery, EstornoNfeQueryVariables>;
export const DevolucaoNfeVendaDocument = gql`
	query DevolucaoNfeVenda($idNFe: Int!) {
		devolucaoNfeVenda(IdNFe: $idNFe) {
			IdCfop
			CFOP
			CFOPOperacao
			DescricaoOperacao
			NatOperacao
			DataHoraEmissao
			DataHoraSaiEnt
			ModCliente
			Status
			IdPessoa
			NomePessoa
			NomeFantasiaPessoa
			CpfCnpjPessoa
			IdNfeRef
			TelefonePessoa
			CelularPessoa
			EmailPessoa
			CidadePessoa
			EstadoPessoa
			ChaveAcesso
			RuaPessoa
			BairroPessoa
			NumeroPessoa
			ComplementoPessoa
			TipoNFe: TipoNF
			ChaveAcesso
			ArquivoPdf
			ItemNfe {
				IdProduto
				IdUnidade
				IdSituaTribut
				IdCfop
				IdNcm
				IdCest
				IdGrade
				IdLinha
				IdColuna
				Codigo
				Descricao
				CodigoCfop
				Quantidade
				SimboloUnidade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				ValorCustoMedioUnitario
				ValorCustoUnitario
				CodBarras
				CodBarrasGrade
				DescricaoComp
				DescricaoGrade
				DescricaoProduto
				CodBenef
				PedidoDeCompra
				NumItemPedido
				ValorBCIcms
				PorcentIcms
				ValorIcms
				ValorBCIcmsSt
				PorcentIcmsSt
				ValorIcmsSt
				ValorBCIpi
				PorcentIpi
				ValorIpi
				IdCstIpi
				PorcentDevol
				IdCstPis
				IdCstCofins
				ValorBCFcpStRet
				PorcentFcpStRet
				ValorFcpStRet
				ValorIcmsSubstituto
				ValorBCEfet
				PorcentIcmsEfet
				PorcentIcmsEfet
				PorcentRedBCEfet
				ValorBCStRet
				PorcentSt
				ValorIcmsStRet
				ValorIcmsDeson
				MotDesIcms
				ValorIcmsEfet
				ValorIi
				ValorFcpSt
				ValorIpiDevol
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				OutrasOpcoes
			}
			ValorDesconto
			ValorSeguro
			ValorOutro
			ValorFrete
			Frete
			IdVendedor
			OutrasOpcoes
			IdTransportadora
			NomeTransportadora
			NomeFantasiaTransportadora
			CpfCnpjTransportadora
			TelefoneTransportadora
			CelularTransportadora
			EmailTransportadora
			CidadeTransportadora
			EstadoTransportadora
			RuaTransportadora
			BairroTransportadora
			NumeroTransportadora
			ComplementoTransportadora
			IdVeiculo
			PlacaVeiculo
			RNTCVeiculo
			PlacaReboqueVeiculo
			RNTCReboqueVeiculo
			VolumeNfe {
				Quantidade
				Especie
				Marca
				Numeracao
				PesoLiquido
				PesoBruto
			}
			PrestacaoNfe {
				IdFormaPgto
				NomeFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				NumLancamento
				NumDocumento
				DataVencimento
				TipoFormaPgto
				TagPgtoNFe
			}
			VendaNfe {
				Id
			}
			ReferenciaNfe {
				IdEmpresa
				IdNfeRef
				IdEstado
				IdFornecedor
				ChaveAcesso
				CnpjEmitente
				Modelo
				Serie
				NumNf
				DataEmissao
				Tipo
				IndEmitente
				IndOperacao
			}
			ValorIcms
			ValorIcmsSt
			ValorIpi
			ValorFcpSt
			ValorIi
			ObservacaoComplementar
			DataCadastro
			DataAlteracao
		}
	}
`;

/**
 * __useDevolucaoNfeVendaQuery__
 *
 * To run a query within a React component, call `useDevolucaoNfeVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevolucaoNfeVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevolucaoNfeVendaQuery({
 *   variables: {
 *      idNFe: // value for 'idNFe'
 *   },
 * });
 */
export function useDevolucaoNfeVendaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DevolucaoNfeVendaQuery, DevolucaoNfeVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DevolucaoNfeVendaQuery, DevolucaoNfeVendaQueryVariables>(DevolucaoNfeVendaDocument, options);
}
export function useDevolucaoNfeVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevolucaoNfeVendaQuery, DevolucaoNfeVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DevolucaoNfeVendaQuery, DevolucaoNfeVendaQueryVariables>(DevolucaoNfeVendaDocument, options);
}
export type DevolucaoNfeVendaQueryHookResult = ReturnType<typeof useDevolucaoNfeVendaQuery>;
export type DevolucaoNfeVendaLazyQueryHookResult = ReturnType<typeof useDevolucaoNfeVendaLazyQuery>;
export type DevolucaoNfeVendaQueryResult = Apollo.QueryResult<DevolucaoNfeVendaQuery, DevolucaoNfeVendaQueryVariables>;
export const EnviaEmailNfeDocument = gql`
	subscription EnviaEmailNfe($idNFe: Int!) {
		enviaEmailNFe(IdNFe: $idNFe)
	}
`;

/**
 * __useEnviaEmailNfeSubscription__
 *
 * To run a query within a React component, call `useEnviaEmailNfeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEnviaEmailNfeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnviaEmailNfeSubscription({
 *   variables: {
 *      idNFe: // value for 'idNFe'
 *   },
 * });
 */
export function useEnviaEmailNfeSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<EnviaEmailNfeSubscription, EnviaEmailNfeSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<EnviaEmailNfeSubscription, EnviaEmailNfeSubscriptionVariables>(EnviaEmailNfeDocument, options);
}
export type EnviaEmailNfeSubscriptionHookResult = ReturnType<typeof useEnviaEmailNfeSubscription>;
export type EnviaEmailNfeSubscriptionResult = Apollo.SubscriptionResult<EnviaEmailNfeSubscription>;
export const AguardaEventoNfeDocument = gql`
	subscription AguardaEventoNfe($correlationId: String!) {
		aguardaEventoNfe(CorrelationId: $correlationId)
	}
`;

/**
 * __useAguardaEventoNfeSubscription__
 *
 * To run a query within a React component, call `useAguardaEventoNfeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAguardaEventoNfeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAguardaEventoNfeSubscription({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useAguardaEventoNfeSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<AguardaEventoNfeSubscription, AguardaEventoNfeSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<AguardaEventoNfeSubscription, AguardaEventoNfeSubscriptionVariables>(AguardaEventoNfeDocument, options);
}
export type AguardaEventoNfeSubscriptionHookResult = ReturnType<typeof useAguardaEventoNfeSubscription>;
export type AguardaEventoNfeSubscriptionResult = Apollo.SubscriptionResult<AguardaEventoNfeSubscription>;
export const CadastraIntermediadorDocument = gql`
	mutation CadastraIntermediador($dados: InputCadastrarIntermediador!) {
		cadastraIntermediador(Dados: $dados)
	}
`;
export type CadastraIntermediadorMutationFn = Apollo.MutationFunction<CadastraIntermediadorMutation, CadastraIntermediadorMutationVariables>;

/**
 * __useCadastraIntermediadorMutation__
 *
 * To run a mutation, you first call `useCadastraIntermediadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraIntermediadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraIntermediadorMutation, { data, loading, error }] = useCadastraIntermediadorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraIntermediadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraIntermediadorMutation, CadastraIntermediadorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraIntermediadorMutation, CadastraIntermediadorMutationVariables>(CadastraIntermediadorDocument, options);
}
export type CadastraIntermediadorMutationHookResult = ReturnType<typeof useCadastraIntermediadorMutation>;
export type CadastraIntermediadorMutationResult = Apollo.MutationResult<CadastraIntermediadorMutation>;
export type CadastraIntermediadorMutationOptions = Apollo.BaseMutationOptions<CadastraIntermediadorMutation, CadastraIntermediadorMutationVariables>;
export const EditaIntermediadorDocument = gql`
	mutation EditaIntermediador($dados: InputEditarIntermediador!) {
		editaIntermediador(Dados: $dados)
	}
`;
export type EditaIntermediadorMutationFn = Apollo.MutationFunction<EditaIntermediadorMutation, EditaIntermediadorMutationVariables>;

/**
 * __useEditaIntermediadorMutation__
 *
 * To run a mutation, you first call `useEditaIntermediadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaIntermediadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaIntermediadorMutation, { data, loading, error }] = useEditaIntermediadorMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaIntermediadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaIntermediadorMutation, EditaIntermediadorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaIntermediadorMutation, EditaIntermediadorMutationVariables>(EditaIntermediadorDocument, options);
}
export type EditaIntermediadorMutationHookResult = ReturnType<typeof useEditaIntermediadorMutation>;
export type EditaIntermediadorMutationResult = Apollo.MutationResult<EditaIntermediadorMutation>;
export type EditaIntermediadorMutationOptions = Apollo.BaseMutationOptions<EditaIntermediadorMutation, EditaIntermediadorMutationVariables>;
export const ToggleStatusIntermediadorDocument = gql`
	mutation ToggleStatusIntermediador($id: Int!, $status: Status!) {
		editaIntermediador(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusIntermediadorMutationFn = Apollo.MutationFunction<ToggleStatusIntermediadorMutation, ToggleStatusIntermediadorMutationVariables>;

/**
 * __useToggleStatusIntermediadorMutation__
 *
 * To run a mutation, you first call `useToggleStatusIntermediadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusIntermediadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusIntermediadorMutation, { data, loading, error }] = useToggleStatusIntermediadorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusIntermediadorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusIntermediadorMutation, ToggleStatusIntermediadorMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusIntermediadorMutation, ToggleStatusIntermediadorMutationVariables>(ToggleStatusIntermediadorDocument, options);
}
export type ToggleStatusIntermediadorMutationHookResult = ReturnType<typeof useToggleStatusIntermediadorMutation>;
export type ToggleStatusIntermediadorMutationResult = Apollo.MutationResult<ToggleStatusIntermediadorMutation>;
export type ToggleStatusIntermediadorMutationOptions = Apollo.BaseMutationOptions<ToggleStatusIntermediadorMutation, ToggleStatusIntermediadorMutationVariables>;
export const IntermediadoresDocument = gql`
	query Intermediadores($filtro: InputFiltroIntermediadores) {
		intermediadores(Filtro: $filtro) {
			Id
			Nome
			Cnpj
			Identificador
			Status
		}
	}
`;

/**
 * __useIntermediadoresQuery__
 *
 * To run a query within a React component, call `useIntermediadoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntermediadoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntermediadoresQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useIntermediadoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IntermediadoresQuery, IntermediadoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<IntermediadoresQuery, IntermediadoresQueryVariables>(IntermediadoresDocument, options);
}
export function useIntermediadoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntermediadoresQuery, IntermediadoresQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<IntermediadoresQuery, IntermediadoresQueryVariables>(IntermediadoresDocument, options);
}
export type IntermediadoresQueryHookResult = ReturnType<typeof useIntermediadoresQuery>;
export type IntermediadoresLazyQueryHookResult = ReturnType<typeof useIntermediadoresLazyQuery>;
export type IntermediadoresQueryResult = Apollo.QueryResult<IntermediadoresQuery, IntermediadoresQueryVariables>;
export const IntermediadorDocument = gql`
	query Intermediador($Id: Int!) {
		intermediador(Filtro: { Id: $Id }) {
			Id
			Nome
			Cnpj
			Identificador
			Status
		}
	}
`;

/**
 * __useIntermediadorQuery__
 *
 * To run a query within a React component, call `useIntermediadorQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntermediadorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntermediadorQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useIntermediadorQuery(baseOptions: ApolloReactHooks.QueryHookOptions<IntermediadorQuery, IntermediadorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<IntermediadorQuery, IntermediadorQueryVariables>(IntermediadorDocument, options);
}
export function useIntermediadorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntermediadorQuery, IntermediadorQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<IntermediadorQuery, IntermediadorQueryVariables>(IntermediadorDocument, options);
}
export type IntermediadorQueryHookResult = ReturnType<typeof useIntermediadorQuery>;
export type IntermediadorLazyQueryHookResult = ReturnType<typeof useIntermediadorLazyQuery>;
export type IntermediadorQueryResult = Apollo.QueryResult<IntermediadorQuery, IntermediadorQueryVariables>;
export const GerarRegistrosPafDocument = gql`
	subscription GerarRegistrosPaf($filtro: InputFiltroRegistrosPaf!) {
		gerarRegistrosPaf(Filtro: $filtro)
	}
`;

/**
 * __useGerarRegistrosPafSubscription__
 *
 * To run a query within a React component, call `useGerarRegistrosPafSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGerarRegistrosPafSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGerarRegistrosPafSubscription({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useGerarRegistrosPafSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<GerarRegistrosPafSubscription, GerarRegistrosPafSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<GerarRegistrosPafSubscription, GerarRegistrosPafSubscriptionVariables>(GerarRegistrosPafDocument, options);
}
export type GerarRegistrosPafSubscriptionHookResult = ReturnType<typeof useGerarRegistrosPafSubscription>;
export type GerarRegistrosPafSubscriptionResult = Apollo.SubscriptionResult<GerarRegistrosPafSubscription>;
export const CadastraCestDocument = gql`
	mutation CadastraCEST($dados: InputCadastrarCest!) {
		cadastraCest(Dados: $dados)
	}
`;
export type CadastraCestMutationFn = Apollo.MutationFunction<CadastraCestMutation, CadastraCestMutationVariables>;

/**
 * __useCadastraCestMutation__
 *
 * To run a mutation, you first call `useCadastraCestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraCestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraCestMutation, { data, loading, error }] = useCadastraCestMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraCestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraCestMutation, CadastraCestMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraCestMutation, CadastraCestMutationVariables>(CadastraCestDocument, options);
}
export type CadastraCestMutationHookResult = ReturnType<typeof useCadastraCestMutation>;
export type CadastraCestMutationResult = Apollo.MutationResult<CadastraCestMutation>;
export type CadastraCestMutationOptions = Apollo.BaseMutationOptions<CadastraCestMutation, CadastraCestMutationVariables>;
export const EditaCestDocument = gql`
	mutation EditaCEST($dados: InputEditarCest!) {
		editaCest(Dados: $dados)
	}
`;
export type EditaCestMutationFn = Apollo.MutationFunction<EditaCestMutation, EditaCestMutationVariables>;

/**
 * __useEditaCestMutation__
 *
 * To run a mutation, you first call `useEditaCestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaCestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaCestMutation, { data, loading, error }] = useEditaCestMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaCestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaCestMutation, EditaCestMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaCestMutation, EditaCestMutationVariables>(EditaCestDocument, options);
}
export type EditaCestMutationHookResult = ReturnType<typeof useEditaCestMutation>;
export type EditaCestMutationResult = Apollo.MutationResult<EditaCestMutation>;
export type EditaCestMutationOptions = Apollo.BaseMutationOptions<EditaCestMutation, EditaCestMutationVariables>;
export const ToggleStatusCestDocument = gql`
	mutation ToggleStatusCest($id: Int!, $status: Status!) {
		editaCest(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusCestMutationFn = Apollo.MutationFunction<ToggleStatusCestMutation, ToggleStatusCestMutationVariables>;

/**
 * __useToggleStatusCestMutation__
 *
 * To run a mutation, you first call `useToggleStatusCestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusCestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusCestMutation, { data, loading, error }] = useToggleStatusCestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusCestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusCestMutation, ToggleStatusCestMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusCestMutation, ToggleStatusCestMutationVariables>(ToggleStatusCestDocument, options);
}
export type ToggleStatusCestMutationHookResult = ReturnType<typeof useToggleStatusCestMutation>;
export type ToggleStatusCestMutationResult = Apollo.MutationResult<ToggleStatusCestMutation>;
export type ToggleStatusCestMutationOptions = Apollo.BaseMutationOptions<ToggleStatusCestMutation, ToggleStatusCestMutationVariables>;
export const CestsDocument = gql`
	query Cests($paginacao: InputPaginacao, $filtro: InputFiltroCests) {
		cests(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				CodCest
				CodNcm
				Descricao
				Id
				IdOriginal
				Item
				Status
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useCestsQuery__
 *
 * To run a query within a React component, call `useCestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCestsQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CestsQuery, CestsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CestsQuery, CestsQueryVariables>(CestsDocument, options);
}
export function useCestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CestsQuery, CestsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CestsQuery, CestsQueryVariables>(CestsDocument, options);
}
export type CestsQueryHookResult = ReturnType<typeof useCestsQuery>;
export type CestsLazyQueryHookResult = ReturnType<typeof useCestsLazyQuery>;
export type CestsQueryResult = Apollo.QueryResult<CestsQuery, CestsQueryVariables>;
export const CestDocument = gql`
	query Cest($Id: Int!) {
		cest(Filtro: { Id: $Id }) {
			CodCest
			CodNcm
			Descricao
			Id
			IdOriginal
			Item
			Status
		}
	}
`;

/**
 * __useCestQuery__
 *
 * To run a query within a React component, call `useCestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCestQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useCestQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CestQuery, CestQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CestQuery, CestQueryVariables>(CestDocument, options);
}
export function useCestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CestQuery, CestQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CestQuery, CestQueryVariables>(CestDocument, options);
}
export type CestQueryHookResult = ReturnType<typeof useCestQuery>;
export type CestLazyQueryHookResult = ReturnType<typeof useCestLazyQuery>;
export type CestQueryResult = Apollo.QueryResult<CestQuery, CestQueryVariables>;
export const CestByCodigoDocument = gql`
	query CestByCodigo($Codigo: String!) {
		cest(Filtro: { CodCest: $Codigo }) {
			Id
			CodCest
			IdOriginal
		}
	}
`;

/**
 * __useCestByCodigoQuery__
 *
 * To run a query within a React component, call `useCestByCodigoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCestByCodigoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCestByCodigoQuery({
 *   variables: {
 *      Codigo: // value for 'Codigo'
 *   },
 * });
 */
export function useCestByCodigoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CestByCodigoQuery, CestByCodigoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CestByCodigoQuery, CestByCodigoQueryVariables>(CestByCodigoDocument, options);
}
export function useCestByCodigoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CestByCodigoQuery, CestByCodigoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CestByCodigoQuery, CestByCodigoQueryVariables>(CestByCodigoDocument, options);
}
export type CestByCodigoQueryHookResult = ReturnType<typeof useCestByCodigoQuery>;
export type CestByCodigoLazyQueryHookResult = ReturnType<typeof useCestByCodigoLazyQuery>;
export type CestByCodigoQueryResult = Apollo.QueryResult<CestByCodigoQuery, CestByCodigoQueryVariables>;
export const CadastraCfopDocument = gql`
	mutation CadastraCFOP($dados: InputCadastrarCfop!) {
		cadastraCfop(Dados: $dados)
	}
`;
export type CadastraCfopMutationFn = Apollo.MutationFunction<CadastraCfopMutation, CadastraCfopMutationVariables>;

/**
 * __useCadastraCfopMutation__
 *
 * To run a mutation, you first call `useCadastraCfopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraCfopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraCfopMutation, { data, loading, error }] = useCadastraCfopMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraCfopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraCfopMutation, CadastraCfopMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraCfopMutation, CadastraCfopMutationVariables>(CadastraCfopDocument, options);
}
export type CadastraCfopMutationHookResult = ReturnType<typeof useCadastraCfopMutation>;
export type CadastraCfopMutationResult = Apollo.MutationResult<CadastraCfopMutation>;
export type CadastraCfopMutationOptions = Apollo.BaseMutationOptions<CadastraCfopMutation, CadastraCfopMutationVariables>;
export const EditaCfopDocument = gql`
	mutation EditaCFOP($dados: InputEditarCfop!) {
		editaCfop(Dados: $dados)
	}
`;
export type EditaCfopMutationFn = Apollo.MutationFunction<EditaCfopMutation, EditaCfopMutationVariables>;

/**
 * __useEditaCfopMutation__
 *
 * To run a mutation, you first call `useEditaCfopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaCfopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaCfopMutation, { data, loading, error }] = useEditaCfopMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaCfopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaCfopMutation, EditaCfopMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaCfopMutation, EditaCfopMutationVariables>(EditaCfopDocument, options);
}
export type EditaCfopMutationHookResult = ReturnType<typeof useEditaCfopMutation>;
export type EditaCfopMutationResult = Apollo.MutationResult<EditaCfopMutation>;
export type EditaCfopMutationOptions = Apollo.BaseMutationOptions<EditaCfopMutation, EditaCfopMutationVariables>;
export const ToggleStatusCfopDocument = gql`
	mutation ToggleStatusCFOP($id: Int!, $status: Status!) {
		editaCfop(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusCfopMutationFn = Apollo.MutationFunction<ToggleStatusCfopMutation, ToggleStatusCfopMutationVariables>;

/**
 * __useToggleStatusCfopMutation__
 *
 * To run a mutation, you first call `useToggleStatusCfopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusCfopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusCfopMutation, { data, loading, error }] = useToggleStatusCfopMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusCfopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusCfopMutation, ToggleStatusCfopMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusCfopMutation, ToggleStatusCfopMutationVariables>(ToggleStatusCfopDocument, options);
}
export type ToggleStatusCfopMutationHookResult = ReturnType<typeof useToggleStatusCfopMutation>;
export type ToggleStatusCfopMutationResult = Apollo.MutationResult<ToggleStatusCfopMutation>;
export type ToggleStatusCfopMutationOptions = Apollo.BaseMutationOptions<ToggleStatusCfopMutation, ToggleStatusCfopMutationVariables>;
export const CfopsDocument = gql`
	query Cfops($paginacao: InputPaginacao, $filtro: InputFiltroCfops) {
		cfops(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Codigo
				CreditoIcmsCompra
				Descricao
				DescricaoOperacao
				Id
				IdOriginal
				IdOperacao
				PrecoPadrao
				MovimentaEstoque
				MovimentaFinanceiro
				Status
				TipoNFe
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useCfopsQuery__
 *
 * To run a query within a React component, call `useCfopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCfopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCfopsQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCfopsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CfopsQuery, CfopsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CfopsQuery, CfopsQueryVariables>(CfopsDocument, options);
}
export function useCfopsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CfopsQuery, CfopsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CfopsQuery, CfopsQueryVariables>(CfopsDocument, options);
}
export type CfopsQueryHookResult = ReturnType<typeof useCfopsQuery>;
export type CfopsLazyQueryHookResult = ReturnType<typeof useCfopsLazyQuery>;
export type CfopsQueryResult = Apollo.QueryResult<CfopsQuery, CfopsQueryVariables>;
export const CfopDocument = gql`
	query Cfop($filtro: InputFiltroCfop) {
		cfop(Filtro: $filtro) {
			Codigo
			CreditoIcmsCompra
			Descricao
			DescricaoOperacao
			Id
			IdOriginal
			PrecoPadrao
			IdOperacao
			MovimentaEstoque
			MovimentaFinanceiro
			Status
			TipoNFe
		}
	}
`;

/**
 * __useCfopQuery__
 *
 * To run a query within a React component, call `useCfopQuery` and pass it any options that fit your needs.
 * When your component renders, `useCfopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCfopQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCfopQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CfopQuery, CfopQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CfopQuery, CfopQueryVariables>(CfopDocument, options);
}
export function useCfopLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CfopQuery, CfopQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CfopQuery, CfopQueryVariables>(CfopDocument, options);
}
export type CfopQueryHookResult = ReturnType<typeof useCfopQuery>;
export type CfopLazyQueryHookResult = ReturnType<typeof useCfopLazyQuery>;
export type CfopQueryResult = Apollo.QueryResult<CfopQuery, CfopQueryVariables>;
export const CfopOperacoesDocument = gql`
	query CfopOperacoes {
		cfopOperacoes {
			Descricao
			Id
			Status
		}
	}
`;

/**
 * __useCfopOperacoesQuery__
 *
 * To run a query within a React component, call `useCfopOperacoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCfopOperacoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCfopOperacoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCfopOperacoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CfopOperacoesQuery, CfopOperacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CfopOperacoesQuery, CfopOperacoesQueryVariables>(CfopOperacoesDocument, options);
}
export function useCfopOperacoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CfopOperacoesQuery, CfopOperacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CfopOperacoesQuery, CfopOperacoesQueryVariables>(CfopOperacoesDocument, options);
}
export type CfopOperacoesQueryHookResult = ReturnType<typeof useCfopOperacoesQuery>;
export type CfopOperacoesLazyQueryHookResult = ReturnType<typeof useCfopOperacoesLazyQuery>;
export type CfopOperacoesQueryResult = Apollo.QueryResult<CfopOperacoesQuery, CfopOperacoesQueryVariables>;
export const CfopByCodigoDocument = gql`
	query CfopByCodigo($Codigo: String!, $Tipo: [TipoNFe]) {
		cfop(Filtro: { Codigo: $Codigo, TipoNFe: $Tipo }) {
			Id
			Codigo
			IdOriginal
			Descricao
			DescricaoOperacao
			TipoNFe
			MovimentaEstoque
			IdOperacao
			MovimentaFinanceiro
			PrecoPadrao
		}
	}
`;

/**
 * __useCfopByCodigoQuery__
 *
 * To run a query within a React component, call `useCfopByCodigoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCfopByCodigoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCfopByCodigoQuery({
 *   variables: {
 *      Codigo: // value for 'Codigo'
 *      Tipo: // value for 'Tipo'
 *   },
 * });
 */
export function useCfopByCodigoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CfopByCodigoQuery, CfopByCodigoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CfopByCodigoQuery, CfopByCodigoQueryVariables>(CfopByCodigoDocument, options);
}
export function useCfopByCodigoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CfopByCodigoQuery, CfopByCodigoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CfopByCodigoQuery, CfopByCodigoQueryVariables>(CfopByCodigoDocument, options);
}
export type CfopByCodigoQueryHookResult = ReturnType<typeof useCfopByCodigoQuery>;
export type CfopByCodigoLazyQueryHookResult = ReturnType<typeof useCfopByCodigoLazyQuery>;
export type CfopByCodigoQueryResult = Apollo.QueryResult<CfopByCodigoQuery, CfopByCodigoQueryVariables>;
export const CadastraNcmDocument = gql`
	mutation CadastraNCM($dados: InputCadastrarNcm!) {
		cadastraNcm(Dados: $dados)
	}
`;
export type CadastraNcmMutationFn = Apollo.MutationFunction<CadastraNcmMutation, CadastraNcmMutationVariables>;

/**
 * __useCadastraNcmMutation__
 *
 * To run a mutation, you first call `useCadastraNcmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraNcmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraNcmMutation, { data, loading, error }] = useCadastraNcmMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraNcmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraNcmMutation, CadastraNcmMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraNcmMutation, CadastraNcmMutationVariables>(CadastraNcmDocument, options);
}
export type CadastraNcmMutationHookResult = ReturnType<typeof useCadastraNcmMutation>;
export type CadastraNcmMutationResult = Apollo.MutationResult<CadastraNcmMutation>;
export type CadastraNcmMutationOptions = Apollo.BaseMutationOptions<CadastraNcmMutation, CadastraNcmMutationVariables>;
export const EditaNcmDocument = gql`
	mutation EditaNCM($dados: InputEditarNcm!) {
		editaNcm(Dados: $dados)
	}
`;
export type EditaNcmMutationFn = Apollo.MutationFunction<EditaNcmMutation, EditaNcmMutationVariables>;

/**
 * __useEditaNcmMutation__
 *
 * To run a mutation, you first call `useEditaNcmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaNcmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaNcmMutation, { data, loading, error }] = useEditaNcmMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaNcmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaNcmMutation, EditaNcmMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaNcmMutation, EditaNcmMutationVariables>(EditaNcmDocument, options);
}
export type EditaNcmMutationHookResult = ReturnType<typeof useEditaNcmMutation>;
export type EditaNcmMutationResult = Apollo.MutationResult<EditaNcmMutation>;
export type EditaNcmMutationOptions = Apollo.BaseMutationOptions<EditaNcmMutation, EditaNcmMutationVariables>;
export const ToggleStatusNcmDocument = gql`
	mutation ToggleStatusNcm($id: Int!, $status: Status!) {
		editaNcm(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusNcmMutationFn = Apollo.MutationFunction<ToggleStatusNcmMutation, ToggleStatusNcmMutationVariables>;

/**
 * __useToggleStatusNcmMutation__
 *
 * To run a mutation, you first call `useToggleStatusNcmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusNcmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusNcmMutation, { data, loading, error }] = useToggleStatusNcmMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusNcmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusNcmMutation, ToggleStatusNcmMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusNcmMutation, ToggleStatusNcmMutationVariables>(ToggleStatusNcmDocument, options);
}
export type ToggleStatusNcmMutationHookResult = ReturnType<typeof useToggleStatusNcmMutation>;
export type ToggleStatusNcmMutationResult = Apollo.MutationResult<ToggleStatusNcmMutation>;
export type ToggleStatusNcmMutationOptions = Apollo.BaseMutationOptions<ToggleStatusNcmMutation, ToggleStatusNcmMutationVariables>;
export const NcmsDocument = gql`
	query Ncms($paginacao: InputPaginacao, $filtro: InputFiltroNcms) {
		ncms(Paginacao: $paginacao, Filtro: $filtro) {
			Data {
				Id
				Status
				CodNCM
				ExtIpi
				AliqEstadual
				AliqMunicipal
				AliqNacional
				AliqImportacao
				Uf
				Tabela
				Descricao
				IdOriginal
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useNcmsQuery__
 *
 * To run a query within a React component, call `useNcmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNcmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNcmsQuery({
 *   variables: {
 *      paginacao: // value for 'paginacao'
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useNcmsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NcmsQuery, NcmsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NcmsQuery, NcmsQueryVariables>(NcmsDocument, options);
}
export function useNcmsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NcmsQuery, NcmsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NcmsQuery, NcmsQueryVariables>(NcmsDocument, options);
}
export type NcmsQueryHookResult = ReturnType<typeof useNcmsQuery>;
export type NcmsLazyQueryHookResult = ReturnType<typeof useNcmsLazyQuery>;
export type NcmsQueryResult = Apollo.QueryResult<NcmsQuery, NcmsQueryVariables>;
export const NcmDocument = gql`
	query Ncm($Id: Int!, $ExtIpi: String) {
		ncm(Filtro: { Id: $Id, ExtIpi: $ExtIpi }) {
			Id
			IdOriginal
			IdEstado
			IdEmpresa
			CodNCM
			ExtIpi
			AliqEstadual
			AliqMunicipal
			AliqNacional
			AliqImportacao
			Descricao
			DataAlteracao
			Status
			Uf
			Tabela
		}
	}
`;

/**
 * __useNcmQuery__
 *
 * To run a query within a React component, call `useNcmQuery` and pass it any options that fit your needs.
 * When your component renders, `useNcmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNcmQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *      ExtIpi: // value for 'ExtIpi'
 *   },
 * });
 */
export function useNcmQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NcmQuery, NcmQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NcmQuery, NcmQueryVariables>(NcmDocument, options);
}
export function useNcmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NcmQuery, NcmQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NcmQuery, NcmQueryVariables>(NcmDocument, options);
}
export type NcmQueryHookResult = ReturnType<typeof useNcmQuery>;
export type NcmLazyQueryHookResult = ReturnType<typeof useNcmLazyQuery>;
export type NcmQueryResult = Apollo.QueryResult<NcmQuery, NcmQueryVariables>;
export const NcmByCodigoDocument = gql`
	query NcmByCodigo($Codigo: String!) {
		ncm(Filtro: { CodNCM: $Codigo }) {
			Id
			CodNCM
			ExtIpi
			IdOriginal
		}
	}
`;

/**
 * __useNcmByCodigoQuery__
 *
 * To run a query within a React component, call `useNcmByCodigoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNcmByCodigoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNcmByCodigoQuery({
 *   variables: {
 *      Codigo: // value for 'Codigo'
 *   },
 * });
 */
export function useNcmByCodigoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NcmByCodigoQuery, NcmByCodigoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NcmByCodigoQuery, NcmByCodigoQueryVariables>(NcmByCodigoDocument, options);
}
export function useNcmByCodigoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NcmByCodigoQuery, NcmByCodigoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NcmByCodigoQuery, NcmByCodigoQueryVariables>(NcmByCodigoDocument, options);
}
export type NcmByCodigoQueryHookResult = ReturnType<typeof useNcmByCodigoQuery>;
export type NcmByCodigoLazyQueryHookResult = ReturnType<typeof useNcmByCodigoLazyQuery>;
export type NcmByCodigoQueryResult = Apollo.QueryResult<NcmByCodigoQuery, NcmByCodigoQueryVariables>;
export const NcmsByEstadoDocument = gql`
	query NcmsByEstado($filtro: InputFiltroNcms) {
		ncms(Filtro: $filtro) {
			Data {
				Id
				CodNCM
				Descricao
				ExtIpi
				Uf
				IdOriginal
			}
		}
	}
`;

/**
 * __useNcmsByEstadoQuery__
 *
 * To run a query within a React component, call `useNcmsByEstadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNcmsByEstadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNcmsByEstadoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useNcmsByEstadoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NcmsByEstadoQuery, NcmsByEstadoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<NcmsByEstadoQuery, NcmsByEstadoQueryVariables>(NcmsByEstadoDocument, options);
}
export function useNcmsByEstadoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NcmsByEstadoQuery, NcmsByEstadoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<NcmsByEstadoQuery, NcmsByEstadoQueryVariables>(NcmsByEstadoDocument, options);
}
export type NcmsByEstadoQueryHookResult = ReturnType<typeof useNcmsByEstadoQuery>;
export type NcmsByEstadoLazyQueryHookResult = ReturnType<typeof useNcmsByEstadoLazyQuery>;
export type NcmsByEstadoQueryResult = Apollo.QueryResult<NcmsByEstadoQuery, NcmsByEstadoQueryVariables>;
export const CadastraRegraTributacaoDocument = gql`
	mutation CadastraRegraTributacao($dados: InputCadastrarRegraTributacao!) {
		cadastraRegraTributacao(Dados: $dados)
	}
`;
export type CadastraRegraTributacaoMutationFn = Apollo.MutationFunction<CadastraRegraTributacaoMutation, CadastraRegraTributacaoMutationVariables>;

/**
 * __useCadastraRegraTributacaoMutation__
 *
 * To run a mutation, you first call `useCadastraRegraTributacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraRegraTributacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraRegraTributacaoMutation, { data, loading, error }] = useCadastraRegraTributacaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraRegraTributacaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraRegraTributacaoMutation, CadastraRegraTributacaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraRegraTributacaoMutation, CadastraRegraTributacaoMutationVariables>(CadastraRegraTributacaoDocument, options);
}
export type CadastraRegraTributacaoMutationHookResult = ReturnType<typeof useCadastraRegraTributacaoMutation>;
export type CadastraRegraTributacaoMutationResult = Apollo.MutationResult<CadastraRegraTributacaoMutation>;
export type CadastraRegraTributacaoMutationOptions = Apollo.BaseMutationOptions<CadastraRegraTributacaoMutation, CadastraRegraTributacaoMutationVariables>;
export const EditaRegraTributacaoDocument = gql`
	mutation EditaRegraTributacao($dados: InputEditarRegraTributacao!) {
		editaRegraTributacao(Dados: $dados)
	}
`;
export type EditaRegraTributacaoMutationFn = Apollo.MutationFunction<EditaRegraTributacaoMutation, EditaRegraTributacaoMutationVariables>;

/**
 * __useEditaRegraTributacaoMutation__
 *
 * To run a mutation, you first call `useEditaRegraTributacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaRegraTributacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaRegraTributacaoMutation, { data, loading, error }] = useEditaRegraTributacaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaRegraTributacaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaRegraTributacaoMutation, EditaRegraTributacaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaRegraTributacaoMutation, EditaRegraTributacaoMutationVariables>(EditaRegraTributacaoDocument, options);
}
export type EditaRegraTributacaoMutationHookResult = ReturnType<typeof useEditaRegraTributacaoMutation>;
export type EditaRegraTributacaoMutationResult = Apollo.MutationResult<EditaRegraTributacaoMutation>;
export type EditaRegraTributacaoMutationOptions = Apollo.BaseMutationOptions<EditaRegraTributacaoMutation, EditaRegraTributacaoMutationVariables>;
export const ToggleStatusRegraTributacaoDocument = gql`
	mutation ToggleStatusRegraTributacao($id: Int!, $status: Status!) {
		editaRegraTributacao(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusRegraTributacaoMutationFn = Apollo.MutationFunction<ToggleStatusRegraTributacaoMutation, ToggleStatusRegraTributacaoMutationVariables>;

/**
 * __useToggleStatusRegraTributacaoMutation__
 *
 * To run a mutation, you first call `useToggleStatusRegraTributacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusRegraTributacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusRegraTributacaoMutation, { data, loading, error }] = useToggleStatusRegraTributacaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusRegraTributacaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusRegraTributacaoMutation, ToggleStatusRegraTributacaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusRegraTributacaoMutation, ToggleStatusRegraTributacaoMutationVariables>(ToggleStatusRegraTributacaoDocument, options);
}
export type ToggleStatusRegraTributacaoMutationHookResult = ReturnType<typeof useToggleStatusRegraTributacaoMutation>;
export type ToggleStatusRegraTributacaoMutationResult = Apollo.MutationResult<ToggleStatusRegraTributacaoMutation>;
export type ToggleStatusRegraTributacaoMutationOptions = Apollo.BaseMutationOptions<ToggleStatusRegraTributacaoMutation, ToggleStatusRegraTributacaoMutationVariables>;
export const RegrasTributacoesDocument = gql`
	query RegrasTributacoes {
		regrasTributacoes {
			Id
			IdUFCondicao
			UFCondicaoDescricao
			TipoUFCondicao
			IdCfopCondicao
			CfopCondicaoCodigo
			CfopCondicaoDescricao
			RegimeTributarioCondicao
			RegimeTributarioICMSCondicao
			Tipo
			Nome
			TipoConsumidorCondicao
			IdPessoaCondicao
			PessoaCondicaoNome
			PessoaCondicaoNomeFantasia
			IdCfopAcao
			CfopAcaoCodigo
			CfopAcaoDescricao
			IdCestAcao
			CestAcaoCodigo
			CestAcaoDescricao
			IdSituaTributAcao
			SituaTributAcaoCodigo
			SituaTributAcaoDescricao
			DestacaDifalAcao
			AliquotaIcmsAcao
			ReducaoIcmsAcao
			AliquotaMvaAcao
			ReducaoMvaAcao
			AliquotaFcpAcao
			AliquotaPisAcao
			IdCstPisAcao
			CstPisAcaoCodigo
			CstPisAcaoDescricao
			AliquotaCofinsAcao
			IdCstCofinsAcao
			CstCofinsAcaoCodigo
			CstCofinsAcaoDescricao
			AliquotaIpiAcao
			IdCstIpiAcao
			CstIpiAcaoCodigo
			CstIpiAcaoDescricao
			ObservacaoNFAcao
			TipoCadastro
			Status
			Ncms {
				Id
				IdRegraTributacao
				IdNcm
				CodNCM
				ExtIpi
			}
		}
	}
`;

/**
 * __useRegrasTributacoesQuery__
 *
 * To run a query within a React component, call `useRegrasTributacoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegrasTributacoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegrasTributacoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegrasTributacoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegrasTributacoesQuery, RegrasTributacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<RegrasTributacoesQuery, RegrasTributacoesQueryVariables>(RegrasTributacoesDocument, options);
}
export function useRegrasTributacoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegrasTributacoesQuery, RegrasTributacoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<RegrasTributacoesQuery, RegrasTributacoesQueryVariables>(RegrasTributacoesDocument, options);
}
export type RegrasTributacoesQueryHookResult = ReturnType<typeof useRegrasTributacoesQuery>;
export type RegrasTributacoesLazyQueryHookResult = ReturnType<typeof useRegrasTributacoesLazyQuery>;
export type RegrasTributacoesQueryResult = Apollo.QueryResult<RegrasTributacoesQuery, RegrasTributacoesQueryVariables>;
export const RegraTributacaoDocument = gql`
	query RegraTributacao($filtro: InputFiltroRegraTributacao) {
		regraTributacao(Filtro: $filtro) {
			Id
			IdUFCondicao
			UFCondicaoDescricao
			TipoUFCondicao
			IdCfopCondicao
			CfopCondicaoCodigo
			CfopCondicaoDescricao
			RegimeTributarioCondicao
			RegimeTributarioICMSCondicao
			Tipo
			Nome
			TipoConsumidorCondicao
			IdPessoaCondicao
			PessoaCondicaoNome
			PessoaCondicaoNomeFantasia
			IdCfopAcao
			CfopAcaoCodigo
			CfopAcaoDescricao
			IdCestAcao
			CestAcaoCodigo
			CestAcaoDescricao
			IdSituaTributAcao
			SituaTributAcaoCodigo
			SituaTributAcaoDescricao
			AliquotaIcmsAcao
			ReducaoIcmsAcao
			AliquotaMvaAcao
			DestacaDifalAcao
			ReducaoMvaAcao
			AliquotaFcpAcao
			AliquotaPisAcao
			IdCstPisAcao
			CstPisAcaoCodigo
			CstPisAcaoDescricao
			AliquotaCofinsAcao
			IdCstCofinsAcao
			CstCofinsAcaoCodigo
			CstCofinsAcaoDescricao
			AliquotaIpiAcao
			IdCstIpiAcao
			CstIpiAcaoCodigo
			CstIpiAcaoDescricao
			ObservacaoNFAcao
			Status
			Ncms {
				Id
				IdRegraTributacao
				IdNcm
				CodNCM
				ExtIpi
			}
		}
	}
`;

/**
 * __useRegraTributacaoQuery__
 *
 * To run a query within a React component, call `useRegraTributacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegraTributacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegraTributacaoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useRegraTributacaoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegraTributacaoQuery, RegraTributacaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<RegraTributacaoQuery, RegraTributacaoQueryVariables>(RegraTributacaoDocument, options);
}
export function useRegraTributacaoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegraTributacaoQuery, RegraTributacaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<RegraTributacaoQuery, RegraTributacaoQueryVariables>(RegraTributacaoDocument, options);
}
export type RegraTributacaoQueryHookResult = ReturnType<typeof useRegraTributacaoQuery>;
export type RegraTributacaoLazyQueryHookResult = ReturnType<typeof useRegraTributacaoLazyQuery>;
export type RegraTributacaoQueryResult = Apollo.QueryResult<RegraTributacaoQuery, RegraTributacaoQueryVariables>;
export const RegraTributacaoByIdDocument = gql`
	query RegraTributacaoById($Id: Int!) {
		regraTributacao(Filtro: { Id: $Id }) {
			Id
			IdUFCondicao
			TipoUFCondicao
			IdCfopCondicao
			RegimeTributarioCondicao
			RegimeTributarioICMSCondicao
			TipoConsumidorCondicao
			IdPessoaCondicao
			IdCfopAcao
			IdCestAcao
			IdSituaTributAcao
			AliquotaIcmsAcao
			ReducaoIcmsAcao
			AliquotaMvaAcao
			ReducaoMvaAcao
			AliquotaFcpAcao
			AliquotaPisAcao
			IdCstPisAcao
			AliquotaCofinsAcao
			IdCstCofinsAcao
			AliquotaIpiAcao
			IdCstIpiAcao
			ObservacaoNFAcao
			Status
			Ncms {
				Id
				IdRegraTributacao
				IdNcm
				CodNCM
				ExtIpi
			}
		}
	}
`;

/**
 * __useRegraTributacaoByIdQuery__
 *
 * To run a query within a React component, call `useRegraTributacaoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegraTributacaoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegraTributacaoByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useRegraTributacaoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RegraTributacaoByIdQuery, RegraTributacaoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<RegraTributacaoByIdQuery, RegraTributacaoByIdQueryVariables>(RegraTributacaoByIdDocument, options);
}
export function useRegraTributacaoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegraTributacaoByIdQuery, RegraTributacaoByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<RegraTributacaoByIdQuery, RegraTributacaoByIdQueryVariables>(RegraTributacaoByIdDocument, options);
}
export type RegraTributacaoByIdQueryHookResult = ReturnType<typeof useRegraTributacaoByIdQuery>;
export type RegraTributacaoByIdLazyQueryHookResult = ReturnType<typeof useRegraTributacaoByIdLazyQuery>;
export type RegraTributacaoByIdQueryResult = Apollo.QueryResult<RegraTributacaoByIdQuery, RegraTributacaoByIdQueryVariables>;
export const LoginDocument = gql`
	mutation Login($login: String!, $senha: String!, $correlationId: String) {
		login(Login: $login, Senha: $senha, CorrelationId: $correlationId)
	}
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      senha: // value for 'senha'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const EnviarEmailDocument = gql`
	mutation EnviarEmail($dados: InputEsqueciSenha!) {
		esqueciSenha(Dados: $dados)
	}
`;
export type EnviarEmailMutationFn = Apollo.MutationFunction<EnviarEmailMutation, EnviarEmailMutationVariables>;

/**
 * __useEnviarEmailMutation__
 *
 * To run a mutation, you first call `useEnviarEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnviarEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enviarEmailMutation, { data, loading, error }] = useEnviarEmailMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEnviarEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnviarEmailMutation, EnviarEmailMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EnviarEmailMutation, EnviarEmailMutationVariables>(EnviarEmailDocument, options);
}
export type EnviarEmailMutationHookResult = ReturnType<typeof useEnviarEmailMutation>;
export type EnviarEmailMutationResult = Apollo.MutationResult<EnviarEmailMutation>;
export type EnviarEmailMutationOptions = Apollo.BaseMutationOptions<EnviarEmailMutation, EnviarEmailMutationVariables>;
export const AlterarSenhaDocument = gql`
	mutation AlterarSenha($dados: InputAlterarSenha!) {
		alterarSenha(Dados: $dados)
	}
`;
export type AlterarSenhaMutationFn = Apollo.MutationFunction<AlterarSenhaMutation, AlterarSenhaMutationVariables>;

/**
 * __useAlterarSenhaMutation__
 *
 * To run a mutation, you first call `useAlterarSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarSenhaMutation, { data, loading, error }] = useAlterarSenhaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useAlterarSenhaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AlterarSenhaMutation, AlterarSenhaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<AlterarSenhaMutation, AlterarSenhaMutationVariables>(AlterarSenhaDocument, options);
}
export type AlterarSenhaMutationHookResult = ReturnType<typeof useAlterarSenhaMutation>;
export type AlterarSenhaMutationResult = Apollo.MutationResult<AlterarSenhaMutation>;
export type AlterarSenhaMutationOptions = Apollo.BaseMutationOptions<AlterarSenhaMutation, AlterarSenhaMutationVariables>;
export const EditarConfiguracoesDocument = gql`
	mutation EditarConfiguracoes($dados: [InputCadastrarConfiguracaoEmpresa!]!) {
		atualizaConfiguracoesEmpresa(Dados: $dados)
	}
`;
export type EditarConfiguracoesMutationFn = Apollo.MutationFunction<EditarConfiguracoesMutation, EditarConfiguracoesMutationVariables>;

/**
 * __useEditarConfiguracoesMutation__
 *
 * To run a mutation, you first call `useEditarConfiguracoesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarConfiguracoesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarConfiguracoesMutation, { data, loading, error }] = useEditarConfiguracoesMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditarConfiguracoesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditarConfiguracoesMutation, EditarConfiguracoesMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditarConfiguracoesMutation, EditarConfiguracoesMutationVariables>(EditarConfiguracoesDocument, options);
}
export type EditarConfiguracoesMutationHookResult = ReturnType<typeof useEditarConfiguracoesMutation>;
export type EditarConfiguracoesMutationResult = Apollo.MutationResult<EditarConfiguracoesMutation>;
export type EditarConfiguracoesMutationOptions = Apollo.BaseMutationOptions<EditarConfiguracoesMutation, EditarConfiguracoesMutationVariables>;
export const EditarEmpresaDocument = gql`
	mutation EditarEmpresa($dados: InputEditarEmpresa!) {
		editaEmpresa(Dados: $dados)
	}
`;
export type EditarEmpresaMutationFn = Apollo.MutationFunction<EditarEmpresaMutation, EditarEmpresaMutationVariables>;

/**
 * __useEditarEmpresaMutation__
 *
 * To run a mutation, you first call `useEditarEmpresaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarEmpresaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarEmpresaMutation, { data, loading, error }] = useEditarEmpresaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditarEmpresaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditarEmpresaMutation, EditarEmpresaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditarEmpresaMutation, EditarEmpresaMutationVariables>(EditarEmpresaDocument, options);
}
export type EditarEmpresaMutationHookResult = ReturnType<typeof useEditarEmpresaMutation>;
export type EditarEmpresaMutationResult = Apollo.MutationResult<EditarEmpresaMutation>;
export type EditarEmpresaMutationOptions = Apollo.BaseMutationOptions<EditarEmpresaMutation, EditarEmpresaMutationVariables>;
export const EnviarCertificadoDocument = gql`
	mutation EnviarCertificado($dados: InputEnviaCertificado!) {
		enviarCertificado(Dados: $dados)
	}
`;
export type EnviarCertificadoMutationFn = Apollo.MutationFunction<EnviarCertificadoMutation, EnviarCertificadoMutationVariables>;

/**
 * __useEnviarCertificadoMutation__
 *
 * To run a mutation, you first call `useEnviarCertificadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnviarCertificadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enviarCertificadoMutation, { data, loading, error }] = useEnviarCertificadoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEnviarCertificadoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnviarCertificadoMutation, EnviarCertificadoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EnviarCertificadoMutation, EnviarCertificadoMutationVariables>(EnviarCertificadoDocument, options);
}
export type EnviarCertificadoMutationHookResult = ReturnType<typeof useEnviarCertificadoMutation>;
export type EnviarCertificadoMutationResult = Apollo.MutationResult<EnviarCertificadoMutation>;
export type EnviarCertificadoMutationOptions = Apollo.BaseMutationOptions<EnviarCertificadoMutation, EnviarCertificadoMutationVariables>;
export const EnviarCsvDocument = gql`
	mutation EnviarCsv($dados: InputEnviaCsv!) {
		enviarCsv(Dados: $dados)
	}
`;
export type EnviarCsvMutationFn = Apollo.MutationFunction<EnviarCsvMutation, EnviarCsvMutationVariables>;

/**
 * __useEnviarCsvMutation__
 *
 * To run a mutation, you first call `useEnviarCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnviarCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enviarCsvMutation, { data, loading, error }] = useEnviarCsvMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEnviarCsvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnviarCsvMutation, EnviarCsvMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EnviarCsvMutation, EnviarCsvMutationVariables>(EnviarCsvDocument, options);
}
export type EnviarCsvMutationHookResult = ReturnType<typeof useEnviarCsvMutation>;
export type EnviarCsvMutationResult = Apollo.MutationResult<EnviarCsvMutation>;
export type EnviarCsvMutationOptions = Apollo.BaseMutationOptions<EnviarCsvMutation, EnviarCsvMutationVariables>;
export const ExcluirCertificadoDocument = gql`
	mutation ExcluirCertificado {
		excluirCertificado
	}
`;
export type ExcluirCertificadoMutationFn = Apollo.MutationFunction<ExcluirCertificadoMutation, ExcluirCertificadoMutationVariables>;

/**
 * __useExcluirCertificadoMutation__
 *
 * To run a mutation, you first call `useExcluirCertificadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirCertificadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirCertificadoMutation, { data, loading, error }] = useExcluirCertificadoMutation({
 *   variables: {
 *   },
 * });
 */
export function useExcluirCertificadoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExcluirCertificadoMutation, ExcluirCertificadoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ExcluirCertificadoMutation, ExcluirCertificadoMutationVariables>(ExcluirCertificadoDocument, options);
}
export type ExcluirCertificadoMutationHookResult = ReturnType<typeof useExcluirCertificadoMutation>;
export type ExcluirCertificadoMutationResult = Apollo.MutationResult<ExcluirCertificadoMutation>;
export type ExcluirCertificadoMutationOptions = Apollo.BaseMutationOptions<ExcluirCertificadoMutation, ExcluirCertificadoMutationVariables>;
export const CadastraComissoesFormaPgtoDocument = gql`
	mutation CadastraComissoesFormaPgto($dados: InputCadastrarComissaoFormaPgto!) {
		cadastraComissoesFormaPgto(Dados: $dados)
	}
`;
export type CadastraComissoesFormaPgtoMutationFn = Apollo.MutationFunction<CadastraComissoesFormaPgtoMutation, CadastraComissoesFormaPgtoMutationVariables>;

/**
 * __useCadastraComissoesFormaPgtoMutation__
 *
 * To run a mutation, you first call `useCadastraComissoesFormaPgtoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraComissoesFormaPgtoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraComissoesFormaPgtoMutation, { data, loading, error }] = useCadastraComissoesFormaPgtoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraComissoesFormaPgtoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraComissoesFormaPgtoMutation, CadastraComissoesFormaPgtoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraComissoesFormaPgtoMutation, CadastraComissoesFormaPgtoMutationVariables>(CadastraComissoesFormaPgtoDocument, options);
}
export type CadastraComissoesFormaPgtoMutationHookResult = ReturnType<typeof useCadastraComissoesFormaPgtoMutation>;
export type CadastraComissoesFormaPgtoMutationResult = Apollo.MutationResult<CadastraComissoesFormaPgtoMutation>;
export type CadastraComissoesFormaPgtoMutationOptions = Apollo.BaseMutationOptions<CadastraComissoesFormaPgtoMutation, CadastraComissoesFormaPgtoMutationVariables>;
export const EditaComissoesFormaPgtoDocument = gql`
	mutation EditaComissoesFormaPgto($dados: InputEditarComissaoFormaPgto!) {
		editaComissoesFormaPgto(Dados: $dados)
	}
`;
export type EditaComissoesFormaPgtoMutationFn = Apollo.MutationFunction<EditaComissoesFormaPgtoMutation, EditaComissoesFormaPgtoMutationVariables>;

/**
 * __useEditaComissoesFormaPgtoMutation__
 *
 * To run a mutation, you first call `useEditaComissoesFormaPgtoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaComissoesFormaPgtoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaComissoesFormaPgtoMutation, { data, loading, error }] = useEditaComissoesFormaPgtoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaComissoesFormaPgtoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaComissoesFormaPgtoMutation, EditaComissoesFormaPgtoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaComissoesFormaPgtoMutation, EditaComissoesFormaPgtoMutationVariables>(EditaComissoesFormaPgtoDocument, options);
}
export type EditaComissoesFormaPgtoMutationHookResult = ReturnType<typeof useEditaComissoesFormaPgtoMutation>;
export type EditaComissoesFormaPgtoMutationResult = Apollo.MutationResult<EditaComissoesFormaPgtoMutation>;
export type EditaComissoesFormaPgtoMutationOptions = Apollo.BaseMutationOptions<EditaComissoesFormaPgtoMutation, EditaComissoesFormaPgtoMutationVariables>;
export const ComissoesFormaPgtoDocument = gql`
	query ComissoesFormaPgto($filtro: InputFiltroComissoesFormaPgto) {
		comissoesFormaPgto(Filtro: $filtro) {
			Id
			TipoFormaPgto
			IdColaborador
			IdFormaPgto
			Valor
			Status
		}
	}
`;

/**
 * __useComissoesFormaPgtoQuery__
 *
 * To run a query within a React component, call `useComissoesFormaPgtoQuery` and pass it any options that fit your needs.
 * When your component renders, `useComissoesFormaPgtoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComissoesFormaPgtoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useComissoesFormaPgtoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComissoesFormaPgtoQuery, ComissoesFormaPgtoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ComissoesFormaPgtoQuery, ComissoesFormaPgtoQueryVariables>(ComissoesFormaPgtoDocument, options);
}
export function useComissoesFormaPgtoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComissoesFormaPgtoQuery, ComissoesFormaPgtoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ComissoesFormaPgtoQuery, ComissoesFormaPgtoQueryVariables>(ComissoesFormaPgtoDocument, options);
}
export type ComissoesFormaPgtoQueryHookResult = ReturnType<typeof useComissoesFormaPgtoQuery>;
export type ComissoesFormaPgtoLazyQueryHookResult = ReturnType<typeof useComissoesFormaPgtoLazyQuery>;
export type ComissoesFormaPgtoQueryResult = Apollo.QueryResult<ComissoesFormaPgtoQuery, ComissoesFormaPgtoQueryVariables>;
export const CadastraPdvDocument = gql`
	mutation CadastraPdv($dados: InputCadastrarPdv!) {
		cadastraPdv(Dados: $dados)
	}
`;
export type CadastraPdvMutationFn = Apollo.MutationFunction<CadastraPdvMutation, CadastraPdvMutationVariables>;

/**
 * __useCadastraPdvMutation__
 *
 * To run a mutation, you first call `useCadastraPdvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraPdvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraPdvMutation, { data, loading, error }] = useCadastraPdvMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraPdvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraPdvMutation, CadastraPdvMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraPdvMutation, CadastraPdvMutationVariables>(CadastraPdvDocument, options);
}
export type CadastraPdvMutationHookResult = ReturnType<typeof useCadastraPdvMutation>;
export type CadastraPdvMutationResult = Apollo.MutationResult<CadastraPdvMutation>;
export type CadastraPdvMutationOptions = Apollo.BaseMutationOptions<CadastraPdvMutation, CadastraPdvMutationVariables>;
export const EditaPdvDocument = gql`
	mutation EditaPdv($dados: InputEditarPdv!) {
		editaPdv(Dados: $dados)
	}
`;
export type EditaPdvMutationFn = Apollo.MutationFunction<EditaPdvMutation, EditaPdvMutationVariables>;

/**
 * __useEditaPdvMutation__
 *
 * To run a mutation, you first call `useEditaPdvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaPdvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaPdvMutation, { data, loading, error }] = useEditaPdvMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaPdvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaPdvMutation, EditaPdvMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaPdvMutation, EditaPdvMutationVariables>(EditaPdvDocument, options);
}
export type EditaPdvMutationHookResult = ReturnType<typeof useEditaPdvMutation>;
export type EditaPdvMutationResult = Apollo.MutationResult<EditaPdvMutation>;
export type EditaPdvMutationOptions = Apollo.BaseMutationOptions<EditaPdvMutation, EditaPdvMutationVariables>;
export const ToggleStatusPdvDocument = gql`
	mutation ToggleStatusPdv($id: Int!, $status: Status!) {
		editaPdv(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusPdvMutationFn = Apollo.MutationFunction<ToggleStatusPdvMutation, ToggleStatusPdvMutationVariables>;

/**
 * __useToggleStatusPdvMutation__
 *
 * To run a mutation, you first call `useToggleStatusPdvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusPdvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusPdvMutation, { data, loading, error }] = useToggleStatusPdvMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusPdvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusPdvMutation, ToggleStatusPdvMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusPdvMutation, ToggleStatusPdvMutationVariables>(ToggleStatusPdvDocument, options);
}
export type ToggleStatusPdvMutationHookResult = ReturnType<typeof useToggleStatusPdvMutation>;
export type ToggleStatusPdvMutationResult = Apollo.MutationResult<ToggleStatusPdvMutation>;
export type ToggleStatusPdvMutationOptions = Apollo.BaseMutationOptions<ToggleStatusPdvMutation, ToggleStatusPdvMutationVariables>;
export const ResetaDadosDemonstrativosDocument = gql`
	mutation ResetaDadosDemonstrativos($dados: InputResetarDadosEmpresa!) {
		resetaDadosDemonstrativos(Dados: $dados)
	}
`;
export type ResetaDadosDemonstrativosMutationFn = Apollo.MutationFunction<ResetaDadosDemonstrativosMutation, ResetaDadosDemonstrativosMutationVariables>;

/**
 * __useResetaDadosDemonstrativosMutation__
 *
 * To run a mutation, you first call `useResetaDadosDemonstrativosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetaDadosDemonstrativosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetaDadosDemonstrativosMutation, { data, loading, error }] = useResetaDadosDemonstrativosMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useResetaDadosDemonstrativosMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetaDadosDemonstrativosMutation, ResetaDadosDemonstrativosMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ResetaDadosDemonstrativosMutation, ResetaDadosDemonstrativosMutationVariables>(ResetaDadosDemonstrativosDocument, options);
}
export type ResetaDadosDemonstrativosMutationHookResult = ReturnType<typeof useResetaDadosDemonstrativosMutation>;
export type ResetaDadosDemonstrativosMutationResult = Apollo.MutationResult<ResetaDadosDemonstrativosMutation>;
export type ResetaDadosDemonstrativosMutationOptions = Apollo.BaseMutationOptions<ResetaDadosDemonstrativosMutation, ResetaDadosDemonstrativosMutationVariables>;
export const EmpresaDocument = gql`
	query Empresa($skipCidade: Boolean! = true) {
		empresa {
			Id
			CpfCnpj
			Nome
			NomeFantasia
			NomeFilial
			Responsavel
			CpfCnpj
			InscricaoEstadual
			InscricaoMunicipal
			Cep
			IdEstado
			IdCidade
			DataCadastro
			Logradouro
			Bairro
			NumEndereco
			Complemento
			Telefone
			Celular
			Celular2
			EmailPrincipal
			EmailAdministrativo
			EmailFinanceiro
			RegimeTributario
			RegimeTributarioICMS
			Observacao
			LogoEmpresa
			PossuiFilial
			ProdutoEmpresa
			PlanoContratado
			CidadeNome @skip(if: $skipCidade)
			Uf @skip(if: $skipCidade)
			Configuracoes {
				Id
				Valor
				IdConfig
				Nome
				NomeLegivel
			}
		}
	}
`;

/**
 * __useEmpresaQuery__
 *
 * To run a query within a React component, call `useEmpresaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmpresaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmpresaQuery({
 *   variables: {
 *      skipCidade: // value for 'skipCidade'
 *   },
 * });
 */
export function useEmpresaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmpresaQuery, EmpresaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<EmpresaQuery, EmpresaQueryVariables>(EmpresaDocument, options);
}
export function useEmpresaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmpresaQuery, EmpresaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<EmpresaQuery, EmpresaQueryVariables>(EmpresaDocument, options);
}
export type EmpresaQueryHookResult = ReturnType<typeof useEmpresaQuery>;
export type EmpresaLazyQueryHookResult = ReturnType<typeof useEmpresaLazyQuery>;
export type EmpresaQueryResult = Apollo.QueryResult<EmpresaQuery, EmpresaQueryVariables>;
export const DataEmpresaRelatorioDocument = gql`
	query DataEmpresaRelatorio {
		empresa {
			Id
			Nome
			NomeFantasia
			CpfCnpj
			LogoEmpresa
			CidadeNome
			Uf
			Logradouro
			Bairro
			Cep
			NumEndereco
			Complemento
			Celular
			Telefone
			EmailPrincipal
		}
	}
`;

/**
 * __useDataEmpresaRelatorioQuery__
 *
 * To run a query within a React component, call `useDataEmpresaRelatorioQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataEmpresaRelatorioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataEmpresaRelatorioQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataEmpresaRelatorioQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataEmpresaRelatorioQuery, DataEmpresaRelatorioQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DataEmpresaRelatorioQuery, DataEmpresaRelatorioQueryVariables>(DataEmpresaRelatorioDocument, options);
}
export function useDataEmpresaRelatorioLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataEmpresaRelatorioQuery, DataEmpresaRelatorioQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DataEmpresaRelatorioQuery, DataEmpresaRelatorioQueryVariables>(DataEmpresaRelatorioDocument, options);
}
export type DataEmpresaRelatorioQueryHookResult = ReturnType<typeof useDataEmpresaRelatorioQuery>;
export type DataEmpresaRelatorioLazyQueryHookResult = ReturnType<typeof useDataEmpresaRelatorioLazyQuery>;
export type DataEmpresaRelatorioQueryResult = Apollo.QueryResult<DataEmpresaRelatorioQuery, DataEmpresaRelatorioQueryVariables>;
export const UsuarioAtualDocument = gql`
	query UsuarioAtual {
		usuarioAtual {
			IdPessoa
			IdEmpresa
			Nome
			NomeFantasia
			PerfilAcesso
			Permissoes {
				Id
				NomePermissao
				OpcoesPermissaoLista
			}
		}
	}
`;

/**
 * __useUsuarioAtualQuery__
 *
 * To run a query within a React component, call `useUsuarioAtualQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsuarioAtualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsuarioAtualQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsuarioAtualQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsuarioAtualQuery, UsuarioAtualQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<UsuarioAtualQuery, UsuarioAtualQueryVariables>(UsuarioAtualDocument, options);
}
export function useUsuarioAtualLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsuarioAtualQuery, UsuarioAtualQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<UsuarioAtualQuery, UsuarioAtualQueryVariables>(UsuarioAtualDocument, options);
}
export type UsuarioAtualQueryHookResult = ReturnType<typeof useUsuarioAtualQuery>;
export type UsuarioAtualLazyQueryHookResult = ReturnType<typeof useUsuarioAtualLazyQuery>;
export type UsuarioAtualQueryResult = Apollo.QueryResult<UsuarioAtualQuery, UsuarioAtualQueryVariables>;
export const CepDocument = gql`
	query Cep($filtro: InputFiltroCep!) {
		cep(Filtro: $filtro) {
			Bairro
			Cidade
			Complemento
			IdCidade
			IdEstado
			Uf
			Logradouro
		}
	}
`;

/**
 * __useCepQuery__
 *
 * To run a query within a React component, call `useCepQuery` and pass it any options that fit your needs.
 * When your component renders, `useCepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCepQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCepQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CepQuery, CepQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CepQuery, CepQueryVariables>(CepDocument, options);
}
export function useCepLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CepQuery, CepQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CepQuery, CepQueryVariables>(CepDocument, options);
}
export type CepQueryHookResult = ReturnType<typeof useCepQuery>;
export type CepLazyQueryHookResult = ReturnType<typeof useCepLazyQuery>;
export type CepQueryResult = Apollo.QueryResult<CepQuery, CepQueryVariables>;
export const RefreshTokenDocument = gql`
	query RefreshToken {
		refreshToken
	}
`;

/**
 * __useRefreshTokenQuery__
 *
 * To run a query within a React component, call `useRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
}
export function useRefreshTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
}
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const PaisesDocument = gql`
	query Paises {
		paises {
			Id
			Nome
		}
	}
`;

/**
 * __usePaisesQuery__
 *
 * To run a query within a React component, call `usePaisesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaisesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaisesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaisesQuery, PaisesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PaisesQuery, PaisesQueryVariables>(PaisesDocument, options);
}
export function usePaisesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaisesQuery, PaisesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PaisesQuery, PaisesQueryVariables>(PaisesDocument, options);
}
export type PaisesQueryHookResult = ReturnType<typeof usePaisesQuery>;
export type PaisesLazyQueryHookResult = ReturnType<typeof usePaisesLazyQuery>;
export type PaisesQueryResult = Apollo.QueryResult<PaisesQuery, PaisesQueryVariables>;
export const EstadosDocument = gql`
	query Estados($filtro: InputFiltroEstado) {
		estados(Filtro: $filtro) {
			Id
			Nome
			UF
			CodUfIBGE
		}
	}
`;

/**
 * __useEstadosQuery__
 *
 * To run a query within a React component, call `useEstadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstadosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useEstadosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EstadosQuery, EstadosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<EstadosQuery, EstadosQueryVariables>(EstadosDocument, options);
}
export function useEstadosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EstadosQuery, EstadosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<EstadosQuery, EstadosQueryVariables>(EstadosDocument, options);
}
export type EstadosQueryHookResult = ReturnType<typeof useEstadosQuery>;
export type EstadosLazyQueryHookResult = ReturnType<typeof useEstadosLazyQuery>;
export type EstadosQueryResult = Apollo.QueryResult<EstadosQuery, EstadosQueryVariables>;
export const CidadesByIdEstadoDocument = gql`
	query CidadesByIdEstado($filtro: InputFiltroCidades) {
		cidades(Filtro: $filtro) {
			Id
			Nome
		}
	}
`;

/**
 * __useCidadesByIdEstadoQuery__
 *
 * To run a query within a React component, call `useCidadesByIdEstadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadesByIdEstadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadesByIdEstadoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCidadesByIdEstadoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CidadesByIdEstadoQuery, CidadesByIdEstadoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CidadesByIdEstadoQuery, CidadesByIdEstadoQueryVariables>(CidadesByIdEstadoDocument, options);
}
export function useCidadesByIdEstadoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CidadesByIdEstadoQuery, CidadesByIdEstadoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CidadesByIdEstadoQuery, CidadesByIdEstadoQueryVariables>(CidadesByIdEstadoDocument, options);
}
export type CidadesByIdEstadoQueryHookResult = ReturnType<typeof useCidadesByIdEstadoQuery>;
export type CidadesByIdEstadoLazyQueryHookResult = ReturnType<typeof useCidadesByIdEstadoLazyQuery>;
export type CidadesByIdEstadoQueryResult = Apollo.QueryResult<CidadesByIdEstadoQuery, CidadesByIdEstadoQueryVariables>;
export const CidadesByCodIbgeDocument = gql`
	query CidadesByCodIbge($IdIbge: String!) {
		cidades(Filtro: { CodIBGE: $IdIbge }) {
			Id
			Nome
		}
	}
`;

/**
 * __useCidadesByCodIbgeQuery__
 *
 * To run a query within a React component, call `useCidadesByCodIbgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadesByCodIbgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadesByCodIbgeQuery({
 *   variables: {
 *      IdIbge: // value for 'IdIbge'
 *   },
 * });
 */
export function useCidadesByCodIbgeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CidadesByCodIbgeQuery, CidadesByCodIbgeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CidadesByCodIbgeQuery, CidadesByCodIbgeQueryVariables>(CidadesByCodIbgeDocument, options);
}
export function useCidadesByCodIbgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CidadesByCodIbgeQuery, CidadesByCodIbgeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CidadesByCodIbgeQuery, CidadesByCodIbgeQueryVariables>(CidadesByCodIbgeDocument, options);
}
export type CidadesByCodIbgeQueryHookResult = ReturnType<typeof useCidadesByCodIbgeQuery>;
export type CidadesByCodIbgeLazyQueryHookResult = ReturnType<typeof useCidadesByCodIbgeLazyQuery>;
export type CidadesByCodIbgeQueryResult = Apollo.QueryResult<CidadesByCodIbgeQuery, CidadesByCodIbgeQueryVariables>;
export const BuscarEmpresaDocument = gql`
	query BuscarEmpresa($filtro: InputFiltroEmpresa) {
		buscarEmpresa(Filtro: $filtro) {
			Id
			Nome
			NomeFantasia
			CpfCnpj
			Logradouro
			Bairro
			Complemento
			NumEndereco
			Telefone
			Celular
			Celular2
			DataCadastro
			CidadeNome
			Uf
		}
	}
`;

/**
 * __useBuscarEmpresaQuery__
 *
 * To run a query within a React component, call `useBuscarEmpresaQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscarEmpresaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscarEmpresaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useBuscarEmpresaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BuscarEmpresaQuery, BuscarEmpresaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<BuscarEmpresaQuery, BuscarEmpresaQueryVariables>(BuscarEmpresaDocument, options);
}
export function useBuscarEmpresaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BuscarEmpresaQuery, BuscarEmpresaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<BuscarEmpresaQuery, BuscarEmpresaQueryVariables>(BuscarEmpresaDocument, options);
}
export type BuscarEmpresaQueryHookResult = ReturnType<typeof useBuscarEmpresaQuery>;
export type BuscarEmpresaLazyQueryHookResult = ReturnType<typeof useBuscarEmpresaLazyQuery>;
export type BuscarEmpresaQueryResult = Apollo.QueryResult<BuscarEmpresaQuery, BuscarEmpresaQueryVariables>;
export const PdvsDocument = gql`
	query Pdvs($filtro: InputFiltroPdvs) {
		pdvs(Filtro: $filtro) {
			Id
			IdEmpresa
			IdContaFinanceira
			Nome
			Status
			NomeContaFinanceira
		}
	}
`;

/**
 * __usePdvsQuery__
 *
 * To run a query within a React component, call `usePdvsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdvsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdvsQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePdvsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PdvsQuery, PdvsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PdvsQuery, PdvsQueryVariables>(PdvsDocument, options);
}
export function usePdvsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PdvsQuery, PdvsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PdvsQuery, PdvsQueryVariables>(PdvsDocument, options);
}
export type PdvsQueryHookResult = ReturnType<typeof usePdvsQuery>;
export type PdvsLazyQueryHookResult = ReturnType<typeof usePdvsLazyQuery>;
export type PdvsQueryResult = Apollo.QueryResult<PdvsQuery, PdvsQueryVariables>;
export const PdvDocument = gql`
	query Pdv($filtro: InputFiltroPdv) {
		pdv(Filtro: $filtro) {
			Id
			IdEmpresa
			IdContaFinanceira
			Nome
			Status
			NomeContaFinanceira
		}
	}
`;

/**
 * __usePdvQuery__
 *
 * To run a query within a React component, call `usePdvQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdvQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePdvQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PdvQuery, PdvQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PdvQuery, PdvQueryVariables>(PdvDocument, options);
}
export function usePdvLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PdvQuery, PdvQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PdvQuery, PdvQueryVariables>(PdvDocument, options);
}
export type PdvQueryHookResult = ReturnType<typeof usePdvQuery>;
export type PdvLazyQueryHookResult = ReturnType<typeof usePdvLazyQuery>;
export type PdvQueryResult = Apollo.QueryResult<PdvQuery, PdvQueryVariables>;
export const PdvByIdDocument = gql`
	query PdvById($Id: Int!) {
		pdv(Filtro: { Id: $Id }) {
			Id
			IdEmpresa
			IdContaFinanceira
			Nome
			Status
			NomeContaFinanceira
		}
	}
`;

/**
 * __usePdvByIdQuery__
 *
 * To run a query within a React component, call `usePdvByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdvByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdvByIdQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function usePdvByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PdvByIdQuery, PdvByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PdvByIdQuery, PdvByIdQueryVariables>(PdvByIdDocument, options);
}
export function usePdvByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PdvByIdQuery, PdvByIdQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PdvByIdQuery, PdvByIdQueryVariables>(PdvByIdDocument, options);
}
export type PdvByIdQueryHookResult = ReturnType<typeof usePdvByIdQuery>;
export type PdvByIdLazyQueryHookResult = ReturnType<typeof usePdvByIdLazyQuery>;
export type PdvByIdQueryResult = Apollo.QueryResult<PdvByIdQuery, PdvByIdQueryVariables>;
export const PermissaoColaboradorAtualizadaDocument = gql`
	subscription PermissaoColaboradorAtualizada {
		permissaoColaboradorAtualizada {
			Id
			NomePermissao
			OpcoesPermissaoLista
		}
	}
`;

/**
 * __usePermissaoColaboradorAtualizadaSubscription__
 *
 * To run a query within a React component, call `usePermissaoColaboradorAtualizadaSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePermissaoColaboradorAtualizadaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissaoColaboradorAtualizadaSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePermissaoColaboradorAtualizadaSubscription(
	baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PermissaoColaboradorAtualizadaSubscription, PermissaoColaboradorAtualizadaSubscriptionVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<PermissaoColaboradorAtualizadaSubscription, PermissaoColaboradorAtualizadaSubscriptionVariables>(PermissaoColaboradorAtualizadaDocument, options);
}
export type PermissaoColaboradorAtualizadaSubscriptionHookResult = ReturnType<typeof usePermissaoColaboradorAtualizadaSubscription>;
export type PermissaoColaboradorAtualizadaSubscriptionResult = Apollo.SubscriptionResult<PermissaoColaboradorAtualizadaSubscription>;
export const BaixaCreditoDocument = gql`
	mutation BaixaCredito($dados: InputBaixaCredito!) {
		baixaCredito(Dados: $dados)
	}
`;
export type BaixaCreditoMutationFn = Apollo.MutationFunction<BaixaCreditoMutation, BaixaCreditoMutationVariables>;

/**
 * __useBaixaCreditoMutation__
 *
 * To run a mutation, you first call `useBaixaCreditoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBaixaCreditoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [baixaCreditoMutation, { data, loading, error }] = useBaixaCreditoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useBaixaCreditoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BaixaCreditoMutation, BaixaCreditoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<BaixaCreditoMutation, BaixaCreditoMutationVariables>(BaixaCreditoDocument, options);
}
export type BaixaCreditoMutationHookResult = ReturnType<typeof useBaixaCreditoMutation>;
export type BaixaCreditoMutationResult = Apollo.MutationResult<BaixaCreditoMutation>;
export type BaixaCreditoMutationOptions = Apollo.BaseMutationOptions<BaixaCreditoMutation, BaixaCreditoMutationVariables>;
export const CreditosDocument = gql`
	query Creditos($filtro: InputFiltroQueryCreditos, $paginacao: InputPaginacao) {
		creditos(Filtro: $filtro, Paginacao: $paginacao) {
			Data {
				Id
				IdPessoa
				IdUsuario
				DataLancamento
				ValorTotal
				ValorAberto
				ValorUtilizado
				IdContaFinanceiro
				CodigoFinanceiro
				Historico
				PessoaNome
				UsuarioNome
				Status
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useCreditosQuery__
 *
 * To run a query within a React component, call `useCreditosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function useCreditosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditosQuery, CreditosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CreditosQuery, CreditosQueryVariables>(CreditosDocument, options);
}
export function useCreditosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditosQuery, CreditosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CreditosQuery, CreditosQueryVariables>(CreditosDocument, options);
}
export type CreditosQueryHookResult = ReturnType<typeof useCreditosQuery>;
export type CreditosLazyQueryHookResult = ReturnType<typeof useCreditosLazyQuery>;
export type CreditosQueryResult = Apollo.QueryResult<CreditosQuery, CreditosQueryVariables>;
export const CreditoDocument = gql`
	query Credito($filtro: InputFiltroQueryCredito) {
		credito(Filtro: $filtro) {
			Id
			IdPessoa
			IdUsuario
			DataLancamento
			ValorTotal
			ValorAberto
			ValorUtilizado
			IdContaFinanceiro
			CodigoFinanceiro
			Historico
			PessoaNome
			UsuarioNome
			Status
			Hash
		}
	}
`;

/**
 * __useCreditoQuery__
 *
 * To run a query within a React component, call `useCreditoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCreditoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditoQuery, CreditoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CreditoQuery, CreditoQueryVariables>(CreditoDocument, options);
}
export function useCreditoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditoQuery, CreditoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CreditoQuery, CreditoQueryVariables>(CreditoDocument, options);
}
export type CreditoQueryHookResult = ReturnType<typeof useCreditoQuery>;
export type CreditoLazyQueryHookResult = ReturnType<typeof useCreditoLazyQuery>;
export type CreditoQueryResult = Apollo.QueryResult<CreditoQuery, CreditoQueryVariables>;
export const CreditoClienteDocument = gql`
	query CreditoCliente($filtro: InputFiltroQueryCreditoCliente!) {
		creditoCliente(Filtro: $filtro)
	}
`;

/**
 * __useCreditoClienteQuery__
 *
 * To run a query within a React component, call `useCreditoClienteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditoClienteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditoClienteQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCreditoClienteQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CreditoClienteQuery, CreditoClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CreditoClienteQuery, CreditoClienteQueryVariables>(CreditoClienteDocument, options);
}
export function useCreditoClienteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditoClienteQuery, CreditoClienteQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CreditoClienteQuery, CreditoClienteQueryVariables>(CreditoClienteDocument, options);
}
export type CreditoClienteQueryHookResult = ReturnType<typeof useCreditoClienteQuery>;
export type CreditoClienteLazyQueryHookResult = ReturnType<typeof useCreditoClienteLazyQuery>;
export type CreditoClienteQueryResult = Apollo.QueryResult<CreditoClienteQuery, CreditoClienteQueryVariables>;
export const LancamentoCreditoDocument = gql`
	mutation LancamentoCredito($dados: InputLancamentoCredito!) {
		lancamentoCredito(Dados: $dados)
	}
`;
export type LancamentoCreditoMutationFn = Apollo.MutationFunction<LancamentoCreditoMutation, LancamentoCreditoMutationVariables>;

/**
 * __useLancamentoCreditoMutation__
 *
 * To run a mutation, you first call `useLancamentoCreditoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLancamentoCreditoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lancamentoCreditoMutation, { data, loading, error }] = useLancamentoCreditoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useLancamentoCreditoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LancamentoCreditoMutation, LancamentoCreditoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<LancamentoCreditoMutation, LancamentoCreditoMutationVariables>(LancamentoCreditoDocument, options);
}
export type LancamentoCreditoMutationHookResult = ReturnType<typeof useLancamentoCreditoMutation>;
export type LancamentoCreditoMutationResult = Apollo.MutationResult<LancamentoCreditoMutation>;
export type LancamentoCreditoMutationOptions = Apollo.BaseMutationOptions<LancamentoCreditoMutation, LancamentoCreditoMutationVariables>;
export const CadastraMetaDocument = gql`
	mutation CadastraMeta($dados: InputCadastrarMeta!) {
		cadastraMeta(Dados: $dados)
	}
`;
export type CadastraMetaMutationFn = Apollo.MutationFunction<CadastraMetaMutation, CadastraMetaMutationVariables>;

/**
 * __useCadastraMetaMutation__
 *
 * To run a mutation, you first call `useCadastraMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraMetaMutation, { data, loading, error }] = useCadastraMetaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraMetaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraMetaMutation, CadastraMetaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraMetaMutation, CadastraMetaMutationVariables>(CadastraMetaDocument, options);
}
export type CadastraMetaMutationHookResult = ReturnType<typeof useCadastraMetaMutation>;
export type CadastraMetaMutationResult = Apollo.MutationResult<CadastraMetaMutation>;
export type CadastraMetaMutationOptions = Apollo.BaseMutationOptions<CadastraMetaMutation, CadastraMetaMutationVariables>;
export const EditaMetaDocument = gql`
	mutation EditaMeta($dados: InputEditarMeta!) {
		editaMeta(Dados: $dados)
	}
`;
export type EditaMetaMutationFn = Apollo.MutationFunction<EditaMetaMutation, EditaMetaMutationVariables>;

/**
 * __useEditaMetaMutation__
 *
 * To run a mutation, you first call `useEditaMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaMetaMutation, { data, loading, error }] = useEditaMetaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaMetaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaMetaMutation, EditaMetaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaMetaMutation, EditaMetaMutationVariables>(EditaMetaDocument, options);
}
export type EditaMetaMutationHookResult = ReturnType<typeof useEditaMetaMutation>;
export type EditaMetaMutationResult = Apollo.MutationResult<EditaMetaMutation>;
export type EditaMetaMutationOptions = Apollo.BaseMutationOptions<EditaMetaMutation, EditaMetaMutationVariables>;
export const ToggleStatusMetaDocument = gql`
	mutation ToggleStatusMeta($id: Int!, $status: Status!) {
		editaMeta(Dados: { Id: $id, Status: $status })
	}
`;
export type ToggleStatusMetaMutationFn = Apollo.MutationFunction<ToggleStatusMetaMutation, ToggleStatusMetaMutationVariables>;

/**
 * __useToggleStatusMetaMutation__
 *
 * To run a mutation, you first call `useToggleStatusMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleStatusMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleStatusMetaMutation, { data, loading, error }] = useToggleStatusMetaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleStatusMetaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleStatusMetaMutation, ToggleStatusMetaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ToggleStatusMetaMutation, ToggleStatusMetaMutationVariables>(ToggleStatusMetaDocument, options);
}
export type ToggleStatusMetaMutationHookResult = ReturnType<typeof useToggleStatusMetaMutation>;
export type ToggleStatusMetaMutationResult = Apollo.MutationResult<ToggleStatusMetaMutation>;
export type ToggleStatusMetaMutationOptions = Apollo.BaseMutationOptions<ToggleStatusMetaMutation, ToggleStatusMetaMutationVariables>;
export const MetaDocument = gql`
	query Meta($filtro: InputFiltroMeta) {
		meta(Filtro: $filtro) {
			Id
			IdVendedor
			NomeVendedor
			NomeMeta
			ValorMeta
			TipoBonificacao
			ValorBonificacao
			Status
		}
	}
`;

/**
 * __useMetaQuery__
 *
 * To run a query within a React component, call `useMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useMetaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MetaQuery, MetaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MetaQuery, MetaQueryVariables>(MetaDocument, options);
}
export function useMetaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MetaQuery, MetaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MetaQuery, MetaQueryVariables>(MetaDocument, options);
}
export type MetaQueryHookResult = ReturnType<typeof useMetaQuery>;
export type MetaLazyQueryHookResult = ReturnType<typeof useMetaLazyQuery>;
export type MetaQueryResult = Apollo.QueryResult<MetaQuery, MetaQueryVariables>;
export const MetasDocument = gql`
	query Metas($filtro: InputFiltroMetas) {
		metas(Filtro: $filtro) {
			Id
			IdVendedor
			NomeVendedor
			NomeMeta
			ValorMeta
			TipoBonificacao
			ValorBonificacao
			Status
		}
	}
`;

/**
 * __useMetasQuery__
 *
 * To run a query within a React component, call `useMetasQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useMetasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MetasQuery, MetasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<MetasQuery, MetasQueryVariables>(MetasDocument, options);
}
export function useMetasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MetasQuery, MetasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<MetasQuery, MetasQueryVariables>(MetasDocument, options);
}
export type MetasQueryHookResult = ReturnType<typeof useMetasQuery>;
export type MetasLazyQueryHookResult = ReturnType<typeof useMetasLazyQuery>;
export type MetasQueryResult = Apollo.QueryResult<MetasQuery, MetasQueryVariables>;
export const CadastraPedidoCondicionalDocument = gql`
	mutation CadastraPedidoCondicional($dados: InputCadastrarCondicional!) {
		cadastraCondicional(Dados: $dados)
	}
`;
export type CadastraPedidoCondicionalMutationFn = Apollo.MutationFunction<CadastraPedidoCondicionalMutation, CadastraPedidoCondicionalMutationVariables>;

/**
 * __useCadastraPedidoCondicionalMutation__
 *
 * To run a mutation, you first call `useCadastraPedidoCondicionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraPedidoCondicionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraPedidoCondicionalMutation, { data, loading, error }] = useCadastraPedidoCondicionalMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraPedidoCondicionalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraPedidoCondicionalMutation, CadastraPedidoCondicionalMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraPedidoCondicionalMutation, CadastraPedidoCondicionalMutationVariables>(CadastraPedidoCondicionalDocument, options);
}
export type CadastraPedidoCondicionalMutationHookResult = ReturnType<typeof useCadastraPedidoCondicionalMutation>;
export type CadastraPedidoCondicionalMutationResult = Apollo.MutationResult<CadastraPedidoCondicionalMutation>;
export type CadastraPedidoCondicionalMutationOptions = Apollo.BaseMutationOptions<CadastraPedidoCondicionalMutation, CadastraPedidoCondicionalMutationVariables>;
export const EditaPedidoCondicionalDocument = gql`
	mutation EditaPedidoCondicional($dados: InputEditarCondicional!) {
		editaCondicional(Dados: $dados)
	}
`;
export type EditaPedidoCondicionalMutationFn = Apollo.MutationFunction<EditaPedidoCondicionalMutation, EditaPedidoCondicionalMutationVariables>;

/**
 * __useEditaPedidoCondicionalMutation__
 *
 * To run a mutation, you first call `useEditaPedidoCondicionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaPedidoCondicionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaPedidoCondicionalMutation, { data, loading, error }] = useEditaPedidoCondicionalMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaPedidoCondicionalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaPedidoCondicionalMutation, EditaPedidoCondicionalMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaPedidoCondicionalMutation, EditaPedidoCondicionalMutationVariables>(EditaPedidoCondicionalDocument, options);
}
export type EditaPedidoCondicionalMutationHookResult = ReturnType<typeof useEditaPedidoCondicionalMutation>;
export type EditaPedidoCondicionalMutationResult = Apollo.MutationResult<EditaPedidoCondicionalMutation>;
export type EditaPedidoCondicionalMutationOptions = Apollo.BaseMutationOptions<EditaPedidoCondicionalMutation, EditaPedidoCondicionalMutationVariables>;
export const RetornoCondicionalDocument = gql`
	mutation RetornoCondicional($dados: InputRetornoCondicional!) {
		retornoCondicional(Dados: $dados)
	}
`;
export type RetornoCondicionalMutationFn = Apollo.MutationFunction<RetornoCondicionalMutation, RetornoCondicionalMutationVariables>;

/**
 * __useRetornoCondicionalMutation__
 *
 * To run a mutation, you first call `useRetornoCondicionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetornoCondicionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retornoCondicionalMutation, { data, loading, error }] = useRetornoCondicionalMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useRetornoCondicionalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RetornoCondicionalMutation, RetornoCondicionalMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<RetornoCondicionalMutation, RetornoCondicionalMutationVariables>(RetornoCondicionalDocument, options);
}
export type RetornoCondicionalMutationHookResult = ReturnType<typeof useRetornoCondicionalMutation>;
export type RetornoCondicionalMutationResult = Apollo.MutationResult<RetornoCondicionalMutation>;
export type RetornoCondicionalMutationOptions = Apollo.BaseMutationOptions<RetornoCondicionalMutation, RetornoCondicionalMutationVariables>;
export const ConverteCondicionalEmVendaDocument = gql`
	mutation ConverteCondicionalEmVenda($dados: InputConverterCondicionalEmVenda!) {
		converteCondicionalEmVenda(Dados: $dados)
	}
`;
export type ConverteCondicionalEmVendaMutationFn = Apollo.MutationFunction<ConverteCondicionalEmVendaMutation, ConverteCondicionalEmVendaMutationVariables>;

/**
 * __useConverteCondicionalEmVendaMutation__
 *
 * To run a mutation, you first call `useConverteCondicionalEmVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConverteCondicionalEmVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [converteCondicionalEmVendaMutation, { data, loading, error }] = useConverteCondicionalEmVendaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useConverteCondicionalEmVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConverteCondicionalEmVendaMutation, ConverteCondicionalEmVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ConverteCondicionalEmVendaMutation, ConverteCondicionalEmVendaMutationVariables>(ConverteCondicionalEmVendaDocument, options);
}
export type ConverteCondicionalEmVendaMutationHookResult = ReturnType<typeof useConverteCondicionalEmVendaMutation>;
export type ConverteCondicionalEmVendaMutationResult = Apollo.MutationResult<ConverteCondicionalEmVendaMutation>;
export type ConverteCondicionalEmVendaMutationOptions = Apollo.BaseMutationOptions<ConverteCondicionalEmVendaMutation, ConverteCondicionalEmVendaMutationVariables>;
export const PedidoCondicionalDocument = gql`
	query PedidoCondicional($filtro: InputFiltroCondicional) {
		condicional(Filtro: $filtro) {
			Id
			IdEmpresa
			IdCliente
			IdVendedor
			IdUsuarioSaida
			IdUsuarioRetorno
			Codigo
			Status
			Data
			DataPrevRetorno
			DataRetorno
			Observacoes
			ValorDesconto
			ValorFrete
			ValorTotal
			ValorTotalPrazo
			DataCadastro
			DataAlteracao
			ItemCondicional {
				Id
				IdEmpresa
				IdCondicional
				IdProduto
				IdUnidade
				IdGrade
				IdLinha
				IdColuna
				Codigo
				CodBarras
				Descricao
				DescricaoProduto
				DescricaoGrade
				DescricaoComp
				CodigoSecundario
				CodigoGrade
				CodBarrasGrade
				Status
				ValorDesconto
				ValorAcrescimo
				SimboloUnidade
				Quantidade
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				ValorUnitario
				ValorSubTotal
				ValorTotalItem
				ValorUnitarioPrazo
				ValorSubTotalPrazo
				ValorTotalItemPrazo
				QtdDevolvida
				QtdFaturada
				ValorCustoMedioUnitario
				ValorCustoUnitario
				DataCadastro
				DataAlteracao
			}
			HashLink
			IdVenda
			NomeCliente
			NomeFantasiaCliente
			CpfCnpjCliente
			TelefoneCliente
			CelularCliente
			CidadeCliente
			UFCliente
			LogradouroCliente
			NumeroCliente
			ComplementoCliente
			BairroCliente
			CepCliente
			EmailCliente
			NomeVendedor
			DocFiscal
		}
	}
`;

/**
 * __usePedidoCondicionalQuery__
 *
 * To run a query within a React component, call `usePedidoCondicionalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePedidoCondicionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedidoCondicionalQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePedidoCondicionalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PedidoCondicionalQuery, PedidoCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PedidoCondicionalQuery, PedidoCondicionalQueryVariables>(PedidoCondicionalDocument, options);
}
export function usePedidoCondicionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PedidoCondicionalQuery, PedidoCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PedidoCondicionalQuery, PedidoCondicionalQueryVariables>(PedidoCondicionalDocument, options);
}
export type PedidoCondicionalQueryHookResult = ReturnType<typeof usePedidoCondicionalQuery>;
export type PedidoCondicionalLazyQueryHookResult = ReturnType<typeof usePedidoCondicionalLazyQuery>;
export type PedidoCondicionalQueryResult = Apollo.QueryResult<PedidoCondicionalQuery, PedidoCondicionalQueryVariables>;
export const PedidosCondicionalDocument = gql`
	query PedidosCondicional($filtro: InputFiltroCondicionais, $paginacao: InputPaginacao) {
		condicionais(Filtro: $filtro, Paginacao: $paginacao) {
			Paginacao {
				TotalRegistros
				TotalValorCondicional
				TotalValorFaturado
				TotalQtdItensCondicional
				TotalQtdCondicionais
			}
			Data {
				Id
				IdEmpresa
				IdCliente
				IdVendedor
				IdUsuarioSaida
				IdUsuarioRetorno
				Codigo
				Status
				Data
				DataPrevRetorno
				DataRetorno
				Observacoes
				ValorDesconto
				ValorFrete
				ValorTotal
				ValorTotalPrazo
				DataCadastro
				DataAlteracao
				ItemCondicional {
					Id
					IdEmpresa
					IdCondicional
					IdProduto
					IdUnidade
					IdGrade
					IdLinha
					IdColuna
					Codigo
					CodBarras
					Descricao
					DescricaoProduto
					DescricaoGrade
					DescricaoComp
					CodigoSecundario
					CodigoGrade
					CodBarrasGrade
					Status
					ValorDesconto
					ValorAcrescimo
					SimboloUnidade
					Quantidade
					QuantidadeEstoque
					QuantidadeEstoqueGrade
					ValorUnitario
					ValorSubTotal
					ValorTotalItem
					ValorUnitarioPrazo
					ValorSubTotalPrazo
					ValorTotalItemPrazo
					QtdDevolvida
					QtdFaturada
					ValorCustoMedioUnitario
					ValorCustoUnitario
					DataCadastro
					DataAlteracao
				}
				HashLink
				IdVenda
				NomeCliente
				NomeFantasiaCliente
				CpfCnpjCliente
				TelefoneCliente
				CelularCliente
				CidadeCliente
				UFCliente
				LogradouroCliente
				NumeroCliente
				ComplementoCliente
				BairroCliente
				CepCliente
				EmailCliente
				NomeVendedor
				DocFiscal
				CodigoVenda
			}
		}
	}
`;

/**
 * __usePedidosCondicionalQuery__
 *
 * To run a query within a React component, call `usePedidosCondicionalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePedidosCondicionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedidosCondicionalQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function usePedidosCondicionalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PedidosCondicionalQuery, PedidosCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PedidosCondicionalQuery, PedidosCondicionalQueryVariables>(PedidosCondicionalDocument, options);
}
export function usePedidosCondicionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PedidosCondicionalQuery, PedidosCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PedidosCondicionalQuery, PedidosCondicionalQueryVariables>(PedidosCondicionalDocument, options);
}
export type PedidosCondicionalQueryHookResult = ReturnType<typeof usePedidosCondicionalQuery>;
export type PedidosCondicionalLazyQueryHookResult = ReturnType<typeof usePedidosCondicionalLazyQuery>;
export type PedidosCondicionalQueryResult = Apollo.QueryResult<PedidosCondicionalQuery, PedidosCondicionalQueryVariables>;
export const CodigoProximoPedidoCondicionalDocument = gql`
	query CodigoProximoPedidoCondicional {
		codigoProximoCondicional
	}
`;

/**
 * __useCodigoProximoPedidoCondicionalQuery__
 *
 * To run a query within a React component, call `useCodigoProximoPedidoCondicionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoProximoPedidoCondicionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoProximoPedidoCondicionalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodigoProximoPedidoCondicionalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoProximoPedidoCondicionalQuery, CodigoProximoPedidoCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoProximoPedidoCondicionalQuery, CodigoProximoPedidoCondicionalQueryVariables>(CodigoProximoPedidoCondicionalDocument, options);
}
export function useCodigoProximoPedidoCondicionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoProximoPedidoCondicionalQuery, CodigoProximoPedidoCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoProximoPedidoCondicionalQuery, CodigoProximoPedidoCondicionalQueryVariables>(CodigoProximoPedidoCondicionalDocument, options);
}
export type CodigoProximoPedidoCondicionalQueryHookResult = ReturnType<typeof useCodigoProximoPedidoCondicionalQuery>;
export type CodigoProximoPedidoCondicionalLazyQueryHookResult = ReturnType<typeof useCodigoProximoPedidoCondicionalLazyQuery>;
export type CodigoProximoPedidoCondicionalQueryResult = Apollo.QueryResult<CodigoProximoPedidoCondicionalQuery, CodigoProximoPedidoCondicionalQueryVariables>;
export const ImpressaoPedidoCondicionalDocument = gql`
	query ImpressaoPedidoCondicional($hash: String!, $view: Boolean) {
		impressaoCondicional(Hash: $hash, View: $view) {
			DataCondicional {
				Id
				IdEmpresa
				IdCliente
				IdVendedor
				IdUsuarioSaida
				IdUsuarioRetorno
				Codigo
				Status
				Data
				DataPrevRetorno
				DataRetorno
				Observacoes
				ValorDesconto
				ValorFrete
				ValorTotal
				ValorTotalPrazo
				DataCadastro
				DataAlteracao
				ItemCondicional {
					Id
					IdEmpresa
					IdCondicional
					IdProduto
					IdUnidade
					IdGrade
					IdLinha
					IdColuna
					Codigo
					CodBarras
					Descricao
					DescricaoProduto
					DescricaoGrade
					DescricaoComp
					CodigoSecundario
					CodigoGrade
					CodBarrasGrade
					Status
					ValorDesconto
					ValorAcrescimo
					SimboloUnidade
					Quantidade
					QuantidadeEstoque
					QuantidadeEstoqueGrade
					ValorUnitario
					ValorSubTotal
					ValorTotalItem
					ValorUnitarioPrazo
					ValorSubTotalPrazo
					ValorTotalItemPrazo
					QtdDevolvida
					QtdFaturada
					ValorCustoMedioUnitario
					ValorCustoUnitario
					DataCadastro
					DataAlteracao
				}
				HashLink
				IdVenda
				NomeCliente
				NomeFantasiaCliente
				CpfCnpjCliente
				TelefoneCliente
				CelularCliente
				CidadeCliente
				UFCliente
				LogradouroCliente
				NumeroCliente
				ComplementoCliente
				BairroCliente
				CepCliente
				EmailCliente
				NomeVendedor
				DocFiscal
			}
			DataEmpresa {
				Id
				Nome
				NomeFantasia
				CpfCnpj
				LogoEmpresa
				CidadeNome
				Uf
				Logradouro
				Bairro
				Cep
				NumEndereco
				Complemento
				Celular
				Telefone
				EmailPrincipal
				ProdutoEmpresa
				Configuracoes {
					Id
					Valor
					IdConfig
					Nome
					NomeLegivel
				}
			}
			DuasVias
		}
	}
`;

/**
 * __useImpressaoPedidoCondicionalQuery__
 *
 * To run a query within a React component, call `useImpressaoPedidoCondicionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpressaoPedidoCondicionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpressaoPedidoCondicionalQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useImpressaoPedidoCondicionalQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ImpressaoPedidoCondicionalQuery, ImpressaoPedidoCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ImpressaoPedidoCondicionalQuery, ImpressaoPedidoCondicionalQueryVariables>(ImpressaoPedidoCondicionalDocument, options);
}
export function useImpressaoPedidoCondicionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImpressaoPedidoCondicionalQuery, ImpressaoPedidoCondicionalQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ImpressaoPedidoCondicionalQuery, ImpressaoPedidoCondicionalQueryVariables>(ImpressaoPedidoCondicionalDocument, options);
}
export type ImpressaoPedidoCondicionalQueryHookResult = ReturnType<typeof useImpressaoPedidoCondicionalQuery>;
export type ImpressaoPedidoCondicionalLazyQueryHookResult = ReturnType<typeof useImpressaoPedidoCondicionalLazyQuery>;
export type ImpressaoPedidoCondicionalQueryResult = Apollo.QueryResult<ImpressaoPedidoCondicionalQuery, ImpressaoPedidoCondicionalQueryVariables>;
export const EnviaEmailCondicionalDocument = gql`
	subscription EnviaEmailCondicional($idCondicional: Int!) {
		enviaEmailCondicional(IdCondicional: $idCondicional)
	}
`;

/**
 * __useEnviaEmailCondicionalSubscription__
 *
 * To run a query within a React component, call `useEnviaEmailCondicionalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEnviaEmailCondicionalSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnviaEmailCondicionalSubscription({
 *   variables: {
 *      idCondicional: // value for 'idCondicional'
 *   },
 * });
 */
export function useEnviaEmailCondicionalSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<EnviaEmailCondicionalSubscription, EnviaEmailCondicionalSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<EnviaEmailCondicionalSubscription, EnviaEmailCondicionalSubscriptionVariables>(EnviaEmailCondicionalDocument, options);
}
export type EnviaEmailCondicionalSubscriptionHookResult = ReturnType<typeof useEnviaEmailCondicionalSubscription>;
export type EnviaEmailCondicionalSubscriptionResult = Apollo.SubscriptionResult<EnviaEmailCondicionalSubscription>;
export const CadastraPedidoVendaDocument = gql`
	mutation CadastraPedidoVenda($dados: InputCadastrarPedidoVenda!) {
		cadastraPedidoVenda(Dados: $dados)
	}
`;
export type CadastraPedidoVendaMutationFn = Apollo.MutationFunction<CadastraPedidoVendaMutation, CadastraPedidoVendaMutationVariables>;

/**
 * __useCadastraPedidoVendaMutation__
 *
 * To run a mutation, you first call `useCadastraPedidoVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraPedidoVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraPedidoVendaMutation, { data, loading, error }] = useCadastraPedidoVendaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraPedidoVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraPedidoVendaMutation, CadastraPedidoVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraPedidoVendaMutation, CadastraPedidoVendaMutationVariables>(CadastraPedidoVendaDocument, options);
}
export type CadastraPedidoVendaMutationHookResult = ReturnType<typeof useCadastraPedidoVendaMutation>;
export type CadastraPedidoVendaMutationResult = Apollo.MutationResult<CadastraPedidoVendaMutation>;
export type CadastraPedidoVendaMutationOptions = Apollo.BaseMutationOptions<CadastraPedidoVendaMutation, CadastraPedidoVendaMutationVariables>;
export const EditaPedidoVendaDocument = gql`
	mutation EditaPedidoVenda($dados: InputEditarPedidoVenda!) {
		editaPedidoVenda(Dados: $dados)
	}
`;
export type EditaPedidoVendaMutationFn = Apollo.MutationFunction<EditaPedidoVendaMutation, EditaPedidoVendaMutationVariables>;

/**
 * __useEditaPedidoVendaMutation__
 *
 * To run a mutation, you first call `useEditaPedidoVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaPedidoVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaPedidoVendaMutation, { data, loading, error }] = useEditaPedidoVendaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaPedidoVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaPedidoVendaMutation, EditaPedidoVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaPedidoVendaMutation, EditaPedidoVendaMutationVariables>(EditaPedidoVendaDocument, options);
}
export type EditaPedidoVendaMutationHookResult = ReturnType<typeof useEditaPedidoVendaMutation>;
export type EditaPedidoVendaMutationResult = Apollo.MutationResult<EditaPedidoVendaMutation>;
export type EditaPedidoVendaMutationOptions = Apollo.BaseMutationOptions<EditaPedidoVendaMutation, EditaPedidoVendaMutationVariables>;
export const GerarVendaDocument = gql`
	mutation GerarVenda($dados: InputGerarVenda!) {
		gerarVenda(Dados: $dados)
	}
`;
export type GerarVendaMutationFn = Apollo.MutationFunction<GerarVendaMutation, GerarVendaMutationVariables>;

/**
 * __useGerarVendaMutation__
 *
 * To run a mutation, you first call `useGerarVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarVendaMutation, { data, loading, error }] = useGerarVendaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useGerarVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GerarVendaMutation, GerarVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<GerarVendaMutation, GerarVendaMutationVariables>(GerarVendaDocument, options);
}
export type GerarVendaMutationHookResult = ReturnType<typeof useGerarVendaMutation>;
export type GerarVendaMutationResult = Apollo.MutationResult<GerarVendaMutation>;
export type GerarVendaMutationOptions = Apollo.BaseMutationOptions<GerarVendaMutation, GerarVendaMutationVariables>;
export const PedidosVendaDocument = gql`
	query PedidosVenda($filtro: InputFiltroPedidosVenda, $paginacao: InputPaginacao) {
		pedidosVenda(Filtro: $filtro, Paginacao: $paginacao) {
			Data {
				Id
				IdCliente
				IdVendedor
				IdUsuario
				IdTransportadora
				IdEnderecoEntrega
				Status
				Codigo
				CodigoVenda
				Data
				QtdItens
				Hora
				DataHora
				DataEntrega
				ValorJuro
				ValorFrete
				DescricaoStatus
				EstagioStatus
				NomeVendedor
				NomeFantasiaCliente
				NomeCliente
				HashLink
				ItemVenda {
					ValorSubTotal
					SimboloUnidade
					DefinicaoServico
					DefinicaoProducao
					DefinicaoVenda
					DataAlteracao
					DataCadastro
					ValorCustoUnitario
					ValorCustoMedioUnitario
					QtdFaturada
					QtdConferida
					ValorTotalItem
					ValorUnitario
					QuantidadeEstoqueGrade
					QuantidadeEstoque
					Quantidade
					ValorJuro
					ValorSeguro
					ValorFrete
					ValorDescontoTotal
					ValorAcrescimo
					ValorDesconto
					InformacoesAdicionais
					DescricaoComp
					DescricaoGrade
					DescricaoProduto
					Descricao
					CodBarras
					Codigo
					IdColuna
					IdLinha
					IdGrade
					IdUnidade
					IdProduto
					IdVenda
					Id
				}
				PrestacaoVenda {
					TotalLancamento
					TipoFormaPgto
					TagPgtoNFe
					NumLancamento
					NumDocumento
					NomeFormaPgto
					DataAlteracao
					ValorParcela
					DataVencimento
					IdFormaPgto
					IdAdminCartao
					IdContaFinanceira
					IdVenda
					Id
				}
				DataAlteracao
				DataCadastro
				IdEcommerce
				PrecoUsado
				Frete
				Conferido
				Observacoes
				ValorTotal
				ValorSeguro
				ValorDesconto
			}
			Paginacao {
				NumPedidos
				TotalItens
				TotalPedido
				TotalRegistros
			}
		}
	}
`;

/**
 * __usePedidosVendaQuery__
 *
 * To run a query within a React component, call `usePedidosVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePedidosVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedidosVendaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function usePedidosVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PedidosVendaQuery, PedidosVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PedidosVendaQuery, PedidosVendaQueryVariables>(PedidosVendaDocument, options);
}
export function usePedidosVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PedidosVendaQuery, PedidosVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PedidosVendaQuery, PedidosVendaQueryVariables>(PedidosVendaDocument, options);
}
export type PedidosVendaQueryHookResult = ReturnType<typeof usePedidosVendaQuery>;
export type PedidosVendaLazyQueryHookResult = ReturnType<typeof usePedidosVendaLazyQuery>;
export type PedidosVendaQueryResult = Apollo.QueryResult<PedidosVendaQuery, PedidosVendaQueryVariables>;
export const PedidoVendaDocument = gql`
	query PedidoVenda($filtro: InputFiltroPedidoVenda) {
		pedidoVenda(Filtro: $filtro) {
			Id
			IdCliente
			IdTransportadora
			IdVendedor
			Codigo
			CodigoVenda
			DataHora
			DataEntrega
			Status
			Frete
			ValorJuro
			ValorFrete
			ValorDesconto
			ValorSeguro
			Observacoes
			DataCadastro
			DataAlteracao
			PrestacaoVenda {
				Id
				IdFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				DataVencimento
				NumDocumento
				NumLancamento
				NomeFormaPgto
				TagPgtoNFe
				TipoFormaPgto
			}
			ItemVenda {
				Id
				IdProduto
				IdGrade
				IdLinha
				IdColuna
				IdUnidade
				SimboloUnidade
				Descricao
				DescricaoGrade
				DescricaoProduto
				Codigo
				CodBarras
				Quantidade
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				ValorDescontoTotal
				DescricaoComp
				InformacoesAdicionais
			}
		}
	}
`;

/**
 * __usePedidoVendaQuery__
 *
 * To run a query within a React component, call `usePedidoVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePedidoVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedidoVendaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePedidoVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PedidoVendaQuery, PedidoVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PedidoVendaQuery, PedidoVendaQueryVariables>(PedidoVendaDocument, options);
}
export function usePedidoVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PedidoVendaQuery, PedidoVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PedidoVendaQuery, PedidoVendaQueryVariables>(PedidoVendaDocument, options);
}
export type PedidoVendaQueryHookResult = ReturnType<typeof usePedidoVendaQuery>;
export type PedidoVendaLazyQueryHookResult = ReturnType<typeof usePedidoVendaLazyQuery>;
export type PedidoVendaQueryResult = Apollo.QueryResult<PedidoVendaQuery, PedidoVendaQueryVariables>;
export const CodigoProximoPedidoVendaDocument = gql`
	query CodigoProximoPedidoVenda {
		codigoProximoPedidoVenda
	}
`;

/**
 * __useCodigoProximoPedidoVendaQuery__
 *
 * To run a query within a React component, call `useCodigoProximoPedidoVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoProximoPedidoVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoProximoPedidoVendaQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodigoProximoPedidoVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoProximoPedidoVendaQuery, CodigoProximoPedidoVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoProximoPedidoVendaQuery, CodigoProximoPedidoVendaQueryVariables>(CodigoProximoPedidoVendaDocument, options);
}
export function useCodigoProximoPedidoVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoProximoPedidoVendaQuery, CodigoProximoPedidoVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoProximoPedidoVendaQuery, CodigoProximoPedidoVendaQueryVariables>(CodigoProximoPedidoVendaDocument, options);
}
export type CodigoProximoPedidoVendaQueryHookResult = ReturnType<typeof useCodigoProximoPedidoVendaQuery>;
export type CodigoProximoPedidoVendaLazyQueryHookResult = ReturnType<typeof useCodigoProximoPedidoVendaLazyQuery>;
export type CodigoProximoPedidoVendaQueryResult = Apollo.QueryResult<CodigoProximoPedidoVendaQuery, CodigoProximoPedidoVendaQueryVariables>;
export const ComissoesDocument = gql`
	query Comissoes($filtro: InputFiltroComissoes!) {
		comissoes(Filtro: $filtro) {
			IdVendedor
			ValorTotalVendas
			ValorTotalDevolucao
			ValorTotalComissao
			Venda {
				DataVenda
				CodigoVenda
				Cliente {
					Id
					Nome
				}
				ValorTotal
				ValorComissao
				ValorDevolvido
				FormaPgto
				NomeFormaPgto
				QtdItens
				NomeVendedor
			}
			Devolucao {
				DataVenda
				IdVenda
				Codigo
				Cliente {
					Id
					Nome
				}
				QtdItens
				ValorTotal
				NomeVendedor
			}
			Metas {
				NomeMeta
				ValorMeta
				PercAtingido
				AdicionalComissao
				TipoBonificacao
				ValorBonificacao
			}
			ComissoesFormaPgto {
				IdFormaPgto
				NomeFormaPgto
				TotalVendido
				ComissaoPercentual
				ComissaoPagar
			}
		}
	}
`;

/**
 * __useComissoesQuery__
 *
 * To run a query within a React component, call `useComissoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useComissoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComissoesQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useComissoesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ComissoesQuery, ComissoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ComissoesQuery, ComissoesQueryVariables>(ComissoesDocument, options);
}
export function useComissoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComissoesQuery, ComissoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ComissoesQuery, ComissoesQueryVariables>(ComissoesDocument, options);
}
export type ComissoesQueryHookResult = ReturnType<typeof useComissoesQuery>;
export type ComissoesLazyQueryHookResult = ReturnType<typeof useComissoesLazyQuery>;
export type ComissoesQueryResult = Apollo.QueryResult<ComissoesQuery, ComissoesQueryVariables>;
export const VendasPorProdutoDocument = gql`
	query VendasPorProduto($filtro: InputFiltroVendasPorProduto!, $paginacao: InputPaginacao) {
		vendasPorProduto(Filtro: $filtro, Paginacao: $paginacao) {
			Data {
				ClassificacaoRanking
				Codigo
				Agrupamento
				TotalVendido
				TotalCusto
				TotalDevolucoes
				TotalLucro
				TicketMedio
				QtdItensVendidos
				Itens {
					IdProduto
					Codigo
					Descricao
					Quantidade
					Custo
					ValorTotal
					MargemLucro
				}
			}
			Paginacao {
				TotalRegistros
				TotalVendido
				TotalCusto
				TotalDevolucoes
				TotalLucro
				TicketMedio
				QtdItensVendidos
			}
		}
	}
`;

/**
 * __useVendasPorProdutoQuery__
 *
 * To run a query within a React component, call `useVendasPorProdutoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendasPorProdutoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendasPorProdutoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function useVendasPorProdutoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VendasPorProdutoQuery, VendasPorProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VendasPorProdutoQuery, VendasPorProdutoQueryVariables>(VendasPorProdutoDocument, options);
}
export function useVendasPorProdutoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendasPorProdutoQuery, VendasPorProdutoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VendasPorProdutoQuery, VendasPorProdutoQueryVariables>(VendasPorProdutoDocument, options);
}
export type VendasPorProdutoQueryHookResult = ReturnType<typeof useVendasPorProdutoQuery>;
export type VendasPorProdutoLazyQueryHookResult = ReturnType<typeof useVendasPorProdutoLazyQuery>;
export type VendasPorProdutoQueryResult = Apollo.QueryResult<VendasPorProdutoQuery, VendasPorProdutoQueryVariables>;
export const VendasPorTributacaoDocument = gql`
	query VendasPorTributacao($filtro: InputFiltroVendasPorTributacao!) {
		vendasPorTributacao(Filtro: $filtro) {
			DataEmissao
			ValorTotal
			QuantidadeTotal
			Itens {
				IdProduto
				Codigo
				Descricao
				ValorTotal
				Quantidade
			}
		}
	}
`;

/**
 * __useVendasPorTributacaoQuery__
 *
 * To run a query within a React component, call `useVendasPorTributacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendasPorTributacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendasPorTributacaoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useVendasPorTributacaoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VendasPorTributacaoQuery, VendasPorTributacaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VendasPorTributacaoQuery, VendasPorTributacaoQueryVariables>(VendasPorTributacaoDocument, options);
}
export function useVendasPorTributacaoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendasPorTributacaoQuery, VendasPorTributacaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VendasPorTributacaoQuery, VendasPorTributacaoQueryVariables>(VendasPorTributacaoDocument, options);
}
export type VendasPorTributacaoQueryHookResult = ReturnType<typeof useVendasPorTributacaoQuery>;
export type VendasPorTributacaoLazyQueryHookResult = ReturnType<typeof useVendasPorTributacaoLazyQuery>;
export type VendasPorTributacaoQueryResult = Apollo.QueryResult<VendasPorTributacaoQuery, VendasPorTributacaoQueryVariables>;
export const CadastraDevolucaoDocument = gql`
	mutation CadastraDevolucao($dados: InputCadastrarDevolucao!) {
		cadastraDevolucao(Dados: $dados)
	}
`;
export type CadastraDevolucaoMutationFn = Apollo.MutationFunction<CadastraDevolucaoMutation, CadastraDevolucaoMutationVariables>;

/**
 * __useCadastraDevolucaoMutation__
 *
 * To run a mutation, you first call `useCadastraDevolucaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraDevolucaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraDevolucaoMutation, { data, loading, error }] = useCadastraDevolucaoMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraDevolucaoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraDevolucaoMutation, CadastraDevolucaoMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraDevolucaoMutation, CadastraDevolucaoMutationVariables>(CadastraDevolucaoDocument, options);
}
export type CadastraDevolucaoMutationHookResult = ReturnType<typeof useCadastraDevolucaoMutation>;
export type CadastraDevolucaoMutationResult = Apollo.MutationResult<CadastraDevolucaoMutation>;
export type CadastraDevolucaoMutationOptions = Apollo.BaseMutationOptions<CadastraDevolucaoMutation, CadastraDevolucaoMutationVariables>;
export const DevolucaoDocument = gql`
	query Devolucao($filtro: InputFiltroDevolucao) {
		devolucao(Filtro: $filtro) {
			Id
			IdUsuario
			IdPessoa
			IdNFe
			Tipo
			Codigo
			Data
			Hora
			ValorTotal
			NomePessoa
			NomeFantasiaPessoa
			CpfCnpjPessoa
			TelefonePessoa
			CelularPessoa
			EmailPessoa
			IdCidadePessoa
			CidadePessoa
			IdEstadoPessoa
			EstadoPessoa
			RuaPessoa
			BairroPessoa
			NumeroPessoa
			ComplementoPessoa
		}
	}
`;

/**
 * __useDevolucaoQuery__
 *
 * To run a query within a React component, call `useDevolucaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevolucaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevolucaoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useDevolucaoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DevolucaoQuery, DevolucaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DevolucaoQuery, DevolucaoQueryVariables>(DevolucaoDocument, options);
}
export function useDevolucaoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevolucaoQuery, DevolucaoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DevolucaoQuery, DevolucaoQueryVariables>(DevolucaoDocument, options);
}
export type DevolucaoQueryHookResult = ReturnType<typeof useDevolucaoQuery>;
export type DevolucaoLazyQueryHookResult = ReturnType<typeof useDevolucaoLazyQuery>;
export type DevolucaoQueryResult = Apollo.QueryResult<DevolucaoQuery, DevolucaoQueryVariables>;
export const DevolucoesDocument = gql`
	query Devolucoes($filtro: InputFiltroDevolucoes, $paginacao: InputPaginacao) {
		devolucoes(Filtro: $filtro, Paginacao: $paginacao) {
			Data {
				Id
				IdUsuario
				IdPessoa
				IdNFe
				Tipo
				Codigo
				Data
				Hora
				ValorTotal
				NomePessoa
				NomeFantasiaPessoa
				ItemDevolucao {
					IdNFe
				}
			}
			Paginacao {
				TotalRegistros
			}
		}
	}
`;

/**
 * __useDevolucoesQuery__
 *
 * To run a query within a React component, call `useDevolucoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevolucoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevolucoesQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function useDevolucoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DevolucoesQuery, DevolucoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<DevolucoesQuery, DevolucoesQueryVariables>(DevolucoesDocument, options);
}
export function useDevolucoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevolucoesQuery, DevolucoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<DevolucoesQuery, DevolucoesQueryVariables>(DevolucoesDocument, options);
}
export type DevolucoesQueryHookResult = ReturnType<typeof useDevolucoesQuery>;
export type DevolucoesLazyQueryHookResult = ReturnType<typeof useDevolucoesLazyQuery>;
export type DevolucoesQueryResult = Apollo.QueryResult<DevolucoesQuery, DevolucoesQueryVariables>;
export const CadastraVendaDocument = gql`
	mutation CadastraVenda($dados: InputCadastrarVenda!) {
		cadastraVenda(Dados: $dados)
	}
`;
export type CadastraVendaMutationFn = Apollo.MutationFunction<CadastraVendaMutation, CadastraVendaMutationVariables>;

/**
 * __useCadastraVendaMutation__
 *
 * To run a mutation, you first call `useCadastraVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraVendaMutation, { data, loading, error }] = useCadastraVendaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useCadastraVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraVendaMutation, CadastraVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraVendaMutation, CadastraVendaMutationVariables>(CadastraVendaDocument, options);
}
export type CadastraVendaMutationHookResult = ReturnType<typeof useCadastraVendaMutation>;
export type CadastraVendaMutationResult = Apollo.MutationResult<CadastraVendaMutation>;
export type CadastraVendaMutationOptions = Apollo.BaseMutationOptions<CadastraVendaMutation, CadastraVendaMutationVariables>;
export const EditaVendaDocument = gql`
	mutation EditaVenda($dados: InputEditarVenda!) {
		editaVenda(Dados: $dados)
	}
`;
export type EditaVendaMutationFn = Apollo.MutationFunction<EditaVendaMutation, EditaVendaMutationVariables>;

/**
 * __useEditaVendaMutation__
 *
 * To run a mutation, you first call `useEditaVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditaVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editaVendaMutation, { data, loading, error }] = useEditaVendaMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *   },
 * });
 */
export function useEditaVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditaVendaMutation, EditaVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<EditaVendaMutation, EditaVendaMutationVariables>(EditaVendaDocument, options);
}
export type EditaVendaMutationHookResult = ReturnType<typeof useEditaVendaMutation>;
export type EditaVendaMutationResult = Apollo.MutationResult<EditaVendaMutation>;
export type EditaVendaMutationOptions = Apollo.BaseMutationOptions<EditaVendaMutation, EditaVendaMutationVariables>;
export const ConfirmaVendaDocument = gql`
	mutation ConfirmaVenda($id: Int!, $correlationId: [String]) {
		confirmaVenda(Id: $id, CorrelationId: $correlationId)
	}
`;
export type ConfirmaVendaMutationFn = Apollo.MutationFunction<ConfirmaVendaMutation, ConfirmaVendaMutationVariables>;

/**
 * __useConfirmaVendaMutation__
 *
 * To run a mutation, you first call `useConfirmaVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmaVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmaVendaMutation, { data, loading, error }] = useConfirmaVendaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useConfirmaVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmaVendaMutation, ConfirmaVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<ConfirmaVendaMutation, ConfirmaVendaMutationVariables>(ConfirmaVendaDocument, options);
}
export type ConfirmaVendaMutationHookResult = ReturnType<typeof useConfirmaVendaMutation>;
export type ConfirmaVendaMutationResult = Apollo.MutationResult<ConfirmaVendaMutation>;
export type ConfirmaVendaMutationOptions = Apollo.BaseMutationOptions<ConfirmaVendaMutation, ConfirmaVendaMutationVariables>;
export const GeraNfeDaVendaDocument = gql`
	mutation GeraNfeDaVenda($id: Int!, $correlationId: [String]) {
		geraNfeDaVenda(Id: $id, CorrelationId: $correlationId)
	}
`;
export type GeraNfeDaVendaMutationFn = Apollo.MutationFunction<GeraNfeDaVendaMutation, GeraNfeDaVendaMutationVariables>;

/**
 * __useGeraNfeDaVendaMutation__
 *
 * To run a mutation, you first call `useGeraNfeDaVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeraNfeDaVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [geraNfeDaVendaMutation, { data, loading, error }] = useGeraNfeDaVendaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useGeraNfeDaVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GeraNfeDaVendaMutation, GeraNfeDaVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<GeraNfeDaVendaMutation, GeraNfeDaVendaMutationVariables>(GeraNfeDaVendaDocument, options);
}
export type GeraNfeDaVendaMutationHookResult = ReturnType<typeof useGeraNfeDaVendaMutation>;
export type GeraNfeDaVendaMutationResult = Apollo.MutationResult<GeraNfeDaVendaMutation>;
export type GeraNfeDaVendaMutationOptions = Apollo.BaseMutationOptions<GeraNfeDaVendaMutation, GeraNfeDaVendaMutationVariables>;
export const FaturaVendaDocument = gql`
	mutation FaturaVenda($id: Int!, $correlationId: [String]) {
		faturaVenda(Id: $id, CorrelationId: $correlationId)
	}
`;
export type FaturaVendaMutationFn = Apollo.MutationFunction<FaturaVendaMutation, FaturaVendaMutationVariables>;

/**
 * __useFaturaVendaMutation__
 *
 * To run a mutation, you first call `useFaturaVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaturaVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faturaVendaMutation, { data, loading, error }] = useFaturaVendaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useFaturaVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FaturaVendaMutation, FaturaVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<FaturaVendaMutation, FaturaVendaMutationVariables>(FaturaVendaDocument, options);
}
export type FaturaVendaMutationHookResult = ReturnType<typeof useFaturaVendaMutation>;
export type FaturaVendaMutationResult = Apollo.MutationResult<FaturaVendaMutation>;
export type FaturaVendaMutationOptions = Apollo.BaseMutationOptions<FaturaVendaMutation, FaturaVendaMutationVariables>;
export const CancelaVendaDocument = gql`
	mutation CancelaVenda($id: Int!) {
		cancelaVenda(Id: $id)
	}
`;
export type CancelaVendaMutationFn = Apollo.MutationFunction<CancelaVendaMutation, CancelaVendaMutationVariables>;

/**
 * __useCancelaVendaMutation__
 *
 * To run a mutation, you first call `useCancelaVendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelaVendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelaVendaMutation, { data, loading, error }] = useCancelaVendaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelaVendaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelaVendaMutation, CancelaVendaMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CancelaVendaMutation, CancelaVendaMutationVariables>(CancelaVendaDocument, options);
}
export type CancelaVendaMutationHookResult = ReturnType<typeof useCancelaVendaMutation>;
export type CancelaVendaMutationResult = Apollo.MutationResult<CancelaVendaMutation>;
export type CancelaVendaMutationOptions = Apollo.BaseMutationOptions<CancelaVendaMutation, CancelaVendaMutationVariables>;
export const StatusVendasDocument = gql`
	query StatusVendas($filtro: InputFiltroStatusVendas) {
		statusVendas(Filtro: $filtro) {
			Id
			Descricao
			Estagio
			Status
			Ordem
		}
	}
`;

/**
 * __useStatusVendasQuery__
 *
 * To run a query within a React component, call `useStatusVendasQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusVendasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusVendasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useStatusVendasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatusVendasQuery, StatusVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<StatusVendasQuery, StatusVendasQueryVariables>(StatusVendasDocument, options);
}
export function useStatusVendasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatusVendasQuery, StatusVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<StatusVendasQuery, StatusVendasQueryVariables>(StatusVendasDocument, options);
}
export type StatusVendasQueryHookResult = ReturnType<typeof useStatusVendasQuery>;
export type StatusVendasLazyQueryHookResult = ReturnType<typeof useStatusVendasLazyQuery>;
export type StatusVendasQueryResult = Apollo.QueryResult<StatusVendasQuery, StatusVendasQueryVariables>;
export const VendasDocument = gql`
	query Vendas($filtro: InputFiltroVendas, $paginacao: InputPaginacao) {
		vendas(Filtro: $filtro, Paginacao: $paginacao) {
			Data {
				Id
				IdCliente
				IdVendedor
				Codigo
				Status
				DescricaoStatus
				DataHora
				DataEntrega
				NomeCliente
				NomeFantasiaCliente
				NomeVendedor
				ValorTotal
				QtdItens
				HashLink
				EstagioStatus
				FinanceiroGerado
				Tipo
				VendaNfe {
					IdNFe
					NumeroNFe
					StatusNFe
				}
				PrestacaoVenda {
					Id
					IdFormaPgto
					IdAdminCartao
					IdContaFinanceira
					ValorParcela
					TotalLancamento
					DataVencimento
					NumDocumento
					NumLancamento
					NomeFormaPgto
					TagPgtoNFe
					TipoFormaPgto
				}
				ItemVenda {
					Id
					IdProduto
					IdGrade
					IdLinha
					IdColuna
					IdUnidade
					SimboloUnidade
					Descricao
					DescricaoGrade
					DescricaoProduto
					Codigo
					CodBarras
					Quantidade
					QuantidadeEstoque
					QuantidadeEstoqueGrade
					ValorUnitario
					ValorDesconto
					ValorAcrescimo
					ValorDescontoTotal
					DescricaoComp
					InformacoesAdicionais
				}
				DataAlteracao
				DataCadastro
				Conferido
				Frete
				Observacoes
				PrecoUsado
				ValorDesconto
				ValorFrete
				ValorJuro
				ValorSeguro
			}
			Paginacao {
				TotalRegistrosVendidos
				TotalVendido
				TotalItensVendidos
				TotalRegistros
				TotalVendas
				TotalItens
				TotalFormasPagamento {
					TotalVendas
					TotalVendido
					IdFormaPgto
					NomeFormaPgto
				}
			}
		}
	}
`;

/**
 * __useVendasQuery__
 *
 * To run a query within a React component, call `useVendasQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      paginacao: // value for 'paginacao'
 *   },
 * });
 */
export function useVendasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VendasQuery, VendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VendasQuery, VendasQueryVariables>(VendasDocument, options);
}
export function useVendasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendasQuery, VendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VendasQuery, VendasQueryVariables>(VendasDocument, options);
}
export type VendasQueryHookResult = ReturnType<typeof useVendasQuery>;
export type VendasLazyQueryHookResult = ReturnType<typeof useVendasLazyQuery>;
export type VendasQueryResult = Apollo.QueryResult<VendasQuery, VendasQueryVariables>;
export const VendaDocument = gql`
	query Venda($filtro: InputFiltroVenda) {
		venda(Filtro: $filtro) {
			Id
			IdCliente
			IdTransportadora
			IdVendedor
			IdIntermediador
			Codigo
			DataHora
			DataEntrega
			Status
			Frete
			ValorJuro
			ValorFrete
			ValorDesconto
			ValorSeguro
			Observacoes
			InformacoesAdicionais
			NomeCliente
			NomeFantasiaCliente
			CpfCnpjCliente
			TelefoneCliente
			CelularCliente
			EmailCliente
			CidadeCliente
			UFCliente
			LogradouroCliente
			BairroCliente
			NumeroCliente
			ComplementoCliente
			HashLink
			DataCadastro
			DataAlteracao
			PrestacaoVenda {
				Id
				IdFormaPgto
				IdAdminCartao
				IdContaFinanceira
				ValorParcela
				TotalLancamento
				DataVencimento
				NumDocumento
				NumLancamento
				NomeFormaPgto
				TagPgtoNFe
				TipoFormaPgto
			}
			ItemVenda {
				Id
				IdProduto
				IdGrade
				IdLinha
				IdColuna
				IdUnidade
				SimboloUnidade
				Descricao
				DescricaoGrade
				DescricaoProduto
				Codigo
				CodBarras
				Quantidade
				QuantidadeEstoque
				QuantidadeEstoqueGrade
				ValorUnitario
				ValorDesconto
				ValorAcrescimo
				ValorDescontoTotal
				DescricaoComp
				InformacoesAdicionais
			}
			VendaNfe {
				IdNFe
				NumeroNFe
				StatusNFe
			}
		}
	}
`;

/**
 * __useVendaQuery__
 *
 * To run a query within a React component, call `useVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VendaQuery, VendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VendaQuery, VendaQueryVariables>(VendaDocument, options);
}
export function useVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendaQuery, VendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VendaQuery, VendaQueryVariables>(VendaDocument, options);
}
export type VendaQueryHookResult = ReturnType<typeof useVendaQuery>;
export type VendaLazyQueryHookResult = ReturnType<typeof useVendaLazyQuery>;
export type VendaQueryResult = Apollo.QueryResult<VendaQuery, VendaQueryVariables>;
export const StatusVendaDocument = gql`
	query StatusVenda($filtro: InputFiltroStatusVenda) {
		statusVenda(Filtro: $filtro) {
			Descricao
			Estagio
			Ordem
		}
	}
`;

/**
 * __useStatusVendaQuery__
 *
 * To run a query within a React component, call `useStatusVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusVendaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useStatusVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatusVendaQuery, StatusVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<StatusVendaQuery, StatusVendaQueryVariables>(StatusVendaDocument, options);
}
export function useStatusVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatusVendaQuery, StatusVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<StatusVendaQuery, StatusVendaQueryVariables>(StatusVendaDocument, options);
}
export type StatusVendaQueryHookResult = ReturnType<typeof useStatusVendaQuery>;
export type StatusVendaLazyQueryHookResult = ReturnType<typeof useStatusVendaLazyQuery>;
export type StatusVendaQueryResult = Apollo.QueryResult<StatusVendaQuery, StatusVendaQueryVariables>;
export const CodigoProximaVendaDocument = gql`
	query CodigoProximaVenda {
		codigoProximaVenda
	}
`;

/**
 * __useCodigoProximaVendaQuery__
 *
 * To run a query within a React component, call `useCodigoProximaVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodigoProximaVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodigoProximaVendaQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodigoProximaVendaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CodigoProximaVendaQuery, CodigoProximaVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<CodigoProximaVendaQuery, CodigoProximaVendaQueryVariables>(CodigoProximaVendaDocument, options);
}
export function useCodigoProximaVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodigoProximaVendaQuery, CodigoProximaVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<CodigoProximaVendaQuery, CodigoProximaVendaQueryVariables>(CodigoProximaVendaDocument, options);
}
export type CodigoProximaVendaQueryHookResult = ReturnType<typeof useCodigoProximaVendaQuery>;
export type CodigoProximaVendaLazyQueryHookResult = ReturnType<typeof useCodigoProximaVendaLazyQuery>;
export type CodigoProximaVendaQueryResult = Apollo.QueryResult<CodigoProximaVendaQuery, CodigoProximaVendaQueryVariables>;
export const ImpressaoVendaDocument = gql`
	query ImpressaoVenda($hash: String!, $view: Boolean) {
		impressaoVenda(Hash: $hash, View: $view) {
			DataVenda {
				Id
				Codigo
				IdVendedor
				NomeVendedor
				DataHora
				DataEntrega
				IdCliente
				NomeCliente
				NomeFantasiaCliente
				CpfCnpjCliente
				TelefoneCliente
				CelularCliente
				CidadeCliente
				UFCliente
				LogradouroCliente
				NumeroCliente
				ComplementoCliente
				BairroCliente
				CepCliente
				Tipo
				ItemVenda {
					Id
					Codigo
					Descricao
					DescricaoComp
					Quantidade
					SimboloUnidade
					ValorUnitario
					ValorAcrescimo
					ValorDesconto
					ValorTotalItem
				}
				QtdItens
				ValorJuro
				ValorFrete
				ValorDesconto
				ValorTotal
				PrestacaoVenda {
					Id
					NomeFormaPgto
					DataVencimento
					ValorParcela
					NumLancamento
				}
				Frete
				IdTransportadora
				NomeTransportadora
				Observacoes
			}
			DataEmpresa {
				Id
				Nome
				NomeFantasia
				CpfCnpj
				LogoEmpresa
				CidadeNome
				Uf
				Logradouro
				Bairro
				Cep
				NumEndereco
				Complemento
				Celular
				Telefone
				EmailPrincipal
				ProdutoEmpresa
			}
		}
	}
`;

/**
 * __useImpressaoVendaQuery__
 *
 * To run a query within a React component, call `useImpressaoVendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpressaoVendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpressaoVendaQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useImpressaoVendaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ImpressaoVendaQuery, ImpressaoVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<ImpressaoVendaQuery, ImpressaoVendaQueryVariables>(ImpressaoVendaDocument, options);
}
export function useImpressaoVendaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImpressaoVendaQuery, ImpressaoVendaQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<ImpressaoVendaQuery, ImpressaoVendaQueryVariables>(ImpressaoVendaDocument, options);
}
export type ImpressaoVendaQueryHookResult = ReturnType<typeof useImpressaoVendaQuery>;
export type ImpressaoVendaLazyQueryHookResult = ReturnType<typeof useImpressaoVendaLazyQuery>;
export type ImpressaoVendaQueryResult = Apollo.QueryResult<ImpressaoVendaQuery, ImpressaoVendaQueryVariables>;
export const TotalizadoresVendasDocument = gql`
	query TotalizadoresVendas($filtro: InputFiltroTotalizadorVenda!) {
		totalizadoresVendas(Filtro: $filtro) {
			ValorPeriodo {
				TotalVendas
				QtdVendas
				QtdItens
				TicketMedioItens
				TicketMedioVendas
			}
			ValorPeriodoAnterior {
				TotalVendas
				QtdVendas
				QtdItens
				TicketMedioItens
				TicketMedioVendas
			}
			ValorPeriodoAnoAnterior {
				TotalVendas
				QtdVendas
				QtdItens
				TicketMedioItens
				TicketMedioVendas
			}
		}
	}
`;

/**
 * __useTotalizadoresVendasQuery__
 *
 * To run a query within a React component, call `useTotalizadoresVendasQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalizadoresVendasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalizadoresVendasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useTotalizadoresVendasQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TotalizadoresVendasQuery, TotalizadoresVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<TotalizadoresVendasQuery, TotalizadoresVendasQueryVariables>(TotalizadoresVendasDocument, options);
}
export function useTotalizadoresVendasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalizadoresVendasQuery, TotalizadoresVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<TotalizadoresVendasQuery, TotalizadoresVendasQueryVariables>(TotalizadoresVendasDocument, options);
}
export type TotalizadoresVendasQueryHookResult = ReturnType<typeof useTotalizadoresVendasQuery>;
export type TotalizadoresVendasLazyQueryHookResult = ReturnType<typeof useTotalizadoresVendasLazyQuery>;
export type TotalizadoresVendasQueryResult = Apollo.QueryResult<TotalizadoresVendasQuery, TotalizadoresVendasQueryVariables>;
export const VolumeVendasDocument = gql`
	query VolumeVendas($filtro: InputFiltroVolumeVenda!) {
		volumeVendas(Filtro: $filtro) {
			ValorTotal
			QtdVenda
			Data
		}
	}
`;

/**
 * __useVolumeVendasQuery__
 *
 * To run a query within a React component, call `useVolumeVendasQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolumeVendasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolumeVendasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useVolumeVendasQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VolumeVendasQuery, VolumeVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VolumeVendasQuery, VolumeVendasQueryVariables>(VolumeVendasDocument, options);
}
export function useVolumeVendasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VolumeVendasQuery, VolumeVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VolumeVendasQuery, VolumeVendasQueryVariables>(VolumeVendasDocument, options);
}
export type VolumeVendasQueryHookResult = ReturnType<typeof useVolumeVendasQuery>;
export type VolumeVendasLazyQueryHookResult = ReturnType<typeof useVolumeVendasLazyQuery>;
export type VolumeVendasQueryResult = Apollo.QueryResult<VolumeVendasQuery, VolumeVendasQueryVariables>;
export const RankingProdutosDocument = gql`
	query RankingProdutos($filtro: InputFiltroRankingVenda!) {
		rankingProdutos(Filtro: $filtro) {
			QtdVendida
			ValorVendido
			Descricao
			Codigo
			IdProduto
		}
	}
`;

/**
 * __useRankingProdutosQuery__
 *
 * To run a query within a React component, call `useRankingProdutosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankingProdutosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankingProdutosQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useRankingProdutosQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RankingProdutosQuery, RankingProdutosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<RankingProdutosQuery, RankingProdutosQueryVariables>(RankingProdutosDocument, options);
}
export function useRankingProdutosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RankingProdutosQuery, RankingProdutosQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<RankingProdutosQuery, RankingProdutosQueryVariables>(RankingProdutosDocument, options);
}
export type RankingProdutosQueryHookResult = ReturnType<typeof useRankingProdutosQuery>;
export type RankingProdutosLazyQueryHookResult = ReturnType<typeof useRankingProdutosLazyQuery>;
export type RankingProdutosQueryResult = Apollo.QueryResult<RankingProdutosQuery, RankingProdutosQueryVariables>;
export const VendasFormaPagamentoDocument = gql`
	query VendasFormaPagamento($filtro: InputFiltroTotalizadorVenda!) {
		vendasFormaPagamento(Filtro: $filtro) {
			IdFormaPgto
			Nome
			ValorVendido
			QtdVendas
			Porcentagem
		}
	}
`;

/**
 * __useVendasFormaPagamentoQuery__
 *
 * To run a query within a React component, call `useVendasFormaPagamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendasFormaPagamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendasFormaPagamentoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useVendasFormaPagamentoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VendasFormaPagamentoQuery, VendasFormaPagamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<VendasFormaPagamentoQuery, VendasFormaPagamentoQueryVariables>(VendasFormaPagamentoDocument, options);
}
export function useVendasFormaPagamentoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendasFormaPagamentoQuery, VendasFormaPagamentoQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<VendasFormaPagamentoQuery, VendasFormaPagamentoQueryVariables>(VendasFormaPagamentoDocument, options);
}
export type VendasFormaPagamentoQueryHookResult = ReturnType<typeof useVendasFormaPagamentoQuery>;
export type VendasFormaPagamentoLazyQueryHookResult = ReturnType<typeof useVendasFormaPagamentoLazyQuery>;
export type VendasFormaPagamentoQueryResult = Apollo.QueryResult<VendasFormaPagamentoQuery, VendasFormaPagamentoQueryVariables>;
export const PicoVendasDocument = gql`
	query PicoVendas($filtro: InputFiltroTotalizadorVenda!) {
		picoVendas(Filtro: $filtro) {
			PicoDiaMes {
				MaiorDia
				MenorDia
			}
			PicoDiaSemana {
				MaiorDia
				MenorDia
			}
		}
	}
`;

/**
 * __usePicoVendasQuery__
 *
 * To run a query within a React component, call `usePicoVendasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePicoVendasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePicoVendasQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function usePicoVendasQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PicoVendasQuery, PicoVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<PicoVendasQuery, PicoVendasQueryVariables>(PicoVendasDocument, options);
}
export function usePicoVendasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PicoVendasQuery, PicoVendasQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<PicoVendasQuery, PicoVendasQueryVariables>(PicoVendasDocument, options);
}
export type PicoVendasQueryHookResult = ReturnType<typeof usePicoVendasQuery>;
export type PicoVendasLazyQueryHookResult = ReturnType<typeof usePicoVendasLazyQuery>;
export type PicoVendasQueryResult = Apollo.QueryResult<PicoVendasQuery, PicoVendasQueryVariables>;
export const TotalizadoresTrocasDevolucoesDocument = gql`
	query TotalizadoresTrocasDevolucoes($filtro: InputFiltroTotalizadorTrocasDevolucao!) {
		totalizadoresTrocasDevolucoes(Filtro: $filtro) {
			QuantidadeDevolucoes
			ValorDevolucoes
			QuantidadeTrocas
			ValorTrocas
		}
	}
`;

/**
 * __useTotalizadoresTrocasDevolucoesQuery__
 *
 * To run a query within a React component, call `useTotalizadoresTrocasDevolucoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalizadoresTrocasDevolucoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalizadoresTrocasDevolucoesQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useTotalizadoresTrocasDevolucoesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TotalizadoresTrocasDevolucoesQuery, TotalizadoresTrocasDevolucoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useQuery<TotalizadoresTrocasDevolucoesQuery, TotalizadoresTrocasDevolucoesQueryVariables>(TotalizadoresTrocasDevolucoesDocument, options);
}
export function useTotalizadoresTrocasDevolucoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalizadoresTrocasDevolucoesQuery, TotalizadoresTrocasDevolucoesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useLazyQuery<TotalizadoresTrocasDevolucoesQuery, TotalizadoresTrocasDevolucoesQueryVariables>(TotalizadoresTrocasDevolucoesDocument, options);
}
export type TotalizadoresTrocasDevolucoesQueryHookResult = ReturnType<typeof useTotalizadoresTrocasDevolucoesQuery>;
export type TotalizadoresTrocasDevolucoesLazyQueryHookResult = ReturnType<typeof useTotalizadoresTrocasDevolucoesLazyQuery>;
export type TotalizadoresTrocasDevolucoesQueryResult = Apollo.QueryResult<TotalizadoresTrocasDevolucoesQuery, TotalizadoresTrocasDevolucoesQueryVariables>;
export const AguardaTransmissaoNfeDocument = gql`
	subscription AguardaTransmissaoNfe($correlationId: String!) {
		aguardaTransmissaoNfe(CorrelationId: $correlationId)
	}
`;

/**
 * __useAguardaTransmissaoNfeSubscription__
 *
 * To run a query within a React component, call `useAguardaTransmissaoNfeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAguardaTransmissaoNfeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAguardaTransmissaoNfeSubscription({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useAguardaTransmissaoNfeSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<AguardaTransmissaoNfeSubscription, AguardaTransmissaoNfeSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<AguardaTransmissaoNfeSubscription, AguardaTransmissaoNfeSubscriptionVariables>(AguardaTransmissaoNfeDocument, options);
}
export type AguardaTransmissaoNfeSubscriptionHookResult = ReturnType<typeof useAguardaTransmissaoNfeSubscription>;
export type AguardaTransmissaoNfeSubscriptionResult = Apollo.SubscriptionResult<AguardaTransmissaoNfeSubscription>;
export const EnviaEmailVendaDocument = gql`
	subscription EnviaEmailVenda($idVenda: Int!) {
		enviaEmailVenda(IdVenda: $idVenda)
	}
`;

/**
 * __useEnviaEmailVendaSubscription__
 *
 * To run a query within a React component, call `useEnviaEmailVendaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEnviaEmailVendaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnviaEmailVendaSubscription({
 *   variables: {
 *      idVenda: // value for 'idVenda'
 *   },
 * });
 */
export function useEnviaEmailVendaSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<EnviaEmailVendaSubscription, EnviaEmailVendaSubscriptionVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useSubscription<EnviaEmailVendaSubscription, EnviaEmailVendaSubscriptionVariables>(EnviaEmailVendaDocument, options);
}
export type EnviaEmailVendaSubscriptionHookResult = ReturnType<typeof useEnviaEmailVendaSubscription>;
export type EnviaEmailVendaSubscriptionResult = Apollo.SubscriptionResult<EnviaEmailVendaSubscription>;
export const CadastraVendaPdvDocument = gql`
	mutation CadastraVendaPdv($dados: InputCadastrarVendaPDV!, $correlationIdNFCe: [String!]) {
		cadastraVendaPdv(Dados: $dados, CorrelationIdNFCe: $correlationIdNFCe)
	}
`;
export type CadastraVendaPdvMutationFn = Apollo.MutationFunction<CadastraVendaPdvMutation, CadastraVendaPdvMutationVariables>;

/**
 * __useCadastraVendaPdvMutation__
 *
 * To run a mutation, you first call `useCadastraVendaPdvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastraVendaPdvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastraVendaPdvMutation, { data, loading, error }] = useCadastraVendaPdvMutation({
 *   variables: {
 *      dados: // value for 'dados'
 *      correlationIdNFCe: // value for 'correlationIdNFCe'
 *   },
 * });
 */
export function useCadastraVendaPdvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CadastraVendaPdvMutation, CadastraVendaPdvMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<CadastraVendaPdvMutation, CadastraVendaPdvMutationVariables>(CadastraVendaPdvDocument, options);
}
export type CadastraVendaPdvMutationHookResult = ReturnType<typeof useCadastraVendaPdvMutation>;
export type CadastraVendaPdvMutationResult = Apollo.MutationResult<CadastraVendaPdvMutation>;
export type CadastraVendaPdvMutationOptions = Apollo.BaseMutationOptions<CadastraVendaPdvMutation, CadastraVendaPdvMutationVariables>;
export const GeraNfceDaVendaPdvDocument = gql`
	mutation GeraNfceDaVendaPdv($id: Int!, $correlationId: [String]) {
		geraNfceDaVendaPdv(Id: $id, CorrelationId: $correlationId)
	}
`;
export type GeraNfceDaVendaPdvMutationFn = Apollo.MutationFunction<GeraNfceDaVendaPdvMutation, GeraNfceDaVendaPdvMutationVariables>;

/**
 * __useGeraNfceDaVendaPdvMutation__
 *
 * To run a mutation, you first call `useGeraNfceDaVendaPdvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeraNfceDaVendaPdvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [geraNfceDaVendaPdvMutation, { data, loading, error }] = useGeraNfceDaVendaPdvMutation({
 *   variables: {
 *      id: // value for 'id'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useGeraNfceDaVendaPdvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GeraNfceDaVendaPdvMutation, GeraNfceDaVendaPdvMutationVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return ApolloReactHooks.useMutation<GeraNfceDaVendaPdvMutation, GeraNfceDaVendaPdvMutationVariables>(GeraNfceDaVendaPdvDocument, options);
}
export type GeraNfceDaVendaPdvMutationHookResult = ReturnType<typeof useGeraNfceDaVendaPdvMutation>;
export type GeraNfceDaVendaPdvMutationResult = Apollo.MutationResult<GeraNfceDaVendaPdvMutation>;
export type GeraNfceDaVendaPdvMutationOptions = Apollo.BaseMutationOptions<GeraNfceDaVendaPdvMutation, GeraNfceDaVendaPdvMutationVariables>;
